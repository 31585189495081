import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { getAllBrandsData } from '../../../actions';
import { MY_ACCOUNT } from '../../../constants';

import FigoLoadingOverlay from '../../common/FigoLoadingOverlay';
import MultiPoliciesPayments from './MultiPoliciesPayments';
import {
  BillingDetailsScreen,
} from './BillingAndPaymentsDetails/BillingDetailsScreen';
import { EmptyScreen } from '../../common/petCloud/EmptyScreen';

import './css/BillingAndPaymentsScreen.css';

const BillingAndPaymentsScreen = ({ breadCrumbs, currentFolderId }) => {
  const { t } = useTranslation('myAccount');
  const dispatch = useDispatch();
  const store = useSelector(({ billingPayments }) => ({ billingPayments }),
    shallowEqual);

  const isBillingDetails = React.useMemo(() => (currentFolderId
    === MY_ACCOUNT.billingAndPaymentDetails), [currentFolderId]);

  useEffect(() => {
    if (!isBillingDetails) {
      dispatch(getAllBrandsData());
    }
  }, [isBillingDetails]);

  const { allBrandsData, loadingAllBrandsData } = store.billingPayments;

  function renderContent() {
    if (isBillingDetails) {
      return <BillingDetailsScreen breadCrumbs={breadCrumbs} />;
    }

    return (
      <>
        {allBrandsData.length > 0 && (
          <p className="Billing-and-payments-description">
            {t('billingAndPayments.description')}
          </p>
        )}

        <FigoLoadingOverlay visible={loadingAllBrandsData} />

        {!loadingAllBrandsData && allBrandsData.length > 0 && (
          <MultiPoliciesPayments
            allBrandsData={allBrandsData}
            breadCrumbs={breadCrumbs}
          />
        )}

        {!loadingAllBrandsData && allBrandsData.length === 0 && (
          <EmptyScreen
            description={t('billingAndPayments'
              + '.noCurrentPolicyPaymentsDescription')}
            title={t('billingAndPayments.noCurrentPolicyPaymentsTitle')}
          />
        )}
      </>
    );
  }

  return (
    <div className="Billing-and-payments-container">
      <h1 className="Billing-and-payments-title">
        {t('billingDetailsCard.title')}
      </h1>

      {renderContent()}
    </div>
  );
};

export { BillingAndPaymentsScreen };
