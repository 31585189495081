/* eslint-disable max-len */
import { createActions } from 'redux-actions';

const actions = createActions({
  ACCEPT_ADDRESS_UPDATE: (data) => data,
  ADDRESS_UPDATED: (data) => data,
  ADDRESS1_CHANGED: (data) => data,
  ADDRESS2_CHANGED: (data) => data,
  APPLY_ADDRESS_UPDATE: (data) => data,
  AVATAR_PICTURE_REMOVED: () => { },
  CANCEL_ADDRESS_UPDATE: () => { },
  CHANGE_PROFILE_PICTURE: (data) => (data),
  CHANGE_PROFILE_PICTURE_FAILED: (data) => (data),
  CHANGE_PROFILE_PICTURE_RESTART: () => { },
  CHANGE_PROFILE_PICTURE_SUCCESS: () => { },
  CITY_CHANGED: (data) => data,
  CLOSE_ENDORSEMENT_SUCCESS_MODAL: () => { },
  CONFIRM_ADDRESS_CHANGE_MODAL_CHANGED: (data) => data,
  COVER_PICTURE_REMOVED: () => { },
  DISPLAY_ENDORSEMENT_MESSAGE_MODAL: (data) => data,
  DISPLAY_ENDORSEMENT_SUCCESS_MODAL: () => { },
  DO_PET_LOAD: (data) => data,
  ENDORSEMENT_LOADED: (data) => data,
  FIRST_NAME_CHANGED: (data) => data,
  FIRST_NAME_RESET: () => { },
  LAST_NAME_CHANGED: (data) => data,
  LAST_NAME_RESET: () => { },
  NAMES_UPDATED: (data) => data,
  PETS_LOAD_ATTEMPT: () => { },
  PETS_LOADED: (data) => data,
  PETS_LOADED_FINISHED: () => { },
  PHONES_UPDATED: (data) => data,
  PRIMARY_PHONE_CHANGED: (data) => data,
  REFRESH_PERSONAL_INFORMATION: (data) => (data),
  REFRESH_PETS: () => { },
  REMOVE_AVATAR_PICTURE: () => { },
  REMOVE_AVATAR_PICTURE_FAILED: () => { },
  REMOVE_COVER_PICTURE: () => { },
  REMOVE_COVER_PICTURE_FAILED: () => { },
  RESET_ADDRESS: () => { },
  RESET_ENDORSEMENT_DATA: () => { },
  RESET_NAME: () => { },
  RESET_PHONE: () => { },
  SAVE_USER_INFO_ADDRESS: (data) => data,
  SECONDARY_PHONE_CHANGED: (data) => data,
  SECONDARY_PHONE_RESET: () => { },
  SOCIAL_PROFILE_LOAD_ATTEMPT: () => { },
  SOCIAL_PROFILE_LOADED: (data) => data,
  SOCIAL_PROFILE_LOADED_FINISHED: () => { },
  STATE_CHANGED: (data) => data,
  STORE_STATES: (data) => data,
  UPDATE_ADDRESS: (data) => data,
  UPDATE_INSURANCE_INFORMATION: (data) => data,
  UPDATE_INSURANCE_INFORMATION_FAILED: () => { },
  UPDATE_INSURANCE_INFORMATION_SUCCESS: () => {},
  UPDATE_NAMES: (data) => data,
  UPDATE_PHONES: (data) => data,
  UPDATE_PHONES_ERROR: () => { },
  ZIPCODE_CHANGED: (data) => data,
});

export const acceptAddressUpdate = actions.acceptAddressUpdate;
export const address1Changed = actions.address1Changed;
export const address2Changed = actions.address2Changed;
export const addressUpdated = actions.addressUpdated;
export const applyAddressUpdate = actions.applyAddressUpdate;
export const cancelAddressUpdate = actions.cancelAddressUpdate;
export const cityChanged = actions.cityChanged;
export const closeEndorsementSuccessModal = actions.closeEndorsementSuccessModal;
export const confirmAddressChangeModalChanged = actions.confirmAddressChangeModalChanged;
export const displayEndorsementMessageModal = actions.displayEndorsementMessageModal;
export const displayEndorsementSuccessModal = actions.displayEndorsementSuccessModal;
export const doPetLoad = actions.doPetLoad;
export const endorsementLoaded = actions.endorsementLoaded;
export const firstnameChanged = actions.firstNameChanged;
export const lastnameChanged = actions.lastNameChanged;
export const namesUpdated = actions.namesUpdated;
export const petsLoaded = actions.petsLoaded;
export const petsLoadAttempt = actions.petsLoadAttempt;
export const petsLoadedFinished = actions.petsLoadedFinished;
export const phonesUpdated = actions.phonesUpdated;
export const primaryPhoneChanged = actions.primaryPhoneChanged;
export const refreshPersonalInformation = actions.refreshPersonalInformation;
export const refreshPets = actions.refreshPets;
export const resetAddress = actions.resetAddress;
export const resetEndorsementData = actions.resetEndorsementData;
export const resetFirstname = actions.firstNameReset;
export const resetLastname = actions.lastNameReset;
export const resetName = actions.resetName;
export const resetPhone = actions.resetPhone;
export const resetSecondaryPhone = actions.secondaryPhoneReset;
export const saveUserInfoAddress = actions.saveUserInfoAddress;
export const secondaryPhoneChanged = actions.secondaryPhoneChanged;
export const socialProfileLoadAttempt = actions.socialProfileLoadAttempt;
export const socialProfileLoaded = actions.socialProfileLoaded;
export const socialProfileLoadedFinished = actions.socialProfileLoadedFinished;
export const stateChanged = actions.stateChanged;
export const storeStates = actions.storeStates;
export const updateAddress = actions.updateAddress;
export const updateNames = actions.updateNames;
export const updatePhones = actions.updatePhones;
export const updatePhonesError = actions.updatePhonesError;
export const zipCodeChanged = actions.zipcodeChanged;
export const {
  avatarPictureRemoved,
  changeProfilePicture,
  changeProfilePictureFailed,
  changeProfilePictureRestart,
  changeProfilePictureSuccess,
  coverPictureRemoved,
  removeAvatarPicture,
  removeAvatarPictureFailed,
  removeCoverPicture,
  removeCoverPictureFailed,
  updateInsuranceInformation,
  updateInsuranceInformationFailed,
  updateInsuranceInformationSuccess,
} = actions;
