import React from 'react';
import { useTranslation } from 'react-i18next';

import mailicon from '../../assets/mail.svg';

import './PublicFooter.css';

const PublicFooter = () => {
  const { t } = useTranslation('common');
  return (
    <footer className="Public-footer">
      <div className="Public-footer-support">
        <div>{t('publicFooter.haveQuestion')}</div>

        <div className="Public-footer-support-email">
          <img alt="" src={mailicon} />

          <a href={`mailto:${t('publicFooter.email')}`}>
            {t('publicFooter.email')}
          </a>
        </div>
      </div>

      <div className="Public-footer-copyright">
        {t('publicFooter.copyright', { year: new Date().getFullYear() })}
      </div>
    </footer>
  );
};

export { PublicFooter };
