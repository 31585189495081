import React from 'react';
import TouchableDiv from '../../common/TouchableDiv';
import arrows from '../../../assets/inboxDocsMultibrand/arrows-black.svg';
import './HeaderFieldContent.css';

const HeaderFieldContent = ({
  className = '',
  hasFilter = true,
  id = '',
  isAscendingFilter = true,
  isFilterActive = false,
  onClick = () => { },
  title = '',
}) => (
  <th className={className}>
    <TouchableDiv id={id} onClick={onClick}>
      <span className="No-cursor-events">{title}</span>

      {hasFilter && (
        <img
          alt=""
          className={`Docs-arrow-icon ${
            !isAscendingFilter && isFilterActive ? 'Rotate-filter' : ''}`}
          src={arrows}
        />
      )}
    </TouchableDiv>
  </th>
);

export { HeaderFieldContent };
