import React from 'react';

const CoverageOverviewField = ({ label, value }) => (
  <div className="Coverage-overview-field">
    <span className="Coverage-overview-info-title">
      {label}
    </span>

    <span className="Coverage-overview-info-value">
      {value}
    </span>
  </div>
);

export { CoverageOverviewField };
