import React from 'react';
import { useTranslation } from 'react-i18next';

import './PetsHealthCard.css';

const PetsHealthCard = () => {
  const { t } = useTranslation('home');

  return (
    <div className="Pets-health-main-container">
      <div className="Pets-health-card">
        <h2 className="Pets-health-card-title">
          {t('petsHealth.title')}
        </h2>

        <h3 className="Pets-health-card-description">
          {t('petsHealth.description')}
        </h3>
      </div>
    </div>
  );
};

export { PetsHealthCard };
