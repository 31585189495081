import React from 'react';

function createHighlightSpan(text, className) {
  return <span className={className}>{text}</span>;
}

function highlightWithRegex(text, regex) {
  const parts = text.split(regex);
  const matches = text.match(regex) || [];
  const highlighted = [];

  parts.forEach((part, index) => {
    highlighted.push(part);

    if (index < parts.length - 1) {
      highlighted.push(
        <span className="Policy-alert-bold-text">{matches[index]}</span>,
      );
    }
  });

  return highlighted;
}

function highlightDate(textParts) {
  const dateRegex = /\d{2}\/\d{2}\/\d{4}/;

  return textParts.flatMap((part) => {
    const parts = String(part).split(dateRegex);
    const dates = String(part).match(dateRegex) || [];

    if (dates.length === 0) {
      return [part];
    }

    const highlightedParts = [];
    for (let i = 0; i < parts.length; i += 1) {
      highlightedParts.push(parts[i]);
      if (dates[i]) {
        highlightedParts.push(
          createHighlightSpan(dates[i], 'Policy-alert-bold-text'),
        );
      }
    }

    return highlightedParts;
  });
}

export function highlightPrice(message, className) {
  const priceRegex = /\$\d+\.?\d{0,2}/g;

  const highlighted = highlightWithRegex(message, priceRegex, className);

  return highlightDate(highlighted);
}
