import { createActions } from 'redux-actions';

const actions = createActions({
  CLAIM_ADDITIONAL_STEPS_HANDLED: () => { },
  CLAIM_ADDITIONAL_STEPS_LOADED: (data) => data,
  CLAIM_GET_POLICY_BY_DATE: (data) => data,
  CLAIM_POLICY_BY_DATE_FAILED: (data) => data,
  CLAIM_POLICY_BY_DATE_LOADED: (data) => data,
  CLAIM_STEPS_LOAD_FAILED: () => { },
  CLAIM_STEPS_LOADED: (data) => data,
  CLAIM_STEPS_VIEWED: () => { },
  CLAIMBOT_SELECTED_PET: (data) => data,
  CLAIMBOT_SELECTED_VALUE: (data) => data,
  CLEAR_CLAIM_STEPS: () => { },
  GENERAL_INFORMATION_LOADED: (data) => data,
  HAS_FIGO_CLAIMS_FAILED: () => { },
  HAS_FIGO_CLAIMS_LOADED: (data) => data,
  LOAD_ADDITIONAL_CLAIMBOT_STEPS: (data) => data,
  LOAD_CLAIM_STEPS: () => { },
  LOAD_HAS_FIGO_CLAIMS: () => { },
  SUBMIT_CLAIM: (data) => data,
  SUBMIT_CLAIM_FAILED: () => { },
  SUBMIT_CLAIM_SUCCESS: () => { },
});

export const {
  claimAdditionalStepsHandled,
  claimAdditionalStepsLoaded,
  claimGetPolicyByDate,
  claimbotSelectedPet,
  claimbotSelectedValue,
  claimPolicyByDateFailed,
  claimPolicyByDateLoaded,
  claimStepsLoaded,
  claimStepsLoadFalied,
  claimStepsViewed,
  clearClaimSteps,
  generalInformationLoaded,
  hasFigoClaimsFailed,
  hasFigoClaimsLoaded,
  loadAdditionalClaimbotSteps,
  loadClaimSteps,
  loadHasFigoClaims,
  submitClaim,
  submitClaimFailed,
  submitClaimSuccess,
} = actions;
