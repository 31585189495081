/* eslint-disable max-len */
import { createActions } from 'redux-actions';

const actions = createActions({
  ADD_ATTACHMENTS_LOAD: (data) => data,
  CLAIM_DATE_CHANGED: (data) => data,
  CLAIM_REIMBURSEMENT_CHECK_CHANGED: (data) => data,
  CLAIM_REIMBURSEMENT_DIRECT_CHANGED: (data) => data,
  CREATE_CLAIM: () => {},
  FILE_CLAIM_COMPLETED: (data) => data,
  FILE_CLAIM_NOT_COMPLETED: () => {},
  FILE_CLAIMS_AMOUNT_CHANGED: (data) => data,
  FILE_CLAIMS_ANOTHER_PET_INSURANCE_CHANGED: (data) => data,
  FILE_CLAIMS_ANOTHER_PET_INSURANCE_SELECTED: (data) => data,
  FILE_CLAIMS_ATTACHMENT_ADDED: (data) => data,
  FILE_CLAIMS_ATTACHMENT_REMOVED: (data) => data,
  FILE_CLAIMS_ATTACHMENT_UPDATED: (data) => data,
  FILE_CLAIMS_DETAILS_CHANGED: (data) => data,
  FILE_CLAIMS_NAME_CHANGED: (data) => data,
  FILE_CLAIMS_RESTART: () => {},
  FILE_CLAIMS_TYPE_CHANGED: (data) => data,
  INITIAL_TIME_SET: (data) => data,
  SELECTED_PET_CHANGED: (data) => data,
  TERM_AND_CONDITION_ONE_SELECTED: (data) => data,
  TERM_AND_CONDITION_TWO_SELECTED: (data) => data,
});

export const {
  addAttachmentsLoad,
  claimDateChanged,
  claimReimbursementCheckChanged,
  claimReimbursementDirectChanged,
  createClaim,
  fileClaimCompleted,
  fileClaimNotCompleted,
  fileClaimsAmountChanged,
  fileClaimsAnotherPetInsuranceChanged,
  fileClaimsAnotherPetInsuranceSelected,
  fileClaimsAttachmentAdded,
  fileClaimsAttachmentRemoved,
  fileClaimsAttachmentUpdated,
  fileClaimsDetailsChanged,
  fileClaimsNameChanged,
  fileClaimsRestart,
  fileClaimsTypeChanged,
  initialTimeSet,
  selectedPetChanged,
  termAndConditionOneSelected,
  termAndConditionTwoSelected,
} = actions;
