import { handleActions } from 'redux-actions';
import { POLICY_STATUS } from '../constants';
import { POWERUP_TYPE }
from '../components/changeCoverage/powerups/powerups.constans';

const INITIAL_SELECTED_VALUES = {
  annualAmountPlan: 0,
  deductibleDescription: '',
  deductibleId: 0,
  isMonthly: true,
  loadingOverview: false,
  modifiers: [],
  monthlyAmountPlan: 0,
  petQuoteId: 0,
  reimbursementDescription: '',
  reimbursementId: 0,
  selectedAnnualBenefit: 0,
  selectedPlan: 0,
  total: 0,
  totalAnnual: 0,
  totalMonthly: 0,
};

const INITIAL_STATE = {
  allPets: [],
  allPolicies: null,
  changeCoverage: {
    deductibleLegend: '',
    error: false,
    initialCoverageValues: INITIAL_SELECTED_VALUES,
    loading: false,
    loadingSubmit: false,
    multimodalData: null,
    multimodalDataError: false,
    powerUpLoadings: [],
    preview: {
      loaded: false,
    },
    quoteSelectedValues: INITIAL_SELECTED_VALUES,
    rate: {
      effectiveDate: null,
      error: false,
      insuranceProduct: null,
      petQuoteResponseList: [],
      rateCreated: false,
      zipCode: null,
    },
    reviewChangesVisible: false,
    success: false,
  },
  changeCoverageValidation: {
    isLoading: false,
    isSuccess: false,
    isValid: false,
    message: '',
  },
  data: [],
  defaultPolicy: null,
  error: null,
  isYoungPetModalVisible: false,
  loading: true,
  loadingDefaultPolicy: false,
  loadingOtherPolicy: false,
  loadingOverview: true,
  loadingPlanSummary: false,
  loadingPoliciesOverView: true,
  loadingSeeMoreInfo: false,
  loadingWhatWeCoverInfo: false,
  loggedInWithoutPets: false,
  multibrandPolicies: [],
  multiModalData: {},
  otherPolicyDetail: null,
  pets: [],
  planSummarySeeMoreInfo: [],
  policiesOverView: [],
  policyBenefits: [],
  policyNotifications: [],
  showPlanSummarySeeMore: false,
  waitingPeriods: null,
  whatWeCoverInfo: [],
};

export default handleActions({
  ALL_PETS_GROUPED: (state, { payload }) => ({
    ...state,
    allPets: payload,
  }),
  CHANGE_COVERAGE_MULTIMODAL_FAILED: (state) => ({
    ...state,
    changeCoverage: {
      ...state.changeCoverage,
      multimodalData: null,
      multimodalDataError: true,
    },
  }),
  CHANGE_COVERAGE_MULTIMODAL_LOADED: (state, { payload }) => ({
    ...state,
    changeCoverage: {
      ...state.changeCoverage,
      multimodalData: payload,
      multimodalDataError: true,
    },
  }),
  CHANGE_COVERAGE_PREVIEW_LOADED: (state, { payload }) => ({
    ...state,
    changeCoverage: {
      ...state.changeCoverage,
      loading: false,
      preview: {
        ...state.changeCoverage.preview,
        loaded: true,
        ...payload,
      },
    },
  }),
  CHANGE_DEDUCTIBLE_LEGEND: (state, { payload }) => ({
    ...state,
    changeCoverage: {
      ...state.changeCoverage,
      deductibleLegend: payload,
    },
  }),
  CLEAR_POLICY_NOTIFICATIONS: (state) => ({
    ...state,
    policyNotifications: [],
  }),
  DEDUCTIBLE_CHANGED: (state, { payload }) => ({
    ...state,
    changeCoverage: {
      ...state.changeCoverage,
      quoteSelectedValues: {
        ...state.changeCoverage.quoteSelectedValues,
        deductibleDescription: payload.description,
        deductibleId: payload.deductibleId,
      },
    },
  }),
  DO_LOGOUT: () => ({ ...INITIAL_STATE }),
  GET_CHANGE_COVERAGE_MULTIMODAL_DATA: (state) => ({
    ...state,
    changeCoverage: {
      ...state.changeCoverage,
      multimodalData: null,
      multimodalDataError: false,
    },
  }),
  GET_CHANGE_COVERAGE_PREVIEW: (state) => ({
    ...state,
    changeCoverage: {
      ...state.changeCoverage,
      loading: true,
    },
  }),
  GET_CHANGE_COVERAGE_PREVIEW_ERROR: (state) => ({
    ...state,
    changeCoverage: {
      ...state.changeCoverage,
      error: true,
      loading: false,
      preview: {
        ...state.changeCoverage.preview,
        loaded: true,
      },
    },
  }),
  GET_MULTIBRAND_DEFAULT_POLICY: (state) => ({
    ...state,
    defaultPolicy: null,
    loadingDefaultPolicy: true,
  }),
  GET_MULTIBRAND_DEFAULT_POLICY_ERROR: (state) => ({
    ...state,
    defaultPolicy: null,
    loadingDefaultPolicy: false,
  }),
  GET_MULTIBRAND_DEFAULT_POLICY_LOADED: (state, { payload }) => ({
    ...state,
    defaultPolicy: payload,
    loadingDefaultPolicy: false,
  }),
  GET_MULTIBRAND_POLICIES: (state) => ({
    ...state,
    loading: true,
    multibrandPolicies: [],
  }),
  GET_MULTIBRAND_POLICIES_ERROR: (state) => ({
    ...state,
    loading: false,
    multibrandPolicies: [],
  }),
  GET_MULTIBRAND_POLICIES_LOADED: (state, { payload }) => ({
    ...state,
    loading: false,
    multibrandPolicies: payload,
  }),
  GET_POLICY_DETAIL: (state) => ({
    ...state,
    loadingOtherPolicy: true,
    otherPolicyDetail: null,
  }),
  GET_POLICY_DETAIL_ERROR: (state) => ({
    ...state,
    loadingOtherPolicy: false,
  }),
  GET_POLICY_DETAIL_LOADED: (state, { payload }) => ({
    ...state,
    loadingOtherPolicy: false,
    otherPolicyDetail: payload,
  }),
  INITIAL_POWER_UPS: (state, { payload }) => ({
    ...state,
    changeCoverage: {
      ...state.changeCoverage,
      quoteSelectedValues: {
        ...state.changeCoverage.quoteSelectedValues,
        modifiers: payload.modifiers,
      },
    },
  }),
  LOAD_PLAN_SUMMARY_DETAILS: (state) => ({
    ...state,
    loadingPlanSummary: true,
    policyBenefits: [],
  }),
  LOAD_PLAN_SUMMARY_SEE_MORE_INFO_ATTEMPT: (state) => ({
    ...state,
    loadingSeeMoreInfo: true,
  }),
  LOAD_POLICIES_CARD_INFORMATION: (state, { payload }) => ({
    ...state,
    policiesWithCardInfo: state.data
      .filter((item) => (
        item.Policy.Status === POLICY_STATUS.active
        || item.Policy.Status === POLICY_STATUS.future
      )).map((item) => ({
        ...item,
        Policy: {
          ...item.Policy,
          last4: payload
            .find((value) => value.PolicyNumber === item.Policy.Number)
            .AccountNumber,
        },
      })),
  }),
  LOAD_POLICIES_OVERVIEW: (state) => ({
    ...state,
    loadingOverview: true,
  }),
  LOAD_WHAT_WE_COVER_INFO_ATTEMPT: (state) => ({
    ...state,
    loadingWhatWeCoverInfo: true,
  }),
  LOGGED_IN_WITHOUT_PETS_RESET: (state) => ({
    ...state,
    loggedInWithoutPets: false,
  }),
  MULTI_MODAL_DATA_LOADED: (state, { payload }) => ({
    ...state,
    multiModalData: payload,
  }),
  PETS_GROUPED: (state, { payload }) => ({
    ...state,
    pets: payload,
  }),
  PLAN_SUMMARY_SEE_MORE_CLICKED: (state, { payload }) => ({
    ...state,
    planSummarySeeMoreInfo: [],
    showPlanSummarySeeMore: payload,
  }),
  PLAN_SUMMARY_SEE_MORE_INFO_LOADED: (state, { payload }) => ({
    ...state,
    loadingSeeMoreInfo: false,
    planSummarySeeMoreInfo: payload,
  }),
  POLICIES_ERROR: (state, { payload }) => ({
    ...state,
    error: payload,
    loading: false,
  }),
  POLICIES_OVERVIEW_LOADED: (state, { payload }) => ({
    ...state,
    loadingOverview: false,
    loadingPoliciesOverView: false,
    policiesOverView: payload,
  }),
  POLICY_DETAIL_CLEANED: (state) => ({
    ...state,
    defaultPolicy: null,
    otherPolicyDetail: null,
  }),
  POLICY_DETAILS_BENEFITS_LOADED: (state, { payload }) => ({
    ...state,
    loadingPlanSummary: false,
    policyBenefits: Array.isArray(payload) ? payload : [],
  }),
  POLICY_NOTIFICATIONS_LOADED: (state, { payload }) => ({
    ...state,
    policyNotifications: payload,
  }),
  POWER_UP_SELECTED: (state, { payload }) => {
    const { quoteSelectedValues } = state.changeCoverage;
    const { id, isSelected } = payload;

    const oldModifiers = quoteSelectedValues.modifiers || [];
    const isNewModifier =
      !oldModifiers.find((oldModifier) => oldModifier.id === id);

    if (isNewModifier) {
      return {
        ...state,
        changeCoverage: {
          ...state.changeCoverage,
          quoteSelectedValues: {
            ...quoteSelectedValues,
            modifiers: [
              ...quoteSelectedValues.modifiers,
              { id, isSelected },
            ],
          },
        },
      };
    }

    return {
      ...state,
      changeCoverage: {
        ...state.changeCoverage,
        quoteSelectedValues: {
          ...quoteSelectedValues,
          modifiers: quoteSelectedValues.modifiers.map((modifier) => {
            if (modifier.id === id) {
              return { id, isSelected };
            }

            return modifier;
          }),
        },
      },
    };
  },
  QUOTE_POWER_UP_CHANGED: (state, { payload }) => {
    const { rate } = state.changeCoverage;
    const { id, isSelected } = payload;

    return {
      ...state,
      changeCoverage: {
        ...state.changeCoverage,
        rate: {
          ...rate,
          insuranceProduct: {
            ...rate.insuranceProduct,
            InsuranceModifiers:
              rate.insuranceProduct.InsuranceModifiers
                .map((item) => {
                  if (item.Id === id) {
                    return {
                      ...item,
                      IsSelected: isSelected,
                    };
                  }

                  return item;
                }),
          },
        },
      },
    };
  },
  QUOTE_TOTAL_RECALCULATED: (state) => {
    const { quoteSelectedValues, rate } = state.changeCoverage;
    if (!quoteSelectedValues.selectedPlan) {
      return state;
    }
    const selectedPlan = rate.petQuoteResponseList[0].Plans
      .find((plan) => plan.Plan === quoteSelectedValues.selectedPlan);
    const { AnnualPremium, MonthlyPremium } = selectedPlan.RatingOptions
      .find((rating) => rating.DeductibleId === quoteSelectedValues.deductibleId
        && rating.ReimbursementId === quoteSelectedValues.reimbursementId);
    return ({
      ...state,
      changeCoverage: {
        ...state.changeCoverage,
        quoteSelectedValues: {
          ...quoteSelectedValues,
          annualAmountPlan: AnnualPremium,
          monthlyAmountPlan: MonthlyPremium,
          total: Number(quoteSelectedValues.isMonthly
            ? MonthlyPremium
            : AnnualPremium).toFixed(2),
          totalAnnual: Number(AnnualPremium).toFixed(2),
          totalMonthly: Number(MonthlyPremium).toFixed(2),
        },
      },
    });
  },
  RATE_COVERAGE: (state) => ({
    ...state,
    changeCoverage: {
      ...state.changeCoverage,
      error: false,
      initialCoverageValues: INITIAL_SELECTED_VALUES,
      loading: true,
      loadingSubmit: false,
      quoteSelectedValues: INITIAL_SELECTED_VALUES,
      rate: {
        ...state.changeCoverage.rate,
        error: false,
      },
      success: false,
    },
  }),
  RATE_COVERAGE_ERROR: (state) => ({
    ...state,
    changeCoverage: {
      ...state.changeCoverage,
      loading: false,
      rate: {
        ...state.changeCoverage.rate,
        error: true,
      },
    },
  }),
  RATE_COVERAGE_SUCCESS: (state, { payload }) => {
    const [petQuote] = payload.petQuoteResponseList;
    const planSelected =
      petQuote.Plans.find((item) => item.Plan === petQuote.Plan);
    const modifierQuote = petQuote.modifiers || [];

    return {
      ...state,
      changeCoverage: {
        ...state.changeCoverage,
        initialCoverageValues: state
          .changeCoverage.initialCoverageValues.petQuoteId
          ? { ...state.changeCoverage.initialCoverageValues }
          : {
            annualAmountPlan: petQuote.annualPremium,
            deductibleDescription: petQuote.deductibleName,
            deductibleId: petQuote.Deductible,
            InsuranceModifiers: petQuote.InsuranceModifiers
              ?.filter((modifier) => modifier.InsuranceModifierTypeId
                === POWERUP_TYPE && modifier.IsVisible)
              .map((powerUp) => ({
                id: powerUp.Id,
                isIncluded: modifierQuote
                  .find((item) => item.id === powerUp.Id)?.isSelected || false,
                name: powerUp.AppText,
              })),
            isMonthly: true,
            modifiers: petQuote.modifiers,
            monthlyAmountPlan: petQuote.monthlyPremium,
            petQuoteId: petQuote.petQuoteId,
            reimbursementDescription: petQuote.ReimbursementName,
            reimbursementId: petQuote.Reimbursement,
            selectedAnnualBenefit: planSelected?.MaxAnnual,
            selectedPlan: petQuote.Plan,
            total: 0,
            totalAnnual: petQuote.annualPremium?.toFixed(2),
            totalMonthly: petQuote.monthlyPremium?.toFixed(2),
          },
        loading: false,
        rate: {
          ...state.changeCoverage.rate,
          error: false,
          rateCreated: true,
          ...payload,
        },
      },
    };
  },
  RATE_QUOTE_UPDATE_ATTEMPT: (state, { payload }) => ({
    ...state,
    changeCoverage: {
      ...state.changeCoverage,
      powerUpLoadings: [...state.changeCoverage.powerUpLoadings, payload.id],
    },
  }),
  RATE_QUOTE_UPDATE_LOADED: (state, { payload }) => {
    const { quoteSelectedValues, rate: {
      petQuoteResponseList,
    } } = state.changeCoverage;

    if (!quoteSelectedValues.selectedPlan) {
      return state;
    }

    const selectedPlan = petQuoteResponseList[0].Plans
      .find((plan) => plan.Plan === quoteSelectedValues.selectedPlan);
    const { AnnualPremium, MonthlyPremium } = selectedPlan.RatingOptions
      .find((rating) => rating.DeductibleId === quoteSelectedValues.deductibleId
        && rating.ReimbursementId === quoteSelectedValues.reimbursementId);

    return ({
      ...state,
      changeCoverage: {
        ...state.changeCoverage,
        powerUpLoadings: state.changeCoverage.powerUpLoadings
          .filter((id) => id !== payload.id),
        quoteSelectedValues: {
          ...quoteSelectedValues,
          annualAmountPlan: AnnualPremium,
          modifiers: petQuoteResponseList[0].modifiers,
          monthlyAmountPlan: MonthlyPremium,
          total: Number(quoteSelectedValues.isMonthly
            ? MonthlyPremium
            : AnnualPremium).toFixed(2),
          totalAnnual: Number(AnnualPremium).toFixed(2),
          totalMonthly: Number(MonthlyPremium).toFixed(2),
        },
      },
    });
  },
  REIMBURSEMENT_CHANGED: (state, { payload }) => ({
    ...state,
    changeCoverage: {
      ...state.changeCoverage,
      quoteSelectedValues: {
        ...state.changeCoverage.quoteSelectedValues,
        reimbursementDescription: payload.description,
        reimbursementId: payload.reimbursementId,
      },
    },
  }),
  RESTART_RATE: (state) => ({
    ...state,
    changeCoverage: {
      ...state.changeCoverage,
      initialCoverageValues: INITIAL_SELECTED_VALUES,
      loading: false,
      powerUpLoadings: [],
      quoteSelectedValues: INITIAL_SELECTED_VALUES,
      rate: {
        error: false,
        rateCreated: false,
      },
    },
  }),

  REVIEW_CHANGES_VISIBLE_CHANGED: (state, { payload }) => ({
    ...state,
    changeCoverage: {
      ...state.changeCoverage,
      reviewChangesVisible: payload,
    },
  }),
  SELECTED_PLAN_CHANGED: (state, { payload }) => ({
    ...state,
    changeCoverage: {
      ...state.changeCoverage,
      quoteSelectedValues: {
        ...state.changeCoverage.quoteSelectedValues,
        selectedAnnualBenefit: payload.selectedAnnualBenefit,
        selectedPlan: payload.planId,
      },
    },
  }),
  STORE_ALL_POLICIES: (state, { payload }) => ({
    ...state,
    allPolicies: Array.isArray(payload) ? payload : [],
    loggedInWithoutPets: payload.length === 0,
  }),
  STORE_POLICIES: (state, { payload }) => {
    const hasPolicies = Array.isArray(payload);

    return {
      ...state,
      data: hasPolicies ? payload : [],
      loading: false,
      loadingWhatWeCoverInfo: hasPolicies && hasPolicies.length > 0,
    };
  },
  SUBMIT_CHANGE_COVERAGE: (state) => ({
    ...state,
    changeCoverage: {
      ...state.changeCoverage,
      error: false,
      loadingSubmit: true,
      rate: {
        ...state.changeCoverage.rate,
        error: false,
      },
      success: false,
    },
  }),
  SUBMIT_CHANGE_COVERAGE_FAILED: (state) => ({
    ...state,
    changeCoverage: {
      ...state.changeCoverage,
      error: true,
      loadingSubmit: false,
      rate: {
        ...state.changeCoverage.rate,
        error: true,
      },
      reviewChangesVisible: false,
      success: false,
    },
  }),
  SUBMIT_CHANGE_COVERAGE_SUCCESS: (state) => ({
    ...state,
    changeCoverage: {
      ...state.changeCoverage,
      error: false,
      initialCoverageValues: INITIAL_SELECTED_VALUES,
      loadingSubmit: false,
      rate: {
        ...state.changeCoverage.rate,
        error: false,
      },
      reviewChangesVisible: false,
      success: true,
    },
  }),
  VALIDATE_CHANGE_COVERAGE: (state) => ({
    ...state,
    changeCoverageValidation: {
      ...state.changeCoverageValidation,
      isLoading: true,
    },
  }),
  VALIDATE_CHANGE_COVERAGE_CLEARED: (state) => ({
    ...state,
    changeCoverageValidation: {
      ...state.changeCoverageValidation,
      isLoading: false,
      isSuccess: false,
      isValid: false,
      message: '',
    },
  }),
  VALIDATE_CHANGE_COVERAGE_FAILED: (state, { payload }) => ({
    ...state,
    changeCoverageValidation: {
      ...state.changeCoverageValidation,
      isLoading: false,
      isSuccess: true,
      isValid: payload.isValid,
      message: payload.message,
    },
  }),
  VALIDATE_CHANGE_COVERAGE_SUCCESS: (state, { payload }) => ({
    ...state,
    changeCoverageValidation: {
      ...state.changeCoverageValidation,
      isLoading: false,
      isSuccess: true,
      isValid: payload.isValid,
      message: '',
    },
  }),
  WAITING_PERIODS_LOADED: (state, { payload }) => ({
    ...state,
    waitingPeriods: payload,
  }),
  WHAT_WE_COVER_INFO_LOADED: (state, { payload }) => ({
    ...state,
    loadingWhatWeCoverInfo: false,
    whatWeCoverInfo: payload,
  }),
  YOUNG_PET_MODAL_CLOSED: (state) => ({
    ...state,
    isYoungPetModalVisible: false,
  }),
  YOUNG_PET_MODAL_OPEN: (state) => ({
    ...state,
    isYoungPetModalVisible: true,
  }),
}, INITIAL_STATE);
