import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DrawPencil }
from '../../../../assets/claimbot/draw-pencil.svg';
import { ReactComponent as Keyboard }
from '../../../../assets/claimbot/keyboard.svg';

import { SIGNATURE_MAX_LENGTH, SIGNATURE_TYPE } from '../claimbot.constants';

import './SignAndSubmit.css';

const SignAndSubmit = ({
  onSignatureTextChanged = () => { },
  onSignatureTypeSelection = () => { },
  signature = null,
  signatureText = '',
  signatureTypeSelected,
}) => {
  const { t } = useTranslation('fileClaimbot');
  // TODO: typing feature will be displayed and worked
  // in another US.
  const onlyTypeSignagure = true;

  const renderContent = () => {
    if (signatureTypeSelected === SIGNATURE_TYPE.DRAWING) {
      return (
        <div className="File-claimbot-signature-content-container-drawing">
          <span>TODO: Drawing feature :P</span>
        </div>
      );
    }

    return (
      <div className="File-claimbot-signature-content-container-typing">
        <input
          maxLength={SIGNATURE_MAX_LENGTH}
          onChange={onSignatureTextChanged}
          value={signatureText}
        />

        {signatureText.length === SIGNATURE_MAX_LENGTH
          ? (
            <span className="File-claimbot-signature-error">
              {t('signature.maxSignatureLength', {
                maxSignatureLength: SIGNATURE_MAX_LENGTH,
              })}
            </span>
          )
          : null}

        <div className="File-claimbot-signature-row">
          <span>{t('signature.preview')}</span>

          {/* TBD: Change style feature */}
          <button className="Hidden" type="button">
            {t('signature.changeStyle')}
          </button>
        </div>

        <div className="File-claimbot-signature-text-preview-container">
          <img alt="" src={signature} />
        </div>

        <p>{t('signature.disclaimer')}</p>
      </div>
    );
  };

  return (
    <div className="File-claimbot-signature-container">
      <div className="File-claimbot-signature-selector">
        {onlyTypeSignagure ? null : (
          <button
            className={`File-claimbot-signature-type-option ${
            signatureTypeSelected === SIGNATURE_TYPE.DRAWING
              ? 'File-claimbot-signature-type-selected' : ''}`}
            onClick={onSignatureTypeSelection(SIGNATURE_TYPE.DRAWING)}
            type="button"
          >
            <DrawPencil
              className={`File-claimbot-signature-type-option-img${
              signatureTypeSelected === SIGNATURE_TYPE.DRAWING
                ? '-selected' : ''
            }`}
            />

            {t('signature.draw')}
          </button>
        )}

        <button
          className={`File-claimbot-signature-type-option ${
            signatureTypeSelected === SIGNATURE_TYPE.TYPING
              ? 'File-claimbot-signature-type-selected' : ''}`}
          onClick={onSignatureTypeSelection(SIGNATURE_TYPE.TYPING)}
          type="button"
        >
          <Keyboard
            className={`File-claimbot-signature-type-option-img${
              signatureTypeSelected === SIGNATURE_TYPE.TYPING ? '-selected' : ''
            }`}
          />

          {t('signature.type')}
        </button>
      </div>
      {renderContent()}
    </div>
  );
};

export { SignAndSubmit };
