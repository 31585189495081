import React, { useMemo } from 'react';

import { fileSizeToMB } from '../../../../services/utils';

import radioCheckIcon from '../../../../assets/claimbot/check-circle.svg';
import crossIcon from '../../../../assets/fileClaim/cross-hydrant.svg';
import deleteCross from '../../../../assets/claimbot/delete-cross.svg';

import './AttachmentItem.css';

const AttachmentItem = ({
  disabled = false,
  attachment,
  onRemoveAttachment = () => { },
  t,
}) => {
  const renderFileName = ({ Filename, Extension }) => {
    const parts = Filename.split('.');
    const name = parts.slice(0, parts.length - 1).join('.');
    return (
      <div className="File-claimbot-row-name-container">
        <span className="File-claimbot-row-name">
          {name}
        </span>

        <span className="File-claimbot-row-name File-claimbot-row-extension">
          {Extension}
        </span>
      </div>
    );
  };

  function getFileStatus() {
    switch (attachment.percentage) {
      case 100:
        if (!attachment.valid) {
          return <img alt={t('uploaded')} src={crossIcon} />;
        }

        return (
          <img alt={t('uploaded')} src={radioCheckIcon} />
        );

      case 0:
        return <>&nbsp;&#8226;&nbsp;{t('queued')}</>;

      default:
        return <>&nbsp;&#8226;&nbsp;{`${attachment.percentage}%`}</>;
    }
  }

  function renderColorBarProgres() {
    if (attachment.percentage > 50) {
      return 'var(--pc-color-leaf-400)';
    }

    return 'var(--pc-color-blue-300)';
  }

  const onDeletePress = () => {
    onRemoveAttachment(attachment);
  };

  const errorMessage = useMemo(() => {
    if (!attachment.errorMsg && !attachment.valid) {
      return t('failedUpload');
    }

    return attachment.errorMsg;
  }, [attachment.errorMsg, attachment.valid]);

  return (
    <div key={attachment.key} className="File-claimbot-row">
      <div className="File-claimbot-row-data">
        <div className="File-claimbot-row-left">
          {renderFileName(attachment)}

          <div className="File-claimbot-row-status">
            <span>{getFileStatus(attachment)}</span>
          </div>
        </div>

        <div className="File-claimbot-row-right">
          <span className="File-claimbot-row-size">
            {fileSizeToMB({ fileSize: attachment.size })} MB
          </span>

          <div className="File-claimbot-row-delete">
            <button
              className={attachment.hideDelete ? 'Gone' : ''}
              disabled={disabled}
              id={attachment.key}
              onClick={onDeletePress}
              type="button"
            >
              <img alt="" src={deleteCross} />
            </button>
          </div>
        </div>
      </div>

      <div className="File-claimbot-row-progress-bar">
        <div
          className="File-claimbot-file-progress"
          style={{
            backgroundColor: renderColorBarProgres(attachment),
            width: `${attachment.percentage}%`,
          }}
        />
      </div>

      <span
        className={`File-claimbot-row-error${
          errorMessage ? ' Margin-top-8' : ''}`}
      >
        {errorMessage}
      </span>
    </div>
  );
};

export { AttachmentItem };
