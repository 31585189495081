import { call, put } from 'redux-saga/effects';
import { get, post, del } from '../services/api';
import {
  deleteTagNumberFailed,
  deleteTagNumberRequested,
  firstStepShowed,
  petTagInfoLoaded,
  petTagInfoLoadFailed,
  petTagInfoPosted,
  petTagInfoPostFailed,
  petTagInfoPostRequested,
  petTagInfoRequested,
  tagNumberDeleted,
  tagNumberErrorMessageSetted,
} from '../actions';
import {
  useCustomerApi150617V1ForSagas,
} from '../services/featureFlagsForSagas';

const DUPLICATED_TAG_NUMBER_ERROR = 'This code already exists.\r\n';

export function* getFigoPetTagInfo(dispatch, action) {
  yield put(petTagInfoRequested());
  const petId = action.payload;
  const response = yield call(
    get,
    dispatch,
    `api/Pets/Tag/${petId}`,
  );

  if (response.success && response.IsValid) {
    yield put(petTagInfoLoaded(response.Data));
  } else {
    yield put(petTagInfoLoadFailed());
  }
}

export function* setFigoPetTagInfo(dispatch, action) {
  yield put(petTagInfoPostRequested());
  const useCustomerPets = yield useCustomerApi150617V1ForSagas();
  const urlPref = useCustomerPets ? 'customer' : 'api';

  const petTagInfo = action.payload;
  const response = yield call(
    post,
    dispatch,
    `${urlPref}/Pets/Tag`,
    petTagInfo,
  );

  if (response.success && response.IsValid) {
    yield put(petTagInfoPosted());
  } else {
    yield put(petTagInfoPostFailed());

    yield put(tagNumberErrorMessageSetted(response.Message));

    if (response.Message === DUPLICATED_TAG_NUMBER_ERROR) {
      yield put(tagNumberErrorMessageSetted(response.Message));
      yield put(firstStepShowed());
    }
  }
}

export function* deleteFigoTagNumber(dispatch, action) {
  yield put(deleteTagNumberRequested());
  const petId = action.payload;
  const url = `api/Pets/Tag/${petId}`;
  const response = yield call(del, dispatch, url);

  if (response.success && response.IsValid) {
    yield put(tagNumberDeleted());
  } else {
    yield put(deleteTagNumberFailed());
  }
}
