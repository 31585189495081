import React from 'react';

import {
  Greetings,
  PetsHealthCard,
  Resources,
} from './components';

import { useTrackOnMount } from '../../hooks';
import { EV9 } from '../../services/mixpanel';

import './HomeScreen.css';

const HomeScreen = () => {
  useTrackOnMount(EV9());

  return (
    <div className="Home-main-container">
      <Greetings />

      <PetsHealthCard />

      <Resources />
    </div>
  );
};

export { HomeScreen };
