import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  isFirstTimeOnClaims,
  petSelected,
  policySelected,
  policySelectedOnClaims,
  setIsCheckedOptionClosed,
  setIsCheckedOptionOpen,
  setSelectedPetId,
  setSelectedPetIndex,
  showAlert,
} from '../actions';

import logOutImage from '../assets/log-out.png';
import { useB2CInfo } from './useB2CInfo';

export const useLogoutSessionCleaner = () => {
  const { t } = useTranslation('settingsScreen');
  const store = useSelector(({ policies, session }) => ({ policies, session }),
    shallowEqual);
  const { impersonateCustomerId: customerId, isImpersonate } = store.session;
  const dispatch = useDispatch();
  const history = useHistory();
  const { logoutB2C } = useB2CInfo();

  const handleResetClaimsAndPolicySelections = useCallback(() => {
    dispatch(petSelected(0));

    dispatch(setSelectedPetIndex(0));

    dispatch(setSelectedPetId(0));

    dispatch(policySelected(0));

    dispatch(policySelectedOnClaims(0));

    dispatch(setIsCheckedOptionOpen(true));

    dispatch(setIsCheckedOptionClosed(true));

    dispatch(isFirstTimeOnClaims(true));
  }, [dispatch]);

  const handlePrimaryAction = useCallback(async () => {
    handleResetClaimsAndPolicySelections();

    if (customerId && isImpersonate) {
      history.replace(`/impersonate/${customerId}`);
    } else {
      history.replace('/home');
    }

    logoutB2C();
  }, [dispatch, customerId, isImpersonate, logoutB2C]);

  const logout = useCallback(() => {
    dispatch(showAlert({
      body: t('myAccountMenu.logOutModal.message'),
      image: logOutImage,
      overlayClose: true,
      primaryAction: handlePrimaryAction,
      primaryText: t('myAccountMenu.logOutModal.logOut'),
      secondaryText: t('myAccountMenu.logOutModal.cancel'),
      type: 'confirm',
    }));
  }, [dispatch]);

  return {
    logout,
  };
};
