import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import NavigationPrompt from 'react-router-navigation-prompt';

import FigoLoadingOverlay from '../../common/FigoLoadingOverlay';
import {
  AmountInput,
  AttachmentSelector,
  ClaimbotStepper,
  ClaimSelector,
  ClaimSubmitted,
  ClaimSummary,
  DateInput,
  FileClaimbotEvie,
  FinishButton,
  MultipleOptionsSelector,
  PetSelector,
  SignAndSubmit,
  TextInput,
  TextInputSingleLine,
  TwoOptionsSelector,
} from './components';
import FigoLottie from '../../common/FigoLottie';
import UnsavedChangesPrompt from '../../common/UnsavedChangesPrompt';
import { PolicySelector } from './components/PolicySelector';

import { useFileClaimbotScreen } from './hooks/useFileClaimbotScreen';

import './FileClaimbotScreen.css';

const FileClaimbotScreen = () => {
  const store = useSelector(
    ({ billingPayments }) => ({ billingPayments }),
    shallowEqual,
  );

  const {
    billingPayments: { loadingAllBrandsData },
  } = store;

  const {
    accountNumber,
    accountNumberVisible,
    agentImage,
    agentText,
    amountInput,
    amountInputVisible,
    attachments,
    attSelectorVisible,
    backButtonLabel,
    claimSelectorVisible,
    claimsGeneric,
    claimSubmittedVisible,
    claimSummaryInfo,
    claimSummaryVisible,
    claimTypes,
    claimTypeSelected,
    currentStep,
    currentStepNumber,
    dateInputVisible,
    finishButtonVisible,
    finished,
    goBackDisabled,
    goForwardisabled,
    loadingAdditionalSteps,
    loadingPolicyByDate,
    maxCoverageDate,
    minCoverageDate,
    multiOptSelectVisible,
    multipleOptions,
    nextButtonLabel,
    numberOfSteps,
    onAccountNumberChanged,
    onAddAttachment,
    onAmountInputChanged,
    onNextStepClick,
    onOneOptionSelected,
    onPetItemPress,
    onPreviousStepClick,
    onRemoveAttachment,
    onRoutingNumberChanged,
    onSelectClaimType,
    onSelectedValidDate,
    onSignatureTextChanged,
    onSignatureTypeSelection,
    onTextInputChanged,
    optionSelected,
    pets,
    petSelectorVisible,
    policyBrandName,
    routingNumber,
    routingNumberVisible,
    selectedDate,
    selectedPet,
    selectedPetPolicies,
    signAndSubmitVisible,
    signature,
    signatureText,
    signatureTypeSelected,
    submitClaim,
    t,
    textInputSingleLineVisible,
    textInputValue,
    textInputVisible,
    twoOptions,
    twoOptSelectVisible,
    unknowStepVisible,
    policySelectorVisible,
    onPolicyItemPress,
    policySelected,
  } = useFileClaimbotScreen();

  return (
    <div className="File-claimbot-main-container">
      {!finished && (
        <NavigationPrompt when={claimsGeneric.selectedPet}>
          {({ onConfirm, onCancel }) => (
            <UnsavedChangesPrompt
              containerClassName="File-claim-type-container"
              currentStep={currentStep}
              currentStepNumber={currentStepNumber}
              icon={null}
              message={t('unsaveClaimModal.content')}
              modalClassName="File-claim-unsave-modal"
              onCancel={onConfirm}
              onConfirm={onCancel}
              policyBrandName={policyBrandName}
              textCancel={t('unsaveClaimModal.cancelTextButton')}
              textConfirm={t('unsaveClaimModal.confirmTextButton')}
              title={t('unsaveClaimModal.title')}
            />
          )}
        </NavigationPrompt>
      )}

      <FigoLoadingOverlay visible={claimsGeneric.loadingSteps} />

      <div className="Screen-header-claimbot">
        <h1>{t('title')}</h1>
      </div>

      <div className="File-claimbot-content">
        <ClaimbotStepper
          backButtonLabel={backButtonLabel}
          currentStepName={t('stepTitle')}
          currentStepNumber={currentStepNumber}
          goBackDisabled={goBackDisabled}
          goForwardisabled={goForwardisabled}
          loading={submitClaim.loading || loadingAllBrandsData
            || loadingAdditionalSteps || loadingPolicyByDate}
          nextButtonLabel={nextButtonLabel}
          numberOfSteps={numberOfSteps}
          onNextStepClick={onNextStepClick}
          onPreviousStepClick={onPreviousStepClick}
          submitted={submitClaim.submitted}
        />

        <div className="card File-card-container">
          <div className="File-claim-card-body">
            <FileClaimbotEvie
              agentImage={agentImage}
              agentText={agentText}
            />

            <div className="File-claimbot-card-content">
              {finishButtonVisible ? <FinishButton /> : null}

              {petSelectorVisible ? (
                <PetSelector
                  onPetItemPress={onPetItemPress}
                  pets={pets}
                  selectedPet={selectedPet}
                />
              ) : null}

              {policySelectorVisible ? (
                <PolicySelector
                  onPolicyItemPress={onPolicyItemPress}
                  policies={selectedPetPolicies}
                  selectedPolicy={policySelected}
                />
              ) : null}

              {dateInputVisible ? (
                <DateInput
                  maxDate={maxCoverageDate}
                  minDate={minCoverageDate}
                  onSelectedValidDate={onSelectedValidDate}
                  selectedDate={selectedDate}
                />
              ) : null}

              {claimSelectorVisible ? (
                <ClaimSelector
                  claimTypes={claimTypes}
                  claimTypeSelected={claimTypeSelected}
                  onSelectClaimType={onSelectClaimType}
                />
              ) : null}

              {textInputVisible ? (
                <TextInput
                  onTextInputChanged={onTextInputChanged}
                  textInputValue={textInputValue}
                />
              ) : null}

              {textInputSingleLineVisible ? (
                <TextInputSingleLine
                  onTextInputChanged={onTextInputChanged}
                  textInputValue={textInputValue}
                />
              ) : null}

              {attSelectorVisible ? (
                <AttachmentSelector
                  attachments={attachments}
                  onAddAttachment={onAddAttachment}
                  onRemoveAttachment={onRemoveAttachment}
                />
              ) : null}

              {amountInputVisible ? (
                <AmountInput
                  number={amountInput}
                  onChange={onAmountInputChanged}
                  placeholder={t('amountInput.placeholder')}
                  title={t('amountInput.title')}
                  type="text"
                  useCurrencyFormat
                />
              ) : null}

              {routingNumberVisible ? (
                <AmountInput
                  number={routingNumber}
                  onChange={onRoutingNumberChanged}
                  placeholder={t('routingNumberInput.placeholder')}
                  title={t('routingNumberInput.title')}
                />
              ) : null}

              {accountNumberVisible ? (
                <AmountInput
                  number={accountNumber}
                  onChange={onAccountNumberChanged}
                  placeholder={t('accountNumberInput.placeholder')}
                  title={t('accountNumberInput.title')}
                />
              ) : null}

              {twoOptSelectVisible ? (
                <TwoOptionsSelector
                  onOneOptionSelected={onOneOptionSelected}
                  optionSelected={optionSelected}
                  twoOptions={twoOptions}
                />
              ) : null}

              {multiOptSelectVisible ? (
                <MultipleOptionsSelector
                  multipleOptions={multipleOptions}
                  onOneOptionSelected={onOneOptionSelected}
                  optionSelected={optionSelected}
                />
              ) : null}

              {claimSummaryVisible ? (
                <ClaimSummary
                  claimSummaryInfo={claimSummaryInfo}
                  selectedPet={selectedPet}
                />
              ) : null}

              {signAndSubmitVisible ? (
                <SignAndSubmit
                  onSignatureTextChanged={onSignatureTextChanged}
                  onSignatureTypeSelection={onSignatureTypeSelection}
                  signature={signature}
                  signatureText={signatureText}
                  signatureTypeSelected={signatureTypeSelected}
                />
              ) : null}

              {submitClaim.loading || loadingAdditionalSteps
              || loadingPolicyByDate ? <FigoLottie /> : null}

              {claimSubmittedVisible ? (
                <ClaimSubmitted
                  policyBrandName={policyBrandName}
                  selectedPolicy={policySelected}
                  success={submitClaim.success}
                />
              ) : null}
            </div>

            {unknowStepVisible
              ? (<span>UNKNOWN STEP {currentStep.KeyName}</span>) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export { FileClaimbotScreen };
