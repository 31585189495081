import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { FooterPetCloud } from '../resetPassword/FooterPetCloud';
import { HeaderPetcloud } from '../resetPassword/HeaderPetcloud';

import petCollage from '../../assets/downloadMobileApps/pet-collage.svg';
import playStore from '../../assets/google-play-navy.svg';
import appStore from '../../assets/apple-store-navy.svg';
import petcloudQrCode
from '../../assets/downloadMobileApps/petcloud-qr-code.png';
import checkArrow from '../../assets/downloadMobileApps/check-arrow-white.png';

import FigoLottie from '../common/FigoLottie';
import { APP_STORE_LINK, GOOGLE_PLAY_LINK } from '../../constants';
import { sendLinkMessage } from '../../actions';
import { maskPhoneNumber, validateNumbersOnly } from '../../services/utils';

import { removeAllData } from '../../services/api';

import './DownloadMobileApplications.css';

const DownloadMobileApplications = () => {
  const { t } = useTranslation('common');
  const [phoneNumber, setPhoneNumber] = useState('');
  const dispatch = useDispatch();
  const store = useSelector(({ common }) => ({ common }), shallowEqual);
  const history = useHistory();

  const {
    common: {
      comingSoon: {
        isSendingMessage,
        messageSent,
      },
    },
  } = store;

  useEffect(() => {
    removeAllData();
  }, []);

  function onPhoneNumberChanged(event) {
    const { target: { value } } = event;
    const cleanText = value ? value.replace(/[()-\s]/g, '') : '';
    const onlyNumbersValue = validateNumbersOnly(cleanText)
      ? cleanText
      : cleanText.replace(/\D/g, '');

    setPhoneNumber(maskPhoneNumber(onlyNumbersValue));
  }

  function onLogoutPress() {
    history.replace('/');
  }

  function onTextMeALinkPress() {
    if (isSendingMessage || !phoneNumber) {
      return;
    }
    const toPhone = phoneNumber.replaceAll('(', '').replaceAll(')', '');

    dispatch(sendLinkMessage({ toPhone }));
  }

  function renderContentButton() {
    if (messageSent) {
      return (
        <>
          <img alt="" className="Download-apps-arrow-check" src={checkArrow} />

          {t('downloadMobileApplications.messageSent')}
        </>
      );
    }

    if (isSendingMessage) {
      return <FigoLottie height={30} width={30} />;
    }

    return t('downloadMobileApplications.textMeALink');
  }

  return (
    <div className="Download-apps-main-container">
      <HeaderPetcloud
        logoutLabel={t('downloadMobileApplications.logoutLabel')}
        onLogoutClick={onLogoutPress}
        showLogout
      />

      <div className="Download-apps-card-container">
        <div className="Download-apps-card">
          <img
            alt=""
            className="Download-apps-pet-collage-image"
            src={petCollage}
          />

          <h1>{t('downloadMobileApplications.welcome')}</h1>

          <span>{t('downloadMobileApplications.description')}</span>

          <div className="Download-apps-icon-store-container">
            <a
              href={GOOGLE_PLAY_LINK}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                alt="play store"
                className="Download-apps-icon-store"
                src={playStore}
              />
            </a>

            <a
              href={APP_STORE_LINK}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                alt=""
                className="Download-apps-icon-store"
                src={appStore}
              />
            </a>
          </div>

          <img
            alt=""
            className="Download-apps-qr-code"
            src={petcloudQrCode}
          />

          <input
            className="Download-apps-phone-number-input"
            onChange={onPhoneNumberChanged}
            placeholder={t('downloadMobileApplications.phoneNumberPlaceholder')}
            value={phoneNumber}
          />

          <button
            className="Download-apps-submit-button"
            onClick={onTextMeALinkPress}
            type="button"
          >
            {renderContentButton()}
          </button>
        </div>
      </div>

      <FooterPetCloud showStoreIcons={false} />
    </div>
  );
};

export { DownloadMobileApplications };
