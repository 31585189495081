import { handleActions } from 'redux-actions';

const INITIAL_STATE = {
  pageHasChanges: false,
  petCloudAccount: {
    loginEmail: '',
  },
  showConfirmChangesModal: false,
  showEmailChangeModal: false,
  showForgotPasswordModal: false,
  showUnsavedModal: false,
};

export default handleActions({
  DISPLAY_CONFIRM_CHANGES_MODAL: (state, { payload }) => ({
    ...state,
    showConfirmChangesModal: payload.showConfirmChangesModal,
  }),
  DISPLAY_EMAIL_CHANGE_MODAL: (state, { payload }) => ({
    ...state,
    showEmailChangeModal: payload.showEmailChangeModal,
  }),
  DISPLAY_FORGOT_PASSWORD_MODAL: (state, { payload }) => ({
    ...state,
    showForgotPasswordModal: payload.showForgotPasswordModal,
  }),
  DISPLAY_UNSAVED_MODAL: (state, { payload }) => ({
    ...state,
    showUnsavedModal: payload.showUnsavedModal,
  }),
  MY_ACCOUNT_EMAIL_CHANGED: (state, { payload }) => ({
    ...state,
    petCloudAccount: {
      ...state.petCloudAccount,
      loginEmail: payload.value,
    },
  }),
  MY_ACCOUNT_HAS_CHANGES: (state, { payload }) => ({
    ...state,
    pageHasChanges: payload.pageHasChanges,
  }),
}, [INITIAL_STATE]);
