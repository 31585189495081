import { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

const useAlphabeticalSortedPets = () => {
  const store = useSelector(({ policies }) => ({ policies }), shallowEqual);

  const alphabeticalSortedPets = useMemo(() => store.policies
    .allPets.sort((previousPet, nextPet) => {
      if (previousPet.Name > nextPet.Name) {
        return 1;
      }

      if (nextPet.Name > previousPet.Name) {
        return -1;
      }

      return 0;
    }).sort((previousPet, nextPet) => {
      const prevStatus = !!(previousPet.policies.length
        && previousPet.policies[0].Number);
      const nextStatus = !!(nextPet.policies.length
        && nextPet.policies[0].Number);
      return nextStatus - prevStatus;
    }), [store.policies.allPets]);

  return alphabeticalSortedPets;
};

export { useAlphabeticalSortedPets };
