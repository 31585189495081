import React from 'react';
import Lottie from 'react-lottie';
import loadingSpinner from '../../assets/lottieFiles/loading-blue.json';

const FigoLottie = ({
  animationData = loadingSpinner,
  height = 30,
  loop = true,
  width = 30,
}) => {
  const defaultOptions = {
    animationData,
    autoplay: true,
    loop,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Lottie
      height={height}
      isClickToPauseDisabled
      options={defaultOptions}
      width={width}
    />
  );
};

export default FigoLottie;
