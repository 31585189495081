import React from 'react';
import { useTranslation } from 'react-i18next';

import './HoursOfOperationTitle.css';

const HoursOfOperationTitle = () => {
  const { t } = useTranslation('sideMenu');

  return (
    <span className="Contact-us-hours-operation">
      {t('contactUs.hoursOfOperation')}
    </span>
  );
};

export { HoursOfOperationTitle };
