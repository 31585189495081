import { createActions } from 'redux-actions';

const actions = createActions({
  CLEAR_ALL_CUSTOMER_CACHE: () => { },
  CLEAR_USER_CACHE: () => { },
  DISMISS_ALERT: () => ({}),
  GENERAL_DATA_LOADED: (data) => data,
  GET_GENERAL_DATA: () => { },
  GET_PUBLIC_SIDE_IMAGE: () => { },
  GET_SHARE_POST_DATA: (data) => data,
  GET_SHARE_POST_DATA_FAILED: () => { },
  GET_SHARE_POST_DATA_SUCCESS: (data) => data,
  LINK_MESSAGE_SENT: (data) => (data),
  PUBLIC_SIDE_IMAGE_LOADED: (data) => (data),
  SEND_LINK_MESSAGE: (data) => (data),
  SEND_MESSAGE_RESTARTED: () => ({}),
  SHOW_ALERT: (data) => ({ data }),
  SHOW_GENERIC_ERROR: (data) => data,
});

export const {
  clearAllCustomerCache,
  clearUserCache,
  dismissAlert,
  generalDataLoaded,
  getGeneralData,
  getPublicSideImage,
  getSharePostData,
  getSharePostDataFailed,
  getSharePostDataSuccess,
  linkMessageSent,
  publicSideImageLoaded,
  sendLinkMessage,
  sendMessageRestarted,
  showAlert,
  showGenericError,
} = actions;
