import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual } from 'react-redux';
import TouchableDiv from '../../common/TouchableDiv';

const CancelButton = ({ handleShowModal }) => {
  const { t } = useTranslation('claims');
  const store = useSelector(({ fileClaims }) => ({ fileClaims }), shallowEqual);
  const { fileClaims: { uploadAttSuccess } } = store;

  return (
    <TouchableDiv
      className="Input-btn Btn-cancel"
      id="btnCancel"
      onClick={handleShowModal}
    >
      <span className="No-clicked">
        {uploadAttSuccess ? t('fileTxtClose') : t('fileTxtCancel')}
      </span>
    </TouchableDiv>
  );
};

export { CancelButton };
