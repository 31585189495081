import React, { useEffect } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

import { LoginScreen } from '../login/LoginScreen';
import { FoundLostPet } from '../foundLostPet/FoundLostPet';
import GenericError from '../genericError/GenericError';
import {
  UpdatePaymentMethodScreen,
} from '../updatePaymentMethod/UpdatePaymentMethodScreen';
import RedirectCustomerId from './RedirectCustomerId';
import { LoginMobile } from '../login/LoginMobile';
import { ChangeCoverageScreen } from '../changeCoverage/ChangeCoverageScreen';
import { SharePost } from '../sharePost/SharePost';
import {
  DownloadMobileApplications,
} from '../downloadMobileApplications/DownloadMobileApplications';

import * as routes from './routes.constants';
import { isMobile } from '../../services/utils';

const PublicRouter = (props) => {
  const showGenericError =
    useSelector(({ common }) => (common.showGenericError), shallowEqual);

  const mobile = React.useMemo(() => isMobile(), []);

  useEffect(() => {
    if (showGenericError) {
      props.history.push(routes.ERROR);
    }
  }, [showGenericError]);

  return (
    <Switch>
      <Route
        exact
        onEnter={window.location.reload}
        path={routes.IOS_URL}
      />

      <Route exact path="/">
        {mobile ? <LoginMobile /> : <LoginScreen />}
      </Route>

      <Route path={routes.B2C_TOKEN_REGISTER}>
        <LoginScreen registerToken />
      </Route>

      <Route exact path={routes.REDIRECT}>
        <LoginScreen />
      </Route>

      <Route exact path={routes.LOGIN}>
        <RedirectCustomerId />
      </Route>

      <Route path={routes.IMPERSONATE}>
        <LoginScreen isImpersonate />
      </Route>

      <Route exact path={routes.FOUND_LOST_PET}>
        <FoundLostPet />
      </Route>

      <Route path={routes.ERROR}>
        <GenericError />
      </Route>

      <Route path={`${routes.POST_SHARE}/:postId`}>
        <SharePost />
      </Route>

      <Route path={routes.CHANGE_COVERAGE}>
        <ChangeCoverageScreen />
      </Route>

      <Route path={routes.PAYMENT_METHOD_EDIT}>
        <UpdatePaymentMethodScreen />
      </Route>

      <Route path={routes.DOWNLOAD_MOBILE_APPS}>
        <DownloadMobileApplications />
      </Route>

      <Route path="*">
        {mobile ? <LoginMobile /> : <Redirect to="/" />}
      </Route>
    </Switch>
  );
};

export default withRouter(PublicRouter);
