import React, { useCallback, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  firstnameChanged,
  lastnameChanged,
  myAccountHasChanges,
  refreshPersonalInformation,
  updateNames,
} from '../../../../actions';

import { PetCloudInputAnimated }
from '../../../common/petCloud/PetCloudInputAnimated';

import { ActionButton } from '../../../common/petCloud/ActionButton';

import { removeEmojis } from '../../../../services/utils';

import '../css/PetCloudEdit.css';

const PetCloudNameEdit = ({
  initialValue = { firstName: '', lastName: '' },
  onClickCancel = () => { },
}) => {
  const dispatch = useDispatch();
  const store = useSelector(({ accountLogin }) => ({
    accountLogin,
  }), shallowEqual);
  const { accountLogin: { namesUpdateError } } = store;
  const { t } = useTranslation('myAccount');
  const [firstNameValue, setFirstNameValue] = useState(initialValue.firstName);
  const [lastNameValue, setLastNameValue] = useState(initialValue.lastName);
  const [isLoading, setIsLoading] = useState(false);

  const enableButton = useMemo(() => {
    if (firstNameValue && lastNameValue) {
      return firstNameValue !== initialValue.firstName
        || lastNameValue !== initialValue.lastName;
    }

    return false;
  }, [
    firstNameValue,
    initialValue,
    initialValue,
    lastNameValue,
  ]);

  const handleFirstNameChanged = useCallback(({ target }) => {
    const { value } = target;
    const cleanValue = removeEmojis(value);

    setFirstNameValue(cleanValue);
    dispatch(firstnameChanged(cleanValue));

    if (cleanValue !== initialValue.firstName) {
      dispatch(myAccountHasChanges({ pageHasChanges: true }));
    }
  }, []);

  const handleLastNameChanged = useCallback(({ target }) => {
    const { value } = target;
    const cleanValue = removeEmojis(value);

    setLastNameValue(cleanValue);
    dispatch(lastnameChanged(cleanValue));

    if (cleanValue !== initialValue.lastName) {
      dispatch(myAccountHasChanges({
        pageHasChanges: true,
      }));
    }
  }, []);

  function handleNamesUpdated() {
    setIsLoading(false);
    onClickCancel();
    dispatch(refreshPersonalInformation());
    dispatch(myAccountHasChanges({ pageHasChanges: false }));
  }

  const handleSaveClick = useCallback(() => {
    setIsLoading(true);
    dispatch(updateNames({
      successCallback: handleNamesUpdated,
    }));
  }, []);

  const handleShowConfirmChangesModal = useCallback(() => {
    handleSaveClick();
  }, []);

  return (
    <div className="Pet-cloud-edit-container">
      <span>{t('petCloudSection.nameEdit.description')}</span>

      <div className="Pet-cloud-edit-input-container">
        <PetCloudInputAnimated
          inputClass="Pet-cloud-name-edit-input"
          onChange={handleFirstNameChanged}
          placeholder={t('petCloudSection.nameEdit.firstName')}
          value={firstNameValue}
        />

        <PetCloudInputAnimated
          inputClass="Pet-cloud-name-edit-input"
          onChange={handleLastNameChanged}
          placeholder={t('petCloudSection.nameEdit.lastName')}
          value={lastNameValue}
        />
      </div>

      <span className="Error-label">{namesUpdateError}</span>

      <div className="Pet-cloud-edit-save-button">
        <ActionButton
          disabled={!enableButton}
          loading={isLoading}
          onClick={handleShowConfirmChangesModal}
          title={t('petCloudSection.save')}
        />
      </div>
    </div>
  );
};

export { PetCloudNameEdit };
