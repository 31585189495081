import React from 'react';
import { useTranslation } from 'react-i18next';

import emailIcon from '../../assets/login/email.svg';
import appleStoreImg from '../../assets/login/apple-store.svg';
import googleStoreImg from '../../assets/login/playstore.svg';
import './FooterPetCloud.css';
import { APP_STORE_LINK, GOOGLE_PLAY_URL } from '../../constants';

const FooterPetCloud = ({ showStoreIcons = true }) => {
  const { t } = useTranslation('common');

  return (
    <div className="Footer-pet-cloud-container">
      <div className="d-flex Flex-column">
        <span>{t('resetPassword.reasetFooter.haveQuestion')}</span>

        <div className="d-flex">
          <span>
            <img
              alt=""
              className="Footer-pet-cloud-email-icon"
              src={emailIcon}
            />
            {t('resetPassword.reasetFooter.email')}
          </span>
        </div>
      </div>

      <div className="d-flex Flex-column Footer-pet-cloud-div-right">
        {showStoreIcons && (
          <>
            <span className="Footer-pet-cloud-download-title">
              {t('resetPassword.reasetFooter.downloadApp')}
            </span>

            <div className="d-flex Footer-pet-cloud-stores">
              <a href={APP_STORE_LINK}>
                <img alt="apple store" src={appleStoreImg} />
              </a>

              <a href={GOOGLE_PLAY_URL}>
                <img alt="google play store" src={googleStoreImg} />
              </a>
            </div>
          </>
        )}

        <span className="Footer-pet-cloud-download-email">
          {t('resetPassword.reasetFooter.copyright')}
        </span>
      </div>
    </div>
  );
};

export { FooterPetCloud };
