import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { usePetPolicies } from '../../../hooks/usePetPolicies';

import checkIcon from '../../../assets/policy/check-green.svg';

import Shimmer from '../../common/Shimmer';

import { currencyFormat } from '../../../services/utils';
import './css/CoverageFigoProgressBar.css';

const CoverageFigoProgressBar = ({
  loadingOverview = false,
  policies = [],
  selectedPolicy = {},
}) => {
  const { t } = useTranslation('insuranceScreen');
  const petPolicies = usePetPolicies();
  const {
    loading,
    unPolicyPet,
    policyCancelled,
  } = petPolicies;

  const overviewValue = useMemo(() => {
    const { policiesOverView } = policies;
    if (loading || loadingOverview || !policiesOverView
      || !selectedPolicy || unPolicyPet()) {
      return {
        AnnualBenefit: 0,
        AvailableBenefits: 0,
        benefitsValue: 0,
        deductibleCoverage: 0,
        deductibleMet: false,
        deductiblePaid: 0,
        deductiblePaidPercentage: 0,
      };
    }

    const { PolicyDisplayNumber } = selectedPolicy;

    const policyOverView = policiesOverView
      .find((policy) => policy
        .PetInformation.PolicyNumber === PolicyDisplayNumber);

    if (policyOverView) {
      const {
        AnnualBenefit,
        AvailableBenefits,
        DeductibleBalance,
        IsUnlimited,
      } = policyOverView;
      let deductiblePaid = selectedPolicy.Deductible - DeductibleBalance;
      deductiblePaid = deductiblePaid < 0 ? 0 : deductiblePaid;

      const deductibleCoverage = selectedPolicy.Deductible;
      const deductiblePaidPercentage = (deductiblePaid * 100)
        / deductibleCoverage;

      return {
        AnnualBenefit,
        AvailableBenefits,
        benefitsValue:
          (((AnnualBenefit - AvailableBenefits) * 100)
            / AnnualBenefit),
        deductibleCoverage: selectedPolicy.Deductible,
        deductibleMet: deductiblePaidPercentage === 100,
        deductiblePaid,
        deductiblePaidPercentage,
        isUnlimited: IsUnlimited,
      };
    }

    return {
      AnnualBenefit: 0,
      AvailableBenefits: 0,
      benefitsValue: 0,
      deductibleCoverage: 0,
      deductibleMet: false,
      deductiblePaid: 0,
      deductiblePaidPercentage: 0,
    };
  }, [policies, loading, selectedPolicy]);

  const {
    AnnualBenefit,
    AvailableBenefits,
    benefitsValue,
    deductibleCoverage,
    deductibleMet,
    deductiblePaid,
    deductiblePaidPercentage,
    isUnlimited,
  } = overviewValue;

  const fullBenefit = useMemo(() => (
    isUnlimited ? false : benefitsValue === 100
  ), [AvailableBenefits, isUnlimited]);

  if (policyCancelled()) {
    return null;
  }

  function renderShimmer() {
    return (
      <div className="Coverage-overview-figo-progress-container">
        <Shimmer className="Coverage-overview-progress-shimmer" visible />

        <Shimmer className="Coverage-overview-progress-shimmer" visible />
      </div>
    );
  }

  return loading || loadingOverview ? renderShimmer() : (
    <div className="Coverage-overview-figo-progress-container">
      <div className="Coverage-overview-figo-progress-row">
        <span className="Coverage-overview-figo-progress-title">
          {t('benefitUsed')}
        </span>

        <span className="Coverage-overview-figo-progress-values">
          {isUnlimited ? t('unlimited')
            : `${currencyFormat({
              fixed: 0,
              value: AnnualBenefit - AvailableBenefits,
            })}`
            + `/$${selectedPolicy.Benefit}`}
        </span>
      </div>

      <div className="Coverage-overview-progress">
        <div
          className={`Coverage-overview-progress-fill${isUnlimited
            ? '-unlimited' : ''}`}
          style={{ width: `${isUnlimited ? '100' : benefitsValue}%` }}
        />
      </div>

      {fullBenefit ? (
        <div className="Coverage-overview-progress-full">
          <img alt="" src={checkIcon} />

          <span>{t('fullBenefitUsed')}</span>
        </div>
      ) : null}

      <div className="Coverage-overview-figo-progress-row-deductible">
        <span className="Coverage-overview-figo-progress-title">
          {t('deductiblePaid')}
        </span>

        <span className="Coverage-overview-figo-progress-values">
          {`${currencyFormat({ fixed: 0, value: deductiblePaid })}`}
          /{`${currencyFormat({ fixed: 0, value: deductibleCoverage })}`}
        </span>
      </div>

      <div className="Coverage-overview-progress">
        <div
          className="Coverage-overview-progress-fill"
          style={{ width: `${deductiblePaidPercentage}%` }}
        />
      </div>

      {deductibleMet ? (
        <div className="Coverage-overview-progress-full">
          <img alt="" src={checkIcon} />

          <span>{t('deductibleMetText')}</span>
        </div>
      ) : null}
    </div>
  );
};

export { CoverageFigoProgressBar };
