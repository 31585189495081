import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import ModalContainer from '../../common/ModalContainer';
import crossImage from '../../../assets/myPets/cross-icon.svg';
import FigoInput from '../../common/FigoInput';
import FigoSelect from '../../common/FigoSelect';
import {
  microchipModalVisible,
  saveMicrochip,
  loadPetProfile,
} from '../../../actions';
import LoadingButton from '../../common/LoadingButton';
import { removeEmojis } from '../../../services/utils';
import './MicroChipModal.css';

const MicroChipModal = () => {
  const { t } = useTranslation('myPets');
  const dispatch = useDispatch();
  const store = useSelector(({ myPets }) => ({ myPets }), shallowEqual);
  const { myPets } = store;
  const {
    loading,
    microchipCompanies,
    microchipData,
    showMicrochipModal,
    success,
  } = myPets;
  const { ChipId, MicrochipId, OrderId } = microchipData;
  const [chipID, setChipID] = useState('');
  const companyIdState = !ChipId ? '' : MicrochipId;
  const [companyID, setCompanyID] = useState(1);
  const disabledButton = !chipID || !companyID;

  const setInitialData = useCallback(() => {
    setChipID(ChipId);
    setCompanyID(companyIdState);
  }, [microchipData]);

  useEffect(() => {
    setInitialData();
  }, [setInitialData]);

  function onClose() {
    dispatch(microchipModalVisible({ isVisible: false }));
    setInitialData();
  }

  function renderCompanyListWithoutDefaultCompany() {
    return [
      <option key="default" value="">
        Select..
      </option>,
      ...microchipCompanies?.map((company) => (
        <option key={company.Id} value={company.Id}>
          {company.ListName}
        </option>
      )),
    ];
  }

  function handleChipIDChange(event) {
    const { target: { value } } = event;

    setChipID(removeEmojis(value));
  }

  function handleCompanySelect(event) {
    const { target: { value } } = event;

    setCompanyID(value);
  }

  function handleSaveChip(event) {
    event.preventDefault();

    dispatch(saveMicrochip({
      ...microchipData,
      ChipId: chipID.replace(/\s/g, ''),
      MicrochipId: companyID,
      OrderId: parseInt(OrderId, 10),
    }));
  }

  function handleSaveChipSuccess() {
    dispatch(loadPetProfile());
    dispatch(microchipModalVisible({ isVisible: false }));
  }

  function renderMicrochipModal() {
    return (
      <div className="Microchip-modal-container">
        <button
          aria-label="Close Modal"
          className="Microchip-modal-cross-button"
          onClick={onClose}
          type="button"
        >
          <img alt={t('MicroChip.closeModal')} src={crossImage} />
        </button>

        <div className="Microchip-modal-content">
          <h2 className="Microchip-modal-title">
            {t('MicroChip.pageTitle')}
          </h2>

          <p className="Microchip-modal-subtitle">
            {t('MicroChip.microchipDetails')}
          </p>

          <Form
            className="Microchip-form-container Flex Flex-column"
            onSubmit={handleSaveChip}
          >
            <FigoSelect
              controlId="microchipCompanies"
              custom
              onChange={handleCompanySelect}
              renderOptionList={renderCompanyListWithoutDefaultCompany}
              title={t('MicroChip.microchipCompany')}
              value={companyID}
            />

            <FigoInput
              controlId="chipID"
              inputName="chipID"
              maxLength={50}
              onChange={handleChipIDChange}
              placeholder={t('MicroChip.chipIdInputPlaceholder')}
              title={t('MicroChip.microchipID')}
              value={chipID}
            />

            <div className="Microchip-modal-buttons-container">
              <LoadingButton
                className="Microchip-modal-save-button"
                disabled={disabledButton}
                loading={loading}
                onClick={handleSaveChip}
                onSuccessAnimationEnd={handleSaveChipSuccess}
                success={success}
                text={t('MicroChip.add')}
              />

              <button
                className="Microchip-modal-cancel-button"
                onClick={onClose}
                type="button"
              >
                {t('MicroChip.cancel')}
              </button>
            </div>
          </Form>
        </div>
      </div>
    );
  }

  return (
    <ModalContainer
      contentStyle="Modal-content-container Flex"
      show={showMicrochipModal}
    >
      {renderMicrochipModal()}
    </ModalContainer>
  );
};

export { MicroChipModal };
