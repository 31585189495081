import React, { useCallback, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';

import { INSURANCE } from '../navigation/routes.constants';
import { getMultibrandPolicies } from '../../actions';
import { MULTIBRAND_POLICY_STATUS } from '../../constants';
import { MARKETING_CHANNEL_ID } from '../../theme/theme.constants';
import { useAlphabeticalSortedPets } from '../../hooks';

import { PetCloudBreadcrumbs } from '../myPets/common/PetCloudBreadcrumbs';
import { CurrentPetPolicy } from './CurrentPetPolicy';
import { PolicyListItem } from '../common/PolicyListItem';
import FigoLottie from '../common/FigoLottie';

import './OtherPolicies.css';

const OtherPolicies = () => {
  const { t } = useTranslation('insuranceScreen');
  const { petId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector(({ policies }) => ({ policies }), shallowEqual);
  const {
    policies: {
      loading,
      multibrandPolicies,
    },
  } = store;

  const getAlphabeticalSortedPets = useAlphabeticalSortedPets();

  const currentPet = useMemo(() => {
    if (!multibrandPolicies || !multibrandPolicies.length) {
      return {};
    }

    const petFound = multibrandPolicies
      .find((pet) => pet.PetId === Number(petId));

    // Map Figo policies
    const petData = getAlphabeticalSortedPets
      ?.find((item) => item.PetId === +petId);
    const petPoliciesData = petData?.policies.map((policy) => {
      const petFoundPoliciesIds = petFound.Policies
        .map((item) => item.PolicyNumber);

      if (petFoundPoliciesIds.includes(policy.Number)) {
        return {};
      }

      return ({
        EffectiveOn: policy.CoverageStartDate,
        ExpiresOn: policy.ExpirationDate,
        PolicyMarketingChannelId: MARKETING_CHANNEL_ID.figo,
        PolicyNumber: policy.Number,
        PolicyStatusDisplay: Object
          .values(MULTIBRAND_POLICY_STATUS)[policy.Status],
      });
    }).filter((petPolicy) => petPolicy.PolicyNumber) || [];

    const allPolicies = petFound ? [
      ...petFound.Policies,
      ...petPoliciesData,
    ] : [...petPoliciesData];

    return {
      ...petFound,
      BreedName: petFound?.Breed || '',
      ImageUrl: petFound?.ProfileUrl || '',
      Name: petFound?.PetName || '',
      policies: allPolicies,
    };
  }, [petId, multibrandPolicies]);

  useEffect(() => {
    if (!multibrandPolicies.length) {
      dispatch(getMultibrandPolicies());
    }
  }, [dispatch]);

  useEffect(() => {
    if (!currentPet.Name && !loading) {
      history.replace(INSURANCE);
    }
  }, [currentPet]);

  const goToPolicy = useCallback((policyId) => () => {
    history.push(`${INSURANCE}/${petId}/${policyId}`);
  }, []);

  if (!currentPet.policies) {
    return (
      <div className="Margin-top-20-percent">
        <FigoLottie
          height={60}
          width={60}
        />
      </div>
    );
  }

  return (
    <div className="Policy-summary-main-container">
      <PetCloudBreadcrumbs
        activeRoute={currentPet?.Name}
        rootRoute={t('title')}
        rootRouteLink={INSURANCE}
      />

      <div className="Policy-summary-title-page-container">
        <h1>{t('title')}</h1>
      </div>

      <div className="Other-policies-container">
        <h2>{t('myPet')}</h2>

        <CurrentPetPolicy currentPet={currentPet} />

        <h2>{t('insurancePolicies')}</h2>

        <p>{t('selectPolicy')}</p>

        <ul className="Other-policies-list">
          {currentPet.policies.map((policy) => (
            <PolicyListItem
              key={policy.PolicyNumber}
              endsOn={policy.ExpiresOn}
              marketingChannel={policy.PolicyMarketingChannelId}
              onClick={goToPolicy(policy.PolicyMarketingChannelId
                !== MARKETING_CHANNEL_ID.figo
                ? policy.PolicyId : policy.PolicyNumber)}
              policyNumber={policy.PolicyNumber}
              startsOn={policy.EffectiveOn}
              status={policy.PolicyStatusDisplay}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export { OtherPolicies };
