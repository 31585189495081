import { createActions } from 'redux-actions';

const actions = createActions({
  CREATE_ONE_INC_SESSION: () => { },
  ONE_INC_DEFAULTS_PAYMENT_FAILED: (data) => data,
  ONE_INC_DEFAULTS_PAYMENT_LOADED: (data) => data,
  ONE_INC_LOAD_DEFAULT_PAYMENTS: () => { },
  ONE_INC_PAYMENT_BANK_SAVED: (data) => data,
  ONE_INC_PAYMENT_CREDIT_CARD_SAVED: (data) => data,
  ONE_INC_PAYMENT_RESET: () => { },
  ONE_INC_SESSION_CREATED: (data) => data,
  ONE_INC_SESSION_REMOVED: () => { },
});

export const {
  createOneIncSession,
  oneIncDefaultsPaymentFailed,
  oneIncDefaultsPaymentLoaded,
  oneIncLoadDefaultPayments,
  oneIncPaymentBankSaved,
  oneIncPaymentCreditCardSaved,
  oneIncPaymentReset,
  oneIncSessionCreated,
  oneIncSessionRemoved,
} = actions;
