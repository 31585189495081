import { combineReducers } from 'redux';
import accountLogin from './accountLogin';
import billingPayments from './billingPayments';
import claims from './claims';
import claimsGeneric from './claimsGeneric';
import common from './common';
import editPayment from './editPayment';
import figoPetTag from './figoPetTag';
import fileClaims from './fieleClaims';
import foundPetLost from './foundLostPet';
import home from './home';
import inboxAndDocs from './inboxAndDocs';
import multibrand from './multibrand';
import myAccount from './myAccount';
import myPets from './myPets';
import myVets from './myVets';
import oneInc from './oneInc';
import orcaInsurance from './orcaInsurance';
import personalInformation from './personalInformation';
import policies from './policies';
import resetPassword from './resetPassword';
import session from './session';
import settings from './settings';

export default combineReducers({
  accountLogin,
  billingPayments,
  claims,
  claimsGeneric,
  common,
  editPayment,
  figoPetTag,
  fileClaims,
  foundPetLost,
  home,
  inboxAndDocs,
  multibrand,
  myAccount,
  myPets,
  myVets,
  oneInc,
  orcaInsurance,
  personalInformation,
  policies,
  resetPassword,
  session,
  settings,
});
