import { useEffect } from 'react';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { useQuery } from './useQuery';
import {
  getRouteParams,
  base64ToObject,
  objectToBase64,
  toPascalCase,
} from '../services/utils';

const useBreadcrumbNavigation = ({
  navigationParam,
  homeData = { id: 0 },
  routeFormat,
  navTree = null,
}) => {
  const query = useQuery();
  const match = useRouteMatch();
  const history = useHistory();
  const location = useLocation();
  const navData = base64ToObject(query.get(navigationParam), []);
  const urlParams = getRouteParams(routeFormat);

  function onBreadcrumbItemClick(newItem) {
    const newIndex = navData.findIndex((item) => item.id === newItem.id);
    const newDataObj = navData.slice(0, newIndex + 1);
    let search = '';
    if (newDataObj.length > 1) {
      const newData = objectToBase64(newDataObj);
      search = `?${navigationParam}=${newData}`;
    }
    if (homeData.id === newItem.id) {
      history.push(`${match.path}`);
    } else {
      const newRoute = `${match.path}/${urlParams
        .map((param) => newItem[param]).join('/')}`;
      history.push(`${newRoute}${search}`);
    }
  }

  function onAddBreadcrumbItem(newItem, replace = false) {
    let itemsToAdd = [];
    if (!navData.length) {
      const item = {};
      urlParams.forEach((param) => {
        item[param] = homeData[param];
      });
      itemsToAdd = itemsToAdd.concat(item);
    }

    if (newItem) {
      itemsToAdd = itemsToAdd.concat(newItem);
    }

    const newDataObj = navData.concat(...itemsToAdd);
    const newData = objectToBase64(newDataObj);
    const search = `?${navigationParam}=${newData}`;

    if (replace) {
      history.replace(`${location.pathname}${search}`);
    } else {
      const newRoute = `${match.path}/${urlParams
        .map((param) => newItem[param]).join('/')}`;
      history.push(`${newRoute}${search}`);
    }
  }

  function findItem({ allParents = false, array, searchId }) {
    let newTree = [];
    array.forEach((item) => {
      if (String(item.Id) === String(searchId)) {
        newTree = newTree.concat(item);
        // TODO: Remove 'Childrens' validation when v2 is complete
      } else if ((item?.Childrens && item?.Childrens.length)
        || (item?.Folders && item?.Folders.length)) {
        const foundItems = findItem({
          allParents,
          array: item?.Childrens || item?.Folders,
          searchId,
        });
        newTree = [
          ...newTree,
          ...foundItems,
        ];
        if (foundItems.length && allParents) {
          newTree = [...newTree, item];
        }
      }
    });
    return newTree;
  }

  function createRoute(source, searchId) {
    return findItem({ allParents: true, array: source, searchId }).reverse();
  }

  function onDirectNavigation(newDestinyItem) {
    const newNavDataItems = createRoute(navTree, newDestinyItem.id);
    let newNavData = [];
    const homeItem = {};
    urlParams.forEach((param) => {
      homeItem[param] = homeData[param];
    });
    const newItems = newNavDataItems.map((item) => {
      const newItem = {};
      urlParams.forEach((param) => {
        newItem[param] = item[toPascalCase(param)];
      });
      return newItem;
    });
    newNavData = [homeItem, ...newItems];

    const newData = objectToBase64(newNavData);
    const search = `?${navigationParam}=${newData}`;

    const lastItem = newNavData[newNavData.length - 1];
    const newRoute = `${match.path}/${urlParams
      .map((param) => lastItem[param]).join('/')}`;
    history.push(`${newRoute}${search}`);
  }

  useEffect(() => {
    if (!navData.length) {
      onAddBreadcrumbItem(null, true);
      if (!match.isExact) {
        const newItem = {};
        const replacement = location.pathname.replace(`${match.path}/`, '');
        const currentValues = replacement.split('/');
        urlParams.forEach((param, index) => {
          newItem[param] = currentValues[index];
        });
        onAddBreadcrumbItem(newItem, true);
      }
    }
  }, []);

  return {
    navData,
    onAddBreadcrumbItem,
    onBreadcrumbItemClick,
    onDirectNavigation,
  };
};

export { useBreadcrumbNavigation };
