import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import TouchableDiv from '../../../common/TouchableDiv';
import { doNothing } from '../../../../services/utils';

import trashIcon from '../../../../assets/inboxDocsMultibrand/trash-white.svg';

import './PermanentlyDeleteButton.css';

const PermanentlyDeleteButton = ({
  isDisabled = true,
  onPermanentlyDeleteClick = () => { },
}) => {
  const { t } = useTranslation('inboxAndDocs');
  const buttonStyling = useMemo(() => {
    const enabled = 'Permanently-delete-button-container';
    const disabled = 'Permanently-delete-button-container-disabled';

    return isDisabled ? disabled : enabled;
  }, [isDisabled]);

  return (
    <TouchableDiv
      className={buttonStyling}
      id="permanentlyDeleteButton"
      onClick={isDisabled ? doNothing : onPermanentlyDeleteClick}
    >
      <img
        alt=""
        className="Permanently-delete-button-icon"
        src={trashIcon}
      />

      <span
        className="Permanently-delete-button-text"
      >
        {t('permanentlyDeleteButton.buttonText')}
      </span>
    </TouchableDiv>
  );
};

export { PermanentlyDeleteButton };
