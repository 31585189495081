import React from 'react';

import PolicyCard from './PolicyCard';

import './css/MultiPoliciesPayments.css';

const MultiPoliciesPayments = ({ allBrandsData, breadCrumbs }) => (
  <div className="Multi-policies-payments">
    {allBrandsData.map((policy) => (
      <PolicyCard
        key={policy.PolicyId}
        billingPayments={policy.BillingPayments}
        breadCrumbs={breadCrumbs}
        marketingChannelId={policy.MarketingChannelId}
        pets={policy.Pets}
        policy={policy}
        policyNumber={policy.PolicyNumber}
      />
    ))}
  </div>
);

export default MultiPoliciesPayments;
