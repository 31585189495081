import React from 'react';
import { createRoot } from 'react-dom/client.js';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfigB2C';
import * as serviceWorker from './serviceWorker';
import App from './App.jsx';
import './index.css';
import './common.css';

const msalInstance = new PublicClientApplication(msalConfig);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App instance={msalInstance} />);
serviceWorker.unregister();
