import { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { petProfileRedirected } from '../../../actions';

import { delay } from '../../../services/utils';

import { MY_PETS } from '../../navigation/routes.constants';

const MyOwnPetsValidation = () => {
  const store = useSelector(({
    myPets,
    personalInformation,
  }) => ({
    myPets,
    personalInformation,
  }), shallowEqual);

  const { personalInformation: { pets } } = store;
  const { myPets: { selectedPetId, petProfileFailed } } = store;
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedPetId || !pets.length) {
      return;
    }

    const isOwnPet = !!pets.find((pet) => pet.Id.toString()
      === selectedPetId.toString());

    if (isOwnPet) {
      return;
    }

    dispatch(petProfileRedirected());
    delay(2000).then(() => history.push(MY_PETS));
  }, [history, pets, selectedPetId]);

  useEffect(() => {
    if (!petProfileFailed) {
      return;
    }

    dispatch(petProfileRedirected());
    delay(2000).then(() => history.push(MY_PETS));
  }, [history, petProfileFailed, dispatch]);

  return null;
};

export { MyOwnPetsValidation };
