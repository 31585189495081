import React from 'react';

import errorIcon from '../../../assets/myAccount/input-error-icon.svg';

import './PetCloudInput.css';

const PetCloudInput = ({
  ariaProps,
  className,
  disabled = false,
  error = '',
  errorClassName = '',
  inputName,
  inputRef,
  isTextArea = false,
  maxLength,
  onBlur,
  onChange,
  onFocus,
  onKeyPress,
  optionalText = '',
  placeholder,
  renderError,
  title,
  type = 'text',
  value = '',
  ...inputProps
}) => {
  function renderInput() {
    if (isTextArea) {
      return (
        <textarea
          ref={inputRef}
          className={`PetCloudInput-input ${className || ''} `
            + `${error && 'Input-error'} ${disabled && 'Input-disabled'}`}
          disabled={disabled}
          maxLength={maxLength}
          name={inputName}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          onKeyPress={onKeyPress}
          placeholder={placeholder}
          value={value || ''}
          {...ariaProps}
        />
      );
    }

    return (
      <input
        ref={inputRef}
        className={`PetCloudInput-input ${className || ''} `
          + `${error && 'Input-error'} ${disabled && 'Input-disabled'}`}
        disabled={disabled}
        maxLength={maxLength}
        name={inputName}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        type={type}
        value={value || ''}
        {...ariaProps}
        {...inputProps}
      />
    );
  }

  function renderInputError() {
    if (!error) {
      return null;
    }

    return (
      <p className={`PetCloudInput-error ${errorClassName}`}>
        {renderError ? renderError(error) : (
          <>
            <img alt="" src={errorIcon} />

            <span>{error}</span>
          </>
        )}
      </p>
    );
  }

  return (
    <div className="PetCloudInput-main-container">
      {title && (
        <div className="PetCloudInput-title-container">
          <h6 className="PetCloudInput-title">{title}</h6>

          <span className="PetCloudInput-optional">{optionalText}</span>
        </div>
      )}

      {renderInput()}

      {renderInputError()}
    </div>
  );
};

export { PetCloudInput };
