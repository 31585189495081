import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { GenericModal } from '../../../common/petCloud/GenericModal';
import { ContactUsModal } from '../../../sideMenu/contactUs/ContactUsModal';

import { displayConfirmChangesModal } from '../../../../actions';

import { CONTACT_US_STEPS }
from '../../../sideMenu/contactUs/contactUs.constants';

import shieldIcon from '../../../../assets/myAccount/shield-icon.png';
import alertTriangleIcon
from '../../../../assets/myAccount/alert-triangle-icon.png';

import '../css/ConfirmChangesModal.css';

const ConfirmChangesModal = ({
  handleCancelClick = () => { },
  handleContinueClick = () => { },
  success = false,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('myAccount');

  const store = useSelector(({ myAccount }) => ({ myAccount }), shallowEqual);
  const { myAccount: { showConfirmChangesModal } } = store;

  const [contactUsVisible, setContactUsVisible] = useState(false);

  const handleModalCancelAction = useCallback(() => {
    dispatch(displayConfirmChangesModal({ showConfirmChangesModal: false }));
  }, []);

  const onContactUsClick = () => {
    setContactUsVisible(true);
    dispatch(displayConfirmChangesModal({ showConfirmChangesModal: false }));
  };

  const onContactUsClose = () => {
    setContactUsVisible(false);
  };

  const isError = !success;
  const primaryButtonText = isError
    ? t('petCloudSection.modal.tryAgain') : t('petCloudSection.modal.continue');
  const secondaryButtonText = isError
    ? t('petCloudSection.modal.contactSupport') : null;
  const description = isError ? t('petCloudSection.modal.error')
    : t('petCloudSection.modal.confirmation');
  const onPrimaryClick = isError ? handleModalCancelAction
    : handleContinueClick;
  const onSecondaryClick = isError ? onContactUsClick : handleCancelClick;

  return (
    <>
      <ContactUsModal
        companyStep={CONTACT_US_STEPS.HOME}
        onClose={onContactUsClose}
        show={contactUsVisible}
      />

      <GenericModal
        containerClassName="Pet-Cloud-confirm-changes-modal"
        handleOutClick={handleModalCancelAction}
        onPrimaryClick={onPrimaryClick}
        onSecondaryClick={onSecondaryClick}
        primaryButtonText={primaryButtonText}
        secondaryButtonText={secondaryButtonText}
        show={showConfirmChangesModal}
      >
        <div className="Pet-Cloud-confirm-changes-modal-text-container">
          <img alt="" src={isError ? alertTriangleIcon : shieldIcon} />

          <p className="Pet-Cloud-confirm-changes-modal-text">{description}</p>
        </div>
      </GenericModal>
    </>
  );
};

export { ConfirmChangesModal };
