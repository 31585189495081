import moment from 'moment';

function formatDateSubmitClaim(date) {
  if (date) {
    return moment(date).format('YYYY-MM-DDT00:00:00-00:00');
  }

  return '';
}

const formatDateClaimSummary = (date) => {
  if (date) {
    return moment(date).format('MM-DD-YYYY');
  }

  return '';
};

function reminderFormatDate({ date, baseDateFormat, months }) {
  return baseDateFormat
    .replace('{month}', months[date.getMonth()])
    .replace('{day}', date.getDate())
    .replace('{year}', date.getFullYear());
}

const getDateOrToday = (date) => {
  const [today] = new Date().toISOString().split('T');
  if (date > today) {
    return today;
  }

  return date;
};

export {
  formatDateClaimSummary,
  formatDateSubmitClaim,
  getDateOrToday,
  reminderFormatDate,
};
