import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import plusImage from '../../../assets/fileClaim/plus.png';
import docNavyImage from '../../../assets/fileClaim/doc-navy.svg';
import plusNavyImage from '../../../assets/fileClaim/plus-navy.svg';
import deleteIcon from '../../../assets/fileClaim/delete-sm.svg';
import radioCheckIcon from '../../../assets/fileClaim/radio-check-leaf.svg';
import crossIcon from '../../../assets/fileClaim/cross-hydrant.svg';
import uploadIcon from '../../../assets/fileClaim/upload-circle.svg';

import { fileSizeToMB } from '../../../services/utils';

import ScrollableDiv from '../../common/ScrollableDiv';
import './InputFile.css';

const FileRow = ({
  disabled,
  file,
  isModal,
  loadingAtt,
  onDelete = () => { },
  successSave,
  t,
}) => {
  const renderFileName = ({ filename, extension }) => {
    const parts = filename.split('.');
    const name = parts.slice(0, parts.length - 1).join('.');
    return (
      <div className="File-row-name-container">
        <span className="File-row-name">
          {name}
        </span>

        <span className="File-row-name File-row-extension">{extension}</span>
      </div>
    );
  };

  function renderTxtBtn() {
    if (file.percentage === 100 && !file.valid) {
      return null;
    }

    if (isModal) {
      if (loadingAtt || successSave) {
        return null;
      }
      return (
        <img
          alt={t('delete')}
          className="No-clicked"
          src={deleteIcon}
        />
      );
    }

    return t('cancel');
  }

  function getFileStatus() {
    switch (file.percentage) {
      case 100:
        if (!file.valid) {
          return <img alt={t('uploaded')} src={crossIcon} />;
        }

        return (
          <img alt={t('uploaded')} src={radioCheckIcon} />
        );

      case 0:
        if (!file.valid && isModal) {
          return <img alt={t('uploaded')} src={crossIcon} />;
        }
        return <>&nbsp;&#8226;&nbsp;{t('queued')}</>;

      default:
        return <>&nbsp;&#8226;&nbsp;{`${file.percentage}%`}</>;
    }
  }

  function renderColorBarProgres() {
    if (file.percentage > 50) {
      if (isModal && !file.valid) {
        return 'var(--pc-color-hydrant-400)';
      }

      return 'var(--pc-color-leaf-300)';
    }

    return 'var(--pc-color-blue-300)';
  }

  return (
    <div key={file.key} className="File-row">
      <div className="File-row-data">
        <div className="File-row-left">
          {renderFileName(file)}

          <div className="File-row-status">
            <span>{getFileStatus(file)}</span>
          </div>
        </div>

        <div className="File-row-right">
          <span className="File-row-size">
            {fileSizeToMB({ fileSize: file.size })} MB
          </span>

          <div className="File-row-delete">
            <button
              className={file.hideDelete ? 'Gone' : ''}
              disabled={disabled}
              id={file.key}
              onClick={onDelete}
              type="button"
            >
              {renderTxtBtn(file)}
            </button>
          </div>
        </div>
      </div>

      <div className="File-row-progress-bar">
        <div
          className="Input-file-file-progress"
          style={{
            backgroundColor: renderColorBarProgres(file),
            width: `${file.percentage}%`,
          }}
        />
      </div>

      <span className="File-row-error">
        {!file.errorMsg && !file.valid
          ? t('failedUpload')
          : file.errorMsg}
      </span>
    </div>
  );
};

const InputFile = ({
  acceptFiles,
  className = '',
  containerClassName = '',
  fileDefinition = '',
  files,
  isModal = false,
  loadingAtt,
  onFileAdd = () => { },
  onFileDelete = () => { },
  showCounter = true,
  successSave,
  disabled = false,
}) => {
  const { t } = useTranslation('fileClaims');
  const [isDragging, setIsDragging] = useState(false);
  const dropzoneCoverRef = useRef();
  const dropzoneRef = useRef();
  const modalClass = isModal ? 'Input-file-modal' : '';

  const handleDragover = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleDragenter = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    const draggingFiles = event.dataTransfer.types.indexOf('Files') >= 0;

    if (draggingFiles) {
      setIsDragging(true);
    }
  }, []);

  const handleDragleave = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    const { fromElement, toElement } = event;
    const { current: dropzoneElement } = dropzoneRef;
    const { current: dropzoneCoverElement } = dropzoneCoverRef;
    if ((fromElement !== dropzoneElement
      || toElement !== dropzoneCoverElement)
      && (fromElement !== dropzoneCoverElement
        || toElement !== dropzoneElement)) {
      setIsDragging(false);
    }
  }, []);

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      setIsDragging(false);
      onFileAdd(event.dataTransfer.files);
      event.dataTransfer.clearData();
    }
  }, []);

  const handleDropOnCover = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  }, []);

  useEffect(() => {
    const currentCover = dropzoneCoverRef.current;
    if (currentCover) {
      currentCover.addEventListener('dragleave', handleDragleave);
      currentCover.addEventListener('drop', handleDropOnCover);
    }

    const currentDropzone = dropzoneRef.current;
    if (currentDropzone) {
      currentDropzone.addEventListener('drop', handleDrop);
    }

    return () => {
      if (currentCover) {
        currentCover.removeEventListener('dragleave', handleDragleave);
        currentCover.removeEventListener('drop', handleDropOnCover);
      }

      if (currentDropzone) {
        currentDropzone.removeEventListener('drop', handleDrop);
      }
    };
  }, [handleDrop, handleDragleave, dropzoneCoverRef, dropzoneRef, isDragging]);

  useEffect(() => {
    window.addEventListener('dragover', handleDragover);
    window.addEventListener('dragenter', handleDragenter);

    return () => {
      window.removeEventListener('dragover', handleDragover);
      window.removeEventListener('dragenter', handleDragenter);
    };
  }, [handleDragenter, handleDragover]);

  function handleFileChange(event) {
    onFileAdd(event.target.files);
  }

  function handleFileDelete(event) {
    onFileDelete(event.target.id);
  }

  function renderDropzone() {
    if (!disabled && isDragging) {
      return (
        <div ref={dropzoneCoverRef} className="Input-file-dropzone-cover">
          <div ref={dropzoneRef} className="Input-file-dropzone">
            <div className="No-clicked">
              <div className="Input-file-icon">
                <img alt="doc" src={docNavyImage} />

                <img
                  alt="plus"
                  className="Input-file-plus-doc"
                  src={plusNavyImage}
                />
              </div>

              <span>{t('dropHere')}</span>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }

  if (!files.length) {
    return (
      <div
        className={`Input-file ${modalClass} ${className} Input-file-empty`}
      >
        {renderDropzone()}

        <input
          accept={acceptFiles}
          disabled={disabled}
          multiple
          onChange={handleFileChange}
          type="file"
        />

        <img alt="" src={uploadIcon} />

        <div className="Input-file-action No-clicked">
          <Trans
            components={{ b: <strong /> }}
            i18nKey="dragDrop"
            t={t}
          />
        </div>

        <span className="Input-file-instruction No-clicked">
          {fileDefinition || t('fileDefinition')}
        </span>
      </div>
    );
  }

  return (
    <div className={`Input-file-container ${containerClassName}`}>
      {renderDropzone()}

      {showCounter && (
        <div className="Input-file-count">
          <span>
            {t('fileCounter', {
              fileCount: files.length,
              uploaded: files
                .filter((file) => file.valid && file.binary).length,
            })}
          </span>
        </div>
      )}

      <ScrollableDiv className={`Input-file ${className}`}>
        {files.map((file) => (
          <FileRow
            key={file.key}
            file={file}
            isModal={isModal}
            loadingAtt={loadingAtt}
            onDelete={handleFileDelete}
            successSave={successSave}
            t={t}
          />
        ))}
      </ScrollableDiv>

      {!isModal && (
        <div className="Input-file-add-files">
          <div className="Input-file-add-browse">
            <img
              alt="add file"
              className="Input-file-plus"
              src={plusImage}
            />

            <span>{t('addFiles')}</span>

            <input
              accept={acceptFiles}
              disabled={disabled}
              multiple
              onChange={handleFileChange}
              type="file"
              value=""
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default InputFile;
