import React, { useCallback, useMemo } from 'react';

import checkIcon from '../../../../assets/plans/check.svg';
import './SelectorPill.css';

const SelectorPill = ({
  checked = false,
  disabled = false,
  itemExtraData,
  label = '',
  name = '',
  onChange = () => { },
  value = '',
}) => {
  const id = useMemo(() => label.replace(/[^A-Za-z0-9]/g, ''), [label]);

  const handleChange = useCallback(() => {
    onChange({ ...itemExtraData, label, value });
  }, [onChange, itemExtraData, label, value]);

  return (
    <label
      aria-label={`${label} ${name}`}
      aria-live={checked ? 'polite' : 'off'}
      className="Selector-pill-wrapper"
      data-checked={checked}
      data-disabled={disabled}
      htmlFor={`${id}-${name}`}
    >
      <input
        aria-disabled={disabled}
        checked={checked}
        className="Selector-pill-button"
        id={`${id}-${name}`}
        name={name}
        onChange={handleChange}
        type="radio"
        value={value}
      />

      {checked
        && <img alt="" className="Selector-pill-button-icon" src={checkIcon} />}

      {label}
    </label>
  );
};

export { SelectorPill };
