import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getAllBrandsData, loadReimbursementMethod } from '../../../../actions';
import { MY_ACCOUNT } from '../../../../constants';
import { formatDate } from '../../../../services/utils';
import { MY_ACCOUNT as MY_ACCOUNT_ROUTE }
from '../../../navigation/routes.constants.js';
import { MARKETING_CHANNEL_ID } from '../../../../theme/theme.constants.js';

const useReimbursementScreen = ({ breadCrumbs }) => {
  const { t } = useTranslation('myAccount');
  const dispatch = useDispatch();
  const history = useHistory();

  const store = useSelector(({ billingPayments, personalInformation }) => ({
    billingPayments,
    personalInformation,
  }), shallowEqual);
  const { billingPayments: {
    allBrandsData,
    loadingAllBrandsData,
    reimbursementMethod,
  }, personalInformation } = store;

  useEffect(() => {
    dispatch(getAllBrandsData());
  }, []);

  const reimbursementOption = useMemo(() => {
    if (!reimbursementMethod) {
      return {
        AccountNumberLastFour: '',
        Details: {},
        Id: '',
        PreferredReimbursementMethod: '',
        RoutingNumberLastFour: '',
      };
    }

    const {
      AccountNumber,
      PreferredReimbursementMethod,
      RoutingNumber,
    } = reimbursementMethod;

    return {
      AccountNumberLastFour: AccountNumber
        ? AccountNumber.substr(AccountNumber?.length - 4) : '',
      Details: {
        AddressLine1: `${personalInformation.address1},`,
        AddressLine2: personalInformation.address2
          ? `${personalInformation.address2},` : '',
        City: personalInformation.city,
        State: personalInformation.state,
        Zipcode: personalInformation.zipCode,
      },
      Id: PreferredReimbursementMethod,
      PreferredReimbursementMethod,
      RoutingNumberLastFour: RoutingNumber
        ? RoutingNumber?.substr(RoutingNumber?.length - 4) : '',
    };
  }, [personalInformation, reimbursementMethod]);

  const onClickReimbursementCard = useCallback(({
    policyInfo,
    policyNumber,
  }) => {
    if (breadCrumbs) {
      breadCrumbs.onAddBreadcrumbItem({
        extraData: { policyInfo },
        id: MY_ACCOUNT.reimbursementDetails,
        name: t('breadCrumbs.breadCrumbPolicy', {
          policyNumber,
        }),
      });
    }

    dispatch(loadReimbursementMethod(policyNumber));
  }, [breadCrumbs]);

  const onOpenReimbursementMethod = useCallback(({
    policyInfo,
  }) => {
    const startDate = formatDate(policyInfo.PolicyStartDate);
    const endDate = formatDate(policyInfo.PolicyEndDate);
    const policyPets = allBrandsData?.find((policy) => policy.PolicyNumber
      === policyInfo.PolicyDisplayNumber)?.Pets || [];

    const data = {
      policyInfo: {
        MarketingChannelId: policyInfo.MarketChannelId,
        Pets: policyPets,
        PolicyNumber: policyInfo.PolicyDisplayNumber,
        PolicyStatus: policyInfo.StatusDisplay,
        PolicyTerm: `${startDate} - ${endDate}`,
      },
    };

    if (policyInfo.MarketChannelId !== MARKETING_CHANNEL_ID.figo) {
      data.policyInfo.PolicyData = policyInfo;
    }

    dispatch(loadReimbursementMethod(policyInfo.PolicyDisplayNumber));

    history.push({
      pathname: MY_ACCOUNT_ROUTE,
      state: {
        breadCrumbId: MY_ACCOUNT.reimbursementDetails,
        breadCrumbName: t('breadCrumbs.breadCrumbPolicy', {
          ns: 'myAccount',
          policyNumber: policyInfo.PolicyDisplayNumber,
        }),
        breadCrumbNameExtraData: data,
      },
    });
  }, []);

  return {
    allBrandsData,
    loadingAllBrandsData,
    onClickReimbursementCard,
    onOpenReimbursementMethod,
    reimbursementOption,
  };
};

export { useReimbursementScreen };
