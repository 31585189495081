import React from 'react';
import ModalContainer from './ModalContainer';
import loadingSpinner
from '../../assets/lottieFiles/loading-blue.json';
import './FigoLoadingOverlay.css';
import FigoLottie from './FigoLottie';

const FigoLoadingOverlay = ({ visible, message = '' }) => (
  <ModalContainer
    contentStyle="Loading-overlay-main-container"
    show={visible}
  >
    <div className="Loading-overlay-container">
      {message && (
        <p className="Loading-overlay-message">
          {message}
        </p>
      )}

      <FigoLottie animationData={loadingSpinner} height={82} width={82} />
    </div>
  </ModalContainer>
);

export default FigoLoadingOverlay;
