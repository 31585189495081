import { createActions } from 'redux-actions';

const actions = createActions({
  ADD_PET: (data) => (data),
  ADD_PET_FAILED: () => { },
  ADD_PET_RESTARTED: () => { },
  BREED_DATA_LOADED: (data) => (data),
  DELETE_PET: (data) => (data),
  DELETE_PET_FAILED: () => { },
  EDIT_PET: (data) => (data),
  LOAD_BREED_LIST: () => ({}),
  LOAD_MICROCHIP_DATA: (data) => (data),
  LOAD_PET_PROFILE: (data) => (data),
  MICROCHIP_DATA_CLEARED: () => { },
  MICROCHIP_DATA_LOADED: (data) => (data),
  MICROCHIP_FAILED: () => { },
  MICROCHIP_MODAL_VISIBLE: (data) => (data),
  MICROCHIP_SAVED: (data) => (data),
  PET_ADDED: (data) => (data),
  PET_DELETED: () => { },
  PET_EDITED: () => { },
  PET_PHOTO_REMOVED: () => { },
  PET_PROFILE_FAILED: () => { },
  PET_PROFILE_LOAD_ATTEMPT: () => { },
  PET_PROFILE_LOADED: (data) => (data),
  PET_PROFILE_REDIRECTED: () => { },
  REMOVE_PET_PHOTO: (data) => (data),
  REMOVE_PET_PHOTO_FAILED: () => { },
  SAVE_MICROCHIP: (data) => (data),
  SELECTED_PET: (data) => (data),
});

export const {
  addPet,
  addPetFailed,
  addPetRestarted,
  breedDataLoaded,
  deletePet,
  deletePetFailed,
  editPet,
  loadBreedList,
  loadMicrochipData,
  loadPetProfile,
  microchipDataCleared,
  microchipDataLoaded,
  microchipFailed,
  microchipModalVisible,
  microchipSaved,
  petAdded,
  petDeleted,
  petEdited,
  petPhotoRemoved,
  petProfileLoadAttempt,
  petProfileLoaded,
  petProfileRedirected,
  petProfileFailed,
  removePetPhoto,
  removePetPhotoFailed,
  saveMicrochip,
} = actions;
