import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { acceptTermsOfService } from '../../actions';
import ModalContainer from '../common/ModalContainer';
import { ActionButton } from '../common/petCloud/ActionButton';
import './AcceptTermsOfServiceModal.css';

const AcceptTermsOfServiceModal = () => {
  const { t } = useTranslation('common');
  const store = useSelector(({ session }) => ({ session }), shallowEqual);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const privacyPolicyLink = store.session.privacyPolicyLink || '/';
  const termsOfServiceLink = store.session.termsOfServiceLink || '/';
  const { userHasTermsOfService } = store.session;
  const firstPart = t('acceptTermsOfServiceModal.messages.firstPart');
  const termsOfService = t('acceptTermsOfServiceModal.title');
  const connector = t('acceptTermsOfServiceModal.messages.connector');
  const ending = t('acceptTermsOfServiceModal.messages.ending');
  const privacyPolicy = t('acceptTermsOfServiceModal.privacyPolicy');
  const buttonText = t('acceptTermsOfServiceModal.buttonText');

  const onClose = useCallback(() => {
    dispatch(acceptTermsOfService());
    setVisible(false);
  }, [dispatch]);

  useEffect(() => {
    setVisible(!userHasTermsOfService);
  }, [userHasTermsOfService]);

  function greenText({ content, link }) {
    return (
      <a
        className="Accept-terms-modal-content-green"
        href={link}
        rel="noopener noreferrer"
        target="_blank"
      >
        {content}
      </a>
    );
  }

  return (
    <ModalContainer
      cancelable
      handleOutClick={onClose}
      id="acceptTermsOfServiceModal"
      show={visible}
    >
      <div className="Accept-terms-modal-container">
        <span className="Accept-terms-modal-title">{termsOfService}</span>

        <span className="Accept-terms-modal-content">
          {firstPart} {greenText({
            content: termsOfService,
            link: termsOfServiceLink,
          })}&nbsp;
          {connector} {greenText({
            content: privacyPolicy,
            link: privacyPolicyLink,
          })}{ending}
        </span>

        <ActionButton
          className="Accept-terms-modal-close-button"
          onClick={onClose}
          title={buttonText}
        />
      </div>
    </ModalContainer>
  );
};

export { AcceptTermsOfServiceModal };
