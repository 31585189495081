import React from 'react';
import { Provider } from 'react-redux';
import { MsalProvider } from '@azure/msal-react';
import { I18nextProvider } from 'react-i18next';
import { initializeStore } from './store';
import { MixpanelProvider } from './hooks/useMixpanel';
import AppRouter from './AppRouter';
import i18n from './i18n';
import './App.css';
import './colors.css';
import { FeatureFlagProvider } from './hooks/useFeatureFlags';

const App = ({ instance }) => (
  <MsalProvider instance={instance}>
    <Provider store={initializeStore()}>
      <FeatureFlagProvider>
        <I18nextProvider i18n={i18n}>
          <MixpanelProvider>
            <AppRouter />
          </MixpanelProvider>
        </I18nextProvider>
      </FeatureFlagProvider>
    </Provider>
  </MsalProvider>
);

export default App;
