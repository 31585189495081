import React from 'react';
import { useTranslation } from 'react-i18next';

import copayImg
from '../../../assets/changeCoverage/pet-protect-copay-active.svg';

import ModalContainer from '../../common/ModalContainer';
import './css/PetProtectCopayModal.css';

const PetProtectCopayModal = ({
  onCancel = () => { },
  onConfirm = () => { },
  show,
}) => {
  const { t } = useTranslation('changeCoverage');

  return (
    <ModalContainer show={show}>
      <div className="Pet-protect-copay-modal">
        <img alt="" src={copayImg} />

        <h3>{t('petProtectCopay.title')}</h3>

        <h4>{t('petProtectCopay.subtitle')}</h4>

        <p>{t('petProtectCopay.text')}</p>

        <button
          className="Pet-protect-modal-confirm"
          onClick={onConfirm}
          type="button"
        >
          {t('petProtectCopay.continue')}
        </button>

        <button
          className="Pet-protect-modal-nevermind Like-span"
          onClick={onCancel}
          type="button"
        >
          {t('petProtectCopay.cancel')}
        </button>
      </div>
    </ModalContainer>
  );
};

export { PetProtectCopayModal };
