import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import moment from 'moment';

import arrowIcon from '../../assets/fileClaim/right-arrow.svg';
import { MARKETING_CHANNEL_ID } from '../../theme/theme.constants';
import { getThemeLogoById } from '../../theme/theme.utils';
import dogDefaultImage from '../../assets/billingPayments/dog-placeholder.png';
import catDefaultImage from '../../assets/billingPayments/cat-placeholder.png';

import Stepper from './Stepper';
import './MultibrandClaimCard.css';
import { PET_TYPE_MULTIBRAND } from '../../constants';
import { currencyFormat } from '../../services/utils';

const STATUS_DISPLAY = {
  attention: 'Attention Needed',
  closed: 'Closed',
  completed: 'Completed',
  done: 'Done!',
};

const ClaimHeader = ({
  buttonLabel,
  date,
  goToClaimDetails = () => { },
  isClosed = false,
  logo,
}) => (
  <div className="Claim-header" data-closed={isClosed}>
    <span>{date}</span>

    {logo && (
      <img alt="" className="Claim-header-logo" src={logo} />
    )}

    <button
      className="Claim-header-button"
      onClick={goToClaimDetails}
      type="button"
    >
      {buttonLabel} <img alt="" src={arrowIcon} />
    </button>
  </div>
);

const ClaimDataRow = ({ label, text, textClassName = '' }) => (
  <div>
    <span className="Claim-data-row-label">{label}</span>

    <span className={`Claim-data-row-text ${textClassName}`}>{text}</span>
  </div>
);

const ClaimOrcaData = ({ status, claimNumber, pets, logo }) => {
  const { t } = useTranslation('claims');

  const petsNames = Array.from(
    new Set(pets?.map(({ PetName }) => PetName)) || [],
  );

  return (
    <div className="Claim-data-container">
      <div className="Claim-data-content">
        <ClaimDataRow
          label={t('status')}
          text={status}
          textClassName={status === STATUS_DISPLAY.attention
            ? 'Claim-data-status' : ''}
        />

        <ClaimDataRow label={t('titleClaim#')} text={claimNumber} />

        <ClaimDataRow
          label={t('petsOnClaim')}
          text={petsNames.join(', ')}
        />
      </div>

      <div>
        <img alt="" src={logo} />
      </div>
    </div>
  );
};

const ClaimFigoData = ({
  status,
  pets = [],
  claimNumber,
  amountPaid = 0,
  claimAmount = 0,
  reimbursementType = '',
}) => {
  const { t } = useTranslation('claims');
  const [pet] = pets;

  const isClosed = status === STATUS_DISPLAY.closed
    || status === STATUS_DISPLAY.completed
    || status === STATUS_DISPLAY.paid
    || status === STATUS_DISPLAY.done;

  const image = useMemo(() => {
    if (pet?.ProfileImage) {
      return pet.ProfileImage;
    }
    return pet?.PetType === PET_TYPE_MULTIBRAND.CAT
      ? catDefaultImage : dogDefaultImage;
  }, [pet]);

  return (
    <div className="Claim-data-container Claim-data-figo-container">
      <div className="Claim-data-container-open">
        <div className="Claim-data-figo">
          <div className="Claim-data-pet">
            <img
              alt=""
              className="Claim-data-pet-image"
              src={image}
            />

            <span>{pet?.PetName}</span>
          </div>

          <div className="Claim-data-info">
            <ClaimDataRow label={t('titleClaim#')} text={claimNumber} />

            {isClosed && (
              <ClaimDataRow
                label={t('paid')}
                text={reimbursementType ? t('paidWith', {
                  amount: currencyFormat({ value: amountPaid }),
                  reimbursementType,
                }) : currencyFormat({ value: amountPaid })}
              />
            )}
          </div>
        </div>

        {!isClosed && (
          <div className="Claim-data-amount">
            {`${currencyFormat({ value: claimAmount })}`}
          </div>
        )}

      </div>

      {!isClosed && (
        <Stepper status={status} />
      )}

    </div>
  );
};

const ClaimData = ({
  marketingChannelId = MARKETING_CHANNEL_ID.figo,
  ...claimInfo
}) => {
  switch (marketingChannelId) {
    case MARKETING_CHANNEL_ID.akc:
    case MARKETING_CHANNEL_ID.ppi:
    case MARKETING_CHANNEL_ID.ppiOnePack:
    case MARKETING_CHANNEL_ID.spot:
      return (
        <ClaimOrcaData {...claimInfo} />
      );
    case MARKETING_CHANNEL_ID.figo:
    default:
      return <ClaimFigoData {...claimInfo} />;
  }
};

const MultibrandClaimCard = ({
  amountPaid = 0,
  claimAmount = 0,
  claimId,
  claimNumber,
  date,
  marketingChannelId = MARKETING_CHANNEL_ID.figo,
  pets = [],
  reimbursementType = '',
  status,
}) => {
  const { t } = useTranslation('claims');
  const history = useHistory();
  const match = useRouteMatch();

  const goToClaimDetails = useCallback(() => {
    history.push(`${match.path}/details`, { claimId, claimNumber });
  }, [history, match, claimNumber]);

  const themeLogos = useMemo(
    () => getThemeLogoById(marketingChannelId),
    [marketingChannelId],
  );

  const formattedDate = useMemo(() => {
    const correctedDate = date.replace('.-', '.000-');
    return moment.parseZone(correctedDate).format('MMMM D, YYYY');
  }, [date]);

  return (
    <div className={`Multibrand-claim-card Colors-${marketingChannelId}`}>
      <ClaimHeader
        buttonLabel={t('viewDetails')}
        date={formattedDate}
        goToClaimDetails={goToClaimDetails}
        isClosed={status === STATUS_DISPLAY.completed
          || status === STATUS_DISPLAY.closed
          || status === STATUS_DISPLAY.done}
        logo={marketingChannelId === MARKETING_CHANNEL_ID.figo
          && themeLogos.white}
      />

      <ClaimData
        amountPaid={amountPaid}
        claimAmount={claimAmount}
        claimNumber={claimNumber}
        logo={marketingChannelId !== MARKETING_CHANNEL_ID.figo
          && themeLogos.simplified}
        marketingChannelId={marketingChannelId}
        pets={pets}
        reimbursementType={reimbursementType}
        status={status}
      />
    </div>
  );
};

export { MultibrandClaimCard };
