import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '../../common/dropdown/Dropdown';
import {
  moveFiles,
  moveFilesRestarted,
  moveFolder,
  moveFolderRestarted,
  showAlert,
} from '../../../actions';
import './MoveModal.css';
import { INBOX_DOCS } from '../../../constants';
import { generateFileTreeOptions } from '../../../services/utils';
import { GenericModal } from '../../common/petCloud';

const MoveModal = ({
  goToNewFolder = () => { },
  onClose = () => { },
  isTrashScreen = false,
  selectedFoldersIds = [],
  selectedItemsIds = [],
  show,
  title = '',
}) => {
  const { t } = useTranslation('inboxAndDocs');
  const [optionSelected, setOptionSelected] = useState(null);
  const [showError, setShowError] = useState(false);

  const store = useSelector(({ inboxAndDocs }) => inboxAndDocs, shallowEqual);
  const {
    movingFiles,
    moveFolder: {
      loading: movingFolder,
      success: folderMoved,
    },
    filesMovedSuccesfully,
    folderStructure,
    errorOnAction,
  } = store;
  const dispatch = useDispatch();

  function handleClose() {
    dispatch(moveFilesRestarted());
    dispatch(moveFolderRestarted());
    onClose();
  }

  useEffect(() => {
    if (!movingFiles && errorOnAction) {
      dispatch(showAlert({ type: 'error' }));
      handleClose();
    }
  }, [errorOnAction]);

  const optionsGenerated = useMemo(
    () => {
      const fullTree = generateFileTreeOptions({
        folders: folderStructure,
        hiddenFolderId: INBOX_DOCS.trash,
        level: 0,
      });
      return isTrashScreen && selectedFoldersIds.length
        ? fullTree.filter((node) => node.level === 0)
        : fullTree;
    },
    [folderStructure, isTrashScreen, selectedFoldersIds.length],
  );

  useEffect(() => () => {
    if (show) {
      setOptionSelected(null);
      setShowError(false);
    }
  }, [show]);

  function handleFilesMoved() {
    handleClose();
    dispatch(moveFilesRestarted());
    if (optionSelected) {
      goToNewFolder({
        id: optionSelected.id,
        name: optionSelected.label,
      });
    }
  }

  function handleOptionChanged(newOption) {
    if (showError) {
      setShowError(false);
    }
    setOptionSelected(newOption);
  }

  function moveItems() {
    if (!optionSelected) {
      setShowError(true);
      return;
    }

    if (selectedItemsIds.length) {
      dispatch(moveFiles({
        destinationFolderId: optionSelected.id,
        filesIds: selectedItemsIds,
      }));
    }

    if (selectedFoldersIds.length) {
      selectedFoldersIds.forEach((id) => {
        dispatch(moveFolder({
          destinationFolderId: optionSelected.id,
          folderIdToMove: id,
        }));
      });
    }
  }

  function handleCancel() {
    if (!movingFiles) {
      handleClose();
    }
  }

  return (
    <GenericModal
      handleOutClick={handleClose}
      loadingPrimary={movingFiles || movingFolder}
      onPrimaryClick={moveItems}
      onPrimarySuccess={handleFilesMoved}
      onSecondaryClick={handleCancel}
      primaryButtonDisabled={!optionSelected}
      primaryButtonText={t('moveModal.move')}
      secondaryButtonText={t('moveModal.cancel')}
      show={show}
      successPrimary={filesMovedSuccesfully || folderMoved}
      title={title || t('moveModal.title')}
    >
      <div className="Width100">
        <span className="Move-to-text">{t('moveModal.moveTo')}</span>

        <div className="Move-action-dropdown">
          <Dropdown
            error={showError ? t('moveModal.required') : ''}
            onSelectionChanged={handleOptionChanged}
            options={optionsGenerated}
            placeholder={t('moveModal.placeholderDropdown')}
            value={optionSelected}
          />
        </div>
      </div>
    </GenericModal>
  );
};

export { MoveModal };
