import { handleActions } from 'redux-actions';

const INITIAL_STATE = {
  addVetError: false,
  checkedPrimaryVet: false,
  checkingPrimaryVet: false,
  customVetFailed: '',
  favoriteVets: [],
  hasPrimaryVet: false,
  isEditingZipCode: false,
  isLoadingFavorites: true,
  isLoadingVets: false,
  isRemovingPet: false,
  isSavingCustomVet: false,
  isSavingVet: false,
  isValidatingZipCode: false,
  isValidZipCode: true,
  lastZipcodeLoaded: '',
  pointInterestFailed: '',
  removePetSuccess: false,
  vetCustomSaved: false,
  vetSaved: false,
  vetsFound: [],
};

export default handleActions({
  ADD_CUSTOM_TO_FAVORITES: (state) => ({
    ...state,
    isSavingCustomVet: true,
    vetCustomSaved: false,
  }),
  ADD_CUSTOM_TO_FAVORITES_FAILED: (state, { payload }) => ({
    ...state,
    customVetFailed: payload,
  }),
  ADD_POINT_INTEREST_FAILED: (state, { payload }) => ({
    ...state,
    pointInterestFailed: payload,
  }),
  ADD_TO_FAVORITES: (state) => ({
    ...state,
    addVetError: false,
    isSavingVet: true,
    vetSaved: false,
  }),
  ADD_TO_FAVORITES_FAILED: (state) => ({
    ...state,
    addVetError: true,
    isSavingVet: false,
    vetSaved: false,
  }),
  ADDED_TO_FAVORITES: (state) => ({
    ...state,
    isSavingCustomVet: false,
    isSavingVet: false,
    vetCustomSaved: true,
    vetSaved: true,
  }),
  CHECK_IF_HAS_PRIMARY_VET: (state) => ({
    ...state,
    checkingPrimaryVet: true,
  }),
  CLEAN_MY_VETS_ERRORS: (state) => ({
    ...state,
    addVetError: false,
    isSavingCustomVet: false,
    isSavingVet: false,
    vetCustomSaved: false,
    vetSaved: false,
  }),
  GET_ALL_FAVORITES_ATTEMPT: (state) => ({
    ...state,
    favoriteVets: [],
    isLoadingFavorites: true,
    removePetSuccess: false,
  }),
  HAS_PRIMARY_VET_CHECKED: (state, { payload }) => ({
    ...state,
    checkedPrimaryVet: true,
    checkingPrimaryVet: false,
    hasPrimaryVet: payload,
  }),
  PRIMARY_VET_SAVE_FAILED: (state) => ({
    ...state,
    addVetError: true,
    hasPrimaryVet: false,
    isSavingVet: false,
    vetSaved: false,
  }),
  PRIMARY_VET_SAVED: (state) => ({
    ...state,
    hasPrimaryVet: true,
    isSavingVet: false,
    vetSaved: true,
  }),
  REMOVE_VET: (state) => ({
    ...state,
    isRemovingPet: true,
  }),
  REMOVE_VET_FAILED: (state) => ({
    ...state,
    isRemovingPet: false,
  }),
  REMOVE_VET_SUCCESS: (state) => ({
    ...state,
    checkedPrimaryVet: false,
    isRemovingPet: false,
    removePetSuccess: true,
  }),
  SAVE_PRIMARY_VET: (state) => ({
    ...state,
    isSavingVet: true,
  }),
  SEARCH_PRIMARY_VETS: (state) => ({
    ...state,
    isLoadingVets: true,
  }),
  SEARCH_VETS: (state) => ({
    ...state,
    isLoadingVets: true,
    lastZipcodeLoaded: '',
    vetsFound: [],
  }),
  SET_FAVORITES: (state, { payload }) => ({
    ...state,
    favoriteVets: payload,
    isLoadingFavorites: false,
  }),
  VALIDATE_ZIP_CODE_ATTEMPT: (state) => ({
    ...state,
    isValidatingZipCode: true,
    isValidZipCode: true,
  }),
  VALIDATE_ZIP_CODE_FAILED: (state) => ({
    ...state,
    isEditingZipCode: false,
    isValidatingZipCode: false,
    isValidZipCode: false,
  }),
  VALIDATE_ZIP_CODE_SUCCESS: (state) => ({
    ...state,
    isEditingZipCode: false,
    isValidatingZipCode: false,
    isValidZipCode: true,
  }),
  VET_CUSTOM_SAVED_CLEARED: (state) => ({
    ...state,
    vetCustomSaved: false,
  }),
  VET_ZIP_CODE_CHANGED: (state) => ({
    ...state,
    isEditingZipCode: true,
    isValidatingZipCode: false,
    isValidZipCode: false,
  }),
  VETS_SEARCH_CLEARED: (state) => ({
    ...state,
    isLoadingVets: false,
    lastZipcodeLoaded: '',
    vetsFound: [],
  }),
  VETS_SEARCHED: (state, { payload }) => ({
    ...state,
    isLoadingVets: false,
    lastZipcodeLoaded: payload.zipCode,
    vetsFound: payload.map((vet) => ({
      ...vet,
      BackgroundImage: vet.Thumbnail,
      Provider: vet.Provider || 1,
      Title: vet.PlaceName,
    })),
  }),
}, INITIAL_STATE);
