import React from 'react';

import './EmptyScreen.css';

const EmptyScreen = ({ description = '', title = '' }) => (
  <div className="Empty-screen-common-container">
    <h3>{title}</h3>

    <span>{description}</span>
  </div>
);

export { EmptyScreen };
