import React, { useState, useImperativeHandle, forwardRef } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PetProfileFamilyPictureMyPets } from './PetProfileFamilyPictureMyPets';
import { PetProfileImage } from '../PetProfileImage';
import { PET_GENDER } from '../../../constants';
import {
  addPetRestarted,
  editPet,
  removePetPhoto,
} from '../../../actions/myPets';
import pencil from '../../../assets/myPets/pencil.svg';
import quotes from '../../../assets/myPets/quotation.svg';
import './PetProfileInfoCardMyPets.css';
import { refreshPets } from '../../../actions';
import { ImageEditorMyPets }
from '../../common/imageEditorMyPets/ImageEditorMyPets';

const PetProfileInfoCardMyPets = forwardRef(({
  familyMembers,
  familyPicture,
  handleOnAddFamilyCoverClick,
  handleOnEditPetClick,
  petAge,
  petBio,
  petBirthday,
  petBreed,
  petBreedId,
  petGender,
  petId,
  petName,
  petType,
  petWeight,
  profilePicture,
}, ref) => {
  const { t } = useTranslation('myPets');
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = useState(false);
  const store = useSelector(({
    common,
    myPets,
  }) => ({ common, myPets }), shallowEqual);

  function handleAddPhoto() {
    setIsUploading(true);
  }

  function closeAddPhoto() {
    setIsUploading(false);
    dispatch(addPetRestarted());
  }

  async function handleSavePhoto(newImage, fileName) {
    const petImageData = newImage.replace(/^data:(.*,)?/, '');

    const petProfile = {
      BreedId: petBreedId,
      Description: petBio ? petBio.replace(/[\\"\\]/g, '') : '',
      DOB: petBirthday,
      Gender: petGender,
      Id: petId,
      Name: petName,
      PetImage: {
        ContentType: 'image/jpeg',
        Extension: 'jpg',
        FileContent: petImageData,
        FileName: fileName,
      },
      TypeId: petType,
      Weight: petWeight || '0',
    };
    dispatch(editPet(petProfile));
  }

  function handleSavePhotoSuccess() {
    closeAddPhoto();
    dispatch(refreshPets());
  }

  function handleRemovePhoto() {
    dispatch(removePetPhoto({ petProfileId: petId }));
  }

  useImperativeHandle(
    ref,
    () => ({
      handleAddPhoto,
    }),
  );

  return (
    <div className="My-pets-profile-info-card-content">
      <PetProfileFamilyPictureMyPets
        image={familyPicture}
        onClick={handleOnAddFamilyCoverClick}
      />

      <div className="My-pets-profile-info-card-information Flex">
        <div className="My-pets-profile-info-card-left-section
            Flex Flex-column"
        >
          <PetProfileImage
            containerClassName={'Pet-profile-image-container '
              + 'Flex Flex-column'}
            image={profilePicture}
            imageClassName="My-pets-profile-info-card-image Pet-profile-image"
            isProfile
            onAddPhotoClick={handleAddPhoto}
            petName={petName}
            petType={petType}
          />

        </div>

        <div className="My-pets-profile-info-card-pet-details">
          <div className="My-pets-profile-info-title-button">
            <h1>{petName}</h1>

            <button
              className="My-pets-profile-info-card-edit-button"
              onClick={handleOnEditPetClick}
              type="button"
            >
              <img
                alt=""
                className="My-pets-profile-info-card-edit-icon"
                src={pencil}
              />

              {t('PetProfile.buttonEdit')}
            </button>
          </div>

          <div className="My-pets-profile-info-card-pet-details-row">
            <span>{petGender === PET_GENDER.MALE ? 'Male' : 'Female'}</span>

            <span>
              {`${petAge} yr${petAge > 1 || petAge === '-' ? 's' : ''}`}
            </span>

            <span>
              {`${petWeight || '-'} lb${petWeight <= 1 ? '' : 's'}`}
            </span>

            <span>{petBreed}</span>
          </div>
          <div className={`My-pets-profile-info-card-pet-details-bio${
                !familyMembers.length ? '-no-family-members' : ''} Flex`}
          >
            <div className="My-pets-profile-info-card-pet-details-bio-box">
              <img alt="" src={quotes} />

              <p>{petBio || t('PetProfile.defaultBio')}</p>
            </div>
            <div />
          </div>
        </div>
      </div>

      <ImageEditorMyPets
        error={store.myPets.error ? t('errorPhoto') : ''}
        imageHeight={230}
        imageWidth={230}
        initialPicture={profilePicture}
        isSaving={store.myPets.loading}
        onClose={closeAddPhoto}
        onRemove={handleRemovePhoto}
        onSave={handleSavePhoto}
        onSuccess={handleSavePhotoSuccess}
        photoType={t('petPhoto')}
        saveSuccess={store.myPets.success}
        show={isUploading}
        subtitle={t('addProfilePhoto')}
        title={profilePicture ? t('editPetPhoto') : t('addPetPhoto')}
      />
    </div>
  );
});

export { PetProfileInfoCardMyPets };
