import './Reimbursement.css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReimbursementMethodCard } from './ReimbursementMethodCard';
import {
  MULTIBRAND_POLICY_STATUS,
  REIMBURSEMENT_METHODS,
} from '../../../constants';
import { useReimbursementScreen }
from '../../myAccount/reimbursement/hooks/useReimbursementScreen';
import { MARKETING_CHANNEL_ID } from '../../../theme/theme.constants';
import { ViewMoreButton } from '../ViewMoreButton';

export const Reimbursement = ({
  selectedPolicy = {},
}) => {
  const { t } = useTranslation('insuranceScreen');
  const { onOpenReimbursementMethod } =
    useReimbursementScreen({ breadCrumbs: null });

  const {
    MarketChannelId,
    ReimbursementOption: {
      AccountNumberLastFour,
      CheckAddress,
      ReimbursementMethodId,
    },
    StatusDisplay,
  } = selectedPolicy;

  const hasReimbursementMethod = ReimbursementMethodId
    !== REIMBURSEMENT_METHODS.none;

  const isRedirectAvailable =
    (StatusDisplay === MULTIBRAND_POLICY_STATUS.active
      || (StatusDisplay === MULTIBRAND_POLICY_STATUS.future
        && MarketChannelId === MARKETING_CHANNEL_ID.figo));

  const handleReimbursementMethod = () => {
    onOpenReimbursementMethod({
      policyInfo: selectedPolicy,
    });
  };

  const reimbursementMethodCardCases = {
    [REIMBURSEMENT_METHODS.check]: {
      addressCheck: CheckAddress,
      methodType: 'check',
    },
    [REIMBURSEMENT_METHODS.deposit]: {
      lastNumbers:
        AccountNumberLastFour?.substr(AccountNumberLastFour?.length - 4) || '',
      methodType: 'directDeposit',
    },
    [REIMBURSEMENT_METHODS.none]: {
      isRedirectAvailable,
      methodType: 'addReimbursementMethod',
      onReimbursementMethod:
        handleReimbursementMethod,
    },
  };

  const reimbursementMethodCardProps =
    reimbursementMethodCardCases[ReimbursementMethodId]
    || reimbursementMethodCardCases[REIMBURSEMENT_METHODS.none];

  return (
    <div className="Reimbursement__container">
      <div className="Reimbursement__header">
        <div>
          <h3 className="Reimbursement__title">{t('reimbursement')}</h3>

          <div className="Reimbursement__text">{t('reimbursementText')}</div>
        </div>

        {hasReimbursementMethod && isRedirectAvailable && (
          <ViewMoreButton
            onClick={handleReimbursementMethod}
            text={t('reimbursementEdit')}
          />
        )}
      </div>

      <div className="Reimbursement-content">
        <h5>{t('reimbursementMethod')}</h5>

        <ReimbursementMethodCard {...reimbursementMethodCardProps} />
      </div>
    </div>
  );
};
