import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import infoIcon from '../../../../assets/plans/info.svg';
import { TOOL_TIP_POSITION } from '../../../common/IconTooltip';

import { SelectorPill } from './SelectorPill';
import CustomTooltip from '../../../common/CustomTooltip';

import './SelectorPills.css';

export const SelectorPills = ({
  children,
  items = [{ disabled: false, item: {}, value: '' }],
  onChange = () => { },
  position = TOOL_TIP_POSITION.start,
  title,
  tooltipDescription,
  value,
}) => {
  const dispatch = useDispatch();

  const onOptionClick = useCallback((item) => {
    if (item.disabled) {
      return;
    }

    onChange(item);
  }, [dispatch, onChange]);

  const titleForId = title.replace(/\s/g, '-');

  return (
    <fieldset className="Selector-pills-container">
      <span className="Selector-pills-title" id={`${titleForId}-label`}>
        <legend>{title}</legend>

        {tooltipDescription && (
          <CustomTooltip
            backgroundColor="var(--citron-shade-3)"
            borderColor="#000"
            description={tooltipDescription}
            icon={infoIcon}
            name={title}
            position={position}
          />
        )}
      </span>

      <div className="Selector-pills-options">
        {items.map((item) => (
          <SelectorPill
            key={item.value}
            checked={value === item.id}
            disabled={item.disabled}
            itemExtraData={item}
            label={item.value}
            name={title.replace(' ', '')}
            onChange={onOptionClick}
            value={item.value}
          />
        ))}

        {children}
      </div>
    </fieldset>
  );
};
