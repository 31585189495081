import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { HeaderFieldContent } from '../HeaderFieldContent';
import TouchableDiv from '../../../common/TouchableDiv';

import chboxUnselected from
'../../../../assets/inboxDocsMultibrand/check-unchecked.svg';
import chboxSelected
from '../../../../assets/inboxDocsMultibrand/check-checked.svg';

import { INBOX_DOCS, INBOX_DOCS_PRIVATE_FOLDERS } from '../../../../constants';
import { useResponsive } from '../../../../hooks/useResponsive';

import './InboxAndDocsTable.css';

const PRIVATE_FOLDERS = [...INBOX_DOCS_PRIVATE_FOLDERS, INBOX_DOCS.trash];

const InboxAndDocsTable = ({
  allRowsSelected = false,
  children = false,
  currentFolderId = 0,
  dateAddedFilter = false,
  fileSizeFilter = false,
  isAscendingFilter = true,
  isEmptyTrash = false,
  nameFilter = false,
  onAllRowSelection = () => { },
  onFileDateAddedFilterClick = () => { },
  onFileNameFilterClick = () => { },
  onFileSizeFilterClick = () => { },
}) => {
  const { t } = useTranslation('inboxAndDocs');
  const isTrashFolder = currentFolderId === INBOX_DOCS.trash;
  const isPrivateFolder = PRIVATE_FOLDERS.includes(currentFolderId);
  const { isTablet } = useResponsive();

  const checkboxes = useMemo(() => ({
    selected: chboxSelected,
    unselected: chboxUnselected,
  }), []);

  const nameColumnText = isTrashFolder || isEmptyTrash
    ? t('columnHeader.fileName') : t('columnHeader.name');

  function handleNameFilterClick() {
    onFileNameFilterClick();
  }

  function handleDateAddedFilter() {
    onFileDateAddedFilterClick();
  }

  function handleFileSizeFilter() {
    onFileSizeFilterClick();
  }

  function handleAllRowsSelection() {
    onAllRowSelection();
  }

  const styling = useMemo(() => {
    let container = 'Docs-table-container';
    let header = 'Docs-file-size-header';

    if ((isTrashFolder || isEmptyTrash)) {
      container += ' Docs-table-container-trash';
      header += ' Docs-file-size-header-trash';
    }

    return {
      container,
      header,
    };
  }, [isEmptyTrash, isTablet, isTrashFolder]);

  return (
    <div
      className={`Docs-table-main-container ${isTrashFolder
          && 'Margin-top-0'}`}
    >
      <table className={styling.container}>
        <thead>
          <tr className="Docs-table-header-row">
            <th className="Docs-select-icon-header-container">
              <TouchableDiv
                className="Docs-select-icon-header"
                id="headerChecboxSelectAllItems"
                onClick={handleAllRowsSelection}
              >
                <img
                  alt=""
                  src={allRowsSelected ? checkboxes.selected
                    : checkboxes.unselected}
                />
              </TouchableDiv>
            </th>

            <HeaderFieldContent
              className="Docs-name-header"
              id="docsNameHeader"
              isAscendingFilter={isAscendingFilter}
              isFilterActive={nameFilter}
              onClick={handleNameFilterClick}
              title={nameColumnText}
            />

            <HeaderFieldContent
              className="Docs-date-added-header"
              id="dateAddedHeader"
              isAscendingFilter={isAscendingFilter}
              isFilterActive={dateAddedFilter}
              onClick={handleDateAddedFilter}
              title={t('columnHeader.dateAdded')}
            />

            <HeaderFieldContent
              className={styling.header}
              id="fileSizeHeader"
              isAscendingFilter={isAscendingFilter}
              isFilterActive={fileSizeFilter}
              onClick={handleFileSizeFilter}
              title={t('columnHeader.fileSize')}
            />

            {!(isPrivateFolder || isEmptyTrash) && (
              <HeaderFieldContent
                className="Docs-assigned-header"
                hasFilter={false}
                id="assinedHeader"
                title={t('columnHeader.assigned')}
              />
            )}

            {!(isTrashFolder || isEmptyTrash) && (
              <th className="Docs-dots-menu-header">
                {t('columnHeader.threeDots')}
              </th>
            )}
          </tr>
        </thead>

        <tbody>
          {children}
        </tbody>
      </table>
    </div>
  );
};

export { InboxAndDocsTable };
