import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  myAccountHasChanges,
  petCloudEmailChanged,
  updatePetCloudEmail,
  refreshPersonalInformation,
} from '../../../../actions';

import { PetCloudInputAnimated }
from '../../../common/petCloud/PetCloudInputAnimated';

import { ActionButton } from '../../../common/petCloud/ActionButton';

import { ConfirmEmailChangesModal } from './ConfirmEmailChangesModal';
import { ForgotPasswordModal } from './ForgotPasswordModal';

import { removeEmojis } from '../../../../services/utils';

import '../css/PetCloudEdit.css';

const PetCloudEmailEdit = ({
  initialValue = '',
  onClickCancel = () => { },
}) => {
  const { t } = useTranslation('myAccount');
  const dispatch = useDispatch();
  const store = useSelector(({ myAccount }) => ({ myAccount }), shallowEqual);
  const { myAccount: {
    showEmailChangeModal,
    showForgotPasswordModal,
  } } = store;
  const [emailValue, setEmailValue] = useState(initialValue.split('@')[0]);
  const [emailError, setEmailError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const disabledButton = useMemo(() => emailValue === initialValue.split('@')[0]
    || emailError, [emailError, emailValue, initialValue]);

  useEffect(() => {
    dispatch(myAccountHasChanges({
      pageHasChanges: emailValue !== initialValue.split('@')[0],
    }));
  }, [emailValue, initialValue]);

  const handleEmailChange = useCallback(({ target }) => {
    const { value } = target;
    const cleanValue = removeEmojis(value);

    setEmailValue(cleanValue);
    dispatch(petCloudEmailChanged(cleanValue));
    setEmailError('');
  }, []);

  const handleOnEmailBlur = useCallback(() => {
    const error = emailValue ? '' : t('petCloudSection.requiredField');

    setEmailError(error);
  }, [emailValue]);

  const handleEmailUpdated = () => {
    setIsLoading(false);
    onClickCancel();
    dispatch(refreshPersonalInformation());
    dispatch(myAccountHasChanges({ pageHasChanges: false }));
  };

  const onClickSave = useCallback(() => {
    setIsLoading(true);
    dispatch(updatePetCloudEmail({
      successCallback: handleEmailUpdated,
    }));
  }, []);

  return (
    <div className="Pet-cloud-edit-container">
      <span>{t('petCloudSection.petCloudEmailEdit.description')}</span>

      <div className="Pet-cloud-email-container">
        <PetCloudInputAnimated
          error={emailError}
          inputClass="Pet-cloud-email-input"
          onBlur={handleOnEmailBlur}
          onChange={handleEmailChange}
          placeholder={t('petCloudSection.petCloudEmailEdit.username')}
          value={emailValue}
        />

        <span>{t('petCloudSection.petCloudEmailEdit.myPetCloud')}</span>
      </div>

      <div className="Pet-cloud-edit-save-button">
        <ActionButton
          disabled={disabledButton}
          loading={isLoading}
          onClick={onClickSave}
          title={t('petCloudSection.save')}
        />
      </div>

      <ConfirmEmailChangesModal
        emailValue={emailValue}
        isPetCoudEmail
        onClickCancel={onClickCancel}
        showEmailChangeModal={showEmailChangeModal}
      />

      <ForgotPasswordModal
        onClickCancel={onClickCancel}
        showForgotPasswordModal={showForgotPasswordModal}
      />
    </div>
  );
};

export { PetCloudEmailEdit };
