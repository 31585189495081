import React from 'react';
import { useTranslation } from 'react-i18next';
import checkIcon from '../../../assets/policy/check-green.svg';
import { useWebViewReskin } from '../../../hooks';

const PaymentMethod = ({ icon, title, isCreditCard, billingDetails }) => {
  const { t } = useTranslation('myAccount');
  const useMobileReskin = useWebViewReskin();

  const className = useMobileReskin
    ? 'Payment-method-card-type' : 'Payment-method-card-credit-card';

  return (
    <div className="Payment-method-card-data-container">
      <img
        alt=""
        className="Payment-method-card-icon"
        src={icon}
      />

      <div className="Payment-method-card-info-wrapper">
        <p className={className}>
          {title}&nbsp;XX
          {isCreditCard
            ? billingDetails?.CCCardNumber?.slice(-4)
            : billingDetails?.ACHAccountNumber?.slice(-4)}
        </p>

        {isCreditCard && (
          <p
            className={`${className}`
              + ` ${useMobileReskin ? 'Payment-method-card-exp' : ''}`}
          >
            <span>{t('billingAndPayments.exp')}</span>
            &nbsp;{billingDetails?.CCExpMonth}/{billingDetails?.CCExpYear}
          </p>
        )}
      </div>

      {!useMobileReskin && (
        <>
          <img
            alt=""
            className="Payment-method-card-icon-checked"
            src={checkIcon}
          />

          <span className="Payment-method-card-default">
            {t('billingAndPayments.default')}
          </span>
        </>
      )}
    </div>
  );
};

export { PaymentMethod };
