import React from 'react';

/**
 * @deprecated
 */
const TouchableDiv = ({
  ariaProps,
  children,
  className,
  divRef,
  id,
  onBlur = () => { },
  onClick = () => { },
  onKeyDown = () => { },
  onKeyPress = () => { },
  style,
  tabIndex = 0,
}) => {
  function handleModalOverlayClick(event) {
    if (event.target.id === id) {
      onClick(event);
    }
  }

  function handleModalOverlayKeyPress(event) {
    if (event.target.id === id) {
      onKeyPress(event);
    }
  }

  function handleModalOverlayKeyDown(event) {
    if (event.target.id === id) {
      onKeyDown(event);
    }
  }

  function handleModalOverlayOnBlur(event) {
    if (event.target.id === id) {
      onBlur(event);
    }
  }

  return (
    <div
      ref={divRef}
      className={className}
      id={id}
      onBlur={handleModalOverlayOnBlur}
      onClick={handleModalOverlayClick}
      onKeyDown={handleModalOverlayKeyDown}
      onKeyPress={handleModalOverlayKeyPress}
      role="button"
      tabIndex={tabIndex}
      {...ariaProps}
      style={style}
    >
      {children}
    </div>
  );
};

export default TouchableDiv;
