import React from 'react';
import { useTranslation } from 'react-i18next';

import { MY_ACCOUNT } from '../../../constants';

import './css/InsuranceAccountRow.css';

const InsuranceAccountRow = ({
  breadCrumbs = {},
  companyLogo = '',
  initialValue = '',
  isFirstRow = false,
  label = '',
}) => {
  const { t } = useTranslation('myAccount');

  const onPushToEditDetails = () => {
    if (breadCrumbs) {
      breadCrumbs.onAddBreadcrumbItem({
        id: MY_ACCOUNT.update,
        name: t('insuranceAccountSection.update'),
      });
    }
  };

  if (isFirstRow) {
    return (
      <div className="Insurance-account-row-first-row">
        <img alt="" src={companyLogo} />

        <button
          className="Insurance-account-row-edit-button"
          onClick={onPushToEditDetails}
          type="button"
        >
          <span>{t('insuranceAccountSection.editAccount')}</span>
        </button>
      </div>
    );
  }

  return (
    <div className="Insurance-account-row-container">
      <h4>{label}</h4>

      <span>{initialValue}</span>
    </div>
  );
};

export { InsuranceAccountRow };
