import React from 'react';
import checkIcon from '../../assets/myPets/check-icon.svg';
import plusIcon from '../../assets/myPets/add-icon.svg';
import './MyPetsButton.css';

const MyPetsButton = ({ text = '', isChecked = false, onClick = () => {} }) => (
  <button
    className={`My-pets-add-button-container ${isChecked
      ? 'My-pets-add-button-disabled'
      : ''}`}
    id={text}
    onClick={onClick}
    type="button"
  >
    <img
      alt=""
      className={`My-pets-add-button-no-clicked ${isChecked
        ? 'My-pets-add-button-img-check' : 'My-pets-add-button-img-add'}`}
      src={isChecked ? checkIcon : plusIcon}
    />

    <span className="My-pets-add-button-no-clicked">{text}</span>
  </button>
);

export { MyPetsButton };
