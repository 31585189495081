import React from 'react';
import { Image } from 'react-bootstrap';

import googlePlayBadge from '../../assets/google-play.svg';
import appStoreBadge from '../../assets/app-store.svg';

import { APP_STORE_URL, GOOGLE_PLAY_URL } from '../../constants';

const DownloadMobileApps = () => (
  <div className="Login-app-stores-container">
    <a href={GOOGLE_PLAY_URL} rel="noopener noreferrer" target="_blank">
      <Image alt="" src={googlePlayBadge} />
    </a>

    <a href={APP_STORE_URL} rel="noopener noreferrer" target="_blank">
      <Image alt="" src={appStoreBadge} />
    </a>
  </div>
);

export { DownloadMobileApps };
