export const getGreetingKey = () => {
  const today = new Date();

  const morning = new Date();
  morning.setHours(0, 1, 0);

  const afternoon = new Date();
  afternoon.setHours(12, 1, 0);

  const evening = new Date();
  evening.setHours(17, 1, 0);

  let greetingKey = 'eveningGreeting';

  if (today >= evening) {
    greetingKey = 'eveningGreeting';
  } else if (today >= afternoon) {
    greetingKey = 'afternoonGreeting';
  } else if (today >= morning) {
    greetingKey = 'morningGreeting';
  }

  return greetingKey;
};
