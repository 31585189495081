import React, { useEffect, useRef, useState } from 'react';
import './css/ModifierModalInfoItemContent.css';

const ModifierModalInfoItemContent = ({
  isBold,
  subtitle,
  title,
  value,
}) => {
  const titleRef = useRef();
  const subtitleRef = useRef();
  const [contentLoaded, setContentLoaded] = useState();

  useEffect(() => {
    if (titleRef.current && !contentLoaded) {
      let htmlContent = title;
      let htmlContainer = document.createElement('div');
      htmlContainer.innerHTML = htmlContent;
      titleRef.current.appendChild(htmlContainer);

      htmlContent = subtitle;
      htmlContainer = document.createElement('div');
      htmlContainer.innerHTML = htmlContent;
      subtitleRef.current.appendChild(htmlContainer);

      setContentLoaded(true);
    }
  }, [contentLoaded, subtitle, title]);

  return (
    <div className="Modifier-modal-info-content-row">
      <div className="Modifier-modal-info-content-row-top">
        <span
          ref={titleRef}
          className={`Modifier-modal-info-content-row-title ${isBold
            ? 'Modifier-modal-info-content-row-title-semi-bold' : ''}`}
        />

        <span className="Modifier-modal-info-content-row-value">
          {value}
        </span>
      </div>

      <div className={`Modifier-modal-info-content-row-middle ${subtitle
        ? '' : 'Modifier-modal-info-element-gone'}`}
      >
        <span
          ref={subtitleRef}
          className="Modifier-modal-info-content-row-subtitle"
        />
      </div>
    </div>
  );
};

export { ModifierModalInfoItemContent };
