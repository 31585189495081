import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'react-bootstrap/Button';
import { useDispatch } from 'react-redux';

import { oneIncPaymentReset } from '../../actions';

import ModalContainer from './ModalContainer';
import FigoLottie from './FigoLottie';
import LoadingButton from './LoadingButton';

import bankAccountIcon from '../../assets/updatePayment/bank-active.svg';
import creditCardIcon from '../../assets/updatePayment/bank-card.png';
import spinner from '../../assets/spinner.json';
import { doNothing } from '../../services/utils';

export const ONE_INC_PAYMENT_CATEGORY = {
  bankAccount: 'ACH',
  creditCard: 'CreditCard',
};

export const PAYMENT_TYPE = {
  bank: {
    name: 'Bank Account',
    value: 1,
  },
  creditCard: {
    name: 'Credit or Debit',
    value: 2,
  },
};

const OneIncDefaultPaymentMethod = ({
  forceClose = 0,
  isCreditCardDefault,
  onCancel = () => { },
  oneIncReducer,
  onNewAddPaymentMethod,
  onSaveComplete,
}) => {
  const { t } = useTranslation('paymentMethod');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [lastShowModal, setLasShowModal] = useState(0);
  const defaultPaymentMethod = useMemo(() => (isCreditCardDefault
    ? ONE_INC_PAYMENT_CATEGORY.creditCard
    : ONE_INC_PAYMENT_CATEGORY.bankAccount), [isCreditCardDefault]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState(defaultPaymentMethod);

  useEffect(() => {
    setSelectedPaymentMethod(defaultPaymentMethod);
  }, [defaultPaymentMethod]);

  function reset() {
    dispatch(oneIncPaymentReset());
    setLasShowModal(0);
    setSelectedPaymentMethod(defaultPaymentMethod);
    setShow(false);
  }

  useEffect(() => {
    if (oneIncReducer.showModal > lastShowModal) {
      setShow(true);
      setLoading(false);
      setSelectedPaymentMethod(selectedPaymentMethod);
      setLasShowModal(oneIncReducer.showModal);
    }
  }, [oneIncReducer.showModal]);

  useEffect(() => {
    if (forceClose) {
      reset();
    }
  }, [forceClose]);

  const onDefaultPaymentMethodUpdated = (data) => () => {
    setSelectedPaymentMethod(data);
  };

  function handleOnCancelModal() {
    onCancel();
    reset();
  }

  function handleUseOtherPaymentMethod() {
    onNewAddPaymentMethod();
    setLoading(true);
  }

  function handleSave() {
    const isCreditCard =
      selectedPaymentMethod === ONE_INC_PAYMENT_CATEGORY.creditCard;
    const method = isCreditCard
      ? oneIncReducer.creditCard : oneIncReducer.bankAccount;
    const customerName = `${method.AddressFirstName} ${method.LastName}`
      .replace(/null/g, '').trim();
    const data = {
      customerName,
      lastFourDigits: isCreditCard
        ? method.MaskedCardNumber.substring(4)
        : method.MaskedAccountNumber.substring(4),
      paymentCategory: selectedPaymentMethod,
      tokenId: null,
    };

    reset();
    onSaveComplete(data);
  }

  function renderOneItemDetails({ icon, lastFourDigits, title = '' }) {
    return (
      <div className="One-modal-details">
        <img alt="icon" className="One-modal-details-image" src={icon} />

        <div>
          <strong>{title.toUpperCase()}</strong>

          <span className="One-modal-last-digits">
            {lastFourDigits}
          </span>
        </div>
      </div>
    );
  }

  function renderCreditCardDeatils() {
    const { creditCard } = oneIncReducer;

    const maskedNumber = creditCard.MaskedCardNumber.substring(4);
    const details = {
      className: 'One-modal-details-image',
      icon: creditCardIcon,
      lastFourDigits: t('cardEnding', { maskedNumber }),
      title: creditCard.cardType || t('creditCardTitle'),
    };

    return renderOneItemDetails(details);
  }

  function renderCheckBox({ checked, isCreditCard }) {
    return (
      <div className="One-inc-radio-button-container">
        <label htmlFor={isCreditCard ? 'rb-credit-card' : 'rb-bank'}>
          <input
            aria-labelledby={isCreditCard ? 'rb-credit-card' : 'rb-bank'}
            checked={checked}
            id={isCreditCard ? 'rb-credit-card' : 'rb-bank'}
            onChange={doNothing}
            tabIndex={-1}
            type="radio"
            value={isCreditCard
              ? PAYMENT_TYPE.creditCard.value
              : PAYMENT_TYPE.bank.value}
          />
        </label>
      </div>
    );
  }

  function renderBankAccountDetails() {
    const { bankAccount } = oneIncReducer;

    const maskedNumber = bankAccount.MaskedAccountNumber.substring(4);
    const details = {
      className: 'One-modal-details-image',
      icon: bankAccountIcon,
      lastFourDigits: t('bankEnding', { maskedNumber }),
      title: t('bankTitle'),
    };

    return renderOneItemDetails(details);
  }

  function renderUpdateButton() {
    if (loading) {
      return (
        <div className="One-modal-details-button">
          <FigoLottie
            animationData={spinner}
            height={30}
            width={100}
          />
        </div>
      );
    }

    return (
      <button
        className="One-modal-details-button"
        onClick={handleUseOtherPaymentMethod}
        type="button"
      >
        {t('useOtherPaymentMethod')}
      </button>
    );
  }

  function renderTwoCardDetails() {
    const { bankAccount, creditCard } = ONE_INC_PAYMENT_CATEGORY;

    return (
      <>
        <div
          className={'d-flex flex-column'
            + `${isCreditCardDefault ? '-reverse' : ''}`}
        >
          <button
            className="One-modal-button-select"
            onClick={onDefaultPaymentMethodUpdated(bankAccount)}
            type="button"
          >
            <div className="d-flex">
              {renderCheckBox({
                checked: selectedPaymentMethod === bankAccount,
                isCreditCard: false,
              })}

              {renderBankAccountDetails()}
            </div>
          </button>

          <button
            className="One-modal-button-select"
            onClick={onDefaultPaymentMethodUpdated(creditCard)}
            type="button"
          >
            <div className="d-flex">
              {renderCheckBox({
                checked: selectedPaymentMethod === creditCard,
                isCreditCard: true,
              })}

              {renderCreditCardDeatils()}
            </div>
          </button>
        </div>

        {renderUpdateButton()}
      </>
    );
  }

  if (!oneIncReducer.isTwoDefaults) {
    return null;
  }

  return (
    <ModalContainer
      handleOutClick={handleOnCancelModal}
      show={show}
    >
      <div className="One-inc-payment-modal">
        <div className="d-flex Flex-column">
          <span className="One-inc-payment-title">
            {t('paymentMethodDefaultTitle')}
          </span>

          <p className="One-inc-payment-message">
            {t('paymentMethodDefaultLeyend')}
          </p>

          {renderTwoCardDetails()}

          <LoadingButton
            className="One-inc-payment-button-save"
            disabled={defaultPaymentMethod === selectedPaymentMethod}
            id="saveOneIncDefault"
            loading={false}
            onClick={handleSave}
            text={t('apply')}
            textStyle="One-inc-button-save-text"
          />

          <Button
            className="One-inc-payment-button-cancel"
            id="cancelMultiplePoliciesPayMethod"
            onClick={handleOnCancelModal}
            size="lg"
            variant="light"
          >
            <span className="One-inc-payment-button-cancel-text">
              {t('cancel')}
            </span>
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};

export { OneIncDefaultPaymentMethod };
