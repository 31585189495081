import React from 'react';
import { useTranslation } from 'react-i18next';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './SideMenu.css';
import { ReactComponent as InsuranceIcon }
from '../../assets/sideMenu/insurance.svg';
import { ReactComponent as HomeIcon }
from '../../assets/sideMenu/home.svg';
import { ReactComponent as ClaimsIcon }
from '../../assets/sideMenu/claims.svg';
import { ReactComponent as InboxDocsIcon }
from '../../assets/sideMenu/inbox-docs.svg';
import { ReactComponent as MyAccountIcon }
from '../../assets/sideMenu/my-account.svg';
import { ReactComponent as LogoutIcon }
from '../../assets/sideMenu/logout.svg';
import { ReactComponent as HeadsetIcon }
from '../../assets/sideMenu/headset.svg';
// import vetsIcon from '../../assets/sideMenu/vets-active.png';
// import vetsIconInactive from '../../assets/sideMenu/vets.png';
import * as routes from '../navigation/routes.constants';
import SideMenuItem from './SideMenuItem';
import '../navigation/HeaderCommon.css';
import { YourPetsSection } from './YourPetsSection';
import { SideMenuActionButton } from './SideMenuActionButton';

import { useLogoutSessionCleaner, useMixpanel } from '../../hooks';
import { EV2 } from '../../services/mixpanel';
import { ContactUsModal } from './contactUs/ContactUsModal';
import { CONTACT_US_STEPS } from './contactUs/contactUs.constants';

const SideMenu = (props) => {
  const { t } = useTranslation('sideMenu');
  const { logout } = useLogoutSessionCleaner();
  const { sendEvent } = useMixpanel();

  function handleClaimsClick() {
    props.onMenuItemPress();
    sendEvent(EV2({ source: 'Home Menu' }));
  }

  function handleInsuranceClick() {
    props.onMenuItemPress();
  }

  const onContactUsClick = () => {
    props.setContactUsVisible(true);
  };

  const onContactUsClose = () => {
    props.setContactUsVisible(false);
  };

  return (
    <div className="Side-menu">
      <Row className="Side-menu-body">
        <Col className="Side-menu-content">
          <Row>
            <Col className="Side-menu-links">
              <nav className="Flex-column">
                <SideMenuItem
                  Icon={HomeIcon}
                  label={t('home')}
                  onClick={props.onMenuItemPress}
                  to={routes.HOME}
                />

                <SideMenuItem
                  Icon={InsuranceIcon}
                  label={t('insurance')}
                  onClick={handleInsuranceClick}
                  to={routes.INSURANCE}
                />

                <SideMenuItem
                  Icon={ClaimsIcon}
                  label={t('claims')}
                  onClick={handleClaimsClick}
                  to={routes.CLAIMS}
                />

                <SideMenuItem
                  Icon={InboxDocsIcon}
                  label={t('inboxAndDocs')}
                  onClick={props.onMenuItemPress}
                  to={routes.INBOX_DOCS}
                />

                <SideMenuItem
                  Icon={MyAccountIcon}
                  label={t('myAccount')}
                  onClick={props.onMenuItemPress}
                  to={routes.MY_ACCOUNT}
                />

                {/* TODO uncomment when is ready to prod  */}
                {/* <SideMenuItem
                  activeIcon={vetsIcon}
                  exact
                  inactiveIcon={vetsIconInactive}
                  label={t('vets')}
                  onClick={props.onMenuItemPress}
                  to="/vets"
                /> */}
              </nav>
            </Col>
          </Row>

          <YourPetsSection
            onContactUsClick={onContactUsClick}
            onMenuItemPress={props.onMenuItemPress}
          />

          <ContactUsModal
            companyStep={CONTACT_US_STEPS.HOME}
            onClose={onContactUsClose}
            show={props.contactUsVisible}
          />

          <div className="Side-menu-logout-container">
            <SideMenuActionButton
              className="Side-menu-contact-us"
              Icon={HeadsetIcon}
              iconClassName="Side-menu-contact-us-icon"
              label={t('contactUs.menuTitle')}
              onClick={onContactUsClick}
            />

            <SideMenuActionButton
              className="Side-menu-logout"
              Icon={LogoutIcon}
              iconClassName="Side-menu-logout-icon"
              label={t('logout')}
              onClick={logout}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SideMenu;
