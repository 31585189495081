import { useCallback, useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { WEB_VIEW_TYPES } from '../constants';
import { getMultibrandWebView, webViewMultibrandReset } from '../actions';

const INITIAL_STATE = {
  companyId: null,
  marketChannelId: null,
  petId: null,
  policyId: null,
  policyNumber: '',
  webViewType: '',
};

const useWebviewMultibrand = () => {
  const { t } = useTranslation('multibrand');
  const dispatch = useDispatch();
  const { webViewData } =
    useSelector(({ multibrand }) => multibrand, shallowEqual);
  const [state, setState] = useState(INITIAL_STATE);
  const [title, setTitle] = useState('');
  const hasFetched = useRef(false);

  const getWebView = useCallback((params = INITIAL_STATE) => {
    setState(params);
    hasFetched.current = false;
  }, []);

  const reset = useCallback(() => {
    setState(INITIAL_STATE);
    dispatch(webViewMultibrandReset());
  }, []);

  useEffect(() => {
    if (state.webViewType && !hasFetched.current) {
      dispatch(getMultibrandWebView(state));
      hasFetched.current = true;
    }
  }, [dispatch, state]);

  useEffect(() => {
    if (state.webViewType
      && !webViewData.isLoading
      && webViewData.url
      && webViewData.webViewType === state.webViewType
    ) {
      let titleText = '';

      switch (state.webViewType) {
        case WEB_VIEW_TYPES.addingNewPetToPolicy:
          titleText = t('orcaInsurance.addNewPet.addANewPet').toUpperCase();
          break;
        case WEB_VIEW_TYPES.billingAndPayment:
          titleText = t('orcaInsurance.addNewPet.addANewPet').toUpperCase();
          break;
        case WEB_VIEW_TYPES.changeCoverage:
          titleText = t('orcaInsurance.changeCoverage').toUpperCase();
          break;
        default:
          titleText = '';
      }

      setState(INITIAL_STATE);
      setTitle(titleText);
      hasFetched.current = false;
    }
  }, [
    state,
    webViewData.url,
    webViewData.isLoading,
    webViewData.webViewType,
  ]);

  useEffect(() => () => {
    dispatch(webViewMultibrandReset());
  }, []);

  return {
    getWebView,
    isLoading: webViewData.isLoading,
    marketChannelId: state.companyId,
    reset,
    title,
    url: webViewData.url,
    webViewType: state.webViewType,
  };
};

export { useWebviewMultibrand };
