import React from 'react';
import { FILES_TYPES_ACCEPTED } from '../../../../constants';
import ScrollableDiv from '../../../common/ScrollableDiv';
import './FileViewerIFrame.css';

const FileViewerIFrame = ({
  filePath = '',
  fileType = '',
  fileViewerId = 'IFrameFileViewer',
  iFrameProps,
  styleProps,
}) => {
  const isTxtFile = fileType === FILES_TYPES_ACCEPTED.txt;

  return (
    <ScrollableDiv className="iframe-container">
      <iframe
        className={`iframe-content ${isTxtFile ? 'iframe-padding' : ''}`}
        id={fileViewerId}
        src={filePath}
        style={{ backgroundColor: '#fff', overflow: 'hidden', ...styleProps }}
        title="file"
        {...iFrameProps}
      />
    </ScrollableDiv>
  );
};

export { FileViewerIFrame };
