import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import checkIcon from '../../../assets/changeCoverage/check.svg';
import tabletPortrait from '../assets/tablet_confirmation.svg';
import tabletBalloon from '../assets/tablet_confirmation_balloon.svg';
import mobilePortrait from '../assets/mobile_confirmation.svg';
import mobileBalloon from '../assets/mobile_confirmation_balloon.svg';
import { useResponsive } from '../../../hooks/useResponsive';
import { Wrapper } from '../../common/Wrapper';
import { CONTACT_FIGO_URL } from '../../../constants';
import './SubmitSuccess.css';

const SubmitSuccess = ({ onClose = () => { } }) => {
  const { t } = useTranslation('changeCoverage');
  const store = useSelector(({ policies }) => ({ ...policies.changeCoverage }));
  const { rate: { effectiveDate, petQuoteResponseList } } = store;
  const { isTablet } = useResponsive();

  const petInfo = useMemo(() => {
    if (petQuoteResponseList && petQuoteResponseList.length) {
      return petQuoteResponseList[0];
    }

    return { Plans: [] };
  }, [petQuoteResponseList]);

  function renderPortrait() {
    return (
      <div className="Submit-portrait">
        <img
          alt="portrait"
          className="Submit-background"
          src={isTablet ? tabletPortrait : mobilePortrait}
        />

        <img
          alt="ballon"
          className="Submit-balloon"
          src={isTablet ? tabletBalloon : mobileBalloon}
        />
      </div>
    );
  }

  return (
    <div className="Submit-success-container">
      <Wrapper>
        {renderPortrait()}

        <div className="Flex-row Submit-title-container">
          <img
            alt="Powerup plus card"
            className="Check-button-plus"
            src={checkIcon}
          />

          <span className="Submit-title">{t('submit.title')}</span>
        </div>

        <span className="Submit-almost-done">{t('submit.almostDone')}</span>

        <div className="Submit-message-container">
          <p className="Submit-message">
            {t('submit.message', { petName: petInfo.petName })}

            <span className="Submit-date">{effectiveDate}</span>.

            {t('submit.moreInfo')}

            <a
              className="Submit-contact-us"
              href={CONTACT_FIGO_URL}
              rel="noopener noreferrer"
              target="_blank"
            >
              {t('submit.contactUs')}
            </a>
          </p>
        </div>

        <button onClick={onClose} type="button">{t('close')}</button>
      </Wrapper>
    </div>
  );
};

export { SubmitSuccess };
