import React, { useEffect, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useBreadcrumbNavigation } from '../../hooks/useBreadcrumbNavigation';
import { Breadcrumbs } from '../common/breadcrumbs/Breadcrumbs';
import { BillingAndPaymentsScreen }
from './BillingAndPayments/BillingAndPaymentsScreen';
import { MyAccountScreen } from './MyAccountScreen';
import { PetCloudAccountScreen } from './petCloudAccount/PetCloudAccountScreen';
import { InsuranceAccountScreen }
from './insuranceAccount/InsuranceAccountScreen';
import { ReimbursementScreen } from './reimbursement/ReimbursmentScreen';

import navArrowIcon from '../../assets/myAccount/nav-arrow.svg';
import { MY_ACCOUNT } from '../../constants';

import { PaymentHistoryScreen } from './paymentHistory/PaymentHistoryScreen';

import './css/MyAccountScreen.css';

const MyAccountNavigation = () => {
  const { t } = useTranslation('myAccount');
  const match = useRouteMatch();
  const history = useHistory();
  const locationState = history.location.state;

  const routeFormat = `${match.path}/:name/:id`;
  const breadCrumbs = useBreadcrumbNavigation({
    homeData: {
      id: MY_ACCOUNT.landing,
      name: t(`breadCrumbs.${MY_ACCOUNT.landing}`),
    },
    navigationParam: 'data',
    navTree: [],
    routeFormat,
  });

  const currentFolderId = useMemo(() => {
    if (!breadCrumbs.navData.length) {
      return MY_ACCOUNT.landing;
    }

    return breadCrumbs.navData[breadCrumbs.navData.length - 1].id;
  }, [breadCrumbs]);

  useEffect(() => {
    if (locationState) {
      breadCrumbs.onAddBreadcrumbItem({
        extraData: locationState.breadCrumbNameExtraData,
        id: locationState.breadCrumbId,
        name: locationState.breadCrumbName,
      });
    }
  }, [breadCrumbs, locationState]);

  function renderBreadCrumbs() {
    if (currentFolderId === MY_ACCOUNT.landing || !currentFolderId) {
      return null;
    }

    return (
      <Breadcrumbs
        containerClassName="Breadcrumbs-container"
        customNavArrow={navArrowIcon}
        itemActiveClassName="Breadcrumb-item-account-active"
        itemClassName="Breadcrumb-item-account"
        labelField="name"
        navData={breadCrumbs.navData}
        onNavItemClick={breadCrumbs.onBreadcrumbItemClick}
      />
    );
  }

  function renderMyAccountContent() {
    switch (currentFolderId) {
      case MY_ACCOUNT.landing:
        return <MyAccountScreen breadCrumbs={breadCrumbs} />;
      case MY_ACCOUNT.petCloudAccount:
        return <PetCloudAccountScreen />;
      case MY_ACCOUNT.insuranceAccount:
      case MY_ACCOUNT.update:
        return (
          <InsuranceAccountScreen
            breadCrumbs={breadCrumbs}
            currentFolderId={currentFolderId}
          />
        );
      case MY_ACCOUNT.billingAndPayments:
      case MY_ACCOUNT.billingAndPaymentDetails:
        return (
          <BillingAndPaymentsScreen
            breadCrumbs={breadCrumbs}
            currentFolderId={currentFolderId}
          />
        );
      case MY_ACCOUNT.paymentHistory:
      case MY_ACCOUNT.paymentHistoryList:
        return (
          <PaymentHistoryScreen
            breadCrumbs={breadCrumbs}
            currentFolderId={currentFolderId}
          />
        );
      case MY_ACCOUNT.reimbursement:
      case MY_ACCOUNT.reimbursementDetails:
        return (
          <ReimbursementScreen
            breadCrumbs={breadCrumbs}
            currentFolderId={currentFolderId}
          />
        );
      default:
        return <MyAccountScreen breadCrumbs={breadCrumbs} />;
    }
  }

  return (
    <div className="My-account-navigation-container">
      {renderBreadCrumbs()}

      {renderMyAccountContent()}
    </div>
  );
};

export { MyAccountNavigation };
