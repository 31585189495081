import React from 'react';
import { useTranslation } from 'react-i18next';

import './DateInput.css';

const DateInput = ({
  maxDate = '',
  minDate = '',
  onSelectedValidDate = () => { },
  selectedDate = '',
}) => {
  const { t } = useTranslation('fileClaimbot');

  const onDatePickerChange = ({ target: { value } }) => {
    const inRange = value >= minDate && value <= maxDate;
    onSelectedValidDate({ date: value, inRange });
  };

  return (
    <div className="File-claim-date-input-container">
      <span>{t('dateOfClaim')}</span>

      <input
        max={maxDate}
        min={minDate}
        onChange={onDatePickerChange}
        type="date"
        value={selectedDate}
      />
    </div>
  );
};

export { DateInput };
