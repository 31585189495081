import { handleActions } from 'redux-actions';

const INITIAL_STATE = {
  amount: '',
  anotherPetInsurance: '',
  anotherPetInsuranceCheckbox: null,
  attachments: [],
  checkboxYes: false,
  claimsDetails: '',
  claimType: -null,
  claimTypeText: '',
  date: '',
  initialTime: null,
  isCheckReimbursementMethodDataAvailable: false,
  isComplete: false,
  isDirectReimbursementMethodDataAvailable: false,
  isSendingClaim: false,
  isSuccess: false,
  isTermAndConditionOneSelected: false,
  isTermAndConditionTwoSelected: false,
  message: '',
  name: '',
  policyNumber: '',
  selectedPet: null,
  uploadAttLoading: false,
  uploadAttSuccess: false,
};

export default handleActions({
  ADD_ATTACHMENTS_LOAD: (state, { payload }) => ({
    ...state,
    uploadAttLoading: payload.loading,
    uploadAttSuccess: payload.success,
  }),
  CLAIM_DATE_CHANGED: (state, action) => ({
    ...state,
    date: action.payload,
  }),
  CLAIM_POLICY_CHANGED: (state, action) => ({
    ...state,
    policyNumber: action.payload,
  }),
  CLAIM_REIMBURSEMENT_CHECK_CHANGED: (state, { payload }) => ({
    ...state,
    isCheckReimbursementMethodDataAvailable: payload,
  }),
  CLAIM_REIMBURSEMENT_DIRECT_CHANGED: (state, { payload }) => ({
    ...state,
    isDirectReimbursementMethodDataAvailable: payload,
  }),
  CREATE_CLAIM: (state) => ({
    ...state,
    isSendingClaim: true,
  }),
  FILE_CLAIM_COMPLETED: (state, { payload }) => ({
    ...state,
    isComplete: true,
    isSendingClaim: false,
    isSuccess: payload.isSuccess,
    message: payload.message,
  }),
  FILE_CLAIM_NOT_COMPLETED: (state) => ({
    ...state,
    isComplete: false,
    isSendingClaim: false,
    isSuccess: false,
  }),
  FILE_CLAIMS_AMOUNT_CHANGED: (state, { payload }) => ({
    ...state,
    amount: payload,
  }),
  FILE_CLAIMS_ANOTHER_PET_INSURANCE_CHANGED: (state, { payload }) => ({
    ...state,
    anotherPetInsurance: payload,
  }),
  FILE_CLAIMS_ANOTHER_PET_INSURANCE_SELECTED: (state, { payload }) => ({
    ...state,
    anotherPetInsuranceCheckbox: true,
    checkboxYes: payload,
  }),
  FILE_CLAIMS_ATTACHMENT_ADDED: (state, { payload }) => ({
    ...state,
    attachments: [
      ...state.attachments,
      payload,
    ],
  }),
  FILE_CLAIMS_ATTACHMENT_REMOVED: (state, { payload }) => ({
    ...state,
    attachments:
      state.attachments.filter((attachment) => attachment.key !== payload),
  }),
  FILE_CLAIMS_ATTACHMENT_UPDATED: (state, { payload }) => ({
    ...state,
    attachments:
      state.attachments.map((attachment) => (
        attachment.key !== payload.key
          ? attachment
          : {
            ...attachment,
            ...payload,
          })),
  }),
  FILE_CLAIMS_DETAILS_CHANGED: (state, { payload }) => ({
    ...state,
    claimsDetails: payload,
  }),
  FILE_CLAIMS_NAME_CHANGED: (state, { payload }) => ({
    ...state,
    name: payload,
  }),
  FILE_CLAIMS_RESTART: () => ({
    ...INITIAL_STATE,
  }),
  FILE_CLAIMS_TYPE_CHANGED: (state, { payload }) => ({
    ...state,
    claimType: payload.claimTypeId,
    claimTypeText: payload.claimTypeDescription,
  }),
  INITIAL_TIME_SET: (state, { payload }) => ({
    ...state,
    initialTime: payload.initialTime,
  }),
  NEW_CLAIM_CANCELLED: (state) => ({
    ...state,
    date: '',
  }),
  SELECTED_PET_CHANGED: (state, action) => ({
    ...state,
    selectedPet: action.payload,
  }),
  TERM_AND_CONDITION_ONE_SELECTED: (state, { payload }) => ({
    ...state,
    isTermAndConditionOneSelected: payload,
  }),
  TERM_AND_CONDITION_TWO_SELECTED: (state, { payload }) => ({
    ...state,
    isTermAndConditionTwoSelected: payload,
  }),
}, INITIAL_STATE);
