import React, { useState, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import FigoLoadingOverlay from '../../../common/FigoLoadingOverlay';
import cloudsImage from '../../../../assets/clouds-icon.svg';
import { FILES_TYPES_ACCEPTED } from '../../../../constants';
import './FileViewer.css';

const FileViewer = ({
  customErrorComponent = null,
  filePath = '',
  fileType = 'pdf',
  fileViewerKey = 'Inbox&DocsFileViewer',
}) => {
  const isPDF = fileType === FILES_TYPES_ACCEPTED.pdf.split('.')[1];
  const [pages, setPages] = useState(null);
  const { t } = useTranslation('inboxAndDocs');

  function onDocumentLoadSuccess({ numPages }) {
    setPages(numPages);
  }

  const ErrorComponent = () => (
    <div className="File-viewer-error-container">
      <img alt="" src={cloudsImage} />

      <p>{t('fileViewer.errorMessage')}</p>
    </div>
  );

  function renderPDF() {
    return (
      <Document
        key={fileViewerKey}
        error={customErrorComponent || <ErrorComponent />}
        file={filePath}
        loading={<FigoLoadingOverlay visible />}
        onLoadSuccess={onDocumentLoadSuccess}
        options={{ workerSrc: '/pdf.worker.js' }}
      >
        {Array.from(new Array(pages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
    );
  }

  function renderImages() {
    const imageStyle = filePath ? 'File-viewer-image'
      : 'File-viewer-error-container';

    return (
      <img
        alt="file-viewer"
        className={imageStyle}
        src={filePath}
      />
    );
  }

  return (
    <div className="File-viewer-container-multibrand">
      <Suspense fallback={<FigoLoadingOverlay visible />}>
        {isPDF ? renderPDF() : renderImages()}
      </Suspense>
    </div>
  );
};

export { FileViewer };
