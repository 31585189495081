import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useOnEscEvent } from '../../../../hooks';
import { FileViewer } from './FileViewer';
import { RecentFileIcon } from '../../recentActivity/RecentFileIcon';
import closeIcon from '../../../../assets/inboxDocsMultibrand/close.svg';
import downloadIcon
from '../../../../assets/inboxDocsMultibrand/download-arrow.svg';
import { FileViewerIFrame } from './FileViewerIFrame';
import { FILES_TYPES_ACCEPTED } from '../../../../constants';
import { downloadFiles } from '../../../../actions';
import './ViewFileContent.css';

const ViewFileContent = ({ file, isLoading, onClose, show }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('inboxAndDocs');
  let fileExtension = file && file.Metadata.Extension.split('.')[1];
  fileExtension = fileExtension && fileExtension.toLowerCase();
  const fileName = `${file?.Name}.${fileExtension}`;
  const [hideStyle, setHideStyle] = useState('');
  const [renderIFrame, setRenderIFrame] = useState(false);
  const viewFileRef = useRef(null);

  useEffect(() => {
    if (isLoading) {
      setHideStyle('View-file-wrapper-hide');
    } else {
      setHideStyle('');
    }
  }, [isLoading]);

  useEffect(() => {
    if (file) {
      const { html, txt } = FILES_TYPES_ACCEPTED;
      const showIFrame = file.Metadata.Extension === html
        || file.Metadata.Extension === txt;

      setRenderIFrame(showIFrame);
    }
  }, [file]);

  useOnEscEvent(() => {
    if (show && !isLoading) {
      onClose();
    }
  }, [isLoading, onClose, show]);

  function onDownloadClick() {
    dispatch(downloadFiles({
      singleFile: true,
      singleFileInfo: file,
    }));
  }

  function renderHeader() {
    return (
      <div className="View-file-content-header">
        <div className="View-file-content-name-container">
          <RecentFileIcon
            alt="File Icon"
            fileExtension={file.Metadata.Extension?.toLowerCase()}
          />

          <span className="View-file-content-name">{fileName}</span>
        </div>

        <div className="View-file-content-button-container">
          <button
            className="View-file-content-download-button"
            onClick={onDownloadClick}
            type="button"
          >
            <div className="View-file-content-download-button-content">
              <img alt="Download" src={downloadIcon} />

              <span>{t('download')}</span>
            </div>

            <div className="View-file-content-download-bottom-line" />
          </button>

          <button
            className="View-file-content-close-button"
            id="viewFileCloseIcon"
            onClick={onClose}
            type="button"
          >
            <img alt="close" src={closeIcon} />
          </button>
        </div>
      </div>
    );
  }

  function renderFileViewer() {
    if (renderIFrame) {
      return (
        <FileViewerIFrame
          filePath={file.Metadata.Url}
          fileType={file.Metadata.Extension}
        />
      );
    }

    return (
      <FileViewer
        filePath={file.Metadata.Url}
        fileType={fileExtension}
        onError={onClose}
      />
    );
  }

  function renderViewFileContent() {
    return (
      <div
        ref={viewFileRef}
        className={`View-file-content-overlay ${hideStyle}`}
      >
        <div className="View-file-content-wrapper">
          {renderHeader()}

          {renderFileViewer()}
        </div>
      </div>
    );
  }

  return show ? renderViewFileContent() : null;
};

export { ViewFileContent };
