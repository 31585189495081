import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import arrowIcon from '../../../../assets/myAccount/arrow-chevron-down.svg';

import { PaymentHistoryDetailsRow } from './PaymentHistoryDetailsRow';

import { PaymentHistoryPageButton } from './PaymentHistoryPageButton';

import '../css/PaymentHistoryList.css';

const PaymentHistoryRow = ({
  amountValue = '',
  dateValue = '',
  descriptionValue = '',
  detailsValue = [],
  handleDetailsClick = () => { },
  handlePageButtonClick = () => { },
  isFooter = false,
  isHeader = false,
  loadingContent = true,
  methodValue = '',
  paymentHistoryCurrentItems = 0,
  paymentHistoryCurrentPage = 1,
  paymentHistoryTotalItems = 0,
  paymentHistoryTotalPages = 1,
  showDetails = false,
}) => {
  const { t } = useTranslation('myAccount');

  const totalPages = useMemo(() => Array.from({
    length: paymentHistoryTotalPages,
  }, (_, index) => ({
    index,
    pageNumber: index + 1,
  })), [paymentHistoryTotalPages]);

  function renderDetailsButton() {
    return (
      <button
        className="Payment-history-list-button"
        onClick={handleDetailsClick}
        type="button"
      >
        <span>{showDetails ? t('paymentHistorySection.hideDetails')
          : t('paymentHistorySection.viewDetails')}
        </span>

        <img
          alt=""
          className={`Payment-history-list-arrow${showDetails ? '-up' : ''}`}
          src={arrowIcon}
        />
      </button>
    );
  }

  if (isHeader) {
    return (
      <thead>
        <tr className="Payment-history-list-header">
          <th>{t('paymentHistorySection.dateTitle')}</th>

          <th>{t('paymentHistorySection.descriptionTitle')}</th>

          <th>{t('paymentHistorySection.methodTitle')}</th>

          <th>{t('paymentHistorySection.amountTitle')}</th>

          <th>{t('paymentHistorySection.detailsTitle')}</th>
        </tr>
      </thead>
    );
  }

  function renderPagination() {
    return totalPages.map(({ index, pageNumber }) => (
      <PaymentHistoryPageButton
        key={String(index)}
        handlePageButtonClick={handlePageButtonClick}
        index={index}
        isActive={paymentHistoryCurrentPage === pageNumber}
        pageNumber={pageNumber}
      />
    ));
  }

  if (isFooter) {
    const ITEMS_PER_PAGE = 10;
    const PREV_PAGE = paymentHistoryCurrentPage - 1;
    let baseTotalItems = 1;
    let totalItems = paymentHistoryCurrentItems;

    if (loadingContent) {
      return '';
    }

    if (paymentHistoryCurrentPage > 1) {
      baseTotalItems = (PREV_PAGE * ITEMS_PER_PAGE) + 1;
      totalItems = (PREV_PAGE * ITEMS_PER_PAGE) + paymentHistoryCurrentItems;

      if (paymentHistoryCurrentPage === paymentHistoryTotalPages) {
        totalItems = paymentHistoryTotalItems;
      }
    }

    return (
      <tr className="Payment-history-list-footer">
        <td>
          <span className="Payment-history-list-footer-text">
            {t('paymentHistorySection.showingItems', {
              baseItems: baseTotalItems,
              count: paymentHistoryTotalItems,
              currentItems: totalItems,
              totalItems: paymentHistoryTotalItems,
            })}
          </span>
        </td>

        <td className="Payment-history-list-footer-pag-container">
          {renderPagination()}
        </td>
      </tr>
    );
  }

  return (
    <>
      <tr className={`Payment-history-list-row${showDetails
        ? '-details' : ''}`}
      >
        <td>{dateValue}</td>

        <td>{descriptionValue}</td>

        <td>{methodValue}</td>

        <td>{amountValue}</td>

        <td>{renderDetailsButton()}</td>
      </tr>

      {showDetails && (
        <PaymentHistoryDetailsRow
          amountValue={amountValue}
          detailsValue={detailsValue}
          totalTitle={t('paymentHistorySection.total')}
        />
      )}
    </>
  );
};

export { PaymentHistoryRow };
