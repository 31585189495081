import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  deductibleChanged,
  getChangeCoverageMultimodal,
  initialPowerUps,
  powerUpSelected,
  quoteTotalRecalculated,
  rateCoverage,
  reimbursementChanged,
  restartRate,
  reviewChangesVisibleChanged,
  selectedPlanChanged,
  updatePowerUp,
} from '../../../../actions';
import { useMixpanel, usePMS } from '../../../../hooks';
import {
  POWERUP_TYPE,
  POWER_UPS_RELATION,
  WELLNESS_COVERAGE_LIMIT_FPI,
  WELLNESS_MULTIMODAL_ID,
} from '../../../changeCoverage/powerups/powerups.constans';
import { isWellnessFPI } from '../../../changeCoverage/utils';
import { MULTIMODAL_SECTIONS } from '../../../changeCoverage/constants';
import { INSURANCE } from '../../../navigation/routes.constants';
import { EV1 } from '../../../../services/mixpanel';
import { getBrandNameByChannelId } from '../../../../services/utils';

const {
  DISMINISHING_DEDUCTIBLE,
  PETPROTECT_COPAY,
  WELLNESS_DENTAL_PSM_ID,
  WELLNESS_PSM_ID,
} = POWER_UPS_RELATION;

const useCoverageOptions = ({
  onRateCreationError = () => { },
  policyNumber = '',
  validatePromptWarning = () => true,
}) => {
  const dispatch = useDispatch();
  const { sendEvent } = useMixpanel();
  const { pms } = usePMS();
  const history = useHistory();
  const [multiModalCategoryId, setMultiModalCategoryId] = useState(0);
  const [multiModalSectionSelected, setMultiModalSectionSelected] = useState(0);
  const [multiModalVisible, setMultiModalVisible] = useState(false);
  const [rateErrorModalVisible, setRateErrorModalVisible] = useState(false);
  const store = useSelector(({ policies }) => ({
    ...policies.changeCoverage,
    allPolicies: policies.allPolicies,
  }), shallowEqual);

  const {
    deductibleLegend,
    loading,
    loadingSubmit,
    multimodalData,
    quoteSelectedValues,
    rate: {
      effectiveDate,
      error: rateError,
      insuranceProduct,
      petQuoteResponseList = [],
      rateCreated,
    },
    powerUpLoadings,
    reviewChangesVisible,
    success,
    allPolicies,
  } = store;

  const { selectedPlan } = quoteSelectedValues;

  useEffect(() => {
    dispatch(rateCoverage({ policyNumber }));
  }, [policyNumber]);

  useEffect(() => {
    setRateErrorModalVisible(rateError);
  }, [rateError]);

  const petInfo = useMemo(() => {
    if (petQuoteResponseList.length) {
      return petQuoteResponseList[0];
    }
    return {
      Plans: [],
    };
  }, [petQuoteResponseList]);

  useEffect(() => {
    if (selectedPlan) {
      dispatch(quoteTotalRecalculated());
    }
  }, [
    quoteSelectedValues.deductibleId,
    quoteSelectedValues.reimbursementId,
    selectedPlan,
    petInfo,
  ]);

  useEffect(() => {
    if (rateCreated && petInfo.Plan) {
      const {
        Deductible,
        Deductibles,
        modifiers,
        Plan,
        Plans,
        Reimbursement,
        Reimbursements,
      } = petInfo;

      dispatch(selectedPlanChanged({
        planId: Plan,
        selectedAnnualBenefit:
          Plans.find((plan) => plan.Plan === Plan)?.MaxAnnual,
      }));
      const reimbursementDesc = Reimbursements
        .find((item) => item.Id === Reimbursement).Description || '';
      dispatch(reimbursementChanged({
        description: reimbursementDesc,
        reimbursementId: Reimbursement,
      }));
      const deductibleDesc = Deductibles
        .find((item) => item.Id === Deductible).Description || '';
      dispatch(deductibleChanged({
        deductibleId: Deductible,
        description: deductibleDesc,
      }));
      dispatch(initialPowerUps({ modifiers }));

      dispatch(getChangeCoverageMultimodal({ policyNumber }));
    }
  }, [rateCreated]);

  const policyBrandName = useMemo(() => {
    const foundPolicy = allPolicies
      ?.find((policyItem) => policyItem.Policy.Number === policyNumber);
    const productFamilyId = foundPolicy?.Policy.ProductFamilyId ?? '';
    return getBrandNameByChannelId(productFamilyId);
  }, [allPolicies, policyNumber]);

  const petProtectCopayModifier = useMemo(() => {
    if (loading || !petQuoteResponseList?.length) {
      return false;
    }

    const items = petQuoteResponseList[0];

    return items.InsuranceModifiers
      .find((modifier) => modifier.PMSModifierId
        === POWER_UPS_RELATION.PETPROTECT_COPAY);
  }, [loading, petQuoteResponseList]);

  const multimodalDataSelected = useMemo(() => {
    let selection = null;
    if (multimodalData) {
      selection = multimodalData.InsuranceMultiModalSectionDtoList
        .find((data) => data.Id === multiModalSectionSelected);
    }

    if (!selection && multiModalSectionSelected && insuranceProduct) {
      const powerupInfo = insuranceProduct.InsuranceModifiers
        .find((modifier) => modifier.PMSModifierId
          === multiModalSectionSelected);

      return {
        InsuranceMultiModalCategoryDtoList: [{
          CategoryHeader: powerupInfo.TitleText,
          OptionalBenefitsDetailsItems: [
            ...powerupInfo.OptionalBenefitsDetailsItem,
          ],
          ...powerupInfo,
        }],
        Title: powerupInfo.TitleText,
      };
    }
    return selection;
  }, [multimodalData, multiModalSectionSelected]);

  function handleAddPowerup({ isSelected = false, powerUp }) {
    const { Id } = powerUp;
    const { InsuranceModifiers } = insuranceProduct;
    const previosQuoteModifiers = (petInfo.modifiers || []);

    dispatch(powerUpSelected({ id: Id, isSelected }));

    const allModifiers = InsuranceModifiers
      .filter((modifier) => modifier.InsuranceModifierTypeId === POWERUP_TYPE
        && modifier.IsVisible)
      .map((item) => ({ id: item.Id, isSelected: false }));

    let modifiers = allModifiers.map((item) => {
      if (item.id === Id) {
        return { id: Id, isSelected };
      }

      const currentModifier = previosQuoteModifiers
        .find((petModifier) => petModifier.id === item.id) || {};

      return {
        ...item,
        isSelected: !!currentModifier.isSelected,
      };
    });

    // Diminishing Deductible modifier and child (if exist)
    const diminishingDeductible = InsuranceModifiers
      .find((modifier) => modifier.InsuranceModifierTypeId === POWERUP_TYPE
        && modifier.PMSModifierId === DISMINISHING_DEDUCTIBLE);
    let diminishingDeductibleChild = [];

    if (diminishingDeductible) {
      diminishingDeductibleChild =
        diminishingDeductible.BundleInsuranceModifiers
          .map((item) => ({ id: item.Id, isSelected: true }));

      modifiers = [
        ...modifiers,
        {
          id: diminishingDeductible.Id,
          isSelected: true,
        },
        ...diminishingDeductibleChild,
      ];
    }

    const wellnessPowerup = InsuranceModifiers
      .find((modifier) => modifier.InsuranceModifierTypeId === POWERUP_TYPE
        && modifier.IsVisible && modifier.PMSModifierId === WELLNESS_PSM_ID);
    const isFPI = wellnessPowerup
      && isWellnessFPI(wellnessPowerup, insuranceProduct);

    const petProtectCopayPowerup = InsuranceModifiers
      .find((modifier) => modifier.InsuranceModifierTypeId === POWERUP_TYPE
        && modifier.IsVisible && modifier.PMSModifierId === PETPROTECT_COPAY);

    if (petProtectCopayPowerup) {
      const ppipFather = { id: petProtectCopayPowerup.Id, isSelected };
      const ppipChildren = petProtectCopayPowerup.BundleInsuranceModifiers
        .map((child) => (child.Id === Id
          ? { id: child.Id, isSelected }
          : { id: child.Id, isSelected: false }));
      if (powerUp.BenefitParentId === petProtectCopayPowerup.Id) {
        modifiers = modifiers
          .filter((element) => element.id !== petProtectCopayPowerup.Id);
        modifiers = [
          ...modifiers,
          ...ppipChildren,
          ppipFather,
        ];
      } else {
        // add previous ppic
        const ppicChildrenIds = ppipChildren
          .map((child) => child.id);
        const previousPPIC = previosQuoteModifiers
          .filter((prevModifier) => ppicChildrenIds
            .includes(prevModifier.id));
        modifiers = [...modifiers, ...previousPPIC];
      }
    }

    if (isFPI) {
      const wellnessfather = { id: wellnessPowerup.Id, isSelected };
      const wellnessChildren = wellnessPowerup.BundleInsuranceModifiers
        .map((child) => {
          if (child.PMSModifierId === WELLNESS_DENTAL_PSM_ID
            || child.Id === Id) {
            return { id: child.Id, isSelected };
          }

          return { id: child.Id, isSelected: false };
        });

      if (wellnessChildren.find((child) => child.id === Id)) {
        modifiers = modifiers
          .filter((element) => element.id !== wellnessPowerup.Id);
        // any wellness clidren is selected
        modifiers = [
          ...modifiers,
          ...wellnessChildren,
          wellnessfather,
        ];
      } else {
        // add previous wellness powerups selected
        const wellnessChildrenIds = wellnessChildren
          .map((child) => child.id);
        const previousWellness = previosQuoteModifiers
          .filter((prevModifier) => wellnessChildrenIds
            .includes(prevModifier.id));

        modifiers = [...modifiers, ...previousWellness];
      }
    }

    if (powerUp.PMSModifierId === POWER_UPS_RELATION.WELLNESS_DENTAL_PSM_ID) {
      // Wellness IHC
      const childrenWellness = wellnessPowerup.BundleInsuranceModifiers
        .map((element) => ({ id: element.Id, isSelected }));

      modifiers = modifiers.concat(childrenWellness);
    }

    dispatch(updatePowerUp({ id: Id, isSelected, modifiers, policyNumber }));
  }

  function handlePetProtectCopayInfoClick() {
    setMultiModalVisible(true);
    setMultiModalSectionSelected(MULTIMODAL_SECTIONS.petProtectCopay);
  }

  function handleWellnessClick(coverageLimitId) {
    let newCategoryId = 0;
    if (coverageLimitId) {
      newCategoryId = WELLNESS_COVERAGE_LIMIT_FPI.BASIC
        .includes(coverageLimitId)
        ? WELLNESS_MULTIMODAL_ID.BASIC
        : WELLNESS_MULTIMODAL_ID.PLUS;
    }
    setMultiModalCategoryId(newCategoryId);
    setMultiModalVisible(true);
    setMultiModalSectionSelected(MULTIMODAL_SECTIONS.wellnessDetails);
  }

  function handleVetFeesClick(PMSModifierId) {
    setMultiModalVisible(true);
    setMultiModalSectionSelected(PMSModifierId);
  }

  function handleReviewChangesClick() {
    dispatch(reviewChangesVisibleChanged(true));
  }

  function onReviewChangesClosed() {
    dispatch(reviewChangesVisibleChanged(false));
  }

  function handleCloseMultiModal() {
    setMultiModalVisible(false);
  }

  function handleErrorModalClose() {
    setRateErrorModalVisible(false);
    onRateCreationError();
  }

  function cleanUpRate() {
    dispatch(restartRate());
  }

  function shouldPromptWarning(_crntLocation, nextLocation) {
    if (!nextLocation) {
      return true;
    }

    return validatePromptWarning(_crntLocation, nextLocation);
  }

  function handleCloseSubmit() {
    const source = window.location.pathname;
    sendEvent(EV1({ pms, policyBrand: policyBrandName, source }));
    history.push(INSURANCE, { changeCoverageId: policyNumber });
  }

  function handleGoBack() {
    history.goBack();
  }

  useEffect(() => () => onReviewChangesClosed(), []);

  return {
    cleanUpRate,
    deductibleLegend,
    effectiveDate,
    handleAddPowerup,
    handleCloseMultiModal,
    handleCloseSubmit,
    handleErrorModalClose,
    handleGoBack,
    handlePetProtectCopayInfoClick,
    handleReviewChangesClick,
    handleVetFeesClick,
    handleWellnessClick,
    insuranceProduct,
    loading,
    loadingSubmit,
    multiModalCategoryId,
    multimodalDataSelected,
    multiModalVisible,
    onReviewChangesClosed,
    petInfo,
    petProtectCopayModifier,
    policyBrandName,
    powerUpLoadings,
    quoteSelectedValues,
    rateErrorModalVisible,
    reviewChangesVisible,
    shouldPromptWarning,
    success,
  };
};

export { useCoverageOptions };
