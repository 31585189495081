import React, { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { useWebviewMultibrand } from '../../../hooks';

import {
  MY_ACCOUNT, WEB_VIEW_TYPES,
  PAYMENT_PLANS_BY_NAME,
} from '../../../constants';
import { MARKETING_CHANNEL_ID } from '../../../theme/theme.constants';
import { getThemeLogoById } from '../../../theme/theme.utils';

import './css/PolicyCard.css';

const PolicyCard = ({
  billingPayments = {},
  breadCrumbs = null,
  marketingChannelId = 0,
  pets = [],
  policyId = '',
  policyNumber = '',
  policy,
}) => {
  const { t } = useTranslation('myAccount');
  const history = useHistory();
  const location = useLocation();
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const logo = useMemo(() => {
    const theme = getThemeLogoById(marketingChannelId);
    return theme.dark;
  }, [marketingChannelId]);
  const isNotPPIOnePack = marketingChannelId
    !== MARKETING_CHANNEL_ID.ppiOnePack;
  const {
    getWebView,
    isLoading,
    title,
    url,
  } = useWebviewMultibrand();
  useEffect(() => {
    if (url && !isLoading && breadCrumbs && selectedPolicy) {
      breadCrumbs.onAddBreadcrumbItem({
        extraData: {
          marketingChannelId: selectedPolicy?.MarketingChannelId,
          policyNumber,
          title,
          url,
        },
        id: MY_ACCOUNT.billingAndPaymentDetails,
        name: t('breadCrumbs.breadCrumbPolicy', {
          policyNumber,
        }),
      });
    }
  }, [url, isLoading, breadCrumbs, selectedPolicy]);

  const onAddFigoBreadcrumbVariant = () => {
    breadCrumbs.onAddBreadcrumbItem({
      extraData: { marketingChannelId, policyNumber },
      id: MY_ACCOUNT.billingAndPaymentDetails,
      name: t('breadCrumbs.breadCrumbPolicy', {
        policyNumber,
      }),
    });
  };

  const onViewDetails = () => {
    history.push(`${location.pathname}/${policyNumber}${location.search}`);
  };

  function onClickViewDetails() {
    setSelectedPolicy(policy);
    if (marketingChannelId === MARKETING_CHANNEL_ID.figo) {
      onViewDetails();
      onAddFigoBreadcrumbVariant();
    } else {
      getWebView({
        companyId: marketingChannelId,
        policyId,
        policyNumber,
        webViewType: WEB_VIEW_TYPES.billingAndPayment,
      });
    }
  }

  return (
    <div className="Policy-card-container">
      <div className="Policy-card-data-container">
        <div className="Policy-card-price-container">
          <img
            alt="Policy Logo"
            className={`Policy-card-price-img 
            Policy-card-price-img-${marketingChannelId}`}
            loading="lazy"
            src={logo}
          />
          {isNotPPIOnePack
            ? (
              <p className="Policy-card-price-text">
                ${billingPayments.Premium}
                <span>{billingPayments
                  ?.BillingCycle === PAYMENT_PLANS_BY_NAME.monthly
                  ? t('billingAndPayments.month') : t('billingAndPayments.yr')}
                </span>
              </p>
            )
            : (
              <p className="Policy-card-price-text">
                <span>
                  {t('billingAndPayments.managedBy')}
                  &nbsp;{billingPayments?.GroupName}
                </span>
              </p>
            )}
        </div>

        <div className="Policy-card-details-container">
          <div className="Policy-card-details-item">
            <h6 className="Policy-card-details-item-title">
              {isNotPPIOnePack ? t('billingAndPayments.policyNumber')
                : t('billingAndPayments.certificateNumber')}
            </h6>

            <p className="Policy-card-details-item-content">
              {policyNumber}
            </p>
          </div>

          <div className="Policy-card-details-item">
            {isNotPPIOnePack ? (
              <>
                <h6 className="Policy-card-details-item-title">
                  {t('billingAndPayments.paymentMethod')}
                </h6>

                <p className="Policy-card-details-item-content">
                  {billingPayments?.PaymentMethod}
                </p>
              </>
            )
              : (
                <>
                  <h6 className="Policy-card-details-item-title">
                    {t('billingAndPayments.petsOnThisPolicy')}
                  </h6>

                  <p className="Policy-card-details-item-content">
                    {pets.map((pet) => pet.PetName).join(', ')}
                  </p>
                </>
              )}
          </div>

          <div className="Policy-card-details-item">
            {isNotPPIOnePack
              ? (
                <>
                  <h6 className="Policy-card-details-item-title">
                    {t('billingAndPayments.petsOnThisPolicy')}
                  </h6>
                  <p className="Policy-card-details-item-content">
                    {pets.map((pet) => pet.PetName).join(', ')}
                  </p>
                </>
              )
              : (
                <>
                  <h6 className="Policy-card-details-item-title-onePack">
                    {t('billingAndPayments.petsOnThisPolicyOnePack')}
                  </h6>
                  <p className="Policy-card-details-item-content-onePack">
                    {t('billingAndPayments.contentOnePack')}
                  </p>
                </>
              )}
          </div>
        </div>
      </div>

      <div className="Policy-card-button-container">
        {isNotPPIOnePack && (
          <button
            className="Policy-card-button-details"
            onClick={onClickViewDetails}
            type="button"
          >
            {t('paymentHistorySection.viewDetails')}
          </button>
        )}
      </div>
    </div>
  );
};

export default PolicyCard;
