import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cleanMyVetsErrors, savePrimaryVet } from '../../../actions';
import { AddVetModal } from '../../myPets/addVetModal/AddVetModal';
import { ConfirmPrimaryVetModal } from './ConfirmPrimaryVetModal';
import { NoVetModal } from './NoVetModal';

const AddPrimaryVet = ({ onClose = () => { } }) => {
  const [showNoVetModal, setShowNoVetModal] = useState(true);
  const [showAddVet, setShowAddVet] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedVet, setSelectedVet] = useState(null);
  const {
    isSavingVet,
    addVetError,
  } = useSelector(({ myVets }) => ({ ...myVets }));

  const dispatch = useDispatch();

  useEffect(() => () => {
    dispatch(cleanMyVetsErrors());
  }, []);

  useEffect(() => {
    if (selectedVet) {
      setShowConfirmationModal(true);
      setShowAddVet(false);
    }
  }, [selectedVet]);

  function handleAddVetClick() {
    setShowAddVet(true);
    setShowNoVetModal(false);
  }

  function goBackToVetSelector() {
    setShowAddVet(true);
    setShowConfirmationModal(false);
    setSelectedVet(null);
    dispatch(cleanMyVetsErrors());
  }

  function closeModals() {
    onClose();
  }

  function addPrimaryVet() {
    dispatch(savePrimaryVet({
      isCustomVet: selectedVet.isCustom,
      selectedVetId: selectedVet.Id,
      stateId: selectedVet.StateId,
      vetCity: selectedVet.City,
      vetCompany: selectedVet.Name,
    }));
  }

  return (
    <>
      <NoVetModal
        onAddVetClick={handleAddVetClick}
        onClose={closeModals}
        show={showNoVetModal}
      />

      {showAddVet && (
        <AddVetModal
          customVetShowCancelButton
          displaySelectOnRows
          hideZipcode
          onClose={closeModals}
          onSelectedVet={setSelectedVet}
          primaryVet
          selectedVet={selectedVet}
        />
      )}

      {selectedVet && (
        <ConfirmPrimaryVetModal
          error={addVetError}
          loading={isSavingVet}
          onBack={goBackToVetSelector}
          onClose={closeModals}
          onConfirm={addPrimaryVet}
          show={showConfirmationModal}
          vetAddress={selectedVet.isCustom
            ? `${selectedVet.City}, ${selectedVet.StateName}`
            : selectedVet.Address}
          vetName={selectedVet.Name}
        />
      )}
    </>
  );
};

export { AddPrimaryVet };
