import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { showGenericError } from '../../actions';
import { ContactUsModal } from '../sideMenu/contactUs/ContactUsModal';
import { CONTACT_US_STEPS }
from '../sideMenu/contactUs/contactUs.constants';

import Header from '../navigation/Header';
import ErrorDogImage from '../../assets/error-dog.svg';

import './GenericError.css';

const GenericError = () => {
  const { t } = useTranslation('genericError');
  const dispatch = useDispatch();
  const history = useHistory();

  const store = useSelector(({
    personalInformation,
  }) => ({
    personalInformation,
  }), shallowEqual);

  const {
    personalInformation,
  } = store;

  const hasPersonalInformation =
    Object.keys(personalInformation.userInfo).length > 0;

  const [contactUsVisible, setContactUsVisible] = useState(false);

  useEffect(() => {
    dispatch(showGenericError(false));
  }, []);

  const onContactUsClick = () => {
    setContactUsVisible(true);
  };

  const onContactUsClose = () => {
    setContactUsVisible(false);
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <>
      {!hasPersonalInformation && <Header />}

      <div className="Generic-error-container">
        {hasPersonalInformation && (
          <ContactUsModal
            companyStep={CONTACT_US_STEPS.HOME}
            onClose={onContactUsClose}
            show={contactUsVisible}
          />
        )}

        <div className="Generic-error-inner-container">
          <img alt="" className="Generic-error-image" src={ErrorDogImage} />

          <h2 className="Generic-error-title">{t('title')}</h2>

          <p className="Generic-error-message">{t('message')}</p>

          {hasPersonalInformation && (
            <button
              className="Generic-error-button"
              onClick={onContactUsClick}
              type="button"
            >
              {t('buttonTitle')}
            </button>
          )}

          <button
            className="Generic-error-back"
            onClick={goBack}
            type="button"
          >
            {t('back')}
          </button>
        </div>
      </div>
    </>
  );
};

export default GenericError;
