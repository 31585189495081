import { useCallback, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  logOut,
  tokenClaimLoaded,
  tokenCustomerLoaded,
  tokenPolicyLoaded,
  tokenRefreshed,
} from '../actions';
import {
  b2cPolicies,
  claimBotScope,
  claimScope,
  customerScope,
  documentsScope,
  policyScope,
  webviewScope,
} from '../authConfigB2C';
import { getData, removeAllData, saveData } from '../services/api';
import {
  API_TOKEN,
  CLAIMS_API_TOKEN,
  CUSTOMER_API_TOKEN,
  IMPERSONATE_KEY,
  POLICY_API_TOKEN,
  WEBVIEW_API_TOKEN,
} from '../services/constants';
import { createLogAuth } from '../sagas/session';
import { MIXPANEL_LOGIN_EVENTS } from '../services/mixpanel';

const SLEEP_TIME = 2000;

const useB2CInfo = () => {
  const { accounts, instance } = useMsal();
  const dispatch = useDispatch();
  const store = useSelector(({ session }) => ({ session }), shallowEqual);
  const [sleepTime, setSleepTime] = useState(0);

  const logoutB2C = useCallback(async () => {
    const postLogoutRedirectUri = window.location.origin;
    const b2cAuthority = b2cPolicies.authorities.signUpSignIn.authority;
    const logoutRequest = {
      account: instance.getActiveAccount(),
      authority: b2cAuthority,
      postLogoutRedirectUri,
    };

    dispatch(logOut());
    removeAllData();

    instance.logoutRedirect(logoutRequest);
  }, [instance, store.session.isImpersonate]);

  const tokenRequestB2C = useCallback(async ({
    authority = b2cPolicies.authorities.signUpSignIn.authority,
    scopes = [],
    tokenKey = '',
  }) => {
    let apiToken;

    try {
      const apiResponse = await instance.acquireTokenSilent({
        account: accounts[0],
        authority,
        scopes,
      });

      apiToken = apiResponse?.accessToken || apiResponse?.idToken;

      saveData(tokenKey, apiToken);

      if (tokenKey === CUSTOMER_API_TOKEN) {
        dispatch(tokenCustomerLoaded());
      } else if (tokenKey === CLAIMS_API_TOKEN) {
        dispatch(tokenClaimLoaded());
      } else if (tokenKey === POLICY_API_TOKEN) {
        dispatch(tokenPolicyLoaded());
      }
    } catch (error) {
      await createLogAuth(({
        dataDogTag: 'acquireTokenSilent',
        error: error.message,
        mixpanelKey: MIXPANEL_LOGIN_EVENTS.getApiTokens,
      }));

      logoutB2C();
    }

    return apiToken;
  }, [accounts, instance]);

  /**
   * Function to get every token for each api
   * with their required scopes.
   * @param {Array} scopes required scopes for desired api
   * @param {String} tokenKey custom keyname to store and use on token
   * @param {String} authority to request token depending user flow
   */
  const getAllTokens = useCallback(async () => {
    if (sleepTime > 0) {
      // skip many refresh token request
      dispatch(tokenRefreshed());
      return;
    }

    setSleepTime(SLEEP_TIME);

    const impersonate = Boolean(getData(IMPERSONATE_KEY));

    const authority = impersonate
      ? b2cPolicies.authorities.impersonation.authority
      : b2cPolicies.authorities.signUpSignIn.authority;

    const hasToken = await tokenRequestB2C({
      authority,
      scopes: [customerScope],
      tokenKey: API_TOKEN,
    });

    if (!hasToken) {
      return;
    }

    await Promise.all([
      tokenRequestB2C({
        authority,
        scopes: [customerScope, documentsScope],
        tokenKey: CUSTOMER_API_TOKEN,
      }),
      tokenRequestB2C({
        authority,
        scopes: [claimBotScope, claimScope],
        tokenKey: CLAIMS_API_TOKEN,
      }),
      tokenRequestB2C({
        authority,
        scopes: [policyScope],
        tokenKey: POLICY_API_TOKEN,
      }),
      tokenRequestB2C({
        authority,
        scopes: [webviewScope],
        tokenKey: WEBVIEW_API_TOKEN,
      }),
    ]);

    setSleepTime(0);
    dispatch(tokenRefreshed());
  }, [instance, sleepTime, tokenRequestB2C]);

  const forgotPasswordB2C = useCallback(() => {
    window.location.replace(b2cPolicies.authorities.forgotPassword.redirectUrl);
  }, [window.location]);

  return {
    forgotPasswordB2C,
    getAllTokens,
    logoutB2C,
    tokenRequestB2C,
  };
};

export { useB2CInfo };
