import React from 'react';
import Cropper from 'react-easy-crop';
import './ImageEditableMyPets.css';
import { SliderMyPets } from './SliderImageMyPets';
import roundArrow from '../../../assets/myPets/round-arrow.svg';

const ImageEditableMyPets = ({
  classNameButtonContainer = '',
  classNameContainer = '',
  classNameSliderContainer = '',
  crop,
  cropHeight,
  cropWidth,
  image,
  onCropChange = () => { },
  onCropComplete = () => { },
  onRotation = () => { },
  onZoom = () => { },
  rotation,
  rotationStep = 2,
  round,
  zoom,
}) => {
  const handleRotation = (stepDegrees) => () => {
    onRotation(stepDegrees);
  };

  function handleZoom(event) {
    const { value } = event.target;
    onZoom(Number(value));
  }

  return (
    <div className="My-pets-image-editable-container">
      <div
        className={`My-pets-image-editable-cropper-container
      ${classNameContainer}`}
      >
        <Cropper
          aspect={16 / 9}
          classes={{
            cropAreaClassName: 'My-pets-image-editable-crop-area',
          }}
          crop={crop}
          cropShape={round ? 'round' : 'rect'}
          cropSize={{ height: cropHeight, width: cropWidth }}
          image={image}
          onCropChange={onCropChange}
          onCropComplete={onCropComplete}
          rotation={rotation}
          showGrid={false}
          zoom={zoom / 150}
        />
      </div>

      <div className="My-pets-image-editor-actions-box">
        <div
          className={`My-pets-image-editable-slider-container ${
        classNameSliderContainer}`}
        >
          <SliderMyPets
            max={350}
            min={150}
            onChange={handleZoom}
            step={0.1}
            value={zoom}
          />
        </div>

        <div
          className={`My-pets-image-editable-rotate-buttons
          ${classNameButtonContainer}`}
        >
          <button
            className="My-pets-image-editable-rotate"
            onClick={handleRotation(-rotationStep)}
            type="button"
          >
            <img alt="Rotate to right" src={roundArrow} />
          </button>

          <button
            className="My-pets-image-editable-rotate"
            onClick={handleRotation(rotationStep)}
            type="button"
          >
            <img
              alt="Rotate to left"
              className="My-pets-image-editable-rotate-arrow-left"
              src={roundArrow}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export { ImageEditableMyPets };
