import React from 'react';
import familyIcon from '../../assets/found-lost-pet/family.png';

import './FoundLostPetFamilyRow.css';

const FoundLostPetFamilyRow = () => (
  <div className="Figo-lost-pet-family-row">
    <img alt="family" src={familyIcon} />
  </div>
);

export { FoundLostPetFamilyRow };
