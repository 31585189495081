import { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { removeEmojis } from '../../../../services/utils';

const useCustomVetForm = ({
  closeModal = () => { },
  handleSuccesCustomVetModal = () => { },
  clearSuccessLoading = () => { },
}) => {
  const { t } = useTranslation('myPets');

  const types = {
    CITY_ERROR: 'CITY_ERROR',
    CITY_NAME: 'CITY_NAME',
    CLOSE_MODAL: 'CLOSE_MODAL',
    STATE_ERROR: 'STATE_ERROR',
    STATE_ID: 'STATE_ID',
    STATE_NAME: 'STATE_NAME',
    VET_COMPANY_NAME: 'VET_COMPANY_NAME',
    VET_COMPANY_NAME_ERROR: 'VET_COMPANY_NAME_ERROR',
  };

  const initialState = {
    cityError: '',
    cityName: '',
    stateId: '',
    stateName: '',
    vetCompanyError: '',
    vetCompanyName: '',
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case types.CITY_NAME:
        return { ...state, cityName: action.value };
      case types.CITY_ERROR:
        return { ...state, cityError: action.value };
      case types.CLOSE_MODAL:
        return initialState;
      case types.STATE_ID:
        return { ...state, stateId: action.value };
      case types.STATE_NAME:
        return { ...state, stateName: action.value };
      case types.STATE_ERROR:
        return { ...state, stateError: action.value };
      case types.VET_COMPANY_NAME_ERROR:
        return { ...state, vetCompanyError: action.value };
      case types.VET_COMPANY_NAME:
        return { ...state, vetCompanyName: action.value };
      default:
        return { ...state };
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  function handleVetCompanyNameChange(event) {
    dispatch({
      type: types.VET_COMPANY_NAME,
      value: removeEmojis(event.target.value),
    });
    dispatch({ type: types.VET_COMPANY_NAME_ERROR, value: '' });
  }

  function handleCityNameChange(event) {
    dispatch({
      type: types.CITY_NAME,
      value: removeEmojis(event.target.value),
    });
    dispatch({ type: types.CITY_ERROR, value: '' });
  }

  function handleStateNameChange(event) {
    const { selectedIndex } = event.nativeEvent.target;
    const label = event.nativeEvent.target[selectedIndex].text;
    dispatch({ type: types.STATE_ID, value: event.target.value });
    dispatch({ type: types.STATE_NAME, value: label });
    dispatch({ type: types.STATE_ERROR, value: '' });
  }

  function handleVetCompanyError() {
    const required = `${t('AddCustomVetModal.required')}`;
    dispatch({
      type: types.VET_COMPANY_NAME_ERROR,
      value: !state.vetCompanyName ? required : '',
    });
  }

  function handleCityError() {
    const required = `${t('AddCustomVetModal.required')}`;
    dispatch({
      type: types.CITY_ERROR,
      value: !state.cityName ? required : '',
    });
  }

  function handleStateError() {
    const required = `${t('AddCustomVetModal.required')}`;
    dispatch({
      type: types.STATE_ERROR,
      value: !state.stateId ? required : '',
    });
  }

  function handleCloseModal() {
    dispatch({ type: types.CLOSE_MODAL });
    dispatch({ type: types.CITY_ERROR, value: '' });
    dispatch({ type: types.STATE_ERROR, value: '' });
    dispatch({ type: types.VET_COMPANY_NAME_ERROR, value: '' });
    closeModal();
  }

  function handleSuccess() {
    dispatch({ type: types.CLOSE_MODAL });
    clearSuccessLoading();
    handleSuccesCustomVetModal();
  }

  return {
    handleCityError,
    handleCityNameChange,
    handleCloseModal,
    handleStateError,
    handleStateNameChange,
    handleSuccess,
    handleVetCompanyError,
    handleVetCompanyNameChange,
    state,
  };
};

export { useCustomVetForm };
