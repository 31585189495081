import React from 'react';

import './NoClaimsPlaceholder.css';
import { useTranslation } from 'react-i18next';

const NoClaimsPlaceholder = () => {
  const { t } = useTranslation('claims');

  return (
    <div className="No-claims-placeholder-container">
      <h3>{t('noClaimsPlaceholder.title')}</h3>

      <span>{t('noClaimsPlaceholder.description')}</span>
    </div>
  );
};

export { NoClaimsPlaceholder };
