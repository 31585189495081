import React from 'react';

import ModalContainer, {
  MODAL_CONTAINER_ANIMATION_TYPE,
} from '../../common/ModalContainer';

import close from '../../../assets/close-button.svg';
import back from '../../../assets/back-arrow-blue.png';

import { useContactUsModal } from './hooks';
import { CONTACT_US_STEPS } from './contactUs.constants';

import './ContactUsModal.css';

const ContactUsModal = ({
  companyStep = '',
  custom = false,
  customDescription = '',
  customTitle = '',
  extraText = '',
  hasHeader = false,
  onClose = () => { },
  show = false,
}) => {
  const {
    onBackClick,
    renderContent,
    shouldRenderBackButton,
    t,
  } = useContactUsModal({ companyStep, hasHeader, show });
  return (
    <ModalContainer
      animationType={MODAL_CONTAINER_ANIMATION_TYPE.OPACITY}
      contentStyle={`Contact-us-modal-container 
      ${shouldRenderBackButton
        ? 'Contact-us-modal-container-with-back-button' : ''}`}
      handleOutClick={onClose}
      show={show}
    >
      {hasHeader && CONTACT_US_STEPS.HOME !== companyStep && (
        <div className="Contact-us-modal-header-container">
          <h1 className="Contact-us-modal-header-title">
            {custom ? customTitle : t('contactUs.menuTitle')}
          </h1>

          <p className="Contact-us-modal-header-description">
            {custom ? customDescription : t('contactUs.description')}
          </p>
        </div>
      )}

      {shouldRenderBackButton && (
        <button
          className="Contact-us-modal-back-button"
          onClick={onBackClick}
          type="button"
        >
          <img alt="" src={back} />

          <span>
            {t('contactUs.back')}
          </span>
        </button>
      )}

      <button
        className="Contact-us-modal-close-button"
        onClick={onClose}
        type="button"
      >
        <img alt="close" src={close} />
      </button>

      {renderContent(extraText)}
    </ModalContainer>
  );
};

export { ContactUsModal };
