import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BENEFIT_FEE_TYPE,
  POWER_UPS_RELATION,
  POWERUP_TYPE,
} from './powerups.constans';
import {
  doNothing,
  findSelectedModifier,
} from '../../../services/utils';
import { isWellnessFPI, toTruncate } from '../utils';

import { useResponsive } from '../../../hooks/useResponsive';

import UpdatedCardPowerup from './UpdatedCardPowerup';

import './css/cardPowerupSection.css';
import { HtmlContent } from '../../common/HtmlContent';

const {
  EXTRA_CARE_PACK_PSM_ID,
  PETPROTECT_COPAY,
  VETERINARY_EXAM_FEES_PSM_ID,
  WELLNESS_PSM_ID,
} = POWER_UPS_RELATION;

const CardPowerupSection = ({
  handleWellnessClick = () => { },
  insuranceProduct,
  onAddPowerup = () => { },
  onVetFeesClick = () => { },
  petName = '',
  petQuote = {},
  quoteSelected,
}) => {
  const { t } = useTranslation('changeCoverage');
  const { isMobile, isTablet } = useResponsive();

  const handleWellnesInfoButtonClick = () => {
    handleWellnessClick();
  };

  const handleAddPowerup = ({ isSelected = false, powerUp }) => () => {
    onAddPowerup({ isSelected, powerUp });
  };

  const handleToggle = ({ isSelected = false, powerUp }) => {
    if (isWellnessFPI(powerUp, insuranceProduct)) {
      const contentModifiers = powerUp.BundleInsuranceModifiers
        .filter((modifier) => modifier.IsVisible && modifier.IsActive);
      const selectedModifier = contentModifiers.find((item) => item.IsSelected);
      const defaultModifier = contentModifiers[0];

      if (!selectedModifier) {
        const isChecked = findSelectedModifier({
          id: defaultModifier.Id,
          modifiers: petQuote.modifiers || [],
        });

        return handleAddPowerup({
          isSelected: !isChecked,
          powerUp: defaultModifier || [],
        });
      }

      const isChecked = findSelectedModifier({
        id: selectedModifier.Id,
        modifiers: petQuote.modifiers,
      });

      return handleAddPowerup({
        isSelected: !isChecked,
        powerUp: selectedModifier,
      });
    }

    return handleAddPowerup({ isSelected, powerUp });
  };

  const handleInfoPowerup = ({ powerUp }) => () => {
    switch (powerUp.PMSModifierId) {
      case VETERINARY_EXAM_FEES_PSM_ID:
        onVetFeesClick(powerUp.PMSModifierId);
        break;

      case EXTRA_CARE_PACK_PSM_ID:
        doNothing();
        break;

      case WELLNESS_PSM_ID:
        handleWellnesInfoButtonClick();
        break;

      default:
        doNothing();
        break;
    }
  };

  const isModifierSelected = ({ id, selectedModifiers }) => {
    const modifiersSelected =
      selectedModifiers.filter((modifier) => modifier.id === id
        && modifier.isSelected);

    return !!modifiersSelected.length;
  };

  const getFeeAmountChildren = ({
    benefitType, modifiers, selectedModifiers,
  }) => modifiers
    .filter((item) => item.InsuranceModifierTypeId === POWERUP_TYPE
      && item.BenefitFeeType === benefitType)
    .map((element) => {
      if (element.BundleInsuranceModifiers) {
        return element.BundleInsuranceModifiers
          .filter((filter) => filter.IsSelected
            || isModifierSelected({ id: filter.Id, selectedModifiers }))
          .reduce((a, b) => a + b.FeeAmount, 0);
      }

      return 0;
    }).reduce((a, b) => a + b, 0);

  const getCoverageFee = () => {
    const insuranceModifiers = petQuote.InsuranceModifiers;

    const modifiers = petQuote.modifiers
      ? petQuote.modifiers.filter((modifier) => modifier.isSelected)
      : [];
    const modifierIds = modifiers.map((modifier) => modifier.id);

    const powerUpsHidden = insuranceModifiers
      .filter((modifier) => modifier.InsuranceModifierTypeId === POWERUP_TYPE
        && modifier.IsSelected && !modifier.IsVisible);
    const powerUpsSelected = insuranceModifiers
      .filter((modifier) => modifierIds.includes(modifier.Id));
    const powerUps = [...powerUpsHidden, ...powerUpsSelected];

    const feeFactor = powerUps
      .filter((item) => item.BenefitFeeType === BENEFIT_FEE_TYPE.ftpFactor)
      .reduce((a, b) => a + b.FeeAmount, 0);
    const feeFixed = powerUps
      .filter((item) => item.BenefitFeeType === BENEFIT_FEE_TYPE.fixedFee
        || item.BenefitFeeType === BENEFIT_FEE_TYPE.dynamic)
      .reduce((a, b) => a + b.FeeAmount, 0);

    const feeFactorChildren = getFeeAmountChildren({
      benefitType: BENEFIT_FEE_TYPE.ftpFactor,
      modifiers: insuranceModifiers,
      selectedModifiers: petQuote.modifiers || [],
    });

    const feeFixedChildren = getFeeAmountChildren({
      benefitType: BENEFIT_FEE_TYPE.fixedFee,
      modifiers: insuranceModifiers,
      selectedModifiers: petQuote.modifiers || [],
    });

    return {
      feeFactor: feeFactor + feeFactorChildren,
      feeFixed: feeFixed + feeFixedChildren,
    };
  };

  const getPriceWithoutFees = () => {
    const { monthlyAmountPlan } = quoteSelected;
    const coverageFeeValue = getCoverageFee();
    const price = monthlyAmountPlan;
    const priceWithoutFees = (price - coverageFeeValue.feeFixed)
      / (1 + coverageFeeValue.feeFactor);

    return priceWithoutFees;
  };

  const getCoverageByBenefitFeeType = ({ BenefitFeeType, FeeAmount }) => {
    const priceWithoutFees = getPriceWithoutFees();

    if (BenefitFeeType === BENEFIT_FEE_TYPE.ftpFactor) {
      const coverageFee = FeeAmount * priceWithoutFees;

      return coverageFee;
    }

    return FeeAmount;
  };

  function calculateCoverageFeeUpdated(powerUp) {
    if (isWellnessFPI(powerUp, insuranceProduct)) {
      const wellnessArrayValues =
        powerUp.BundleInsuranceModifiers
          .map((item) => {
            const contentModifierSelected = (petQuote.modifiers || [])
              .find((element) => element.id === item.Id);
            const isSelected = contentModifierSelected
              ? contentModifierSelected.isSelected : false;

            return isSelected && item.FeeAmount
              ? item.FeeAmount : 0;
          });

      const wellnessPrice = wellnessArrayValues
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
        || powerUp.BundleInsuranceModifiers[0].FeeAmount;

      return toTruncate(wellnessPrice).toFixed(2);
    }

    const coverageFee = getCoverageByBenefitFeeType(powerUp);
    return toTruncate(coverageFee).toFixed(2);
  }

  const renderPowerups = () => {
    if (!petQuote) {
      return null;
    }

    if (!petQuote.InsuranceModifiers) {
      return null;
    }

    const powerUps = petQuote.InsuranceModifiers
      .filter((modifier) => modifier.InsuranceModifierTypeId === POWERUP_TYPE
        && modifier.IsVisible && modifier.PMSModifierId !== PETPROTECT_COPAY);

    function shouldDisplayInfoImage(powerUp) {
      return powerUp.PMSModifierId === WELLNESS_PSM_ID;
    }

    const powerUpSelectedHandle = (id) => {
      const { modifiers } = petQuote;

      if (!modifiers || !modifiers.length) {
        return false;
      }

      const modifier = modifiers.find((item) => item.id === id);

      return modifier && modifier.isSelected;
    };

    function renderContent(powerUp) {
      return (
        <HtmlContent
          classNameContainer="Powerup-card-information"
          content={powerUp.InformationText}
          elementType="p"
        />
      );
    }

    return (
      <>
        {powerUps.map((powerUp) => (
          <UpdatedCardPowerup
            key={String(powerUp.Id)}
            bundleModifierAdd={handleAddPowerup}
            bundleModifierCheckSelection={powerUpSelectedHandle}
            content={renderContent(powerUp)}
            handleAddPowerup={handleToggle({
              isSelected: true, powerUp,
            })}
            handleRemovePowerup={handleToggle({ powerUp })}
            hideInfoImage={(isMobile || isTablet)
              && shouldDisplayInfoImage(powerUp)}
            onClickWhatsCovered={handleInfoPowerup({ powerUp })}
            petQuoteSelected={quoteSelected}
            powerUpInfo={powerUp}
            price={calculateCoverageFeeUpdated(powerUp)}
            selected={powerUpSelectedHandle(powerUp.Id)}
            title={powerUp.TitleText}
          />
        ))}
      </>
    );
  };

  const powerupTitle = useMemo(
    () => <h3>{t('powerUpSection.powerUpTitle')}</h3>,
    [isMobile, petName],
  );

  return (
    <>
      <div className="Card-plan-powerup-header-container">
        <div className="Card-plan-powerup-header-title">
          {powerupTitle}
        </div>
      </div>

      <p className="Card-plan-powerup-description">
        {t('powerUpSection.description')}

        {isMobile ? ' ' : <br />}

        {t('powerUpSection.note')}
      </p>

      <div className="Card-plan-powerup-cards-container">
        {renderPowerups()}
      </div>
    </>
  );
};

export { CardPowerupSection };
