import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

import { getGreetingKey } from '../utils';

const Greetings = () => {
  const { t } = useTranslation('home');
  const { name } = useSelector(({ session }) => ({
    name: session.userInfo.FirstName,
  }), shallowEqual);

  return (
    <>
      <h1 className="Home-title">
        {t(`${getGreetingKey()}`, { name })}
      </h1>

      <h3 className="Home-sub-title">
        {t('welcomeToYourPetCloud')}
      </h3>
    </>
  );
};

export { Greetings };
