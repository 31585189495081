import React, { useMemo } from 'react';
import plusIcon from '../../../assets/myPets/add-circle.svg';
import minusIcon from '../../../assets/myPets/subtract-circle.svg';
import './SliderImageMyPets.css';

const SliderMyPets = ({
  max = 100,
  min = 0,
  onChange = () => { },
  value,
  step = 1,
  otherProps,
}) => {
  const percentage = useMemo(
    () => (100 * (value - min)) / (max - min),
    [value, max, min],
  );

  const convertToPercentage =
  (sliderValue) => Math.round(((sliderValue - min) / (max - min)) * 100);
  const handleWidth = 20;
  const offset = (handleWidth / 2) * (percentage / 100);

  return (
    <div className="My-pets-slider-container">
      <img alt="" className="My-pets-slider-minus" src={minusIcon} />

      <input
        className="My-pets-slider-input"
        max={max}
        min={min}
        onChange={onChange}
        step={step}
        type="range"
        value={value}
        {...otherProps}
      />

      <div className="My-pets-slider No-clicked">
        <div className="My-pets-slider-value-track No-clicked">
          <div
            className="My-pets-slider-track"
            style={{ width: `calc(${percentage}%)` }}
          />

          <div
            className="My-pets-slider-value"
            style={{ left: `calc(${percentage}% - ${offset}px)` }}
          />
        </div>

        <div className="My-pets-slider-rail" />
      </div>

      <img alt="" className="My-pets-slider-plus" src={plusIcon} />

      <p className="My-pets-slider-percentage">{convertToPercentage(value)}%</p>
    </div>
  );
};

export { SliderMyPets };
