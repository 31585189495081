import { createActions } from 'redux-actions';

const actions = createActions({
  CONTACT_PET_FAMILY: (data) => data,
  LOST_PET_TAG_CHANGED: (data) => data,
  RESTART_PET_TAG_SEARCH: () => { },
  SEARCH_PET_TAG: (data) => data,
  SEARCH_PET_TAG_ATTEMPT: () => { },
  SEARCH_PET_TAG_FAILED: (data) => data,
  SEARCH_PET_TAG_FOUND: (data) => data,
  SEARCH_PET_TAG_RESET: () => { },
  SEND_MESSAGE_FAILED: () => { },
  SEND_MESSAGE_SUCCEED: () => { },
});

export const {
  contactPetFamily,
  lostPetTagChanged,
  restartPetTagSearch,
  searchPetTag,
  searchPetTagAttempt,
  searchPetTagFailed,
  searchPetTagFound,
  searchPetTagReset,
  sendMessageFailed,
  sendMessageSucceed,
} = actions;
