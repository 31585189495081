import { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PET_GENDER, PET_TYPE_ID } from '../../../constants';
import {
  addPet,
  addPetRestarted,
  editPet,
  loadBreedList,
  refreshPets,
} from '../../../actions';
import {
  removeEmojis,
  removeInvalidSpacesOnNames,
  testNumberWithDecimal,
} from '../../../services/utils';

const useAddAPetModal = ({
  isEditMode,
  modalAddPetVisible,
  petInformation,
}) => {
  const { t } = useTranslation('myPets');
  const dispatch = useDispatch();
  const store = useSelector(({ myPets }) => ({ myPets }), shallowEqual);
  const [petTypeSelected, setPetTypeSelected] = useState(PET_TYPE_ID.DOG);
  const [petTypeError, setPetTypeError] = useState('');
  const [petGenderSelected, setPetGenderSelected] = useState(PET_GENDER.MALE);
  const [petGenderError, setPetGenderError] = useState('');
  const [petBreedSelected, setPetBreedSelected] = useState('');
  const [petBreedError, setPetBreedError] = useState('');
  const [petName, setPetName] = useState('');
  const [petNameError, setPetNameError] = useState('');
  const [petWeight, setPetWeight] = useState('');
  const [petWeightError, setPetWeightError] = useState('');
  const [petBirthdaySelected, setPetBirthdaySelected] = useState();
  const [petBirthdayError, setPetBirthdayError] = useState('');
  const [petBio, setPetBio] = useState('');
  const [petId, setPetId] = useState(0);
  const { myPets } = store;

  const REQUIRED_ERROR = t('AddAPetModal.requiredError');

  useEffect(() => {
    if (modalAddPetVisible) {
      setPetTypeSelected(PET_TYPE_ID.DOG);
      setPetTypeError('');
      setPetGenderSelected(PET_GENDER.MALE);
      setPetGenderError('');
      setPetBreedSelected('');
      setPetBreedError('');
      setPetName('');
      setPetNameError('');
      setPetWeight('');
      setPetWeightError('');
      setPetBirthdaySelected();
      setPetBirthdayError('');
      setPetBio('');
      setPetId(0);
      dispatch(addPetRestarted());
    }
  }, [modalAddPetVisible]);

  useEffect(() => {
    const { breedData } = myPets;

    if (!breedData.dogBreeds.length && !breedData.catBreeds.length) {
      dispatch(loadBreedList());
    }
  }, [dispatch, myPets.breedData]);

  const petBreedList = useMemo(() => {
    if (!myPets.breedData.catBreeds.length
      || !myPets.breedData.dogBreeds.length
      || petTypeSelected === null) {
      const emptyBreed =
        { Id: -1, Name: '' };
      setPetBreedSelected(emptyBreed);
      return [emptyBreed];
    }

    const newBreedList = petTypeSelected === PET_TYPE_ID.DOG
      ? myPets.breedData.dogBreeds : myPets.breedData.catBreeds;

    let breedSelected = newBreedList[0];

    if (isEditMode) {
      breedSelected = newBreedList
        .find((breed) => breed.Id === parseInt(petInformation.BreedId, 10))
        || petInformation.BreedInformation;
    }

    setPetBreedSelected(breedSelected);

    setPetBreedError('');

    return newBreedList;
  }, [petTypeSelected, myPets.breedData, isEditMode]);

  useEffect(() => {
    setPetName(petInformation ? petInformation.Name : '');
    setPetTypeSelected(petInformation ? petInformation.PetType
      : PET_TYPE_ID.DOG);
    setPetBreedSelected(petInformation ? petInformation.BreedInformation : '');
    setPetGenderSelected(petInformation ? petInformation.Gender
      : PET_GENDER.MALE);
    setPetBirthdaySelected(petInformation ? petInformation.DateOfBirth : null);
    const petWeightClean = petInformation
      ? Number(petInformation.Weight).toFixed(2) : '';
    setPetWeight(`${petWeightClean} ${t('AddAPetModal.pounds')}`);
    setPetBio((petInformation
      ? petInformation.Description
      : '').replace(/"/g, ''));
    setPetId(petInformation ? petInformation.Id : null);

    dispatch(addPetRestarted());
  }, [dispatch, petInformation]);

  function onDatePickerChange(newDate) {
    setPetBirthdaySelected(newDate);
    if (newDate) {
      setPetBirthdayError('');
    }
  }

  function onBirthdaySelectorBlur(value) {
    if (!value) {
      setPetBirthdayError(REQUIRED_ERROR);
    }
  }

  function handlePetNameChanged({ target: { value } }) {
    const cleanValue = removeEmojis(value);
    setPetName(cleanValue);
    if (cleanValue) {
      setPetNameError('');
    }
  }

  function handlePetNameOnBlur() {
    let errorPetName = '';
    let newPetName = '';

    newPetName = removeInvalidSpacesOnNames(petName);
    setPetName(newPetName);
    errorPetName = !newPetName;
    setPetNameError(errorPetName ? REQUIRED_ERROR : '');
  }

  function handlePetWeightChanged({ target: { value } }) {
    const onlyNumbers = testNumberWithDecimal(value);
    if (!onlyNumbers && value) {
      return;
    }

    setPetWeight(value);
    if (value) {
      setPetWeightError('');
    }
  }

  function handlePetWeightOnFocus() {
    if (petWeight) {
      const poundValue = petWeight.split(t('AddAPetModal.pounds'))[0];
      const cleanNumber = !petWeight ? '' : Number(poundValue).toFixed(2);
      setPetWeight(cleanNumber);
    }
  }

  function handlePetWeightOnBlur() {
    const cleanNumber = !petWeight ? '' : Number(petWeight).toFixed(2);
    setPetWeight(`${cleanNumber} ${t('AddAPetModal.pounds')}`);
    if (!cleanNumber) {
      setPetWeightError(REQUIRED_ERROR);
    }
  }

  function handlePetTypeSelectedChanged(newPetType) {
    setPetTypeSelected(newPetType);
    setPetTypeError('');
  }

  function handlePetBreedSelectionChanged(newPetBreed) {
    setPetBreedSelected(newPetBreed);
  }

  function handlePetGenderSelectionChange(newPetGender) {
    setPetGenderSelected(newPetGender);
    setPetGenderError('');
  }

  function handlePetsBioChange(event) {
    const { value } = event.target;

    setPetBio(removeEmojis(value));
  }

  function handleSavePet() {
    const poundValue = petWeight.split(t('AddAPetModal.pounds'))[0];
    const cleanNumber = !petWeight ? '0' : Number(poundValue).toFixed(2);

    if (isEditMode) {
      dispatch(editPet({
        BreedId: petBreedSelected.Id,
        Description: petBio.trim(),
        DOB: petBirthdaySelected.toISOString().split('T')[0],
        Gender: petGenderSelected,
        Id: petId,
        Name: petName,
        TypeId: petTypeSelected,
        Weight: cleanNumber,
      }));

      return;
    }

    dispatch(addPet({
      petToAdd: {
        Birthday: petBirthdaySelected.toISOString().split('T')[0],
        BreedId: petBreedSelected.Id,
        Gender: petGenderSelected,
        Name: petName,
        Notes: petBio.trim(),
        Picture: null,
        Type: petTypeSelected,
        Weight: cleanNumber,
      },
    }));
  }

  function refreshPetsSuccess() {
    dispatch(refreshPets());
  }

  const saveButtonDisabled = useMemo(() => !petName || !petBreedSelected
    || !petBirthdaySelected || !petWeight || petNameError || petBreedError
    || petBirthdayError || petWeightError,
  [
    petBirthdayError,
    petBirthdaySelected,
    petBreedError,
    petBreedSelected,
    petName,
    petNameError,
    petWeight,
    petWeightError,
  ]);

  return {
    handlePetBreedSelectionChanged,
    handlePetGenderSelectionChange,
    handlePetNameChanged,
    handlePetNameOnBlur,
    handlePetsBioChange,
    handlePetTypeSelectedChanged,
    handlePetWeightChanged,
    handlePetWeightOnBlur,
    handlePetWeightOnFocus,
    handleSavePet,
    myPets,
    onBirthdaySelectorBlur,
    onDatePickerChange,
    petBio,
    petBirthdayError,
    petBirthdaySelected,
    petBreedError,
    petBreedList,
    petBreedSelected,
    petGenderError,
    petGenderSelected,
    petName,
    petNameError,
    petTypeError,
    petTypeSelected,
    petWeight,
    petWeightError,
    refreshPetsSuccess,
    saveButtonDisabled,
  };
};

export { useAddAPetModal };
