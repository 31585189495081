import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
  forwardRef,
} from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useMixpanel } from '../../../../hooks';
import { EV39 } from '../../../../services/mixpanel';
import { showAlert, uploadFiles } from '../../../../actions';

import InputFile from '../../../claims/steps/InputFile';
import { FolderSelector } from './FolderSelector';
import { RelatedPetsSelector } from './RelatedPetsSelector';
import { GenericModal } from '../../../common/petCloud/GenericModal';
import './UploadFilesModal.css';

const FULL_PERCENTAGE = 100;

const Step1 = forwardRef(({
  acceptedFiles = '',
  disabled = false,
  goNextStep = () => { },
  onAddFiles = () => { },
  onFolderSelected = () => { },
  onPetSelectedChange = () => { },
  petList = [],
  petsIdsSelected = [],
  selectedFolder = {},
}, inputFileRef) => {
  const handleAddFiles = useCallback((event) => {
    onAddFiles(event.target.files);
    goNextStep();
  }, [onAddFiles]);

  return (
    <>
      <FolderSelector
        disabled={disabled}
        onFolderSelected={onFolderSelected}
        selectedFolder={selectedFolder}
      />

      <RelatedPetsSelector
        onPetSelectedChange={onPetSelectedChange}
        petList={petList}
        petsIdsSelected={petsIdsSelected}
      />

      <input
        ref={inputFileRef}
        accept={acceptedFiles}
        hidden
        multiple
        onChange={handleAddFiles}
        type="file"
      />
    </>
  );
});

const Step2 = ({
  acceptedFiles,
  filesAttached,
  filesToDisplay,
  onAddFiles = () => { },
  onDeleteFile = () => { },
  t = () => { },
  uploading,
}) => (
  <div>
    <InputFile
      acceptFiles={acceptedFiles}
      className="Upload-files-modal-file Upload-files-modal-empty"
      disabled={uploading}
      files={[]}
      isModal
      onFileAdd={onAddFiles}
      onFileDelete={onDeleteFile}
      showCounter={false}
    />

    <h3 className="Upload-files-title">
      {t('uploadModal.uploadedFiles')}
    </h3>

    {filesAttached.length && (
      <InputFile
        acceptFiles={acceptedFiles}
        className="Upload-files-modal-file"
        disabled
        files={filesToDisplay}
        isModal
        onFileAdd={onAddFiles}
        onFileDelete={onDeleteFile}
        showCounter={false}
      />
    )}
  </div>
);

const UploadFilesModal = ({
  acceptedFiles = '',
  currentFolder = null,
  filesAttached = [],
  folderId = -1,
  isHome = false,
  onAddFiles = () => { },
  onClose = () => { },
  onDeleteFile = () => { },
  onFolderSelected = () => { },
  selectedFolder = {},
  show = false,
}) => {
  const [petsIdsSelected, setPetIdsSelected] = useState([]);
  const [isSelectingFolder, setIsSelectingFolder] = useState(true);
  const [error, setError] = useState('');
  const { t } = useTranslation('inboxAndDocs');
  const dispatch = useDispatch();
  const { sendEvent } = useMixpanel();
  const inputFileRef = useRef();
  const store = useSelector(
    ({ personalInformation, inboxAndDocs }) => ({
      petList: personalInformation.pets,
      uploadFiles: inboxAndDocs.uploadFiles,
    }),
    shallowEqual,
  );
  const {
    petList,
    uploadFiles: {
      uploading,
      uploadedSuccessfully,
    },
  } = store;

  const showConfirmation = useMemo(
    () => uploadedSuccessfully
      && filesAttached.some((file) => !file.valid)
      && filesAttached.some((file) => file.valid),
    [uploadedSuccessfully, filesAttached],
  );

  const filesToDisplay = useMemo(() => (uploadedSuccessfully
    ? filesAttached.filter((file) => file.percentage === FULL_PERCENTAGE)
    : filesAttached), [uploadedSuccessfully, filesAttached]);

  const handlePetSelectedChanged = useCallback((petIdChanged) => {
    if (petsIdsSelected.includes(petIdChanged)) {
      setPetIdsSelected((prevPets) => prevPets
        .filter((pet) => pet !== petIdChanged));
    } else {
      setError('');
      setPetIdsSelected((prevPets) => [...prevPets, petIdChanged]);
    }
  }, [petsIdsSelected]);

  useEffect(() => {
    if (petList.length === 1) {
      setPetIdsSelected([petList[0].Id]);
    }
  }, [petList]);

  const goToUpload = useCallback(() => {
    setIsSelectingFolder(false);
  }, []);

  const goToSelectFolder = useCallback(() => {
    setIsSelectingFolder(true);
  }, []);

  function handleClose() {
    if (!uploading) {
      setPetIdsSelected(petList.length === 1 ? [petList[0].Id] : []);
      setError('');
      goToSelectFolder();
      onClose();
    }
  }

  useEffect(() => {
    if (uploadedSuccessfully && filesAttached.every((file) => !file.valid)) {
      dispatch(showAlert({ type: 'error' }));
      handleClose();
    }
  }, [dispatch, uploadedSuccessfully, filesAttached]);

  function handleUploadFiles() {
    if (uploading) {
      return;
    }

    if (!petsIdsSelected.length || !filesToDisplay.length) {
      setError(t('uploadModal.assignError'));
      return;
    }

    if (showConfirmation) {
      handleClose();
      return;
    }

    dispatch(uploadFiles({
      batchSize: 4,
      currentBatch: 0,
      files: filesAttached
        .filter((file) => file.valid && file.binary && file.percentage === 0),
      folderId,
      orderIds: petList
        .filter((pet) => petsIdsSelected.includes(pet.Id))
        .map((pet) => pet.Id),
    }));

    sendEvent(EV39({ source: 'Inbox and docs', uploadSource: 'browse file' }));
  }

  const folderAndAssignValid = useMemo(
    () => {
      let folderSelectionValid = false;
      if (isHome) {
        folderSelectionValid = !!selectedFolder
          && selectedFolder.id;
      } else {
        folderSelectionValid = !!currentFolder;
      }

      return folderSelectionValid && petsIdsSelected.length > 0;
    },
    [selectedFolder, petsIdsSelected],
  );
  const primaryButtonDisabled = useMemo(
    () => !folderAndAssignValid, [folderAndAssignValid],
  );

  const handlePrimaryActionClick = useCallback(() => {
    if (primaryButtonDisabled || uploading) {
      return;
    }

    if (isSelectingFolder) {
      if (filesToDisplay.length) {
        goToUpload();
      } else if (inputFileRef.current) {
        inputFileRef.current.click();
      }
      return;
    }

    handleUploadFiles();
  }, [primaryButtonDisabled, isSelectingFolder, handleUploadFiles]);

  const handleCancelClick = useCallback(() => {
    if (isSelectingFolder) {
      setPetIdsSelected([]);
      setError('');
      onClose();
      return;
    }
    goToSelectFolder();
  }, [onClose, isSelectingFolder]);

  const handleSuccess = useCallback(() => {
    setPetIdsSelected((prevPets) => prevPets);
    setError('');
    onClose();
  }, [onClose]);

  const primaryButtonText = useMemo(() => {
    if (!isSelectingFolder) {
      return t('uploadModal.uploadNow');
    }
    if (isSelectingFolder && filesToDisplay.length) {
      return t('uploadModal.next');
    }
    return t('uploadModal.selectFiles');
  }, [isSelectingFolder, filesToDisplay.length]);

  return (
    <GenericModal
      containerClassName="Upload-files-modal-wrapper"
      handleOutClick={handleClose}
      loadingPrimary={uploading}
      onPrimaryClick={handlePrimaryActionClick}
      onPrimarySuccess={handleSuccess}
      onSecondaryClick={handleCancelClick}
      primaryButtonDisabled={primaryButtonDisabled}
      primaryButtonText={primaryButtonText}
      secondaryButtonText={isSelectingFolder
        ? t('uploadModal.cancel') : t('uploadModal.back')}
      show={show}
      successPrimary={uploadedSuccessfully
        && filesAttached
          .every((file) => file.percentage === FULL_PERCENTAGE)}
      title={t('uploadModal.upload')}
    >
      <div className="Upload-files-modal-container">
        {isSelectingFolder ? (
          <Step1
            ref={inputFileRef}
            acceptedFiles={acceptedFiles}
            disabled={!isHome}
            goNextStep={goToUpload}
            onAddFiles={onAddFiles}
            onFolderSelected={onFolderSelected}
            onPetSelectedChange={handlePetSelectedChanged}
            petList={petList}
            petsIdsSelected={petsIdsSelected}
            selectedFolder={isHome ? selectedFolder : currentFolder}
          />
        ) : (
          <Step2
            acceptedFiles={acceptedFiles}
            filesAttached={filesAttached}
            filesToDisplay={filesToDisplay}
            onAddFiles={onAddFiles}
            onDeleteFile={onDeleteFile}
            t={t}
            uploading={uploading}
          />
        )}

        <span className="Upload-modal-error">
          {error}
        </span>
      </div>
    </GenericModal>
  );
};

export { UploadFilesModal };
