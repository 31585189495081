import React from 'react';
import selectedRadio from '../../assets/radio-selected.svg';
import deselectedRadio from '../../assets/radio-deselected.svg';
import './RadioButton.css';

const RadioButton = ({
  children,
  name = '',
  checked = false,
  onChange = () => { },
}) => (
  // Disabled rule 'cause this structure replace the htmlFor attr
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  <label className="Radio-button">
    <img
      alt=""
      className="Radio-button-img"
      src={checked ? selectedRadio : deselectedRadio}
    />

    <input id={name} name={name} onClick={onChange} type="radio" />

    {children}
  </label>
);

export { RadioButton };
