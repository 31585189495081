import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import mixpanel from 'mixpanel-browser';
import platform from 'platform';

import { PET_TYPE, POLICY_STATUS } from '../constants';
import { getGeneralData } from '../actions';
import { delay, getBrandNameByChannelId } from '../services/utils';

const MixpanelContext = createContext();
export const useMixpanel = () => useContext(MixpanelContext);

const POLICY_STATUS_STR = ['cancelled', 'active', 'future', 'expired'];

/**
 * Track a mixpanel event on component did mount stage,
 * it handles the required wait to have the people props properly registred
 * @param {object} event contains the required props for the event tracking
 * @param {Array} dependencies dependencies required to run the effect properly
 */
export const useTrackOnMount = (event, dependencies = []) => {
  const generalData =
    useSelector(({ common }) => (common.generalData), shallowEqual);
  const { sendEvent } = useMixpanel();

  useEffect(() => {
    const trackEvent = async () => {
      await delay(500);
      sendEvent(event);
    };

    if (generalData && event) {
      trackEvent();
    }
  }, [generalData, ...dependencies]);
};

export const MixpanelProvider = ({ children }) => {
  const dispatch = useDispatch();
  const store = useSelector((
    {
      accountLogin,
      common,
      personalInformation,
      policies,
      session,
    },
  ) => ({
    accountLogin,
    common,
    personalInformation,
    policies,
    session,
  }), shallowEqual);

  const {
    accountLogin: {
      userInfo,
    },
    common: { generalData },
    personalInformation: {
      pets,
      profile,
    },
    session,
    policies: { allPets },
  } = store;

  useEffect(() => {
    if (userInfo) {
      dispatch(getGeneralData());
    }
  }, [dispatch, session.userInfo]);

  useEffect(() => {
    mixpanel.init(process.env.REACT_APP_MIXPANEL, {
      debug: process.env.REACT_APP_NAME !== 'Prod',
    });
  }, []);

  const petsWithPolicies = useMemo(() => (
    pets
      ? pets.filter((pet) => pet.PolicyNumber
        && pet.PolicyStatusId > POLICY_STATUS.cancelled)
      : []), [pets]);

  const petsWithPolicySuperProp = useMemo(() => {
    const petsDetails = petsWithPolicies.map((pet) => {
      const dob = pet.DOB && new Date(pet.DOB).toISOString()
        .split('T')[0].replace(/-/g, '');

      return `${pet.Name}`
        + `-${pet.BreedName}`
        + `-${dob}`
        + `-${pet.PolicyNumber}`
        + `-${POLICY_STATUS_STR[pet.PolicyStatusId] || ''}`;
    });

    return petsDetails.join(',');
  }, [petsWithPolicies]);

  function claimsStatus(claimStatusId) {
    switch (claimStatusId) {
      case 0:
        return 'open';

      case 1:
        return 'records';

      case 2:
        return 'inReview';

      default:
        return 'closed';
    }
  }

  const orderSuperProp = useMemo(() => ({
    'active/future insurance count': generalData?.ActiveFuture,
    'cancelled insurance count': generalData?.Cancelled,
  }), [generalData]);

  const claimsSuperProp = useMemo(() => {
    let claimsWithStatus = [];
    let totalClaims = 0;
    if (generalData && generalData.ClaimsInfo) {
      claimsWithStatus = generalData.ClaimsInfo
        .map((claim) => `${claim.PetName}`
          + `-${claim.PolicyNumber}`
          + `-${claim.ClaimNumber}`
          + `-${claimsStatus(claim.ClaimStatus)}`);

      totalClaims = generalData.ClaimsInfo.length;
    }

    return {
      'claim number with status': claimsWithStatus,
      'total number of claim': totalClaims,
    };
  }, [generalData]);

  const customerTypeProp = useMemo(() => {
    let customerType = 'null';
    switch (generalData?.CustomerType) {
      case 0:
        customerType = 'free customer';
        break;
      case 1:
        customerType = 'insured-active';
        break;
      case 2:
        customerType = 'insured-cancelled';
        break;
      default:
        customerType = 'null';
    }
    return {
      'customer type': customerType,
    };
  }, [generalData]);

  const statusProfile = useMemo(() => ({
    'profile activation status': profile.Enabled ? 'Active' : 'Inactive',
  }), [profile]);

  const filteredPolicies = useMemo(() => allPets
    .flatMap((item) => item.policies.filter((policy) => policy.Status
    === POLICY_STATUS.active || policy.Status === POLICY_STATUS.future)),
  [allPets]);

  const petsWithActiveOrFuturePolicies = useMemo(() => {
    const policies = filteredPolicies.map((policy) => {
      const { ProductFamilyId } = policy;
      return getBrandNameByChannelId(ProductFamilyId);
    }).join(',') || '';

    return policies;
  }, [filteredPolicies]);

  const superProperties = useMemo(() => ({
    $email: userInfo?.LoginEmail,
    $name: userInfo?.SocialInfo.Username,
    $os: platform.os.toString(),
    $os_version: platform.os.version,
    $userId: userInfo?.CustomerId,
    'customer id': userInfo?.CustomerId,
    distinct_id: userInfo?.CustomerId,
    'pets details and policy status': petsWithPolicySuperProp,
    'user brand': petsWithActiveOrFuturePolicies,
    ...orderSuperProp,
    ...claimsSuperProp,
    ...statusProfile,
  }), [
    userInfo,
    petsWithPolicySuperProp,
    orderSuperProp,
    claimsSuperProp,
    statusProfile,
    petsWithActiveOrFuturePolicies,
  ]);

  const peopleProps = useMemo(() => {
    if (!userInfo) {
      return null;
    }

    const petsDetails = petsWithPolicies
      .map((pet) => `${pet.Name}-${pet.PolicyNumber}`
        + `-${POLICY_STATUS_STR[pet.PolicyStatusId] || ''}`)
      .join(', ');

    const catsWithPolicy = petsWithPolicies
      .filter((pet) => pet.PetTypeId === PET_TYPE.CAT);

    const dogsWithPolicy = petsWithPolicies
      .filter((pet) => pet.PetTypeId === PET_TYPE.DOG);

    return {
      $email: userInfo.LoginEmail,
      $name: userInfo.SocialInfo.Username,
      'Company name': userInfo.Company.OriginId,
      'customer id': userInfo.CustomerId,
      distinct_id: userInfo.CustomerId,
      'number of cats': catsWithPolicy.length,
      'number of dogs': dogsWithPolicy.length,
      'number of pets': petsWithPolicies.length,
      'pets details and policy status': petsWithPolicySuperProp,
      'pets names with policy number and status': petsDetails,
      state: userInfo.Address.StateId,
      ...orderSuperProp,
      ...customerTypeProp,
      ...claimsSuperProp,
    };
  }, [
    allPets,
    claimsSuperProp,
    customerTypeProp,
    orderSuperProp,
    petsWithPolicies,
    userInfo,
  ]);

  useEffect(() => {
    mixpanel.register(superProperties);
  }, [superProperties]);

  useEffect(() => {
    if (peopleProps) {
      mixpanel.identify(userInfo.CustomerId);
      mixpanel.people.set(peopleProps);
    }
  }, [peopleProps]);

  function sendEvent({ eventName = '', params = {} }) {
    mixpanel.track(eventName, params);
  }

  return (
    <MixpanelContext.Provider value={{ sendEvent }}>
      {children}
    </MixpanelContext.Provider>
  );
};
