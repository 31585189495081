import React from 'react';
import petCloudPaw from '../../assets/sideMenu/petcloud-paw.svg';
import petCloudText from '../../assets/sideMenu/petcloud-text.svg';
import { useShowFF } from '../../hooks';

const Header = () => {
  // For testing purposes, remove once FF are validated
  const showFF = useShowFF();
  return (
    <div className={`Main-header ${showFF ? 'Main-header-purple' : ''}`}>
      <img alt="" className="Main-header-paw" src={petCloudPaw} />

      <img alt="" src={petCloudText} />
    </div>
  );
};

export default Header;
