import { useSelector, shallowEqual } from 'react-redux';
import { useMemo } from 'react';
import { PMS } from '../constants';

const usePMS = () => {
  const store = useSelector(
    ({ policies }) => ({
      policies,
    }),
    shallowEqual,
  );

  const { policies: { data }, policies: { multibrandPolicies } } = store;

  const pms = useMemo(() => {
    if (data.length > 0) {
      return data[0]?.Policy?.PolicyManagementSystem;
    }
    if (multibrandPolicies.length > 0) {
      return PMS.orca;
    }
    return '';
  }, [data, multibrandPolicies]);

  return { pms };
};

export { usePMS };
