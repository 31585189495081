import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Shimmer from '../../../common/Shimmer';

import { ChangeCoverageButtons } from '../ChangeCoverageButtons';

import '../css/ChangeCoverageScreenFooter.css';

const ChangeCoverageScreenFooter = ({
  handleGoBack = () => { },
  onReviewChangesClick = () => { },
}) => {
  const store = useSelector(({ policies }) => ({ ...policies.changeCoverage }));
  const { rate: { isAnnual }, quoteSelectedValues } = store;
  const { t } = useTranslation('changeCoverage');

  const priceWithFormat = useMemo(() => {
    const price = isAnnual
      ? quoteSelectedValues.total : quoteSelectedValues.totalMonthly;
    const annualTotal = isAnnual ? quoteSelectedValues.totalAnnual : 0;

    if (!price) {
      return { decimal: '00', integer: 0, price: 0 };
    }

    return {
      annualTotal,
      monthlyDecimal: `.${price.toString().split('.')[1] || '00'}`,
      monthlyInteger: price.toString().split('.')[0],
      price: parseInt(price, 10),
    };
  }, [isAnnual, quoteSelectedValues, store.rate]);

  function handleReviewChanges() {
    if (store.loading || priceWithFormat.price === 0) {
      return;
    }

    onReviewChangesClick();
  }

  function renderPrice() {
    if (store.loading || !priceWithFormat.monthlyInteger) {
      return (
        <div className="Change-coverage-footer-price-shimmer">
          <Shimmer height={48} visible />
        </div>
      );
    }

    const priceText = isAnnual ? `$${priceWithFormat.annualTotal}`
      : `$${priceWithFormat.monthlyInteger}${priceWithFormat.monthlyDecimal}`;

    return (
      <div className="Change-coverage-footer-price-container">
        <h2 className="Change-coverage-footer-price">
          {priceText}
        </h2>

        <span className="Change-coverage-footer-text">
          /{isAnnual ? t('options.annual') : t('options.monthly')}
        </span>
      </div>
    );
  }

  return (
    <div className="Change-coverage-footer-container">
      {renderPrice()}

      <ChangeCoverageButtons
        containerClass="Change-Coverage-footer-buttons-container"
        onClickPrimaryButton={handleReviewChanges}
        onClickSecondaryButton={handleGoBack}
        primaryText={t('options.reviewChanges')}
        secondaryText={t('preview.back')}
      />
    </div>
  );
};

export { ChangeCoverageScreenFooter };
