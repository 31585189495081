import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
  GenericModal,
  PetCloudInputAnimated,
} from '../../common/petCloud';
import { removeEmojis } from '../../../services/utils';
import { createFolder } from '../../../actions';

export const AddFolderModal = ({
  onClose = () => { },
  onRefresh = () => { },
  parentId,
  show = false,
}) => {
  const [newFolderName, setNewFolderName] = useState('');
  const [internalError, setInternalError] = useState('');

  const { t } = useTranslation('inboxAndDocs');
  const { newFolder: store } =
    useSelector(({ inboxAndDocs }) => inboxAndDocs, shallowEqual);
  const dispatch = useDispatch();

  const onNewFolderNameChanged = useCallback(({ target }) => {
    const { value } = target;
    setNewFolderName(removeEmojis(value));
    setInternalError('');
  }, []);

  const onSubmitAddFolder = useCallback((event) => {
    event.preventDefault();

    if (store.loading) {
      return;
    }

    if (!newFolderName) {
      setInternalError(t('modalAddFolder.error'));
      return;
    }

    setInternalError('');
    dispatch(createFolder({ name: newFolderName, parentId }));
  }, [dispatch, newFolderName, parentId, store]);

  const handleClose = useCallback(() => {
    setNewFolderName('');
    onClose();
  }, [onClose]);

  const onNewFolderSuccess = useCallback(() => {
    if (store.loading) {
      return;
    }

    handleClose();
    onRefresh();
  }, [dispatch, store, handleClose, onRefresh]);

  return (
    <GenericModal
      handleOutClick={handleClose}
      loadingPrimary={store.loading}
      onPrimaryClick={onSubmitAddFolder}
      onPrimarySuccess={onNewFolderSuccess}
      onSecondaryClick={handleClose}
      primaryButtonDisabled={!newFolderName}
      primaryButtonText={t('modalAddFolder.add')}
      secondaryButtonText={t('modalAddFolder.cancel')}
      show={show}
      successPrimary={store.success}
      title={t('modalAddFolder.title')}
    >
      <PetCloudInputAnimated
        error={store.error || internalError}
        id="new-folder-name"
        label={t('modalAddFolder.label')}
        labelClass="Add-folder-modal-label"
        onChange={onNewFolderNameChanged}
        placeholder={t('modalAddFolder.placeholder')}
        value={newFolderName}
      />
    </GenericModal>
  );
};
