import React, { Component } from 'react';
import Lottie from 'react-lottie';
import Button from 'react-bootstrap/Button';
import loadingSpinner from '../../assets/loading-spinner.json';
import loadingCheck from '../../assets/loading-check.json';
import './SuccessButton.css';

class SuccessButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonContent: {
        text: props.text,
      },
      className: props.className,
      loading: false,
    };
  }

  handleAnimationEnd = () => {
    if (this.state.buttonContent
      && this.state.buttonContent.animation === loadingCheck) {
      if (this.fadeOutTimer) {
        clearTimeout(this.fadeOutTimer);
      }

      if (!this.props.disableFadeOut) {
        this.setState({ className: 'animated fadeOut' });
        this.fadeOutTimer = setTimeout(() => this.handleFadeOut(), 1000);
      } else {
        this.handleFadeOut();
      }
    }
  };

  handleFadeOut() {
    if (this.props.onAnimationEnd) {
      this.props.onAnimationEnd();
      this.setState({ loading: false });
    }
  }

  getButtonSize = (size) => {
    let width = 30;
    let height = 26;

    if (size === 'lg') {
      width = 36;
      height = 36;
    }

    return { height, width };
  };

  startSuccess() {
    this.setState({
      buttonContent: {
        animation: loadingCheck,
        loop: false,
      },
    });
  }

  startLoading() {
    this.setState({
      buttonContent: {
        animation: loadingSpinner,
        loop: true,
      },
      loading: true,
    });
  }

  reset() {
    const { text, className } = this.props;
    const buttonContent = { text };

    this.setState({ buttonContent, className, loading: false });
  }

  renderButtonContent() {
    const { buttonContent } = this.state;
    const { size, text } = this.props;

    if (buttonContent.animation) {
      const defaultOptions = {
        animationData: buttonContent.animation,
        autoplay: true,
        loop: buttonContent.loop,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        },
      };

      const { height, width } = this.getButtonSize(size);

      return (
        <Lottie
          eventListeners={[{
            callback: this.handleAnimationEnd,
            eventName: 'complete',
          }]}
          height={height}
          options={defaultOptions}
          width={width}
        />
      );
    } if (buttonContent.text) {
      return buttonContent.text;
    }

    return text;
  }

  renderCancelButton() {
    if (!this.props.cancelable || this.state.loading) {
      return null;
    }

    return (
      <Button
        className="Cancel-button Button-mint"
        onClick={this.props.onCancelClick}
        variant="light"
      >
        {this.props.cancelText}
      </Button>
    );
  }

  render() {
    return (
      <div className="Success-button-container">
        {this.renderCancelButton()}

        <Button
          {...this.props}
          className={`${this.props.inactive
            ? 'Save-button-inactive'
            : 'Save-button'} ${this.state.className}`}
        >
          {this.renderButtonContent()}
        </Button>
      </div>
    );
  }
}

export { SuccessButton };
