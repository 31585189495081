import { createActions } from 'redux-actions';

const actions = createActions({
  CHECK_PASSWORD_SETUP: (data) => data,
  DO_RESET_PASSWORD: (data) => data,
  GET_NEW_PASSWORD_TOKEN: (data) => data,
  GET_NEW_PASSWORD_TOKEN_FAILED: () => { },
  NEW_PASSWORD_TOKEN_GENERATED: (data) => data,
  PASSWORD_SETUP_CHECKED: (data) => data,
  PASSWORD_SETUP_RESTARTED: (data) => data,
  RESET_PASSWORD_FAILED: (data) => data,
  RESET_PASSWORD_SUCCESS: () => { },
  RESET_PASSWORD_TOKEN_LOADED: (data) => data,
  SET_UP_NEW_PASSWORD: (data) => data,
  SET_UP_NEW_PASSWORD_FAILED: (data) => data,
  SET_UP_NEW_PASSWORD_SUCCESS: () => { },
  VALID_RESET_PASSWORD_TOKEN: (data) => data,
});

export const {
  checkPasswordSetup,
  doResetPassword,
  getNewPasswordToken,
  getNewPasswordTokenFailed,
  newPasswordTokenGenerated,
  passwordSetupChecked,
  passwordSetupRestarted,
  resetPasswordFailed,
  resetPasswordSuccess,
  resetPasswordTokenLoaded,
  setUpNewPassword,
  setUpNewPasswordFailed,
  setUpNewPasswordSuccess,
  validResetPasswordToken,
} = actions;
