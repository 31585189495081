import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { PaymentHistoryPolicies } from './PaymentHistoryPolicies';
import { PaymentHistoryList } from './PaymentHistoryList/PaymentHistoryList';
import { EmptyScreen } from '../../common/petCloud/EmptyScreen';

import { MY_ACCOUNT } from '../../../constants';

import { loadPolicyDetails } from '../../../actions';

import './css/PaymentHistoryScreen.css';

const PaymentHistoryScreen = ({ breadCrumbs, currentFolderId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('myAccount');
  const {
    policies: {
      data,
      loading,
    },
  } = useSelector(({ policies }) => ({ policies }), shallowEqual);
  const isPaymentList = currentFolderId === MY_ACCOUNT.paymentHistoryList;

  useEffect(() => {
    if (data.length === 1) {
      dispatch(loadPolicyDetails(data[0].Policy.Number));
    }
  }, [data]);

  const policyInfo = useMemo(() => {
    if (data.length && !isPaymentList) {
      return data.map((item) => item.Policy);
    }

    return [];
  }, [data, isPaymentList]);

  function renderContent() {
    if (!data.length && !loading) {
      return (
        <EmptyScreen
          description={t('paymentHistorySection.emptyScreenDescription')}
          title={t('paymentHistorySection.emptyScreenTitle')}
        />
      );
    }

    return (
      <>
        <p className="Payment-history-screen-description">
          {isPaymentList
            ? t('paymentHistorySection.description')
            : t('paymentHistorySection.descriptionPolicies')}
        </p>

        {data.length > 1 && !isPaymentList ? (
          <PaymentHistoryPolicies
            breadCrumbs={breadCrumbs}
            loading={loading}
            policies={policyInfo}
          />
        ) : <PaymentHistoryList />}
      </>
    );
  }

  return (
    <div className="Payment-history-screen-container">
      <h1 className="Payment-history-screen-main-title">
        {t('paymentHistorySection.title')}
      </h1>

      {renderContent()}
    </div>
  );
};

export { PaymentHistoryScreen };
