import { call, put } from 'redux-saga/effects';
import { apiCallWithApiKey, get, IDOR_HEADER_V2 } from '../services/api';
import {
  generalDataLoaded,
  getSharePostDataFailed,
  getSharePostDataSuccess,
  linkMessageSent,
  publicSideImageLoaded,
} from '../actions';
import { SMS_APP_LINKS } from '../constants';

export function* sendLinkMessage(dispatch, { payload }) {
  const options = {
    body: {
      body: SMS_APP_LINKS,
      toPhone: payload.toPhone,
    },
    method: 'POST',
  };

  const response = yield call(
    apiCallWithApiKey,
    'api/Quote/SendSMS',
    options,
    dispatch,
  );

  yield put(linkMessageSent(response.success && response.IsValid));
}

export function* clearAllCustomerCache(dispatch) {
  const url = 'api/User/ClearAllCustomerCache';
  yield call(get, dispatch, url);
}

export function* clearUserCache(dispatch) {
  const url = 'api/User/ClearCache';
  yield call(get, dispatch, url);
}

export function* getSharePostData(dispatch, { payload }) {
  const { postId } = payload;

  const response = yield call(
    apiCallWithApiKey,
    `api/Social/Share/Post/${postId}`,
    { headers: IDOR_HEADER_V2 },
    dispatch,
  );

  if (response.IsValid && response.Data) {
    yield put(getSharePostDataSuccess(response.Data));
  } else {
    yield put(getSharePostDataFailed());
  }
}

export function* getPublicSideImage(dispatch) {
  const response = yield call(
    apiCallWithApiKey,
    'api/Contentful/GetSignInPage/true',
    dispatch,
  );

  if (response.IsValid && response.Data) {
    yield put(publicSideImageLoaded(response.Data.ImageUrl));
  }
}

export function* getGeneralData() {
  const response = yield call(get, null, 'api/GeneralData');
  if (response.IsValid && response.Data) {
    yield put(generalDataLoaded(response.Data.PoliciesInfo));
  }
}
