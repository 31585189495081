import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MyPetsButton } from '../../common/MyPetsButton';
import './PetProfileProgressMyPets.css';

const PetProfileProgressMyPets = ({
  percentageComplete = 0,
  petName = '',
  steps = [],
}) => {
  const { t } = useTranslation('myPets');
  const [value, setValue] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setValue(percentageComplete);
    }, 500);
    return () => clearInterval(interval);
  }, [percentageComplete]);

  return (
    <div className="My-pets-profile-progress-container">
      <div className="My-pets-profile-progress-header Flex">
        <h2 className="My-pets-profile-progress-title">
          {t(
            'completeProfile.incompleteProfileTitle',
            { petName },
          )}
        </h2>
      </div>

      <div className="My-pets-profile-progress-actions Flex">
        {steps.map((step) => (
          <MyPetsButton
            key={step.name}
            isChecked={step.isComplete}
            onClick={step.onClick}
            text={step.name}
          />
        ))}
      </div>

      <div className="My-pets-profile-progress-bar-container">
        <div className="My-pets-profile-progress-bar" style={{ width: '100%' }}>
          <div
            className="My-pets-profile-progress-bar-completion"
            style={{
              width: `${value}%`,
            }}
          />
        </div>

        <span className="Percentage-complete">
          {t(
            'completeProfile.percentageComplete',
            { percentage: percentageComplete },
          )}
        </span>
      </div>
    </div>
  );
};

export { PetProfileProgressMyPets };
