import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import notCheckIcon from '../../assets/claims/empty-ellipsis.svg';
import checkMark from '../../assets/claims/check-circle-blue.svg';

import './Stepper.css';
import { CLAIM_STATUS_BY_TEXT } from '../../constants';

const CLAIM_STATUS = {
  open: 0,
  records: 1,
  // eslint-disable-next-line sort-keys
  inReview: 2,
  // eslint-disable-next-line sort-keys
  done: 3,
};

const Step = ({ icon, isComplete = false, stepValue = '' }) => (
  <div className="Circle-status">
    <img alt="" src={icon} />

    <span className="Circle-number" data-completed={isComplete}>
      {stepValue}
    </span>
  </div>
);

const Stepper = ({ status }) => {
  const { t } = useTranslation('claims');

  const convertedStatus = useMemo(() => {
    const statusFound = Object.entries(CLAIM_STATUS)
      .find(([key]) => key.toLowerCase()
        === status.toLowerCase().replace(/\s/g, ''));
    return statusFound?.[1] ?? CLAIM_STATUS.open;
  }, [status]);

  const getLineStyle = (checkStatus, currentStatus) => {
    const notFilledLineClass = 'Line-status';
    const baseStyle = currentStatus >= checkStatus
      ? 'Line-complete' : notFilledLineClass;

    return baseStyle === 'Line-complete' ? 'Line-Complete-Blue' : baseStyle;
  };

  const openLineStyle = getLineStyle(CLAIM_STATUS.records, convertedStatus);
  const recordsLineStyle = getLineStyle(CLAIM_STATUS.inReview, convertedStatus);
  const reviewLineStyle = getLineStyle(CLAIM_STATUS.done, convertedStatus);

  const inRecordsComplete = [
    CLAIM_STATUS_BY_TEXT.records,
    CLAIM_STATUS_BY_TEXT.inReview,
  ].includes(status);
  const inReviewComplete = CLAIM_STATUS_BY_TEXT.inReview === status;
  const inDoneComplete = CLAIM_STATUS_BY_TEXT.done === status;

  return (
    <div className="General-container">
      <div className="Status-container">
        <div className="Status-txt-container-blue">
          <span className="Status-txt-open">{t('statusOpen')}</span>
        </div>

        <div className="Status-txt-container-blue">
          <span className="Status-txt">
            {t('statusRecords')}
          </span>
        </div>

        <div className="Status-txt-container-blue">
          <span className="Status-txt Status-txt-review">
            {t('statusInReview')}
          </span>
        </div>

        <div className="Status-txt-container-blue">
          <span className="Status-txt Status-txt-done">
            {t('statusDone')}
          </span>
        </div>
      </div>

      <div className="Stepper-container">
        <div className="Circle-container">
          <Step icon={checkMark} isComplete />

          <div className={openLineStyle} />
        </div>

        <div className="Circle-container Circle-container-center">
          <Step
            icon={inRecordsComplete ? checkMark : notCheckIcon}
            isComplete={inRecordsComplete}
            stepValue={CLAIM_STATUS.records}
          />

          <div className={recordsLineStyle} />

          <Step
            icon={inReviewComplete ? checkMark : notCheckIcon}
            isComplete={inReviewComplete}
            stepValue={CLAIM_STATUS.inReview}
          />
        </div>

        <div className="Circle-container">
          <div className={reviewLineStyle} />

          <Step
            icon={inDoneComplete ? checkMark : notCheckIcon}
            isComplete={inDoneComplete}
            stepValue={CLAIM_STATUS.done}
          />
        </div>
      </div>
    </div>
  );
};

export default Stepper;
