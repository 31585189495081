import React, { useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useCustomerInboxDocsApiV2 } from '../../hooks';

import folderIcon from '../../assets/inboxDocs/black-folder.svg';

import downloadIcon
from '../../assets/inboxDocsMultibrand/download-circle-black.svg';
import editIcon
from '../../assets/inboxDocsMultibrand/edit-black.svg';
import moveIcon from '../../assets/inboxDocsMultibrand/move-arrow-black.svg';
import tagIcon from '../../assets/inboxDocsMultibrand/tag-black.svg';
import trashIcon from '../../assets/inboxDocsMultibrand/thrash-black.svg';

import { TripleDotMenu } from '../common/TripleDotMenu';
import { MoveModal } from './modals/MoveModal';
import { RenameModal } from './modals/RenameModal';
import { DeleteModal } from './modals/DeleteModal';
import {
  DEFAULT_FILE_PERMISSION,
  EDIT_FILE_PERMISSIONS,
  INBOX_DOCS,
  PERMISSIONS_INBOX_DOCS,
} from '../../constants';
import {
  downloadFiles,
  loadFolderStructure,
  refreshFolderContent,
} from '../../actions';

const InboxAndDocsMenu = ({
  displayingFolderContent = false,
  filePermissions = [],
  folderPermissions = [],
  goToFolder = () => { },
  isFolder,
  itemId,
  itemInfo,
  onEditTagPetClick = () => { },
  parentFolderId = 0,
}) => {
  const dispatch = useDispatch();
  const store = useSelector(({ inboxAndDocs }) => ({
    inboxAndDocs,
  }), shallowEqual);

  const { folderStructure } = store.inboxAndDocs;

  const [moveModalVisible, setMoveModalVisible] = useState(false);
  const [renameModalVisible, setRenameModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const { t } = useTranslation('inboxAndDocs');

  const location = useLocation();
  const folder = location.pathname.split('/').pop();
  const parentId = folder === 'inboxAndDocs' ? INBOX_DOCS.landing : folder;

  const itemsToDelete = isFolder ? [] : [itemId];
  const foldersToDelete = isFolder ? [itemId] : [];

  const isCustomerInboxDocsApiV2 = useCustomerInboxDocsApiV2();

  // File permission use folder permissions
  // (or default if folder second level refresh)
  const filePermissionsByFolder = useMemo(() => {
    const currentFolder = folderStructure
      .find(({ Id }) => String(Id) === String(parentFolderId));

    if (isCustomerInboxDocsApiV2) {
      let permissions = [];

      if (currentFolder?.CanAddFile) {
        permissions = [...permissions, PERMISSIONS_INBOX_DOCS.addFiles];
      }

      if (currentFolder?.CanAddFolder) {
        permissions = [...permissions, PERMISSIONS_INBOX_DOCS.addFolder];
      }

      if (currentFolder?.CanEdit) {
        permissions = [...permissions, ...EDIT_FILE_PERMISSIONS];
      }

      return [...DEFAULT_FILE_PERMISSION, ...permissions];
    }

    return currentFolder?.FilePermissions || DEFAULT_FILE_PERMISSION;
  }, [isCustomerInboxDocsApiV2, folderStructure, parentFolderId]);

  const menuOptions = useMemo(() => {
    const permissionsConfig =
      isFolder
        ? folderPermissions
        : filePermissions ?? filePermissionsByFolder;

    if (!permissionsConfig) {
      return [];
    }

    const permissions = [];

    permissionsConfig.forEach((permission) => {
      switch (permission) {
        case PERMISSIONS_INBOX_DOCS.delete:
          permissions.push({
            icon: trashIcon,
            id: 5,
            isReadOnlyVisible: false,
            onClick: () => {
              setDeleteModalVisible(true);
            },
            text: t('menuOptions.delete'),
          });
          break;

        case PERMISSIONS_INBOX_DOCS.download:
          permissions.push({
            icon: downloadIcon,
            id: 0,
            isReadOnlyVisible: true,
            onClick: () => {
              if (!isFolder) {
                dispatch(downloadFiles({
                  singleFile: true,
                  singleFileInfo: itemInfo,
                }));
              } else {
                dispatch(downloadFiles({
                  folderIds: [itemId],
                }));
              }
            },
            text: t('menuOptions.download'),
          });
          break;

        case PERMISSIONS_INBOX_DOCS.edittag:
          permissions.push({
            icon: tagIcon,
            id: 4,
            isDisabled: !!isFolder,
            isReadOnlyVisible: false,
            onClick: () => { onEditTagPetClick(); },
            text: t('menuOptions.editTag'),
          });
          break;

        case PERMISSIONS_INBOX_DOCS.move:
          permissions.push({
            icon: moveIcon,
            id: 2,
            isReadOnlyVisible: false,
            onClick: () => {
              if (!isFolder) {
                setMoveModalVisible(true);
              }
            },
            text: t('menuOptions.move'),
          });
          break;

        case PERMISSIONS_INBOX_DOCS.rename:
          permissions.push({
            icon: editIcon,
            id: 3,
            isReadOnlyVisible: false,
            onClick: () => {
              setRenameModalVisible(!renameModalVisible);
            },
            text: t('menuOptions.rename'),
          });
          break;

        case PERMISSIONS_INBOX_DOCS.viewinfolder:
          permissions.push({
            icon: folderIcon,
            id: 1,
            isReadOnlyVisible: true,
            onClick: () => {
              goToFolder();
            },
            text: t('menuOptions.viewInFolder'),
          });
          break;

        default:
          break;
      }
    });

    return permissions;
  }, [filePermissions, folderPermissions, isFolder]);

  function reloadParent() {
    dispatch(refreshFolderContent(parentId));
    dispatch(loadFolderStructure());
  }

  function handleCloseMoveModal() {
    setMoveModalVisible(false);
  }

  function handleCloseRenameModal() {
    setRenameModalVisible(false);
  }

  function handleCloseDeleteModal() {
    setDeleteModalVisible(false);
  }

  return (
    <>
      <TripleDotMenu
        displayingFolderContent={displayingFolderContent}
        isFolder={isFolder}
        itemId={itemId}
        options={menuOptions}
      />

      {!isFolder && (
        <MoveModal
          goToNewFolder={goToFolder}
          onClose={handleCloseMoveModal}
          selectedItemsIds={[itemId]}
          show={moveModalVisible}
        />
      )}

      <RenameModal
        isFolder={isFolder}
        itemExtension={itemInfo.Metadata.Extension}
        itemId={itemId}
        itemName={itemInfo.Name}
        onClose={handleCloseRenameModal}
        onSuccess={reloadParent}
        show={renameModalVisible}
      />

      <DeleteModal
        isFolder={isFolder}
        itemExtension={itemInfo.Metadata.Extension}
        itemId={itemId}
        itemName={itemInfo.Name}
        onClose={handleCloseDeleteModal}
        onSuccess={reloadParent}
        selectedFolderIds={foldersToDelete}
        selectedItemsIds={itemsToDelete}
        show={deleteModalVisible}
      />
    </>
  );
};

export { InboxAndDocsMenu };
