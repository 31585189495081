import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  InboxAndDocsTableHeader,
} from './fileListTable/InboxAndDocsTableHeader';
import { InboxAndDocsTable } from './fileListTable/InboxAndDocsTable';
import { InboxAndDocsItemRow } from './fileListTable/InboxAndDocsItemRow';
import {
  useAlphabeticalSortedPets,
} from '../../../hooks/useAlphabeticalSortedPets';
import FigoLottie from '../../common/FigoLottie';
import FigoLoadingOverlay from '../../common/FigoLoadingOverlay';
import loadingSpinner
from '../../../assets/lottieFiles/loading-blue.json';
import {
  errorViewFile,
  loadFolderContent,
  markFilesAsRead,
  refreshFolderContent,
  showViewFile,
} from '../../../actions';
import './InboxAndDocsFileList.css';
import {
  addOrRemoveItemFromList,
  downloadFileInPage,
  sortInboxAndDocsFileList,
} from '../../../services/utils';
import {
  INBOX_DOCS_FILE_CATEGORY,
  READ_FILE_SOURCE,
  FILTER_TYPES,
} from '../../../constants';
import { TagPetModal } from '../modals/TagPetModal';
import { MoveModal } from '../modals/MoveModal';
import { DeleteModal } from '../modals/DeleteModal';
import { ViewFileContent } from './fileViewer/ViewFileContent';

const InboxAndDocsFileList = ({
  changeLocation = () => { },
  currentFolderId = 0,
  goToFolder = () => { },
  goToFolderDirect = () => { },
}) => {
  const store = useSelector(({
    inboxAndDocs,
  }) => ({
    inboxAndDocs,
  }), shallowEqual);
  const [selectedFilter, setSelectedFilter] =
    useState({ ascendent: true, filter: null });
  const [itemRowListSelected, setItemRowListSelected] = useState([]);
  const [tagPetModalVisible, setTagPetModalVisible] = useState(false);
  const [moveModalVisible, setMoveModalVisible] = useState(false);
  const [fileToTagPet, setFileToTagPet] = useState(null);
  const [previousPetTagged, setPreviousPetTagged] = useState([]);
  const petList = useAlphabeticalSortedPets();
  const [petListChecked, setPetListChecked] = useState(petList);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const { t } = useTranslation('inboxAndDocs');
  const dispatch = useDispatch();
  const { inboxAndDocs } = store;
  const FILE_VIEWER_ACCEPTED_FILES = ['.pdf',
    '.html', '.txt', '.jpg', '.jpeg', '.png'];
  const isLoading = inboxAndDocs.viewFileLoading;

  useEffect(() => {
    if (petListChecked.length === 0 && petList.length > 0) {
      setPetListChecked(petList);
    }
  }, [petList, petListChecked]);

  const fileList = useMemo(() => {
    if (!inboxAndDocs || !inboxAndDocs.folderContent.length) {
      return [];
    }

    let fileListPerPet = inboxAndDocs.folderContent;

    if (petListChecked.length === petList.length) {
      return sortInboxAndDocsFileList({
        ascendent: selectedFilter.ascendent,
        rawList: fileListPerPet,
        sortType: selectedFilter.filter,
      });
    }

    fileListPerPet = inboxAndDocs.folderContent
      .filter((file) => file.Type === INBOX_DOCS_FILE_CATEGORY.folder
        || petListChecked.find(
          (pet) => (file.PetsId && file.PetsId.includes(pet.PetId)),
        ));

    return sortInboxAndDocsFileList({
      ascendent: selectedFilter.ascendent,
      rawList: fileListPerPet,
      sortType: selectedFilter.filter,
    });
  }, [
    inboxAndDocs.folderContent,
    petList,
    petListChecked,
    selectedFilter,
  ]);

  const filesAndDocsSelected = useMemo(() => {
    const allFiles = itemRowListSelected
      .filter((item) => item.Type === INBOX_DOCS_FILE_CATEGORY.file);

    const allFolders = itemRowListSelected
      .filter((item) => item.Type === INBOX_DOCS_FILE_CATEGORY.folder);

    const onlyFiles = itemRowListSelected
      .filter((item) => item.Type === INBOX_DOCS_FILE_CATEGORY.file
        && !item.IsReadOnly);

    const onlyFolders = itemRowListSelected
      .filter((item) => item.Type === INBOX_DOCS_FILE_CATEGORY.folder
        && !item.IsReadOnly);

    return {
      allFiles,
      allFolders,
      files: onlyFiles,
      folders: onlyFolders,
    };
  }, [itemRowListSelected]);

  const allRowsSelected =
    itemRowListSelected.length === fileList.length && fileList.length > 0;

  const handleFileViewerVisible = (data) => () => {
    if (data) {
      const validExtension = FILE_VIEWER_ACCEPTED_FILES
        .find((fileItem) => fileItem === data.Metadata.Extension.toLowerCase());

      if (validExtension) {
        dispatch(showViewFile(data));
      } else {
        downloadFileInPage({ fileName: data.Name, fileUrl: data.Metadata.Url });
      }
    } else {
      dispatch(errorViewFile());
    }

    if (data && !data.Metadata.IsRead) {
      dispatch(markFilesAsRead({
        filesIds: [data.Id],
        source: READ_FILE_SOURCE.inboxDocs,
      }));
    }
  };

  const handleRowSelection = ({ item }) => () => {
    if (itemRowListSelected.includes(item)) {
      setItemRowListSelected((prev) => [
        ...prev.filter((element) => element !== item),
      ]);

      return;
    }

    setItemRowListSelected((prev) => [...prev, item]);
  };

  function handleAllRowSelection() {
    const quantitySelected = itemRowListSelected.length;
    const totalQuantity = fileList.length;

    const newListSelected = quantitySelected === 0
      || quantitySelected < totalQuantity ? fileList : [];

    setItemRowListSelected(newListSelected);
  }

  function handleAssignTagPetClick({ fileId, previousPetIdTagged = [] }) {
    setFileToTagPet(fileId);
    const previousPets = petList
      .filter((pet) => previousPetIdTagged.includes(pet.PetId));

    setPreviousPetTagged(previousPets);
    setTagPetModalVisible(true);
  }

  function handleCloseTagPetModal() {
    setTagPetModalVisible(false);
  }

  function handleSuccessTagPetModal() {
    dispatch(loadFolderContent(currentFolderId));
  }

  function restartRowSelection() {
    setItemRowListSelected([]);
  }

  function reloadParent() {
    dispatch(refreshFolderContent(currentFolderId));
  }

  const fileListByDate = useMemo(() => fileList.sort((a, b) => {
    if (b.Metadata.LastAddedDate > a.Metadata.LastAddedDate
      || b.LastUpdate > a.LastUpdate) {
      return 1;
    }
    if (b.Metadata.LastAddedDate < a.Metadata.LastAddedDate
      || b.LastUpdate < a.LastUpdate) {
      return -1;
    }
    return 0;
  }), [fileList]);

  const renderContent = () => {
    if (inboxAndDocs.loading) {
      return (
        <tr>
          <td className="Docs-loading-container">
            <FigoLottie
              animationData={loadingSpinner}
              height={74}
              width={74}
            />
          </td>
        </tr>
      );
    }

    return fileListByDate.map((item) => (
      <InboxAndDocsItemRow
        key={item.Id}
        changeLocation={changeLocation}
        currentFolderId={currentFolderId}
        goToFolder={goToFolder}
        isSelected={itemRowListSelected.includes(item)}
        itemRow={item}
        onAssignTagPetClick={handleAssignTagPetClick}
        onEditTagPetClick={handleAssignTagPetClick}
        onFileRowClick={handleFileViewerVisible(item)}
        onRowSelection={handleRowSelection({ item })}
      />
    ));
  };

  function handleFileDateAddedFilter() {
    setSelectedFilter(
      (filter) => ({ ascendent: !filter.ascendent, filter: FILTER_TYPES.date }),
    );
  }

  function handleFileNameFilter() {
    setSelectedFilter(
      (filter) => ({ ascendent: !filter.ascendent, filter: FILTER_TYPES.name }),
    );
  }

  function handleFileSizeFilter() {
    setSelectedFilter(
      (filter) => ({ ascendent: !filter.ascendent, filter: FILTER_TYPES.size }),
    );
  }

  function handlePetSelected(petSelected) {
    if (petListChecked.length > 1) {
      const newPetList = addOrRemoveItemFromList({
        itemList: petListChecked,
        newItem: petSelected,
      });

      setPetListChecked(newPetList);
    } else if (petListChecked.length === 1) {
      const singleItem = petListChecked[0];

      if (singleItem.PetId !== petSelected.PetId) {
        const newPetList = addOrRemoveItemFromList({
          itemList: petListChecked,
          newItem: petSelected,
        });

        setPetListChecked(newPetList);
      }
    }
  }

  function getPetSelectorTitle() {
    const quantity = petListChecked.length;
    if (quantity !== petList.length) {
      return t('petsSelected', { quantity });
    }

    return t('allPets');
  }

  function handleOpenMoveModal() {
    setMoveModalVisible(true);
  }

  function handleCloseMoveModal() {
    setMoveModalVisible(false);
  }

  function handleOpenDeleteModal() {
    setDeleteModalVisible(true);
  }

  function handleCloseDeleteModal() {
    setDeleteModalVisible(false);
  }

  function getFileNameSelected() {
    if (filesAndDocsSelected?.files?.length > 0) {
      return filesAndDocsSelected.files[0].Name;
    }

    return '';
  }

  const onResetFilterClick = () => {
    setPetListChecked(petList);
  };

  return (
    <div className="Docs-table-container-full-width">
      <InboxAndDocsTableHeader
        cleanSelection={restartRowSelection}
        currentFolderId={currentFolderId}
        fileList={filesAndDocsSelected}
        onPermanentlyDeleteClick={handleOpenDeleteModal}
        onPetSelected={handlePetSelected}
        onResetFilterClick={onResetFilterClick}
        onRestoreFilesClick={handleOpenMoveModal}
        onTagPetClick={handleAssignTagPetClick}
        petList={petList}
        petListChecked={petListChecked}
        petSelectedTitle={getPetSelectorTitle()}
      />

      <InboxAndDocsTable
        allRowsSelected={allRowsSelected}
        currentFolderId={currentFolderId}
        dateAddedFilter={selectedFilter.filter === FILTER_TYPES.date}
        fileSizeFilter={selectedFilter.filter === FILTER_TYPES.size}
        isAscendingFilter={selectedFilter.ascendent}
        nameFilter={selectedFilter.filter === FILTER_TYPES.name}
        onAllRowSelection={handleAllRowSelection}
        onFileDateAddedFilterClick={handleFileDateAddedFilter}
        onFileNameFilterClick={handleFileNameFilter}
        onFileSizeFilterClick={handleFileSizeFilter}
      >
        {renderContent()}
      </InboxAndDocsTable>

      <ViewFileContent
        file={inboxAndDocs.viewFileData}
        isLoading={isLoading}
        onClose={handleFileViewerVisible()}
        show={inboxAndDocs.viewFileVisible
          && inboxAndDocs.viewFileData}
      />

      <FigoLoadingOverlay
        message={inboxAndDocs.downloadingFiles ? t('preparingFiles') : ''}
        visible={inboxAndDocs.markingFilesAsRead
          || (isLoading && inboxAndDocs.viewFileVisible)
          || inboxAndDocs.downloadingFiles}
      />

      <TagPetModal
        currentFolderId={currentFolderId}
        fileToTagPet={fileToTagPet}
        onCloseTagPetModal={handleCloseTagPetModal}
        onSuccessTagPetModal={handleSuccessTagPetModal}
        petList={petList}
        previousPetTagged={previousPetTagged}
        show={tagPetModalVisible}
      />

      <MoveModal
        goToNewFolder={goToFolderDirect}
        isTrashScreen
        onClose={handleCloseMoveModal}
        selectedFoldersIds={filesAndDocsSelected.folders.map((item) => item.Id)}
        selectedItemsIds={filesAndDocsSelected.files.map((item) => item.Id)}
        show={moveModalVisible}
        title={t('restoreModal.titleModal')}
      />

      <DeleteModal
        isFolder={false}
        isTrashScreen
        itemName={getFileNameSelected()}
        onClose={handleCloseDeleteModal}
        onSuccess={reloadParent}
        selectedFolderIds={filesAndDocsSelected.folders.map((item) => item.Id)}
        selectedItemsIds={filesAndDocsSelected.files.map((item) => item.Id)}
        show={deleteModalVisible}
      />
    </div>
  );
};

export { InboxAndDocsFileList };
