import React, { useEffect, useState } from 'react';

import { APP_STORE_URL, GOOGLE_PLAY_URL } from '../../constants';
import { delay } from '../../services/utils';

import './bannerApp.css';

import FigoIcon from '../../assets/login/app-icon.png';

const BannerApp = ({ appName, buttonText, title }) => {
  const [classShow, setClassShow] = useState('');
  const [isAndroid, setAndroid] = useState(false);
  const [isMobile, setMobile] = useState(false);
  const [isSafari, setSafari] = useState(false);

  useEffect(() => {
    const userAgent = typeof window.navigator === 'undefined'
      ? '' : navigator.userAgent.toLowerCase();

    setMobile(userAgent.match(/android|iPhone/i));
    setAndroid(userAgent.match(/android/i));
    setSafari(userAgent.match(/safari/i)
      && !userAgent.match(/crios|duckduckgo|fxios|chrome/i));
  }, []);

  useEffect(() => {
    const show = async () => {
      if (isSafari) {
        setClassShow('Banner-hide');
        return;
      }

      await delay(300);

      setClassShow('Banner-container-show');
    };

    if (isMobile) {
      show();
    }
  }, [isMobile]);

  async function handlePlaystoreClick() {
    if (isAndroid) {
      window.location.href = 'app://open.mypetcloud';
      await delay(300);
    }

    window.location.href = isAndroid ? GOOGLE_PLAY_URL : APP_STORE_URL;
  }

  return (
    <div className={`Banner-container ${classShow}`}>
      <div>
        <div className="Banner-icon-container">
          <img alt="app-icon" className="Banner-logo" src={FigoIcon} />

          <div className="Banner-text-name-container">
            <span>{appName}</span>

            <span>{title}</span>
          </div>
        </div>
      </div>

      <div>
        <button
          className="Banner-button"
          onClick={handlePlaystoreClick}
          type="button"
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export { BannerApp };
