import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';

import downloadIcon
from '../../../../assets/inboxDocsMultibrand/download-circle-blue.svg';
import markAsReadIcon from
'../../../../assets/inboxDocsMultibrand/mark-as-read.svg';
import moveFileIcon
from '../../../../assets/inboxDocsMultibrand/move-arrow-blue.svg';

import tagFileIcon
from '../../../../assets/inboxDocsMultibrand/tag-blue.svg';
import deleteFileIcon
from '../../../../assets/inboxDocsMultibrand/thrash-blue.svg';

import { PetSelectorMultisSelection } from './PetSelectorMultisSelection';
import { HeaderButtonFileAction } from './HeaderButtonFileAction';
import { MoveModal } from '../../modals/MoveModal';
import { DeleteModal } from '../../modals/DeleteModal';
import {
  ACTION_BUTTONS_CONTAINER_ID,
  INBOX_DOCS_PRIVATE_FOLDERS,
  INBOX_DOCS,
  READ_FILE_SOURCE,
} from '../../../../constants';
import {
  downloadFiles,
  loadFolderContent,
  loadFolderStructure,
  markFilesAsRead,
} from '../../../../actions';
import { TrashHeaderContainer } from './TrashHeaderContainer';
import { useResponsive } from '../../../../hooks/useResponsive';

const InboxAndDocsTableHeader = ({
  cleanSelection = () => { },
  currentFolderId = -1,
  fileList = {
    allFiles: [],
    allFolders: [],
    files: [],
    folders: [],
  },
  isEmptyFolder = false,
  onPermanentlyDeleteClick = () => { },
  onPetSelected = () => { },
  onResetFilterClick = () => { },
  onRestoreFilesClick = () => { },
  onTagPetClick = () => { },
  petList = [],
  petListChecked = [],
  petSelectedTitle = '',
}) => {
  const { t } = useTranslation('inboxAndDocs');
  const dispatch = useDispatch();
  const { isTablet } = useResponsive();
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [showModalDelete, setShowDeleteModal] = useState(false);

  const isTrashFolder = String(currentFolderId) === String(INBOX_DOCS.trash);
  const isPrivateFolder = INBOX_DOCS_PRIVATE_FOLDERS
    .includes(String(currentFolderId));

  const haveFiles = fileList.files.length > 0;
  const haveFolders = fileList.folders.length > 0;
  const haveAnyMark = fileList.files.length > 0 || fileList.folders.length > 0;
  const markedFiles = fileList.files.length;

  const tagPetDisabled = markedFiles !== 1 || haveFolders;
  const markAsReadDisabled = !haveFiles;
  const downloadDisabled = !fileList.allFiles.length
    && !fileList.allFolders.length;
  const trashHeaderDisabled = !haveFiles && !haveFolders;
  const moveHeaderDisabled = !haveFiles || haveFolders;
  const deleteHeaderDisabled = !haveAnyMark || (haveFiles && haveFolders)
    || fileList.folders.length > 1;

  function handleDownloadFileClick() {
    let downloadInfo = {};
    const singleFile = fileList.allFiles.length === 1
      && fileList.allFolders.length === 0 ? fileList.allFiles[0] : {};

    if (singleFile) {
      downloadInfo = {
        singleFile: true,
        singleFileInfo: singleFile,
      };
    }

    if (fileList.allFiles.length > 1 || fileList.allFolders.length) {
      downloadInfo = {
        fileIds: fileList.allFiles.map((item) => item.Id),
        folderIds: fileList.allFolders.map((item) => item.Id),
      };
    }

    dispatch(downloadFiles(downloadInfo));
  }

  function handleMarkAsReadFileClick() {
    if (fileList.files.length) {
      dispatch(markFilesAsRead({
        filesIds: fileList.files.map((file) => file.Id),
        source: READ_FILE_SOURCE.toolbar,
      }));
      cleanSelection();
    }
  }

  function handleMoveFileClick() {
    setShowMoveModal(true);
  }

  function handleTagFileClick() {
    const currentFile = fileList.files[0];

    onTagPetClick({
      fileId: currentFile.Id,
      previousPetIdTagged: currentFile.PetsId,
    });
  }

  function handleDeleteFileClick() {
    setShowDeleteModal(true);
  }

  function handleItemClick(item) {
    onPetSelected(item);
  }

  function renderOnlyHeader() {
    return (
      <PetSelectorMultisSelection
        classContainer={petList.length !== petListChecked.length
          ? 'Pet-selector-pets-selected' : ''}
        itemList={petList}
        itemListChecked={petListChecked}
        onItemClick={handleItemClick}
        onResetFilterClick={onResetFilterClick}
        title={petSelectedTitle}
      />
    );
  }

  function handleCloseMoveModal() {
    setShowMoveModal(false);
  }

  function goToFolder() {
    dispatch(loadFolderStructure());
    dispatch(loadFolderContent(currentFolderId));
  }

  function getFilesToMoveIds() {
    if (haveFolders) {
      return fileList.folders.map((item) => item.Id);
    }

    return fileList.files.map((item) => item.Id);
  }

  function handleCloseDeleteModal() {
    setShowDeleteModal(false);
  }

  function getFileNameSelected() {
    if (fileList?.files?.length > 0) {
      return fileList.files[0].Name;
    }

    return '';
  }

  function renderCompleteHeader() {
    return (
      <>
        <MoveModal
          goToNewFolder={goToFolder}
          isFolder={haveFolders}
          onClose={handleCloseMoveModal}
          selectedItemsIds={getFilesToMoveIds()}
          show={showMoveModal}
        />

        <DeleteModal
          isFolder={haveFolders}
          itemName={getFileNameSelected()}
          onClose={handleCloseDeleteModal}
          onSuccess={goToFolder}
          selectedFolderIds={fileList.folders.map((item) => item.Id)}
          selectedItemsIds={fileList.files.map((item) => item.Id)}
          show={showModalDelete}
        />

        {renderOnlyHeader()}

        <HeaderButtonFileAction
          disabled={downloadDisabled}
          handleButtonClick={handleDownloadFileClick}
          icon={downloadIcon}
          id="downloadFileHeaderButton"
          title={t('tableHeaderButtons.download')}
        />

        <HeaderButtonFileAction
          disabled={markAsReadDisabled}
          handleButtonClick={handleMarkAsReadFileClick}
          icon={markAsReadIcon}
          id="markAsReadFileHeaderButton"
          title={t('tableHeaderButtons.markAsRead')}
        />

        <HeaderButtonFileAction
          disabled={isPrivateFolder || moveHeaderDisabled}
          handleButtonClick={handleMoveFileClick}
          icon={moveFileIcon}
          id="moveFileHeaderButton"
          title={t('tableHeaderButtons.move')}
        />

        <HeaderButtonFileAction
          disabled={tagPetDisabled || isPrivateFolder}
          handleButtonClick={handleTagFileClick}
          icon={tagFileIcon}
          id="tagFileHeaderButton"
          title={t('tableHeaderButtons.tag')}
        />

        <HeaderButtonFileAction
          disabled={isPrivateFolder || deleteHeaderDisabled}
          handleButtonClick={handleDeleteFileClick}
          icon={deleteFileIcon}
          id="deleteFileHeaderButton"
          title={t('tableHeaderButtons.delete')}
        />
      </>
    );
  }

  function conditionalHeaderRender() {
    if (isTrashFolder) {
      const actionButtonsContainer = document
        .getElementById(ACTION_BUTTONS_CONTAINER_ID);

      if (!actionButtonsContainer) {
        return null;
      }

      return createPortal(
        (<TrashHeaderContainer
          isDisabled={trashHeaderDisabled}
          onPermanentlyDeleteClick={onPermanentlyDeleteClick}
          onRestoreFilesClick={onRestoreFilesClick}
        />), actionButtonsContainer,
      );
    }

    if (isEmptyFolder) {
      return renderOnlyHeader();
    }

    return renderCompleteHeader();
  }

  const docsHeaderContainerCSS = isTrashFolder && isTablet
    ? 'Docs-header-container-trash' : 'Docs-header-container';

  return (
    <div
      className={`${docsHeaderContainerCSS} Docs-header-justify-content-start`}
    >
      {conditionalHeaderRender()}
    </div>
  );
};

export { InboxAndDocsTableHeader };
