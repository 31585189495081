import React from 'react';

import LoadingButton from '../../common/LoadingButton';

import './css/ChangeCoverageScreenPreview.css';

const ChangeCoverageButtons = ({
  containerClass = '',
  disabledPrimaryButton = false,
  loadingPrimaryButton = false,
  onClickPrimaryButton = () => { },
  onClickSecondaryButton = () => { },
  primaryText = '',
  secondaryText = '',
  successPrimaryButton = false,
}) => (
  <div
    className={`Change-coverage-preview-buttons-container ${containerClass}`}
  >
    <button
      className="Change-coverage-preview-keep-button"
      onClick={onClickSecondaryButton}
      type="button"
    >
      {secondaryText}
    </button>

    <LoadingButton
      className="Change-coverage-preview-change-button"
      disabled={disabledPrimaryButton}
      loading={loadingPrimaryButton}
      onClick={onClickPrimaryButton}
      success={successPrimaryButton}
      text={primaryText}
    />
  </div>
);

export { ChangeCoverageButtons };
