import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ContactInformationRow,
  HoursOfOperationTitle,
  ScheduleRow,
  ScheduleRowTitle,
} from './common';

import {
  FACEBOOK_TITLE,
  MESSENGER_LINK,
} from './contactUs.constants';

import './ContactUsCompany.css';

const getTitleFromLink = ({ link, title }) => {
  if (!title) {
    if (link === MESSENGER_LINK) {
      return FACEBOOK_TITLE;
    }

    return link;
  }

  return title;
};

const ContactUsCompany = ({
  contactInfo = {},
  extraText = '',
  logo = null,
  logoClass = '',
}) => {
  const { t } = useTranslation('sideMenu');

  return (
    <div className="Contact-us-company-container">

      <div className="Contact-us-company-header-container">
        <h1 className="Contact-us-company-header-title">
          {t('contactUs.menuTitle')}
        </h1>

        <p className="Contact-us-company-header-description">
          {t('contactUs.description')}
        </p>

      </div>

      <img alt="" className={logoClass} src={logo} />

      <div className="Contact-us-extra-text">{extraText}</div>

      <HoursOfOperationTitle />

      {contactInfo.HoursOfOperation?.map((item, childNum) => (
        <div
          key={`ContactuUs-${item.Name}`}
          className={childNum > 0 ? 'Margin-top-16' : ''}
        >
          <ScheduleRowTitle title={item.Name} />

          {item.Date.map((date, index) => (
            <ScheduleRow
              key={`ScheduleRow-${date.Value}`}
              classContainer={`Margin-top-${index === 0 ? '8' : '15'}`}
              leftText={`${date.Value.split(': ')[0]}:`}
              rightText={date.Value.split(': ')[1]}
            />
          ))}
        </div>
      ))}

      <div className="Margin-top-8" />

      {contactInfo.ContactInformation?.map((item) => (
        <ContactInformationRow
          key={item.Value}
          leftText={item.Name}
          link={!item.Value ? item.Action : ''}
          rightText={getTitleFromLink({
            link: item.Action,
            title: item.Value,
          })}
        />
      ))}
    </div>
  );
};

export { ContactUsCompany };
