import React from 'react';

import './InboxAndDocsTableHeader.css';

const HeaderButtonFileAction = ({
  classNameContainer = '',
  disabled = false,
  handleButtonClick = () => { },
  icon,
  id = '',
  title = '',
}) => (
  <button
    className={'Docs-header-button-container'
      + ` ${disabled
        ? 'Docs-header-button-disabled' : ''} ${classNameContainer}`}
    id={id}
    onClick={handleButtonClick}
    type="button"
  >
    <img alt="" src={icon} />

    <span>{title}</span>
  </button>
);

export { HeaderButtonFileAction };
