export const PLANS = {
  essential: 12,
  preferred: 9,
  ultimate: 39,
};

export const MULTIMODAL_SECTIONS = {
  needToKnow: 1,
  petProtectCopay: 4,
  termsAndConditions: 3,
  vetExamFees: 5,
  wellnessDetails: 2,
};
