import React from 'react';

import './MultipleOptionsSelector.css';

// WIP
const MultipleOptionsSelector = () => {
  const test = 'WIP krnl ;P';

  return (
    <div>
      {test}
    </div>
  );
};

export { MultipleOptionsSelector };
