import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';

import {
  useAlphabeticalSortedPetsWithPolicy,
  useBackButton,
  useMixpanel,
  usePMS,
} from '../../../hooks';

import { usePetPolicies } from '../../../hooks/usePetPolicies';

import figoLogo from '../../../assets/figo-logo-white.svg';

import {
  billingPaymentReseted,
  clearPolicyNotifications,
  getChangeCoveragePreview,
  isFirstTimeOnClaims,
  petSelected,
  policySelected,
  policySelectedOnClaims,
  restartRate,
  reviewChangesVisibleChanged,
  setSelectedPetId,
  setSelectedPetIndex,
  validateChangeCoverage,
  validateChangeCoverageCleared,
} from '../../../actions';

import {
  CHANGE_COVERAGE_WEB,
  INSURANCE,
} from '../../navigation/routes.constants';

import { EV1 } from '../../../services/mixpanel';

import { PetCloudBreadcrumbs } from '../../myPets/common/PetCloudBreadcrumbs';
import FigoLoadingOverlay from '../../common/FigoLoadingOverlay';
import Shimmer from '../../common/Shimmer';

import { ChangeCoverageScreenPreview } from './ChangeCoverageScreenPreview';
import { ChangeCoverageScreenOptions }
from './changeCoverageOptions/ChangeCoverageScreenOptions';
import { usePolicyBrand } from '../../../hooks/usePolicyBrand';

import './css/ChangeCoverageInsuranceScreen.css';

const ChangeCoverageInsuranceScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const { sendEvent } = useMixpanel();
  const { t } = useTranslation('changeCoverage');
  const store = useSelector(({ policies }) => ({ policies }));
  const { policyNumber } = useParams();
  const { policyBrandName } = usePolicyBrand({ policyNumber });
  const { hasPets } = usePetPolicies();
  const { pms } = usePMS();
  const sortedPetsWithPolicy =
    useAlphabeticalSortedPetsWithPolicy();
  const {
    policies: {
      allPets,
      allPolicies,
      changeCoverage: { success, reviewChangesVisible },
      changeCoverageValidation,
      loading: policiesLoading,
    },
  } = store;

  const isPolicyOwner = useMemo(() => {
    let isOwner = false;

    if (allPolicies && policyNumber) {
      isOwner = !!allPolicies
        .find((policy) => policy.Policy.Number === policyNumber);
    }

    return isOwner;
  }, [allPolicies, policyNumber]);

  useBackButton(() => {
    dispatch(restartRate());
  }, []);

  useEffect(() => () => { dispatch(restartRate()); }, [dispatch]);

  useEffect(() => {
    if (store.policies.loading) {
      return;
    }

    if (isPolicyOwner) {
      dispatch(validateChangeCoverage({ policyNumber }));
    } else {
      const source = window.location.pathname;
      sendEvent(EV1({ pms, policyBrand: policyBrandName, source }));
      history.push(INSURANCE);
    }
  }, [dispatch, isPolicyOwner, policyNumber, store.policies.loading]);

  function handlePolicySelect(index) {
    const { policies } = store;

    dispatch(clearPolicyNotifications());

    if (!policies.allPolicies.length) {
      return;
    }

    dispatch(policySelected(index));
    dispatch(policySelectedOnClaims(index + 1));
    dispatch(isFirstTimeOnClaims(false));
  }

  function handlePetSelect(index) {
    const petId = allPets[index].PetId;
    const indexBillingPayment = sortedPetsWithPolicy
      .findIndex((pet) => pet.PetId === petId);
    const indexNotFound = -1;
    const firstIndex = 0;
    dispatch(petSelected(indexBillingPayment === indexNotFound
      ? firstIndex
      : indexBillingPayment));
    dispatch(setSelectedPetId(petId));
    dispatch(clearPolicyNotifications());
    dispatch(setSelectedPetIndex(index));
    dispatch(billingPaymentReseted());
  }

  useEffect(() => {
    if (policiesLoading || !changeCoverageValidation.isSuccess) {
      return;
    }

    if (hasPets) {
      const petIndexToSelect = sortedPetsWithPolicy
        .findIndex((pet) => pet.policies
          .find((policy, index) => {
            const policyFound = policy.Number === policyNumber;
            if (policyFound) {
              handlePolicySelect(index);
            }
            return policyFound;
          }));

      if (petIndexToSelect < 0
        || (changeCoverageValidation.isSuccess
          && !changeCoverageValidation.isValid)) {
        const source = window.location.pathname;
        sendEvent(EV1({ pms, policyBrand: policyBrandName, source }));
        history.replace(INSURANCE);
      } else {
        handlePetSelect(petIndexToSelect);
        dispatch(validateChangeCoverageCleared());
        dispatch(getChangeCoveragePreview({ policyNumber }));
      }
    }
  }, [
    dispatch,
    changeCoverageValidation.isSuccess,
    changeCoverageValidation.isValid,
    policyNumber,
    sortedPetsWithPolicy,
    hasPets]);

  function shouldPromptWarning(_crntLocation, nextLocation) {
    const { isSuccess, isValid } = changeCoverageValidation;

    if (nextLocation.pathname === INSURANCE && success) {
      return false;
    }

    return ((!isSuccess || isValid)
      && nextLocation.pathname !== `${CHANGE_COVERAGE_WEB}/${policyNumber}`);
  }

  function handleBackButton() {
    if (reviewChangesVisible) {
      dispatch(reviewChangesVisibleChanged(false));
      return;
    }

    history.goBack();
  }

  function handleChangeCoverageButton() {
    history.push(`${CHANGE_COVERAGE_WEB}/${policyNumber}/options`);
  }

  function handleKeepCoverageButton() {
    const findPetId =
    allPolicies.find((policy) => policy.Policy.Number === policyNumber);
    const source = window.location.pathname;
    sendEvent(EV1({ pms, policyBrand: policyBrandName, source }));
    const url = findPetId
      ? `${INSURANCE}/${findPetId?.PetInformation?.PetId}` : INSURANCE;
    history.push(url);
  }

  function renderChangeCoverageContainer() {
    if (policiesLoading || changeCoverageValidation.isLoading) {
      return (
        <Shimmer
          className="Change-coverage-insurance-card-container-shimmer"
        />
      );
    }

    return (
      <div className={'Change-coverage-insurance-card-container '
        + `${reviewChangesVisible
          ? 'Change-coverage-insurance-container-mobile' : ''}`}
      >
        <Switch>
          <Route path={`${CHANGE_COVERAGE_WEB}/:policyNumber/options`}>
            <ChangeCoverageScreenOptions
              onRateCreationError={handleBackButton}
              policyNumber={policyNumber}
              validatePromptWarning={shouldPromptWarning}
            />
          </Route>

          <Route exact path={match.path}>
            <ChangeCoverageScreenPreview
              onChangeCoverageClick={handleChangeCoverageButton}
              onKeepClick={handleKeepCoverageButton}
            />
          </Route>
        </Switch>
      </div>
    );
  }

  return (
    <div className="Change-coverage-insurance-screen-container">
      <PetCloudBreadcrumbs
        activeRoute={t('preview.changeCoverage')}
        rootRoute={t('insuranceTitle')}
      />

      <h1>{t('preview.changeCoverage')}</h1>

      <div className="Change-coverage-insurance-header-container">
        <img
          alt=""
          className="Change-coverage-insurance-header-logo"
          src={figoLogo}
        />
      </div>

      {renderChangeCoverageContainer()}

      <FigoLoadingOverlay
        visible={policiesLoading || changeCoverageValidation.isLoading}
      />
    </div>
  );
};

export { ChangeCoverageInsuranceScreen };
