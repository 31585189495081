import React from 'react';
import './TrashHeaderContainer.css';
import { PermanentlyDeleteButton } from './PermanentlyDeleteButton';
import { RestoreButton } from './RestoreButton';

const TrashHeaderContainer = ({
  onPermanentlyDeleteClick = {},
  onRestoreFilesClick = () => { },
  isDisabled = false,
}) => (
  <div className="Trash-buttons-container">
    <PermanentlyDeleteButton
      isDisabled={isDisabled}
      onPermanentlyDeleteClick={onPermanentlyDeleteClick}
    />

    <RestoreButton
      isDisabled={isDisabled}
      onRestoreFilesClick={onRestoreFilesClick}
    />
  </div>
);

export { TrashHeaderContainer };
