import React from 'react';

import arrow from '../../../../assets/back-arrow.svg';

import './CardProvider.css';

const CardProvider = ({
  onClick = () => { },
  providerIcon = null,
  providerIconStyle = '',
}) => (
  <button
    className="Contact-us-card-provider-card"
    onClick={onClick}
    type="button"
  >
    <img alt="provider" className={providerIconStyle} src={providerIcon} />

    <img alt="go" className="Contact-us-card-provider-back-arrow" src={arrow} />
  </button>
);

export { CardProvider };
