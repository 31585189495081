export const POLICY_STATUS = {
  active: 1,
  cancelled: 0,
  expired: 3,
  future: 2,
  past: 4,
  unPolicy: -1,
};

export const MULTIBRAND_POLICY_STATUS = {
  cancelled: 'Cancelled',
  // eslint-disable-next-line sort-keys
  active: 'Active',
  future: 'Future',
  // eslint-disable-next-line sort-keys
  expired: 'Expired',
  past: 'Past',
  unPolicy: 'UnPolicy',
};

export const PAYMENT_METHOD_IDS = {
  ach: 0,
  agencyBill: 2,
  creditCard: 1,
};

export const BANK_ACCOUNT = 'Bank Account';

export const CREDIT_CARD = 'Credit Card';

export const PAYMENT_METHOD_NAMES = [
  BANK_ACCOUNT,
  CREDIT_CARD,
  'Agency Bill',
];

export const PAYMENT_PLANS = {
  annual: 1,
  monthly: 0,
};

export const PAYMENT_PLANS_BY_NAME = {
  annual: 'Annual',
  monthly: 'Monthly',
};

export const REIMBURSEMENT_METHODS = {
  check: 1,
  deposit: 2,
  none: 0,
};

export const PET_TYPE = {
  CAT: 13,
  DOG: 12,
};

export const PET_TYPE_ID = {
  CAT: 1,
  DOG: 0,
};

/**
 * Multibrand endpoints return the pet type based on this
 */
export const PET_TYPE_MULTIBRAND = {
  CAT: 2,
  DOG: 1,
};

export const PET_GENDER = {
  FEMALE: 1,
  MALE: 0,
};

export const FILE_CLAIMS_STEPS = {
  claimsDetails: {
    id: 4,
    parentStep: 1,
  },
  claimType: {
    id: 2,
    parentStep: 1,
  },
  claimTypeDetail: {
    id: 3,
    parentStep: 1,
  },
  fileClaimFail: {
    id: 9,
    parentStep: 3,
  },
  fileClaimSuccess: {
    id: 10,
    parentStep: 3,
  },
  fileUpload: {
    id: 5,
    parentStep: 1,
  },
  reimbursementMethod: {
    id: 6,
    parentStep: 2,
  },
  review: {
    id: 8,
    parentStep: 3,
  },
  selectPet: {
    id: 1,
    parentStep: 1,
  },
  termsAndConditions: {
    id: 7,
    parentStep: 3,
  },
};

export const CLAIM_TYPES = {
  ADVERTISING_REWARD: 4,
  EMERGENCY_BOARDING: 3,
  MORTALITY: 6,
  THEFT_OR_STRAYING: 5,
  VACATION_CANCELLATION: 7,
  VET_FEE: 2,
};

export const ACCOUNT_TYPES = {
  CHECKING: 1,
  SAVINGS: 2,
};

export const MAX_SIZE_MB = 15;
export const ALLOWED_EXT =
  /(\.pdf|\.jpg|\.jpeg|\.png|\.doc|\.docx|\.xls|\.xlsx|\.csv|\.txt|\.html)$/i;
export const CARD_NUMBER_DASHES_LENGTH = 19;
export const CARD_NUMBER_LENGTH = 16;
export const CVV_MAX_LENGTH = 4;
export const CVV_MIN_LENGTH = 3;
export const EXPIRATION_DATE_DASHES_LENGTH = 5;
export const EXPIRATION_DATE_LENGTH = 4;
export const MAX_CITY_LENGTH = 20;
export const EDIT_ROUTING_NUMBER_LENGTH = 9;
export const NAME_LENGTH = 60;
export const PET_TAG_LENGTH = 5;
export const ROUTING_NUMBER_LENGTH = 30;
export const STREET_ADDRESS_LENGTH = 30;
export const ZIP_CODE_LENGTH = 5;

export const MONTHLY_FEE = '2.00';

export const FILES_TYPES_ACCEPTED = {
  csv: '.csv',
  doc: '.doc',
  docx: '.docx',
  html: '.html',
  jpeg: '.jpeg',
  jpg: '.jpg',
  pdf: '.pdf',
  png: '.png',
  txt: '.txt',
  xls: '.xls',
  xlsx: '.xlsx',
};

export const GOOGLE_PLAY_URL =
  'https://play.google.com/store/apps/details?id=com.figoinsurance.petcloud';

export const APP_STORE_URL =
  'https://apps.apple.com/us/app/figo-pet-cloud/id1278520013';

export const SMS_APP_LINKS = 'Download the Pet Cloud app.\n'
  + 'For iOS: https://appstore.com/petcloud\n'
  + 'For Android https://play.google.com/store/'
  + 'apps/details?id=com.figoinsurance.petcloud';

export const FACEBOOK_URL = 'https://www.facebook.com/FigoPet';

export const TWITTER_URL = 'https://twitter.com/FigoPet';

export const INSTAGRAM_URL = 'https://www.instagram.com/FigoPet/';

export const CONTACT_FIGO_URL =
  'https://figopetinsurance.com/contact-us-figo-pet-insurance';

export const LEARN_MORE_URL = 'https://figopetinsurance.com/pet-cloud';

export const FIGO_INSURANCE_URL = 'https://figopetinsurance.com/pet-insurance';

export const PRIVACY_POLICY_LINK =
  'https://figopetinsurance.com/figo-privacy-policy';

export const TERMS_OF_USE_LINK =
  'https://figopetinsurance.com/what-are-figos-terms-use';
export const CONTACT_US_URL =
  'https://figopetinsurance.com/contact-us-figo-pet-insurance';

export const POLICY_DOCUMENTS_URL = 'Policy%20Documents';

export const NEW_CLAIM_STEPS = {
  CLAIM_INFO: 3,
  CLAIM_TYPE_SELECTION: 2,
  PET_SELECTION: 1,
};

export const ONE_INC_PAYMENT_CATEGORY = {
  bankAccount: 'Echeck',
  creditCard: 'CreditCard',
};

export const ONE_INC_DEFAULT_TOKEN = 'DEFAULT';

export const ONE_INC_EMPTY_ACH = {
  Address: '',
  AddressFirstName: '',
  BillingInfoType: 0,
  City: '',
  LastName: '',
  MaskedAccountNumber: '',
  MaskedCardNumber: '',
  PaymentProfileId: '',
  State: '',
  Zip: '',
};

export const ONE_INC_EMPTY_CC = {
  Address: '',
  AddressFirstName: '',
  BillingInfoType: 1,
  City: '',
  LastName: '',
  MaskedAccountNumber: '',
  MaskedCardNumber: '',
  PaymentProfileId: '',
  State: '',
  Zip: '',
};

export const ONE_INC_PAYMENT_METHOD = {
  bankAccount: 2,
  creditCard: 1,
};

export const ADD_PET_MODAL_STEPS = {
  FIRST_STEP: 1,
  SECOND_STEP: 2,
  THIRD_STEP: 3,
};

export const PASSWORD_ERROR = {
  matchesPrevious: 9,
  none: -1,
};

export const US_KEYWORD_REGEX = /^[\x20-\x7E]*$/g;

export const INBOX_DOCS = {
  claimDocuments: '8',
  inbox: '1',
  landing: '0',
  medicalRecords: '5',
  pictures: '4',
  policyDocuments: '2',
  shotsCertificates: '6',
  trash: '7',
  vetBills: '3',
};

export const PROTECTED_FOLDERS = [
  INBOX_DOCS.policyDocuments,
  INBOX_DOCS.trash,
  INBOX_DOCS.claimDocuments,
];

export const ACTION_BUTTONS_CONTAINER_ID =
  'inbox-docs-action-buttons-container';

export const MY_ACCOUNT = {
  billingAndPaymentDetails: '8',
  billingAndPayments: '4',
  insuranceAccount: '5',
  landing: '0',
  paymentHistory: '2',
  paymentHistoryList: '3',
  petCloudAccount: '1',
  reimbursement: '6',
  reimbursementDetails: '7',
  update: '9',
};

export const MY_ACCOUNT_EDIT = {
  email: '3',
  loginEmail: '1',
  name: '0',
  password: '4',
  phone: '2',
};

export const PERMISSIONS_INBOX_DOCS = {
  addFiles: 'addfile',
  addFolder: 'addfolder',
  delete: 'delete',
  download: 'download',
  edittag: 'edittag',
  move: 'move',
  rename: 'rename',
  share: 'share',
  // recent file option (only available on web)
  viewinfolder: 'viewinfolder',
};

export const EDIT_FILE_PERMISSIONS = [
  PERMISSIONS_INBOX_DOCS.rename,
  PERMISSIONS_INBOX_DOCS.delete,
  PERMISSIONS_INBOX_DOCS.move,
  PERMISSIONS_INBOX_DOCS.edittag,
];

export const DEFAULT_FILE_PERMISSION = [PERMISSIONS_INBOX_DOCS.download];

/**
 * Enumerator used for identify the root or default foders from Inbox and Docs.
 */
export const DEFAULT_FOLDERS_INBOX_AND_DOCS = {
  1: 'Inbox',
  2: 'PolicyDocuments',
  3: 'VetBills',
  4: 'Pictures',
  5: 'MedicalRecords',
  6: 'ShotsCertificates',
  7: 'Trash',
  8: 'ClaimFolder',
};

export const INBOX_DOCS_FILE_CATEGORY = {
  file: 2,
  folder: 1,
};

export const INBOX_DOCS_PRIVATE_FOLDERS = [
  INBOX_DOCS.claimDocuments,
  INBOX_DOCS.policyDocuments,
];

export const ZERO_YEARS_OLD = 0;

export const MINIMUM_DAYS_REQUIRED_FOR_QUOTE = 49;

export const WELLNESS_MODIFIER_ID = 70428;

export const FOLDER_COLORS = {
  2: '#2c6085',
  3: '#eecc38',
  4: '#e56383',
  5: '#2fb3b4',
  6: '#ffa300',
  8: '#7c52bf',
  custom: '#eceef0',
};

export const READ_FILE_SOURCE = {
  claims: 1,
  inboxDocs: 2,
  toolbar: 3,
};

export const ACCEPTED_FILES = '.pdf,.jpg,.jpeg,.png,.doc,'
  + '.docx,.xls,.xlsx,.csv,.txt,.html';

export const CLAIM_STATUS = {
  CLOSED: 4,
  IN_REVIEW: 3,
  OPEN: 1,
  RECORDS: 2,
};

export const CLAIM_STATUS_ACTION = {
  CLOSED: 'closed',
  OPEN: 'open',
};

export const NAME_MAX_LENGTH = {
  file: 30,
  folder: 50,
};

export const FILTER_TYPES = {
  date: 1,
  name: 0,
  size: 2,
};

/**
 * Type of policy (only for Figo policies)
 */
export const PRODUCT_FAMILY = {
  FGO: 1,
  FPI: 3,
  GPI: 4,
  IHC: 2,
  None: 0,
};

export const EXPLORE_PROVIDERS = {
  google: 1,
  manuallyAdded: 3,
  pointInterest: 2,
};

export const IMAGE_UPLOAD_TYPE = {
  background: 1,
  customerProfile: 3,
  petProfile: 2,
  post: 0,
};

export const APP_STORE_LINK = 'https://apps.apple.com'
  + '/us/app/figo-pet-cloud/id1278520013?ls=1';

export const GOOGLE_PLAY_LINK = 'https://play.google.com'
  + '/store/apps/details?id=com.figoinsurance.petcloud';

/**
 * Identifier for companies
 * policies and claims use MARKETING_CHANNEL_ID
 * */
export const DESIGN_TOKEN_TYPE = {
  THEME_AKC: 3,
  THEME_FIGO: 1,
  THEME_PET_PARTNERS: 2,
  THEME_SPOT: 5,
};

export const NO_CLAIMS = 0;

export const DEPOSIT_REIMBURSEMENT = 2;
export const CHECK_REIMBURSEMENT = 1;

export const WEB_VIEW_TYPES = {
  billingAndPayment: 1,
  changeCoverage: 2,
  paymentMethod: 3,
  reimbursementMethod: 4,
  // eslint-disable-next-line sort-keys
  figoGetQuote: 5,
  // eslint-disable-next-line sort-keys
  addingNewPetToPolicy: 6,
  newQuoteForPet: 7,
};

export const POST_MESSAGE_TYPES = {
  CLOSE_PAGE: 'close-page',
  POLICY_UPDATED: 'policy-updated',
};

export const MAX_ZIP_LENGTH = 5;
export const MAX_PHONE_LENGTH_MASK = 14;

export const CLAIM_STATUS_BY_TEXT = {
  attentionNeeded: 'Attention Needed',
  closed: 'Closed',
  completed: 'Completed',
  done: 'Done!',
  inReview: 'In Review',
  received: 'Received',
  records: 'Records',
};

export const SPECIES_TYPE = {
  cat: 1,
  dog: 0,
};

export const GENDER_TYPE = {
  female: 1,
  male: 0,
};

export const PMS = {
  diamond: 'Diamond',
  orca: 'Orca',
};
