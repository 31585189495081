import AwesomeDebouncePromise from 'awesome-debounce-promise';
import useConstant from 'use-constant';

const useDebounceSearch = (searchFunction, timeout = 1000) => {
  const debounceSearch = () => (query) => {
    searchFunction(query);
  };

  const searchResults = useConstant((query) => AwesomeDebouncePromise(
    debounceSearch(query), timeout,
  ));

  return { searchResults };
};

export { useDebounceSearch };
