import React, { useState } from 'react';
import TouchableDiv from './TouchableDiv';
import { OverlayContainer } from './OverlayContainer';
import tripleDots from '../../assets/inboxDocs/triple-dots.svg';
import { TripleDotMenuOption } from './TripleDotMenuOption';

import './TripleDotMenu.css';

const TripleDotMenu = ({
  displayingFolderContent = false,
  itemId = 0,
  options = [],
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [displayUp, setDisplayUp] = useState(false);

  function handleOnClickOption(option) {
    setIsVisible(false);
    option.onClick(option);
  }

  function handleOnClick(event) {
    setIsVisible(!isVisible);
    event.stopPropagation();
  }

  return (
    <>
      <TouchableDiv
        className={`Dot-menu-button ${isVisible
          ? ' Dot-menu-button-active' : ''}`}
        id={`tripleDots${itemId}`}
        onClick={handleOnClick}
      >
        <img alt="" className="Dot-menu-dots-image" src={tripleDots} />
      </TouchableDiv>

      <div
        ref={(menuRef) => {
          if (isVisible && menuRef) {
            const bottomPosition = menuRef.getBoundingClientRect().bottom;

            if (window.innerHeight - bottomPosition < 0) {
              setDisplayUp(true);
            }
          }
        }}
        className={`Dot-menu-container 
        ${isVisible ? '' : 'Dot-menu-container-hidden'}
        ${displayUp ? 'Dot-menu-display-upwards' : ''}
        Dot-menu-container-redesign 
        ${displayingFolderContent
            && 'Dot-menu-folder-content'}`}
      >
        <OverlayContainer
          className="Dot-menu-overlay-container"
          id={`dotMenuOverlay${itemId}`}
          onClick={handleOnClick}
        >
          <ul>
            {options.map((option) => (
              <TripleDotMenuOption
                key={`${option.id}-${itemId}`}
                handleOnClickOption={handleOnClickOption}
                itemId={itemId}
                option={option}
              />
            ))}
          </ul>
        </OverlayContainer>
      </div>
    </>
  );
};

export { TripleDotMenu };
