import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import UninsuredPet from './UninsuredPet';
import { YoungPetModal } from './YoungPetModal';

import dogDefaultImage from '../../assets/billingPayments/dog-placeholder.png';
import catDefaultImage from '../../assets/billingPayments/cat-placeholder.png';
import { PET_TYPE_ID } from '../../constants';
import { resetValidationBreedMultibrand } from '../../actions';

const NoPolicyPets = ({
  currentPet,
  isYoungPetModalVisible = false,
  onGetQuote = () => { },
  onlyFigo = false,
}) => {
  const dispatch = useDispatch();
  useEffect(() => () => {
    dispatch(resetValidationBreedMultibrand());
  }, [dispatch]);

  const handleGetQuote = () => {
    onGetQuote({ petID: currentPet.PetId });
  };

  const placeholderImage = currentPet?.Type === PET_TYPE_ID.CAT
    ? catDefaultImage
    : dogDefaultImage;

  return (
    <>
      <UninsuredPet
        onGetQuote={handleGetQuote}
        petBreedName={currentPet?.Breed}
        petImage={currentPet?.ProfileUrl || placeholderImage}
        petName={currentPet?.PetName}
        showLearnMore={onlyFigo}
      />

      <YoungPetModal isVisible={isYoungPetModalVisible} />
    </>
  );
};

export { NoPolicyPets };
