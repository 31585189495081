import React from 'react';

import { useTranslation } from 'react-i18next';

import moment from 'moment';

import { CoveragePolicyStatus }
from '../../insurance/coverageOverview/CoveragePolicyStatus';
import { POLICY_STATUS, PAYMENT_PLANS_BY_NAME } from '../../../constants';

import figoLogo from '../../../assets/figo-logo.svg';

import './css/BillingDetailsCard.css';
import { useWebViewReskin } from '../../../hooks';

const BillingDetailsCard = ({ policyInformation }) => {
  const { t } = useTranslation(['myAccount', 'insuranceScreen']);
  const useMobileReskin = useWebViewReskin();

  if (!policyInformation) {
    return null;
  }

  const { Pets = [], BillingPayments = {} } = policyInformation;

  const detailItemClassName = useMobileReskin
    ? 'Billing-details-policy-detail-item'
    : 'Billing-details-policy-details-item';

  return (
    <div className="Billing-details-card-container">
      <div className="Billing-details-policy-status-container">
        <CoveragePolicyStatus
          label={t(`policyStatus-${POLICY_STATUS[policyInformation?.PolicyStatus
            ?.toLowerCase()]}`, { ns: 'insuranceScreen' })}
          status={POLICY_STATUS[policyInformation?.PolicyStatus?.toLowerCase()]}
        />
      </div>

      <div
        className={useMobileReskin
          ? 'Billing-details-top-container'
          : 'Billing-details-upper-container'}
      >
        <img alt="" className="Billing-details-upper-img" src={figoLogo} />

        <p className="Billing-details-price-text">
          ${BillingPayments?.Premium}
          <span>{BillingPayments
            ?.BillingCycle === PAYMENT_PLANS_BY_NAME.monthly
            ? t('billingAndPayments.month') : t('billingAndPayments.yr')}
          </span>
        </p>
      </div>

      <div className="Billing-details-policy-details">
        <div className={detailItemClassName}>
          <h6 className="Billing-details-policy-details-key">
            {t('billingAndPayments.policyNumber')}
          </h6>

          <p className="Billing-details-policy-details-value">
            {policyInformation?.PolicyNumber}
          </p>
        </div>

        <div className={detailItemClassName}>
          <h6 className="Billing-details-policy-details-key">
            {t('billingAndPayments.policyTerm')}
          </h6>

          <p className="Billing-details-policy-details-value">
            {moment(policyInformation?.PolicyStartDate?.split('T')[0])
              .format('M/DD/YYYY')}-{moment(policyInformation?.PolicyEndDate
              ?.split('T')[0]).format('M/DD/YYYY')}
          </p>
        </div>

        <div className={detailItemClassName}>
          <h6 className="Billing-details-policy-details-key">
            {t('billingAndPayments.billingCycle')}
          </h6>

          <p className="Billing-details-policy-details-value">
            {BillingPayments?.BillingCycle}
          </p>
        </div>

        <div className={detailItemClassName}>
          <h6 className="Billing-details-policy-details-key">
            {t('billingAndPayments.nextBillingDate')}
          </h6>
          <p className="Billing-details-policy-details-value">
            {moment(BillingPayments?.NextBillingDate?.split('T')[0])
              .format('M/DD/YYYY')}
          </p>
        </div>

        <div className={detailItemClassName}>
          <h6 className="Billing-details-policy-details-key">
            {t('billingAndPayments.petsOnThisPolicy')}
          </h6>

          <p className="Billing-details-policy-details-value">
            {Pets?.map(({ PetName }) => PetName).join(', ')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BillingDetailsCard;
