import React, { useCallback, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { ADD_ORCA_PET_IFRAME } from '../navigation/routes.constants';
import { PetCard } from './multiplePets/PetCard';

import plusIcon from '../../assets/add-circle-blue.svg';
import { getMultibrandPolicies } from '../../actions';

import './AddPetScreen.css';
import FigoLottie from '../common/FigoLottie';

const AddPetScreen = () => {
  const { t } = useTranslation('insuranceScreen');
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const store = useSelector(({ policies }) => (policies), shallowEqual);
  const { loading, multibrandPolicies } = store;

  useEffect(() => {
    if (!multibrandPolicies.length) {
      dispatch(getMultibrandPolicies());
    }
  }, []);

  const selectedPolicy = useMemo(() => {
    if (!location.state) {
      return null;
    }

    return location.state.selectedPolicy;
  }, [location.state]);

  const pets = useMemo(() => multibrandPolicies
    .filter((pet) => !pet.Policies.length), [multibrandPolicies]);

  const onAddANewPetPress = useCallback(() => {
    history.push(ADD_ORCA_PET_IFRAME, {
      selectedPolicy,
    });
  }, [history, selectedPolicy]);

  const handlePetSelection = useCallback((pet) => () => {
    history.push(ADD_ORCA_PET_IFRAME, {
      selectedPet: pet,
      selectedPolicy,
    });
  }, [history, selectedPolicy]);

  if (loading) {
    return (
      <div className="Add-pet-screen-loading-container">
        <FigoLottie height={80} width={80} />
      </div>
    );
  }

  return (
    <div className="Add-pet-screen-main-container">
      <div className="Add-pet-screen-title-page-container">
        <h1>{t('addOrcaPet.title')}</h1>

        <p>{t('addOrcaPet.description')}</p>
      </div>

      <div className="Add-pet-screen-pets-container">
        {pets.map((pet) => (
          <PetCard
            key={`${pet.PetId}`}
            age={pet.PetAge}
            breed={pet.Breed}
            gender={pet.PetGender}
            name={pet.PetName}
            onClick={handlePetSelection(pet)}
            picture={pet.ProfileUrl}
            type={pet.Type}
            weight={pet.Weight}
          />
        ))}

        <button
          className="Add-pet-screen-add-pet-card"
          onClick={onAddANewPetPress}
          type="button"
        >
          <img alt="" src={plusIcon} />
          {t('addOrcaPet.addPet')}
        </button>
      </div>
    </div>
  );
};

export { AddPetScreen };
