import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ModalContainer from '../../../common/ModalContainer';

import { ReimbursementMethodContainer } from './ReimbursementMethodContainer';
import { DirectDepositContent } from './DirectDepositContent';
import { CheckContent } from './CheckContent';

import closeIcon from '../../../../assets/close-button.svg';
import { DEPOSIT_REIMBURSEMENT } from '../../../../constants';

import '../css/ReimbursementEditModal.css';

const ReimbursementEditModal = ({
  isVisible = false,
  onCancel = () => { },
}) => {
  const { t } = useTranslation('myAccount');
  const store = useSelector(({ billingPayments, policies }) => ({
    billingPayments,
    policies,
  }), shallowEqual);
  const {
    billingPayments: {
      reimbursementData: { PreferredReimbursementMethod },
    },
  } = store;

  if (!isVisible) {
    return null;
  }

  return (
    <ModalContainer show>
      <div className="Reimbursement-edit-modal-container ">
        <div className="Reimbursement-edit-modal-title-container">
          <h3>{t('reimbursementSection.editModal.title')}</h3>

          <button
            className="Reimbursement-edit-modal-close-icon-container"
            id="closeIconEditReimbursementModal"
            onClick={onCancel}
            type="button"
          >
            <img
              alt="Close"
              src={closeIcon}
            />
          </button>
        </div>

        <p className="Reimbursement-edit-modal-description">
          {t('reimbursementSection.editModal.description')}
        </p>

        <ReimbursementMethodContainer
          PreferredReimbursementMethod={PreferredReimbursementMethod}
        />

        {PreferredReimbursementMethod === DEPOSIT_REIMBURSEMENT
          ? <DirectDepositContent onCancel={onCancel} />
          : <CheckContent onCancel={onCancel} />}
      </div>
    </ModalContainer>
  );
};

export { ReimbursementEditModal };
