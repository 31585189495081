import React from 'react';

import './ScheduleRowTitle.css';

const ScheduleRowTitle = ({ containerClass = '', title = '' }) => (
  <span className={`Contact-us-schedule-row-title ${containerClass}`}>
    {title}
  </span>
);

export { ScheduleRowTitle };
