import { createActions } from 'redux-actions';

const actions = createActions({
  STARTUP: () => ({}),
  STARTUP_FROM_CACHE: (data) => data,
});

export const {
  startup,
  startupFromCache,
} = actions;
