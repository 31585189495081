import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const useDisableSubmit = () => {
  const store = useSelector(({ policies }) => ({ ...policies.changeCoverage }));
  const {
    initialCoverageValues,
    quoteSelectedValues,
  } = store;

  const {
    deductibleId,
    reimbursementId,
    selectedPlan,
  } = quoteSelectedValues;

  const disableSubmit = useMemo(() => {
    let equalModifiers = true;
    const { modifiers } = initialCoverageValues;

    // Validate if there is no selected modifier
    if (!modifiers.length && quoteSelectedValues.modifiers.length) {
      equalModifiers = quoteSelectedValues
        .modifiers.every((item) => !item.isSelected);
    }

    // Get only the id from initial and current power ups
    const initialSelected = modifiers.filter((initial) => initial.isSelected)
      .map((initial) => initial.id).sort();
    const currentSelected = quoteSelectedValues.modifiers
      .filter((current) => current.isSelected)
      .map((current) => current.id).sort();

    // Validate there is a different item between both arrays
    const difference = currentSelected
      .filter((power) => initialSelected.indexOf(power) === -1)
      .concat(initialSelected
        .filter((power) => currentSelected.indexOf(power) === -1));
    equalModifiers = !difference.length;

    return initialCoverageValues.deductibleId === deductibleId
      && initialCoverageValues.reimbursementId === reimbursementId
      && initialCoverageValues.selectedPlan === selectedPlan
      && equalModifiers;
  }, [
    deductibleId,
    reimbursementId,
    selectedPlan,
    initialCoverageValues,
    quoteSelectedValues,
  ]);

  return disableSubmit;
};

export { useDisableSubmit };
