import React from 'react';

import './css/MyAccountScreen.css';

const MyAccountCardItem = ({
  description = '',
  icon = null,
  onClick = () => { },
  title = '',
}) => (
  <button
    className="My-account-card-item"
    onClick={onClick}
    type="button"
  >
    <div className="My-account-card-icon-background">
      <img alt="" className="Card-icon" src={icon} />
    </div>

    <div className="My-account-card-info">
      <h4>{title}</h4>

      <p>{description}</p>
    </div>
  </button>
);

export { MyAccountCardItem };
