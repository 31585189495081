import { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  contactsFormInitialized,
  editModeSet,
  familyMemberNameErrorMessageSetted,
  familyMemberNameSetted,
  familyMemberPhoneNumberErrorMessageSetted,
  familyMemberPhoneNumberSetted,
  figoTagModalHidden,
  loadPetProfile,
  messageToFinderSetted,
  petIdSetted,
  petTagInfoEditModeSet,
  petTagSetted,
  setPetTagInfo,
  tagNumberErrorMessageSetted,
} from '../../../actions';
import {
  removeEmojis,
  validateNumbersOnly,
  validatePhone,
} from '../../../services/utils';

const usePetTagModal = ({ petId }) => {
  const { t } = useTranslation('myPets');
  const dispatch = useDispatch();
  const store = useSelector(({ figoPetTag }) => ({ figoPetTag }), shallowEqual);
  const {
    data,
    emergencyContactsForm,
    isEditMode,
    isPetTagInfoRequestStarted,
    petTagInfo: { MessageToFinder, PetId, TagNumber },
    successPetTagPost,
    tagNumberErrorMessage,
  } = store.figoPetTag;
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  useEffect(() => {
    if (!PetId || (PetId !== petId)) {
      dispatch(petIdSetted(petId));
    }
  }, [PetId, dispatch, petId]);

  function hasObjectEmptyValues(object = {}) {
    return Object.values(object).filter((value) => !!value).length > 0;
  }

  useEffect(() => {
    if (Object.keys(data).length) {
      const isFamilyMember1Fill = hasObjectEmptyValues(
        data.EmergencyContacts[0] || {},
      );

      const isFamilyMember2Fill = hasObjectEmptyValues(
        data.EmergencyContacts[1] || {},
      );

      const isFamilyMember3Fill = hasObjectEmptyValues(
        data.EmergencyContacts[2] || {},
      );

      const editMode = data.TagNumber
        || isFamilyMember1Fill
        || isFamilyMember2Fill
        || isFamilyMember3Fill
        || data.MessageToFinder;

      dispatch(editModeSet(editMode));
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (Object.keys(data).length && isEditMode) {
      dispatch(petTagInfoEditModeSet({
        messageToFinder: data.MessageToFinder || '',
        tagNumber: data.TagNumber || '',
      }));
    }
  }, [data, dispatch, isEditMode]);

  useEffect(() => {
    if (data && Array.isArray(data?.EmergencyContacts)) {
      dispatch(contactsFormInitialized(data.EmergencyContacts));
    }
  }, [data]);

  function handlePetTagClose() {
    dispatch(loadPetProfile());
    dispatch(figoTagModalHidden());
  }

  function handlePetTagInput(event) {
    dispatch(petTagSetted(removeEmojis(event.target.value)));
    setSaveButtonDisabled(removeEmojis(event.target.value) === data.TagNumber);
  }

  function handleOnBlurPetTag() {
    dispatch(tagNumberErrorMessageSetted(TagNumber ? ''
      : t('PetTagModal.tagNumberError')));
  }

  function handleMessageToFinderInput(event) {
    dispatch(messageToFinderSetted(removeEmojis(event.target.value)));
    setSaveButtonDisabled(removeEmojis(event.target.value)
      === data.MessageToFinder);
  }

  const handleFamilyMemberNameInput = (contactKey) => (event) => {
    dispatch(familyMemberNameSetted({ contactKey, name: event.target.value }));

    setSaveButtonDisabled(event.target.value === emergencyContactsForm
      .find((contact) => contact.key === contactKey)?.name);
  };

  const handleFamilyMemberPhoneNumberInput = (contactKey) => (event) => {
    const { value } = event.target;
    const cleanText = value ? value.replace(/[()-\s]/g, '') : '';
    const onlyNumbersValue = validateNumbersOnly(cleanText)
      ? cleanText
      : cleanText.replace(/\D/g, '');
    const PHONE_MAX_LENGTH = 10;

    if (onlyNumbersValue.length > PHONE_MAX_LENGTH) {
      return;
    }

    dispatch(familyMemberPhoneNumberSetted({
      contactKey,
      phoneNumber: onlyNumbersValue,
    }));

    setSaveButtonDisabled(onlyNumbersValue === emergencyContactsForm
      .find((contact) => contact.key === contactKey)?.phoneNumber);

    dispatch(familyMemberPhoneNumberErrorMessageSetted({
      contactKey,
      errorMessage: '',
    }));
  };

  function findErrors(contact) {
    let nameErrorFound = false;
    let phoneErrorFound = false;
    const requiredError = t('PetTagModal.requiredError');
    const invalidPhoneError = t('PetTagModal.phoneNumberError');
    if (contact.isRequired) {
      if (!contact.name) {
        dispatch(familyMemberNameErrorMessageSetted({
          contactKey: contact.key,
          errorMessage: requiredError,
        }));
        nameErrorFound = true;
      }

      if (!contact.phoneNumber) {
        dispatch(familyMemberPhoneNumberErrorMessageSetted({
          contactKey: contact.key,
          errorMessage: requiredError,
        }));
        phoneErrorFound = true;
      }
    }

    if (!phoneErrorFound) {
      dispatch(familyMemberPhoneNumberErrorMessageSetted({
        contactKey: contact.key,
        errorMessage: '',
      }));
    }

    if (!nameErrorFound) {
      dispatch(familyMemberNameErrorMessageSetted({
        contactKey: contact.key,
        errorMessage: '',
      }));
    }

    if (nameErrorFound || phoneErrorFound) {
      return true;
    }

    if (!contact.name && contact.phoneNumber) {
      dispatch(familyMemberNameErrorMessageSetted({
        contactKey: contact.key,
        errorMessage: requiredError,
      }));
      nameErrorFound = true;
    }

    if (contact.phoneNumber && !validatePhone(contact.phoneNumber)) {
      dispatch(familyMemberPhoneNumberErrorMessageSetted({
        contactKey: contact.key,
        errorMessage: invalidPhoneError,
      }));
      phoneErrorFound = true;
    }

    if (contact.name && !contact.phoneNumber) {
      dispatch(familyMemberPhoneNumberErrorMessageSetted({
        contactKey: contact.key,
        errorMessage: requiredError,
      }));
      phoneErrorFound = true;
    }

    if (!phoneErrorFound) {
      dispatch(familyMemberPhoneNumberErrorMessageSetted({
        contactKey: contact.key,
        errorMessage: '',
      }));
    }

    if (!nameErrorFound) {
      dispatch(familyMemberNameErrorMessageSetted({
        contactKey: contact.key,
        errorMessage: '',
      }));
    }

    setSaveButtonDisabled(phoneErrorFound || nameErrorFound);

    return phoneErrorFound || nameErrorFound;
  }

  function handleSavePetTagButton() {
    const errors = emergencyContactsForm.some((contact) => findErrors(contact));

    if (errors) {
      return;
    }

    const figoTag = {
      EmergencyContacts: emergencyContactsForm.map((contact) => ({
        Id: contact.id,
        Name: contact.name,
        PhoneNumber: contact.phoneNumber,
      })),
      MessageToFinder,
      PetId,
      TagNumber,
    };

    dispatch(setPetTagInfo(figoTag));
  }

  const addButtonDisabled = useMemo(() => !TagNumber || tagNumberErrorMessage
    || !emergencyContactsForm[0].name || !emergencyContactsForm[0].phoneNumber
    || emergencyContactsForm[0].hasNameError
    || emergencyContactsForm[0].hasPhoneNumberError,
  [
    emergencyContactsForm,
    TagNumber,
    tagNumberErrorMessage,
  ]);

  const familyMemberInfo = useMemo(() => {
    const emptyMember = {
      name: '',
      nameErrorMessage: '',
      phoneNumber: '',
      phoneNumberErrorMessage: '',
    };

    const member1 = emergencyContactsForm
      .find((member) => member.key === 1) || emptyMember;
    const member2 = emergencyContactsForm
      .find((member) => member.key === 2) || emptyMember;
    const member3 = emergencyContactsForm
      .find((member) => member.key === 3) || emptyMember;

    return [member1, member2, member3];
  }, [emergencyContactsForm]);

  return {
    addButtonDisabled,
    emergencyContactsForm,
    familyMemberInfo,
    handleFamilyMemberNameInput,
    handleFamilyMemberPhoneNumberInput,
    handleMessageToFinderInput,
    handleOnBlurPetTag,
    handlePetTagClose,
    handlePetTagInput,
    handleSavePetTagButton,
    isEditMode,
    isPetTagInfoRequestStarted,
    MessageToFinder,
    saveButtonDisabled,
    successPetTagPost,
    TagNumber,
    tagNumberErrorMessage,
  };
};

export { usePetTagModal };
