import React from 'react';
import { BreadcrumbItem } from './BreadcrumbItem';
import navNextArrowBlue
from '../../../assets/inboxDocs/nav-next-arrow-blue.png';
import './Breadcrumbs.css';

const Breadcrumbs = ({
  containerClassName = '',
  customNavArrow = null,
  itemActiveClassName = '',
  itemClassName = '',
  labelField,
  navData = [],
  onNavItemClick = () => { },
}) => {
  const handleClickBreadcrumbItem = (newId) => () => {
    onNavItemClick(newId);
  };

  const items = navData.length > 1 && navData.map((item, index) => {
    const breadcrumb = (
      <BreadcrumbItem
        key={item.id}
        current={index === navData.length - 1}
        itemActiveClassName={itemActiveClassName}
        itemClassName={itemClassName}
        onNav={handleClickBreadcrumbItem(item)}
      >
        {item[labelField]}
      </BreadcrumbItem>
    );

    if (index < navData.length - 1) {
      return (
        <React.Fragment key={item.id}>
          {breadcrumb}

          <img
            alt=""
            src={customNavArrow || navNextArrowBlue}
          />
        </React.Fragment>
      );
    }
    return breadcrumb;
  });

  return (
    <div className={`Breadcrumbs ${containerClassName}`}>
      {items}
    </div>
  );
};

export { Breadcrumbs };
