import { useEffect, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useB2CInfo } from './useB2CInfo';

/**
 * Use this hook for do logout and then redirect to the current
 * location (query params are supported if exists) on public router.
 */
const useLogoutAndRedirection = () => {
  const store = useSelector(({ session }) => ({ session }), shallowEqual);
  const history = useHistory();
  const { logoutB2C } = useB2CInfo();

  const fullCurrentPath = useMemo(() => {
    if (history.location.search) {
      return `${history.location.pathname}${history.location.search}`;
    }

    return history.location.pathname;
  }, [history.location]);

  useEffect(() => {
    const hasSession = !!store.session.userInfo;
    if (hasSession) {
      logoutB2C();
      history.replace(fullCurrentPath);
    }
  }, []);
};

export { useLogoutAndRedirection };
