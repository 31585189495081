import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OverlayContainer } from '../../../common/OverlayContainer';

import { ReimbursementEditOption } from './ReimbursementEditOption';

import '../css/ReimbursementEditMenu.css';

const ReimbursementEditMenu = ({
  itemId = 0,
  options = [],
}) => {
  const { t } = useTranslation('myAccount');
  const [isVisible, setIsVisible] = useState(false);
  const [displayUp, setDisplayUp] = useState(false);

  function handleOnClickOption(option) {
    setIsVisible(false);
    option.onClick(option);
  }

  function handleOnClick(event) {
    setIsVisible((prevState) => !prevState);
    event.stopPropagation();
  }

  return (
    <>
      <button
        className="Reimbursement-edit-button"
        id={`editReimbursement${itemId}`}
        onClick={handleOnClick}
        type="button"
      >
        <span>{t('reimbursementSection.editOptions.title')}</span>
      </button>

      <div
        ref={(menuRef) => {
          if (isVisible && menuRef) {
            const bottomPosition = menuRef.getBoundingClientRect().bottom;

            if (window.innerHeight - bottomPosition < 0) {
              setDisplayUp(true);
            }
          }
        }}
        className={`Reimbursement-edit-menu-container 
        ${isVisible ? '' : 'Reimbursement-edit-menu-container-hidden'}
        ${displayUp ? 'Reimbursement-edit-menu-display-upwards' : ''}`}
      >
        <OverlayContainer
          className="Reimbursement-edit-menu-overlay-container"
          id={`editReimMenuOverlay${itemId}`}
          onClick={handleOnClick}
        >
          <ul>
            {options.map((option) => (
              <ReimbursementEditOption
                key={`${option.id}-${itemId}`}
                handleOnClickOption={handleOnClickOption}
                itemId={itemId}
                option={option}
              />
            ))}
          </ul>
        </OverlayContainer>
      </div>
    </>
  );
};

export { ReimbursementEditMenu };
