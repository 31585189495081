import { handleActions } from 'redux-actions';

const INITIAL_STATE = {
  additionalStepsHandled: true,
  agentInformation: null,
  attachments: [],
  claimSteps: null,
  claimStepsViewed: false,
  customerInformation: null,
  figoClaimValidations: {
    hasClaimFigo: false,
    loading: false,
    validated: false,
  },
  loadingAdditionalSteps: false,
  loadingPolicyByDate: false,
  loadingSteps: false,
  petsInfo: null,
  policyByDate: null,
  policyError: null,
  selectedPet: null,
  submitClaim: {
    answered: false,
    loading: false,
    success: false,
  },
  userResponses: { },
};

export default handleActions({
  CLAIM_ADDITIONAL_STEPS_HANDLED: (state) => ({
    ...state,
    additionalStepsHandled: true,
  }),
  CLAIM_ADDITIONAL_STEPS_LOADED: (state, action) => ({
    ...state,
    additionalStepsHandled: false,
    claimSteps: [
      ...state.claimSteps.filter((step) => step.InitialSteps),
      ...action.payload,
    ],
    loadingAdditionalSteps: false,
  }),
  CLAIM_GET_POLICY_BY_DATE: (state) => ({
    ...state,
    loadingPolicyByDate: true,
  }),
  CLAIM_POLICY_BY_DATE_FAILED: (state, action) => ({
    ...state,
    loadingPolicyByDate: false,
    policyByDate: null,
    policyError: action.payload,
  }),
  CLAIM_POLICY_BY_DATE_LOADED: (state, action) => ({
    ...state,
    loadingPolicyByDate: false,
    policyByDate: action.payload,
    policyError: null,
  }),
  CLAIM_STEPS_LOAD_FAILED: (state) => ({
    ...state,
    loadingSteps: false,
  }),
  CLAIM_STEPS_LOADED: (state, action) => ({
    ...state,
    claimSteps: action.payload,
    claimStepsViewed: false,
  }),
  CLAIM_STEPS_VIEWED: (state) => ({
    ...state,
    claimStepsViewed: true,
  }),
  CLAIMBOT_SELECTED_PET: (state, action) => ({
    ...state,
    selectedPet: action.payload,
  }),
  CLAIMBOT_SELECTED_VALUE: (state, action) => {
    const responsesUpdated = state.userResponses;
    responsesUpdated[action.payload.keyName] = {
      displayName: action.payload.displayName,
      displayValue: action.payload.displayValue,
      value: action.payload.value,
    };

    return {
      ...state,
      userResponses: responsesUpdated,
    };
  },
  CLEAR_CLAIM_STEPS: () => ({
    ...INITIAL_STATE,
  }),
  GENERAL_INFORMATION_LOADED: (state, action) => ({
    ...state,
    agentInformation: action.payload.AgentInfo,
    customerInformation: action.payload.CustomerInfo,
    loadingSteps: false,
    petsInfo: action.payload.PetsInfo,
  }),
  HAS_FIGO_CLAIMS_FAILED: (state) => ({
    ...state,
    figoClaimValidations: {
      ...state,
      hasClaimFigo: false,
      loading: false,
      validated: false,
    },
  }),
  HAS_FIGO_CLAIMS_LOADED: (state, action) => ({
    ...state,
    figoClaimValidations: {
      ...state,
      hasClaimFigo: action.payload,
      loading: false,
      validated: true,
    },
  }),
  LOAD_ADDITIONAL_CLAIMBOT_STEPS: (state) => ({
    ...state,
    loadingAdditionalSteps: true,
  }),
  LOAD_CLAIM_STEPS: (state) => ({
    ...state,
    loadingSteps: true,
  }),
  LOAD_HAS_FIGO_CLAIMS: (state) => ({
    ...state,
    ...state,
    figoClaimValidations: {
      ...state,
      hasClaimFigo: false,
      loading: true,
      validated: false,
    },
  }),
  SUBMIT_CLAIM: (state) => ({
    ...state,
    submitClaim: {
      ...state.submitClaim,
      answered: false,
      loading: true,
      success: false,
    },
  }),
  SUBMIT_CLAIM_FAILED: (state) => ({
    ...state,
    submitClaim: {
      ...state.submitClaim,
      answered: true,
      loading: false,
      success: false,
    },
  }),
  SUBMIT_CLAIM_SUCCESS: (state) => ({
    ...state,
    submitClaim: {
      ...state.submitClaim,
      answered: true,
      loading: false,
      success: true,
    },
  }),
}, INITIAL_STATE);
