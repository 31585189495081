import { createActions } from 'redux-actions';

const actions = createActions({
  CHANGE_NAME: (data) => data,
  CHANGE_NAME_FAILED: (data) => data,
  CHANGE_NAME_RESTARTED: () => { },
  CHANGE_NAME_SUCCESS: () => { },
  CREATE_FOLDER: (data) => data,
  CREATE_FOLDER_FAILED: (data) => data,
  CREATE_FOLDER_RESET: () => { },
  CREATE_FOLDER_SUCCESS: () => { },
  DELETE_FAILED: () => { },
  DELETE_FILES: (data) => data,
  DELETE_FOLDER: (data) => data,
  DELETE_RESTARTED: () => { },
  DELETE_SUCCESS: () => { },
  DOWNLOAD_FILES: (data) => data,
  ERROR_VIEW_FILE: () => { },
  FILE_PERMISSIONS_LOADED: (data) => data,
  FILE_TO_UPLOAD_REMOVED: (data) => data,
  FILE_TO_UPLOAD_UPDATED: (data) => data,
  FILES_DOWNLOADED: () => { },
  FILES_MARKED_AS_READ: (data) => data,
  FILES_MOVED: () => { },
  FILES_TO_UPLOAD_ADDED: (data) => data,
  FILES_UPLOADED: () => { },
  FOLDER_LOADED: (data) => data,
  FOLDER_MOVED: () => { },
  FOLDER_STRUCTURE_LOADED: (data) => data,
  GET_RECENT_FILES: (data) => data,
  LOAD_FOLDER_CONTENT: (data) => data,
  LOAD_FOLDER_FAILED: (data) => data,
  LOAD_FOLDER_STRUCTURE: () => { },
  MARK_FILES_AS_READ: (data) => data,
  MARK_FILES_AS_READ_ATTEMPTED: () => { },
  MOVE_FILES: (data) => data,
  MOVE_FILES_FAILED: () => { },
  MOVE_FILES_RESTARTED: () => { },
  MOVE_FOLDER: (data) => data,
  MOVE_FOLDER_RESTARTED: () => { },
  OPEN_MEDIA_FILE_TO_BLOB: (data) => data,
  RECENT_FILES_LOAD_FAILED: (data) => data,
  RECENT_FILES_LOADED: (data) => data,
  RECENT_FILES_REQUESTED: () => { },
  REFRESH_FOLDER_CONTENT: (data) => data,
  SHOW_VIEW_FILE: (data) => data,
  SHOWED_VIEW_FILE: (data) => data,
  TAG_PET_FILE: (data) => data,
  TAG_PET_FILE_FAILED: (data) => data,
  TAG_PET_FILE_RESTARTED: () => { },
  TAG_PET_FILE_SUCCESS: () => { },
  UPLOAD_FILES: (data) => data,
  UPLOAD_FILES_RESTARTED: () => { },
});

export const {
  changeName,
  changeNameFailed,
  changeNameRestarted,
  changeNameSuccess,
  createFolder,
  createFolderFailed,
  createFolderReset,
  createFolderSuccess,
  deleteFailed,
  deleteFiles,
  deleteFolder,
  deleteRestarted,
  deleteSuccess,
  downloadFiles,
  errorViewFile,
  filePermissionsLoaded,
  filesDownloaded,
  filesMarkedAsRead,
  filesMoved,
  filesToUploadAdded,
  filesUploaded,
  fileToUploadRemoved,
  fileToUploadUpdated,
  folderLoaded,
  folderMoved,
  folderStructureLoaded,
  getRecentFiles,
  loadFolderContent,
  loadFolderFailed,
  loadFolderStructure,
  markFilesAsRead,
  markFilesAsReadAttempted,
  moveFiles,
  moveFilesFailed,
  moveFilesRestarted,
  moveFolder,
  moveFolderRestarted,
  openMediaFileToBlob,
  recentFilesLoaded,
  recentFilesLoadFailed,
  recentFilesRequested,
  refreshFolderContent,
  showedViewFile,
  showViewFile,
  tagPetFile,
  tagPetFileFailed,
  tagPetFileRestarted,
  tagPetFileSuccess,
  uploadFiles,
  uploadFilesRestarted,
} = actions;
