import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Col,
  Row,
} from 'react-bootstrap';

const PageNotFound = () => {
  const { t } = useTranslation('pageNotFound');

  return (
    <Container>
      <Row>
        <Col>
          <p>{t('pageNotFound')}</p>
        </Col>
      </Row>
    </Container>
  );
};

export default PageNotFound;
