import React from 'react';

import './RadioInputWithLabel.css';

const RadioInputWithLabel = ({
  classContainer,
  disabled = false,
  id,
  isChecked,
  onClick,
  title,
  value,
}) => {
  function handleLocalClick() {
    if (onClick && !disabled) {
      onClick(value);
    }
  }

  return (
    <div className={`Radio-input-container ${classContainer}`}>
      <input
        checked={isChecked}
        className="Radio-input-with-label"
        id={id}
        name="radio"
        onChange={handleLocalClick}
        tabIndex={-1}
        type="radio"
      />

      <label
        aria-label={isChecked ? `${title} selected` : `${title} unselected`}
        className="Radio-input-label-container"
        htmlFor="radio"
        tabIndex={-1}
      >
        {title}
      </label>
    </div>
  );
};

export { RadioInputWithLabel };
