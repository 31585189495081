import {
  THEME_AKC,
  THEME_PET_PARTNERS,
  THEME_SPOT,
  MARKETING_CHANNEL_ID,
} from './theme.constants';
import { getCompany, getStoreThemeId } from './theme.store';

import figoLogoDark
from '../assets/brandLogos/dark-background/figo-logo.svg';
import figoLogoWhite from '../assets/figo-logo-white.svg';
import partnersLogoDark
from '../assets/brandLogos/dark-background/petpartners-logo.svg';
import akcDark from '../assets/brandLogos/dark-background/akc-logo.svg';
import spotDark from '../assets/brandLogos/dark-background/spot-logo.svg';
import akcLogo from '../assets/brandLogos/simplified/akc-simplified.svg';
import ppiLogo from '../assets/brandLogos/simplified/ppi-simplified.svg';
import ppiOnePack from '../assets/brandProviderLogos/ppi-one-pack-logo.svg';
import spotLogo from '../assets/brandLogos/simplified/spot-simplified.svg';
import akcWhiteLogo from '../assets/brandLogos/white/akc-logo-white.svg';
import ppiWhiteLogo from '../assets/brandLogos/white/ppi-logo-white.svg';
import ppiOPWhite from '../assets/brandLogos/white/ppi-onepack.svg';
import spotWhiteLogo from '../assets/brandLogos/white/spot-logo-white.svg';

export async function getTheme() {
  const defaultCompany = await getCompany();
  const defaultThemeId = defaultCompany.Id;

  return { company: defaultCompany, themeId: defaultThemeId };
}

export async function getThemeId() {
  const defaultTheme = await getStoreThemeId();

  return defaultTheme;
}

export function getThemeLogoById(themeId) {
  let themeLogo = {
    dark: figoLogoDark,
    simplified: null,
    white: figoLogoWhite,
  };

  switch (parseInt(themeId, 10)) {
    case MARKETING_CHANNEL_ID.akc:
      themeLogo = {
        dark: akcDark,
        simplified: akcLogo,
        white: akcWhiteLogo,
      };
      break;
    case MARKETING_CHANNEL_ID.ppi:
      themeLogo = {
        dark: partnersLogoDark,
        simplified: ppiLogo,
        white: ppiWhiteLogo,
      };
      break;
    case MARKETING_CHANNEL_ID.ppiOnePack:
      themeLogo = {
        dark: ppiOnePack,
        simplified: ppiLogo,
        white: ppiOPWhite,
      };
      break;
    case MARKETING_CHANNEL_ID.spot:
      themeLogo = {
        dark: spotDark,
        simplified: spotLogo,
        white: spotWhiteLogo,
      };
      break;
    default:
      themeLogo = {
        dark: figoLogoDark,
        simplified: null,
        white: figoLogoWhite,
      };
  }

  return themeLogo;
}

export async function getThemeLogo() {
  const themeFromStore = await getStoreThemeId();

  return getThemeLogoById(themeFromStore);
}

export function isOrcaCompany(companyId) {
  return [THEME_AKC, THEME_PET_PARTNERS, THEME_SPOT].includes(companyId);
}
