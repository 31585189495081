import React, { useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TouchableDiv from '../../common/TouchableDiv';
import FigoLottie from '../../common/FigoLottie';
import loadingSpinner from '../../../assets/loading-spinner.json';
import {
  addAttachmentsLoad,
  addAttachmentToClaim,
  fileClaimsAttachmentRemoved,
} from '../../../actions';

const SubmitAttachmentButton = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('claims');
  const store = useSelector(({
    claims,
    fileClaims,
  }) => ({
    attachments: claims.attachmentsByClaim,
    claims,
    fileClaims,
  }), shallowEqual);

  const {
    claims: {
      claimById: { ClaimId },
    },
    fileClaims: {
      attachments: fileClaimsAttachments,
      uploadAttLoading,
    },
  } = store;

  const attachmentWithNoError = useMemo(() => (fileClaimsAttachments
    .filter((attachment) => !attachment.errorMsg)), [fileClaimsAttachments]);

  const renderLoding = () => {
    if (uploadAttLoading) {
      return (
        <FigoLottie
          animationData={loadingSpinner}
          height={30}
          width={40}
        />
      );
    }

    return <span className="No-clicked"> {t('filesSumit')} </span>;
  };

  const filesSize = fileClaimsAttachments.length;
  const attachmentsSize = attachmentWithNoError.length;
  const classNameStyles = filesSize && attachmentsSize
    ? 'Input-btn Btn-submit Btn-submit-blue'
    : 'Input-btn Btn-submit-non-files';

  const disabledBtn = uploadAttLoading || !filesSize
      || !attachmentsSize ? 'Btn-submit-disabled' : '';

  function saveAttachments() {
    dispatch(addAttachmentsLoad({
      loading: true,
      success: false,
    }));

    fileClaimsAttachments
      .filter((attachment) => attachment.errorMsg)
      .forEach((attach) => dispatch(
        fileClaimsAttachmentRemoved(attach.key),
      ));

    dispatch(addAttachmentToClaim({
      ClaimId,
      files: attachmentWithNoError,
      t,
    }));
  }

  return (
    <TouchableDiv
      className={`${classNameStyles} ${disabledBtn}`}
      id="btnSubmit"
      onClick={saveAttachments}
    >
      {renderLoding()}
    </TouchableDiv>
  );
};

export { SubmitAttachmentButton };
