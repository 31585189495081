import React from 'react';

import checkBlue from '../../assets/checkbox-check-blue.svg';
import uncheckedIcon from '../../assets/checkbox-unchecked.svg';

import './Checkbox.css';

const Checkbox = ({
  checkboxLabelCustom = '',
  checked = false,
  className = '',
  id = '',
  label = '',
  onChange = () => { },
}) => {
  function toggle() {
    onChange(!checked);
  }

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label
      className={`Checkbox-common-container ${className}`}
      id={`checkbox${id}-label`}
    >
      <input
        checked={checked}
        id={id}
        name={label}
        onChange={toggle}
        type="checkbox"
      />

      <img alt="" src={checked ? checkBlue : uncheckedIcon} />

      <span className={checkboxLabelCustom}>{label}</span>
    </label>
  );
};

export default Checkbox;
