import React from 'react';

import '../css/PaymentHistoryDetailsRow.css';

const PaymentHistoryDetailsRow = ({
  amountValue = '',
  detailsValue = [],
  totalTitle = '',
}) => {
  function renderTotalRow() {
    return (
      <tr className="Payment-history-details-row-total">
        <td className="Payment-history-details-total-value">
          <span>{totalTitle}</span>

          <span>{amountValue}</span>
        </td>
      </tr>
    );
  }

  return (
    <>
      {detailsValue.map((detail, row) => (
        <tr key={String(row)} className="Payment-history-details-row">
          <td className="Payment-history-details-value">
            <span>{detail.Description}</span>

            <span>${detail.Amount.toFixed(2)}</span>
          </td>
        </tr>
      ))}

      {renderTotalRow()}
    </>
  );
};

export { PaymentHistoryDetailsRow };
