import { doNothing } from '../services/utils';

const OS = {
  android: 'Android',
  ios: 'iOS',
  unknown: 'unknown',
};

function getOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return OS.android;
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return OS.ios;
  }

  return OS.unknown;
}

const usePostMessage = () => (message) => {
  const os = getOperatingSystem();

  try {
    if (os === OS.ios) {
      window.webkit.messageHandlers.dismissWebView.postMessage(message);
    } else if (os === OS.android) {
      window.ReactNativeWebView.postMessage(message);
    } else {
      window.postMessage(message);
    }
  } catch (_) {
    doNothing();
  }
};

export { usePostMessage };
