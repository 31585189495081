import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import cloudAdd from '../../../../assets/fileClaim/cloud-add.svg';

import './DragZone.css';

const DragZone = ({ attachments = [], onAddFiles = () => { } }) => {
  const { t } = useTranslation('fileClaimbot');
  const [isDragging, setIsDragging] = useState(false);
  const dropzoneCoverRef = useRef();
  const dropzoneRef = useRef();

  const handleDragover = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleDragenter = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    const draggingFiles = event.dataTransfer.types.indexOf('Files') >= 0;

    if (draggingFiles) {
      setIsDragging(true);
    }
  }, []);

  const handleDragleave = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    const { fromElement, toElement } = event;
    const { current: dropzoneElement } = dropzoneRef;
    const { current: dropzoneCoverElement } = dropzoneCoverRef;
    if ((fromElement !== dropzoneElement
      || toElement !== dropzoneCoverElement)
      && (fromElement !== dropzoneCoverElement
        || toElement !== dropzoneElement)) {
      setIsDragging(false);
    }
  }, []);

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      setIsDragging(false);
      onAddFiles(event.dataTransfer.files);
      event.dataTransfer.clearData();
    }
  }, [attachments]);

  const handleDropOnCover = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  }, []);

  useEffect(() => {
    const currentCover = dropzoneCoverRef.current;
    if (currentCover) {
      currentCover.addEventListener('dragleave', handleDragleave);
      currentCover.addEventListener('drop', handleDropOnCover);
    }

    const currentDropzone = dropzoneRef.current;
    if (currentDropzone) {
      currentDropzone.addEventListener('drop', handleDrop);
    }

    return () => {
      if (currentCover) {
        currentCover.removeEventListener('dragleave', handleDragleave);
        currentCover.removeEventListener('drop', handleDropOnCover);
      }

      if (currentDropzone) {
        currentDropzone.removeEventListener('drop', handleDrop);
      }
    };
  }, [handleDrop, handleDragleave, dropzoneCoverRef, dropzoneRef, isDragging]);

  useEffect(() => {
    window.addEventListener('dragover', handleDragover);
    window.addEventListener('dragenter', handleDragenter);

    return () => {
      window.removeEventListener('dragover', handleDragover);
      window.removeEventListener('dragenter', handleDragenter);
    };
  }, [handleDragenter, handleDragover]);

  if (!isDragging) {
    return null;
  }

  return (
    <div ref={dropzoneCoverRef} className="Dragzone-overlayer">
      <div ref={dropzoneRef} className="Dragzone-container">
        <div className="No-clicked">
          <div className="Dragzone-icons-container">
            <img alt="doc" src={cloudAdd} />
          </div>

          <span className="Dragzone-title">
            {t('dropHere')}
          </span>

          <span className="Dragzone-subtitle">
            {t('dropHereSupportedFormats')}
          </span>
        </div>
      </div>
    </div>
  );
};

export { DragZone };
