import React from 'react';
import { useTranslation } from 'react-i18next';

import './css/CoverageFigoInfoFee.css';

const CoverageInfoFeeCancelled = ({
  cancellationDate = '',
  effectiveDate = '',
  payAmount = '',
  policyNumber = '',
}) => {
  const { t } = useTranslation('insuranceScreen');

  return (
    <>
      <div className="Coverage-overview-column">
        <div className="Flex Flex-column">
          <span className="Coverage-overview-info-title">
            {t('policyNumberTitle')}
          </span>

          <span className="Coverage-overview-info-value">
            {policyNumber}
          </span>
        </div>

        <div className="Flex Flex-column">
          <span className="Coverage-overview-info-title">
            {t('policyCancelled')}
          </span>

          <span className="Coverage-overview-info-value">
            {cancellationDate}
          </span>
        </div>
      </div>

      <div className="Coverage-overview-column">
        <div className="Flex Flex-column">
          <span className="Coverage-overview-info-title">
            {t('policyEffectiveDate')}
          </span>

          <span className="Coverage-overview-info-value">
            {effectiveDate}
          </span>
        </div>

        <div className="Flex Flex-column">
          <span className="Coverage-overview-info-title">
            {t('monthlyCost')}
          </span>

          <span className="Coverage-overview-info-value">
            {payAmount}
          </span>
        </div>
      </div>
    </>
  );
};

export { CoverageInfoFeeCancelled };
