import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { HtmlContent } from '../../common/HtmlContent';

import { getOrcaPowerUp } from '../../../actions';

import includedIcon from '../../../assets/policy/included-check.svg';
import notIncludedIcon from '../../../assets/policy/not-included-cross.svg';

import './css/PowerupInfoCard.css';

const PowerupInfoCard = ({
  contentfulKey = '',
  description = '',
  included = false,
  name = '',
  optionalBenefits = [],
  onClickLearnMore = () => { },
}) => {
  const { t } = useTranslation('insuranceScreen');
  const dispatch = useDispatch();

  function renderIncluded() {
    const icon = included ? includedIcon : notIncludedIcon;
    const text = included ? t('included') : t('notIncluded');

    return (
      <div className="Powerup-info-included-container">
        <img alt="" src={icon} />

        <span className={!included
          ? 'Powerup-info-not-included' : ''}
        >
          {text}
        </span>
      </div>
    );
  }

  function onClick() {
    const content = optionalBenefits;
    if (content && content.length) {
      onClickLearnMore(content[0].Id);
    } else {
      dispatch(getOrcaPowerUp({ productKey: contentfulKey }));
      onClickLearnMore();
    }
  }

  return (
    <div className="Powerup-info-card-container">
      <div className="Powerup-info-title-container">
        <h4>{name}</h4>

        {renderIncluded()}
      </div>

      <HtmlContent
        className="Powerup-info-description"
        content={description}
        elementType="p"
      />

      {optionalBenefits.length > 0 && included && (
        <button
          className="Powerup-info-learn-more"
          onClick={onClick}
          type="button"
        >
          <span>{t('learnMore')}</span>
        </button>
      )}
    </div>
  );
};

export { PowerupInfoCard };
