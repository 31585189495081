import { handleActions } from 'redux-actions';

const INITIAL_STATE = {
  downloadingFiles: false,
  error: '',
  errorOnAction: false,
  filePermissions: null,
  filesMovedSuccesfully: false,
  fileTaggedSuccesfully: false,
  folderContent: [],
  folderDeleteLoading: false,
  folderStructure: [],
  isRecentFilesLoading: false,
  itemDeletedSuccesfully: false,
  itemDeleteLoading: false,
  loading: false,
  markingFilesAsRead: false,
  moveFolder: {
    loading: false,
    success: false,
  },
  movingFiles: false,
  nameChangedSuccessfully: false,
  nameChangeError: '',
  nameChangeLoading: false,
  newFolder: {
    error: '',
    loading: false,
    success: false,
  },
  recentFiles: [],
  recentFilesError: '',
  tagPetLoading: false,
  uploadFiles: {
    filesAttached: [],
    uploadedSuccessfully: false,
    uploading: false,
  },
  viewFileData: null,
  viewFileLoading: false,
  viewFileVisible: false,
};

export default handleActions({
  CHANGE_NAME: (state) => ({
    ...state,
    nameChangeError: '',
    nameChangeLoading: true,
  }),
  CHANGE_NAME_FAILED: (state, { payload }) => ({
    ...state,
    nameChangeError: payload,
    nameChangeLoading: false,
  }),
  CHANGE_NAME_RESTARTED: (state) => ({
    ...state,
    nameChangedSuccessfully: false,
    nameChangeError: '',
    nameChangeLoading: false,
  }),
  CHANGE_NAME_SUCCESS: (state) => ({
    ...state,
    nameChangedSuccessfully: true,
    nameChangeError: '',
    nameChangeLoading: false,
  }),
  CREATE_FOLDER: (state) => ({
    ...state,
    newFolder: {
      error: '',
      loading: true,
      success: false,
    },
  }),
  CREATE_FOLDER_FAILED: (state, { payload }) => ({
    ...state,
    newFolder: {
      error: payload,
      loading: false,
    },
  }),
  CREATE_FOLDER_RESET: (state) => ({
    ...state,
    newFolder: {
      error: '',
      loading: false,
      success: false,
    },
  }),
  CREATE_FOLDER_SUCCESS: (state) => ({
    ...state,
    newFolder: {
      error: '',
      loading: false,
      success: true,
    },
  }),
  DELETE_FAILED: (state, { payload }) => ({
    ...state,
    errorOnAction: payload,
    folderDeleteLoading: false,
    itemDeletedSuccesfully: false,
    itemDeleteLoading: false,
  }),
  DELETE_FILES: (state) => ({
    ...state,
    itemDeleteLoading: true,
  }),
  DELETE_FOLDER: (state) => ({
    ...state,
    folderDeleteLoading: true,
  }),
  DELETE_RESTARTED: (state) => ({
    ...state,
    errorOnAction: '',
    folderDeleteLoading: false,
    itemDeletedSuccesfully: false,
    itemDeleteLoading: false,
  }),
  DELETE_SUCCESS: (state) => ({
    ...state,
    errorOnAction: '',
    folderDeleteLoading: false,
    itemDeletedSuccesfully: true,
    itemDeleteLoading: false,
  }),
  DOWNLOAD_FILES: (state) => ({
    ...state,
    downloadingFiles: true,
  }),
  ERROR_VIEW_FILE: (state) => ({
    ...state,
    viewFileData: null,
    viewFileLoading: false,
    viewFileVisible: false,
  }),
  FILE_PERMISSIONS_LOADED: (state, { payload }) => ({
    ...state,
    filePermissions: payload,
  }),
  FILE_TO_UPLOAD_REMOVED: (state, { payload }) => ({
    ...state,
    uploadFiles: {
      ...state.uploadFiles,
      filesAttached: state.uploadFiles.filesAttached
        .filter((file) => file.key !== payload),
    },
  }),
  FILE_TO_UPLOAD_UPDATED: (state, { payload }) => ({
    ...state,
    uploadFiles: {
      ...state.uploadFiles,
      filesAttached: state.uploadFiles.filesAttached
        .map((file) => (file.key !== payload.fileKey
          ? file
          : {
            ...file,
            ...payload.newValues,
          })),
    },
  }),
  FILES_DOWNLOADED: (state) => ({
    ...state,
    downloadingFiles: false,
  }),
  FILES_MARKED_AS_READ: (state, { payload }) => ({
    ...state,
    folderContent: state.folderContent.map((item) => (payload.includes(item.Id)
      ? {
        ...item,
        Metadata: { ...item.Metadata, IsRead: true },
      }
      : item)),
    markingFilesAsRead: false,
  }),
  FILES_MOVED: (state) => ({
    ...state,
    errorOnAction: false,
    filesMovedSuccesfully: true,
    movingFiles: false,
  }),
  FILES_TO_UPLOAD_ADDED: (state, { payload }) => ({
    ...state,
    uploadFiles: {
      ...state.uploadFiles,
      filesAttached: [...state.uploadFiles.filesAttached, payload],
    },
  }),
  FILES_UPLOADED: (state) => ({
    ...state,
    uploadFiles: {
      ...state.uploadFiles,
      uploadedSuccessfully: true,
      uploading: false,
    },
  }),
  FOLDER_LOADED: (state, { payload }) => ({
    ...state,
    folderContent: payload,
    loading: false,
  }),
  FOLDER_MOVED: (state) => ({
    ...state,
    moveFolder: {
      ...state.moveFolder,
      loading: false,
      success: true,
    },
  }),
  FOLDER_STRUCTURE_LOADED: (state, { payload }) => ({
    ...state,
    folderStructure: payload,
  }),
  LOAD_FOLDER_CONTENT: (state) => ({
    ...state,
    loading: true,
  }),
  LOAD_FOLDER_FAILED: (state, { payload }) => ({
    ...state,
    error: payload,
    folderContent: [],
    loading: false,
  }),
  LOAD_FOLDER_STRUCTURE: (state) => ({
    ...state,
    newFolder: {
      ...state.newFolder,
      error: '',
      loading: false,
      success: false,
    },
  }),
  MARK_FILES_AS_READ_ATTEMPTED: (state) => ({
    ...state,
    markingFilesAsRead: true,
  }),
  MOVE_FILES: (state) => ({
    ...state,
    errorOnAction: false,
    filesMovedSuccesfully: false,
    movingFiles: true,
  }),
  MOVE_FILES_FAILED: (state) => ({
    ...state,
    errorOnAction: true,
    filesMovedSuccesfully: false,
    movingFiles: false,
  }),
  MOVE_FILES_RESTARTED: (state) => ({
    ...state,
    filesMovedSuccesfully: false,
    movingFiles: false,
  }),
  MOVE_FOLDER: (state) => ({
    ...state,
    moveFolder: {
      ...state.moveFolder,
      loading: true,
      success: false,
    },
  }),
  MOVE_FOLDER_RESTARTED: (state) => ({
    ...state,
    moveFolder: {
      ...state.moveFolder,
      loading: false,
      success: false,
    },
  }),
  RECENT_FILES_LOAD_FAILED: (state, { payload }) => ({
    ...state,
    isRecentFilesLoading: false,
    recentFilesError: payload,
  }),
  RECENT_FILES_LOADED: (state, { payload }) => ({
    ...state,
    isRecentFilesLoading: false,
    recentFiles: payload,
  }),
  RECENT_FILES_REQUESTED: (state) => ({
    ...state,
    isRecentFilesLoading: true,
  }),
  REFRESH_FOLDER_CONTENT: (state) => ({
    ...state,
    loading: true,
  }),
  SHOW_VIEW_FILE: (state) => ({
    ...state,
    viewFileLoading: true,
    viewFileVisible: true,
  }),
  SHOWED_VIEW_FILE: (state, { payload }) => ({
    ...state,
    viewFileData: payload,
    viewFileLoading: false,
  }),
  TAG_PET_FILE: (state) => ({
    ...state,
    error: '',
    fileTaggedSuccesfully: false,
    tagPetLoading: true,
  }),
  TAG_PET_FILE_FAILED: (state, { payload }) => ({
    ...state,
    error: payload,
    fileTaggedSuccesfully: false,
    tagPetLoading: false,
  }),
  TAG_PET_FILE_RESTARTED: (state) => ({
    ...state,
    error: '',
    fileTaggedSuccesfully: false,
    tagPetLoading: false,
  }),
  TAG_PET_FILE_SUCCESS: (state) => ({
    ...state,
    error: '',
    fileTaggedSuccesfully: true,
    tagPetLoading: false,
  }),
  UPLOAD_FILES: (state) => ({
    ...state,
    uploadFiles: {
      ...state.uploadFiles,
      uploadedSuccessfully: false,
      uploading: true,
    },
  }),
  UPLOAD_FILES_RESTARTED: (state) => ({
    ...state,
    uploadFiles: {
      filesAttached: [],
      uploadedSuccessfully: false,
      uploading: false,
    },
  }),
}, INITIAL_STATE);
