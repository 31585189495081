import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';

import { PET_GENDER } from '../../constants';
import { MARKETING_CHANNEL_ID } from '../../theme/theme.constants';

import { MultiplePetsSelector } from './multiplePets/MultiplePetsSelector';
import {
  billingPaymentReseted,
  clearPolicyNotifications,
  getMultibrandPolicies,
  petSelected, policySelected,
  policySelectedOnClaims,
  setSelectedPetId,
  setSelectedPetIndex,
} from '../../actions';
import { INSURANCE } from '../navigation/routes.constants';
import { useMixpanel, usePMS } from '../../hooks';
import { EV1 } from '../../services/mixpanel';
import { getBrandNameByChannelId } from '../../services/utils';
import FigoLottie from '../common/FigoLottie';

import loadingSpinnerBlue
from '../../assets/lottieFiles/loading-blue.json';

import './css/PolicySummary.css';

const InsuranceScreen = () => {
  const { t } = useTranslation('insuranceScreen');
  const dispatch = useDispatch();
  const { sendEvent } = useMixpanel();
  const { pms } = usePMS();
  const store = useSelector(({
    billingPayments,
    claims,
    inboxAndDocs,
    personalInformation,
    policies,
    session,
  }) => ({
    billingPayments,
    claims,
    inboxAndDocs,
    personalInformation,
    policies,
    session,
  }), shallowEqual);

  const { loading, multibrandPolicies } = store.policies;
  const history = useHistory();
  useEffect(() => {
    dispatch(getMultibrandPolicies());
  }, []);

  function onResetPolicySelector() {
    dispatch(policySelected(0));
    dispatch(policySelectedOnClaims(0));
  }

  const handleMultiplePetsSelect = (petId) => () => {
    const multibrandPets = multibrandPolicies;
    const allPets = store.policies.allPets;
    const isFigo = multibrandPets.some((pet) => pet.PetId === petId
      && pet.Policies.some((policy) => policy
        .PolicyMarketingChannelId === MARKETING_CHANNEL_ID.figo));
    const arrayData = isFigo ? allPets : multibrandPets || [];
    const petIndex = arrayData?.findIndex((pet) => pet.PetId === petId);
    const policyBrand = isFigo
      ? getBrandNameByChannelId(MARKETING_CHANNEL_ID.figo)
      : arrayData[petIndex]?.Policies
        .map((item) => getBrandNameByChannelId(item.PolicyMarketingChannelId))
        .join('');
    dispatch(petSelected(petIndex));
    dispatch(setSelectedPetId(petId));
    dispatch(clearPolicyNotifications());
    dispatch(setSelectedPetIndex(petIndex));
    dispatch(billingPaymentReseted());
    onResetPolicySelector();
    sendEvent(EV1({ pms, policyBrand }));

    const selectedPetData = multibrandPolicies
      .find((item) => item.PetId === +petId) || {};

    if (selectedPetData.Policies?.length > 1) {
      history.push(`${INSURANCE}/${petId}/morePolicies`);
    } else {
      history.push(`${INSURANCE}/${petId}`);
    }
  };

  const multibrandPetList = useMemo(() => {
    if (!multibrandPolicies) {
      return [];
    }

    return multibrandPolicies
      .map((element) => ({
        petAge: element.PetAge,
        petBreed: element.Breed,
        petGender: element.PetGender === PET_GENDER.MALE
          ? t('male') : t('female'),
        petGenderId: element.PetGender,
        petId: element.PetId,
        petName: element.PetName,
        petProfileImage: element.ProfileUrl,
        petType: element.Type,
        petWeight: element.Weight,
        policies: element.Policies,
      }));
  }, [multibrandPolicies]);

  function renderContent() {
    if (!multibrandPetList.length || loading) {
      return (
        <div className="Policy-summary-shimmer-content">
          <FigoLottie
            animationData={loadingSpinnerBlue}
            height={50}
            width={50}
          />
        </div>
      );
    }

    const singlePet = multibrandPetList.length === 1;

    if (singlePet) {
      const [petAndPolicyInfo] = multibrandPetList;
      const singlePolicy = petAndPolicyInfo
        && petAndPolicyInfo.policies.length === 1;
      const { petId } = petAndPolicyInfo;

      // single policy (policy detail)
      if (singlePolicy) {
        return <Redirect to={`${INSURANCE}/${petId}`} />;
      }

      // multiple policy (policy selection)
      return <Redirect to={`${INSURANCE}/${petId}/morePolicies`} />;
    }

    return (
      <MultiplePetsSelector
        handlePetSelect={handleMultiplePetsSelect}
        pets={multibrandPetList}
        subTitle={t('multiplePets.subtitle')}
        title={t('title')}
      />
    );
  }

  return renderContent();
};

export { InsuranceScreen };
