import React from 'react';
import './InputFilesButton.css';

const InputFilesButton = ({
  acceptFiles,
  children,
  className = '',
  isDisabled = false,
  multiple = false,
  onAddFiles = () => { },
}) => {
  function handleAddFiles(event) {
    if (multiple) {
      onAddFiles(event.target.files);
    } else {
      onAddFiles(event.target.files[0]);
    }
  }

  return (
    <button
      className={`Input-files-button-container ${className}`}
      type="button"
    >
      {children}

      <input
        accept={acceptFiles}
        className="Input-files-button"
        disabled={isDisabled}
        multiple={multiple}
        onChange={handleAddFiles}
        type="file"
        value=""
      />
    </button>
  );
};

export { InputFilesButton };
