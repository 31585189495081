import React, { useState, useRef, useEffect } from 'react';

import arrowDownFilled from '../../../assets/chevron-fill.svg';

import ScrollableDiv from '../ScrollableDiv';
import Shimmer from '../Shimmer';
import TouchableDiv from '../TouchableDiv';
import { ListItem } from './ListItem';
import './Dropdown.css';

const DOWN_KEY = 40;
const ENTER_KEY = 13;
const ESC_KEY = 27;
const LEFT_KEY = 37;
const RIGHT_KEY = 39;
const SPACE_KEY = 32;
const UP_KEY = 38;

function handleKeyDown(event) {
  const ignoredKeys = [DOWN_KEY, LEFT_KEY, RIGHT_KEY,
    UP_KEY, SPACE_KEY];
  if (ignoredKeys.indexOf(event.keyCode) > -1) {
    event.preventDefault();
  }
}

const Dropdown = ({
  ariaProps = {},
  disabled = false,
  error = '',
  id = 'dropdown',
  label = '',
  labelClass = '',
  onSelectionChanged = () => { },
  options = [],
  placeholder = '',
  showShimmer = false,
  title = '',
  value,
}) => {
  const [open, setOpen] = useState(false);
  const [itemSelectedId, setItemSelectedId] = useState(0);
  const selectedItemRef = useRef(null);
  const dropdownHeaderRef = useRef(null);
  const containerItemRef = useRef(null);

  useEffect(() => {
    if (value) {
      setItemSelectedId(value.id);
    }
  }, [value]);

  function setActiveDescendant(currentRef) {
    if (currentRef && selectedItemRef.current) {
      const selectedItemId = selectedItemRef.current.getAttribute('id');
      currentRef.setAttribute('aria-activedescendant', selectedItemId);
    }
  }

  function scrollItemListToView() {
    if (selectedItemRef.current) {
      containerItemRef.current.scrollTo(
        0, selectedItemRef.current.offsetTop - 5,
      );
    }
  }

  useEffect(() => {
    if (open) {
      if (dropdownHeaderRef.current) {
        setActiveDescendant(dropdownHeaderRef.current);
      }

      if (selectedItemRef.current) {
        scrollItemListToView();
      }

      window.addEventListener('keydown', handleKeyDown, true);
    } else if (dropdownHeaderRef.current) {
      dropdownHeaderRef.current.focus();
    }

    return () => {
      if (open) {
        window.removeEventListener('keydown', handleKeyDown, true);
      }
    };
  }, [open]);

  useEffect(() => {
    scrollItemListToView();
    setActiveDescendant(dropdownHeaderRef.current);
  }, [itemSelectedId]);

  function toggle() {
    if (!disabled) {
      setOpen((prevOpen) => !prevOpen);
    }
  }

  const handleItemClick = (item) => (event) => {
    if (event.type === 'click') {
      toggle();
    }

    onSelectionChanged(item);
    setItemSelectedId(item.id);
  };

  function handleKeyPress(event) {
    if (disabled) {
      return;
    }
    const { keyCode } = event;
    switch (keyCode) {
      case UP_KEY:
      case DOWN_KEY: {
        const factor = keyCode === UP_KEY ? -1 : 1;
        const temp = options
          .findIndex((element) => element.id === itemSelectedId);
        if (options[temp + factor]) {
          const newId = options[temp + factor].id;
          setItemSelectedId(newId);
        }
        break;
      }

      case ESC_KEY: {
        setOpen(false);
        if (dropdownHeaderRef.current) {
          dropdownHeaderRef.current.focus();
        }
        break;
      }

      case ENTER_KEY: {
        const item = options.find((option) => option.id === itemSelectedId);

        if (item && open) {
          handleItemClick(item)(event);
        }
        break;
      }

      default:
        break;
    }
  }

  function handleOutsideClick() {
    setOpen(false);
  }

  return (
    <div
      className={'Dropdown-container'
        + `${error ? ' Dropdown-container-error' : ''}`}
    >
      <label className={`Dropdown-label ${labelClass}`} htmlFor={id}>
        {label}
      </label>

      <Shimmer
        ariaProps={{
          ...ariaProps,
          'aria-activedescendant': `${id}-options`,
          'aria-controls': `${id}-options`,
          'aria-expanded': open,
          'aria-label': `${title} selector`,
          title: `${value ? value.label : 'None'} selected`,
        }}
        className={'Dropdown-header'
          + `${disabled ? ' Dropdown-header-disabled' : ''}`}
        compRef={dropdownHeaderRef}
        element="touchableDiv"
        id={id}
        onClick={toggle}
        onKeyDown={handleKeyPress}
        tabIndex={0}
        visible={showShimmer}
      >
        <div className="Dropdown-selected-item No-clicked">
          <p
            className={!value?.label && placeholder
              ? 'Dropdown-placeholder' : ''}
          >
            {value?.label || placeholder}
          </p>
        </div>

        <div className="No-clicked" tabIndex={-1}>
          <img
            alt=""
            aria-hidden="true"
            src={arrowDownFilled}
          />
        </div>
      </Shimmer>

      <span aria-live="polite" className="Dropdown-error">{error}</span>

      {open && (
        <ScrollableDiv
          className="Dropdown-list"
          divRef={containerItemRef}
        >
          <TouchableDiv
            ariaProps={{ 'aria-hidden': true }}
            className="Dropdown-list-overlay-container"
            id={`${id}-overlay`}
            onClick={handleOutsideClick}
            tabIndex={0}
          />

          <ul aria-labelledby={id} id={`${id}-options`}>
            {options.map((item) => (
              <ListItem
                key={item.id}
                item={item}
                liRef={item.id === itemSelectedId ? selectedItemRef : null}
                onClick={handleItemClick(item)}
                onKeyDown={handleKeyPress}
                selected={item.id === itemSelectedId}
              />
            ))}
          </ul>
        </ScrollableDiv>
      )}
    </div>
  );
};

export { Dropdown };
