import React, { useEffect, useState } from 'react';
import { currencyFormat } from '../../../../services/utils';

import './AmountInput.css';

const AmountInput = ({
  number = '',
  onChange = () => { },
  placeholder = '',
  title = '',
  type = 'number',
  useCurrencyFormat = false,
}) => {
  const [displayValue, setDisplayValue] = useState('');

  useEffect(() => {
    if (number && useCurrencyFormat) {
      setDisplayValue(currencyFormat({ value: number }));
    }
  }, []);

  const onBlur = () => {
    if (useCurrencyFormat) {
      setDisplayValue(currencyFormat({ value: number }));
    }
  };

  const onFocus = () => {
    setDisplayValue('');
  };

  return (
    <div className="File-claimbot-amount-input-container">
      <h3>{title}</h3>

      <input
        inputMode="numeric"
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder}
        type={type}
        value={displayValue || number}
      />
    </div>
  );
};

export { AmountInput };
