import { createActions } from 'redux-actions';

const actions = createActions({
  ADD_ATTACHMENT_TO_CLAIM: (data) => data,
  CLAIM_POLICY_CHANGED: (data) => data,
  CLAIM_REQUESTED: () => { },
  CLAIMS_RESULTS_LOADED_ERROR: (error) => error.message,
  CLAIMS_RESULTS_LOADED_MORE: (data) => data,
  CLAIMS_RESULTS_LOADED_SUCCESS: (data) => (data),
  FAILED_TO_LOAD_CLAIM_STEPS: () => { },
  GET_CLAIM_ATTACHMENTS: (data) => data,
  GET_CLAIM_ATTACHMENTS_FAILED: (data) => data,
  GET_CLAIM_ATTACHMENTS_LOADED: (data) => data,
  GET_CLAIM_BY_ID: (data) => data,
  GET_CLAIM_BY_ID_FAILED: (data) => data,
  GET_CLAIM_BY_ID_LOADED: (data) => data,
  GET_CLAIM_BY_ID_REQUESTED: (data) => data,
  GET_CLAIMS: (data) => data,
  GET_CLAIMS_FAILED: (data) => data,
  GET_CLAIMS_LOADED: (data) => data,
  IS_FIRST_TIME_ON_CLAIMS: (data) => data,
  LOAD_NEW_CLAIM_STEPS: () => { },
  NEW_CLAIM_CANCELLED: () => { },
  NEW_CLAIM_STEPS_LOADED: (data) => data,
  POLICY_SELECTED_ON_CLAIMS: (data) => data,
  REQUEST_CLAIM_BY_ID: (data) => data,
  REQUEST_CLAIMS_RESULTS: (data) => data,
  RESET_CHECKED_OPTION: () => { },
  RESTART_CLAIMS_DETAILS: () => {},
  SAVE_SELECTED_PET: (data) => data,
  SET_IS_CHECKED_OPTION_CLOSED: (data) => data,
  SET_IS_CHECKED_OPTION_OPEN: (data) => data,
  SET_SELECTED_PET_ID: (data) => data,
  SET_SELECTED_PET_INDEX: (data) => data,
});

export const {
  addAttachmentToClaim,
  claimPolicyChanged,
  claimRequested,
  claimsResultsLoadedError,
  claimsResultsLoadedMore,
  claimsResultsLoadedSuccess,
  failedToLoadClaimSteps,
  getClaimAttachments,
  getClaimAttachmentsFailed,
  getClaimAttachmentsLoaded,
  getClaimById,
  getClaimByIdFailed,
  getClaimByIdLoaded,
  getClaimByIdRequested,
  getClaims,
  getClaimsFailed,
  getClaimsLoaded,
  isFirstTimeOnClaims,
  loadNewClaimSteps,
  newClaimCancelled,
  newClaimStepsLoaded,
  policySelectedOnClaims,
  requestClaimById,
  requestClaimsResults,
  resetCheckedOption,
  restartClaimsDetails,
  saveSelectedPet,
  setIsCheckedOptionClosed,
  setIsCheckedOptionOpen,
  setSelectedPetId,
  setSelectedPetIndex,
} = actions;
