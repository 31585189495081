import React from 'react';
import { useTranslation } from 'react-i18next';
import { CONTACT_FIGO_URL } from '../../constants';
import crazyPatternIcon from '../../assets/found-lost-pet/pattern-bar.png';

import './FooterFoundLostPet.css';

const FooterFoundLostPet = () => {
  const { t } = useTranslation('foundLostPet');
  const currentYear = new Date().getFullYear();

  return (
    <>
      <img
        alt="crazy pattern"
        className="Found-lost-pet-crazy-pattern-background"
        src={crazyPatternIcon}
      />

      <div className="Footer-found-lost-pet-container">
        <div className="Footer-found-lost-pet-have-question-container">
          <span className="Footer-found-lost-pet-text">
            {t('haveQuestions')}&nbsp;
          </span>

          <a
            className="Footer-found-lost-pet-contact-figo"
            href={CONTACT_FIGO_URL}
            target="blank"
          >
            {t('contactFigo')}
          </a>
        </div>

        <div className="Footer-found-lost-pet-copyright-container">
          <span className="Footer-found-lost-pet-copyright-text">
            {t('copyright', { currentYear })}
          </span>
        </div>
      </div>
    </>
  );
};

export { FooterFoundLostPet };
