import React from 'react';
import { useTranslation } from 'react-i18next';
import figoLogo from
'../../assets/found-lost-pet/figo-logo-light-background.png';
import './HeaderCommon.css';

const HeaderCommon = ({
  hideSignIn = false,
}) => {
  const { t } = useTranslation('foundLostPet');

  return (
    <div className="Figo-common-header-container">
      <img
        alt="figoLogo"
        className="Figo-common-header-logo"
        src={figoLogo}
      />

      {!hideSignIn && (
        <div className="Figo-common-header-text-container">
          <span className="Figo-common-header-title">
            {t('haveAFigoAccount')}
          </span>

          <a className="Figo-common-header-sign-in" href="/">
            {t('signIn')}
          </a>
        </div>
      )}
    </div>
  );
};

export default HeaderCommon;
