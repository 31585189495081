import React from 'react';
import { POLICY_STATUS } from '../../../constants';

import activeIcon from '../../../assets/policy/active-green.svg';
import cancelledIcon from '../../../assets/policy/cancelled-red.svg';
import futureIcon from '../../../assets/policy/future-brown.svg';
import pastIcon from '../../../assets/policy/past-black.svg';

import './css/CoveragePolicyStatus.css';

const CoveragePolicyStatus = ({ label, status }) => {
  let statusIcon = null;
  let statusClass = '';

  switch (status) {
    case POLICY_STATUS.active:
      statusIcon = activeIcon;
      statusClass = 'active';
      break;

    case POLICY_STATUS.cancelled:
      statusIcon = cancelledIcon;
      statusClass = 'cancelled';
      break;

    case POLICY_STATUS.future:
      statusIcon = futureIcon;
      statusClass = 'future';
      break;

    case POLICY_STATUS.unPolicy:
    case POLICY_STATUS.expired:
    case POLICY_STATUS.past:
      statusIcon = pastIcon;
      statusClass = 'unPolicy';
      break;

    default:
      statusIcon = pastIcon;
      statusClass = 'unPolicy';
      break;
  }

  return (
    <div className={`Coverage-policy-status-${statusClass}`}>
      <img alt="" className="Coverage-policy-status-icon" src={statusIcon} />

      <p>{label}</p>
    </div>
  );
};

export { CoveragePolicyStatus };
