import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalContainer from '../../common/ModalContainer';
import TouchableDiv from '../../common/TouchableDiv';

import './ChangeCoverageErrorModal.css';

const ChangeCoverageErrorModal = ({
  showModal = false,
  errorMessage = '',
  handleCloseModal = () => { },
}) => {
  const { t } = useTranslation('changeCoverage');
  return (
    <ModalContainer show={showModal}>
      <div className="CCEM-container">
        <h1 className="CCEM-title">{t('error')}</h1>

        <p className="CCEM-message">{errorMessage}</p>

        <TouchableDiv
          className="CCEM-close-container"
          id="closeChangeCoverageErrorModal"
          onClick={handleCloseModal}
        >
          <span className="CCEM-close-text">{t('close')}</span>
        </TouchableDiv>
      </div>
    </ModalContainer>
  );
};

export { ChangeCoverageErrorModal };
