import React from 'react';
import selectedIcon from '../../../assets/myPets/selected.svg';
import './FoundVetItem.css';

const FoundVetItem = ({
  added = false,
  addedLabel = '',
  address = '',
  distance = 0,
  name = '',
  rightText = '',
  showDistance = true,
}) => (
  <>
    <div className={'Found-vet-item-data'
      + `${rightText ? ' Found-vet-item-data-margin' : ''}`}
    >
      <span className="Found-vet-item-name">{name}</span>

      <span className="Found-vet-item-address">{address}</span>

      {showDistance && (
        <span className="Found-vet-item-distance">
          {distance.toFixed(2)}&nbsp;
        </span>
      )}
    </div>

    {added ? (
      <div className="Found-vet-item-added">
        <img alt="" src={selectedIcon} />

        <span>
          {addedLabel}
        </span>
      </div>
    ) : (
      <span className="Found-vet-item-extra-text">
        {rightText}
      </span>
    )}
  </>
);

export { FoundVetItem };
