import React from 'react';

import { PolicyListItem } from '../../../common/PolicyListItem';
import './PolicySelector.css';

const PolicySelector = ({
  onPolicyItemPress = () => { },
  policies = [],
  selectedPolicy = null,
}) => (
  <ul className="Policy-selector-container">
    {policies.map((policy) => (
      <PolicyListItem
        key={policy.PolicyNumber}
        endsOn={policy.ExpirationDate}
        marketingChannel={policy.PolicyMarketingChannelId}
        onClick={onPolicyItemPress(policy)}
        policyNumber={policy.PolicyNumber}
        selected={selectedPolicy?.PolicyNumber === policy.PolicyNumber}
        startsOn={policy.EffectiveDate}
        status={policy.PolicyStatus}
      />
    ))}
  </ul>
);

export { PolicySelector };
