import { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';

const useAlphabeticalSortedPetsWithPolicy = () => {
  const store = useSelector(({ policies }) => ({ policies }), shallowEqual);
  const alphabeticalSortedPetsWithPolicy = useMemo(() => store.policies.pets
    .sort((previousPet, nextPet) => {
      if (previousPet.Name > nextPet.Name) {
        return 1;
      }

      if (nextPet.Name > previousPet.Name) {
        return -1;
      }

      return 0;
    }), [store.policies.pets]);
  return alphabeticalSortedPetsWithPolicy;
};

export { useAlphabeticalSortedPetsWithPolicy };
