import React from 'react';
import { useTranslation } from 'react-i18next';

import './TextInputSingleLine.css';

const TextInputSingleLine = ({
  onTextInputChanged = () => { },
  textInputValue = '',
}) => {
  const { t } = useTranslation('fileClaimbot');

  const onChange = ({ target }) => {
    const CLAIM_DETAIL_REGEX = /[:-@[-`]+/;
    const formattedText = target.value.replace(CLAIM_DETAIL_REGEX, '');
    onTextInputChanged(formattedText);
  };

  return (
    <div className="File-claimbot-text-input-single-line-container">
      <h3>{t('textInputSingleLine.subTitle')}</h3>

      <input
        onChange={onChange}
        placeholder={t('textInputSingleLine.placeholder')}
        value={textInputValue}
      />
    </div>
  );
};

export { TextInputSingleLine };
