import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { shallowEqual, useSelector } from 'react-redux';
import { generateFileTreeOptions } from '../../../../services/utils';
import { INBOX_DOCS } from '../../../../constants';

import { Dropdown } from '../../../common/dropdown/Dropdown';
import './FolderSelector.css';

const FolderSelector = ({
  disabled = false,
  onFolderSelected = () => { },
  selectedFolder = {},
}) => {
  const { folderStructure } =
    useSelector(({ inboxAndDocs }) => inboxAndDocs, shallowEqual);
  const { t } = useTranslation('inboxAndDocs');

  const optionsGenerated = useMemo(
    () => generateFileTreeOptions({
      folders: folderStructure,
      hiddenFolderId: INBOX_DOCS.trash,
      level: 0,
    }),
    [folderStructure],
  );

  const handleFolderSelected = useCallback((item) => {
    onFolderSelected(item);
  }, []);

  return (
    <div className="Folder-selector-container">
      <h3
        className="Upload-files-title"
        id="folder-selector-label"
      >
        {t('uploadModal.uploadTo')}
      </h3>

      <Dropdown
        ariaProps={{
          'aria-labelledby': 'folder-selector-label',
        }}
        disabled={disabled}
        onSelectionChanged={handleFolderSelected}
        options={optionsGenerated}
        placeholder={t('uploadModal.selectFolder')}
        value={selectedFolder}
      />
    </div>
  );
};

export { FolderSelector };
