import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { getBrandNameByChannelId } from '../services/utils';

const usePolicyBrand = ({ policyNumber = '' }) => {
  const store = useSelector(({ policies }) => ({
    ...policies.changeCoverage,
    allPolicies: policies.allPolicies,
  }), shallowEqual);
  const {
    allPolicies,
  } = store;

  const policyBrandName = useMemo(() => {
    const foundPolicy = allPolicies
      ?.find((policyItem) => policyItem.Policy.Number === policyNumber);
    const productFamilyId = foundPolicy?.Policy.ProductFamilyId ?? '';
    return getBrandNameByChannelId(productFamilyId);
  }, [allPolicies, policyNumber]);

  return { policyBrandName };
};

export { usePolicyBrand };
