import React from 'react';
import TouchableDiv from './TouchableDiv';
import './OverlayContainer.css';

const OverlayContainer = ({
  isVisible = true,
  onClick,
  id,
  children,
  className = '',
}) => (
  <>
    {isVisible && (
      <TouchableDiv
        className={`My-account-menu-overlay ${className}`}
        id={id}
        onClick={onClick}
      />
    )}
    {children}
  </>
);

export { OverlayContainer };
