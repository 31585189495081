import React from 'react';
import { useTranslation } from 'react-i18next';

import './TextInput.css';

const TextInput = ({
  onTextInputChanged = () => { },
  textInputValue = '',
}) => {
  const { t } = useTranslation('fileClaimbot');

  const onChange = ({ target }) => {
    const CLAIM_DETAIL_REGEX = /[:-@[-`]+/;
    const formattedText = target.value.replace(CLAIM_DETAIL_REGEX, '');
    onTextInputChanged(formattedText);
  };

  return (
    <div className="File-claimbot-text-input-container">
      <h3>{t('textInput.subTitle')}</h3>

      <textarea
        onChange={onChange}
        placeholder={t('textInput.placeholder')}
        value={textInputValue}
      />
    </div>
  );
};

export { TextInput };
