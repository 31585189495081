import React from 'react';

import arrowCheckWhite from '../../../../assets/claimbot/arrow-check-white.svg';

import './TwoOptionsSelector.css';

const TwoOptionsSelector = ({
  onOneOptionSelected = () => { },
  optionSelected = null,
  twoOptions = {},
}) => {
  const { leftOption, rightOption } = twoOptions;

  const onPress = ({ option, validStep = false }) => () => {
    onOneOptionSelected({ option, validStep });
  };

  const ArrowImage = () => <img alt="" src={arrowCheckWhite} />;

  const leftSelected = leftOption.id === optionSelected?.id
    && leftOption.label === optionSelected?.label;
  const rightSelected = rightOption.id === optionSelected?.id
    && rightOption.label === optionSelected?.label;

  return (
    <div className="File-claimbot-two-options-selector-container">
      <button
        className={`File-claimbot-two-options-left ${leftSelected
          ? 'File-claimbot-two-options-option-selected' : ''}`}
        onClick={onPress({ option: leftOption, validStep: false })}
        type="button"
      >
        {leftSelected ? <ArrowImage /> : null}

        {leftOption.label}
      </button>

      <button
        className={`File-claimbot-two-options-right ${rightSelected
          ? 'File-claimbot-two-options-option-selected' : ''
        }`}
        onClick={onPress({ option: rightOption, validStep: true })}
        type="button"
      >
        {rightSelected ? <ArrowImage /> : null}

        {rightOption.label}
      </button>
    </div>
  );
};

export { TwoOptionsSelector };
