import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { isMobile } from '../../services/utils';
import {
  CHANGE_COVERAGE,
  CHANGE_COVERAGE_SUBMIT_SUCCESS,
  CHANGE_COVERAGE_WEB,
} from '../navigation/routes.constants';
import {
  clearPolicyNotifications,
  getChangeCoveragePreview,
  getPrivateToken,
  petSelected,
  policySelected,
  restartRate,
  setSelectedPetId,
  setSelectedPetIndex,
} from '../../actions';
import { MobileHeader } from '../navigation/MobileHeader';
import {
  useAlphabeticalSortedPetsWithPolicy,
  usePostMessage,
  useQuery,
} from '../../hooks';
import { usePetPolicies } from '../../hooks/usePetPolicies';
import { SubmitSuccess } from './changeCoverageSubmitted/SubmitSuccess';
import {
  ChangeCoverageScreenPreview,
  ChangeCoverageScreenOptions,
} from '../insurance/changeCoverage';
import './ChangeCoverageScreen.css';

const ChangeCoverageScreen = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();
  const postMessage = usePostMessage();
  const publicTokenParam = query.get('publicToken');
  const [policyNumber, setPolicyNumber] = useState(match.params.policyNumber
    || '');
  const [publicToken, setPublicToken] = useState(publicTokenParam || '');
  const store = useSelector(({
    policies,
    session,
  }) => ({ policies, session }), shallowEqual);
  const {
    policies: {
      allPets,
      changeCoverage: { success },
      loading: policiesLoading,
    },
  } = store;
  const sortedPetsWithPolicy =
    useAlphabeticalSortedPetsWithPolicy();
  const { hasPets } = usePetPolicies();

  function handlePolicySelect(index) {
    const { policies } = store;

    dispatch(clearPolicyNotifications());

    if (!policies.allPolicies.length) {
      return;
    }

    dispatch(policySelected(index));
  }

  function handlePetSelect(index) {
    const petId = allPets[index].PetId;
    const indexBillingPayment = sortedPetsWithPolicy
      .findIndex((pet) => pet.PetId === petId);
    const indexNotFound = -1;
    const firstIndex = 0;
    dispatch(petSelected(indexBillingPayment === indexNotFound
      ? firstIndex
      : indexBillingPayment));
    dispatch(setSelectedPetId(petId));
    dispatch(clearPolicyNotifications());
    dispatch(setSelectedPetIndex(index));
  }

  useEffect(() => {
    if (policiesLoading) {
      return;
    }

    if (hasPets && store.session.privateToken) {
      const petIndexToSelect = sortedPetsWithPolicy
        .findIndex((pet) => pet.policies
          .find((policy, index) => {
            const policyFound = policy.Number === policyNumber;
            if (policyFound) {
              handlePolicySelect(index);
            }
            return policyFound;
          }));

      if (petIndexToSelect >= 0) {
        handlePetSelect(petIndexToSelect);
      }
    }
  }, [
    dispatch,
    hasPets,
    policyNumber,
    sortedPetsWithPolicy,
    store.session.privateToken,
  ]);

  useEffect(() => () => {
    dispatch(restartRate());
  }, []);

  useEffect(() => {
    setPolicyNumber(match.params.policyNumber || '');
    if (!publicToken) {
      setPublicToken(publicTokenParam);
    }
  }, [match.params, publicTokenParam]);

  useEffect(() => {
    if (!isMobile()) {
      history.replace(`${CHANGE_COVERAGE_WEB}/${policyNumber}`);
    } else if (!publicToken) {
      history.replace('/');
    }
  }, [publicToken]);

  useEffect(() => {
    if (!store.session.privateToken && publicToken) {
      dispatch(getPrivateToken({ publicToken }));
    }
  }, [dispatch, publicToken, store.session.privateToken]);

  useEffect(() => {
    if (store.session.privateToken) {
      dispatch(getChangeCoveragePreview({ policyNumber }));
    }
  }, [dispatch, policyNumber, store.session.privateToken]);

  useEffect(() => {
    if (success) {
      history.push(CHANGE_COVERAGE_SUBMIT_SUCCESS);
    }
  }, [history, success]);

  function handleChangeCoverageButton() {
    history.push(`${match.url}/options`);
  }

  function handleKeepCoverageButton() {
    postMessage('close-page');
  }

  function handleRateError() {
    history.goBack();
  }

  function shouldPromptWarning() {
    return false;
  }

  function renderContent() {
    if (!store.session.privateToken) {
      return null;
    }

    return (
      <>
        <Switch>
          <Route path={`${match.path}/options`}>
            <ChangeCoverageScreenOptions
              onRateCreationError={handleRateError}
              policyNumber={policyNumber}
              validatePromptWarning={shouldPromptWarning}
            />
          </Route>

          <Route path={CHANGE_COVERAGE_SUBMIT_SUCCESS}>
            <SubmitSuccess onClose={handleKeepCoverageButton} />
          </Route>

          <Route path={CHANGE_COVERAGE}>
            <ChangeCoverageScreenPreview
              onChangeCoverageClick={handleChangeCoverageButton}
              onKeepClick={handleKeepCoverageButton}
            />
          </Route>

        </Switch>
      </>
    );
  }

  return (
    <div className="Change-coverage-main-container">
      <MobileHeader />

      {renderContent()}
    </div>
  );
};

export { ChangeCoverageScreen };
