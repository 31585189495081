import { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
  changeProfilePicture,
  changeProfilePictureRestart,
  displayUnsavedModal,
  myAccountHasChanges,
  refreshPersonalInformation,
  removeAvatarPicture,
} from '../../../actions';

import { MY_ACCOUNT_EDIT } from '../../../constants';

const useMyAccountInfo = () => {
  const dispatch = useDispatch();
  const store = useSelector(({
    myAccount,
    personalInformation,
  }) => ({ myAccount, personalInformation }), shallowEqual);
  const { myAccount: {
    pageHasChanges,
    showUnsavedModal,
  }, personalInformation: {
    userInfo,
    pictureEditing,
  } } = store;

  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [isEditingPhone, setIsEditingPhone] = useState(false);
  const [isEditingPCEmail, setIsEditingPCEmail] = useState(false);
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [isCurrentHidden, setIsCurrentHidden] = useState(true);
  const [isNewHidden, setIsNewHidden] = useState(true);
  const [isConfirmHidden, setIsConfirmHidden] = useState(true);
  const [activeEdition, setActiveEdition] = useState('');
  const [showImageEditor, setShowImageEditor] = useState(false);

  const resetEditFields = useCallback(() => {
    setIsEditingName(false);
    setIsEditingEmail(false);
    setIsEditingPhone(false);
    setIsEditingPCEmail(false);
    setIsEditingPassword(false);
  }, []);

  const isEditingAny = useMemo(() => isEditingName || isEditingEmail
    || isEditingPhone || isEditingPCEmail || isEditingPassword,
  [
    isEditingEmail,
    isEditingName,
    isEditingPassword,
    isEditingPCEmail,
    isEditingPhone,
  ]);

  useEffect(() => {
    if (!isEditingAny) {
      dispatch(myAccountHasChanges({ pageHasChanges: false }));
    }
  }, [isEditingAny]);

  const userInformation = useMemo(() => {
    if (!userInfo) {
      return {
        firstName: '',
        fullName: '',
        lastName: '',
        loginEmail: '',
        nameInitials: '',
        password: '',
        petCloudEmail: '',
        phoneNumber: '',
        profileUrl: '',
      };
    }

    const firstNameInitial = userInfo.FirstName.charAt(0);
    const lastNameInitial = userInfo.LastName.charAt(0);

    return {
      firstName: userInfo.FirstName,
      fullName: `${userInfo.FirstName} ${userInfo.LastName}`,
      lastName: userInfo.LastName,
      loginEmail: userInfo.LoginEmail,
      nameInitials: `${firstNameInitial}${lastNameInitial}`,
      password: '•••••••••••••••••••••',
      petCloudEmail: userInfo.PetCloudEmail,
      phoneNumber: userInfo.PhoneNumber,
      profileUrl: userInfo.SocialInfo.PictureUrl,
    };
  }, [userInfo]);

  const handleNameEditing = useCallback(() => {
    setActiveEdition(MY_ACCOUNT_EDIT.name);

    if (isEditingAny && pageHasChanges) {
      dispatch(displayUnsavedModal({ showUnsavedModal: true }));
    }

    if (!showUnsavedModal && !pageHasChanges) {
      resetEditFields();
      setIsEditingName(true);
    }
  }, [isEditingAny, pageHasChanges, showUnsavedModal]);

  const handleEmailEditing = useCallback(() => {
    setActiveEdition(MY_ACCOUNT_EDIT.loginEmail);

    if (isEditingAny && pageHasChanges) {
      dispatch(displayUnsavedModal({ showUnsavedModal: true }));
    }

    if (!showUnsavedModal && !pageHasChanges) {
      resetEditFields();
      setIsEditingEmail(true);
    }
  }, [isEditingAny, pageHasChanges, showUnsavedModal]);

  const handleEditingPhone = useCallback(() => {
    setActiveEdition(MY_ACCOUNT_EDIT.phone);

    if (isEditingAny && pageHasChanges) {
      dispatch(displayUnsavedModal({ showUnsavedModal: true }));
    }

    if (!showUnsavedModal && !pageHasChanges) {
      resetEditFields();
      setIsEditingPhone(true);
    }
  }, [isEditingAny, pageHasChanges, showUnsavedModal]);

  const handleEditingPCEmail = useCallback(() => {
    setActiveEdition(MY_ACCOUNT_EDIT.email);

    if (isEditingAny && pageHasChanges) {
      dispatch(displayUnsavedModal({ showUnsavedModal: true }));
    }

    if (!showUnsavedModal && !pageHasChanges) {
      resetEditFields();
      setIsEditingPCEmail(true);
    }
  }, [isEditingAny, pageHasChanges, showUnsavedModal]);

  const handleEditingPassword = useCallback(() => {
    setActiveEdition(MY_ACCOUNT_EDIT.password);

    if (isEditingAny && pageHasChanges) {
      dispatch(displayUnsavedModal({ showUnsavedModal: true }));
    }

    if (!showUnsavedModal && !pageHasChanges) {
      resetEditFields();
      setIsEditingPassword(true);
    }
  }, [isEditingAny, pageHasChanges, showUnsavedModal]);

  const handleVisibleCurrentPassword = useCallback(() => {
    setIsCurrentHidden(!isCurrentHidden);
  }, [isCurrentHidden]);

  const handleVisibleNewPassword = useCallback(() => {
    setIsNewHidden(!isNewHidden);
  }, [isNewHidden]);

  const handleVisibleConfirmPassword = useCallback(() => {
    setIsConfirmHidden(!isConfirmHidden);
  }, [isConfirmHidden]);

  const handleCancelModal = useCallback(() => {
    dispatch(displayUnsavedModal({ showUnsavedModal: false }));
    dispatch(myAccountHasChanges({ pageHasChanges: true }));
  }, []);

  const handleDiscardModal = useCallback((onConfirm) => () => {
    dispatch(displayUnsavedModal({ showUnsavedModal: false }));
    dispatch(myAccountHasChanges({ pageHasChanges: false }));
    resetEditFields();

    if (onConfirm && typeof onConfirm === 'function') {
      onConfirm();
    } else {
      switch (activeEdition) {
        case MY_ACCOUNT_EDIT.name:
          setIsEditingName(true);
          break;

        case MY_ACCOUNT_EDIT.loginEmail:
          setIsEditingEmail(true);
          break;

        case MY_ACCOUNT_EDIT.phone:
          setIsEditingPhone(true);
          break;

        case MY_ACCOUNT_EDIT.email:
          setIsEditingPCEmail(true);
          break;

        case MY_ACCOUNT_EDIT.password:
          setIsEditingPassword(true);
          break;

        default:
          break;
      }
    }
  }, [activeEdition]);

  const handleShowImageEditor = useCallback(() => {
    setShowImageEditor(true);
  }, []);

  const handleCloseImageEditor = useCallback(() => {
    setShowImageEditor(false);
    dispatch(changeProfilePictureRestart());
  }, [dispatch]);

  const handleRemovePicture = useCallback(() => {
    dispatch(removeAvatarPicture());
  }, [dispatch]);

  const handleSavePicture = useCallback((newImage) => {
    const imageBase64 = newImage.replace(/^data:(.*,)?/, '');
    dispatch(changeProfilePicture({ imageBase64, isCover: false }));
  }, [dispatch]);

  const handleChangePictureSuccess = useCallback(() => {
    handleCloseImageEditor();
    dispatch(refreshPersonalInformation());
  }, [dispatch]);

  return {
    ...userInformation,
    handleCancelModal,
    handleChangePictureSuccess,
    handleCloseImageEditor,
    handleDiscardModal,
    handleEditingPassword,
    handleEditingPCEmail,
    handleEditingPhone,
    handleEmailEditing,
    handleNameEditing,
    handleRemovePicture,
    handleSavePicture,
    handleShowImageEditor,
    handleVisibleConfirmPassword,
    handleVisibleCurrentPassword,
    handleVisibleNewPassword,
    isConfirmHidden,
    isCurrentHidden,
    isEditingAny,
    isEditingEmail,
    isEditingName,
    isEditingPassword,
    isEditingPCEmail,
    isEditingPhone,
    isNewHidden,
    isPhotoError: pictureEditing.error,
    isPhotoSaved: pictureEditing.success,
    isSavingPhoto: pictureEditing.loading,
    pageHasChanges,
    resetEditFields,
    showImageEditor,
    showUnsavedModal,
  };
};

export { useMyAccountInfo };
