import { all, call, put, takeLatest } from 'redux-saga/effects';
import { get } from '../services/api';
import {
  getAllBrandsData as GET_ALL_BRANDS_DATA,
  getAllBrandsDataFailed,
  getAllBrandsDataLoaded,
} from '../actions';

export function* getAllBrandsData() {
  const url = 'api/MultiBrand/Customer/PolicySummaries';

  const response = yield call(get, null, url);
  if (response.IsValid && response.Data) {
    yield put(getAllBrandsDataLoaded(response.Data));
  } else {
    yield put(getAllBrandsDataFailed());
  }
}

export function* billingSaga(store) {
  yield all([
    takeLatest(GET_ALL_BRANDS_DATA, getAllBrandsData, store.dispatch),
  ]);
}
