import React, { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { CONTACT_US_URL } from '../../../../constants';
import { CLAIMS } from '../../../navigation/routes.constants';
import { useMixpanel } from '../../../../hooks';
import { EV5 } from '../../../../services/mixpanel';
import {
  getMinutesFromInitialTime,
  getDateBasicFormat,
} from '../../../../services/utils';
import { getAllBrandsData } from '../../../../actions';
import FigoLottie from '../../../common/FigoLottie';
import errorIcon from '../../../../assets/claimbot/submit-error.svg';
import successIcon from '../../../../assets/claimbot/submit-success.svg';
import loadingSpinner
from '../../../../assets/lottieFiles/loading-spinner.json';
import './ClaimSubmitted.css';

const ClaimSubmitted = ({ success, policyBrandName, selectedPolicy }) => {
  const { t } = useTranslation('fileClaimbot');
  const dispatch = useDispatch();
  const { sendEvent } = useMixpanel();
  const icon = success ? successIcon : errorIcon;
  const title = success ? t('claimSubmitted.success.title')
    : t('claimSubmitted.error.title');

  const store = useSelector(
    ({ billingPayments, fileClaims }) => ({ billingPayments, fileClaims }),
    shallowEqual,
  );

  const {
    amount,
    claimsDetails,
    date,
    initialTime,
    policyNumber,
    billingPayments: { allBrandsData, loadingAllBrandsData },
  } = store;

  useEffect(() => {
    dispatch(getAllBrandsData());
  }, []);

  const lostDateFormated = getDateBasicFormat(+date, 'YYYY-MM-DD hh:mm:ss');
  const timeSpentBeforeSubmit = getMinutesFromInitialTime(initialTime);

  const pms = useMemo(() => allBrandsData.find((policy) => policy.PolicyNumber
  === selectedPolicy.PolicyNumber)?.PolicyManagementSystem, [allBrandsData]);

  useEffect(() => {
    if (allBrandsData && pms) {
      sendEvent(EV5({
        claimAmount: amount,
        claimData: store,
        claimDescription: claimsDetails,
        claimResult: success ? 'submitted' : 'error',
        dateOfLoss: lostDateFormated,
        pms,
        policyBrand: policyBrandName,
        policyNumber,
        timeSpentBeforeSubmit,
      }));
    }
  }, [
    amount,
    claimsDetails,
    pms,
    policyBrandName,
    policyNumber,
    success,
    timeSpentBeforeSubmit,
  ]);

  const description = useMemo(() => {
    if (success) {
      return (
        <span>
          {t('claimSubmitted.success.toSeeTheStatus')}

          <a href={CLAIMS}>
            {t('claimSubmitted.success.claims')}
          </a>

          {t('claimSubmitted.success.home')}
        </span>
      );
    }

    return (
      <span>
        {t('claimSubmitted.error.pleaseRetry')}

        <a href={CONTACT_US_URL} rel="noopener noreferrer" target="_blank">
          {t('claimSubmitted.error.contactUs')}
        </a>

        {t('claimSubmitted.error.forMoreInformation')}
      </span>
    );
  }, [success, t]);

  if (loadingAllBrandsData) {
    return (
      <FigoLottie
        animationData={loadingSpinner}
        height={30}
        width={30}
      />
    );
  }

  return (
    <div className="File-claimbot-claim-submitted-container">
      <img alt="" src={icon} />

      <h2>{title}</h2>

      {description}
    </div>
  );
};

export { ClaimSubmitted };
