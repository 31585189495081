import React, { useState } from 'react';

import { usePaymentHistory } from '../hooks/usePaymentHistory';

import { currencyFormat, getDateBasicFormat } from '../../../../services/utils';

import { PaymentHistoryRow } from './PaymentHistoryRow';

import FigoLottie from '../../../common/FigoLottie';
import loadingSpinner from '../../../../assets/lottieFiles/loading-blue.json';

import '../css/PaymentHistoryList.css';

const PaymentHistoryList = () => {
  const {
    handlePageButtonClick,
    loadingContent,
    paymentHistory,
    paymentHistoryCurrentPage,
    paymentHistoryTotalItems,
    paymentHistoryTotalPages,
  } = usePaymentHistory();
  const [showDetails, setShowDetails] = useState(false);
  const [currentRow, setCurrentRow] = useState(0);

  const handleDetailsClick = (row) => () => {
    if (row === currentRow) {
      setShowDetails((prevState) => !prevState);
    } else {
      setShowDetails(true);
      setCurrentRow(row);
    }
  };

  if (!loadingContent && !paymentHistory.length) {
    return null;
  }

  function renderRows() {
    if (loadingContent) {
      return (
        <div className="Payment-history-list-loading-container">
          <FigoLottie
            animationData={loadingSpinner}
            height={50}
            width={50}
          />
        </div>
      );
    }

    return (paymentHistory.map(({
      Amount,
      BillingDate,
      Description,
      PaymentDetails,
      Type,
    }, row) => (
      <PaymentHistoryRow
        key={String(row)}
        amountValue={currencyFormat({ value: Amount })}
        dateValue={getDateBasicFormat(BillingDate.split('T')[0])}
        descriptionValue={Description}
        detailsValue={PaymentDetails}
        handleDetailsClick={handleDetailsClick(row)}
        methodValue={Type}
        showDetails={currentRow === row && showDetails}
      />
    )));
  }

  return (
    <table className="Payment-history-list-container">
      <PaymentHistoryRow isHeader />

      <tbody>
        {renderRows()}
      </tbody>

      <tfoot>
        <PaymentHistoryRow
          handlePageButtonClick={handlePageButtonClick}
          isFooter
          loadingContent={loadingContent}
          paymentHistoryCurrentItems={paymentHistory.length}
          paymentHistoryCurrentPage={paymentHistoryCurrentPage}
          paymentHistoryTotalItems={paymentHistoryTotalItems}
          paymentHistoryTotalPages={paymentHistoryTotalPages}
        />
      </tfoot>
    </table>
  );
};

export { PaymentHistoryList };
