import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HomeEn from './translations/home.en.json';
import SessionRouterEn from './translations/sessionRouter.en.json';
import SideMenuEn from './translations/sideMenu.en.json';
import SettingsScreenEn from './translations/settingsScreen.en.json';
import LoginScreenEn from './translations/loginScreen.en.json';
import PageNotFoundEn from './translations/pageNotFound.en.json';
import CommonEn from './translations/common.en.json';
import InboxAndDocsEn from './translations/inboxAndDocs.en.json';
import MyPetsEn from './translations/myPets.en.json';
import EditReimbursementMethodEn
from './translations/editReimbursementMethod.en.json';
import InsuranceEn from './translations/insurance.en.json';
import ClaimsEn from './translations/claims.en.json';
import FileClaims from './translations/fileClaims.en.json';
import FileClaimbot from './translations/fileClaimbot.en.json';
import FoundLostPet from './translations/foundLostPet.en.json';
import GenericErrorEn from './translations/genericError.en.json';
import PaymentMethodEn from './translations/paymentMethod.en.json';
import ChangeCoverageEn from './translations/changeCoverage.en.json';
import MyAccountEn from './translations/myAccountScreen.en.json';
import multibrandEn from './translations/multibrand.en.json';

const resources = {
  en: {
    changeCoverage: ChangeCoverageEn,
    claims: ClaimsEn,
    common: CommonEn,
    editReimbursementMethod: EditReimbursementMethodEn,
    fileClaimbot: FileClaimbot,
    fileClaims: FileClaims,
    foundLostPet: FoundLostPet,
    genericError: GenericErrorEn,
    home: HomeEn,
    inboxAndDocs: InboxAndDocsEn,
    insuranceScreen: InsuranceEn,
    loginScreen: LoginScreenEn,
    multibrand: multibrandEn,
    myAccount: MyAccountEn,
    myPets: MyPetsEn,
    pageNotFound: PageNotFoundEn,
    paymentMethod: PaymentMethodEn,
    sessionRouter: SessionRouterEn,
    settingsScreen: SettingsScreenEn,
    sideMenu: SideMenuEn,
  },
};

i18n.use(initReactI18next).init({
  debug: false,
  defaultNS: 'home',
  lng: 'en',
  ns: ['common', 'home', 'loginScreen'],
  resources,
});

export default i18n;
