import { useMemo } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { loadPaymentHistory } from '../../../../actions';

const usePaymentHistory = () => {
  const dispatch = useDispatch();
  const store = useSelector(({
    billingPayments,
  }) => billingPayments, shallowEqual);
  const {
    loadingPaymentHistory,
    paymentHistory,
    paymentHistoryCurrentPage,
    paymentHistoryPolicyNumber,
    paymentHistoryTotalItems,
    paymentHistoryTotalPages,
    policyDetails,
  } = store;

  const loadingContent = useMemo(() => loadingPaymentHistory
    || (loadingPaymentHistory && !paymentHistory.length),
  [loadingPaymentHistory, paymentHistory]);

  function handlePageButtonClick({ pageIndex }) {
    dispatch(loadPaymentHistory({
      itemsOfFirstPage: 10,
      itemsPerPage: 10,
      pageIndex,
      policyId: policyDetails?.PolicyId,
    }));
  }

  return {
    handlePageButtonClick,
    loadingContent,
    paymentHistory,
    paymentHistoryCurrentPage: paymentHistoryCurrentPage + 1,
    paymentHistoryPolicyNumber,
    paymentHistoryTotalItems,
    paymentHistoryTotalPages,
  };
};

export { usePaymentHistory };
