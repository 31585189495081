import React from 'react';

import './ResourceCard.css';

const ResourceCard = ({
  buttonLabel = '',
  description = '',
  onClick = () => { },
  title = '',
}) => (
  <div className="Resource-card-card">
    <h3 className="Resource-card-title">{title}</h3>

    <h3 className="Resource-card-description">{description}</h3>

    <button
      className="Resource-card-button"
      onClick={onClick}
      type="button"
    >
      {buttonLabel}
    </button>
  </div>
);

export { ResourceCard };
