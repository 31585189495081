import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CardProvider } from './common';

import figoLogo from '../../../assets/brandProviderLogos/figo-logo.svg';
import ppiLogo from '../../../assets/brandProviderLogos/ppi-logo.png';
import ppiOnePackLogo
from '../../../assets/brandProviderLogos/ppi-one-pack-logo.svg';
import akcLogo from '../../../assets/brandProviderLogos/akc-logo.png';

import { CONTACT_US_STEPS } from './contactUs.constants';
import { MARKETING_CHANNEL_ID } from '../../../theme/theme.constants';
import { ContactUsCompany } from './ContactUsCompany';

const ContactUsHome = ({
  contactInfo = {},
  insuranceCompanies = [],
  onProviderClick = () => { },
}) => {
  const { t } = useTranslation('sideMenu');

  const companiesMemo = useMemo(
    () => insuranceCompanies.map((company) => {
      let item = {
        contactInfo: contactInfo.figoInfo,
        icon: figoLogo,
        name: 'figo',
        step: CONTACT_US_STEPS.FIGO,
      };

      switch (company.Id) {
        case MARKETING_CHANNEL_ID.akc:
          item = {
            contactInfo: contactInfo.akcInfo,
            icon: akcLogo,
            name: 'akc',
            step: CONTACT_US_STEPS.AKC,
          };
          break;

        case MARKETING_CHANNEL_ID.ppi:
          item = {
            contactInfo: contactInfo.ppiInfo,
            icon: ppiLogo,
            name: 'ppi',
            step: CONTACT_US_STEPS.PPI,
          };
          break;

        case MARKETING_CHANNEL_ID.ppiOnePack:
          item = {
            contactInfo: contactInfo.ppiOnePackInfo,
            icon: ppiOnePackLogo,
            name: 'ppi-one-pack',
            step: CONTACT_US_STEPS.PPI_ONE_PACK,
          };
          break;

        default:
          break;
      }

      return item;
    }), [insuranceCompanies],
  );

  if (companiesMemo.length === 1) {
    const [company] = companiesMemo;

    return (
      <ContactUsCompany
        contactInfo={company.contactInfo}
        logo={company.icon}
        logoClass={`Contact-us-${company.name}-logo`}
      />
    );
  }

  return (
    <>
      <h1 className="Contact-us-modal-title">
        {t('contactUs.modalTitle')}
      </h1>

      <h3 className="Contact-us-modal-sub-title">
        {t('contactUs.modalSubTitle')}
      </h3>

      {companiesMemo.map(({ icon, name, step }) => (
        <CardProvider
          key={step}
          onClick={onProviderClick(step)}
          providerIcon={icon}
          providerIconStyle={`Contact-us-modal-${name}-icon`}
        />
      ))}
    </>
  );
};

export { ContactUsHome };
