import React from 'react';

import checkIcon from '../../../assets/policy/included-check-white.svg';

import './css/ModifierWellnessGroup.css';

const ModifierWellnessButton = ({
  handleOnClick = () => { },
  isBasic = true,
  isSelected = false,
  title = '',
}) => {
  const buttonClassName = `Modifier-modal-wellness-${isBasic
    ? 'basic' : 'plus'}-button${!isSelected ? '-not-selected' : ''}`;
  const textClassName = `Modifier-modal-wellness-text${!isSelected
    ? '-not-selected' : ''}`;
  const imageClassname = isSelected ? 'Modifier-modal-wellness-img' : 'Hide';

  return (
    <button
      className={buttonClassName}
      onClick={handleOnClick}
      type="button"
    >
      <img alt="" className={imageClassname} src={checkIcon} />

      <span className={textClassName}>
        {title}
      </span>
    </button>
  );
};

export { ModifierWellnessButton };
