import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import 'react-circular-progressbar/dist/styles.css';
import {
  clearPolicyNotifications,
  loadPoliciesOverview,
  requestClaimsResults,
} from '../../actions';
import './ClaimsScreenComponent.css';
import Shimmer from '../common/Shimmer';
import { NoClaimsPlaceholder } from './NoClaimsPlaceholder';
import { FileClaimButton } from './FileClaimButton';
import LoadingActionButton from '../common/petCloud/LoadingActionButtonMyPets';
import FilterClaims from './FilterClaims';
import NoMatchingFilters from './NoMatchingFIlters';
import { NO_CLAIMS, CLAIM_STATUS_ACTION } from '../../constants';
import { MultibrandClaimCard } from './MultibrandClaimCard';

const ClaimGroupTitle = ({ claimStatus }) => {
  const { t } = useTranslation('claims');

  const claimOpen = claimStatus === CLAIM_STATUS_ACTION.OPEN;

  const titleText = t(claimOpen ? 'openClaims' : 'closedClaims');

  return (
    <h2
      className={`Claims-screen-secondary-title${
      !claimOpen ? ' Margin-top-32' : ''}`}
    >
      {titleText}
    </h2>
  );
};

const ClaimsCards = ({ claimGroups }) => (
  <>
    {claimGroups.map(
      (claimGroup) => claimGroup.data.length > 0 && (
        <div key={claimGroup.claimStatus}>
          <ClaimGroupTitle claimStatus={claimGroup.claimStatus} />

          {claimGroup.data.map((claim) => (
            <MultibrandClaimCard
              key={claim.ClaimNumber}
              amountPaid={claim.AmountPaid}
              claimAmount={claim.ClaimedAmount}
              claimId={claim.ClaimId}
              claimNumber={claim.ClaimNumber}
              date={claim.DateSubmitted}
              marketingChannelId={claim.MarketingChannelId}
              pets={claim?.Diagnoses}
              reimbursementType={claim.Reimbursement}
              status={claim.ClaimStatus}
            />
          ))}
        </div>
      ),
    )}
  </>
);

const ClaimsScreenComponent = () => {
  const { t } = useTranslation('claims');
  const dispatch = useDispatch();
  const store = useSelector(
    ({ billingPayments, claims, policies }) => ({
      billingPayments,
      claims,
      policies,
    }),
    shallowEqual,
  );
  const [totalShownClaims, setTotalShownClaims] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const { policies, claims } = store;
  const { isCheckedOptionClosed, isCheckedOptionOpen } = claims;
  const {
    claims: {
      claimsResults = {
        closed: {},
        open: {},
      },
    },
  } = useSelector((state) => state);
  const { open, closed } = claimsResults;
  const openClaimsOnly = !isCheckedOptionClosed && isCheckedOptionOpen;
  const closedClaimsOnly = isCheckedOptionClosed && !isCheckedOptionOpen;
  const hasPets = !policies.loading && !!policies.allPets.length;
  const totalOpenClaims = open?.totalCount || 0;
  const totalClosedClaims = closed?.totalCount || 0;

  const fetchClaims = useCallback(
    ({ statusClaim, pageNumber = 0 }) => {
      dispatch(
        requestClaimsResults({
          marketChannelId: null,
          pageNumber,
          petId: null,
          status: statusClaim,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    fetchClaims(
      { statusClaim: CLAIM_STATUS_ACTION.OPEN },
    );
    fetchClaims(
      { statusClaim: CLAIM_STATUS_ACTION.CLOSED },
    );
  }, [fetchClaims]);

  const mapData = ({ data, status }) => ({
    claimStatus: status,
    data: data?.items || [],
  });

  const allClaims = useMemo(
    () => [
      mapData({ data: open, status: CLAIM_STATUS_ACTION.OPEN }),
      mapData({ data: closed, status: CLAIM_STATUS_ACTION.CLOSED }),
    ],
    [open, closed],
  );

  const areAllClaimsEmpty = useMemo(() => (
    allClaims.every((claim) => claim.data.length === NO_CLAIMS)
  ), [allClaims]);

  const filteredClaims = useMemo(() => {
    const closedClaimsData = closed?.items || [];
    const openClaimsData = open?.items || [];

    const openClaims = {
      claimStatus: CLAIM_STATUS_ACTION.OPEN,
      data: openClaimsData,
    };

    const closedClaims = {
      claimStatus: CLAIM_STATUS_ACTION.CLOSED,
      data: closedClaimsData,
    };

    const result = [];
    if (isCheckedOptionClosed || isCheckedOptionOpen) {
      if (openClaims.data.length && !closedClaimsOnly) {
        result.push(openClaims);
      }

      if (closedClaims.data.length && !openClaimsOnly) {
        result.push(closedClaims);
      }
    }

    return result;
  }, [open, closed, isCheckedOptionClosed, isCheckedOptionOpen, currentPage]);

  const loadingContent = useMemo(() => (
    policies.loading || claims.loading
  ), [policies.loading, claims.loading]);

  useEffect(() => {
    const shownOpenClaims =
      filteredClaims.find((c) => c.claimStatus
        === CLAIM_STATUS_ACTION.OPEN)?.data
        ?.length || NO_CLAIMS;

    const shownClosedClaims =
      filteredClaims.find((c) => c.claimStatus
        === CLAIM_STATUS_ACTION.CLOSED)?.data
        ?.length || NO_CLAIMS;

    setTotalShownClaims(shownOpenClaims + shownClosedClaims);
  }, [filteredClaims]);

  useEffect(() => {
    dispatch(clearPolicyNotifications());
    dispatch(loadPoliciesOverview());
  }, [dispatch]);

  const onLoadMore = useCallback(() => {
    setCurrentPage((prevPage) => {
      const newPage = prevPage + 1;
      fetchClaims({
        pageNumber: newPage,
        statusClaim: CLAIM_STATUS_ACTION.CLOSED,
      });
      return newPage;
    });
  }, [filteredClaims]);

  const hasMoreClaims = useMemo(() => {
    const loadedClosedClaims = closed?.items?.length || NO_CLAIMS;

    return loadedClosedClaims < totalClosedClaims;
  }, [open, closed, totalShownClaims]);

  const renderClaimsCardsContainer = () => {
    if (!hasPets || areAllClaimsEmpty) {
      return <NoClaimsPlaceholder />;
    }

    if (filteredClaims.length === NO_CLAIMS) {
      return <NoMatchingFilters />;
    }

    if (hasPets || !areAllClaimsEmpty) {
      return (
        <>
          <ClaimsCards claimGroups={filteredClaims} />

          {hasMoreClaims && !openClaimsOnly && (
            <div className="d-flex justify-content-center">
              <LoadingActionButton
                className="Load-more-claims-button"
                loading={claims.isLoadingMore}
                onClick={onLoadMore}
                text={t('loadMoreClaim')}
              />
            </div>
          )}
        </>
      );
    }

    return null;
  };

  const renderClaimsCount = () => {
    const currentlyDisplayedClaims = filteredClaims.reduce(
      (total, group) => total + group.data.length,
      0,
    );

    const totalClaims = totalClosedClaims + totalOpenClaims;

    return (
      <Shimmer
        className="Claims-screen-result-text-container"
        visible={loadingContent}
        width={200}
      >
        <span className="Claims-screen-result-text">
          {t('filterResultText', {
            count: currentlyDisplayedClaims,
            total: totalClaims,
          })}
        </span>
      </Shimmer>
    );
  };

  return (
    <div className="Claims-screen">
      <h2 className="Claims-screen-main-title">{t('firstTitle')}</h2>

      <div className="Claims-screen-selectors-container">
        <div className="Claims-screen-buttons-row">
          <FilterClaims
            disabled={(currentPage === NO_CLAIMS
              && totalOpenClaims + totalClosedClaims === NO_CLAIMS)
              || areAllClaimsEmpty || !hasPets}
          />

          {renderClaimsCount()}

          <FileClaimButton />
        </div>

        <div className="Claims-screen-cards-container">
          {loadingContent ? (
            <>
              <Shimmer className="Claims-screen-shimmer-title" />

              <div className="Claims-screen-shimmer">
                <Shimmer className="Claims-screen-shimmer-row" height={64} />

                <div className="Claims-screen-shimmer-container">
                  <div>
                    <Shimmer className="Claims-screen-shimmer-label" />

                    <Shimmer className="Claims-screen-shimmer-label" />

                    <Shimmer className="Claims-screen-shimmer-label" />
                  </div>

                  <div>
                    <Shimmer className="Claims-screen-shimmer-img" />
                  </div>
                </div>
              </div>

              <div className="Claims-screen-shimmer">
                <Shimmer className="Claims-screen-shimmer-row" height={64} />

                <div className="Claims-screen-shimmer-container">
                  <div>
                    <Shimmer className="Claims-screen-shimmer-label" />

                    <Shimmer className="Claims-screen-shimmer-label" />

                    <Shimmer className="Claims-screen-shimmer-label" />
                  </div>

                  <div>
                    <Shimmer className="Claims-screen-shimmer-img" />
                  </div>
                </div>
              </div>

              <div className="Claims-screen-shimmer">
                <Shimmer className="Claims-screen-shimmer-row" height={64} />

                <div className="Claims-screen-shimmer-container">
                  <div>
                    <Shimmer className="Claims-screen-shimmer-label" />

                    <Shimmer className="Claims-screen-shimmer-label" />

                    <Shimmer className="Claims-screen-shimmer-label" />
                  </div>

                  <div>
                    <Shimmer className="Claims-screen-shimmer-img" />
                  </div>
                </div>
              </div>
            </>
          ) : (
            renderClaimsCardsContainer()
          )}
        </div>
      </div>
    </div>
  );
};

export default ClaimsScreenComponent;
