import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  loadMicrochipData,
  microchipModalVisible,
} from '../../../actions';
import FigoLottie from '../../common/FigoLottie';

import addIconBlue from '../../../assets/myPets/add-icon-blue.svg';
import pencilIcon from '../../../assets/myPets/pencil-icon.svg';
import loadingSpinnerBlue
from '../../../assets/lottieFiles/loading-blue.json';

import { PetCloudBreadcrumbs } from '../common/PetCloudBreadcrumbs';

import './MicrochipScreen.css';

const MicrochipScreen = () => {
  const { t } = useTranslation('myPets');

  const { id: paramId } = useParams();
  const dispatch = useDispatch();
  const store = useSelector(
    ({
      myPets,
    }) => ({
      myPets,
    }), shallowEqual,
  );
  const { myPets: { petProfile }, myPets: { loading }, myPets } = store;
  const { Name = '', Id = '' } = petProfile;
  const { microchipCompanies, microchipData, petProfileLoading } = myPets;
  const { ChipId = '' } = microchipData;
  const isLoading = loading || petProfileLoading;
  const petId = Id || paramId;
  const hasMicrochip = Object.keys(microchipData).length > 2;

  const provider = useMemo(() => {
    if (!hasMicrochip) {
      return {};
    }

    const companyId = parseInt(microchipData.MicrochipId, 10);

    return microchipCompanies.find((company) => company.Id === companyId);
  }, [hasMicrochip, microchipCompanies, microchipData]);

  function openMicrochipModal() {
    dispatch(microchipModalVisible({ isVisible: true }));
  }

  useEffect(() => {
    if (petId) {
      dispatch(loadMicrochipData({ petId }));
    }
    window.scrollTo(0, 0);
  }, []);

  function renderHeader() {
    return (
      <div className="Microchip-screen-header-container">
        <h1 className="Microchip-screen-page-title">
          {t('MicroChip.pageTitle')}
        </h1>

        {!hasMicrochip && (
          <button
            className="Microchip-screen-button-add-microchip"
            onClick={openMicrochipModal}
            type="button"
          >
            <img
              alt=""
              className="Microchip-screen-button-image"
              src={addIconBlue}
            />
            {t('MicroChip.addAMicrochip')}
          </button>
        )}
      </div>
    );
  }

  function renderCardInformation() {
    if (!hasMicrochip) return null;

    return (
      <div className="Microchip-screen-card-container">
        <div className="Microchip-screen-card">
          <div className="Microchip-screen-card-left-side">
            <div className="Microchip-screen-id-information">
              <h2 className="Microchip-screen-title-information">
                {t('MicroChip.microchipID')}
              </h2>

              <p className="Microchip-screen-information">{ChipId}</p>
            </div>

            <div>
              <h2 className="Microchip-screen-title-information">
                {t('MicroChip.vendor')}
              </h2>

              <p className="Microchip-screen-information">
                {provider.ListName}
              </p>
            </div>
          </div>

          <div className="Microchip-screen-card-right-side">
            <button
              className="Microchip-screen-button-edit-microchip"
              onClick={openMicrochipModal}
              type="button"
            >
              <img
                alt=""
                className="Microchip-screen-button-image"
                src={pencilIcon}
              />
              {t('MicroChip.editDetails')}
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="My-pets-loading-container">
        <FigoLottie
          animationData={loadingSpinnerBlue}
          height={50}
          width={50}
        />
      </div>
    );
  }

  return (
    <div className="Microchip-screen-container">
      <PetCloudBreadcrumbs
        activeRoute={t('MicroChip.microchip')}
        rootRoute={Name}
      />

      {renderHeader()}

      {renderCardInformation()}
    </div>
  );
};

export { MicrochipScreen };
