import './BillingAndPayments.css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import {
  PAYMENT_METHOD_NAMES,
  PAYMENT_PLANS,
  MULTIBRAND_POLICY_STATUS,
} from '../../../constants';
import { InfoBlock } from './InfoBlock';
import { PaymentMethodCard } from './PaymentMethodCard';
import { ViewMoreButton } from '../ViewMoreButton';
import { MARKETING_CHANNEL_ID } from '../../../theme/theme.constants';
import {
  useBillingAndPayments,
} from '../../myAccount/BillingAndPayments/hooks/useBillingAndPayments';

export const BillingAndPayments = ({
  company = MARKETING_CHANNEL_ID.figo,
  selectedPolicy = {},
}) => {
  const { t } = useTranslation('insuranceScreen');

  const {
    BillingCycle,
    MarketChannelId,
    NextBillingDate,
    StatusDisplay,
    TotalPremium,
  } = selectedPolicy || {};

  const {
    ExpirationMonth,
    ExpirationYear,
    FirstNameOnCard,
    LastFour,
    LastNameOnCard,
    PaymentMethodName,
  } = selectedPolicy?.PaymentOption || {};

  const hasPaymentMethod = PAYMENT_METHOD_NAMES.includes(PaymentMethodName);

  const isRedirectAvailable =
    (StatusDisplay === MULTIBRAND_POLICY_STATUS.active
      || (StatusDisplay === MULTIBRAND_POLICY_STATUS.future
        && MarketChannelId === MARKETING_CHANNEL_ID.figo));

  const { onOpenPaymentMethod } = useBillingAndPayments({
    policyInfo: selectedPolicy || {},
  });

  const paymentMethodCardCases = {
    [PAYMENT_METHOD_NAMES[0]]: {
      lastNumbers: LastFour,
      methodType: 'savingBank',
    },
    [PAYMENT_METHOD_NAMES[1]]: {
      expirationDate: ExpirationMonth && ExpirationYear
        ? `${ExpirationMonth.toString().padStart(2, '0')}/${ExpirationYear}`
        : '',
      lastNumbers: LastFour,
      methodType: 'creditCard',
    },
    default: {
      isRedirectAvailable,
      methodType: 'addPaymentMethod',
      onAddPaymentMethod: onOpenPaymentMethod,
    },
  };

  const paymentMethodCardProps = paymentMethodCardCases[PaymentMethodName]
    || paymentMethodCardCases.default;

  let billingCycleId = BillingCycle;

  if (typeof billingCycleId === 'string') {
    billingCycleId = BillingCycle === 'Annual'
      ? PAYMENT_PLANS.annual : PAYMENT_PLANS.monthly;
  }

  return (
    <div className="Billing-payments__container">
      <div className="Billing-payments__header">
        <h3 className="Billing-payments__title">{t('billingAndPayments')}</h3>

        {hasPaymentMethod && isRedirectAvailable && (
          <ViewMoreButton
            onClick={onOpenPaymentMethod}
            text={t('viewBillingDetails')}
          />
        )}
      </div>

      {hasPaymentMethod && (
        <div className="Billing-payments__body">
          <div className="Billing-payments__general">
            <div className="Billing-payments__Pay-amount">
              <h4>${TotalPremium}</h4>

              <div>{t(`payment-${billingCycleId}`)}</div>
            </div>

            <InfoBlock
              title={t('billingCycle')}
              value={billingCycleId === PAYMENT_PLANS.annual
                ? t('billingCycleTypes.annual')
                : t('billingCycleTypes.monthly')}
            />

            <InfoBlock
              title={t('ownerName')}
              value={`${FirstNameOnCard ?? ''} ${LastNameOnCard ?? ''}`}
            />

            <InfoBlock
              title={t('nextBillingDate')}
              value={NextBillingDate ? `${moment(NextBillingDate,
                'YYYY-MM-DDTHH:mm:ss.SSSZ')
                .format('MM/D/YYYY')}` : ''}
            />
          </div>

          {company !== MARKETING_CHANNEL_ID.figo && (
            // TODO: Add this block when the Monthly Premiums data is ready
            <></>
          )}
        </div>
      )}

      <div className="Billing-payments__footer">
        <h5>{t('paymentMethod')}</h5>

        <PaymentMethodCard {...paymentMethodCardProps} />
      </div>
    </div>
  );
};
