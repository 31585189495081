import React from 'react';
import { useTranslation } from 'react-i18next';

import { InboxAndDocsMenu } from './InboxAndDocsMenu';

import customIcon
from '../../assets/inboxDocsMultibrand/iconsFolder/custom-icon.svg';
import policyIcon
from '../../assets/inboxDocsMultibrand/iconsFolder/policy.svg';
import vetBillsIcon
from '../../assets/inboxDocsMultibrand/iconsFolder/vet-bills.svg';
import picturesIcon
from '../../assets/inboxDocsMultibrand/iconsFolder/pictures-icon.svg';
import medicalRecordsIcon
from '../../assets/inboxDocsMultibrand/iconsFolder/medical-records-icon.svg';
import shotsAndCertificatesIcon from
'../../assets/inboxDocsMultibrand/iconsFolder/shots-certificates-icon.svg';
import claimsIcon from
'../../assets/inboxDocsMultibrand/iconsFolder/claims-icon.svg';

import {
  DEFAULT_FOLDERS_INBOX_AND_DOCS,
  INBOX_DOCS,
} from '../../constants';
import { getNullSafeDate } from '../../services/utils';
import { isDefaultFolder } from './inboxAndDocs.utils';

import './Folder.css';

const UnreadFiles = ({ amount, dateAdded }) => {
  const { t } = useTranslation('inboxAndDocs');

  return (
    <div className="Folder-unread-files">
      {!!amount && (
        <span className="Folder-unread-files-amount">
          {t('unreadFile', { count: amount })}
        </span>
      )}

      {dateAdded && !!amount && (
        <span className="Folder-unread-files-date">
          {t('dateAdded', {
            date: getNullSafeDate(dateAdded),
            interpolation: { escapeValue: false },
          })}
        </span>
      )}
    </div>
  );
};

const Folder = ({ folder, onClick = () => { } }) => {
  function renderFolderIcon(id) {
    let render;
    switch (id) {
      case INBOX_DOCS.policyDocuments:
        render = policyIcon;
        break;
      case INBOX_DOCS.vetBills:
        render = vetBillsIcon;
        break;
      case INBOX_DOCS.pictures:
        render = picturesIcon;
        break;
      case INBOX_DOCS.medicalRecords:
        render = medicalRecordsIcon;
        break;
      case INBOX_DOCS.shotsCertificates:
        render = shotsAndCertificatesIcon;
        break;
      case INBOX_DOCS.claimDocuments:
        render = claimsIcon;
        break;
      default:
        render = customIcon;
        break;
    }
    return (
      <img
        alt=""
        className="Folder-icon-card"
        src={render}
      />
    );
  }

  const defaultFolder = isDefaultFolder(folder.Id);

  function handleOnClick(event) {
    if (!event.target.id) {
      return;
    }

    onClick();
  }

  return (
    <div
      className="Flex Folder-container-card"
      id={`Folder-${folder.Id}`}
      onClick={handleOnClick}
      onKeyDown={null}
      onKeyPress={null}
      role="button"
      tabIndex={-1}
    >
      <div className="Flex Flex-column No-clicked Folder-card">
        {renderFolderIcon(folder.Id)}

        <span className={`${!defaultFolder
          ? 'Folder-title-width' : ''} Folder-title`}
        >
          {folder.Name}
        </span>

        {defaultFolder && (
          <UnreadFiles
            amount={folder.Metadata.UnReadDocs}
            dateAdded={folder.Metadata.LastAddedDate}
          />
        )}
      </div>

      {DEFAULT_FOLDERS_INBOX_AND_DOCS[folder.Id] ? null : (
        <div className="Folder-dots-container">
          <InboxAndDocsMenu
            folderPermissions={folder.FolderPermissions}
            isFolder
            itemId={folder.Id}
            itemInfo={folder}
          />
        </div>
      )}
    </div>
  );
};

export { Folder };
