export const THEME_PETCLOUD = 1;
export const THEME_FIGO = 2;
export const THEME_AKC = 3;
export const THEME_PET_PARTNERS = 4;
export const THEME_SPOT = 5;

/**
 * Company that provides the policy/claim data
 */
export const MARKETING_CHANNEL_ID = {
  akc: 3,
  figo: 1,
  ppi: 2,
  ppiOnePack: 4,
  spot: 5,
};
