import { handleActions } from 'redux-actions';

const INITIAL_STATE = {
  bannerVisible: true,
  customerInformation: null,
  data: null,
  error: null,
  impersonateCustomerId: null,
  impersonatingAdminEmail: '',
  isExpired: false,
  isImpersonate: false,
  isRefreshingToken: false,
  isTokenRefreshed: false,
  loading: false,
  modal: false,
  privacyPolicyLink: null,
  privateToken: null,
  publicToken: null,
  retrievingSession: true,
  startingUp: true,
  termsOfServiceLink: null,
  termsOfServiceVersionId: null,
  userHasTermsOfService: true,
  userInfo: null,
};

export default handleActions({
  ATTEMPT_PRIVATE_TOKEN: (state) => ({
    ...state,
    loading: true,
  }),
  ATTEMPT_TEMPORARY_TOKEN: (state) => ({
    ...state,
    loading: true,
  }),
  BANNER_VISIBLE_CHANGED: (state, { payload }) => ({
    ...state,
    bannerVisible: payload.visible,
  }),
  CLEAR_ERROR: (state) => ({
    ...state,
    error: null,
  }),
  CLOSE_MODAL: (state) => ({
    ...state,
    modal: false,
  }),
  CUSTOMER_INFORMATION_FAILED: (state) => ({
    ...state,
    loading: false,
  }),
  CUSTOMER_INFORMATION_LOADED: (state, { payload }) => ({
    ...state,
    customerInformation: payload,
    loading: false,
  }),
  DO_LOGIN: (state) => ({
    ...state,
    error: null,
    loading: true,
  }),
  DO_LOGIN_IMPERSONATE: (state) => ({
    ...state,
    error: null,
    loading: true,
  }),
  DO_LOGOUT: () => ({
    ...INITIAL_STATE,
    startingUp: false,
  }),
  LOAD_CUSTOMER_INFORMATION: (state) => ({
    ...state,
    loading: true,
  }),
  LOAD_DATA_FOR_PRIVATE_TOKEN: (state) => ({
    ...state,
    loading: true,
  }),
  LOG_IN: (state, action) => ({
    ...state,
    error: null,
    loading: false,
    privateToken: action.payload,
    retrievingSession: false,
  }),
  LOG_OUT: (state) => ({
    ...state,
    data: null,
    error: null,
    isExpired: false,
    isRefreshingToken: false,
    isTokenRefreshed: false,
    loading: false,
    retrievingSession: false,
    userInfo: null,
  }),
  PRIVATE_TOKEN_COMPLETED: (state) => ({
    ...state,
    loading: false,
  }),
  PRIVATE_TOKEN_FAILED: (state) => ({
    ...state,
    loading: false,
  }),
  REFRESH_TOKEN: (state) => ({
    ...state,
    isRefreshingToken: true,
  }),
  RESET_LOGIN_ERROR: (state) => ({
    ...state,
    error: null,
  }),
  SAVE_IMPERSONATE_CUSTOMER_ID: (state, { payload }) => ({
    ...state,
    impersonateCustomerId: payload.customerId,
    impersonatingAdminEmail: payload.adminEmail,
    isImpersonate: true,
  }),
  SAVE_USER_INFO: (state, { payload }) => ({
    ...state,
    startingUp: false,
    userInfo: payload,
  }),
  SAVE_USER_TERMS_OF_SERVICE: (state, { payload }) => ({
    ...state,
    privacyPolicyLink: payload.PrivacyPolicyLink,
    termsOfServiceLink: payload.TermsOfServiceLink,
    termsOfServiceVersionId: payload.TermsVersionId,
    userHasTermsOfService: payload.UserHasTermsOfService,
  }),
  SESSION_ERROR: (state, { payload }) => ({
    ...state,
    error: payload,
    loading: false,
    modal: true,
    retrievingSession: false,
  }),
  SESSION_EXPIRED: (state) => ({
    ...state,
    isExpired: true,
  }),
  SET_PRIVATE_TOKEN: (state, { payload }) => ({
    ...state,
    privateToken: payload.privateToken,
  }),
  SET_TEMPORARY_TOKEN: (state, { payload }) => ({
    ...state,
    publicToken: payload.publicToken,
  }),
  STARTUP: (state) => ({
    ...state,
    startingUp: true,
  }),
  STARTUP_FINISHED: (state) => ({
    ...state,
    startingUp: false,
  }),
  STARTUP_FROM_CACHE: (state, { payload }) => ({
    ...state,
    isExpired: false,
    privateToken: payload.privateToken,
    startingUp: false,
    userInfo: payload.userInfo,
  }),
  TEMPORARY_TOKEN_COMPLETED: (state) => ({
    ...state,
    loading: false,
  }),
  TEMPORARY_TOKEN_FAILED: (state) => ({
    ...state,
    loading: false,
  }),
  TEMPORARY_TOKEN_RESET: (state) => ({
    ...state,
    publicToken: null,
  }),
  TERMS_OF_SERVICE_ACCEPTED: (state) => ({
    ...state,
    userHasTermsOfService: true,
  }),
  TOKEN_REFRESHED: (state) => ({
    ...state,
    isExpired: false,
    isRefreshingToken: false,
    isTokenRefreshed: true,
  }),
  UPDATE_SESSION: (state, { payload }) => ({
    ...state,
    data: payload,
    error: null,
    loading: false,
    retrievingSession: false,
  }),
}, INITIAL_STATE);
