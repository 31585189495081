import React, { useCallback, useEffect } from 'react';

import './GroupButtonSelector.css';

const GroupButtonSelector = ({
  disabled = false,
  error = '',
  labelRef,
  onSelectionChange,
  selectedValue,
  title,
  values,
}) => {
  useEffect(() => {
    if (labelRef && labelRef.current) {
      labelRef.current.focus();
    }
  }, [labelRef]);

  const onClickButton = useCallback((value) => () => {
    onSelectionChange(value);
  }, []);

  return (
    <div className="Group-button-selector-container">
      <p
        ref={labelRef}
        className="No-clicked Group-button-selector-label"
      >
        {title}
      </p>

      <div className="Group-button-selector-items-container" tabIndex={-1}>
        {Object.keys(values).map((key) => {
          const isActive = selectedValue === values[key].id;
          const Icon = values[key].icon;

          return (
            <button
              key={values[key].id}
              className={`Group-button-selector-button${isActive
                ? '-active' : ''}`}
              disabled={disabled}
              onClick={onClickButton(values[key].id)}
              type="button"
            >
              {Icon && (
                <Icon className={`Group-button-selector-icon${isActive
                  ? '-active' : ''}`}
                />
              )}

              <span>{values[key].title}</span>
            </button>
          );
        })}
      </div>

      <span className="Error-label Group-button-error">{error}</span>
    </div>
  );
};

export { GroupButtonSelector };
