import React from 'react';
import './BreadcrumbItem.css';

const BreadcrumbItem = ({
  children,
  current,
  itemActiveClassName = '',
  itemClassName = '',
  onNav = () => { },
}) => {
  const styles = {
    breadCrumbActiveItem: itemActiveClassName || 'Breadcrumb-item-active',
    breadCrumbItem: itemClassName || 'Breadcrumb-item',
  };

  function handleNav() {
    if (!current) {
      onNav();
    }
  }

  return (
    <button
      className={`${styles.breadCrumbItem} ${current
        ? `${styles.breadCrumbActiveItem}` : ''}`}
      onClick={handleNav}
      type="button"
    >
      {children}
    </button>
  );
};

export { BreadcrumbItem };
