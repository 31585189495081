/* eslint-disable react/no-danger */
import React from 'react';

const ContentfulComponent = ({ className = '', htmlContent = '' }) => (
  <div
    className={className}
    dangerouslySetInnerHTML={{ __html: htmlContent }}
  />
);

export { ContentfulComponent };
