import React, { useState } from 'react';
import Image from 'react-bootstrap/Image';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  setIsCheckedOptionClosed,
  setIsCheckedOptionOpen,
} from '../../actions';
import filterIcon from '../../assets/claims/filter-controller.svg';
import filterArrow from '../../assets/claims/chevron-down-blue.svg';
import checkIcon from '../../assets/claims/check-mark-gray.svg';
import './FilterClaims.css';

const FilterClaims = ({ disabled = false }) => {
  const { t } = useTranslation('claims');
  const [isFilterMenuOpen, setFilterMenuOpen] = useState(false);
  const {
    isCheckedOptionClosed,
    isCheckedOptionOpen,
  } = useSelector((state) => state.claims);
  const dispatch = useDispatch();

  const toggleFilterMenu = () => {
    setFilterMenuOpen(!isFilterMenuOpen);
  };

  const toggleCheckedOptionOpen = () => {
    dispatch(setIsCheckedOptionOpen(!isCheckedOptionOpen));
  };

  const toggleCheckedOptionClosed = () => {
    dispatch(setIsCheckedOptionClosed(!isCheckedOptionClosed));
  };

  return (
    <div className="Filter-claims-menu-main-container">
      <button
        className="Filter-claims-menu-container"
        disabled={disabled}
        id="myFilterMenu"
        onClick={toggleFilterMenu}
        type="button"
      >
        <Image
          id="myFilterMenu"
          src={filterIcon}
        />

        <div className="Filter-claims-inner-container">
          <span className="Filter-claims-content">
            <span className="Filter-claims-text">{t('filterClaims')}</span>

            <img alt="" className="Filter-claims-text-icon" src={filterArrow} />
          </span>
        </div>
      </button>

      {isFilterMenuOpen && (
        <div
          className="Filter-claims-menu-button-container"
          id="filterOverlay"
        >
          <div className="Filter-claims-menu">
            <h4 className="Filter-claims-menu-title">{t('claimStatus')}</h4>

            <div className="Filter-claims-menu-separator-line" />

            <div className="Filter-claims-menu-item">
              <button
                className="Filter-claims-menu-button"
                id="filterOpenBtn"
                onClick={toggleCheckedOptionOpen}
                type="button"
              >
                <span className="Filter-claims-menu-button-span">
                  {t('filterMenuOpenText')}
                </span>

                {isCheckedOptionOpen && (
                  <img
                    alt="checked"
                    className="Filter-claims-checkbox"
                    src={checkIcon}
                  />
                )}
              </button>
            </div>

            <div className="Filter-claims-menu-item">
              <button
                className="Filter-claims-menu-button"
                id="filterClosedBtn"
                onClick={toggleCheckedOptionClosed}
                type="button"
              >
                <span className="Filter-claims-menu-button-span">
                  {t('filterMenuClosedText')}
                </span>

                {isCheckedOptionClosed && (
                  <img
                    alt="checked"
                    className="Filter-claims-checkbox"
                    src={checkIcon}
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterClaims;
