import React from 'react';

import './ClaimSummary.css';

const ClaimSummaryRow = ({
  leftText = '',
  rightText = '',
}) => (
  <div className="File-claimbot-claim-summary-row">
    <span className="File-claimbot-claim-summary-row-left-text">
      {leftText}
    </span>

    <span className="File-claimbot-claim-summary-row-right-text">
      {rightText}
    </span>
  </div>
);

const ClaimSummary = ({
  claimSummaryInfo = [],
  selectedPet = { },
}) => (
  <div className="File-claimbot-claim-summary-container">
    <div className="File-claimbot-claim-summary-pet-card">
      <img alt="" src={selectedPet.PictureUrl} />

      <div className="File-claimbot-claim-summary-column">
        <span className="File-claimbot-claim-summary-pet-name">
          {selectedPet.Name}
        </span>

        <span className="File-claimbot-claim-summary-pet-breed">
          {selectedPet.Breed}
        </span>
      </div>
    </div>

    <div className="File-claimbot-claim-summary-rows-container">
      {claimSummaryInfo.map((row) => (
        <ClaimSummaryRow
          key={`${row.displayName}-${row.displayValue}`}
          leftText={row.displayName}
          rightText={row.displayValue}
        />
      ))}
    </div>
  </div>
);

export { ClaimSummary };
