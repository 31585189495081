import { handleActions } from 'redux-actions';
import { ONE_INC_EMPTY_ACH, ONE_INC_EMPTY_CC } from '../constants';

const INITIAL_STATE = {
  bankAccount: { ...ONE_INC_EMPTY_ACH },
  creditCard: { ...ONE_INC_EMPTY_CC },
  customerId: '',
  defaultPayments: [],
  defaultTokenACH: '',
  defaultTokenCC: '',
  isTwoDefaults: false,
  loadingDefaultMethods: false,
  paymentCategory: '',
  paymentName: '',
  portalOneSessionKey: '',
  showModal: 0,
  tokenId: '',
};

export default handleActions({
  ONE_INC_DEFAULTS_PAYMENT_FAILED: (state) => ({
    ...state,
    loadingDefaultMethods: false,
  }),
  ONE_INC_DEFAULTS_PAYMENT_LOADED: (state, { payload }) => ({
    ...state,
    bankAccount: payload.bankAccountDefault || { ...ONE_INC_EMPTY_ACH },
    creditCard: payload.creditCardDefault || { ...ONE_INC_EMPTY_CC },
    defaultPayments: payload.BillingInfoList,
    defaultTokenACH: payload.defaultTokenACH,
    defaultTokenCC: payload.defaultTokenCC,
    isTwoDefaults: payload.isTwoDefaults,
    loadingDefaultMethods: false,
    showModal: state.showModal + 1,
  }),
  ONE_INC_LOAD_DEFAULT_PAYMENTS: (state) => ({
    ...state,
    loadingDefaultMethods: true,
  }),
  ONE_INC_PAYMENT_BANK_SAVED: (state, { payload }) => ({
    ...state,
    bankAccount: payload.data,
    creditCard: { ...INITIAL_STATE.creditCard },
    isTwoDefaults: true,
    paymentCategory: payload.data.paymentCategory,
    tokenId: payload.data.tokenId,
  }),
  ONE_INC_PAYMENT_CREDIT_CARD_SAVED: (state, { payload }) => ({
    ...state,
    bankAccount: { ...INITIAL_STATE.bankAccount },
    creditCard: payload.data,
    isTwoDefaults: false,
    paymentCategory: payload.data.paymentCategory,
    tokenId: payload.data.tokenId,
  }),
  ONE_INC_PAYMENT_RESET: () => ({
    ...INITIAL_STATE,
  }),
  ONE_INC_SESSION_CREATED: (state, { payload }) => ({
    ...state,
    portalOneSessionKey: payload,
  }),
  ONE_INC_SESSION_REMOVED: (state) => ({
    ...state,
    portalOneSessionKey: '',
  }),
}, INITIAL_STATE);
