import React from 'react';
import { CLAIM_STATUS_BY_TEXT } from '../../../constants';

export const ClaimInfoItem = ({
  title = '',
  value = '',
  direction = '',
  bold = false,
}) => (
  <div className={`Claim-info-item ${direction === 'column'
    ? 'Claim-info-item-column'
    : ''}`}
  >
    <h4 className="Claim-info-title">{title}</h4>

    <div className={'Claim-info-value'
      + ` ${value === CLAIM_STATUS_BY_TEXT.attentionNeeded
        ? 'Claim-info-value-attentionNeeded' : ''}`
      + `${bold ? 'Claim-info-bold' : ''}`}
    >
      {value}
    </div>
  </div>
);
