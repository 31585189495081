import React, { useEffect, useMemo, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useIsAuthenticated } from '@azure/msal-react';
import { startup, startupFinished } from './actions';
import SessionRouter from './components/navigation/SessionRouter';
import PublicRouter from './components/navigation/PublicRouter';
import CustomModal from './components/common/CustomModal';
import { ImpersonateSession } from './components/common/ImpersonateSession';

import { useOneIncScript } from './hooks/useOneIncScript';
import { useB2CInfo } from './hooks';
import { doNothing } from './services/utils';

const AppRouter = () => {
  const dispatch = useDispatch();
  useOneIncScript();

  const pathname = window.location.pathname.split('/')[1];
  const store = useSelector(({
    policies,
    session,
  }) => ({ policies, session }), shallowEqual);
  const { logoutB2C, getAllTokens } = useB2CInfo();
  const [allTokensLoaded, setAllTokensLoaded] = useState(false);
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    switch (pathname) {
      case 'login':
        logoutB2C();
        break;
      case 'paymentMethod':
        sessionStorage.clear();
        dispatch(startupFinished());
        break;
      default:
        doNothing();
    }

    return () => null;
  }, []);

  const getTokensBeforeStartUp = async () => {
    await getAllTokens();
    dispatch(startup());
    setAllTokensLoaded(true);
  };

  const hasSession = useMemo(() => (
    Boolean(isAuthenticated
      && store.session.privateToken
      && store.session.userInfo)
  ), [isAuthenticated, store.session.privateToken, store.session.userInfo]);

  useEffect(() => {
    if (hasSession) {
      getTokensBeforeStartUp();
    }
  }, [hasSession]);

  function renderPageContent() {
    if (hasSession && allTokensLoaded) {
      return <SessionRouter />;
    }

    return <PublicRouter />;
  }

  return (
    <Router>
      {renderPageContent()}

      <CustomModal />

      <ImpersonateSession />
    </Router>
  );
};

export default AppRouter;
