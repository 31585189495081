/* eslint-disable max-len */
import { createActions } from 'redux-actions';

const actions = createActions({
  CONTACTS_FORM_INITIALIZED: (data) => data,
  DELETE_TAG_NUMBER: (data) => data,
  DELETE_TAG_NUMBER_FAILED: (data) => data,
  DELETE_TAG_NUMBER_REQUESTED: () => {},
  EDIT_MODE_SET: (data) => data,
  FAMILY_MEMBER_NAME_ERROR_MESSAGE_SETTED: (data) => data,
  FAMILY_MEMBER_NAME_SETTED: (data) => data,
  FAMILY_MEMBER_PHONE_NUMBER_ERROR_MESSAGE_SETTED: (data) => data,
  FAMILY_MEMBER_PHONE_NUMBER_SETTED: (data) => data,
  FIGO_TAG_MODAL_HIDDEN: () => {},
  FIGO_TAG_MODAL_SHOWN: () => {},
  FIRST_STEP_SHOWED: () => {},
  GET_PET_TAG_INFO: (data) => data,
  MESSAGE_TO_FINDER_SETTED: (data) => data,
  PET_ID_SETTED: (data) => data,
  PET_TAG_INFO_EDIT_MODE_SET: (data) => data,
  PET_TAG_INFO_LOAD_FAILED: (data) => data,
  PET_TAG_INFO_LOADED: (data) => data,
  PET_TAG_INFO_POST_FAILED: (data) => data,
  PET_TAG_INFO_POST_REQUESTED: () => {},
  PET_TAG_INFO_POSTED: (data) => data,
  PET_TAG_INFO_REQUESTED: () => {},
  PET_TAG_SETTED: (data) => data,
  REMOVE_STEP_SHOWED: () => {},
  SECOND_STEP_SHOWED: () => {},
  SET_PET_TAG_INFO: (data) => data,
  TAG_NUMBER_DELETED: (data) => data,
  TAG_NUMBER_ERROR_MESSAGE_SETTED: (data) => data,
  TAG_NUMBER_MODE_SET: (data) => data,
});

export const contactsFormInitialized = actions.contactsFormInitialized;
export const deleteTagNumber = actions.deleteTagNumber;
export const deleteTagNumberFailed = actions.deleteTagNumberFailed;
export const deleteTagNumberRequested = actions.deleteTagNumberRequested;
export const editModeSet = actions.editModeSet;
export const familyMemberNameErrorMessageSetted = actions.familyMemberNameErrorMessageSetted;
export const familyMemberNameSetted = actions.familyMemberNameSetted;
export const familyMemberPhoneNumberErrorMessageSetted = actions.familyMemberPhoneNumberErrorMessageSetted;
export const familyMemberPhoneNumberSetted = actions.familyMemberPhoneNumberSetted;
export const figoTagModalHidden = actions.figoTagModalHidden;
export const figoTagModalShown = actions.figoTagModalShown;
export const firstStepShowed = actions.firstStepShowed;
export const getPetTagInfo = actions.getPetTagInfo;
export const messageToFinderSetted = actions.messageToFinderSetted;
export const petIdSetted = actions.petIdSetted;
export const petTagInfoEditModeSet = actions.petTagInfoEditModeSet;
export const petTagInfoLoaded = actions.petTagInfoLoaded;
export const petTagInfoLoadFailed = actions.petTagInfoLoadFailed;
export const petTagInfoPosted = actions.petTagInfoPosted;
export const petTagInfoPostFailed = actions.petTagInfoPostFailed;
export const petTagInfoPostRequested = actions.petTagInfoPostRequested;
export const petTagInfoRequested = actions.petTagInfoRequested;
export const petTagSetted = actions.petTagSetted;
export const removeStepShowed = actions.removeStepShowed;
export const secondStepShowed = actions.secondStepShowed;
export const setPetTagInfo = actions.setPetTagInfo;
export const tagNumberDeleted = actions.tagNumberDeleted;
export const tagNumberErrorMessageSetted = actions.tagNumberErrorMessageSetted;
export const tagNumberModeSet = actions.tagNumberModeSet;
