import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { WELLNESS_MODIFIER_ID } from '../../../constants';

import { ModifierWellnessButton } from './ModifierWellnessButton';

import './css/ModifierWellnessGroup.css';

const ModifierWellnessGroup = ({
  categoryList = [],
  isWhatsCovered = false,
  menuSelected = 0,
  onClickMenu = () => { },
}) => {
  const { t } = useTranslation('insuranceScreen');
  const WELLNESS_BASIC = 15;
  const WELLNESS_PLUS = 16;

  const wellnessBasicId = useMemo(() => {
    if (isWhatsCovered) {
      return WELLNESS_BASIC;
    }

    return categoryList
      .filter((element) => element.InsuranceModifierId
        === WELLNESS_MODIFIER_ID)[0]?.Id;
  }, [categoryList]);

  const wellnessPlusId = useMemo(() => {
    if (isWhatsCovered) {
      return WELLNESS_PLUS;
    }

    return categoryList
      .filter((element) => element.InsuranceModifierId
        === WELLNESS_MODIFIER_ID)[1]?.Id;
  }, [categoryList]);

  const handleOnClick = (id) => () => {
    onClickMenu(id);
  };

  function renderContent() {
    if (categoryList.length === 1) {
      return null;
    }

    return (
      <div className="Modifier-modal-wellness-button-container">
        <ModifierWellnessButton
          handleOnClick={handleOnClick(wellnessBasicId)}
          isBasic
          isSelected={menuSelected === wellnessBasicId}
          title={t('wellnessBasic')}
        />

        <ModifierWellnessButton
          handleOnClick={handleOnClick(wellnessPlusId)}
          isBasic={false}
          isSelected={menuSelected === wellnessPlusId}
          title={t('wellnessPlus')}
        />
      </div>
    );
  }

  return renderContent();
};

export { ModifierWellnessGroup };
