import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

import { PET_TYPE } from '../../constants';
import { CurrentPetPolicy } from './CurrentPetPolicy';
import './UninsuredPet.css';
import LoadingActionButton from '../common/petCloud/LoadingActionButtonMyPets';

const UninsuredPet = ({
  showLearnMore = false,
  onGetQuote = () => { },
  petBreedName,
  petImage,
  petName,
  petType = PET_TYPE.DOG,
}) => {
  const { t } = useTranslation('insuranceScreen');
  const loading = useSelector(
    ({ orcaInsurance }) => orcaInsurance.getQuoteValidation.loading,
    shallowEqual,
  );

  const pet = useMemo(() => ({
    Breed: petBreedName,
    PetName: petName,
    ProfileUrl: petImage,
    Type: petType,
  }), [petName, petImage, petBreedName, petType]);

  return (
    <div className="Uninsured-pet-container">
      <div className="Uninsured-pet-card">
        <div>
          <h2 className="Uninsured-pet-card-title">My Pet</h2>

          <div className="Uninsured-pet-card-pet-card-profile">
            <CurrentPetPolicy currentPet={pet} />
          </div>
        </div>

        <div className="Uninsured-pet-content">
          <h2 className="Uninsured-pet-content-subtitle">
            {t('takeTheNextStep', {
              interpolation: { escapeValue: false },
              petName,
            })}
          </h2>

          <p className="Uninsured-pet-content-paragraph">
            {t('insurancePlans')}

            <br />

            {t('reimbursementCovered')}
          </p>

          <div className="Uninsured-pet-buttons">
            {showLearnMore && (
              <a
                className="Uninsured-pet-buttons-learn-more"
                href="https://figopetinsurance.com/pet-insurance"
                rel="noreferrer"
                target="_blank"
                type="button"
              >
                {t('learnMore')}
              </a>
            )}

            <LoadingActionButton
              className="Uninsured-pet-buttons-get-quote"
              loading={loading}
              onClick={onGetQuote}
              text={t('getAQuote')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UninsuredPet;
