import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalContainer from '../../common/ModalContainer';
import successIcon from '../../../assets/check.svg';
import './css/SubmitChanges.css';

const SubmitChanges = ({ isVisible = false, onClose = () => { } }) => {
  const { t } = useTranslation('changeCoverage');

  return (
    <ModalContainer contentStyle="Submit-container" show={isVisible}>
      <img alt="success" src={successIcon} />

      <span>{t('submit.title')}</span>

      <p>{t('submit.description')}</p>

      <button onClick={onClose} type="button">{t('close')}</button>
    </ModalContainer>
  );
};

export { SubmitChanges };
