import React from 'react';
import Shimmer from '../../common/Shimmer';

import './css/InsuranceAccountScreen.css';

const InsuranceAccountShimmer = () => (
  <div className="Insurance-account-shimmer-container">
    <Shimmer className="Insurance-account-shimmer-element" visible />

    <Shimmer className="Insurance-account-shimmer-element" visible />

    <Shimmer className="Insurance-account-shimmer-element" visible />

    <Shimmer className="Insurance-account-shimmer-element" visible />
  </div>
);

export { InsuranceAccountShimmer };
