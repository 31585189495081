import React from 'react';
import { useTranslation } from 'react-i18next';

import figoLogo from '../../../assets/figo-logo.svg';

import { CoveragePolicyStatus }
from '../../insurance/coverageOverview/CoveragePolicyStatus';

import './css/PaymentHistoryScreen.css';

const PaymentHistoryPolicyCard = ({
  expirationDate = '',
  onClick = () => { },
  policyNumber = '',
  startDate = '',
  status = '',
}) => {
  const { t } = useTranslation('insuranceScreen');

  return (
    <button
      className="Payment-history-screen-policy-card"
      onClick={onClick}
      type="button"
    >
      <div className="Flex-row">
        <img
          alt=""
          className="Payment-history-screen-policy-card-logo"
          src={figoLogo}
        />

        <div className="Payment-history-screen-policy-info-container">
          <div className="Payment-history-screen-policy-info-details">
            <span className="Payment-history-screen-title">
              {t('policyNumberTitle')}
            </span>

            <span className="Payment-history-screen-value">{policyNumber}</span>
          </div>

          <div className="Payment-history-screen-policy-info-details">
            <span className="Payment-history-screen-title">
              {t('policyTerm')}
            </span>

            <span className="Payment-history-screen-value">
              {`${startDate} - ${expirationDate}`}
            </span>
          </div>
        </div>
      </div>

      <CoveragePolicyStatus
        label={t(`policyStatus-${status}`)}
        status={status}
      />
    </button>
  );
};

export { PaymentHistoryPolicyCard };
