import React, { useMemo } from 'react';

import './css/PolicyHeader.css';
import { getThemeLogoById } from '../../theme/theme.utils';

const PolicyHeader = ({ company }) => {
  const getCompanyLogo = useMemo(() => {
    const logos = getThemeLogoById(company);
    return logos.white;
  }, [company]);

  return (
    <div className="Policy-header-container">
      <img
        alt=""
        className={`Policy-header-logo-${company}`}
        src={getCompanyLogo}
      />
    </div>
  );
};

export { PolicyHeader };
