import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import {
  updatePaymentMethod,
  billingPaymentReseted,
} from '../../../actions';
import {
  PAYMENT_METHOD_IDS,
  MULTIBRAND_POLICY_STATUS,
} from '../../../constants';
import { PaymentMethod } from './PaymentMethod';
import { OneIncModal } from '../../common/OneIncModal';
import FigoLoadingOverlay from '../../common/FigoLoadingOverlay';

import bankIcon from '../../../assets/billingPayments/saving-bank.svg';
import checkIcon from '../../../assets/billingPayments/default-check.svg';
import creditCardIcon from '../../../assets/billingPayments/credit-card.svg';
import addIconBlue from '../../../assets/myPets/add-icon-blue.svg';

import './css/PaymentMethodCard.css';
import { useWebViewReskin } from '../../../hooks';

const PaymentMethodCard = ({ policyInformation, policyNumber }) => {
  const { t } = useTranslation('myAccount');
  const dispatch = useDispatch();
  const useMobileReskin = useWebViewReskin();
  const store = useSelector(({
    billingPayments,
    editPayment,
    oneInc,
    personalInformation,
    policies,
  }) => ({
    billingPayments,
    editPayment,
    oneInc,
    personalInformation,
    policies,
  }));

  const {
    billingPayments: {
      billingDetails,
      loadingBillingInfo,
      updatingPaymentMethod,
    },
  } = store;

  const billingDetailsValidation =
    billingDetails?.ACHAccountNumber !== null
    || billingDetails?.CCCardNumber !== null;

  function onUpdatePaymentMethod({
    paymentMethodData,
    policies,
    sameBillingAddress,
    userInformation,
  }) {
    dispatch(updatePaymentMethod({
      paymentMethodData,
      policies,
      sameBillingAddress,
      userInformation,
    }));
  }

  const isCreditCard = billingDetails?.PayMethod
    === PAYMENT_METHOD_IDS.creditCard;
  const isBankAccount = billingDetails?.PayMethod === PAYMENT_METHOD_IDS.ach;
  const validPolicies = policyInformation?.PolicyStatus
    === MULTIBRAND_POLICY_STATUS.active || policyInformation?.PolicyStatus
    === MULTIBRAND_POLICY_STATUS.future;

  useEffect(() => {
    dispatch(billingPaymentReseted());
  }, []);

  if (updatingPaymentMethod || loadingBillingInfo) {
    return (
      <FigoLoadingOverlay visible={updatingPaymentMethod} />
    );
  }

  return (
    <>
      {billingDetailsValidation && billingDetails && (
        <div
          className="Payment-method-card-container"
          data-default={useMobileReskin}
        >
          {useMobileReskin && (
            <div className="Payment-method-card-default-label">
              <img alt="" src={checkIcon} />

              {t('billingAndPayments.default')}
            </div>
          )}

          <PaymentMethod
            billingDetails={billingDetails}
            icon={isBankAccount ? bankIcon : creditCardIcon}
            isCreditCard={isCreditCard}
            title={isBankAccount ? t('billingAndPayments.bankAccountEnding')
              : t('billingAndPayments.creditCardEnding')}
          />

          <div>
            <OneIncModal
              buttonClass="Payment-method-card-tooltip-button"
              onUpdatePaymentMethod={onUpdatePaymentMethod}
              selectedPolicy={policyInformation?.PolicyNumber
                || policyNumber}
            >
              <span className="Payment-method-card-button-details">
                {t('billingAndPayments.edit')}
              </span>
            </OneIncModal>
          </div>
        </div>
      )}

      {!billingDetailsValidation && validPolicies && (
        <OneIncModal
          buttonClass="Billing-info-screen-button-add-method"
          onUpdatePaymentMethod={onUpdatePaymentMethod}
          selectedPolicy={policyInformation?.PolicyNumber || ''}
        >
          <p>
            <img
              alt=""
              className="Billing-info-screen-button-image"
              src={addIconBlue}
            />
            {t('billingDetailsCard.addPaymentMethod')}
          </p>
        </OneIncModal>
      )}
    </>
  );
};

export default PaymentMethodCard;
