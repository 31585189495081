import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Shimmer from '../../common/Shimmer';
import './css/RenewalFloatingBarTop.css';

const RenewalFloatingBarTop = () => {
  const store = useSelector(({ policies }) => ({ ...policies.changeCoverage }));
  const { rate: { isAnnual }, quoteSelectedValues } = store;
  const { t } = useTranslation('changeCoverage');

  const totalPrice = useMemo(() => {
    const price = isAnnual
      ? quoteSelectedValues.total : quoteSelectedValues.totalMonthly;
    const annual = isAnnual ? quoteSelectedValues.totalAnnual : 0;

    if (!price) {
      return { annual: '$0.00', monthly: '$0.00' };
    }

    return {
      annual: `$${annual}`,
      monthly: `$${price}`,
    };
  }, [isAnnual, quoteSelectedValues]);

  return (
    <div className="Renewal-floating-bar-top-background">
      {store.loading
        ? (<Shimmer className="Renewal-floating-bar-top-container" />)
        : (
          <div className="Renewal-floating-bar-top-container">
            <span className="Renewal-floating-bar-top-premium">
              {isAnnual
                ? t('options.annualPremium')
                : t('options.monthlyPremium')}
            </span>

            <span className="Renewal-floating-bar-top-amount">
              {isAnnual ? totalPrice.annual : totalPrice.monthly}
            </span>
          </div>
        )}
    </div>
  );
};

export { RenewalFloatingBarTop };
