import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { loadPolicyDetails } from '../../../actions';

import Shimmer from '../../common/Shimmer';

import { PaymentHistoryPolicyCard } from './PaymentHistoryPolicyCard';

import { getDateBasicFormat } from '../../../services/utils';

import { MY_ACCOUNT } from '../../../constants';

import './css/PaymentHistoryScreen.css';

const PaymentHistoryPolicies = ({
  breadCrumbs,
  loading = true,
  policies = [],
}) => {
  const { t } = useTranslation('myAccount');
  const dispatch = useDispatch();

  function formatDate(date) {
    if (!date) {
      return t('na');
    }

    return getDateBasicFormat(date.split('T')[0]);
  }

  const onClickCard = useCallback((policyNumber) => () => {
    if (breadCrumbs) {
      breadCrumbs.onAddBreadcrumbItem({
        id: MY_ACCOUNT.paymentHistoryList,
        name: t('breadCrumbs.breadCrumbPolicy', {
          policyNumber,
        }),
      });
    }

    dispatch(loadPolicyDetails(policyNumber));
  }, [breadCrumbs]);

  return (
    <div className="Payment-history-screen-policies-container">
      <h2>{t('paymentHistorySection.insurancePolicies')}</h2>

      <div className="Payment-history-screen-policies-card-container">
        <Shimmer
          element="fragment"
          height={56}
          visible={loading || policies.length === 0}
          width={940}
        >
          {policies.map(({
            CoverageStartDate,
            ExpirationDate,
            Number,
            Status,
          }) => (
            <PaymentHistoryPolicyCard
              key={String(Number)}
              expirationDate={formatDate(ExpirationDate)}
              onClick={onClickCard(Number)}
              policyNumber={Number}
              startDate={formatDate(CoverageStartDate)}
              status={Status}
            />
          ))}
        </Shimmer>
      </div>
    </div>
  );
};

export { PaymentHistoryPolicies };
