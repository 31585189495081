import {
  POWER_UPS_RELATION,
  PRODUCT_VALUES,
} from './powerups/powerups.constans';

const {
  WELLNESS_PSM_ID,
} = POWER_UPS_RELATION;

function isChangeCoverage() {
  const path = window.location.href;

  return path.includes('changecoverage');
}

function isProductFPI(productId) {
  const { FPI_VALUES } = PRODUCT_VALUES;

  return FPI_VALUES.indexOf(productId) >= 0;
}

function isWellnessFPI(powerUp, insuranceProduct) {
  const productId = insuranceProduct.Id;
  return powerUp.PMSModifierId === WELLNESS_PSM_ID && isProductFPI(productId);
}

function toTruncate(value) {
  return Math.trunc((value * 100)) / 100;
}

export { isChangeCoverage, isProductFPI, isWellnessFPI, toTruncate };
