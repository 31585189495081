import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ScrollableDiv from '../common/ScrollableDiv';
import selectorIcon from '../../assets/myPets/drop-down-arrow.svg';
import { removeEmojis } from '../../services/utils';
import './FigoSelectSearchable.css';

const SPACE_KEY = 32;
const DOWN_KEY = 40;
const LEFT_KEY = 37;
const RIGHT_KEY = 39;
const UP_KEY = 38;
const INPUT_NODE = 'INPUT';

function handleKeyDown(event) {
  const ignoredKeys = [DOWN_KEY, LEFT_KEY, RIGHT_KEY,
    UP_KEY];
  if (event.target.nodeName !== INPUT_NODE) {
    ignoredKeys.push(SPACE_KEY);
  }
  if (ignoredKeys.indexOf(event.keyCode) > -1) {
    event.preventDefault();
  }
}

const FigoSelectSearchable = ({
  ariaProps,
  arrowIcon = null,
  classNameContainer = '',
  classNameHeader = '',
  disabled = false,
  elementDisplayName = '',
  elementValue = '',
  errorMessage = '',
  iconClassName = '',
  items = [],
  onBlur = () => { },
  onSelectionChanged,
  placeholder = '',
  placeholderClassname = '',
  selectedValue,
  title = '',
}) => {
  const [open, setOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [itemsFiltered, setItemsFiltered] = useState([]);
  const [itemSelectedId, setItemSelectedId] = useState(1);
  const [itemSelectedName, setItemSelectedName] = useState('');
  const searchRef = useRef(null);
  const dropdownHeaderRef = useRef(null);
  const selectedItemRef = useRef(null);
  const containerItemRef = useRef(null);
  const { t } = useTranslation('common');

  useEffect(() => {
    setItemsFiltered(items);
  }, [items]);

  useEffect(() => {
    if (open) {
      if (searchRef.current) {
        searchRef.current.focus();
      }

      window.addEventListener('keydown', handleKeyDown, true);
    } else {
      if (searchRef.current) {
        searchRef.current.focus();
      }

      if (dropdownHeaderRef.current) {
        dropdownHeaderRef.current.focus();
      }
    }

    return () => {
      if (open) {
        window.removeEventListener('keydown', handleKeyDown, true);
      }
    };
  }, [open]);

  useEffect(() => {
    setSearchInput('');
  }, []);

  function scrollItemListToView() {
    if (selectedItemRef.current) {
      containerItemRef.current.scrollTo(
        0, selectedItemRef.current.offsetTop - 5,
      );
    }
  }

  useEffect(() => {
    scrollItemListToView();
  }, [itemSelectedId, setItemSelectedId]);

  function filterItems(inputSearch) {
    const breedDataFiltered =
      items.filter((element) => element[elementDisplayName]
        .toUpperCase().includes(inputSearch.toUpperCase()));

    setItemsFiltered(breedDataFiltered);

    if (breedDataFiltered[0]) {
      setItemSelectedId(breedDataFiltered[0].Id);
      setItemSelectedName(breedDataFiltered[0].Name);
      containerItemRef.current.scrollTo(0, 0);
    }
  }

  function toggle() {
    setOpen(!open);
  }

  function handleDropDownContainer() {
    if (!disabled) {
      toggle();
    }
  }

  const handleOnClickItem = (item) => (event) => {
    if (event && event.type === 'click') {
      toggle();
    }

    onSelectionChanged(item);
  };

  const handleOnKeyPressItem = (item) => (event) => {
    const { key } = event;
    if (key === 'Enter' || key === ' ') {
      handleOnClickItem(item)();
    }
  };

  function handleSearchInputChanged(event) {
    const value = removeEmojis(event.target.value);
    setSearchInput(value);
    filterItems(value.trim());
  }

  const handleArrowKeyPressed = () => (event) => {
    const { keyCode } = event;
    const ENTER_KEY = 13;
    const ESC_KEY = 27;
    const TAB_KEY = 9;

    switch (keyCode) {
      case UP_KEY:
      case DOWN_KEY: {
        const factor = keyCode === UP_KEY ? -1 : 1;
        const temp = itemsFiltered
          .findIndex((element) => element.Id === itemSelectedId);
        if (itemsFiltered[temp + factor]) {
          const selection = itemsFiltered[temp + factor];
          setItemSelectedId(selection.Id);
          setItemSelectedName(selection.Name);
        }

        break;
      }

      case ENTER_KEY: {
        const item = itemsFiltered
          .find((element) => element.Id === itemSelectedId);

        if (item && open) {
          handleOnClickItem(item)();
          toggle();
        }

        break;
      }

      case ESC_KEY: {
        setOpen(false);
        if (dropdownHeaderRef.current) {
          dropdownHeaderRef.current.focus();
        }

        break;
      }

      case TAB_KEY: {
        setOpen(false);

        break;
      }

      default: {
        break;
      }
    }
  };

  function handleOutsideClick() {
    setOpen(false);
  }

  function renderHeaderDropdown() {
    if (!open) {
      const headerValue = selectedValue[elementDisplayName] || placeholder;

      return (
        <p className={`${!selectedValue[elementDisplayName]
          ? placeholderClassname : ''}`}
        >
          {headerValue}
        </p>
      );
    }

    return (
      <input
        ref={searchRef}
        aria-autocomplete="list"
        className="Figo-select-search-input"
        onChange={handleSearchInputChanged}
        onKeyDown={handleArrowKeyPressed()}
        placeholder={placeholder || t('figoSelectSearchable.search')}
        value={searchInput}
      />
    );
  }

  const ItemList = ({ item }) => (
    <li
      ref={item.Id === itemSelectedId ? selectedItemRef : null}
      aria-selected="false"
      className="Figo-select-list-item-container"
      id={`dropdownItem${item.Id}`}
      onClick={handleOnClickItem(item)}
      onKeyDown={handleArrowKeyPressed()}
      onKeyPress={handleOnKeyPressItem(item)}
      role="option"
      tabIndex={-1}
    >
      <span
        className={`Figo-select-list-item ${item.Id === itemSelectedId
          && item.Name === itemSelectedName
          && 'Figo-select-list-item-selected'}`}
        tabIndex={-1}
      >
        {item[elementDisplayName]}
      </span>
    </li>
  );

  const ItemListNotFound = () => (
    <li key={0} className="Figo-select-list-item-container">
      <span className="Figo-select-list-item">
        <span>{t('figoSelectSearchable.resultsNotFound')}</span>
      </span>
    </li>
  );

  function renderItems() {
    if (!open) {
      return null;
    }

    const resultsNotFound = !itemsFiltered || itemsFiltered.length === 0;

    return (
      <ScrollableDiv
        className={`Figo-select-list ${!open ? 'Figo-select-list-hidden' : ''}`}
        divRef={containerItemRef}
      >
        <div
          aria-hidden="true"
          className="Figo-select-list-overlayed-container"
          id="dropdownAddPet"
          onClick={handleOutsideClick}
          role="button"
          tabIndex={0}
        />

        <ul aria-labelledby="addPetDropdownContainer">
          {resultsNotFound ? <ItemListNotFound />
            : itemsFiltered
              .map((item) => (
                <ItemList
                  key={`${item[elementValue]}${item.Name}`}
                  item={item}
                />
              ))}
        </ul>
      </ScrollableDiv>
    );
  }

  const renderErrorMessage = () => (
    <span aria-live="polite" className="Figo-select-header-error-message">
      {errorMessage}
    </span>
  );

  return (
    <div className={`Figo-select-container ${errorMessage
      && 'Figo-select-container-error Figo-select-container-error-safari '}
        ${classNameContainer}`}
    >
      <p className="form-label">{title}</p>

      <button
        {...ariaProps}
        aria-expanded={open}
        className={'Figo-select-header '
          + `${errorMessage && 'Figo-select-header-error'}`
          + ` ${classNameHeader || ''}`}
        disabled={disabled}
        id="selectorContainer"
        onBlur={onBlur}
        onClick={handleDropDownContainer}
        title={`${selectedValue} selected`}
        type="button"
      >
        <div className={'Figo-select-selected-item '
          + `${disabled ? 'Figo-select-selected-item-disabled' : ''}`}
        >
          {renderHeaderDropdown()}
        </div>

        <div
          className="Figo-select-arrow-container"
          id="arrowContainer"
        >
          <img
            alt="arrow"
            aria-hidden="true"
            className={`Figo-select-arrow-closed ${iconClassName} `
              + `${open ? 'Figo-select-arrow-open' : ''}`}
            src={arrowIcon || selectorIcon}
          />
        </div>
      </button>

      {renderItems()}

      {renderErrorMessage()}
    </div>
  );
};

export { FigoSelectSearchable };
