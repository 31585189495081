import React, { useMemo } from 'react';

import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  useHistory,
} from 'react-router-dom';

import onError from '../../../assets/on-error-icon.svg';
import onSuccess from '../../../assets/on-success-icon.svg';

import './css/InsuranceRequestChangeBanner.css';

const InsuranceRequestChangeBanner = ({ onContactUsClick }) => {
  const { t } = useTranslation('myAccount');
  const history = useHistory();

  const store = useSelector(({ session, personalInformation }) => ({
    personalInformation, session,
  }), shallowEqual);

  const email = useMemo(() => {
    if (!store.session.customerInformation) {
      return '';
    }

    return store.session.customerInformation.email;
  }, [store.session.customerInformation]);

  const isRequestSubmitted =
  !store.personalInformation.errorUpdateInsuranceInformation;

  const icon = isRequestSubmitted ? onSuccess : onError;

  const title = isRequestSubmitted
    ? t('insuranceAccountSection.requestSubmitted')
    : t('insuranceAccountSection.somethingWentWrong');

  const description = isRequestSubmitted
    ? t('insuranceAccountSection.requestSubmittedDescription')
    : t('insuranceAccountSection.somethingWentWrongDescription');

  const button = isRequestSubmitted
    ? t('insuranceAccountSection.returnToMyAccount')
    : t('insuranceAccountSection.contactUs');

  const onClick = () => {
    if (isRequestSubmitted) {
      history.push('/myAccount');
    } else {
      onContactUsClick();
    }
  };

  return (
    <div className="Insurance-request-change-container">
      <div className="Insurance-request-change-inner-container">
        <img alt="" src={icon} />

        <h2 className="Insurance-request-change-title">{title}</h2>

        <p className="Insurance-request-change-description">
          {description}

          {isRequestSubmitted && (
            <>
              <br />

              <span>{email}</span>
            </>
          )}
        </p>

        <button
          className="Insurance-request-change-button"
          onClick={onClick}
          type="button"
        >
          {button}
        </button>
      </div>
    </div>
  );
};

export { InsuranceRequestChangeBanner };
