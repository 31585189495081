import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  displayEmailChangeModal,
  myAccountHasChanges,
  refreshPersonalInformation,
  resetInfo,
  updateEmail,
  updatePetCloudEmail,
} from '../../../../actions';

import { GenericModal } from '../../../common/petCloud/GenericModal';
import { PetCloudInputAnimated }
from '../../../common/petCloud/PetCloudInputAnimated';

import visibleIcon from '../../../../assets/myAccount/password-visible.svg';
import hiddenIcon from '../../../../assets/myAccount/password-hidden.svg';
import errorIcon from '../../../../assets/myAccount/input-error-icon.svg';

import '../css/PetCloudEdit.css';

const ConfirmEmailChangesModal = ({
  emailValue,
  isPetCoudEmail = false,
  onClickCancel = () => { },
  showEmailChangeModal = false,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('myAccount');
  const store = useSelector(({ accountLogin, session }) => ({
    accountLogin,
    session,
  }), shallowEqual);
  const { accountLogin: {
    areEmailUpdated,
    arePetCloudEmailUpdated,
  } } = store;
  const [passwordValue, setPasswordValue] = useState('');
  const [updateError, setUpdateError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleVisiblePassword = useCallback(() => {
    setIsPasswordVisible(!isPasswordVisible);
  }, [isPasswordVisible]);

  const handleCancelAction = useCallback(() => {
    dispatch(displayEmailChangeModal({ showEmailChangeModal: false }));
    setPasswordValue('');
    setUpdateError('');
  }, []);

  function handleUpdateError(error) {
    setUpdateError(error);
    setIsLoading(false);
  }

  function handleUpdateSuccess() {
    dispatch(refreshPersonalInformation());
    dispatch(myAccountHasChanges({ pageHasChanges: false }));
    setIsLoading(false);
  }

  const handleConfirmAction = useCallback(() => {
    setIsLoading(true);

    if (isPetCoudEmail) {
      dispatch(updatePetCloudEmail({
        errorCallback: handleUpdateError,
        successCallback: handleUpdateSuccess,
      }));
    } else {
      dispatch(updateEmail({
        email: emailValue,
        errorCallback: handleUpdateError,
        password: passwordValue,
        successCallback: handleUpdateSuccess,
      }));
    }
  }, [emailValue, isPetCoudEmail, passwordValue]);

  const handleCloseSuccessModal = useCallback(() => {
    dispatch(displayEmailChangeModal({ showEmailChangeModal: false }));
    dispatch(resetInfo());
    onClickCancel();
  }, []);

  const handlePasswordOnChange = useCallback(({ target }) => {
    const { value } = target;

    setPasswordValue(value);
  }, []);

  function renderError() {
    if (updateError) {
      return (
        <div className="Pet-cloud-forgot-modal-error">
          <img alt="" src={errorIcon} />

          <span>{updateError}</span>
        </div>
      );
    }

    return null;
  }

  function renderModal() {
    if (areEmailUpdated || arePetCloudEmailUpdated) {
      return (
        <GenericModal
          containerClassName="Pet-cloud-confirm-email-modal-container"
          description={t('petCloudSection.emailEdit.successDescription')}
          handleOutClick={handleCloseSuccessModal}
          onPrimaryClick={handleCloseSuccessModal}
          primaryButtonText={t('petCloudSection.close')}
          show={showEmailChangeModal}
          title={t('petCloudSection.emailEdit.successTitle')}
        />
      );
    }

    return (
      <GenericModal
        containerClassName="Pet-cloud-confirm-email-modal-container"
        description={t('petCloudSection.emailEdit.modalDescription')}
        handleOutClick={handleCancelAction}
        loadingPrimary={isLoading}
        onPrimaryClick={handleConfirmAction}
        onSecondaryClick={handleCancelAction}
        primaryButtonDisabled={!passwordValue.length}
        primaryButtonText={t('petCloudSection.confirm')}
        secondaryButtonText={t('petCloudSection.cancel')}
        show={showEmailChangeModal}
        title={t('petCloudSection.emailEdit.modalTitle')}
      >
        <div className="Pet-cloud-confirm-email-container">
          <PetCloudInputAnimated
            error={renderError()}
            extraItemClass={updateError
              ? 'Pet-cloud-forgot-tooltip-extra-space' : ''}
            inputClass="Pet-cloud-password-input"
            label={t('petCloudSection.password')}
            labelClass="Pet-cloud-password-modal-label"
            onChange={handlePasswordOnChange}
            placeholder={t('petCloudSection.password')}
            type={isPasswordVisible ? 'text' : 'password'}
            value={passwordValue}
          >
            <button
              className="Like-span"
              onClick={handleVisiblePassword}
              type="button"
            >
              <img
                alt=""
                id="confirmPasswordHideButton"
                src={isPasswordVisible ? visibleIcon : hiddenIcon}
              />
            </button>
          </PetCloudInputAnimated>
        </div>
      </GenericModal>
    );
  }

  return renderModal();
};

export { ConfirmEmailChangesModal };
