import React from 'react';
import ModalContainer from './ModalContainer';
import closeIcon from '../../assets/close.svg';
import './ActionModal.css';
import LoadingButton from './LoadingButton';
import { InputFilesButton } from './inputFiles/InputFilesButton';

const ActionModal = ({
  acceptedFiles,
  cancelButtonText,
  children,
  className = '',
  confirmButtonText,
  customTitle = null,
  loading,
  onAddFiles = () => { },
  onCancel = () => { },
  onClose = () => { },
  onConfirm = () => { },
  onSuccess = () => { },
  saveButtonClass = '',
  saveButtonDisabled = false,
  show,
  showCloseButton = true,
  success,
  title,
  useInputFileButton = false,
}) => (
  <ModalContainer
    show={show}
  >
    <div className={`Action-modal-container Flex Flex-column ${className}`}>
      {showCloseButton && (
        <button
          className="Action-modal-close-modal"
          onClick={onClose}
          type="button"
        >
          <img alt="" src={closeIcon} />
        </button>
      )}

      {customTitle || <h2 className="Action-modal-title">{title}</h2>}

      {children}

      <div
        className={`Action-modal-buttons${cancelButtonText ? '' : '-one'} Flex`}
      >
        {cancelButtonText && (
          <button
            className={`Action-modal-secondary-button Action-modal-button btn 
          ${loading ? 'Action-modal-button-disabled' : ''}`}
            onClick={onCancel}
            type="button"
          >
            {cancelButtonText}
          </button>
        )}

        {useInputFileButton ? (
          <InputFilesButton
            acceptFiles={acceptedFiles}
            className={'Action-modal-confirm-button Action-modal-button '
              + `${saveButtonClass}`}
            isDisabled={saveButtonDisabled}
            multiple
            onAddFiles={onAddFiles}
          >
            <span className="No-clicked">{confirmButtonText}</span>
          </InputFilesButton>
        )
          : (
            <LoadingButton
              className={'Action-modal-confirm-button Action-modal-button '
                + `${saveButtonClass} Action-modal-button-blue`}
              disabled={saveButtonDisabled}
              loading={loading}
              onClick={onConfirm}
              onSuccessAnimationEnd={onSuccess}
              success={success}
              text={confirmButtonText}
              type="button"
            />
          )}
      </div>
    </div>
  </ModalContainer>
);

export { ActionModal };
