import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import NavigationPrompt from 'react-router-navigation-prompt';

import { PetCloudAccountRow } from './PetCloudAccountRow';

import { useMyAccountInfo } from '../hooks/useMyAccountInfo';

import { GenericModal } from '../../common/petCloud/GenericModal';
import {
  ImageEditorMyPets,
} from '../../common/imageEditorMyPets/ImageEditorMyPets';

import './css/PetCloudAccountScreen.css';

const PetCloudAccountScreen = () => {
  const { t } = useTranslation('myAccount');
  const primaryUnsavedButtonText =
    t('petCloudSection.unsavedChangesModal.primaryButton');
  const secondaryUnsavedButtonText =
    t('petCloudSection.unsavedChangesModal.secondaryButton');
  const {
    firstName,
    fullName,
    handleCancelModal,
    handleDiscardModal,
    handleChangePictureSuccess,
    handleCloseImageEditor,
    handleEditingPCEmail,
    handleEmailEditing,
    handleNameEditing,
    handleRemovePicture,
    handleSavePicture,
    handleShowImageEditor,
    isEditingAny,
    isEditingEmail,
    isEditingName,
    isEditingPCEmail,
    isPhotoError,
    isPhotoSaved,
    isSavingPhoto,
    lastName,
    loginEmail,
    nameInitials,
    pageHasChanges,
    petCloudEmail,
    profileUrl,
    resetEditFields,
    showImageEditor,
    showUnsavedModal,
  } = useMyAccountInfo();

  function shouldPromptWarning(_crntLocation, nextLocation) {
    const shouldPrompt = !nextLocation.pathname
      .includes(t('petCloudSection.petCloudCard.title')) && pageHasChanges;

    const hasError = nextLocation.pathname === '/error';

    return shouldPrompt && !hasError;
  }

  const handleCancelAction = useCallback((onCancel) => () => {
    handleCancelModal();

    if (onCancel && typeof onConfirm === 'function') {
      onCancel();
    }
  }, []);

  function renderNavigationPrompt(props) {
    const { onCancel, onConfirm } = props;

    return (
      <GenericModal
        description={t('petCloudSection.unsavedChangesModal.description')}
        handleOutClick={handleCancelAction(onCancel)}
        isDeleteAction
        onPrimaryClick={handleDiscardModal(onConfirm)}
        onSecondaryClick={handleCancelAction(onCancel)}
        primaryButtonText={primaryUnsavedButtonText}
        secondaryButtonText={secondaryUnsavedButtonText}
        show={pageHasChanges}
        title={t('petCloudSection.unsavedChangesModal.title')}
      />
    );
  }

  return (
    <div className="Pet-cloud-account-container">
      <h1 className="Pet-cloud-account-title">{t('petCloudSection.title')}</h1>

      <p className="Pet-cloud-account-description">
        {t('petCloudSection.description')}
      </p>

      <div className="Pet-cloud-account-info-container">
        <PetCloudAccountRow
          actionLabel={profileUrl
            ? t('petCloudSection.editPhoto')
            : t('petCloudSection.upload')}
          initialValue={nameInitials}
          isEditingAny={isEditingAny}
          isProfilePicture
          label={t('petCloudSection.profilePicture')}
          onClickActionButton={handleShowImageEditor}
          profilePicture={profileUrl}
        />

        <PetCloudAccountRow
          actionLabel={t('petCloudSection.edit')}
          initialValue={isEditingName ? { firstName, lastName } : fullName}
          isEditing={isEditingName}
          isEditingAny={isEditingAny}
          label={t('petCloudSection.name')}
          onClickActionButton={handleNameEditing}
          onClickCancel={resetEditFields}
        />

        <PetCloudAccountRow
          actionLabel={t('petCloudSection.edit')}
          initialValue={loginEmail}
          isEditing={isEditingEmail}
          isEditingAny={isEditingAny}
          label={t('petCloudSection.email')}
          onClickActionButton={handleEmailEditing}
          onClickCancel={resetEditFields}
        />

        <PetCloudAccountRow
          actionLabel={t('petCloudSection.edit')}
          initialValue={petCloudEmail}
          isEditing={isEditingPCEmail}
          isEditingAny={isEditingAny}
          label={t('petCloudSection.petCloudEmail')}
          onClickActionButton={handleEditingPCEmail}
          onClickCancel={resetEditFields}
        />
      </div>

      <NavigationPrompt
        disableNative={false}
        when={shouldPromptWarning}
      >
        {renderNavigationPrompt}
      </NavigationPrompt>

      <GenericModal
        description={t('petCloudSection.unsavedChangesModal.description')}
        handleOutClick={handleCancelAction()}
        isDeleteAction
        onPrimaryClick={handleDiscardModal()}
        onSecondaryClick={handleCancelAction()}
        primaryButtonText={primaryUnsavedButtonText}
        secondaryButtonText={secondaryUnsavedButtonText}
        show={showUnsavedModal}
        title={t('petCloudSection.unsavedChangesModal.title')}
      />

      <ImageEditorMyPets
        error={isPhotoError}
        imageHeight={300}
        imageWidth={300}
        initialPicture={profileUrl}
        isSaving={isSavingPhoto}
        onClose={handleCloseImageEditor}
        onRemove={handleRemovePicture}
        onSave={handleSavePicture}
        onSuccess={handleChangePictureSuccess}
        photoType={t('petCloudSection.profileType')}
        round
        saveSuccess={isPhotoSaved}
        show={showImageEditor}
        title={profileUrl
          ? t('petCloudSection.editProfilePicture')
          : t('petCloudSection.addProfilePicture')}
      />
    </div>
  );
};

export { PetCloudAccountScreen };
