import React, { useCallback, useMemo } from 'react';

import './ActionButton.css';
import LoadingButton from '../LoadingButton';

const ActionButton = ({
  disabled = false,
  className = '',
  isDeleteButton = false,
  isSecondaryButton = false,
  leftIcon = null,
  leftIconClassName = '',
  loading = false,
  noMargin = false,
  onClick = () => { },
  onSuccess = () => { },
  success = false,
  title = '',
}) => {
  const style = useMemo(() => {
    let styleName = 'Action-button';
    let styleNameText = '';

    if (isDeleteButton) {
      styleName = 'Action-button-red';
    }

    if (isSecondaryButton) {
      styleName = 'Action-button-white';
    }

    styleNameText = `${styleName}-text${disabled ? '-disabled' : ''}`;

    return {
      button: `${styleName} ${noMargin ? 'Action-button-no-margin' : ''}`,
      text: styleNameText,
    };
  }, [disabled, isDeleteButton, isSecondaryButton]);

  const renderLeftIcon = useCallback(() => {
    if (leftIcon) {
      return (
        <img alt="" className={leftIconClassName} src={leftIcon} />
      );
    }

    return null;
  }, [leftIcon, leftIconClassName]);

  const text = useMemo(() => (
    <>
      {renderLeftIcon()}

      {title}
    </>
  ), [renderLeftIcon, title]);

  return (
    <LoadingButton
      className={`${style.button} ${className}`}
      disabled={disabled}
      loading={loading}
      onClick={onClick}
      onSuccessAnimationEnd={onSuccess}
      submit={false}
      success={success}
      text={text}
      textStyle={style.text}
    />
  );
};

export { ActionButton };
