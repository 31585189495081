import React from 'react';
import { useTranslation } from 'react-i18next';
import familyPicturePlaceholder
from '../../../assets/myPets/family-picture-placeholder.svg';
import familyPicturePlaceholderMobile
from '../../../assets/myPets/family-picture-placeholder-mobile.svg';
import { UploadPhotoButton } from '../../common/UploadPhotoButton';
import './PetProfileFamilyPictureMyPets.css';

const MOBILE_BREAKPOINT_WIDTH = 929;

const PetProfileFamilyPictureMyPets = ({ onClick, image }) => {
  const { t } = useTranslation('myPets');

  const picturePlaceholder = window.innerWidth > MOBILE_BREAKPOINT_WIDTH
    ? familyPicturePlaceholder : familyPicturePlaceholderMobile;

  return (
    <div className={image
      ? 'My-pets-profile-family-picture-container'
      : 'My-pets-profile-family-placeholder'}
    >
      <img
        alt="Family"
        className={image
          ? 'My-pets-profile-family-image'
          : 'My-pets-profile-family-image-placeholder'}
        src={image || picturePlaceholder}
      />

      <UploadPhotoButton
        className="My-pets-profile-family-upload-photo-styles"
        iconOnly
        isProfilePicture={false}
        onClick={onClick}
        placeholder={!image}
        text={t('PetProfile.uploadPicture')}
      />
    </div>
  );
};

export { PetProfileFamilyPictureMyPets };
