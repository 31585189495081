import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { contactPetFamily, showGenericError } from '../../actions';

import {
  maskPhoneNumber,
  removeEmojis,
  validateNumbersOnly,
} from '../../services/utils';

import LoadingButton from '../common/LoadingButton';
import { FoundLostPetContactFormInput } from './FoundLostPetContactFormInput';
import { FoundLostPetPetInformation } from './FoundLostPetPetInformation';

import './FoundLostPetContactForm.css';

const MAX_NAME = 60;
const MAX_PHONE = 10;
const MAX_MESSAGE = 500;

const FoundLostPetContactForm = () => {
  const { t } = useTranslation('foundLostPet');
  const store = useSelector(({ foundPetLost }) => (foundPetLost), shallowEqual);
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState('');
  const {
    isSendingMessage,
    lostPetTag,
    sendMessageAttempted,
    sendMessageSuccess,
  } = store;

  useEffect(() => {
    if (sendMessageAttempted) {
      if (!sendMessageSuccess) {
        dispatch(showGenericError(true));
      }
    }
  }, [sendMessageAttempted, sendMessageSuccess]);

  function onNameChanged(event) {
    const cleanValue = removeEmojis(event.target.value);
    if (cleanValue.trim().length <= MAX_NAME) {
      setName(cleanValue);
      setNameError('');
    }
  }

  function onPhoneChanged(event) {
    const { value } = event.target;
    const cleanText = value ? value.replace(/[()-\s]/g, '') : '';
    const onlyNumbersValue = validateNumbersOnly(cleanText)
      ? cleanText
      : cleanText.replace(/\D/g, '');

    if (onlyNumbersValue.length && onlyNumbersValue.length < MAX_PHONE) {
      setPhoneError(t('contactPetFamily.phoneError'));
    } else {
      setPhoneError('');
    }

    if (onlyNumbersValue.length <= MAX_PHONE) {
      setPhone(onlyNumbersValue);
    }
  }

  function onMessageChanged(event) {
    const cleanValue = removeEmojis(event.target.value);
    if (cleanValue.trim().length <= MAX_MESSAGE) {
      setMessage(cleanValue);
      setMessageError('');
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    let hasError = false;

    if (!name.length) {
      setNameError(t('requiredField'));
      hasError = true;
    }

    if (phoneError || phone.length < MAX_PHONE) {
      setPhoneError(t('contactPetFamily.phoneError'));
      hasError = true;
    }

    if (!message.length) {
      setMessageError(t('requiredField'));
      hasError = true;
    }

    if (hasError) {
      return;
    }

    dispatch(contactPetFamily({
      finderName: name,
      lostPetTag,
      message,
      phoneNumber: phone,
    }));
  }

  return (
    <form className="Found-lost-pet-contact-form-form" onSubmit={handleSubmit}>
      <span className="Found-lost-pet-contact-form-title">
        {t('contactPetFamily.title')}
      </span>

      <FoundLostPetPetInformation />

      <FoundLostPetContactFormInput
        customStyleClass=""
        error={nameError}
        maxLength={50}
        onChange={onNameChanged}
        placeholder={t('contactPetFamily.nameLabel')}
        value={name}
      />

      <FoundLostPetContactFormInput
        customInputType="numeric"
        customStyleClass=""
        error={phoneError}
        maxLength={50}
        onChange={onPhoneChanged}
        placeholder={t('contactPetFamily.phoneLabel')}
        value={maskPhoneNumber(phone)}
      />

      <FoundLostPetContactFormInput
        customStyleClass=""
        error={messageError}
        isMultiLineMessage
        maxLength={500}
        onChange={onMessageChanged}
        placeholder={t('contactPetFamily.messageLabel')}
        value={message}
      />

      <LoadingButton
        className={'Figo-lost-pet-found-rounded-button-base'
          + ' Found-lost-pet-contact-form-submit-button'}
        loading={isSendingMessage}
        text={t('contactPetFamily.textMyFamily')}
        type="submit"
      />
    </form>
  );
};

export { FoundLostPetContactForm };
