import React from 'react';
import { useTranslation } from 'react-i18next';
import NavigationPrompt from 'react-router-navigation-prompt';
import moment from 'moment';
import { useResponsive } from '../../../../hooks/useResponsive';
import {
  useDisableSubmit,
} from '../../../changeCoverage/changeCoverageOptions/useDisableSubmit';
import { possesiveAdaptationI18n } from '../../../../services/utils';

import { useCoverageOptions } from '../hooks/useCoverageOptions';

import {
  DeductibleReimbursement,
} from '../../../changeCoverage/sliders/DeductibleReimbursement';
import {
  PetCopayCoverageSection,
} from '../../../changeCoverage/changeCoverageOptions/PetCopayCoverageSection';
import {
  CardPowerupSection,
} from '../../../changeCoverage/powerups/CardPowerupSection';
import {
  ReviewChangesModal,
} from '../../../changeCoverage/reviewChangesModal/ReviewChangesModal';
import {
  RateErrorModal,
} from '../../../changeCoverage/changeCoverageOptions/RateErrorModal';
import {
  SubmitChanges,
} from '../../../changeCoverage/changeCoverageOptions/SubmitChanges';
import {
  ChangeCoveragePreviewSubmit,
} from '../changeCoverageSubmit/ChangeCoveragePreviewSubmit';
import { ChangeCoverageScreenFooter } from './ChangeCoverageScreenFooter';
import { ModifierModalInfo } from '../../modifiers/ModifierModalInfo';
import FigoLoadingOverlay from '../../../common/FigoLoadingOverlay';
import UnsavedChangesPrompt from '../../../common/UnsavedChangesPrompt';
import { usePetProtectCopay } from '../../../../hooks';

import '../css/ChangeCoverageScreenOptions.css';

const ChangeCoverageScreenOptions = ({
  onRateCreationError = () => { },
  policyNumber = '',
  validatePromptWarning = () => true,
}) => {
  const { t } = useTranslation('changeCoverage');
  const { isMobile } = useResponsive();
  const disableSubmit = useDisableSubmit();
  const {
    cleanUpRate,
    deductibleLegend,
    effectiveDate,
    handleAddPowerup,
    handleCloseMultiModal,
    handleCloseSubmit,
    handleErrorModalClose,
    handleGoBack,
    handlePetProtectCopayInfoClick,
    handleReviewChangesClick,
    handleVetFeesClick,
    handleWellnessClick,
    insuranceProduct,
    loading,
    loadingSubmit,
    multiModalCategoryId,
    multimodalDataSelected,
    multiModalVisible,
    onReviewChangesClosed,
    petInfo,
    petProtectCopayModifier,
    powerUpLoadings,
    quoteSelectedValues,
    rateErrorModalVisible,
    reviewChangesVisible,
    shouldPromptWarning,
    success,
  } = useCoverageOptions({
    onRateCreationError,
    policyNumber,
    validatePromptWarning,
  });
  const isPetProtectCopayActive = usePetProtectCopay();

  function formatDate(date) {
    return !date ? '-' : moment(date.split('T')[0]).format('MM/DD/YYYY');
  }

  function renderNavigationPrompt(props) {
    const { onConfirm, onCancel } = props;

    return (
      <UnsavedChangesPrompt
        message={t('options.promptMessage')}
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
    );
  }

  if (reviewChangesVisible) {
    return (
      <>
        <ChangeCoveragePreviewSubmit
          disableSubmit={disableSubmit}
          petInfo={petInfo}
          policyNumber={policyNumber}
        />

        <FigoLoadingOverlay visible={loadingSubmit} />

        <NavigationPrompt
          afterConfirm={cleanUpRate}
          disableNative={false}
          when={shouldPromptWarning}
        >
          {renderNavigationPrompt}
        </NavigationPrompt>
      </>
    );
  }

  return (
    <>
      <div className="Change-coverage-screen-options-container">
        <h3>
          {t('options.optionsTitle')}
        </h3>

        <p className="Change-coverage-screen-options-description">
          {t('options.instruction', {
            count: possesiveAdaptationI18n(petInfo.name),
            effDate: formatDate(effectiveDate),
            interpolation: {
              escapeValue: false,
            },
            name: petInfo.petName,
          })}
        </p>

        {!loading && (
          <DeductibleReimbursement
            deductibleLegend={deductibleLegend}
            plans={petInfo.Plans}
            quoteSelected={quoteSelectedValues}
          />
        )}

        {isPetProtectCopayActive && !loading && petProtectCopayModifier && (
          <PetCopayCoverageSection
            active={petProtectCopayModifier.IsSelected}
            howItWorksClick={handlePetProtectCopayInfoClick}
            modifierData={petProtectCopayModifier}
            onAddPowerup={handleAddPowerup}
            petQuote={petInfo}
          />
        )}

        {!loading && (
          <CardPowerupSection
            handleWellnessClick={handleWellnessClick}
            insuranceProduct={insuranceProduct}
            multiModalData={multimodalDataSelected}
            onAddPowerup={handleAddPowerup}
            onVetFeesClick={handleVetFeesClick}
            petName={petInfo.petName}
            petQuote={petInfo}
            powerUpLoadings={powerUpLoadings}
            quoteSelected={quoteSelectedValues}
          />
        )}

        <FigoLoadingOverlay visible={loading || !!powerUpLoadings.length} />

        <ReviewChangesModal
          disableSubmit={disableSubmit}
          onClose={onReviewChangesClosed}
          petInfo={petInfo}
          policyNumber={policyNumber}
          showModal={reviewChangesVisible}
        />

        {multimodalDataSelected && (
          <ModifierModalInfo
            initialMenuSelected={multiModalCategoryId}
            isVisible={multiModalVisible}
            isWhatsCovered
            multiModalData={multimodalDataSelected}
            onClose={handleCloseMultiModal}
            t={t}
          />
        )}

        {!loading && (
          <RateErrorModal
            onClose={handleErrorModalClose}
            tErrorText={t}
            visible={rateErrorModalVisible}
          />
        )}

        <NavigationPrompt
          afterConfirm={cleanUpRate}
          disableNative={false}
          when={shouldPromptWarning}
        >
          {renderNavigationPrompt}
        </NavigationPrompt>

        <SubmitChanges
          isVisible={success && !isMobile}
          onClose={handleCloseSubmit}
        />
      </div>

      <ChangeCoverageScreenFooter
        handleGoBack={handleGoBack}
        onReviewChangesClick={handleReviewChangesClick}
      />
    </>
  );
};

export { ChangeCoverageScreenOptions };
