import { handleActions } from 'redux-actions';
import { PASSWORD_ERROR } from '../constants';

const INITIAL_STATE = {
  areEmailUpdated: false,
  arePasswordsMatching: false,
  arePasswordsUpdated: false,
  arePetCloudEmailUpdated: false,
  confirmPassword: '',
  isEmailBeingUpdated: false,
  isEmailValid: true,
  isPasswordsBeingUpdated: false,
  isPetCloudEmailBeingUpdated: false,
  isPetCloudEmailValid: true,
  newPassword: '',
  newPasswordHasEightCharacters: false,
  newPasswordHasLetters: false,
  newPasswordHasNumbers: false,
  newPasswordHasSpaces: false,
  oldPassword: '',
  passwordUpdateError: null,
  passwordUpdateErrorType: PASSWORD_ERROR.none,
  petCloudEmail: '',
  petCloudEmailUpdateError: null,
  userInfo: null,
};
const EMAIL_REGEX =
  /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/;

export default handleActions({
  CONFIRM_PASSWORD_CHANGED: (state, { payload }) => ({
    ...state,
    arePasswordsMatching: payload === state.newPassword,
    arePasswordsUpdated: false,
    confirmPassword: payload,
    passwordUpdateError: null,
    passwordUpdateErrorType: PASSWORD_ERROR.none,
  }),
  DO_LOGOUT: () => ({ ...INITIAL_STATE }),
  EMAIL_CHANGED: (state, { payload }) => ({
    ...state,
    areEmailUpdated: false,
    email: payload,
    emailUpdateError: null,
    isEmailValid: EMAIL_REGEX.test(payload),
  }),
  EMAIL_UPDATED: (state) => ({
    ...state,
    areEmailUpdated: true,
    emailUpdateError: null,
    isEmailBeingUpdated: false,
    isEmailValid: true,
    userInfo: {
      ...state.userInfo,
      LoginEmail: state.email,
    },
  }),
  NEW_PASSWORD_CHANGED: (state, { payload }) => ({
    ...state,
    arePasswordsMatching: payload === state.confirmPassword,
    arePasswordsUpdated: false,
    newPassword: payload,
    newPasswordHasEightCharacters: payload.length >= 8,
    newPasswordHasLetters: /^(?=.*[a-zA-Z])/.test(payload),
    newPasswordHasNumbers: /\d/.test(payload),
    newPasswordHasSpaces: /\s/.test(payload),
    passwordUpdateError: null,
    passwordUpdateErrorType: PASSWORD_ERROR.none,
  }),
  OLD_PASSWORD_CHANGED: (state, action) => ({
    ...state,
    arePasswordsUpdated: false,
    oldPassword: action.payload,
    passwordUpdateError: null,
    passwordUpdateErrorType: PASSWORD_ERROR.none,
  }),
  PASSWORD_UPDATED: (state) => ({
    ...state,
    arePasswordsUpdated: true,
    confirmPassword: '',
    isPasswordsBeingUpdated: false,
    newPassword: '',
    newPasswordHasEightCharacters: false,
    newPasswordHasLetters: false,
    newPasswordHasNumbers: false,
    newPasswordHasSpaces: false,
    oldPassword: '',
    passwordUpdateError: null,
    passwordUpdateErrorType: PASSWORD_ERROR.none,
  }),
  PET_CLOUD_EMAIL_CHANGED: (state, { payload }) => ({
    ...state,
    arePetCloudEmailUpdated: false,
    isPetCloudEmailValid: EMAIL_REGEX.test(`${payload}@MyPetCloud.com`),
    petCloudEmail: payload,
    petCloudEmailUpdateError: null,
  }),
  PET_CLOUD_EMAIL_RESET: (state) => ({
    ...state,
    petCloudEmail: state.userInfo.PetCloudEmail
      ? state.userInfo.PetCloudEmail.split('@')[0]
      : '',
  }),
  PET_CLOUD_EMAIL_UPDATED: (state) => ({
    ...state,
    arePetCloudEmailUpdated: true,
    isPetCloudEmailBeingUpdated: false,
    isPetCloudEmailValid: true,
    petCloudEmailUpdateError: null,
    userInfo: {
      ...state.userInfo,
      PetCloudEmail: `${state.petCloudEmail}@MyPetCloud.com`,
    },
  }),
  RESET_EMAIL: (state) => ({
    ...state,
    email: state.userInfo.LoginEmail,
  }),
  RESET_INFO: (state) => ({
    ...state,
    areEmailUpdated: false,
    emailUpdateError: null,
    isEmailBeingUpdated: false,
  }),
  RESET_INFORMATION: (state) => ({
    ...INITIAL_STATE,
    confirmPassword: '',
    email: state.userInfo.LoginEmail,
    newPassword: '',
    oldPassword: '',
    petCloudEmail: state.userInfo.PetCloudEmail
      ? state.userInfo.PetCloudEmail.split('@')[0]
      : '',
    userInfo: state.userInfo,
  }),
  SAVE_USER_INFO: (state, { payload }) => ({
    ...state,
    email: payload.LoginEmail,
    petCloudEmail: payload.PetCloudEmail
      ? payload.PetCloudEmail.split('@')[0]
      : '',
    userInfo: payload,
  }),
  STARTUP_FROM_CACHE: (state, { payload }) => ({
    ...state,
    userInfo: payload.userInfo,
  }),
  UPDATE_EMAIL: (state) => ({
    ...state,
    areEmailUpdated: false,
    emailUpdateError: null,
    isEmailBeingUpdated: true,
  }),
  UPDATE_EMAIL_ERROR: (state, { payload }) => ({
    ...state,
    areEmailUpdated: false,
    emailUpdateError: payload,
    isEmailBeingUpdated: false,
    isEmailValid: false,
  }),
  UPDATE_PASSWORD: (state) => ({
    ...state,
    arePasswordsUpdated: false,
    isPasswordsBeingUpdated: true,
    passwordUpdateError: null,
    passwordUpdateErrorType: PASSWORD_ERROR.none,
  }),
  UPDATE_PASSWORD_ERROR: (state, { payload }) => ({
    ...state,
    arePasswordsUpdated: false,
    isPasswordsBeingUpdated: false,
    passwordUpdateError: payload.message,
    passwordUpdateErrorType: payload.type,
  }),
  UPDATE_PET_CLOUD_EMAIL: (state) => ({
    ...state,
    arePetCloudEmailUpdated: false,
    isPetCloudEmailBeingUpdated: true,
    petCloudEmailUpdateError: null,
  }),
  UPDATE_PET_CLOUD_EMAIL_ERROR: (state, { payload }) => ({
    ...state,
    arePetCloudEmailUpdated: false,
    isPetCloudEmailBeingUpdated: false,
    isPetCloudEmailValid: false,
    petCloudEmailUpdateError: payload,
  }),
}, INITIAL_STATE);
