/* eslint-disable max-len */
import { createActions } from 'redux-actions';

const actions = createActions({
  APPLY_ALL_POLICIES_CHECK_CHANGED: (data) => data,
  BILLING_INFO_LOAD_FAILED: (data) => data,
  BILLING_INFO_LOADED: (data) => data,
  BILLING_PAYMENT_RESETED: () => { },
  CHANGE_REIMBURSEMENT_ACCOUNT_NUMBER: (data) => data,
  CHANGE_REIMBURSEMENT_ACCOUNT_TYPE: (data) => data,
  CHANGE_REIMBURSEMENT_CONFIRM_ACCOUNT_NUMBER: (data) => data,
  CHANGE_REIMBURSEMENT_METHOD_TYPE: (data) => data,
  CHANGE_REIMBURSEMENT_ROUTING_NUMBER: (data) => data,
  CHANGE_ROUTING_NUMBER_ERROR: (data) => data,
  CLOSE_EDIT_REIMBURSEMENT_METHOD_MODAL: (data) => data,
  GET_ALL_BRANDS_DATA: () => {},
  GET_ALL_BRANDS_DATA_FAILED: () => {},
  GET_ALL_BRANDS_DATA_LOADED: (data) => (data),
  LOAD_PAYMENT_HISTORY: (data) => data,
  LOAD_PLAN_SUMMARY_DETAILS: (data) => data,
  LOAD_POLICY_DETAILS: (data) => data,
  LOAD_REIMBURSEMENT_METHOD: (data) => data,
  OPEN_EDIT_REIMBURSEMENT_METHOD_MODAL: (data) => data,
  PAYMENT_HISTORY_CLEARED: () => { },
  PAYMENT_HISTORY_LOAD_FAILED: (data) => data,
  PAYMENT_HISTORY_LOADED: (data) => data,
  PAYMENT_METHOD_ACCOUNT_NUMBER_CHANGED: (data) => data,
  PAYMENT_METHOD_ACCOUNT_NUMBER_CONFIRMATION_CHANGED: (data) => data,
  PAYMENT_METHOD_ACCOUNT_NUMBER_CONFIRMATION_ERROR_CHANGED: (data) => data,
  PAYMENT_METHOD_ACCOUNT_NUMBER_ERROR_CHANGED: (data) => data,
  PAYMENT_METHOD_ACCOUNT_TYPE_CHANGED: (data) => data,
  PAYMENT_METHOD_CARD_NUMBER_CHANGED: (data) => data,
  PAYMENT_METHOD_CARD_NUMBER_ERROR_CHANGED: (data) => data,
  PAYMENT_METHOD_CITY_CHANGED: (data) => data,
  PAYMENT_METHOD_CITY_ERROR_CHANGED: (data) => data,
  PAYMENT_METHOD_CVV_NUMBER_CHANGED: (data) => data,
  PAYMENT_METHOD_CVV_NUMBER_ERROR_CHANGED: (data) => data,
  PAYMENT_METHOD_DATA_CLEARED: () => { },
  PAYMENT_METHOD_EDIT_MODAL_VISIBLE_CHANGED: (data) => data,
  PAYMENT_METHOD_ERROR_MODAL_VISIBLE_CHANGED: (data) => data,
  PAYMENT_METHOD_EXPIRATION_DATE_CHANGED: (data) => data,
  PAYMENT_METHOD_EXPIRATION_DATE_ERROR_CHANGED: (data) => data,
  PAYMENT_METHOD_FIRST_NAME_CHANGED: (data) => data,
  PAYMENT_METHOD_FIRST_NAME_ERROR_CHANGED: (data) => data,
  PAYMENT_METHOD_LAST_NAME_CHANGED: (data) => data,
  PAYMENT_METHOD_LAST_NAME_ERROR_CHANGED: (data) => data,
  PAYMENT_METHOD_PAST_DUE_MODAL_VISIBLE_CHANGED: (data) => data,
  PAYMENT_METHOD_PAYMENT_METHOD_TYPE_CHANGED: (data) => data,
  PAYMENT_METHOD_ROUTING_NUMBER_CHANGED: (data) => data,
  PAYMENT_METHOD_ROUTING_NUMBER_ERROR_CHANGED: (data) => data,
  PAYMENT_METHOD_STATE_CHANGED: (data) => data,
  PAYMENT_METHOD_STATE_ERROR_CHANGED: (data) => data,
  PAYMENT_METHOD_STREET_ADDRESS_CHANGED: (data) => data,
  PAYMENT_METHOD_STREET_ADDRESS_ERROR_CHANGED: (data) => data,
  PAYMENT_METHOD_STREET_ADDRESS2_CHANGED: (data) => data,
  PAYMENT_METHOD_STREET_ADDRESS2_ERROR_CHANGED: (data) => data,
  PAYMENT_METHOD_SUCCESS_MODAL_VISIBLE_CHANGED: (data) => data,
  PAYMENT_METHOD_UPDATE_ATTEMPTED: () => { },
  PAYMENT_METHOD_UPDATED: (data) => data,
  PAYMENT_METHOD_ZIP_CODE_CHANGED: (data) => data,
  PAYMENT_METHOD_ZIP_CODE_ERROR_CHANGED: (data) => data,
  PET_SELECTED: (data) => data,
  POLICY_DETAILS_BENEFITS_LOADED: (data) => data,
  POLICY_DETAILS_LOAD_FAILED: (data) => data,
  POLICY_DETAILS_LOADED: (data) => data,
  POLICY_SELECTED: (data) => data,
  REIMBURSEMENT_METHOD_ATTEMPT: () => { },
  REIMBURSEMENT_METHOD_LOADED: (data) => data,
  REIMBURSEMENT_METHOD_ROUTING_VALIDATION_FAILED: (data) => data,
  REIMBURSEMENT_METHOD_UPDATE_FAILED: (data) => data,
  REIMBURSEMENT_METHOD_UPDATED: () => { },
  UPDATE_PAYMENT_METHOD: (data) => data,
  UPDATE_REIMBURSEMENT_METHOD: (data) => data,
});

export const {
  applyAllPoliciesCheckChanged,
  billingInfoLoaded,
  billingInfoLoadFailed,
  billingPaymentReseted,
  changeReimbursementAccountNumber,
  changeReimbursementAccountType,
  changeReimbursementConfirmAccountNumber,
  changeReimbursementMethodType,
  changeReimbursementRoutingNumber,
  changeRoutingNumberError,
  closeEditReimbursementMethodModal,
  loadPaymentHistory,
  loadPlanSummaryDetails,
  loadPolicyDetails,
  loadReimbursementMethod,
  getAllBrandsData,
  getAllBrandsDataFailed,
  getAllBrandsDataLoaded,
  openEditReimbursementMethodModal,
  paymentHistoryCleared,
  paymentHistoryLoaded,
  paymentHistoryLoadFailed,
  paymentMethodAccountNumberChanged,
  paymentMethodAccountNumberConfirmationChanged,
  paymentMethodAccountNumberConfirmationErrorChanged,
  paymentMethodAccountNumberErrorChanged,
  paymentMethodAccountTypeChanged,
  paymentMethodCardNumberChanged,
  paymentMethodCardNumberErrorChanged,
  paymentMethodCityChanged,
  paymentMethodCityErrorChanged,
  paymentMethodCvvNumberChanged,
  paymentMethodCvvNumberErrorChanged,
  paymentMethodDataCleared,
  paymentMethodEditModalVisibleChanged,
  paymentMethodErrorModalVisibleChanged,
  paymentMethodExpirationDateChanged,
  paymentMethodExpirationDateErrorChanged,
  paymentMethodFirstNameChanged,
  paymentMethodFirstNameErrorChanged,
  paymentMethodLastNameChanged,
  paymentMethodLastNameErrorChanged,
  paymentMethodPastDueModalVisibleChanged,
  paymentMethodPaymentMethodTypeChanged,
  paymentMethodRoutingNumberChanged,
  paymentMethodRoutingNumberErrorChanged,
  paymentMethodStateChanged,
  paymentMethodStateErrorChanged,
  paymentMethodStreetAddress2Changed,
  paymentMethodStreetAddress2ErrorChanged,
  paymentMethodStreetAddressChanged,
  paymentMethodStreetAddressErrorChanged,
  paymentMethodSuccessModalVisibleChanged,
  paymentMethodUpdateAttempted,
  paymentMethodUpdated,
  paymentMethodZipCodeChanged,
  paymentMethodZipCodeErrorChanged,
  petSelected,
  policyDetailsBenefitsLoaded,
  policyDetailsLoaded,
  policyDetailsLoadFailed,
  policySelected,
  reimbursementMethodAttempt,
  reimbursementMethodLoaded,
  reimbursementMethodRoutingValidationFailed,
  reimbursementMethodUpdated,
  reimbursementMethodUpdateFailed,
  updatePaymentMethod,
  updateReimbursementMethod,
} = actions;
