import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getRecentFiles } from '../../../actions';

import { RecentFile } from './RecentFile';
import Shimmer from '../../common/Shimmer';
import FigoLoadingOverlay from '../../common/FigoLoadingOverlay';
import { goToSelectedFolder } from '../../../services/inboxAndDocs';

import './RecentActivity.css';

const RecentActivity = ({
  breadcrumbs = {},
  folders = [],
}) => {
  const { t } = useTranslation('inboxAndDocs');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRecentFiles());
  }, [dispatch]);

  const store = useSelector(({
    inboxAndDocs,
  }) => ({
    inboxAndDocs,
  }), shallowEqual);

  const {
    downloadingFiles,
    isRecentFilesLoading,
    recentFiles,
  } = store.inboxAndDocs;

  const handleGoToFolder = (folderId) => () => {
    goToSelectedFolder({ breadcrumbs, folderContent: folders, folderId });
  };

  if (isRecentFilesLoading) {
    return <Shimmer className="Recent-activity-shimmer" />;
  }

  return (
    <div className="Recent-activity-container">
      <span className="Recent-activity-title">
        {t('recentActivity.title')}
      </span>

      {recentFiles.map((recentFile) => {
        const {
          DateAdded,
          DownloadUrl,
          Extension,
          FileName,
          FilePermissions,
          Id,
          IsReadOnly,
          ParentFolderId,
        } = recentFile;

        return (
          <RecentFile
            key={Id}
            dateAdded={DateAdded}
            fileExtension={Extension}
            fileInfo={{
              FilePermissions,
              Id,
              IsReadOnly,
              Metadata: { Extension, Url: DownloadUrl },
              Name: FileName,
            }}
            fileName={FileName}
            goToFolder={handleGoToFolder(ParentFolderId)}
            id={Id}
          />
        );
      })}

      <FigoLoadingOverlay
        message={downloadingFiles ? t('preparingFiles') : ''}
        visible={downloadingFiles}
      />
    </div>
  );
};

export { RecentActivity };
