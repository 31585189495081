import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { getThemeLogoById } from '../../../../theme/theme.utils';

import { ReimbursementDetailsFrame } from '../reimbursementDetails';
import { MARKETING_CHANNEL_ID } from '../../../../theme/theme.constants';

import '../css/ReimbursementMultibrandList.css';
import { EmptyScreen } from '../../../common/petCloud/EmptyScreen';

const ReimbursementMultibrandList = ({
  allBrandsData = [],
  onClickCard = () => { },
}) => {
  const { t } = useTranslation('myAccount');
  const [selectedPolicy, setSelectedPolicy] = useState({});
  const [petId, setPetId] = useState('');
  const [openReimbursementFrame, setOpenReimbursementFrame] = useState(false);

  function formatDate(date) {
    return !date ? '-' : moment(date.split('T')[0]).format('MM/DD/YYYY');
  }

  const policiesToShow = useMemo(() => allBrandsData
    .filter((policy) => policy.MarketingChannelId !== 0
      && policy.MarketingChannelId !== MARKETING_CHANNEL_ID.ppiOnePack),
  [allBrandsData]);

  function renderList() {
    if (!allBrandsData.length || !policiesToShow.length) {
      return (
        <EmptyScreen
          description={t('reimbursementSection.emptyScreen.description')}
          title={t('reimbursementSection.emptyScreen.title')}
        />
      );
    }

    return (
      <div className="Reimbursement-multibrand-cards-container">
        <p>{t('reimbursementSection.selectPolicy')}</p>

        {policiesToShow.map((policy) => {
          const startDate = formatDate(policy.PolicyStartDate);
          const endDate = formatDate(policy.PolicyEndDate);

          const handleOnClickCard = () => {
            if (policy.MarketingChannelId !== MARKETING_CHANNEL_ID.figo) {
              setPetId(policy.Pets[0].PetId);
              setSelectedPolicy({
                marketingChannelId: policy.MarketingChannelId,
                policyId: policy.PolicyId,
                policyNumber: policy.PolicyNumber,
              });
              setOpenReimbursementFrame(true);
              return;
            }
            onClickCard({
              policyInfo: {
                PolicyTerm: `${startDate} - ${endDate}`,
                ...policy,
              },
              policyNumber: policy.PolicyNumber,
            });
          };

          return (
            <div
              key={String(policy.PolicyId)}
              className="Reimbursement-multibrand-card"
            >
              <div className="Reimbursement-multibrand-left-content">
                <img
                  alt=""
                  src={getThemeLogoById(policy.MarketingChannelId).dark}
                />

                <div className="Reimbursement-multibrand-card-info-wrapper">
                  <div className="Reimbursement-multibrand-card-info-container">
                    <span className="Reimbursement-multibrand-card-info-title">
                      {t('reimbursementSection.policyNumber')}
                    </span>

                    <span className="Reimbursement-multibrand-card-info-value">
                      {policy.PolicyNumber}
                    </span>
                  </div>

                  <div className="Reimbursement-multibrand-card-info-container">
                    <span className="Reimbursement-multibrand-card-info-title">
                      {t('reimbursementSection.policyTerm')}
                    </span>

                    <span className="Reimbursement-multibrand-card-info-value">
                      {`${startDate} - ${endDate}`}
                    </span>
                  </div>

                  <div className="Reimbursement-multibrand-card-info-container">
                    <span className="Reimbursement-multibrand-card-info-title">
                      {t('reimbursementSection.petsOnThisPolicy')}
                    </span>

                    <span className="Reimbursement-multibrand-card-info-value">
                      {policy.Pets.map((pet) => pet.PetName).join(', ')}
                    </span>
                  </div>
                </div>
              </div>

              <button
                className="Reimbursement-multibrand-card-details-button"
                onClick={handleOnClickCard}
                type="button"
              >
                <span>{t('reimbursementSection.viewDetails')}</span>
              </button>
            </div>
          );
        })}
      </div>
    );
  }

  if (openReimbursementFrame) {
    return (
      <ReimbursementDetailsFrame
        petId={petId}
        policy={selectedPolicy}
      />
    );
  }

  return (
    <div className="Reimbursement-multibrand-screen-container">
      <h1>{t('reimbursementCard.title')}</h1>

      {renderList()}
    </div>
  );
};

export { ReimbursementMultibrandList };
