import React, { useEffect, useState } from 'react';
import { useDebounceSearch } from '../../../hooks';
import searchICon from '../../../assets/search-icon.svg';
import loadingBlue from '../../../assets/lottieFiles/loading-blue.json';
import ScrollableDiv from '../../common/ScrollableDiv';
import TouchableDiv from '../../common/TouchableDiv';
import FigoLottie from '../../common/FigoLottie';
import { removeEmojis } from '../../../services/utils';
import './SearchInput.css';

const SearchInput = ({
  emptyMessage = '',
  hideInput = false,
  id = '',
  isLoadingVets = true,
  itemListRender = () => { },
  itemSelected = null,
  itemsFound = [],
  onSearch = () => { },
  onSelected = () => { },
  renderSelectedItem = () => { },
}) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [itemsFiltered, setItemsFiltered] = useState([]);

  function handleInputChange(event) {
    const { value } = event.target;
    setInputValue(removeEmojis(value));
  }

  function handleInputFocus() {
    setOpen(true);
  }

  function closeDropdown() {
    setOpen(false);
  }

  const handleSelection = (item) => () => {
    onSelected(item);
  };

  const { searchResults } = useDebounceSearch(onSearch, 500);

  useEffect(() => {
    searchResults(inputValue);
  }, [inputValue]);

  useEffect(() => {
    setItemsFiltered(itemsFound);
  }, [itemsFound]);

  useEffect(() => {
    closeDropdown();
  }, [itemSelected]);

  useEffect(() => {
    if (hideInput) {
      setOpen(false);
    }
  }, [hideInput]);

  return (
    <div className="Search-input-wrapper">

      {itemSelected ? (
        <div>
          {renderSelectedItem()}
        </div>
      ) : (
        <div className="Search-input-container">
          <img
            alt="search"
            className="Veterinarian-search-icon"
            src={searchICon}
          />

          <input
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            value={inputValue}
          />
        </div>
      )}

      {open && (
        <div className="Search-input-dropdown-container">
          <ScrollableDiv className="Search-input-dropdown">
            <TouchableDiv
              className="Search-input-overlay"
              id={`searchInput${id}`}
              onClick={closeDropdown}
            />

            {isLoadingVets && <FigoLottie animationData={loadingBlue} />}

            {itemsFiltered.map((item) => (
              <button
                key={item.PlaceId || item.Title || item.Id}
                className="Search-input-list-item"
                id={item.Id}
                onClick={handleSelection(item)}
                type="button"
              >
                {itemListRender(item)}
              </button>
            ))}

          </ScrollableDiv>

          {!isLoadingVets && (
            <div className="Search-input-empty-message">
              {emptyMessage}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export { SearchInput };
