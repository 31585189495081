import { call, put } from 'redux-saga/effects';

import {
  oneIncDefaultsPaymentFailed,
  oneIncDefaultsPaymentLoaded,
  oneIncSessionCreated,
} from '../actions';
import { apiCallWithApiKey, get } from '../services/api';

export function* createOneIncSession(dispatch) {
  const response = yield call(
    apiCallWithApiKey,
    'api/Payments/getOneIncSessionId',
    {},
    dispatch,
  );

  if (response.IsValid) {
    const { portalOneSessionKey } = response.Data.Result;
    yield put(oneIncSessionCreated(portalOneSessionKey));
  }
}

export function* loadDefaultpayments(dispatch) {
  const response = yield call(get, dispatch, '/api/Billing/InfoByCustomer');

  if (response.IsValid) {
    const {
      BillingInfoList: billingInfoList,
      DefaultPaymentProfileIdACH: defaultTokenACH,
      DefaultPaymentProfileIdCC: defaultTokenCC,
    } = response.Data;
    const haveDefault = !!defaultTokenACH || !!defaultTokenCC;
    const isTwoDefaults = !!defaultTokenACH && !!defaultTokenCC;

    if (isTwoDefaults) {
      yield put(oneIncDefaultsPaymentLoaded({
        bankAccountDefault: billingInfoList
          .find((item) => item.PaymentProfileId === defaultTokenACH),
        billingInfoList,
        creditCardDefault: billingInfoList
          .find((item) => item.PaymentProfileId === defaultTokenCC),
        defaultTokenACH,
        defaultTokenCC,
        haveDefault,
        isTwoDefaults,
      }));
    } else {
      yield call(createOneIncSession, dispatch);
    }
  } else {
    yield put(oneIncDefaultsPaymentFailed());
  }
}
