import { handleActions } from 'redux-actions';

const WEB_VIEW_INITIAL_STATE = {
  isLoading: false,
  url: '',
  webViewType: -1,
};

const INITIAL_STATE = {
  webViewData: WEB_VIEW_INITIAL_STATE,
};

export default handleActions({
  WEB_VIEW_MULTIBRAND_ATTEMPT: (state) => ({
    ...state,
    webViewData: {
      ...WEB_VIEW_INITIAL_STATE,
      isLoading: true,
    },
  }),

  WEB_VIEW_MULTIBRAND_FAILED: (state) => ({
    ...state,
    webViewData: { ...WEB_VIEW_INITIAL_STATE },
  }),

  WEB_VIEW_MULTIBRAND_RESET: (state) => ({
    ...state,
    webViewData: { ...WEB_VIEW_INITIAL_STATE },
  }),

  WEB_VIEW_MULTIBRAND_SUCCESS: (state, action) => ({
    ...state,
    webViewData: {
      isLoading: false,
      url: action.payload.data,
      webViewType: action.payload.webViewType,
    },
  }),
}, INITIAL_STATE);
