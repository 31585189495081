import React from 'react';
import moment from 'moment';

import { useTranslation } from 'react-i18next';
import { useCoveragePreviewSubmit } from '../hooks/useCoveragePreviewSubmit';
import { possesiveAdaptationI18n } from '../../../../services/utils';
import { PET_TYPE_ID } from '../../../../constants';
import { CONTACT_US_STEPS }
from '../../../sideMenu/contactUs/contactUs.constants';

import { PreviewSubmitUpdatedPlan } from './PreviewSubmitUpdatedPlan';
import { PreviewSubmitNote } from './PreviewSubmitNote';
import { ChangeCoverageButtons } from '../ChangeCoverageButtons';

import { ContactUsModal } from '../../../sideMenu/contactUs/ContactUsModal';

import '../css/ChangeCoveragePreviewSubmit.css';

const { DOG } = PET_TYPE_ID;

const ChangeCoveragePreviewSubmit = ({
  disableSubmit = true,
  petInfo,
  policyNumber = '',
}) => {
  const { t } = useTranslation('changeCoverage');
  const {
    annualPrice,
    contactUsVisible,
    effectiveDate,
    fees,
    handleCancel,
    handleSubmit,
    initialCoverageValues,
    initialTotalPriceWithFees,
    isAnnual,
    loadingSubmit,
    monthlyPrice,
    onContactUsClick,
    onContactUsClose,
    powerUps,
    quoteSelectedValues,
    success,
    totalPriceWithFees,
  } = useCoveragePreviewSubmit({
    petInfo,
    policyNumber,
  });

  function formatDate(date) {
    return !date ? '-' : moment(date.split('T')[0]).format('MM/DD/YYYY');
  }

  return (
    <div className="Change-coverage-preview-submit-container">
      <h3>{t('preview.submitTitle')}</h3>

      <p>
        {t('preview.reviewLegend', {
          count: possesiveAdaptationI18n(petInfo.name),
          effDate: formatDate(effectiveDate),
          interpolation: {
            escapeValue: false,
          },
          name: petInfo.petName,
        })}
      </p>

      <PreviewSubmitUpdatedPlan
        annualPrice={annualPrice}
        fees={fees}
        initialCoverageValues={initialCoverageValues}
        initialTotalPriceWithFees={initialTotalPriceWithFees}
        isAnnual={isAnnual}
        isDog={petInfo.petType === DOG}
        monthlyPrice={monthlyPrice}
        petInfo={petInfo}
        powerUps={powerUps}
        quoteSelectedValues={quoteSelectedValues}
        totalPriceWithFees={totalPriceWithFees}
      />

      <PreviewSubmitNote onContactUsClick={onContactUsClick} />

      <ChangeCoverageButtons
        disabledPrimaryButton={disableSubmit}
        loadingPrimaryButton={loadingSubmit}
        onClickPrimaryButton={handleSubmit}
        onClickSecondaryButton={handleCancel}
        primaryText={t('reviewChangesModal.submitChanges')}
        secondaryText={t('preview.back')}
        successPrimaryButton={success}
      />

      <ContactUsModal
        companyStep={CONTACT_US_STEPS.FIGO}
        onClose={onContactUsClose}
        show={contactUsVisible}
      />
    </div>
  );
};

export { ChangeCoveragePreviewSubmit };
