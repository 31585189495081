import React from 'react';
import { useTranslation } from 'react-i18next';

import closeIcon from '../../../assets/close-button.svg';

import ModalContainer from '../../common/ModalContainer';
import ScrollableDiv from '../../common/ScrollableDiv';
import { ActionButton, PetCloudInput } from '../../common/petCloud';

import { usePetTagModal } from '../hooks/usePetTagModal';

import { maskPhoneNumber } from '../../../services/utils';

import './PetTagModal.css';

const PetTagModal = ({
  isVisible = false,
  petId = '',
}) => {
  const { t } = useTranslation('myPets');
  const messageToFinderMaxLength = 2000;

  const {
    addButtonDisabled,
    familyMemberInfo,
    handleFamilyMemberNameInput,
    handleFamilyMemberPhoneNumberInput,
    handleMessageToFinderInput,
    handleOnBlurPetTag,
    handlePetTagClose,
    handlePetTagInput,
    handleSavePetTagButton,
    isEditMode,
    isPetTagInfoRequestStarted,
    MessageToFinder,
    saveButtonDisabled,
    successPetTagPost,
    TagNumber,
    tagNumberErrorMessage,
  } = usePetTagModal({ petId });

  return (
    <ModalContainer show={isVisible}>
      <ScrollableDiv className="Pet-tag-modal-main-container" tabIndex={-1}>
        <div className="Pet-tag-modal-title-container">
          <h3>{t('PetTagModal.title')}</h3>

          <button
            className="Pet-tag-modal-close-icon-container"
            id="closeIconAddPetModal"
            onClick={handlePetTagClose}
            type="button"
          >
            <img
              alt="Close"
              className="Pet-tag-modal-close-icon"
              src={closeIcon}
            />
          </button>
        </div>

        <p className="Pet-tag-modal-description">
          {t('PetTagModal.description')}
        </p>

        <div className="Pet-tag-modal-content-container">
          <div className="Pet-tag-modal-tag-input-container">
            <PetCloudInput
              error={tagNumberErrorMessage}
              onBlur={handleOnBlurPetTag}
              onChange={handlePetTagInput}
              placeholder={t('PetTagModal.exampleTagNumber')}
              title={`${t('PetTagModal.tagNumberField')}*`}
              value={TagNumber}
            />
          </div>

          <PetCloudInput
            className="Pet-tag-modal-finder-input"
            isTextArea
            maxLength={messageToFinderMaxLength}
            onChange={handleMessageToFinderInput}
            placeholder={t('PetTagModal.exampleMessageFinder')}
            title={t('PetTagModal.messageToFinderField')}
            value={MessageToFinder}
          />

          <div className="Pet-tag-modal-row-container">
            <PetCloudInput
              className="Pet-tag-modal-contact-input"
              error={familyMemberInfo[0].nameErrorMessage}
              onChange={handleFamilyMemberNameInput(1)}
              placeholder={t('PetTagModal.fullName')}
              title={t('PetTagModal.emergencyContactField', { number: '1*' })}
              value={familyMemberInfo[0].name}
            />

            <PetCloudInput
              className="Pet-tag-modal-phone-input"
              error={familyMemberInfo[0].phoneNumberErrorMessage}
              errorClassName="Pet-tag-modal-phone-error"
              onChange={handleFamilyMemberPhoneNumberInput(1)}
              placeholder={t('PetTagModal.phoneNumber')}
              value={maskPhoneNumber(familyMemberInfo[0].phoneNumber)}
            />
          </div>

          <div className="Pet-tag-modal-row-container">
            <PetCloudInput
              className="Pet-tag-modal-contact-input"
              error={familyMemberInfo[1].nameErrorMessage}
              onChange={handleFamilyMemberNameInput(2)}
              placeholder={t('PetTagModal.fullName')}
              title={t('PetTagModal.emergencyContactField', { number: 2 })}
              value={familyMemberInfo[1].name}
            />

            <PetCloudInput
              className="Pet-tag-modal-phone-input"
              error={familyMemberInfo[1].phoneNumberErrorMessage}
              errorClassName="Pet-tag-modal-phone-error"
              onChange={handleFamilyMemberPhoneNumberInput(2)}
              placeholder={t('PetTagModal.phoneNumber')}
              value={maskPhoneNumber(familyMemberInfo[1].phoneNumber)}
            />
          </div>

          <div className="Pet-tag-modal-row-container">
            <PetCloudInput
              className="Pet-tag-modal-contact-input"
              error={familyMemberInfo[2].nameErrorMessage}
              onChange={handleFamilyMemberNameInput(3)}
              placeholder={t('PetTagModal.fullName')}
              title={t('PetTagModal.emergencyContactField', { number: 3 })}
              value={familyMemberInfo[2].name}
            />

            <PetCloudInput
              className="Pet-tag-modal-phone-input"
              error={familyMemberInfo[2].phoneNumberErrorMessage}
              errorClassName="Pet-tag-modal-phone-error"
              onChange={handleFamilyMemberPhoneNumberInput(3)}
              placeholder={t('PetTagModal.phoneNumber')}
              value={maskPhoneNumber(familyMemberInfo[2].phoneNumber)}
            />
          </div>
        </div>

        <div className={`Pet-tag-modal-footer-container
        ${familyMemberInfo[2].phoneNumberErrorMessage
          ? 'Pet-tag-modal-footer-container-margin' : ''}`}
        >
          <ActionButton
            disabled={isEditMode ? saveButtonDisabled : addButtonDisabled}
            loading={isPetTagInfoRequestStarted}
            onClick={handleSavePetTagButton}
            onSuccess={handlePetTagClose}
            success={successPetTagPost}
            title={isEditMode ? t('PetTagModal.saveButton')
              : t('PetTagModal.addButton')}
          />

          <ActionButton
            isSecondaryButton
            onClick={handlePetTagClose}
            title={t('PetTagModal.cancelButton')}
          />
        </div>
      </ScrollableDiv>
    </ModalContainer>
  );
};

export { PetTagModal };
