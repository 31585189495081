import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import { InsuranceRequestChangeBanner } from './InsuranceRequestChangeBanner';
import {
  PetCloudInputAnimated,
} from '../../common/petCloud/PetCloudInputAnimated';
import LoadingButton from '../../common/LoadingButton';

import {
  isEmailValid,
  removeEmojis,
  maskPhoneNumber,
  validateZipCode,
} from '../../../services/utils';
import {
  updateInsuranceInformation,
} from '../../../actions';
import {
  MY_ACCOUNT, MAX_ZIP_LENGTH,
  MAX_PHONE_LENGTH_MASK,
} from '../../../constants';

import Checkbox from '../../common/Checkbox';

import errorIcon from '../../../assets/myAccount/input-error-icon.svg';
import { getStateAbbreviation } from '../../../services/zipcode';
import { useSubmitRequestAccountChanges } from '../../../hooks/useFeatureFlags';

const labelToFieldsMap = {
  Address: ['Address', 'City', 'State', 'ZipCode'],
  Email: ['Email'],
  'Name on Account': ['FirstName', 'LastName'],
  'Phone Number': ['Phone'],
};

const SELECT_OPTION = 'Select';

const InsuranceAccountUpdateForm =
  ({ toggleUpdateForm, breadCrumbs = {}, onContactUsClick }) => {
    const { t } = useTranslation('myAccount');
    const dispatch = useDispatch();
    const store = useSelector(({ session, personalInformation }) => ({
      personalInformation, session,
    }), shallowEqual);
    const submitRequestChanges = useSubmitRequestAccountChanges();

    const email = useMemo(() => {
      if (!store.session.customerInformation) {
        return '';
      }

      return store.session.customerInformation.email;
    }, [store.session.customerInformation]);

    const companyId = useMemo(() => {
      if (breadCrumbs) {
        return breadCrumbs.navData
          .find((data) => data.id === MY_ACCOUNT.insuranceAccount).extraData;
      }

      return 1;
    }, [breadCrumbs]);

    const checkboxData = [
      {
        id: 1,
        isChecked: false,
        label: t('insuranceAccountSection.nameAccount'),
      },
      { id: 2, isChecked: false, label: t('insuranceAccountSection.address') },
      { id: 3, isChecked: false, label: t('insuranceAccountSection.email') },
      {
        id: 4,
        isChecked: false,
        label: t('insuranceAccountSection.phoneNumber'),
      },
    ];

    // TODO: this information will be pre-filled once the data source split up
    // the name, state, zipcode and other fields.
    const initialFormData = {
      Address: '',
      City: '',
      CompanyId: companyId,
      Email: '',
      FirstName: '',
      LastName: '',
      Phone: '',
      SecondAddress: '',
      SecondaryFirstName: '',
      SecondaryLastName: '',
      State: '',
      ZipCode: '',
    };

    const [checkboxes, setCheckboxes] = useState(checkboxData);
    const [formData, setFormData] = useState(initialFormData);
    const [emailError, setEmailError] = useState('');
    const [zipCodeError, setZipCodeError] = useState('');
    const [isPhoneValid, setIsPhoneValid] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    const loading = store.personalInformation.loadingUpdateInsuranceInformation;
    const areCheckboxesChecked = checkboxes.some((item) => item.isChecked);
    const isEmailDisabled = checkboxes[2]?.isChecked ? emailError : false;
    const isPhoneDisabled = checkboxes[3]?.isChecked ? !isPhoneValid : false;

    const updateButtonState = () => {
      const areAllInputsValid = checkboxes.every((item) => {
        if (item.isChecked) {
          const fieldsToValidate = labelToFieldsMap[item.label];

          return fieldsToValidate.every((field) => {
            if (field === 'Email') {
              return formData.Phone.length === MAX_PHONE_LENGTH_MASK
                ? true : formData[field] !== '';
            }

            if (field === 'State') {
              const currentFieldValue = formData[field];
              return currentFieldValue !== ''
                && currentFieldValue !== SELECT_OPTION;
            }

            return formData[field] !== '';
          });
        }

        return true;
      });

      setIsDisabled(!areAllInputsValid);
    };

    const handleCheckboxChange = (id) => {
      setCheckboxes((prevCheckboxes) => prevCheckboxes
        .map((checkbox) => (checkbox.id === id
          ? { ...checkbox, isChecked: !checkbox.isChecked }
          : checkbox)));
      updateButtonState();
    };

    const handleInputChange = (e) => {
      let { value } = e.target;
      const { id } = e.target;

      if (id === 'Email') {
        const cleanValue = removeEmojis(value);
        // By pass validation when the Phone field is not empty
        const invalidEmail = !value.length && checkboxes[3]?.isChecked
          && formData.Phone.length === MAX_PHONE_LENGTH_MASK
          ? true : isEmailValid(cleanValue);
        setEmailError(!invalidEmail);
      }

      if (id === 'Phone') {
        value = maskPhoneNumber(value);
        setIsPhoneValid(value.length === MAX_PHONE_LENGTH_MASK);
        const invalidEmail = !formData.Email.length && checkboxes[3]?.isChecked
          && value.length === MAX_PHONE_LENGTH_MASK
          ? true : isEmailValid(formData.Email);
        setEmailError(!invalidEmail);
      }

      if (id === 'ZipCode') {
        const numericZip = validateZipCode(value);
        const checkLength = numericZip?.length === MAX_ZIP_LENGTH;
        const stateAbbr = getStateAbbreviation(numericZip);
        value = numericZip;

        setFormData((prev) => ({
          ...prev,
          State: stateAbbr,
        }));

        setZipCodeError(!checkLength || !stateAbbr.length > 0);
      }

      setFormData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    };

    const renderStatesOptions = () => {
      const options = [
        { Name: SELECT_OPTION },
        ...store.personalInformation.states,
      ];

      return options.map((State) => (
        <option key={State.Name}>{State.Name}</option>
      ));
    };

    const renderError = () => {
      if (emailError) {
        return (
          <div className="Pet-cloud-forgot-modal-error">
            <img alt="" src={errorIcon} />

            <span>{t('petCloudSection.invalidEmail')}</span>
          </div>
        );
      }
      return null;
    };

    const onSubmit = () => {
      dispatch(updateInsuranceInformation(formData));
    };

    useEffect(() => {
      updateButtonState();
    }, [formData, checkboxes]);

    if (store.personalInformation.errorUpdateInsuranceInformation !== null) {
      return (
        <InsuranceRequestChangeBanner
          onContactUsClick={onContactUsClick}
        />
      );
    }
    return (
      <>
        <h2 className="Insurance-account-subtitle">
          {t('insuranceAccountSection.updateInformation')}
        </h2>

        <p className="Insurance-account-subtitle-description">
          {t('insuranceAccountSection.updateInformationDescription')}
        </p>

        <h2 className="Insurance-account-title-form">
          {t('insuranceAccountSection.emailOnFile')}
        </h2>

        <p className="Insurance-account-subtitle-description">{email}</p>

        <h2 className="Insurance-account-title-form">
          {t('insuranceAccountSection.selectInformation')}
        </h2>

        <div className="Insurance-account-checkboxes-container">
          <Checkbox
            checked={checkboxes[0].isChecked}
            className="Insurance-account-checkbox"
            isRounded={false}
            label={checkboxes[0].label}
            onChange={() => handleCheckboxChange(checkboxes[0].id)}
          />

          {checkboxes[0].isChecked && (
            <div className="Insurance-account-inputs-container
          Insurance-account-inputs-container-row"
            >
              <PetCloudInputAnimated
                id="FirstName"
                inputClass="Insurance-account-input-50"
                onChange={(e) => handleInputChange(e)}
                placeholder={t('insuranceAccountSection.firstName')}
                value={formData.FirstName}
              />

              <PetCloudInputAnimated
                id="LastName"
                inputClass="Insurance-account-input-50"
                onChange={(e) => handleInputChange(e)}
                placeholder={t('insuranceAccountSection.lastName')}
                value={formData.LastName}
              />
            </div>
          )}

          <Checkbox
            checked={checkboxes[1].isChecked}
            className="Insurance-account-checkbox"
            isRounded={false}
            label={checkboxes[1].label}
            onChange={() => handleCheckboxChange(checkboxes[1].id)}
          />

          {checkboxes[1].isChecked && (
            <div className="Insurance-account-inputs-container">
              <PetCloudInputAnimated
                id="Address"
                inputClass="Insurance-account-input-100"
                onChange={(e) => handleInputChange(e)}
                placeholder={t('insuranceAccountSection.addressLine1')}
                value={formData.Address}
              />

              <PetCloudInputAnimated
                id="SecondAddress"
                inputClass="Insurance-account-input-100"
                onChange={(e) => handleInputChange(e)}
                placeholder={t('insuranceAccountSection.addressLine2')}
                value={formData.SecondAddress}
              />

              <div className="Insurance-account-inputs33-contanier">
                <PetCloudInputAnimated
                  id="City"
                  inputClass="Insurance-account-input-33"
                  onChange={(e) => handleInputChange(e)}
                  placeholder={t('insuranceAccountSection.city')}
                  value={formData.City}
                />

                <PetCloudInputAnimated
                  error={zipCodeError
                    ? t('insuranceAccountSection.zipCodeError') : ''}
                  id="ZipCode"
                  inputClass="Insurance-account-input-33"
                  onChange={(e) => handleInputChange(e)}
                  placeholder={t('insuranceAccountSection.zipCode')}
                  value={formData.ZipCode}
                />

                <select
                  className="Insurance-account-select-33"
                  id="State"
                  onChange={(e) => handleInputChange(e)}
                  value={formData.State}
                >
                  {renderStatesOptions()}
                </select>
              </div>
            </div>
          )}

          <Checkbox
            checked={checkboxes[2].isChecked}
            className="Insurance-account-checkbox"
            isRounded={false}
            label={checkboxes[2].label}
            onChange={() => handleCheckboxChange(checkboxes[2].id)}
          />

          {checkboxes[2].isChecked && (
            <div className="Insurance-account-inputs-container">
              <PetCloudInputAnimated
                error={renderError()}
                id="Email"
                inputClass={`Insurance-account-input-100 
              ${emailError ? 'Insurance-account-input-error' : ''}`}
                onChange={(e) => handleInputChange(e)}
                placeholder={t('insuranceAccountSection.emailAddress')}
                value={formData.Email}
              />
            </div>
          )}

          <Checkbox
            checked={checkboxes[3].isChecked}
            className="Insurance-account-checkbox"
            isRounded={false}
            label={checkboxes[3].label}
            onChange={() => handleCheckboxChange(checkboxes[3].id)}
          />

          {checkboxes[3].isChecked && (
            <div className="Insurance-account-inputs-container">
              <PetCloudInputAnimated
                id="Phone"
                inputClass="Insurance-account-input-100"
                onChange={(e) => handleInputChange(e)}
                placeholder={t('insuranceAccountSection.phoneNumber')}
                value={formData.Phone}
              />
            </div>
          )}
        </div>

        <div className="Insurance-account-buttons-container">
          <button
            className="Insurance-account-button-contact-us"
            onClick={toggleUpdateForm}
            type="button"
          >
            {t('insuranceAccountSection.cancel')}
          </button>

          {submitRequestChanges ? (
            <LoadingButton
              className="Insurance-account-button-contact-submit"
              disabled={isDisabled || isEmailDisabled
                || !areCheckboxesChecked || zipCodeError || isPhoneDisabled}
              loading={loading}
              onClick={onSubmit}
              text={t('insuranceAccountSection.submitRequest')}
              type="button"
            />
          ) : null}
        </div>
      </>
    );
  };

export { InsuranceAccountUpdateForm };
