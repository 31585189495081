import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import alertIcon from '../../assets/alert.png';
import { FILE_CLAIMS_STEPS } from '../../constants';
import { useMixpanel } from '../../hooks';
import { EV4 } from '../../services/mixpanel';
import {
  getDateBasicFormat,
  getClaimDataForMixPanel,
} from '../../services/utils';
import './UnsavedChangesPrompt.css';

const UnsavedChangesPrompt = ({
  icon = alertIcon,
  containerClassName = '',
  currentStepNumber = 0,
  message = '',
  modalClassName = '',
  onCancel = () => { },
  onConfirm = () => { },
  policyBrandName,
  textCancel,
  textConfirm,
  title,
}) => {
  const { t } = useTranslation(['common', 'fileClaims']);

  const store = useSelector(({ fileClaims }) => ({ fileClaims }), shallowEqual);

  const { fileClaims } = store;

  const { selectedPet } = fileClaims;

  const { sendEvent } = useMixpanel();

  function handleOnConfirm() {
    onConfirm();
  }

  function handleOnCancel() {
    onCancel();
    switch (currentStepNumber) {
      case FILE_CLAIMS_STEPS.selectPet.id:
        sendEvent(EV4({
          claimData: getClaimDataForMixPanel({
            selectedPet,
          }),
          ...(fileClaims.date && {
            dateOfLoss: getDateBasicFormat(new Date(Number(fileClaims.date))),
          }),
          lastBotText: t('evie.step1', { ns: 'fileClaims' }),
          lastInputFromUser: fileClaims.date
            ? getDateBasicFormat(new Date(Number(fileClaims.date)))
            : selectedPet?.Name,
          policyBrand: policyBrandName,
          policyNumber: fileClaims.date ? fileClaims.policyNumber : null,
        }));
        break;

      case FILE_CLAIMS_STEPS.claimType.id:
      case FILE_CLAIMS_STEPS.claimTypeDetail.id:
        sendEvent(EV4({
          claimData: getClaimDataForMixPanel({
            date: fileClaims.date,
            policyNumber: fileClaims.policyNumber,
            selectedPet,
          }),
          dateOfLoss: getDateBasicFormat(new Date(Number(fileClaims.date))),
          lastBotText: t('evie.step3', { ns: 'fileClaims' }),
          lastInputFromUser: fileClaims.claimTypeText,
          policyBrand: policyBrandName,
          policyNumber: fileClaims.policyNumber,
        }));
        break;

      case FILE_CLAIMS_STEPS.claimsDetails.id:
        sendEvent(EV4({
          claimData: getClaimDataForMixPanel({
            date: fileClaims.date,
            policyNumber: fileClaims.policyNumber,
            selectedPet,
          }),
          ...(fileClaims.claimsDetails && {
            claimDescription: fileClaims.claimsDetails,
          }),
          dateOfLoss: getDateBasicFormat(new Date(Number(fileClaims.date))),
          lastBotText: t('evie.step4', { ns: 'fileClaims' }),
          lastInputFromUser: fileClaims.claimsDetails
            ? fileClaims.claimsDetails : fileClaims.claimTypeText,
          policyBrand: policyBrandName,
          policyNumber: fileClaims.policyNumber,
        }));
        break;

      case FILE_CLAIMS_STEPS.fileUpload.id:
        sendEvent(EV4({
          claimData: getClaimDataForMixPanel({
            date: fileClaims.date,
            policyNumber: fileClaims.policyNumber,
            selectedPet,
          }),
          claimDescription: fileClaims.claimsDetails,
          dateOfLoss: getDateBasicFormat(new Date(Number(fileClaims.date))),
          lastBotText: t('evie.step5', { ns: 'fileClaims' }),
          lastInputFromUser: fileClaims.attachments.length
            ? fileClaims.attachments[0].filename : fileClaims.claimsDetails,
          policyBrand: policyBrandName,
          policyNumber: fileClaims.policyNumber,
        }));
        break;

      case FILE_CLAIMS_STEPS.reimbursementMethod.id:
        sendEvent(EV4({
          claimData: getClaimDataForMixPanel({
            date: fileClaims.date,
            policyNumber: fileClaims.policyNumber,
            selectedPet,
          }),
          claimDescription: fileClaims.claimsDetails,
          dateOfLoss: getDateBasicFormat(new Date(Number(fileClaims.date))),
          lastBotText: t('evie.step6', { ns: 'fileClaims' }),
          lastInputFromUser: fileClaims.attachments[0].filename,
          policyBrand: policyBrandName,
          policyNumber: fileClaims.policyNumber,
        }));
        break;

      case FILE_CLAIMS_STEPS.termsAndConditions.id:
        sendEvent(EV4({
          claimData: getClaimDataForMixPanel({
            date: fileClaims.date,
            policyNumber: fileClaims.policyNumber,
            selectedPet,
          }),
          claimDescription: fileClaims.claimsDetails,
          dateOfLoss: getDateBasicFormat(new Date(Number(fileClaims.date))),
          lastBotText: t('evie.step7', { ns: 'fileClaims' }),
          lastInputFromUser: fileClaims.attachments[0].filename,
          policyBrand: policyBrandName,
          policyNumber: fileClaims.policyNumber,
        }));
        break;

      case FILE_CLAIMS_STEPS.review.id:
        sendEvent(EV4({
          claimData: getClaimDataForMixPanel({
            date: fileClaims.date,
            policyNumber: fileClaims.policyNumber,
            selectedPet,
          }),
          claimDescription: fileClaims.claimsDetails,
          dateOfLoss: getDateBasicFormat(new Date(Number(fileClaims.date))),
          lastBotText: t('evie.step8', { ns: 'fileClaims' }),
          lastInputFromUser: fileClaims.attachments[0].filename,
          policyBrand: policyBrandName,
          policyNumber: fileClaims.policyNumber,
        }));
        break;

      default:
        break;
    }
  }

  return (
    <div className="Modal-overlay-backdrop">
      <Card className={`Modal-container ${modalClassName}`}>
        {icon && (
          <div className="Modal-image-container">
            <Image className="Modal-image" src={icon} />
          </div>
        )}

        <div className={containerClassName}>
          <p className="Modal-title">
            {title || t('unsavedChangesModalTitle')}
          </p>

          {message !== ' ' && (
            <div className="Modal-body-container">
              <p className="Modal-body">
                {message || t('unsavedChangesModalBody')}
              </p>
            </div>
          )}

          <div className="Modal-buttons-container">
            <Button
              className="Modal-button Modal-button-leave"
              id="unsavedChangesButtonLeave"
              onClick={handleOnConfirm}
            >
              {textCancel || t('unsavedChangesModalLeave')}
            </Button>

            <Button
              className="Modal-button Modal-button-stay"
              id="unsavedChangesButtonStay"
              onClick={handleOnCancel}
            >
              {textConfirm || t('unsavedChangesModalStay')}
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default UnsavedChangesPrompt;
