import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ResourceCard } from './ResourceCard';

import {
  CLAIMS,
  INSURANCE,
} from '../../navigation/routes.constants';

import './Resources.css';
import { DownloadModal } from '../../common/DownloadModal';

const Resources = () => {
  const { t } = useTranslation('home');
  const history = useHistory();
  const [downLoadModalVisible, setDownLoadModalVisible] = useState(false);

  const onFileAClaimCick = () => {
    history.push(CLAIMS);
  };

  const onViewMyInsuranceCick = () => {
    history.push(INSURANCE);
  };

  const onDownloadAppCick = () => {
    setDownLoadModalVisible(true);
  };

  const onCloseDownloadModal = () => {
    setDownLoadModalVisible(false);
  };

  return (
    <div className="Resources-main-container">
      <h1 className="Resources-title">
        {t('resources.title')}
      </h1>

      <DownloadModal
        onClose={onCloseDownloadModal}
        show={downLoadModalVisible}
      />

      <div className="Resources-card-container">
        <ResourceCard
          buttonLabel={t('resources.cards.fileAClaim.buttonLabel')}
          description={t('resources.cards.fileAClaim.description')}
          onClick={onFileAClaimCick}
          title={t('resources.cards.fileAClaim.title')}
        />

        <ResourceCard
          buttonLabel={t('resources.cards.'
            + 'viewMyInsurance.buttonLabel')}
          description={t('resources.cards.'
            + 'viewMyInsurance.description')}
          onClick={onViewMyInsuranceCick}
          title={t('resources.cards.'
            + 'viewMyInsurance.title')}
        />

        <ResourceCard
          buttonLabel={t('resources.cards.downloadApp.buttonLabel')}
          description={t('resources.cards.downloadApp.description')}
          onClick={onDownloadAppCick}
          title={t('resources.cards.downloadApp.title')}
        />
      </div>
    </div>
  );
};

export { Resources };
