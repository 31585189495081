import React from 'react';
import { useTranslation } from 'react-i18next';

import qrCodeNew from '../../assets/qr-code-new.png';
import closeIcon from '../../assets/close-button.svg';
import googlePlayBlack from '../../assets/google-play-black.png';
import appStoreBlack from '../../assets/app-store-black.png';

import {
  APP_STORE_URL,
  GOOGLE_PLAY_URL,
} from '../../constants';
import ModalContainer from './ModalContainer';

import './DownloadModal.css';

const DownloadModal = ({ show, onClose }) => {
  const { t } = useTranslation('common');

  return (
    <ModalContainer
      cancelable
      contentStyle="Download-modal-main-container"
      customCloseIcon={closeIcon}
      handleOutClick={onClose}
      show={show}
    >
      <h1 className="Download-modal-title">
        {t('downloadAppsNew.title')}
      </h1>

      <h3 className="Download-modal-description">
        {t('downloadAppsNew.description')}
      </h3>

      <div className="Download-modal-row">
        <div className="Download-modal-qr-container">
          <img alt="" src={qrCodeNew} />

          <span>{t('downloadAppsNew.scanToDownload')}</span>
        </div>

        <h3 className="Download-modal-or">
          {t('downloadAppsNew.or')}
        </h3>

        <div className="Download-modal-col">
          <a href={APP_STORE_URL} rel="noopener noreferrer" target="_blank">
            <img alt="" src={appStoreBlack} />
          </a>

          <a href={GOOGLE_PLAY_URL} rel="noopener noreferrer" target="_blank">
            <img alt="" src={googlePlayBlack} />
          </a>
        </div>
      </div>
    </ModalContainer>
  );
};

export { DownloadModal };
