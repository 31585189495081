import React from 'react';
import { useTranslation } from 'react-i18next';

import './PetProfileCompleted.css';

const PetProfileCompleted = ({ petName = '' }) => {
  const { t } = useTranslation('myPets');

  return (
    <div className="Pet-proflie-completed-container">
      <span>{t('PetProfile.petProfileCompleted', { petName })}</span>
    </div>
  );
};

export { PetProfileCompleted };
