import React from 'react';
import { useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { addPetRestarted, deletePet, refreshPets } from '../../actions';

import ModalContainer from '../common/ModalContainer';
import { ActionButton } from '../common/petCloud/ActionButton';

import closeIcon from '../../assets/close-button.svg';

import { MY_PETS } from '../navigation/routes.constants';

import { useMixpanel } from '../../hooks';
import { EV11 } from '../../services/mixpanel';

import './DeleteAPetModal.css';

const DeleteAPetModal = ({
  isInsured,
  name,
  onClose = () => { },
  petId,
  show,
}) => {
  const { t } = useTranslation('myPets');
  const { loading, success } =
    useSelector(({ myPets }) => myPets, shallowEqual);
  const dispatch = useDispatch();
  const history = useHistory();
  const { sendEvent } = useMixpanel();

  function handleDeletePet() {
    sendEvent(EV11({ petId }));
    dispatch(deletePet(petId));
  }

  function handleDeletedPet() {
    onClose();
    dispatch(refreshPets());
    history.push(MY_PETS);
    dispatch(addPetRestarted());
  }

  return (
    <ModalContainer show={show}>
      <div className="Delete-a-pet-modal-main-container">
        <div className="Delete-a-pet-modal-header">
          <h3>{t('DeletePetModal.deletePet')}</h3>

          <button
            className="Delete-a-pet-close-modal"
            id="closeIconAddPetModal"
            onClick={onClose}
            type="button"
          >
            <img alt="Close" src={closeIcon} />
          </button>
        </div>

        <p className="Delete-a-pet-modal-message">
          {isInsured
            ? t('DeletePetModal.insuredPet')
            : t('DeletePetModal.confirmationMessage', { petName: name })}
        </p>

        <div className="Delete-a-pet-actions">
          {isInsured ? (
            <ActionButton
              onClick={onClose}
              title={t('DeletePetModal.close')}
            />
          ) : (
            <>
              <ActionButton
                isDeleteButton
                loading={loading}
                onClick={handleDeletePet}
                onSuccess={handleDeletedPet}
                success={success}
                title={t('DeletePetModal.deleteButton')}
              />

              <ActionButton
                isSecondaryButton
                onClick={onClose}
                title={t('DeletePetModal.cancel')}
              />
            </>
          )}
        </div>
      </div>
    </ModalContainer>
  );
};

export { DeleteAPetModal };
