import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  createFolderReset,
  filesToUploadAdded,
  fileToUploadRemoved,
  fileToUploadUpdated,
  loadFolderContent,
  loadFolderStructure,
  uploadFilesRestarted,
} from '../../actions';
import { toBase64 } from '../../services/utils';
import {
  ACTION_BUTTONS_CONTAINER_ID,
  INBOX_DOCS_PRIVATE_FOLDERS,
  INBOX_DOCS,
  PROTECTED_FOLDERS,
} from '../../constants';
import { useBreadcrumbNavigation } from '../../hooks/useBreadcrumbNavigation';
import plusIcon from '../../assets/inboxDocsMultibrand/iconsFolder/add.svg';
import { FoldersSection } from './FoldersSection';
import { InboxSection } from './InboxSection';
import { RecentActivity } from './recentActivity/RecentActivity';
import FigoLottie from '../common/FigoLottie';
import loadingSpinnerBlue
from '../../assets/lottieFiles/loading-blue.json';
import { Breadcrumbs } from '../common/breadcrumbs/Breadcrumbs';
import { InboxAndDocsFileList } from './foldersContent/InboxAndDocsFileList';
import './InboxAndDocsScreen.css';
import { InboxAndDocsEmptyScreen } from './InboxAndDocsEmptyScreen';
import { Dropzone } from '../common/inputFiles/Dropzone';
import { UploadFilesButton } from './UploadFilesButton';
import { UploadFilesModal } from './modals';
import { AddFolderModal } from './modals/AddFolderModal';

const MAX_SIZE_MB = 15;
const FACTOR_B_TO_MB = 1048576;
// eslint-disable-next-line max-len
const ALLOWED_EXT = /(\.pdf|\.jpg|\.jpeg|\.png|\.doc|\.docx|\.xls|\.xlsx|\.txt|\.html|\.svg|\.tiff)$/i;

const ALLOWED_EXT_STRING =
  '.pdf,.html,.txt,.jpg,.jpeg,.png,.xls,.xlsx,.doc,.docx';
const FOLDER_PRIVATE = [
  ...INBOX_DOCS_PRIVATE_FOLDERS,
  INBOX_DOCS.trash,
];

const InboxAndDocsScreen = () => {
  const { t } = useTranslation('inboxAndDocs');
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const [showAddFolder, setShowAddFolder] = useState(false);
  const [showUploadFilesModal, setShowUploadFilesModal] = useState(false);
  const [selectedUploadFolder, setSelectedUploadFolder] = useState(null);

  const store = useSelector(({
    inboxAndDocs,
    personalInformation,
  }) => ({
    inboxAndDocs,
    personalInformation,
  }), shallowEqual);

  const { PetCloudEmail } = store.personalInformation.userInfo;
  const {
    inboxAndDocs: {
      folderContent,
      loading: loadingFolders,
      uploadFiles,
    },
  } = store;
  const isEmptyFolder = !folderContent.length;
  const inbox = useMemo(
    () => folderContent.find((folder) => folder.Id === INBOX_DOCS.inbox),
    [folderContent],
  );
  const routeFormat = `${match.path}/:name/:id`;

  const breadcrumbs = useBreadcrumbNavigation({
    homeData: {
      id: INBOX_DOCS.landing,
      name: 'Home',
    },
    navigationParam: 'data',
    navTree: store.inboxAndDocs.folderStructure,
    routeFormat,
  });

  const { currentFolderId, currentFolderName } = useMemo(() => {
    if (!breadcrumbs.navData.length) {
      return INBOX_DOCS.landing;
    }

    const folderInfo = breadcrumbs.navData[breadcrumbs.navData.length - 1];

    return {
      currentFolderId: String(folderInfo?.id),
      currentFolderName: folderInfo?.name,
    };
  }, [breadcrumbs]);

  const breadcrumbsSectionId = breadcrumbs.navData[1]?.id ?? INBOX_DOCS.landing;
  const isPolicyDocumentsReadOnly = PROTECTED_FOLDERS
    .includes(String(breadcrumbsSectionId));
  const isTrashFolder = currentFolderId === INBOX_DOCS.trash;

  useEffect(() => {
    if (breadcrumbs.navData.length > 1 && selectedUploadFolder) {
      setSelectedUploadFolder(null);
    }
  }, [breadcrumbs]);

  useEffect(() => {
    dispatch(loadFolderStructure());
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadFolderContent(currentFolderId || INBOX_DOCS.landing));
  }, [dispatch, currentFolderId]);

  async function convertToBinary({ file, fileKey }) {
    try {
      const binaryDoc = await toBase64(file);
      dispatch(fileToUploadUpdated({
        fileKey,
        newValues: {
          binary: binaryDoc,
          errorMsg: '',
        },
      }));
    } catch (_) {
      dispatch(fileToUploadUpdated({
        fileKey,
        newValues: {
          errorMsg: t('uploadModal.failedUpload'),
          percentage: 25,
          valid: false,
        },
      }));
    }
  }

  function validateFile({ file, maxSizeMB }) {
    return file.size > (maxSizeMB * FACTOR_B_TO_MB)
      ? t('uploadModal.largeFile') : '';
  }

  function handleAddFiles(fileList) {
    setShowUploadFilesModal(true);
    for (let i = 0; i < fileList.length; i += 1) {
      const file = fileList[i];
      const error = validateFile({ file, maxSizeMB: MAX_SIZE_MB });
      const extMatch = file.name.match(ALLOWED_EXT);
      const uniqueKey = `${file.name}-${Date.now()}`;
      if (extMatch) {
        dispatch(filesToUploadAdded({
          contentType: file.type,
          errorMsg: error,
          extension: extMatch.slice(1)[0],
          filename: file.name,
          hideDelete: false,
          key: uniqueKey,
          percentage: 0,
          size: file.size,
          valid: !error,
        }));
      }
      if (!error) {
        convertToBinary({ file, fileKey: uniqueKey });
      }
    }
  }

  function onAddFolder() {
    setShowAddFolder(true);
  }

  const openUploadFilesModal = useCallback(() => {
    setShowUploadFilesModal(true);
  }, []);

  function renderInboxAndDocsContent() {
    if (loadingFolders) {
      return (
        <div className="Inbox-and-docs-loading-container">
          <FigoLottie
            animationData={loadingSpinnerBlue}
            height={50}
            width={50}
          />
        </div>
      );
    }

    const dropzone = (
      !INBOX_DOCS_PRIVATE_FOLDERS.includes(currentFolderId)
      && !isTrashFolder
      && !showUploadFilesModal
      && (
        <Dropzone onAddFiles={handleAddFiles} />
      )
    );

    if (isEmptyFolder) {
      return (
        <>
          <InboxAndDocsEmptyScreen
            folderId={currentFolderId}
            handleAddFiles={handleAddFiles}
            isTrashFolder={isTrashFolder}
          />

          {dropzone}
        </>
      );
    }

    return (
      <Switch>
        <Route exact path={match.path}>
          <div className="Flex Flex-column">
            <InboxSection
              email={PetCloudEmail}
              inbox={inbox}
              onClick={breadcrumbs.onAddBreadcrumbItem}
            />

            <FoldersSection
              breadcrumbs={breadcrumbs}
              folders={folderContent}
              onAddFolder={onAddFolder}
              onOpenUpload={openUploadFilesModal}
            />
          </div>

          <RecentActivity
            breadcrumbs={breadcrumbs}
            folders={store.inboxAndDocs.folderStructure}
          />
        </Route>

        <Route path={routeFormat}>
          <InboxAndDocsFileList
            changeLocation={breadcrumbs.onDirectNavigation}
            currentFolderId={currentFolderId}
            goToFolder={breadcrumbs.onAddBreadcrumbItem}
            goToFolderDirect={breadcrumbs.onDirectNavigation}
          />

          {dropzone}
        </Route>
      </Switch>
    );
  }

  function onCloseFolder() {
    if (store.inboxAndDocs.newFolder.loading) {
      return;
    }

    dispatch(createFolderReset());
    setShowAddFolder(false);
  }

  const onNewFolderCreated = useCallback(() => {
    setShowAddFolder(false);
    dispatch(loadFolderStructure());
    dispatch(loadFolderContent(currentFolderId || INBOX_DOCS.landing));
  }, [dispatch, currentFolderId]);

  function closeUploadFiles() {
    setShowUploadFilesModal(false);
    dispatch(uploadFilesRestarted());
    const success = uploadFiles.filesAttached
      .some((file) => file.percentage === 100);
    if (success) {
      if (!selectedUploadFolder) {
        dispatch(loadFolderContent(currentFolderId || INBOX_DOCS.landing));
      } else {
        breadcrumbs.onDirectNavigation({
          id: selectedUploadFolder.id,
          name: selectedUploadFolder.label,
        });
      }
    }
    setSelectedUploadFolder(null);
  }

  function handleDeleteFile(fileKeyToRemove) {
    dispatch(fileToUploadRemoved(fileKeyToRemove));
  }

  function handleSelectedFolder(itemFolder) {
    setSelectedUploadFolder(
      (prev) => (itemFolder === prev ? null : itemFolder),
    );
  }

  function renderAddFolder() {
    const hideAddFolder = breadcrumbs.navData.length > 2;

    if (hideAddFolder) {
      return null;
    }

    return (
      <button
        className="Inbox-and-docs-add-folder"
        onClick={onAddFolder}
        type="button"
      >
        <img alt="" src={plusIcon} />

        <span>{t('addFolder')}</span>
      </button>
    );
  }

  function renderActionButton() {
    if (FOLDER_PRIVATE.includes(String(currentFolderId))) {
      return null;
    }

    return (
      <div className="Inbox-and-docs-actions-container">
        {renderAddFolder()}

        {!isPolicyDocumentsReadOnly && (
          <UploadFilesButton
            isHome={breadcrumbs.navData.length < 2}
            onClick={openUploadFilesModal}
          />
        )}
      </div>
    );
  }

  const renderHeader = () => {
    const isHome = breadcrumbs?.navData.length <= 1;

    // no navigation executed
    if (isHome) {
      return null;
    }

    // navigation
    return (
      <div className="Screen-header-transparent">
        <Breadcrumbs
          labelField="name"
          navData={breadcrumbs.navData}
          onNavItemClick={breadcrumbs.onBreadcrumbItemClick}
        />

        <div
          className="Inbox-and-docs-header"
          id={ACTION_BUTTONS_CONTAINER_ID}
        >
          <div className="Screen-title-black Inbox-and-docs-title">
            <span>{currentFolderName}</span>
          </div>

          {renderActionButton()}
        </div>
      </div>
    );
  };

  return (
    <>
      <AddFolderModal
        onClose={onCloseFolder}
        onRefresh={onNewFolderCreated}
        parentId={currentFolderId}
        show={showAddFolder}
      />

      {showUploadFilesModal && (
        <UploadFilesModal
          acceptedFiles={ALLOWED_EXT_STRING}
          currentFolder={{ id: currentFolderId, label: currentFolderName }}
          filesAttached={uploadFiles.filesAttached}
          folderId={selectedUploadFolder?.id || currentFolderId}
          isHome={breadcrumbs.navData.length < 2}
          onAddFiles={handleAddFiles}
          onClose={closeUploadFiles}
          onDeleteFile={handleDeleteFile}
          onFolderSelected={handleSelectedFolder}
          selectedFolder={selectedUploadFolder}
          show={showUploadFilesModal}
        />
      )}

      {renderHeader()}

      <div
        className={'Inbox-and-docs-content Flex '
          + `${isEmptyFolder
            ? 'Inbox-and-docs-center Flex-column' : ''} ${isTrashFolder
              ? 'Padding-top-24' : ''}`}
      >
        {renderInboxAndDocsContent()}
      </div>
    </>
  );
};

export default InboxAndDocsScreen;
