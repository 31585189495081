import React, { useState, useRef } from 'react';
import infoIcon from '../../assets/info-blue-light.svg';

import './CustomTooltip.css';

export const TOOL_TIP_POSITION = {
  center: {
    containerArrowPosition: 'Icon-tooltip-arrow-container-center',
    left: -115,
  },
  end: {
    containerArrowPosition: 'Icon-tooltip-arrow-container-end',
    left: -204,
  },
  start: {
    containerArrowPosition: 'Icon-tooltip-arrow-container-start',
    left: -24,
  },
};

const CustomTooltip = ({
  backgroundColor = 'var(--blue-main)',
  borderColor = '',
  containerClassName,
  description,
  descriptionClassName,
  icon,
  position = TOOL_TIP_POSITION.center,
  name = '',
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const iconRef = useRef();
  const id = `tooltip-${name.replace(' ', '-')}`;

  const containerLocation = () => {
    if (!iconRef.current) {
      return {};
    }

    const { offsetTop, offsetLeft } = iconRef.current;

    const top = offsetTop - 128;
    const left = offsetLeft + position.left;
    return { left: `${left}px`, top: `${top}px` };
  };

  function handleHoverIn() {
    setTooltipVisible(true);
  }

  function handleHoverOut() {
    setTooltipVisible(false);
  }

  function handleVisibility() {
    if (tooltipVisible) {
      handleHoverOut();
    } else {
      handleHoverIn();
    }
  }

  function renderTooltip() {
    if (!tooltipVisible) {
      return null;
    }

    return (
      <div
        className="Icon-tooltip-main-container"
        id={id}
        role="tooltip"
        style={containerLocation()}
      >
        <div
          className={'Icon-tooltip-description-container '
            + `${containerClassName}`}
          style={{
            backgroundColor,
            borderColor,
            borderStyle: 'solid',
            borderWidth: 1,
          }}
        >
          <span className={`Icon-tooltip-description ${descriptionClassName}`}>
            {description}
          </span>
        </div>

        <div className={'Icon-tooltip-arrow-container '
          + `${position.containerArrowPosition}`}
        >
          <div
            className="Icon-tooltip-arrow-down-border"
            style={{
              borderTopColor: `${borderColor || backgroundColor}`,
              borderTopStyle: 'solid',
              borderTopWidth: '11px',
            }}
          />

          <div
            className="Icon-tooltip-arrow-down"
            style={{ borderTop: `10px solid ${backgroundColor}` }}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      {renderTooltip()}

      <button
        aria-describedby={id}
        aria-label={tooltipVisible ? description : ''}
        aria-live={tooltipVisible ? 'assertive' : null}
        className="Icon-tooltip-icon-button"
        onBlur={handleHoverOut}
        onClick={handleVisibility}
        type="button"
      >
        <img
          ref={iconRef}
          alt="info"
          className="Icon-tooltip-icon-default"
          onMouseEnter={handleHoverIn}
          onMouseLeave={handleHoverOut}
          src={icon || infoIcon}
        />
      </button>
    </>
  );
};

export default CustomTooltip;
