import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { AddAttachmentModal } from './AddAttachmentModal';
import {
  fileClaimsRestart,
  markFilesAsRead,
  openMediaFileToBlob,
  getClaimByIdRequested,
} from '../../../actions';
import { READ_FILE_SOURCE, CLAIM_STATUS_BY_TEXT }
from '../../../constants';
import { useMixpanel, useTrackOnMount } from '../../../hooks';
import { EV7, EV8 } from '../../../services/mixpanel';
import { getBrandNameByChannelId } from '../../../services/utils';
import { RecentFileIcon }
from '../../inboxAndDocs/recentActivity/RecentFileIcon';
import { MARKETING_CHANNEL_ID } from '../../../theme/theme.constants';
import addIconBlue from '../../../assets/myPets/add-icon-blue.svg';

const ClaimsAttachments = () => {
  const { t } = useTranslation('claims');
  const { sendEvent } = useMixpanel();
  const [
    showModalAddAttachements,
    setShowModalAddAttachments] = useState(false);
  const store = useSelector(({
    claims,
    fileClaims,
  }) => ({
    claims,
    fileClaims,
  }), shallowEqual);

  const {
    claims: {
      claimById: {
        ClaimStatus,
        Attachments,
        ClaimId,
        MarketingChannelId,
      },
      isLoadingDetails,
    },
    fileClaims: { uploadAttSuccess },
  } = store;
  const dispatch = useDispatch();
  const location = useLocation();

  const attachments = useMemo(() => Attachments ?? [], [Attachments]);

  const shouldBeShowBtnUpload = () => (ClaimStatus
    !== CLAIM_STATUS_BY_TEXT.closed
      && ClaimStatus !== CLAIM_STATUS_BY_TEXT.done)
    && MarketingChannelId === MARKETING_CHANNEL_ID.figo;

  const viewClaimAttachmentsEvent = useMemo(() => {
    if (!isLoadingDetails && attachments.length) {
      return EV7({
        claimNumber: location.state.claimNumber,
        numberOfAttachments: attachments.length,
        policyBrand: getBrandNameByChannelId(MarketingChannelId),
      });
    }
    return null;
  }, [attachments, isLoadingDetails]);

  useTrackOnMount(viewClaimAttachmentsEvent, [attachments]);

  function handleShowModal() {
    dispatch(fileClaimsRestart());
    setShowModalAddAttachments(!showModalAddAttachements);
    if (uploadAttSuccess) {
      dispatch(getClaimByIdRequested({ claimId: ClaimId }));
    }
  }

  const handleMarkAttachmentAsRead = (file) => () => {
    dispatch(openMediaFileToBlob(file.Id));
    if (!file.IsRead) {
      dispatch(markFilesAsRead({
        filesIds: [file.Id],
        source: READ_FILE_SOURCE.claims,
      }));
    }

    sendEvent(EV8({
      claimNumber: location.state.claimNumber,
      fileName: file.FileNameAndExtension,
      filePath: file.Url,
      policyBrand: getBrandNameByChannelId(MarketingChannelId),
    }));
  };

  return (
    <div className="Claim-attachment-multibrand-container">
      <div className="Claim-attachments-wrapper">
        <div className="Claim-attachment-title-wrapper">
          <h3 className="Claim-attachments-title">{t('attachments')}</h3>
        </div>

        <div className="Claim-attachments-items-wrapper">
          {attachments.map((attachment) => (
            <div
              key={attachment.Id}
              className="Claim-attachments-item-container"
              onClick={handleMarkAttachmentAsRead(attachment)}
              onKeyPress={handleMarkAttachmentAsRead(attachment)}
              role="button"
              tabIndex="0"
              type="button"
            >
              <div className="Claim-attachments-item-img">
                <RecentFileIcon
                  alt="file extension icon"
                  className="Docs-file-icon-row"
                  fileExtension={attachment.Extension?.toLowerCase()}
                />
              </div>

              <div className="Claim-attachments-item-text">
                <p className="Claim-attachments-item-name">
                  {attachment.FileNameAndExtension}
                </p>

                <p className="Claim-attachments-item-date">
                  {moment(attachment.UpdatedOnUtc, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
                    .format('MMMM D, YYYY')}
                </p>
              </div>
            </div>
          ))}
        </div>

        {shouldBeShowBtnUpload() && (
          <button
            className="Claim-button-upload-attachment"
            onClick={handleShowModal}
            type="button"
          >
            <img
              alt=""
              className="Claim-button-image"
              src={addIconBlue}
            />

            <span className="No-clicked">{t('addAttachment')}</span>
          </button>
        )}
      </div>

      <AddAttachmentModal
        setShowModalAddAttachments={setShowModalAddAttachments}
        showModalAddAttachements={showModalAddAttachements}
      />
    </div>
  );
};

export { ClaimsAttachments };
