import { select } from 'redux-saga/effects';

function* getFeatureFlagByName(ffName) {
  const featureFlags = yield select(({ settings }) => settings.featureFlags);
  return !!(featureFlags?.flags || {})[ffName];
}

export function* useCustomerApiV1ForSagas() {
  const featureFlags = yield select(({ settings }) => settings.featureFlags);
  return !!featureFlags?.flags?.PetCloudCustomerApiV1;
}

export function* useCustomerInboxDocsApiV2ForSagas() {
  const featureFlags = yield select(({ settings }) => settings.featureFlags);
  return !!featureFlags?.flags?.PetCloudCustomerApiV2InboxAndDocs;
}

export function* useClaimApiV2ForSagas() {
  const featureFlags = yield select(({ settings }) => settings.featureFlags);
  return !!featureFlags?.flags?.PetCloudClaimsApiV2;
}

export function* useCustomerApiV2MyPetsForSagas() {
  const featureFlags = yield select(({ settings }) => settings.featureFlags);
  return !!featureFlags?.flags?.PetCloudCustomerApiV2MyPets;
}

export function* useClaimsApiV1ForSagas() {
  const featureFlags = yield select(({ settings }) => settings.featureFlags);
  return !!featureFlags?.flags?.PetCloudClaimsApiV1;
}

export function* useClaimsApiV2ForSagas() {
  const featureFlags = yield select(({ settings }) => settings.featureFlags);
  return !!featureFlags?.flags?.PetCloudClaimsApiV2;
}

export function* useCustomerApi150617V1ForSagas() {
  const featureFlags = yield select(({ settings }) => settings.featureFlags);
  return !!featureFlags?.flags?.PetCloudCustomer150617ApiV1;
}

export function* usePolicyApiV1ForSagas() {
  return yield getFeatureFlagByName('PetCloudPolicyApiV1');
}

export function* usePolicySummaryV1ForSagas() {
  return yield getFeatureFlagByName('PetCloudPolicyApiV1Summary');
}

export function* useClaimsAPIV2T155969ForSagas() {
  const featureFlags = yield select(({ settings }) => settings.featureFlags);
  return !!featureFlags?.flags?.PetCloudClaimsAPIV2T155969;
}

export function* usePolicyApiV1T153274ForSagas() {
  const featureFlags = yield select(({ settings }) => settings.featureFlags);
  return !!featureFlags?.flags?.PetCloudT153274;
}

export function* useCustomerApiV1T162824ForSagas() {
  const featureFlags = yield select(({ settings }) => settings.featureFlags);
  return !!featureFlags?.flags?.PetCloudCustomerApiV1T162824;
}

export function* usePolicyApiV1T153276ForSagas() {
  const featureFlags = yield select(({ settings }) => settings.featureFlags);
  return !!featureFlags?.flags?.PetCloudPolicyApiV1T153276;
}
