import React from 'react';
import { useTranslation } from 'react-i18next';
import plusImage from '../../../assets/fileClaim/plus.png';
import { ACCEPTED_FILES } from '../../../constants';

const AddFilesButton = ({ addFile = () => { } }) => {
  const { t } = useTranslation('claims');

  function handleFileChange(event) {
    addFile(event.target.files);
  }

  return (
    <div className="Input-file-add-browse">
      <img
        alt="add file"
        className="Input-file-plus"
        src={plusImage}
      />

      <span>{t('addFiles')}</span>

      <input
        accept={ACCEPTED_FILES}
        multiple
        onChange={handleFileChange}
        type="file"
        value=""
      />
    </div>
  );
};

export { AddFilesButton };
