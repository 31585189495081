import React from 'react';
import { Link } from 'react-router-dom';

const PetProfileCardLink = ({ children, href }) => (
  <Link className="My-pets-profile-card-container" to={href}>
    {children}
  </Link>
);

const PetProfileCardButton = ({ children, onClick }) => (
  <button
    className="My-pets-profile-card-container"
    onClick={onClick}
    type="button"
  >
    {children}
  </button>
);

export const PetProfileCard = ({
  onClick = null,
  href,
  iconSrc,
  paragraph,
  title,
}) => {
  const Container = onClick ? PetProfileCardButton : PetProfileCardLink;

  return (
    <Container href={href} onClick={onClick}>
      <img alt="" className="card-icon" src={iconSrc} />

      <div className="My-pets-profile-card-text-content">
        <h3 className="My-pets-profile-card-title">
          {title}
        </h3>

        <p className="My-pets-profile-card-paragraph">
          {paragraph}
        </p>
      </div>
    </Container>
  );
};
