import React from 'react';

import './FoundLostPetContactFormInput.css';

const FoundLostPetContactFormInput = ({
  customInputType = 'text',
  customStyleClass = '',
  error = '',
  isMultiLineMessage = false,
  maxLength = 10,
  onChange = () => { },
  placeholder = '',
  value = '',
}) => {
  function onInputValueChange(event) {
    onChange(event);
  }

  if (isMultiLineMessage) {
    return (
      <div className="Found-lost-pet-form-input-container-with-error">
        <textarea
          className={`Found-lost-pet-form-input-multiline ${customStyleClass} ${
            error ? ' Found-lost-pet-form-input-invalid-field' : ''
          }`}
          inputMode={customInputType}
          maxLength={maxLength}
          onChange={onInputValueChange}
          placeholder={placeholder}
          type="text"
          value={value}
        />

        <div className="Found-lost-pet-form-input-footer-container">
          <span className="Found-lost-pet-form-input-error">{error}</span>

          <span className="Found-lost-pet-form-input-counter">
            {value.length}/{maxLength}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="Found-lost-pet-form-input-container-with-error">
      <input
        className={`Found-lost-pet-form-input ${customStyleClass} ${
          error ? ' Found-lost-pet-form-input-invalid-field' : ''}`}
        inputMode={customInputType}
        maxLength={maxLength}
        onChange={onInputValueChange}
        placeholder={placeholder}
        type="text"
        value={value}
      />

      {error ? <span>{error}</span> : null}
    </div>
  );
};

export { FoundLostPetContactFormInput };
