import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ModalContainer from '../../common/ModalContainer';
import InputFile from '../steps/InputFile';
import { ACCEPTED_FILES, ALLOWED_EXT, MAX_SIZE_MB } from '../../../constants';
import { toBase64, doNothing } from '../../../services/utils';
import {
  getClaimByIdRequested,
  fileClaimsAttachmentUpdated,
  fileClaimsAttachmentAdded,
  fileClaimsAttachmentRemoved,
  fileClaimsRestart,
} from '../../../actions';
import { AddFilesButton } from './AddFilesButton';
import { CancelButton } from './CancelButton';
import { SubmitAttachmentButton } from './SubmitAttachmentButton';

const AddAttachmentModal = ({
  setShowModalAddAttachments = () => { },
  showModalAddAttachements = false,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation('claims');
  const store = useSelector(({
    claims,
    fileClaims,
  }) => ({
    attachments: claims.attachmentsByClaim,
    claims,
    fileClaims,
  }), shallowEqual);

  const {
    fileClaims: {
      attachments: fileClaimsAttachments,
      uploadAttLoading,
      uploadAttSuccess,
    },
  } = store;

  const closeButtonClassName = !uploadAttLoading && uploadAttSuccess
    ? 'Btn-close-blue' : '';

  async function convertToBinary({ file, fileKey }) {
    try {
      const binaryDoc = await toBase64(file);

      dispatch(fileClaimsAttachmentUpdated({
        binary: binaryDoc,
        errorMsg: '',
        key: fileKey,
      }));
    } catch (_) {
      doNothing();
    }
  }

  function validateFile(file, maxSize) {
    let errorMsg = '';
    let isValid = true;
    if (file.size > (maxSize * 1024 * 1024)) {
      isValid = false;
      errorMsg = t('largeFile');
    }

    return {
      errorMsg,
      isValid,
    };
  }

  async function addFile(files) {
    for (let i = 0; i < files.length; i += 1) {
      const file = files[i];
      const validation = validateFile(file, MAX_SIZE_MB);
      const extMatch = file.name.match(ALLOWED_EXT);
      const uniqueKey = `${file.name}-${Date.now()}`;

      if (extMatch) {
        dispatch(fileClaimsAttachmentAdded({
          errorMsg: validation.errorMsg,
          extension: extMatch.slice(1)[0],
          filename: file.name,
          key: uniqueKey,
          percentage: 0,
          size: file.size,
          valid: validation.isValid,
        }));

        if (validation.isValid) {
          convertToBinary({ file, fileKey: uniqueKey });
        }
      }
    }
  }

  function handleDeleteFile(fileKeyToRemove) {
    dispatch(fileClaimsAttachmentRemoved(fileKeyToRemove));
  }

  function handleShowModal() {
    dispatch(fileClaimsRestart());
    setShowModalAddAttachments(!showModalAddAttachements);
    if (uploadAttSuccess) {
      dispatch(getClaimByIdRequested({ claimId: location.state.claimId }));
    }
  }

  return (
    <ModalContainer show={showModalAddAttachements}>
      <div className="Modal-add-file-container">
        <InputFile
          acceptFiles={ACCEPTED_FILES}
          files={fileClaimsAttachments}
          isModal
          loadingAtt={uploadAttLoading}
          onFileAdd={addFile}
          onFileDelete={handleDeleteFile}
          successSave={uploadAttSuccess}
        />

        <div className="Input-buttons-add-file-container">
          <div className="Input-add-files">
            {!uploadAttLoading
              && !uploadAttSuccess
              && <AddFilesButton addFile={addFile} />}
          </div>

          <div className={`Input-btts-container ${closeButtonClassName}`}>
            {!uploadAttLoading && (
              <CancelButton handleShowModal={handleShowModal} />)}

            {!uploadAttSuccess && <SubmitAttachmentButton />}
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export { AddAttachmentModal };
