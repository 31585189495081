import { createActions } from 'redux-actions';

const actions = createActions({
  ALL_PETS_GROUPED: (data) => data,
  CHANGE_COVERAGE_MULTIMODAL_FAILED: (data) => data,
  CHANGE_COVERAGE_MULTIMODAL_LOADED: (data) => data,
  CHANGE_COVERAGE_PREVIEW_LOADED: (data) => data,
  CHANGE_DEDUCTIBLE_LEGEND: (data) => data,
  CLEAR_POLICY_NOTIFICATIONS: () => { },
  DEDUCTIBLE_CHANGED: (data) => data,
  GET_CHANGE_COVERAGE_MULTIMODAL: (data) => data,
  GET_CHANGE_COVERAGE_PREVIEW: (data) => data,
  GET_CHANGE_COVERAGE_PREVIEW_ERROR: () => { },
  GET_MULTIBRAND_DEFAULT_POLICY: (data) => data,
  GET_MULTIBRAND_DEFAULT_POLICY_ERROR: () => { },
  GET_MULTIBRAND_DEFAULT_POLICY_LOADED: (data) => data,
  GET_MULTIBRAND_POLICIES: () => { },
  GET_MULTIBRAND_POLICIES_ERROR: () => { },
  GET_MULTIBRAND_POLICIES_LOADED: (data) => data,
  GET_POLICY_DETAIL: (data) => data,
  GET_POLICY_DETAIL_ERROR: () => { },
  GET_POLICY_DETAIL_LOADED: (data) => data,
  GET_WAITING_PERIODS: (data) => data,
  INITIAL_POWER_UPS: (data) => data,
  LOAD_MULTI_MODAL_DATA: (data) => data,
  LOAD_PLAN_SUMMARY_SEE_MORE_INFO: (data) => data,
  LOAD_PLAN_SUMMARY_SEE_MORE_INFO_ATTEMPT: () => { },
  LOAD_POLICIES_OVERVIEW: () => { },
  LOAD_POLICY_NOTIFICATIONS: (data) => data,
  LOAD_WHAT_WE_COVER_INFO: (data) => data,
  LOAD_WHAT_WE_COVER_INFO_ATTEMPT: () => { },
  LOGGED_IN_WITHOUT_PETS_RESET: () => { },
  MULTI_MODAL_DATA_LOADED: (data) => data,
  PETS_GROUPED: (data) => data,
  PLAN_SUMMARY_SEE_MORE_CLICKED: (data) => data,
  PLAN_SUMMARY_SEE_MORE_INFO_LOADED: (data) => data,
  POLICIES_ERROR: (data) => data,
  POLICIES_OVERVIEW_LOADED: (data) => data,
  POLICY_DETAIL_CLEANED: () => {},
  POLICY_NOTIFICATIONS_LOADED: (data) => data,
  POWER_UP_SELECTED: (data) => data,
  QUOTE_POWER_UP_CHANGED: (data) => data,
  QUOTE_TOTAL_RECALCULATED: (data) => data,
  RATE_COVERAGE: (data) => data,
  RATE_COVERAGE_ERROR: () => { },
  RATE_COVERAGE_SUCCESS: (data) => data,
  RATE_QUOTE_UPDATE_ATTEMPT: (data) => data,
  RATE_QUOTE_UPDATE_LOADED: (data) => data,
  REFRESH_POLICIES: () => { },
  REIMBURSEMENT_CHANGED: (data) => data,
  RESTART_RATE: () => { },
  REVIEW_CHANGES_VISIBLE_CHANGED: (data) => data,
  SELECTED_PLAN_CHANGED: (data) => data,
  STORE_ALL_POLICIES: (data) => data,
  STORE_POLICIES: (data) => data,
  SUBMIT_CHANGE_COVERAGE: (data) => data,
  SUBMIT_CHANGE_COVERAGE_FAILED: () => { },
  SUBMIT_CHANGE_COVERAGE_SUCCESS: () => { },
  UPDATE_POWER_UP: (data) => data,
  VALIDATE_CHANGE_COVERAGE: (data) => data,
  VALIDATE_CHANGE_COVERAGE_CLEARED: (data) => data,
  VALIDATE_CHANGE_COVERAGE_FAILED: (data) => data,
  VALIDATE_CHANGE_COVERAGE_SUCCESS: (data) => data,
  WAITING_PERIODS_LOADED: (data) => data,
  WHAT_WE_COVER_INFO_LOADED: (data) => data,
  YOUNG_PET_MODAL_CLOSED: () => { },
  YOUNG_PET_MODAL_OPEN: () => { },
});

export const {
  allPetsGrouped,
  changeCoverageMultimodalFailed,
  changeCoverageMultimodalLoaded,
  changeCoveragePreviewLoaded,
  changeDeductibleLegend,
  clearPolicyNotifications,
  deductibleChanged,
  getChangeCoverageMultimodal,
  getChangeCoveragePreview,
  getChangeCoveragePreviewError,
  getMultibrandDefaultPolicy,
  getMultibrandDefaultPolicyError,
  getMultibrandDefaultPolicyLoaded,
  getMultibrandPolicies,
  getMultibrandPoliciesError,
  getMultibrandPoliciesLoaded,
  getPolicyDetail,
  getPolicyDetailError,
  getPolicyDetailLoaded,
  getWaitingPeriods,
  initialPowerUps,
  loadMultiModalData,
  loadPlanSummarySeeMoreInfo,
  loadPlanSummarySeeMoreInfoAttempt,
  loadPoliciesOverview,
  loadPolicyNotifications,
  loadWhatWeCoverInfo,
  loadWhatWeCoverInfoAttempt,
  loggedInWithoutPetsReset,
  multiModalDataLoaded,
  petsGrouped,
  planSummarySeeMoreClicked,
  planSummarySeeMoreInfoLoaded,
  policiesError,
  policiesOverviewLoaded,
  policyDetailCleaned,
  policyNotificationsLoaded,
  powerUpSelected,
  quotePowerUpChanged,
  quoteTotalRecalculated,
  rateCoverage,
  rateCoverageError,
  rateCoverageSuccess,
  rateQuoteUpdateAttempt,
  rateQuoteUpdateLoaded,
  refreshPolicies,
  reimbursementChanged,
  restartRate,
  reviewChangesVisibleChanged,
  selectedPlanChanged,
  storeAllPolicies,
  storePolicies,
  submitChangeCoverage,
  submitChangeCoverageFailed,
  submitChangeCoverageSuccess,
  updatePowerUp,
  validateChangeCoverage,
  validateChangeCoverageCleared,
  validateChangeCoverageFailed,
  validateChangeCoverageSuccess,
  waitingPeriodsLoaded,
  whatWeCoverInfoLoaded,
  youngPetModalClosed,
  youngPetModalOpen,
} = actions;
