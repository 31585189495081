import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ScrollableDiv from '../../common/ScrollableDiv';
import Shimmer from '../../common/Shimmer';
import { MyPetsVetCard } from './MyPetsVetCard';
import { MyPetsAddVetModal } from '../addVetModal/MyPetsAddVetModal';
import { GenericModal } from '../../common/petCloud/GenericModal';
import { getAllFavorites, removeVet } from '../../../actions/myVets';

import { PetCloudBreadcrumbs } from '../common/PetCloudBreadcrumbs';
import addVetIcon from '../../../assets/myVets/add.svg';

import './MyVetsComponent.css';

const MyVetsComponent = ({ petName }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('myPets');
  const [loadingDelete, setLoadingDelete] = useState(false);
  const store = useSelector(({ myVets }) => ({
    favoriteVets: myVets.favoriteVets,
    isLoadingFavorites: myVets.isLoadingFavorites,
    isRemovingPet: myVets.isRemovingPet,
    removePetSuccess: myVets.removePetSuccess,
    vetCustomSaved: myVets.vetCustomSaved,
  }), shallowEqual);
  const [selectedVetToRemove, setSelectedVetToRemove] = useState(null);
  const [addVetModalOpen, setAddVetModalOpen] = useState(false);
  const [selectedVet, setSelectedVet] = useState(null);

  useEffect(() => {
    dispatch(getAllFavorites());
  }, [dispatch]);

  useEffect(() => {
    if (store.removePetSuccess) {
      dispatch(getAllFavorites());
      setSelectedVetToRemove(null);
    }
  }, [dispatch, store.removePetSuccess]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (store.removePetSuccess) {
      setLoadingDelete(false);
    }
  }, [store.removePetSuccess]);

  const showRemovePetCard = (vet) => () => {
    setSelectedVetToRemove(vet);
  };

  function hideRemovePetCard() {
    if (!store.isRemovingPet) {
      setSelectedVetToRemove(null);
    }
  }

  function handleAddVetClick() {
    setAddVetModalOpen(true);
  }

  function handleCloseAddVetModal() {
    setAddVetModalOpen(false);
  }

  function handleRemoveVet() {
    if (!store.isRemovingPet) {
      setLoadingDelete(true);
      dispatch(removeVet({ placeId: selectedVetToRemove.PlaceId }));
    }
  }

  useEffect(() => {
    if (store.vetCustomSaved) {
      handleCloseAddVetModal();
    }
  }, [store.vetCustomSaved]);

  return (
    <div className="My-pets-vets-container Flex Flex-column">
      <div className="breadcrumb-container">
        <PetCloudBreadcrumbs
          activeRoute={t('MyVets.title')}
          rootRoute={petName}
        />
      </div>

      <div className="My-pets-vets-header Flex">
        <h2 className="My-pets-vets-title">
          {t('MyVets.title')}
        </h2>

        <button
          className="My-pets-vets-add-vet-button"
          onClick={handleAddVetClick}
          type="button"
        >
          <span className="underline-wrapper">
            <img
              alt=""
              className="My-pets-vets-add-vet-button-icon"
              src={addVetIcon}
            />

            {t('MyVets.addaVet')}
          </span>
        </button>
      </div>

      <ScrollableDiv className="My-pets-vets-cards-container">
        <Shimmer
          className="Flex Flex-column"
          height={107}
          visible={store.isLoadingFavorites}
          width={384}
        >
          {store.favoriteVets.map((vet) => {
            const {
              Address1,
              Address2,
              City,
              Phone,
              PlaceId,
              PlaceName,
              ProviderId,
              State,
              Thumbnail,
            } = vet;

            return (
              selectedVetToRemove?.PlaceId === PlaceId ? (
                <GenericModal
                  description={t('MyVets.removeVetDescription')}
                  handleOutClick={hideRemovePetCard}
                  isDeleteAction
                  loadingPrimary={loadingDelete}
                  onPrimaryClick={handleRemoveVet}
                  onSecondaryClick={hideRemovePetCard}
                  primaryButtonText={t('MyVets.deleteBtn')}
                  secondaryButtonText={t('MyVets.keepVetBtn')}
                  show={!!selectedVetToRemove}
                  title={t('Delete Vet')}
                />
              )
                : (
                  <MyPetsVetCard
                    key={PlaceId}
                    handleRemoveVet={showRemovePetCard(vet)}
                    providerId={ProviderId}
                    vetAddress1={Address1}
                    vetAddress2={Address2}
                    vetCity={City}
                    vetImage={Thumbnail}
                    vetName={PlaceName}
                    vetState={State}
                    vetTelephone={Phone}
                  />
                )
            );
          })}
        </Shimmer>
      </ScrollableDiv>

      {addVetModalOpen && (
        <MyPetsAddVetModal
          onClose={handleCloseAddVetModal}
          onSelectedVet={setSelectedVet}
          selectedVet={selectedVet}
          setAddVetModalOpen={setAddVetModalOpen}
        />
      )}
    </div>
  );
};

export { MyVetsComponent };
