const CONTACT_US_STEPS = {
  AKC: 'akc',
  FIGO: 'figo',
  HOME: 'home',
  PPI: 'ppi',
  PPI_ONE_PACK: 'ppiOnePack',
};

const CONTACT_US_STEPS_BY_NUMBER = {
  0: 'home',
  1: 'figo',
  2: 'ppi',
  3: 'akc',
  4: 'ppiOnePack',
};

const MESSENGER_LINK = 'http://m.me/FigoPet';

const FACEBOOK_TITLE = 'Facebook';

export {
  FACEBOOK_TITLE,
  MESSENGER_LINK,
  CONTACT_US_STEPS,
  CONTACT_US_STEPS_BY_NUMBER,
};
