import React from 'react';

import './ContactInformationRow.css';

const ContactInformationRow = ({
  leftText = '',
  link = null,
  rightText = '',
}) => (
  <div className="Contact-information-row-container">
    <span className="Contact-information-left-text">
      {leftText}
    </span>

    {link
      ? (
        <a
          className="Contact-information-link"
          href={link}
          rel="noopener noreferrer"
          target="_blank"
        >
          {rightText}
        </a>
      )
      : (
        <span className="Contact-information-right-text">
          {rightText}
        </span>
      )}
  </div>
);

export { ContactInformationRow };
