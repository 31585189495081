import React from 'react';

import './FileClaimbotEvie.css';

const FileClaimbotEvie = ({
  agentImage = '',
  agentText = '',
}) => {
  // weird as hell
  const messageWithFormat = (text) => {
    const anchorRegex = /<a\s+href="([^"]*)"\s*>((?:.(?!<\/a>))*.)<\/a>/g;
    const parts = text.split(anchorRegex);
    const result = [];

    for (let i = 0; i < parts.length; i += 3) {
      result.push(parts[i]);

      if (parts[i + 1]) {
        result.push((
          <a
            key={String(i + 1)}
            href={parts[i + 1]}
            rel="noopener noreferrer"
            target="_blank"
          >
            {parts[i + 2]}
          </a>
        ));
      }
    }

    return result;
  };

  return (
    <div className="File-claimbot-evie">
      <img alt="" src={agentImage} />

      <div className="File-claimbot-evie-bubble-message">
        <span>{messageWithFormat(agentText)}</span>
      </div>
    </div>
  );
};
export { FileClaimbotEvie };
