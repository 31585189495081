import React from 'react';
import { useTranslation } from 'react-i18next';
import blueCircleIcon from '../../../assets/changeCoverage/blue-circle.svg';
import { HtmlContent } from '../../common/HtmlContent';

import './PowerupCard.css';

const PowerupCard = ({
  detail = '',
  handleLearnMoreClick = () => { },
  included = false,
  title = '',
}) => {
  const { t } = useTranslation('changeCoverage');

  return (
    <div className="Change-coverage-powerup-card">
      <div className="Change-coverage-powerup-card-top-row">
        <h4>{title}</h4>

        <div className="Change-coverage-powerup-card-status-pill">
          <img alt="" src={blueCircleIcon} />

          <span>{t(`preview.${included ? 'included' : 'notIncluded'}`)}</span>
        </div>
      </div>

      <HtmlContent
        classNameContainer="Change-coverage-powerup-card-detail"
        content={detail}
        elementType="p"
      />

      <button
        className="Change-coverage-powerup-card-learn-more"
        onClick={handleLearnMoreClick}
        type="button"
      >
        <span>{t('preview.learnMore')}</span>
      </button>
    </div>
  );
};

export { PowerupCard };
