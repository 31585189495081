import { handleActions } from 'redux-actions';

const INITIAL_STATE = {
  attachmentsByClaim: [],
  claimById: {
    AccountInfo: '',
    BilledAmount: '',
    ClaimId: '',
    ClaimNumber: '',
    ClaimStatus: '',
    Description: '',
    LossDate: '0000-00-00T00:00:00.-05:00',
    NumberOfAttachments: 0,
    PaidAmount: '',
    Pet: { Name: '' },
    PolicyNumber: '',
    ReportedDate: '0000-00-00T00:00:00.-05:00',
  },
  claimsResults: {
    closed: {
      items: [],
      totalCount: 0,
    },
    open: {
      items: [],
      totalCount: 0,
    },
  },
  data: [],
  error: null,
  isCheckedOptionClosed: true,
  isCheckedOptionOpen: true,
  isFirstTimeOnClaimsScreen: true,
  isLoadingClaimsAfterCreatingOne: false,
  isLoadingDetails: true,
  isLoadingMore: false,
  loading: true,
  loadingClaimSteps: false,
  newClaim: {
    eligiblePets: [],
    // for api/Bot/GetClaimTypeSteps/0/1
    isLoadedClaimTypes: false,
    petId: -1,
    policyNumber: '',
    steps: [],
  },
  // TODO: Remove this variable when new claims design is complete
  newClaimModalVisible: false,
  newClaimStarted: false,
  petID: 0,
  petIndex: 0,
  policySelected: 0,
  totalClaims: 0,
};

export default handleActions({
  CLAIM_REQUESTED: (state) => ({
    ...state,
    isLoadingDetails: true,
  }),
  CLAIMS_RESULTS_LOADED_ERROR: (state, { payload }) => ({
    ...state,
    error: payload,
    loading: false,
  }),

  CLAIMS_RESULTS_LOADED_MORE: (state, { payload }) => {
    const { status } = payload;
    return {
      ...state,
      claimsResults: {
        ...state.claimsResults,
        [status]: {
          ...state.claimsResults[status],
          items: [
            ...state.claimsResults[status].items,
            ...payload.data.Items,
          ],
        },
      },
      error: null,
      loading: false,
    };
  },

  CLAIMS_RESULTS_LOADED_SUCCESS: (state, { payload }) => ({
    ...state,
    claimsResults: {
      ...state.claimsResults,
      [payload.status]: {
        items: payload.data?.Items || [],
        totalCount: payload.data.TotalCount,
      },
    },
    error: null,
    loading: false,
    status: payload.status,
  }),
  DO_LOGOUT: () => ({
    ...INITIAL_STATE,
  }),
  FAILED_TO_LOAD_CLAIM_STEPS: (state) => ({
    ...state,
    loadingClaimSteps: false,
  }),
  FILE_CLAIM_COMPLETED: (state) => ({
    ...state,
    isLoadingClaimsAfterCreatingOne: true,
  }),
  GET_CLAIM_ATTACHMENTS_LOADED: (state, action) => ({
    ...state,
    attachmentsByClaim: action.payload,
    isLoadingDetails: false,
  }),
  GET_CLAIM_BY_ID_FAILED: (state) => ({
    ...state,
    isLoadingDetails: false,
  }),
  GET_CLAIM_BY_ID_LOADED: (state, action) => ({
    ...state,
    claimById: {
      ...action.payload,
      Pet: { ...action.payload.Pet },
    },
    isLoadingDetails: false,
  }),
  GET_CLAIM_BY_ID_REQUESTED: (state) => ({
    ...state,
    isLoadingDetails: true,
  }),
  GET_CLAIMS: (state, { payload }) => ({
    ...state,
    isLoadingMore: payload.loadMore,
    loading: !payload.loadMore,
  }),
  GET_CLAIMS_FAILED: (state, action) => ({
    ...state,
    error: action.payload,
    isLoadingClaimsAfterCreatingOne: false,
    loading: false,
  }),
  GET_CLAIMS_LOADED: (state, { payload }) => ({
    ...state,
    data: payload.loadMore ? [
      ...state.data,
      ...payload.claims,
    ] : payload.claims,
    isLoadingClaimsAfterCreatingOne: false,
    isLoadingMore: false,
    loading: false,
    totalClaims: payload.totalClaims,
  }),
  IS_FIRST_TIME_ON_CLAIMS: (state, action) => ({
    ...state,
    isFirstTimeOnClaimsScreen: action.payload,
  }),
  LOAD_NEW_CLAIM_STEPS: (state) => ({
    ...state,
    loadingClaimSteps: true,
  }),
  NEW_CLAIM_CANCELLED: (state) => ({
    ...state,
    newClaim: {
      ...state.newClaim,
      date: 0,
    },
    newClaimModalVisible: false,
    newClaimStarted: false,
  }),
  NEW_CLAIM_STEPS_LOADED: (state, action) => ({
    ...state,
    loadingClaimSteps: false,
    newClaim: {
      ...state.newClaim,
      eligiblePets: action.payload
        .find((s) => s.KeyName === 'select_pet').InputData.PetsInfoMultiple
        .map((ss) => ({
          ...ss,
          petValidDates: ss.petValidDates.sort((a, b) => {
            const date = new Date(a.EffectiveDate.replace('.', '')).getTime();
            const date2 = new Date(b.EffectiveDate.replace('.', '')).getTime();

            return date > date2 ? 1 : -1;
          }),
        })),
      isLoadedClaimTypes: true,
      steps: action.payload,
    },
    newClaimModalVisible: true,
    newClaimStarted: true,
  }),
  POLICY_SELECTED_ON_CLAIMS: (state, action) => ({
    ...state,
    policySelected: action.payload,
  }),
  RESET_CHECKED_OPTION: (state) => ({
    ...state,
    isCheckedOptionClosed: true,
    isCheckedOptionOpen: true,
  }),
  RESTART_CLAIMS_DETAILS: (state) => ({
    ...state,
    attachmentsByClaim: [],
    claimById: INITIAL_STATE.claimById,
  }),
  SAVE_SELECTED_PET: (state, action) => ({
    ...state,
    newClaim: {
      ...state.newClaim,
      ...action.payload,
      isLoadedClaimTypes: true,
    },
    newClaimModalVisible: false,
  }),
  SET_IS_CHECKED_OPTION_CLOSED: (state, action) => ({
    ...state,
    isCheckedOptionClosed: action.payload,
  }),
  SET_IS_CHECKED_OPTION_OPEN: (state, action) => ({
    ...state,
    isCheckedOptionOpen: action.payload,
  }),
  SET_SELECTED_PET_ID: (state, action) => ({
    ...state,
    petID: action.payload,
  }),
  SET_SELECTED_PET_INDEX: (state, action) => ({
    ...state,
    petIndex: action.payload,
  }),
}, INITIAL_STATE);
