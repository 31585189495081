import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DogIcon }
from '../../../../assets/policy/dog-selected.svg';
import { ReactComponent as CatIcon }
from '../../../../assets/policy/cat-selected.svg';
import arrowRight
from '../../../../assets/changeCoverage/arrow-right-black.svg';

import {
  currencyFormat,
  possesiveAdaptationI18n,
} from '../../../../services/utils';

import '../css/ChangeCoveragePreviewSubmit.css';

const PreviewSubmitUpdatedPlan = ({
  annualPrice = '',
  fees = [],
  initialCoverageValues = '',
  initialTotalPriceWithFees,
  isAnnual = false,
  isDog = true,
  monthlyPrice = '',
  petInfo = '',
  powerUps = [],
  quoteSelectedValues = '',
  totalPriceWithFees = '',
}) => {
  const { t } = useTranslation('changeCoverage');
  const IconPet = isDog ? DogIcon : CatIcon;

  function renderName({ initialValue, text, updatedValue }) {
    const classNameValue = initialValue !== updatedValue
      ? 'Preview-plan-info-name-changed' : 'Preview-plan-info-name';

    return <span className={classNameValue}>{text}</span>;
  }

  function renderValues({ initialValue, updatedValue }) {
    if (initialValue !== updatedValue) {
      return (
        <div className="Preview-plan-info-value-row">
          <span className="Preview-plan-info-value-changed">
            {initialValue}
          </span>

          <img alt="" src={arrowRight} />

          <span className="Preview-plan-info-value">
            {updatedValue}
          </span>
        </div>
      );
    }

    return (
      <span className="Preview-plan-info-initial-value">
        {initialValue}
      </span>
    );
  }

  return (
    <div className="Preview-submit-plan-container">
      <div className="Preview-submit-plan-top-row">
        <IconPet className="Preview-pet-icon" />

        <div className="Preview-pet-name-updated-plan">
          <span>{`${petInfo.petName}'s`}</span>

          <span>{t('preview.updatedPlan')}</span>
        </div>
      </div>

      <div className="Preview-plan-info-container">
        <div className="Preview-plan-info-row">
          {renderName({
            initialValue: initialCoverageValues.selectedAnnualBenefit,
            text: t('reviewChangesModal.annualBenefit'),
            updatedValue: quoteSelectedValues.selectedAnnualBenefit,
          })}

          {renderValues({
            initialValue: initialCoverageValues.selectedAnnualBenefit,
            updatedValue: quoteSelectedValues.selectedAnnualBenefit,
          })}
        </div>

        <div className="Preview-plan-info-row">
          {renderName({
            initialValue: initialCoverageValues.reimbursementDescription,
            text: t('reviewChangesModal.reimbursement'),
            updatedValue: quoteSelectedValues.reimbursementDescription,
          })}

          {renderValues({
            initialValue: initialCoverageValues.reimbursementDescription,
            updatedValue: quoteSelectedValues.reimbursementDescription,
          })}
        </div>

        <div className="Preview-plan-info-row">
          {renderName({
            initialValue: initialCoverageValues.deductibleDescription,
            text: t('reviewChangesModal.deductible'),
            updatedValue: quoteSelectedValues.deductibleDescription,
          })}

          {renderValues({
            initialValue: initialCoverageValues.deductibleDescription,
            updatedValue: quoteSelectedValues.deductibleDescription,
          })}
        </div>

        <div className="Preview-plan-info-powerups-container">
          {powerUps.map((powerup) => {
            const initialPowerup = initialCoverageValues
              .InsuranceModifiers.find((initial) => initial.id === powerup.id);

            return (
              <div key={powerup.id} className="Preview-plan-info-row">
                {renderName({
                  initialValue: initialPowerup.isIncluded
                    ? t('reviewChangesModal.included')
                    : t('reviewChangesModal.notIncluded'),
                  text: powerup.name,
                  updatedValue: powerup.isIncluded
                    ? t('reviewChangesModal.included')
                    : t('reviewChangesModal.notIncluded'),
                })}

                {renderValues({
                  initialValue: initialPowerup.isIncluded
                    ? t('reviewChangesModal.included')
                    : t('reviewChangesModal.notIncluded'),
                  updatedValue: powerup.isIncluded
                    ? t('reviewChangesModal.included')
                    : t('reviewChangesModal.notIncluded'),
                })}
              </div>
            );
          })}
        </div>

        <div className="Preview-plan-info-row">
          {renderName({
            initialValue: isAnnual ? `$${initialCoverageValues.totalAnnual}`
              : `$${initialCoverageValues.totalMonthly}`,
            text: isAnnual
              ? t('reviewChangesModal.annualPremium')
              : t('reviewChangesModal.monthlyPremium'),
            updatedValue: isAnnual ? annualPrice : monthlyPrice,
          })}

          {renderValues({
            initialValue: isAnnual ? `$${initialCoverageValues.totalAnnual}`
              : `$${initialCoverageValues.totalMonthly}`,
            updatedValue: isAnnual ? annualPrice : monthlyPrice,
          })}
        </div>

        <div className="Flex Flex-column">
          {fees.map((fee, index) => (
            <div
              key={String(index)}
              className="Preview-plan-info-row"
            >
              <span className="Preview-plan-info-name">{fee.description}</span>

              <span className="Preview-plan-info-initial-value">
                {currencyFormat({ value: fee.amount })}
              </span>
            </div>
          ))}
        </div>

        <div className="Preview-plan-info-row">
          {renderName({
            initialValue: initialTotalPriceWithFees,
            text: t('preview.petTotal', {
              count: possesiveAdaptationI18n(petInfo.petName),
              name: petInfo.petName,
            }),
            updatedValue: totalPriceWithFees,
          })}

          {renderValues({
            initialValue: initialTotalPriceWithFees,
            updatedValue: totalPriceWithFees,
          })}
        </div>
      </div>
    </div>
  );
};

export { PreviewSubmitUpdatedPlan };
