import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import TouchableDiv from '../../../common/TouchableDiv';
import ScrollableDiv from '../../../common/ScrollableDiv';
import Checkbox from '../../../common/Checkbox';

import arrowDown
from '../../../../assets/inboxDocsMultibrand/arrow-down-black.svg';

import './PetSelectorMultisSelection.css';

const PetSelectorMultisSelection = ({
  classContainer = '',
  itemList = [],
  itemListChecked = [],
  onItemClick = () => {},
  isModal = false,
  onResetFilterClick = () => { },
  title,
}) => {
  const { t } = useTranslation('inboxAndDocs');
  const [open, setOpen] = useState(false);

  function toggle() {
    setOpen(!open);
  }

  function handleDropDownContainer() {
    toggle();
  }

  const handleOnClickItem = (item) => () => {
    onItemClick(item);
  };

  function handleOutsideClick() {
    setOpen(false);
  }

  const renderHeaderDropdown = () => (
    <div className="Pet-selector-new-selected-item">
      <p>{title}</p>
    </div>
  );

  const ItemListElement = ({ item }) => (
    <li
      aria-selected="false"
      className="Pet-selector-multi-list-item-container"
      id={`dropdownItem${item.Id}`}
      onClick={handleOnClickItem(item)}
      onKeyPress={handleOnClickItem(item)}
      role="option"
      tabIndex={-1}
    >
      <Checkbox
        checked={itemListChecked.includes(item)}
        className="Pet-selector-multi-list-item"
        isRounded={false}
        label={item.Name}
        onClick={handleOnClickItem(item)}
      />
    </li>
  );

  function renderItems() {
    return (
      <ScrollableDiv
        className={`Pet-selector-multi-list ${
          open ? '' : 'Pet-selector-multi-list-hidden'}`}
      >
        <TouchableDiv
          ariaProps={{ 'aria-hidden': true }}
          className="Pet-selector-multi-list-overlayed-container"
          id="dropdownAddPet"
          onClick={handleOutsideClick}
          tabIndex={0}
        />
        <ul aria-labelledby="addPetDropdownContainer">
          {itemList.map((item) => (
            <ItemListElement key={String(item.PetId)} item={item} />
          ))}
        </ul>
      </ScrollableDiv>
    );
  }

  const petSelector = () => (
    <div className={`Pet-selector-new-container ${classContainer}`}>
      <div
        className="Pet-selector-new-header"
        id="addPetDropdownContainer"
        onClick={handleDropDownContainer}
        onKeyPress={handleDropDownContainer}
        role="button"
        tabIndex={0}
      >
        {renderHeaderDropdown()}

        <div
          className="Pet-selector-multi-arrow-container No-clicked"
          id="arrowContainer"
          tabIndex={-1}
        >
          <img
            alt="arrow"
            aria-hidden="true"
            className={'Pet-selector-new-arrow-closed '
              + `${open ? 'Pet-selector-new-arrow-open' : ''}`}
            src={arrowDown}
          />
        </div>
      </div>

      {renderItems()}
    </div>
  );

  return (
    <>
      <div
        className={`Pet-selector-new-main-container ${
          isModal ? 'Full-width' : ''}`}
      >
        {petSelector()}

        {!isModal && (
          <div className="Pet-selector-new-row">
            <div className="Pet-selector-new-separator" />

            <button
              className="Pet-selector-new-reset-label"
              onClick={onResetFilterClick}
              type="button"
            >
              {t('reset')}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export { PetSelectorMultisSelection };
