import React from 'react';
import FigoLogo from '../../assets/figo-logo-white.svg';
import { RenewalFloatingBarTop }
from '../changeCoverage/changeCoverageOptions/RenewalFloatingBarTop';
import '../common/HeaderCommon.css';

const MobileHeader = () => {
  const pathname = window.location.pathname;

  function renderFloatingBar() {
    if (!pathname.includes('/options')) {
      return null;
    }

    return <RenewalFloatingBarTop />;
  }

  return (
    <div className="Main-header-mobile">
      <img
        alt="figo logo"
        className={'Figo-common-header-logo-mobile'
          + `${pathname.includes('/options')
            ? ' Figo-common-header-logo-mobile-cc' : ''}`}
        src={FigoLogo}
      />

      {renderFloatingBar()}
    </div>
  );
};

export { MobileHeader };
