import React from 'react';

export const InfoBlock = ({
  title = '',
  value = '',
}) => (
  <div className="Info-block__container">
    <h5 className="Info-block__title">{title}</h5>

    <div className="Info-block__value">{value}</div>
  </div>
);
