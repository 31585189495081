import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  figoTagModalShown,
  getPetTagInfo,
} from '../../../actions';
import FigoLottie from '../../common/FigoLottie';

import addIconBlue from '../../../assets/myPets/add-icon-blue.svg';

import pencilIcon from '../../../assets/myPets/pencil-icon.svg';
import loadingSpinnerBlue
from '../../../assets/lottieFiles/loading-blue.json';

import { PetCloudBreadcrumbs } from '../common/PetCloudBreadcrumbs';
import { maskPhoneNumber } from '../../../services/utils';

import { PetTagModal } from './PetTagModal';

import './PetTagScreen.css';

const PetTagScreen = () => {
  const { t } = useTranslation('myPets');

  const dispatch = useDispatch();
  const store = useSelector(
    ({
      figoPetTag,
      myPets,
    }) => ({
      figoPetTag,
      myPets,
    }), shallowEqual,
  );

  const {
    figoPetTag: {
      data: { MessageToFinder, TagNumber, EmergencyContacts },
      isFigoTagModalVisible,
      isLoadingPetInfo,
    },
    myPets: { petProfile, petProfileLoading, selectedPetId },
  } = store;

  const { Name = '', Id = '' } = petProfile;

  const isLoading = petProfileLoading || isLoadingPetInfo;
  const petId = Id || selectedPetId;

  useEffect(() => {
    if (!isFigoTagModalVisible && petId) {
      dispatch(getPetTagInfo(petId));
    }
  }, [dispatch, isFigoTagModalVisible, petId]);

  function openPetTagModal() {
    dispatch(figoTagModalShown());
  }

  function renderHeader() {
    return (
      <div className="Pet-tag-screen-header-container">
        <h1 className="Pet-tag-screen-page-title">
          {t('PetTag.title')}
        </h1>

        {!TagNumber && (
          <button
            className="Pet-tag-screen-button-add-tag"
            onClick={openPetTagModal}
            type="button"
          >
            <img
              alt=""
              className="Pet-tag-screen-button-image"
              src={addIconBlue}
            />

            <span>{t('PetTag.addPetTag')}</span>
          </button>
        )}
      </div>
    );
  }

  function renderEmergencyContacts() {
    if (!EmergencyContacts.length) {
      return null;
    }

    return EmergencyContacts.map((contact) => {
      if (contact.Name) {
        return (
          <span key={`${contact.Id}`}>{t('PetTag.emergencyContact', {
            name: contact.Name,
            phoneNumber: maskPhoneNumber(contact.PhoneNumber),
          })}
          </span>
        );
      }

      return null;
    });
  }

  function renderCardInformation() {
    if (!TagNumber) return null;

    return (
      <div>
        <div className="Pet-tag-screen-card">
          <div className="Pet-tag-screen-card-left-side">
            <div className="Pet-tag-screen-information">
              <div className="Pet-tag-screen-information-item">
                <h4>{t('PetTag.tagNumberTitle')}</h4>

                <span>{TagNumber}</span>
              </div>

              <div className="Pet-tag-screen-information-item">
                <h4>{t('PetTag.messageToFinderTitle')}</h4>

                <p>{MessageToFinder}</p>
              </div>

              <div className="Pet-tag-screen-information-item">
                <h4>{t('PetTag.emergencyContactsTitle')}</h4>

                <div className="Pet-tag-emergency-contacts-container">
                  {renderEmergencyContacts()}
                </div>
              </div>
            </div>
          </div>

          <div>
            <button
              className="Pet-tag-screen-button-edit-tag"
              onClick={openPetTagModal}
              type="button"
            >
              <img
                alt=""
                className="Pet-tag-screen-button-image"
                src={pencilIcon}
              />

              <span>{t('PetTag.editDetails')}</span>
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="My-pets-loading-container">
        <FigoLottie
          animationData={loadingSpinnerBlue}
          height={50}
          width={50}
        />
      </div>
    );
  }

  return (
    <div className="Pet-tag-screen-container">
      <PetCloudBreadcrumbs
        activeRoute={t('PetTag.petTag')}
        rootRoute={Name}
      />

      {renderHeader()}

      {renderCardInformation()}

      <PetTagModal isVisible={isFigoTagModalVisible} petId={petId} />
    </div>
  );
};

export { PetTagScreen };
