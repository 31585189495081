import React from 'react';
import { useTranslation } from 'react-i18next';
import creditCard from '../../../assets/billingPayments/credit-card.svg';
import savingBank from '../../../assets/billingPayments/saving-bank.svg';
import LoadingActionButton from '../../common/petCloud/LoadingActionButton';
import addIcon from '../../../assets/policy/add-white.svg';

const CreditCard = ({
  cardNumber = '',
  expirationDate = '',
  t,
}) => (
  <div className="Payment-method__card">
    <img alt="credit-card" src={creditCard} />

    <span>{t('cardEnding', { cardNumber })}</span>

    <span>{t('expirationDate', {
      expirationDate,
      interpolation: {
        escapeValue: false,
      },
    })}
    </span>
  </div>
);

const SavingBank = ({
  bankNumber = '',
  t,
}) => (
  <div className="Payment-method__card">
    <img alt="saving-bank" src={savingBank} />

    <span>{t('bankEnding', { bankNumber })}</span>
  </div>
);

const AddPaymentMethod = ({
  isLoading = false,
  isRedirectAvailable = false,
  onAddPaymentMethod = () => { },
  t,
}) => {
  const handlePaymentMethod = () => {
    onAddPaymentMethod();
  };

  return (
    <div className="Payment-method__card">
      <span className="Payment-method__text">
        {`${t('noPaymentMethodSet')} ${isRedirectAvailable
          ? t('addMethod')
          : ''}`}
      </span>

      {isRedirectAvailable && (
        <LoadingActionButton
          className="Payment-method__add-button"
          loading={isLoading}
          onClick={handlePaymentMethod}
          type="button"
        >
          <img alt="" src={addIcon} />

          <span>{t('addPaymentMethod')}</span>
        </LoadingActionButton>
      )}
    </div>
  );
};

export const PaymentMethodCard = ({
  expirationDate = '',
  isLoading = false,
  isRedirectAvailable = false,
  lastNumbers = '',
  methodType = '',
  onAddPaymentMethod = () => { },
}) => {
  const { t } = useTranslation('insuranceScreen');

  const paymentMethods = {
    creditCard: (
      <CreditCard
        cardNumber={lastNumbers}
        expirationDate={expirationDate}
        t={t}
      />
    ),
    savingBank: (
      <SavingBank
        bankNumber={lastNumbers}
        t={t}
      />
    ),
  };

  return paymentMethods[methodType] || (
    <AddPaymentMethod
      isLoading={isLoading}
      isRedirectAvailable={isRedirectAvailable}
      onAddPaymentMethod={onAddPaymentMethod}
      t={t}
    />
  );
};
