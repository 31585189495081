import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import uploadIcon from '../../../assets/upload-circle.svg';
import errorIcon from '../../../assets/error.svg';
import successIcon from '../../../assets/success.svg';
import loadingSpinner from '../../../assets/loading-whites.json';
import { delay } from '../../../services/utils';
import FigoLottie from '../FigoLottie';
import './InputImageMyPets.css';

const DRAGGING_CLASSNAME = ' My-pets-input-image-container-hover';
const ERROR_CLASSNAME = ' My-pets-input-image-error';

const InputImageMyPets = ({
  acceptFiles,
  classContainer = '',
  error,
  file = null,
  onFileChange = () => { },
}) => {
  const { t } = useTranslation('common');
  const [isDragging, setIsDragging] = useState('');
  const [loading, setLoading] = useState(false);
  const dropzoneRef = useRef();
  const inputRef = useRef();

  const hasError = useMemo(() => (error && !loading
    ? ERROR_CLASSNAME : ''), [error, loading]);

  const simulateDelay = async () => {
    await delay(1000);
    setLoading(false);
  };

  const handleDragover = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleDragenter = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    const { fromElement } = event;
    if (fromElement === dropzoneRef.current
      && event.dataTransfer.items
      && event.dataTransfer.items.length > 0) {
      setIsDragging(DRAGGING_CLASSNAME);
    }
  }, []);

  const handleDragleave = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    const { fromElement } = event;
    const { current: dropzoneElement } = dropzoneRef;
    const { current: inputElement } = inputRef;
    if (fromElement !== dropzoneElement && fromElement !== inputElement) {
      setIsDragging('');
    }
  }, []);

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      setIsDragging('');
      onFileChange(event.dataTransfer.files[0]);
      event.dataTransfer.clearData();
      setLoading(true);
      simulateDelay();
    }
  }, [onFileChange]);

  useEffect(() => {
    window.addEventListener('dragover', handleDragover);
    window.addEventListener('dragenter', handleDragenter);

    return () => {
      window.removeEventListener('dragover', handleDragover);
      window.removeEventListener('dragenter', handleDragenter);
    };
  }, [handleDragenter, handleDragover]);

  useEffect(() => {
    const currentDropzone = dropzoneRef.current;
    if (currentDropzone) {
      currentDropzone.addEventListener('drop', handleDrop);
      currentDropzone.addEventListener('dragleave', handleDragleave);
    }

    return () => {
      if (currentDropzone) {
        currentDropzone.removeEventListener('drop', handleDrop);
        currentDropzone.removeEventListener('dragleave', handleDragleave);
      }
    };
  }, [handleDrop, handleDragleave, isDragging]);

  function handleFileChange(event) {
    const { files } = event.target;
    onFileChange(files[0]);
    setLoading(true);
    simulateDelay();
  }

  function handleDeleteFile() {
    onFileChange(null);
  }

  const getContent = () => {
    if (loading) {
      return (
        <FigoLottie
          animationData={loadingSpinner}
          height={30}
          width={30}
        />
      );
    }

    if (error) {
      return (
        <>
          <img alt="" src={errorIcon} />

          <span className="My-pets-input-image-message
            My-pets-input-image-message-error"
          >
            {t('uploadEditablePhoto.error')}
          </span>

          <span className="My-pets-input-image-extensions">
            {acceptFiles.split(',').join(', ')}
          </span>
        </>
      );
    }

    if (file && !loading) {
      return (
        <>
          <img alt="" src={successIcon} />

          <span className="My-pets-input-image-message">
            {t('uploadEditablePhoto.success')}
          </span>

          <span className="My-pets-input-image-file-name">{file.name}</span>

          <button
            className="My-pets-input-image-delete"
            onClick={handleDeleteFile}
            type="button"
          >
            {t('uploadEditablePhoto.delete')}
          </button>
        </>
      );
    }

    return (
      <>
        <img alt="" className="My-pets-input-image-icon" src={uploadIcon} />

        <span className="My-pets-input-image-instruction-1">
          {t('uploadEditablePhoto.dropFile')}

          <span className="My-pets-input-image-instruction-highlight">
            {t('uploadEditablePhoto.chooseFile')}
          </span>

          {t('uploadEditablePhoto.upload')}
        </span>

        <span className="My-pets-input-image-instruction-2">
          {t('uploadEditablePhoto.fileSize')}
        </span>
      </>
    );
  };

  return (
    <div
      ref={dropzoneRef}
      className={`My-pets-input-image-container ${isDragging}${hasError}${
        (file && !error) || loading
          ? ' My-pets-input-image-success' : ''} ${classContainer}`}
    >
      {(!file || error) && (
        <input
          ref={inputRef}
          accept={acceptFiles}
          onChange={handleFileChange}
          type="file"
        />
      )}

      <div className={`My-pets-input-image-content
        ${!file ? 'No-clicked' : ''}`}
      >
        {getContent()}
      </div>
    </div>
  );
};

export { InputImageMyPets };
