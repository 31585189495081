import { handleActions } from 'redux-actions';

const INITIAL_STATE_PAYMENT_METHOD_DATA = {
  accountNumber: '',
  accountNumberConfirmation: '',
  accountNumberConfirmationError: '',
  accountNumberError: '',
  accountType: 0,
  cardNumber: '',
  cardNumberError: '',
  city: '',
  cityError: '',
  cvvNumber: '',
  cvvNumberError: '',
  expirationDate: '',
  expirationDateError: '',
  firstName: '',
  firstNameError: '',
  lastName: '',
  lastNameError: '',
  paymentMethodSelected: 0,
  routingNumber: '',
  routingNumberError: '',
  streetAddress: '',
  streetAddress2: '',
  streetAddress2Error: '',
  streetAddressError: '',
  zipCode: '',
  zipCodeError: '',
};

const INITIAL_STATE = {
  allBrandsData: [],
  billingDetails: null,
  editReimbursementMethodModalVisible: false,
  error: null,
  isApplyAllPolicies: true,
  loadingAllBrandsData: false,
  loadingBillingInfo: true,
  loadingPaymentHistory: false,
  loadingPolicyDetails: true,
  loadingReimbursementMethod: true,
  noMoreResults: false,
  paymentHistory: [],
  paymentHistoryCurrentPage: 0,
  paymentHistoryPage: 0,
  paymentHistoryPolicyNumber: '',
  paymentHistoryTotalItems: 0,
  paymentHistoryTotalPages: 0,
  paymentMethodData: INITIAL_STATE_PAYMENT_METHOD_DATA,
  paymentMethodEditModalVisible: false,
  paymentMethodErrorModalVisible: false,
  paymentMethodPastDueModalVisible: false,
  paymentMethodSuccessModalVisible: false,
  policiesUpdated: 0,
  policyDetails: null,
  reimbursementData: {
    AccountNumber: '',
    AccountType: 1,
    ConfirmAccountNumber: '',
    PreferredReimbursementMethod: null,
    RoutingNumber: '',
    Type: null,
  },
  reimbursementMethod: null,
  reimbursementMethodUpdateSuccess: false,
  requestUpdatePaymentMethod: {},
  routingNumberError: null,
  selectedPet: 0,
  selectedPolicy: 0,
  updatePaymentMethodMessageResponse: '',
  updateReimbursementMethodError: false,
  updateReimbursementMethodErrorMessage: null,
  updatingPaymentMethod: false,
  updatingReimbursementMethod: false,
};

export default handleActions({
  APPLY_ALL_POLICIES_CHECK_CHANGED: (state, { payload }) => ({
    ...state,
    isApplyAllPolicies: payload,
  }),
  BILLING_INFO_LOAD_FAILED: (state, { payload }) => ({
    ...state,
    error: payload,
    loadingBillingInfo: false,
    loadingPaymentHistory: false,
  }),
  BILLING_INFO_LOADED: (state, { payload }) => ({
    ...state,
    billingDetails: payload,
    error: null,
    loadingBillingInfo: false,
    loadingPaymentHistory: false,
  }),
  BILLING_PAYMENT_RESETED: (state) => ({
    ...state,
    billingDetails: null,
    loadingBillingInfo: true,
  }),
  CHANGE_REIMBURSEMENT_ACCOUNT_NUMBER: (state, action) => ({
    ...state,
    reimbursementData: {
      ...state.reimbursementData,
      AccountNumber: action.payload,
    },
  }),
  CHANGE_REIMBURSEMENT_ACCOUNT_TYPE: (state, action) => ({
    ...state,
    reimbursementData: {
      ...state.reimbursementData,
      AccountType: action.payload,
    },
  }),
  CHANGE_REIMBURSEMENT_CONFIRM_ACCOUNT_NUMBER: (state, action) => ({
    ...state,
    reimbursementData: {
      ...state.reimbursementData,
      ConfirmAccountNumber: action.payload,
    },
  }),
  CHANGE_REIMBURSEMENT_METHOD_TYPE: (state, action) => ({
    ...state,
    reimbursementData: {
      ...state.reimbursementData,
      PreferredReimbursementMethod: action.payload,
      Type: action.payload,
    },
  }),
  CHANGE_REIMBURSEMENT_ROUTING_NUMBER: (state, action) => ({
    ...state,
    reimbursementData: {
      ...state.reimbursementData,
      RoutingNumber: action.payload,
    },
  }),
  CHANGE_ROUTING_NUMBER_ERROR: (state, action) => ({
    ...state,
    routingNumberError: action.payload,
  }),
  CLOSE_EDIT_REIMBURSEMENT_METHOD_MODAL: (state) => ({
    ...state,
    editReimbursementMethodModalVisible: false,
    reimbursementData: {
      ...INITIAL_STATE.reimbursementData,
    },
    reimbursementMethodUpdateSuccess: false,
    selectedReimbursementMethodTab: null,
    updateReimbursementMethodError: false,
  }),
  DO_LOGOUT: () => ({ ...INITIAL_STATE }),
  GET_ALL_BRANDS_DATA: (state) => ({
    ...state,
    loadingAllBrandsData: true,
  }),
  GET_ALL_BRANDS_DATA_FAILED: (state) => ({
    ...state,
    loadingAllBrandsData: false,
  }),
  GET_ALL_BRANDS_DATA_LOADED: (state, action) => ({
    ...state,
    allBrandsData: action.payload,
    loadingAllBrandsData: false,
  }),
  LOAD_PAYMENT_HISTORY: (state) => ({
    ...state,
  }),
  LOAD_POLICY_DETAILS: (state) => ({
    ...state,
    error: null,
    loadingBillingInfo: true,
    loadingPaymentHistory: true,
    loadingPolicyDetails: true,
  }),
  OPEN_EDIT_REIMBURSEMENT_METHOD_MODAL: (state, action) => ({
    ...state,
    editReimbursementMethodModalVisible: true,
    reimbursementData: {
      ...state.reimbursementData,
      PreferredReimbursementMethod: action.payload,
      Type: action.payload,
    },
  }),
  PAYMENT_HISTORY_CLEARED: (state) => ({
    ...state,
    error: null,
    loadingPaymentHistory: true,
    paymentHistory: [],
  }),
  PAYMENT_HISTORY_LOAD_FAILED: (state, { payload }) => ({
    ...state,
    error: payload,
    loadingPaymentHistory: false,
  }),
  PAYMENT_HISTORY_LOADED: (state, { payload: {
    paymentHistoryCurrentPage,
    paymentHistoryData,
    paymentHistoryTotalItems,
    paymentHistoryTotalPages,
  } }) => ({
    ...state,
    error: null,
    loadingPaymentHistory: false,
    noMoreResults: state.paymentHistory.length === paymentHistoryData.length,
    paymentHistory: paymentHistoryData,
    paymentHistoryCurrentPage,
    paymentHistoryPage: paymentHistoryData.length
      && state.paymentHistoryPage + 1,
    paymentHistoryTotalItems,
    paymentHistoryTotalPages,
  }),
  PAYMENT_METHOD_ACCOUNT_NUMBER_CHANGED: (state, { payload }) => ({
    ...state,
    paymentMethodData: {
      ...state.paymentMethodData,
      accountNumber: payload.value,
    },
  }),
  PAYMENT_METHOD_ACCOUNT_NUMBER_CONFIRMATION_CHANGED: (state, { payload }) => ({
    ...state,
    paymentMethodData: {
      ...state.paymentMethodData,
      accountNumberConfirmation: payload.value,
    },
  }),
  PAYMENT_METHOD_ACCOUNT_NUMBER_CONFIRMATION_ERROR_CHANGED:
    (state, { payload }) => ({
      ...state,
      paymentMethodData: {
        ...state.paymentMethodData,
        accountNumberConfirmationError: payload.value,
      },
    }),
  PAYMENT_METHOD_ACCOUNT_NUMBER_ERROR_CHANGED: (state, { payload }) => ({
    ...state,
    paymentMethodData: {
      ...state.paymentMethodData,
      accountNumberError: payload.value,
    },
  }),
  PAYMENT_METHOD_ACCOUNT_TYPE_CHANGED: (state, { payload }) => ({
    ...state,
    paymentMethodData: {
      ...state.paymentMethodData,
      accountType: payload.value,
    },
  }),
  PAYMENT_METHOD_CARD_NUMBER_CHANGED: (state, { payload }) => ({
    ...state,
    paymentMethodData: {
      ...state.paymentMethodData,
      cardNumber: payload.value,
    },
  }),
  PAYMENT_METHOD_CARD_NUMBER_ERROR_CHANGED: (state, { payload }) => ({
    ...state,
    paymentMethodData: {
      ...state.paymentMethodData,
      cardNumberError: payload.value,
    },
  }),
  PAYMENT_METHOD_CITY_CHANGED: (state, { payload }) => ({
    ...state,
    paymentMethodData: {
      ...state.paymentMethodData,
      city: payload.value,
    },
  }),
  PAYMENT_METHOD_CITY_ERROR_CHANGED: (state, { payload }) => ({
    ...state,
    paymentMethodData: {
      ...state.paymentMethodData,
      cityError: payload.value,
    },
  }),
  PAYMENT_METHOD_CVV_NUMBER_CHANGED: (state, { payload }) => ({
    ...state,
    paymentMethodData: {
      ...state.paymentMethodData,
      cvvNumber: payload.value,
    },
  }),
  PAYMENT_METHOD_CVV_NUMBER_ERROR_CHANGED: (state, { payload }) => ({
    ...state,
    paymentMethodData: {
      ...state.paymentMethodData,
      cvvNumberError: payload.value,
    },
  }),
  PAYMENT_METHOD_DATA_CLEARED: (state) => ({
    ...state,
    paymentMethodData: INITIAL_STATE_PAYMENT_METHOD_DATA,
    updatePaymentMethodMessageResponse: '',
  }),
  PAYMENT_METHOD_EDIT_MODAL_VISIBLE_CHANGED: (state, { payload }) => ({
    ...state,
    paymentMethodEditModalVisible: payload.visible,
  }),
  PAYMENT_METHOD_ERROR_MODAL_VISIBLE_CHANGED: (state, { payload }) => ({
    ...state,
    paymentMethodErrorModalVisible: payload.visible,
  }),
  PAYMENT_METHOD_EXPIRATION_DATE_CHANGED: (state, { payload }) => ({
    ...state,
    paymentMethodData: {
      ...state.paymentMethodData,
      expirationDate: payload.value,
    },
  }),
  PAYMENT_METHOD_EXPIRATION_DATE_ERROR_CHANGED: (state, { payload }) => ({
    ...state,
    paymentMethodData: {
      ...state.paymentMethodData,
      expirationDateError: payload.value,
    },
  }),
  PAYMENT_METHOD_FIRST_NAME_CHANGED: (state, { payload }) => ({
    ...state,
    paymentMethodData: {
      ...state.paymentMethodData,
      firstName: payload.value,
    },
  }),
  PAYMENT_METHOD_FIRST_NAME_ERROR_CHANGED: (state, { payload }) => ({
    ...state,
    paymentMethodData: {
      ...state.paymentMethodData,
      firstNameError: payload.value,
    },
  }),
  PAYMENT_METHOD_LAST_NAME_CHANGED: (state, { payload }) => ({
    ...state,
    paymentMethodData: {
      ...state.paymentMethodData,
      lastName: payload.value,
    },
  }),
  PAYMENT_METHOD_LAST_NAME_ERROR_CHANGED: (state, { payload }) => ({
    ...state,
    paymentMethodData: {
      ...state.paymentMethodData,
      lastNameError: payload.value,
    },
  }),
  PAYMENT_METHOD_PAST_DUE_MODAL_VISIBLE_CHANGED: (state, { payload }) => ({
    ...state,
    paymentMethodPastDueModalVisible: payload.visible,
    requestUpdatePaymentMethod: payload.requestUpdatePaymentMethod || {},
  }),
  PAYMENT_METHOD_PAYMENT_METHOD_TYPE_CHANGED: (state, { payload }) => ({
    ...state,
    paymentMethodData: {
      ...state.paymentMethodData,
      paymentMethodSelected: payload.value,
    },
  }),
  PAYMENT_METHOD_ROUTING_NUMBER_CHANGED: (state, { payload }) => ({
    ...state,
    paymentMethodData: {
      ...state.paymentMethodData,
      routingNumber: payload.value,
    },
  }),
  PAYMENT_METHOD_ROUTING_NUMBER_ERROR_CHANGED: (state, { payload }) => ({
    ...state,
    paymentMethodData: {
      ...state.paymentMethodData,
      routingNumberError: payload.value,
    },
  }),
  PAYMENT_METHOD_STATE_CHANGED: (state, { payload }) => ({
    ...state,
    paymentMethodData: {
      ...state.paymentMethodData,
      state: payload.value,
    },
  }),
  PAYMENT_METHOD_STATE_ERROR_CHANGED: (state, { payload }) => ({
    ...state,
    paymentMethodData: {
      ...state.paymentMethodData,
      stateError: payload.value,
    },
  }),
  PAYMENT_METHOD_STREET_ADDRESS_CHANGED: (state, { payload }) => ({
    ...state,
    paymentMethodData: {
      ...state.paymentMethodData,
      streetAddress: payload.value,
    },
  }),
  PAYMENT_METHOD_STREET_ADDRESS_ERROR_CHANGED: (state, { payload }) => ({
    ...state,
    paymentMethodData: {
      ...state.paymentMethodData,
      streetAddressError: payload.value,
    },
  }),
  PAYMENT_METHOD_STREET_ADDRESS2_CHANGED: (state, { payload }) => ({
    ...state,
    paymentMethodData: {
      ...state.paymentMethodData,
      streetAddress2: payload.value,
    },
  }),
  PAYMENT_METHOD_STREET_ADDRESS2_ERROR_CHANGED: (state, { payload }) => ({
    ...state,
    paymentMethodData: {
      ...state.paymentMethodData,
      streetAddress2Error: payload.value,
    },
  }),
  PAYMENT_METHOD_SUCCESS_MODAL_VISIBLE_CHANGED: (state, { payload }) => ({
    ...state,
    paymentMethodSuccessModalVisible: payload.visible,
  }),
  PAYMENT_METHOD_UPDATE_ATTEMPTED: (state) => ({
    ...state,
    updatingPaymentMethod: true,
  }),
  PAYMENT_METHOD_UPDATED: (state, { payload }) => ({
    ...state,
    paymentMethodEditModalVisible: false,
    paymentMethodErrorModalVisible: payload.error,
    paymentMethodSuccessModalVisible: !payload.error,
    policiesUpdated: payload.policiesUpdated,
    updatePaymentMethodMessageResponse: payload.messageResponse,
    updatingPaymentMethod: false,
  }),
  PAYMENT_METHOD_ZIP_CODE_CHANGED: (state, { payload }) => ({
    ...state,
    paymentMethodData: {
      ...state.paymentMethodData,
      zipCode: payload.value,
    },
  }),
  PAYMENT_METHOD_ZIP_CODE_ERROR_CHANGED: (state, { payload }) => ({
    ...state,
    paymentMethodData: {
      ...state.paymentMethodData,
      zipCodeError: payload.value,
    },
  }),
  PET_SELECTED: (state, { payload }) => ({
    ...state,
    paymentHistory: [],
    paymentHistoryPage: 0,
    selectedPet: payload,
  }),
  POLICY_DETAILS_LOAD_FAILED: (state, { payload }) => ({
    ...state,
    error: payload,
    loadingPaymentHistory: false,
    loadingPolicyDetails: false,
  }),
  POLICY_DETAILS_LOADED: (state, { payload }) => ({
    ...state,
    error: null,
    loadingPaymentHistory: false,
    loadingPolicyDetails: false,
    policyDetails: payload,
  }),
  POLICY_SELECTED: (state, { payload }) => ({
    ...state,
    paymentHistory: [],
    paymentHistoryPage: 0,
    selectedPolicy: payload < 0 ? 0 : payload,
  }),
  REIMBURSEMENT_METHOD_ATTEMPT: (state) => ({
    ...state,
    loadingReimbursementMethod: true,
  }),
  REIMBURSEMENT_METHOD_LOADED: (state, { payload }) => ({
    ...state,
    loadingReimbursementMethod: false,
    reimbursementMethod: payload,
  }),
  REIMBURSEMENT_METHOD_ROUTING_VALIDATION_FAILED: (state, action) => ({
    ...state,
    reimbursementMethodUpdateSuccess: false,
    routingNumberError: action.payload,
    updatingReimbursementMethod: false,
  }),
  REIMBURSEMENT_METHOD_UPDATE_FAILED: (state, action) => ({
    ...state,
    editReimbursementMethodModalVisible: false,
    reimbursementData: {
      ...INITIAL_STATE.reimbursementData,
    },
    reimbursementMethodUpdateSuccess: false,
    selectedReimbursementMethodTab: null,
    updateReimbursementMethodError: true,
    updateReimbursementMethodErrorMessage: action.payload,
    updatingReimbursementMethod: false,
  }),
  REIMBURSEMENT_METHOD_UPDATED: (state) => ({
    ...state,
    reimbursementMethodUpdateSuccess: true,
    updatingReimbursementMethod: false,
  }),
  UPDATE_REIMBURSEMENT_METHOD: (state) => ({
    ...state,
    updatingReimbursementMethod: true,
  }),
}, INITIAL_STATE);
