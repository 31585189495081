import { handleActions } from 'redux-actions';

const INITIAL_STATE = {
  addedPetId: null,
  breedData: {
    catBreeds: [],
    dogBreeds: [],
  },
  error: false,
  loading: false,
  message: '',
  microchipCompanies: [],
  microchipData: {},
  petProfile: {},
  petProfileFailed: false,
  petProfileLoading: false,
  selectedPetId: null,
  showMicrochipModal: false,
  success: false,
};

export default handleActions({
  ADD_PET: (state) => ({
    ...state,
    error: false,
    loading: true,
    success: false,
  }),
  ADD_PET_FAILED: (state) => ({
    ...state,
    addedPetId: null,
    error: true,
    loading: false,
    success: false,
  }),
  ADD_PET_RESTARTED: (state) => ({
    ...state,
    addedPetId: null,
    error: false,
    loading: false,
    success: false,
  }),
  BREED_DATA_LOADED: (state, { payload }) => ({
    ...state,
    breedData: {
      ...state.breedData,
      catBreeds: payload.catBreeds,
      dogBreeds: payload.dogBreeds,
    },
  }),
  DELETE_PET: (state) => ({
    ...state,
    error: false,
    loading: true,
    success: false,
  }),
  DELETE_PET_FAILED: (state) => ({
    ...state,
    error: true,
    loading: false,
    success: false,
  }),
  EDIT_PET: (state) => ({
    ...state,
    error: false,
    loading: true,
    success: false,
  }),
  MICROCHIP_DATA_CLEARED: (state) => ({
    ...state,
    microchipCompanies: [],
    microchipData: {},
  }),
  MICROCHIP_DATA_LOADED: (state, { payload }) => ({
    ...state,
    microchipCompanies: payload.companies,
    microchipData: payload.data,
  }),
  MICROCHIP_FAILED: (state) => ({
    ...state,
    error: true,
    loading: false,
    success: false,
  }),
  MICROCHIP_MODAL_VISIBLE: (state, { payload }) => ({
    ...state,
    error: false,
    loading: false,
    showMicrochipModal: payload.isVisible,
    success: false,
  }),
  MICROCHIP_SAVED: (state, { payload }) => ({
    ...state,
    error: false,
    loading: false,
    microchipData: payload,
    success: true,
  }),
  PET_ADDED: (state, { payload }) => ({
    ...state,
    addedPetId: payload.addedPetId,
    error: false,
    loading: false,
    success: true,
  }),
  PET_DELETED: (state) => ({
    ...state,
    error: false,
    loading: false,
    success: true,
  }),
  PET_EDITED: (state) => ({
    ...state,
    error: false,
    loading: false,
    success: true,
  }),
  PET_PHOTO_REMOVED: (state) => ({
    ...state,
    error: false,
    loading: false,
    success: true,
  }),
  PET_PROFILE_FAILED: (state) => ({
    ...state,
    error: true,
    loading: false,
    petProfileFailed: true,
  }),
  PET_PROFILE_LOAD_ATTEMPT: (state) => ({
    ...state,
    error: false,
    petProfile: {},
    petProfileFailed: false,
    petProfileLoading: true,
  }),
  PET_PROFILE_LOADED: (state, { payload }) => ({
    ...state,
    petProfile: payload,
    petProfileLoading: false,
    selectedPetId: payload.Id,
  }),
  PET_PROFILE_REDIRECTED: (state) => ({
    ...state,
    petProfileFailed: false,
    selectedPetId: null,
  }),
  REMOVE_PET_PHOTO: (state) => ({
    ...state,
    error: false,
    loading: true,
    success: false,
  }),
  REMOVE_PET_PHOTO_FAILED: (state) => ({
    ...state,
    error: true,
    loading: false,
    success: false,
  }),
  SAVE_MICROCHIP: (state) => ({
    ...state,
    error: false,
    loading: true,
    success: false,
  }),
  SET_SELECTED_PET_ID: (state, { payload }) => ({
    ...state,
    selectedPetId: payload,
  }),
}, INITIAL_STATE);
