import { handleActions } from 'redux-actions';

const INITIAL_STATE = {
  isSendingMessage: false,
  loadingLostPetData: false,
  lostPetData: null,
  lostPetDataError: '',
  lostPetTag: '',
  sendMessageAttempted: false,
  sendMessageSuccess: false,
};

export default handleActions({
  CONTACT_PET_FAMILY: (state) => ({
    ...state,
    isSendingMessage: true,
  }),
  LOST_PET_TAG_CHANGED: (state, { payload }) => ({
    ...state,
    lostPetTag: payload,
  }),
  RESTART_PET_TAG_SEARCH: () => ({ ...INITIAL_STATE }),
  SEARCH_PET_TAG_ATTEMPT: (state) => ({
    ...state,
    loadingLostPetData: true,
  }),
  SEARCH_PET_TAG_FAILED: (state, { payload }) => ({
    ...state,
    loadingLostPetData: false,
    lostPetDataError: payload,
  }),
  SEARCH_PET_TAG_FOUND: (state, { payload }) => ({
    ...state,
    loadingLostPetData: false,
    lostPetData: payload,
  }),
  SEARCH_PET_TAG_RESET: (state) => ({ ...state, lostPetData: null }),
  SEND_MESSAGE_FAILED: (state) => ({
    ...state,
    isSendingMessage: false,
    sendMessageAttempted: true,
    sendMessageSuccess: false,
  }),
  SEND_MESSAGE_SUCCEED: (state) => ({
    ...state,
    isSendingMessage: false,
    sendMessageAttempted: true,
    sendMessageSuccess: true,
  }),
}, INITIAL_STATE);
