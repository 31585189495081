import React from 'react';
import { useTranslation } from 'react-i18next';

import { ActionButton } from '../../../common/petCloud';

import '../css/ReimbursementEditModal.css';

const EditReimbursementModalButtons = ({
  handleEditReimbursementAccept = () => { },
  handleEditReimbursementCancel = () => { },
  handleEditReimbursementSuccess = () => { },
  isBankFormInvalid = false,
  reimbursementMethodUpdateSuccess = false,
  updatingReimbursementMethod = false,
}) => {
  const { t } = useTranslation('common');

  return (
    <div className="Reimbursement-edit-modal-footer-buttons-container">
      <ActionButton
        disabled={isBankFormInvalid}
        loading={updatingReimbursementMethod}
        onClick={handleEditReimbursementAccept}
        onSuccess={handleEditReimbursementSuccess}
        success={reimbursementMethodUpdateSuccess}
        title={t('saveButton')}
      />

      <ActionButton
        isSecondaryButton
        onClick={handleEditReimbursementCancel}
        title={t('cancelButton')}
      />
    </div>
  );
};

export { EditReimbursementModalButtons };
