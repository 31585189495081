import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  displayConfirmChangesModal,
  myAccountHasChanges,
  refreshPersonalInformation,
  updateEmail,
} from '../../../../actions';

import { PetCloudInputAnimated }
from '../../../common/petCloud/PetCloudInputAnimated';

import { ActionButton } from '../../../common/petCloud/ActionButton';

import { isEmailValid, removeEmojis } from '../../../../services/utils';

import { ConfirmChangesModal } from './ConfirmChangesModal';
import { ForgotPasswordModal } from './ForgotPasswordModal';

import '../css/PetCloudEdit.css';

const PetCloudLoginEmailEdit = ({
  initialValue = '',
  onClickCancel = () => { },
}) => {
  const { t } = useTranslation('myAccount');
  const dispatch = useDispatch();
  const store = useSelector(({
    accountLogin,
    myAccount,
    session,
  }) => ({
    accountLogin,
    myAccount,
    session,
  }), shallowEqual);
  const { accountLogin: {
    areEmailUpdated,
    isEmailBeingUpdated,
  }, myAccount: {
    showForgotPasswordModal,
  } } = store;
  const [emailValue, setEmailValue] = useState(initialValue);
  const [emailError, setEmailError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const disabledButton = useMemo(() => emailValue === initialValue
    || emailError, [emailError, emailValue, initialValue]);

  useEffect(() => {
    dispatch(myAccountHasChanges({
      pageHasChanges: emailValue !== initialValue,
    }));
  }, [emailValue, initialValue]);

  const handleEmailChange = useCallback(({ target }) => {
    const { value } = target;
    const cleanValue = removeEmojis(value);

    setEmailValue(cleanValue);
    const invalidEmail = !isEmailValid(cleanValue);
    setEmailError(invalidEmail ? t('petCloudSection.invalidEmail') : '');
  }, []);

  const handleOnEmailBlur = useCallback(() => {
    let error = t('petCloudSection.requiredField');

    if (emailValue) {
      const invalidEmail = !isEmailValid(emailValue);

      error = invalidEmail ? t('petCloudSection.invalidEmail') : '';
    }

    setEmailError(error);
  }, [emailValue]);

  function handleUpdateError() {
    setIsLoading(false);
  }

  function handleUpdateSuccess() {
    dispatch(refreshPersonalInformation());
    dispatch(myAccountHasChanges({ pageHasChanges: false }));
    setIsLoading(false);
  }

  const onClickSave = useCallback(() => {
    dispatch(updateEmail({
      email: emailValue,
      errorCallback: handleUpdateError,
      successCallback: handleUpdateSuccess,
    }));
  }, [emailValue]);

  function handleConfirmChangesAction() {
    dispatch(displayConfirmChangesModal({ showConfirmChangesModal: false }));
    onClickCancel();
  }

  return (
    <div className="Pet-cloud-edit-container">
      <span>{t('petCloudSection.emailEdit.description')}</span>

      <PetCloudInputAnimated
        error={emailError}
        inputClass="Pet-cloud-full-width-input"
        onBlur={handleOnEmailBlur}
        onChange={handleEmailChange}
        placeholder={t('petCloudSection.emailEdit.emailAddress')}
        value={emailValue}
      />

      <div className="Pet-cloud-edit-save-button">
        <ActionButton
          disabled={disabledButton}
          loading={isLoading || isEmailBeingUpdated}
          onClick={onClickSave}
          title={t('petCloudSection.save')}
        />
      </div>

      <ConfirmChangesModal
        handleCancelClick={handleConfirmChangesAction}
        handleContinueClick={handleConfirmChangesAction}
        success={areEmailUpdated}
      />

      <ForgotPasswordModal
        onClickCancel={onClickCancel}
        showForgotPasswordModal={showForgotPasswordModal}
      />
    </div>
  );
};

export { PetCloudLoginEmailEdit };
