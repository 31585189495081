import React, { useState, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  changeName,
  changeNameRestarted,
} from '../../../actions';
import FigoInput from '../../common/FigoInput';
import { NAME_MAX_LENGTH } from '../../../constants';
import { removeEmojis } from '../../../services/utils';
import { GenericModal } from '../../common/petCloud';

const RenameModal = ({
  isFolder,
  itemId,
  itemName,
  onClose = () => { },
  onSuccess = () => { },
  show = false,
}) => {
  const { t } = useTranslation('inboxAndDocs');
  const dispatch = useDispatch();
  const [fieldError, setFieldError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(itemName);
  const [isEmpty, setIsEmpty] = useState(false);
  const store = useSelector(({ inboxAndDocs }) => inboxAndDocs, shallowEqual);
  const {
    loading,
    nameChangedSuccessfully,
    nameChangeError,
    nameChangeLoading,
  } = store;

  const type = isFolder ? t('renameModal.folder') : t('renameModal.file');
  const maxLength = isFolder ? NAME_MAX_LENGTH.folder : NAME_MAX_LENGTH.file;
  const noChangedDetected = name === itemName;

  useEffect(() => {
    setFieldError(nameChangeError);
    setIsLoading(loading);
  }, [nameChangeError, loading]);

  function handleOnBlur() {
    if (isEmpty) {
      setFieldError(t('renameModal.requiredError'));
    }
  }

  function handleOnChange({ target: { value } }) {
    const error = value ? null : t('renameModal.requiredError');
    const cleanValue = removeEmojis(value);

    setName(cleanValue);
    setIsEmpty(!cleanValue.trim());
    setFieldError(error);
  }

  function renderInputError(error) {
    return <span>{error}</span>;
  }

  function handleOnClose() {
    if (!isLoading) {
      setName(itemName);
      setFieldError(null);
      dispatch(changeNameRestarted());
      onClose();
    }
  }

  function handleOnSave() {
    setIsLoading(true);
    dispatch(changeName({ isFolder, itemId, newName: name, type }));
  }

  function handleOnSucces() {
    dispatch(changeNameRestarted());
    onSuccess();
    onClose();
  }

  return (
    <GenericModal
      handleOutClick={handleOnClose}
      loadingPrimary={nameChangeLoading}
      onPrimaryClick={handleOnSave}
      onPrimarySuccess={handleOnSucces}
      onSecondaryClick={handleOnClose}
      primaryButtonDisabled={isEmpty || isLoading || noChangedDetected}
      primaryButtonText={t('renameModal.save')}
      secondaryButtonText={t('renameModal.cancel')}
      show={show}
      successPrimary={nameChangedSuccessfully}
      title={t('renameModal.title', { type })}
    >
      <div className="Width100">
        <FigoInput
          disabled={isLoading}
          error={fieldError}
          maxLength={maxLength}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          placeholder={t('renameModal.suggestions')}
          renderError={renderInputError}
          title={t('renameModal.label', { type })}
          value={name}
        />
      </div>
    </GenericModal>
  );
};

export { RenameModal };
