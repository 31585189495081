import { createActions } from 'redux-actions';

const actions = createActions({
  BREED_MULTIBRAND_VALIDATION_FAILED: (data) => data,
  BREED_MULTIBRAND_VALIDATION_SUCCEED: (data) => data,
  GET_ORCA_POWER_UP: (data) => data,
  ORCA_GET_POWER_UP_ATTEMPT: () => { },
  ORCA_GET_POWER_UP_FAILED: () => { },
  ORCA_GET_POWER_UP_LOADED: (data) => data,
  RESET_VALIDATION_BREED_MULTIBRAND: (data) => data,
  VALIDATE_BREED_MULTIBRAND: (data) => data,
});

export const {
  breedMultibrandValidationFailed,
  breedMultibrandValidationSucceed,
  getOrcaPowerUp,
  orcaGetPowerUpAttempt,
  orcaGetPowerUpFailed,
  orcaGetPowerUpLoaded,
  resetValidationBreedMultibrand,
  validateBreedMultibrand,
} = actions;
