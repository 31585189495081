import React from 'react';
import './ScrollableDiv.css';

const ScrollableDiv = ({
  children,
  className,
  divRef,
  fixedScrollbar,
  scrollbarMargin,
}) => (
  <div
    ref={divRef}
    className={`Content-scrollable-y ${className || ''}${
      scrollbarMargin ? ' Scrollbar-margin' : ''}${
      fixedScrollbar ? ' Content-scrollable-fixed' : ''}`}
  >
    {children}
  </div>
);

export default ScrollableDiv;
