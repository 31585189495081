import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import TouchableDiv from '../../../common/TouchableDiv';
import { doNothing } from '../../../../services/utils';

import restoreIcon from '../../../../assets/inboxDocsMultibrand/restore.svg';

import './RestoreButton.css';

const RestoreButton = ({
  isDisabled = true,
  onRestoreFilesClick = () => { },
}) => {
  const { t } = useTranslation('inboxAndDocs');
  const buttonStyling = useMemo(() => {
    const enabled = 'Restore-button-container';
    const disabled = 'Restore-button-container-disabled';

    return isDisabled ? disabled : enabled;
  }, [isDisabled]);

  return (
    <TouchableDiv
      className={buttonStyling}
      id="restoreButton"
      onClick={isDisabled ? doNothing : onRestoreFilesClick}
    >
      <img
        alt=""
        className="Restore-button-icon"
        src={restoreIcon}
      />

      <span className="Restore-button-text">
        {t('restoreButton.buttonText')}
      </span>
    </TouchableDiv>
  );
};

export { RestoreButton };
