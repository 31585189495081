import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import {
  changeDeductibleLegend,
  deductibleChanged,
  reimbursementChanged,
  selectedPlanChanged,
} from '../../../actions';

import Shimmer from '../../common/Shimmer';
import { TOOL_TIP_POSITION } from '../../common/CustomTooltip';
import { SelectorPills }
from '../changeCoverageOptions/selectorPills/SelectorPills';

import infoRedIcon from '../../../assets/changeCoverage/info-red.svg';
import { CONTACT_US_URL } from '../../../constants';
import './css/DeductibleReimbursement.css';

const ULTIMATE_PLAN = 'Unlimited';
const EMPTY_TEXT = '';

export const DeductibleReimbursement = ({
  plans,
  quoteSelected,
}) => {
  const dispatch = useDispatch();
  const store = useSelector(({ policies }) => ({ ...policies.changeCoverage }));
  const { initialCoverageValues } = store;

  const [deductibles, setDeductibles] = useState([]);

  const {
    deductibleId,
    reimbursementId,
    selectedPlan,
  } = quoteSelected;
  const { t } = useTranslation('changeCoverage');

  const plansItems = useMemo(
    () => (plans.map((item) => ({
      ...item,
      id: item.Plan,
      value: item.MaxAnnual,
    }))),
    [plans],
  );

  const downgradePlan = useMemo(() => {
    const quoteValues = ['$5,000', '$10,000', ULTIMATE_PLAN];

    const initialIndex = quoteValues
      .indexOf(initialCoverageValues.selectedAnnualBenefit);
    const selectedIndex = quoteValues
      .indexOf(quoteSelected.selectedAnnualBenefit);

    return selectedIndex < initialIndex;
  }, [initialCoverageValues, quoteSelected.selectedAnnualBenefit]);

  const isUltimateSelected = quoteSelected.selectedAnnualBenefit
    === ULTIMATE_PLAN;

  const planMemo = useMemo(() => plans
    .find((item) => item.Plan === selectedPlan), [selectedPlan]);

  const updateDeductible = useCallback(({ value, id }) => {
    dispatch(deductibleChanged({ deductibleId: id, description: value }));
  }, [dispatch]);

  const noDeductibleReimbursement = useCallback(() => {
    const deductibleFilter = planMemo.RatingOptions
      .filter((item) => !item.Invalid)
      .map((item) => ({
        available: !item.Invalid,
        description: item.DeductibleName,
        disabled: item.Invalid,
        id: item.DeductibleId,
      }));
    const deductibleAvailable = deductibleFilter
      .reverse().find((item) => item.available);

    const { description, id } = deductibleAvailable;

    dispatch(deductibleChanged({ deductibleId: id, description }));
  }, [deductibleId, dispatch, planMemo]);

  const updateReimbursement = useCallback(() => {
    const ratingOptions = planMemo.RatingOptions
      .filter((rating) => rating.DeductibleId === deductibleId);
    const reimbursementsFilter = ratingOptions
      .filter((item) => item.DeductibleId === deductibleId && !item.Invalid)
      .map((item) => ({
        available: !item.Invalid,
        description: item.ReimbursementName,
        disabled: item.Invalid,
        id: item.ReimbursementId,
      }));
    const reimbursementAvailable = reimbursementsFilter
      .reverse().find((item) => item.available);

    if (!reimbursementAvailable) {
      noDeductibleReimbursement();
      return;
    }

    const { description, id } = reimbursementAvailable;

    dispatch(reimbursementChanged({ description, reimbursementId: id }));
  }, [deductibleId, dispatch, planMemo]);

  useEffect(() => {
    if (!planMemo) {
      return;
    }

    const ratingOptions = planMemo.RatingOptions
      .filter((rating) => rating.ReimbursementId === reimbursementId);
    const deductiblesFilter = ratingOptions
      .filter((item) => item.ReimbursementId === reimbursementId
        && !item.Invalid)
      .map((item) => ({
        available: !item.Invalid,
        disabled: item.Invalid,
        id: item.DeductibleId,
        value: item.DeductibleName,
      }));
    const deductiblesAvailables =
      deductiblesFilter.filter((item) => item.available);
    const currentDeductible =
      deductiblesFilter.find((item) => item.id === deductibleId) || {};
    let deductibleLegend = '';

    if (!deductiblesAvailables.length) {
      updateReimbursement();
      return;
    }

    const reimbursementPercentage = ratingOptions[0].ReimbursementName;

    if (deductiblesAvailables.length < deductiblesFilter.length) {
      const names = deductiblesAvailables.map((item) => item.value);
      let nameLegend = '';

      if (names.length === 1) {
        nameLegend = names.join(t('sliders.deductibleLegendJoin'));
      } else {
        names.forEach((item, index) => {
          const concat = names.length - 1 === index
            ? t('sliders.deductibleLegendJoin') : ', ';

          nameLegend = nameLegend
            ? `${nameLegend}${concat}${item}` : ` ${item}`;
        });
      }

      deductibleLegend = t('sliders.deductibleLegend', {
        names: nameLegend, reimbursementPercentage,
      });
    }

    dispatch(changeDeductibleLegend(deductibleLegend));

    if (!currentDeductible.available) {
      updateDeductible(
        [...deductiblesFilter].reverse().find((item) => item.available),
      );
    }

    setDeductibles(deductiblesFilter);
  }, [
    deductibleId,
    dispatch,
    reimbursementId,
    planMemo,
    updateDeductible,
    updateReimbursement,
  ]);

  const onDeductibleChange = useCallback(({ id, value }) => {
    updateDeductible({ id, value });
  }, [updateDeductible]);

  const [reimbursements, setReimbursements] = useState([]);

  useEffect(() => {
    if (!planMemo) {
      return;
    }

    const ratingOptions = planMemo.RatingOptions
      .filter((rating) => rating.DeductibleId === deductibleId);
    const reimbursementsFilter = ratingOptions
      .filter((item) => item.DeductibleId === deductibleId && !item.Invalid)
      .map((item) => ({
        available: !item.Invalid,
        disabled: item.Invalid,
        id: item.ReimbursementId,
        value: item.ReimbursementName,
      }));

    setReimbursements(reimbursementsFilter);
  }, [deductibleId, planMemo]);

  const onReimbursementChange = useCallback(({ id, value }) => {
    dispatch(reimbursementChanged({ description: value, reimbursementId: id }));
  }, [dispatch]);

  const onCoverageChange = useCallback(({ MaxAnnual, Plan }) => {
    dispatch(selectedPlanChanged({
      planId: Plan,
      selectedAnnualBenefit: MaxAnnual,
    }));
  }, [dispatch, plans]);

  return (
    <>
      <div className="DeductibleReimbursement-container">
        <div className="DeductibleReimbursement-container-div Flex-column">
          <Shimmer
            element="fragment"
            height={70}
            visible={plansItems.length === 0}
          >
            <SelectorPills
              items={plansItems}
              onChange={onCoverageChange}
              title={t('sliders.annualCoverageTitle')}
              tooltipDescription={t('tooltip.annualCoverageTooltip')}
              value={quoteSelected.selectedPlan}
            >
              {downgradePlan && (
                <div className="d-flex">
                  <img
                    alt=""
                    className="Deductible-legend-text-icon"
                    src={infoRedIcon}
                  />

                  <p className="Deductible-legend-text">

                    <Trans
                      components={{
                        a: <a href={CONTACT_US_URL}>{EMPTY_TEXT}</a>,
                      }}
                      i18nKey="sliders.annualCoverageLegend"
                      ns="changeCoverage"
                    />
                  </p>
                </div>
              )}
            </SelectorPills>
          </Shimmer>

          <Shimmer
            element="fragment"
            height={70}
            margin="16px 0"
            visible={deductibles.length === 0}
          >
            <SelectorPills
              items={deductibles}
              onChange={onDeductibleChange}
              title={t('sliders.deductibleTitle')}
              tooltipDescription={t('tooltip.deductibleTooltip')}
              value={quoteSelected.deductibleId}
            >
              {isUltimateSelected && (
                <span
                  aria-live="polite"
                  className="Deductible-legend-text"
                >
                  {t('sliders.100ReimbursementNoAvailable')}
                </span>
              )}
            </SelectorPills>
          </Shimmer>

          <Shimmer
            element="fragment"
            height={70}
            visible={reimbursements.length === 0}
          >
            <SelectorPills
              items={reimbursements}
              onChange={onReimbursementChange}
              position={TOOL_TIP_POSITION.start}
              title={t('sliders.reimbursementTitle')}
              tooltipDescription={t('tooltip.reimbursementTooltip')}
              value={quoteSelected.reimbursementId}
            >
              {store.deductibleLegend && (
                <span aria-live="polite" className="Deductible-legend-text">
                  {store.deductibleLegend}
                </span>
              )}
            </SelectorPills>
          </Shimmer>
        </div>
      </div>

      {store.deductibleLegend && (
        <span aria-live="polite" className="Deductible-legend-text">
          {store.deductibleLegend}
        </span>
      )}

      {isUltimateSelected && (
        <span aria-live="polite" className="Deductible-legend-text">
          {t('sliders.100ReimbursementNoAvailable')}
        </span>
      )}
    </>
  );
};
