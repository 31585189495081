import { useEffect, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';

const useBackButton = (callback = () => { }, dependencies) => {
  const history = useHistory();
  const initialLocationKey = useRef();
  const handlePopState = useCallback(() => {
    if (history.location.key === initialLocationKey.current) {
      callback();
    }
  }, [...dependencies, callback]);

  useEffect(() => {
    window.addEventListener('popstate', handlePopState);
    initialLocationKey.current = history.location.key;

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [...dependencies]);
};

export { useBackButton };
