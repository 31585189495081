import React, { useEffect, useMemo } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ModalContainer from '../../common/ModalContainer';
import TouchableDiv from '../../common/TouchableDiv';
import LoadingButton from '../../common/LoadingButton';
import closeIcon from '../../../assets/myPets/close-icon.svg';
import {
  addCustomToFavorites,
  vetCustomSavedCleared,
} from '../../../actions/myVets';
import { EXPLORE_PROVIDERS } from '../../../constants';
import { useCustomVetForm } from './hooks/useCustomVetForm';
import './AddVetManuallyModal.css';

const AddVetInputContainer = ({
  children,
  inputContainerCSS = '',
  inputTitle = '',
  errorMessage = '',
}) => (
  <div className={`AVMM-input-container ${inputContainerCSS}`}>
    <span className="AVMM-input-titles">
      {inputTitle}
    </span>

    {children}

    {errorMessage && (
      <span className="AVMM-error-message">
        {errorMessage}
      </span>
    )}
  </div>
);

const StatesList = ({ states = [] }) => states?.map((usState) => (
  <option key={`${usState.Id}`} value={usState.Id}>
    {usState.StateName}
  </option>
));

const AddVetManuallyModal = ({
  closeModal = () => { },
  handleSuccesCustomVetModal = () => { },
  onSavePrimaryVet = () => { },
  primaryVet = false,
  show = false,
  showCancelButton = false,
}) => {
  const dispatch = useDispatch();
  const store = useSelector(({ myVets, personalInformation }) => ({
    myVets,
    personalInformation,
  }), shallowEqual);
  const { states } = store.personalInformation;
  const { isSavingCustomVet, vetCustomSaved } = store.myVets;
  const { t } = useTranslation('myPets');

  function clearSuccessLoading() {
    dispatch(vetCustomSavedCleared());
  }

  const {
    handleCityError,
    handleCityNameChange,
    handleCloseModal,
    handleStateError,
    handleStateNameChange,
    handleSuccess,
    handleVetCompanyError,
    handleVetCompanyNameChange,
    state,
  } = useCustomVetForm({
    clearSuccessLoading,
    closeModal,
    handleSuccesCustomVetModal,
  });

  const invalidForm = useMemo(() => !state.vetCompanyName || !state.cityName
    || !state.stateId, [state.vetCompanyName, state.cityName, state.stateId]);

  useEffect(() => {
    if (vetCustomSaved) {
      handleCloseModal();
    }
  }, [vetCustomSaved]);

  function handleSaveClick() {
    if (invalidForm) {
      handleVetCompanyError();
      handleCityError();
      handleStateError();
      return;
    }

    if (primaryVet) {
      onSavePrimaryVet({
        City: state.cityName,
        isCustom: true,
        Name: state.vetCompanyName,
        StateId: state.stateId,
        StateName: state.stateName,
      });
    } else {
      dispatch(addCustomToFavorites({
        categoryId: 1,
        city: state.cityName,
        name: state.vetCompanyName,
        providerId: EXPLORE_PROVIDERS.manuallyAdded,
        stateId: state.stateId,
      }));
    }
  }

  return (
    <ModalContainer show={show}>
      <div className="AVMM-container">
        <TouchableDiv
          className="Cursor-pointer AVMM-close-btn"
          id="closeIconBtn"
          onClick={handleCloseModal}
        >
          <img alt="" className="Add-figo-tag-close-icon" src={closeIcon} />
        </TouchableDiv>

        <span className="AVMM-title">
          {t('AddCustomVetModal.title')}
        </span>

        <AddVetInputContainer
          errorMessage={state.vetCompanyError}
          inputContainerCSS="AVMM-input-container-title"
          inputTitle={t('AddCustomVetModal.vetCompanyTitle')}
        >
          <input
            className="AVMM-input"
            onChange={handleVetCompanyNameChange}
            value={state.vetCompanyName}
          />
        </AddVetInputContainer>

        <div className="AVMM-inputs-row">
          <AddVetInputContainer
            errorMessage={state.cityError}
            inputContainerCSS="AVMM-input-container-city"
            inputTitle={t('AddCustomVetModal.cityTitle')}
          >
            <input
              className="AVMM-input"
              onChange={handleCityNameChange}
              value={state.cityName}
            />
          </AddVetInputContainer>

          <AddVetInputContainer
            errorMessage={state.stateError}
            inputTitle={t('AddCustomVetModal.stateTitle')}
          >
            <select
              className="AVMM-select"
              onChange={handleStateNameChange}
              value={state.stateId}
            >
              <option selected value="">
                {t('AddCustomVetModal.statePlaceholder')}
              </option>

              <StatesList states={states} />
            </select>
          </AddVetInputContainer>
        </div>

        <div className="AVMM-buttons">
          {showCancelButton && (
            <button
              className="AVMM-cancel-btn"
              onClick={closeModal}
              type="button"
            >
              {t('AddCustomVetModal.cancel')}
            </button>
          )}

          <LoadingButton
            className="AVMM-save-btn"
            disabled={isSavingCustomVet}
            loading={isSavingCustomVet}
            onClick={handleSaveClick}
            onSuccessAnimationEnd={handleSuccess}
            success={vetCustomSaved}
            text={t('AddCustomVetModal.save')}
            type="button"
          />
        </div>
      </div>
    </ModalContainer>
  );
};

export { AddVetManuallyModal };
