import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Folder } from './Folder';

import { goToSelectedFolder } from '../../services/inboxAndDocs';
import { INBOX_DOCS } from '../../constants';
import { filePermissionsLoaded } from '../../actions';

import './FoldersSection.css';

import addFolderIcon from
'../../assets/inboxDocsMultibrand/iconsFolder/add.svg';
import deleteIcon from
'../../assets/inboxDocsMultibrand/iconsFolder/trash.svg';

import { UploadFilesButton } from './UploadFilesButton';

const FoldersSection = ({
  breadcrumbs = {},
  folders = [],
  onAddFolder = () => { },
  onOpenUpload = () => { },
}) => {
  const { t } = useTranslation('inboxAndDocs');
  const dispatch = useDispatch();

  const goToFolder = (folder) => () => {
    const folderId = folder.Id;
    const filePermissions = folder.FilePermissions;
    dispatch(filePermissionsLoaded(filePermissions));
    goToSelectedFolder({ breadcrumbs, folderContent: folders, folderId });
  };

  const emptyFilePermissionsForThrashThreeDotsFolder = [];

  return (
    <div className="Folders-section-container Flex Flex-column">
      <div className="Folder-title-section">
        <span className="Folder-title-text">{t('foldersTitle')}</span>

        <div className="Buttons-container">
          <button
            className="Round-button"
            onClick={onAddFolder}
            type="button"
          >
            <img alt="" src={addFolderIcon} />

            <span>{t('addFolder')}</span>
          </button>

          <UploadFilesButton onClick={onOpenUpload} />
        </div>
      </div>

      <div className="Folders-items Flex Flex-wrap">
        {folders.map((folder) => (folder.Id !== INBOX_DOCS.inbox
          && folder.Id !== INBOX_DOCS.trash
          && (
            <Folder
              key={folder.Id}
              folder={folder}
              onClick={goToFolder(folder)}
            />
          )))}
      </div>

      <div
        className="Folders-section-deleted-files-redesign Flex"
        id="btnViewDeletedFiles"
        onClick={goToFolder({
          FilePermissions: emptyFilePermissionsForThrashThreeDotsFolder,
          Id: INBOX_DOCS.trash,
        })}
        onKeyDown={null}
        onKeyPress={null}
        role="button"
        tabIndex={-1}
      >
        <div className="No-clicked Flex">
          <img alt="" src={deleteIcon} />

          <span>{t('deletedFiles')}</span>
        </div>
      </div>
    </div>
  );
};

export { FoldersSection };
