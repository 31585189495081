import React, { useState, forwardRef, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe
from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import calendarIcon from '../../assets/myPets/calendar-black-icon.svg';

import 'react-datepicker/dist/react-datepicker.css';

import './AgeInputSelector.css';

const KEY_CODE_TAB = 9;

const AgeInputSelector = ({
  error = '',
  handleInputError,
  onDatePickerSelectionChange,
  selectedDate,
}) => {
  const { t } = useTranslation('myPets');
  const [inputHasFocus, setInputHasFocus] = useState(false);
  const customInputRef = useRef();
  const datePickerRef = useRef();

  function onDatePickerInputFocus() {
    setInputHasFocus(true);
  }

  function onDatePickerSelect() {
    setInputHasFocus(false);
  }

  function onDatePickerInputBlur() {
    setInputHasFocus(false);
    handleInputError(selectedDate);
  }

  function onDatePickerInputKeyDown(event) {
    if (event.keyCode === KEY_CODE_TAB || event.which === KEY_CODE_TAB) {
      datePickerRef.current.setOpen(false);
    }
  }

  const autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy');

  const CalendarCustomInput = forwardRef((
    { autoFocus, placeholder, ...allProps },
    ref,
  ) => (
    <>
      <MaskedInput
        ref={ref}
        autoFocus={inputHasFocus}
        className="Age-selector-input"
        guide={false}
        id="idMaskedDateInput"
        keepCharPositions
        mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
        pipe={autoCorrectedDatePipe}
        placeholder={t('AddAPetModal.petBirthdayPlaceholder')}
        {...allProps}
      />

      <img
        alt=""
        aria-hidden="true"
        className={'Age-selector-arrow-closed '
            + `${inputHasFocus ? 'Age-selector-arrow-open' : ''}`}
        src={calendarIcon}
      />
    </>
  ));

  return (
    <div className="Age-selector-container">
      <h6 className="Age-selector-input-label">
        {t('AddAPetModal.petBirthdayTitle')}
      </h6>

      <div className="Age-selector-input-container">
        <DatePicker
          ref={datePickerRef}
          className={`Age-selector-input 
            ${error && 'Age-selector-input-error'}`}
          customInput={<CalendarCustomInput ref={customInputRef} />}
          maxDate={Date.now()}
          onChange={onDatePickerSelectionChange}
          onClickOutside={onDatePickerInputBlur}
          onFocus={onDatePickerInputFocus}
          onKeyDown={onDatePickerInputKeyDown}
          onSelect={onDatePickerSelect}
          selected={selectedDate}
        />
      </div>

      <span className="Error-label Age-selector-error">{error}</span>
    </div>
  );
};

export { AgeInputSelector };
