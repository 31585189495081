import { handleActions } from 'redux-actions';

const emptyEmergencyContact = {
  hasNameError: false,
  hasPhoneNumberError: false,
  id: 0,
  isRequired: false,
  key: 0,
  name: '',
  nameErrorMessage: '',
  phoneNumber: '',
  phoneNumberErrorMessage: '',
};

const INITIAL_STATE = {
  currentModalStep: {
    isFirstStep: true,
    isRemoveStep: false,
    isSecondStep: false,
  },
  data: {},
  emergencyContactsForm: [
    { ...emptyEmergencyContact, isRequired: true, key: 1 },
    { ...emptyEmergencyContact, key: 2 },
    { ...emptyEmergencyContact, key: 3 },
  ],
  error: '',
  isDeleteTagNumberRequestStarted: false,
  isEditMode: false,
  isFigoTagModalVisible: false,
  isLoadingPetInfo: false,
  isPetTagInfoRequestStarted: false,
  isTagNumberError: false,
  isTagNumberMode: false,
  petTagInfo: {
    MessageToFinder: '',
    PetId: '',
    TagNumber: '',
  },
  successPetTagDelete: false,
  successPetTagPost: false,
  tagNumberErrorMessage: '',
};

export default handleActions({
  CONTACTS_FORM_INITIALIZED: (state, { payload }) => ({
    ...state,
    emergencyContactsForm: payload.length ? payload.map((contact, index) => ({
      hasNameError: false,
      hasPhoneNumberError: false,
      id: contact.Id,
      isRequired: index === 0,
      key: index + 1,
      name: contact.Name,
      nameErrorMessage: '',
      phoneNumber: contact.PhoneNumber,
      phoneNumberErrorMessage: '',
    })) : INITIAL_STATE.emergencyContactsForm,
  }),
  DELETE_TAG_NUMBER_FAILED: (state) => ({
    ...state,
    isDeleteTagNumberRequestStarted: false,
  }),
  DELTE_TAG_NUMBER_REQUESTED: (state) => ({
    ...state,
    isDeleteTagNumberRequestStarted: true,
  }),
  EDIT_MODE_SET: (state, { payload }) => ({
    ...state,
    isEditMode: payload,
  }),
  FAMILY_MEMBER_NAME_ERROR_MESSAGE_SETTED: (state, { payload }) => ({
    ...state,
    emergencyContactsForm: state.emergencyContactsForm.map((contact) => (
      contact.key === payload.contactKey
        ? {
          ...contact,
          hasNameError: !!payload.errorMessage,
          nameErrorMessage: payload.errorMessage,
        } : contact
    )),
  }),
  FAMILY_MEMBER_NAME_SETTED: (state, { payload }) => {
    const isNewContact = !state.emergencyContactsForm
      .find((contact) => contact.key === payload.contactKey);

    const newContact = {
      ...emptyEmergencyContact,
      key: payload.contactKey,
      name: payload.name,
    };

    return ({
      ...state,
      emergencyContactsForm: isNewContact
        ? state.emergencyContactsForm.concat(newContact)
        : state.emergencyContactsForm.map((contact) => (
          contact.key === payload.contactKey
            ? {
              ...contact,
              name: payload.name,
            } : contact
        )),
    });
  },
  FAMILY_MEMBER_PHONE_NUMBER_ERROR_MESSAGE_SETTED: (state, { payload }) => ({
    ...state,
    emergencyContactsForm: state.emergencyContactsForm.map((contact) => (
      contact.key === payload.contactKey
        ? {
          ...contact,
          hasPhoneNumberError: !!payload.errorMessage,
          phoneNumberErrorMessage: payload.errorMessage,
        } : contact
    )),
  }),
  FAMILY_MEMBER_PHONE_NUMBER_SETTED: (state, { payload }) => {
    const isNewContact = !state.emergencyContactsForm
      .find((contact) => contact.key === payload.contactKey);

    const newContact = {
      ...emptyEmergencyContact,
      key: payload.contactKey,
      phoneNumber: payload.phoneNumber,
    };

    return ({
      ...state,
      emergencyContactsForm: isNewContact
        ? state.emergencyContactsForm.concat(newContact)
        : state.emergencyContactsForm.map((contact) => (
          contact.key === payload.contactKey
            ? {
              ...contact,
              phoneNumber: payload.phoneNumber,
            } : contact
        )),
    });
  },
  FIGO_TAG_MODAL_HIDDEN: (state) => ({
    ...state,
    currentModalStep: {
      isFirstStep: true,
      isRemoveStep: false,
      isSecondStep: false,
    },
    familyMemberOneFullName: '',
    familyMemberOneFullNameErrorMessage: '',
    familyMemberOnePhoneNumberErrorMessage: '',
    familyMemberThreeFullName: '',
    familyMemberTwoFullName: '',
    isDeleteTagNumberRequestStarted: false,
    isEditMode: false,
    isFamilyMemberOneFullNameError: false,
    isFamilyMemberOnePhoneNumberError: false,
    isFamilyMemberThreeFullNameError: false,
    isFamilyMemberThreePhoneNumberError: false,
    isFamilyMemberTwoFullNameError: false,
    isFamilyMemberTwoPhoneNumberError: false,
    isFigoTagModalVisible: false,
    isPetTagInfoRequestStarted: false,
    isTagNumberError: false,
    petTagInfo: {
      FamilyMember1: {
        FirstName: '',
        LastName: '',
        PhoneNumber: '',
      },
      FamilyMember2: {
        FirstName: '',
        LastName: '',
        PhoneNumber: '',
      },
      FamilyMember3: {
        FirstName: '',
        LastName: '',
        PhoneNumber: '',
      },
      MessageToFinder: '',
      TagNumber: '',
    },
    successPetTagDelete: false,
    successPetTagPost: false,
    tagNumberErrorMessage: '',
  }),
  FIGO_TAG_MODAL_SHOWN: (state) => ({
    ...state,
    isFigoTagModalVisible: true,
  }),
  FIRST_STEP_SHOWED: (state) => ({
    ...state,
    currentModalStep: {
      isFirstStep: true,
      isRemoveStep: false,
      isSecondStep: false,
    },
  }),
  MESSAGE_TO_FINDER_SETTED: (state, { payload }) => ({
    ...state,
    petTagInfo: {
      ...state.petTagInfo,
      MessageToFinder: payload,
    },
  }),
  PET_ID_SETTED: (state, { payload }) => ({
    ...state,
    petTagInfo: {
      ...state.petTagInfo,
      PetId: payload,
    },
  }),
  PET_TAG_INFO_EDIT_MODE_SET: (state, { payload }) => ({
    ...state,
    petTagInfo: {
      ...state.petTagInfo,
      MessageToFinder: payload.messageToFinder,
      TagNumber: payload.tagNumber,
    },

  }),
  PET_TAG_INFO_LOAD_FAILED: (state, action) => ({
    ...state,
    error: action.payload,
    isLoadingPetInfo: false,
  }),
  PET_TAG_INFO_LOADED: (state, action) => ({
    ...state,
    data: {
      ...action.payload,
      EmergencyContacts: action.payload.EmergencyContacts.map((contact) => ({
        ...contact,
        PhoneNumber: contact.PhoneNumber
          ? contact.PhoneNumber.replace(/[()-\s]/g, '') : '',
      })),
    },
    isLoadingPetInfo: false,
  }),
  PET_TAG_INFO_POST_FAILED: (state) => ({
    ...state,
    isPetTagInfoRequestStarted: false,
  }),
  PET_TAG_INFO_POST_REQUESTED: (state) => ({
    ...state,
    isPetTagInfoRequestStarted: true,
  }),
  PET_TAG_INFO_POSTED: (state) => ({
    ...state,
    isPetTagInfoRequestStarted: false,
    successPetTagPost: true,
  }),
  PET_TAG_INFO_REQUESTED: (state) => ({
    ...state,
    isLoadingPetInfo: true,
  }),
  PET_TAG_SETTED: (state, { payload }) => ({
    ...state,
    isTagNumberError: false,
    petTagInfo: {
      ...state.petTagInfo,
      TagNumber: payload,
    },
    tagNumberErrorMessage: '',
  }),
  REMOVE_STEP_SHOWED: (state) => ({
    ...state,
    currentModalStep: {
      isFirstStep: false,
      isRemoveStep: true,
      isSecondStep: false,
    },
  }),
  SECOND_STEP_SHOWED: (state) => ({
    ...state,
    currentModalStep: {
      isFirstStep: false,
      isRemoveStep: false,
      isSecondStep: true,
    },
  }),
  TAG_NUMBER_DELETED: (state) => ({
    ...state,
    isDeleteTagNumberRequestStarted: false,
    successPetTagDelete: true,
  }),
  TAG_NUMBER_ERROR_MESSAGE_SETTED: (state, { payload }) => ({
    ...state,
    isTagNumberError: true,
    tagNumberErrorMessage: payload,
  }),
  TAG_NUMBER_MODE_SET: (state, { payload }) => ({
    ...state,
    isTagNumberMode: payload,
  }),
}, INITIAL_STATE);
