import React from 'react';
import { useTranslation } from 'react-i18next';
import { UploadPhotoButton } from '../common/UploadPhotoButton';
import { getNameInitials } from '../../services/utils';
import './PetItemCardMyPets.css';

const PetProfileImage = ({
  containerClassName,
  hideAddPhoto,
  image,
  imageClassName = 'My-pets-item-card-image-pet-list',
  initialsClassName = 'My-pets-item-card-image-initials',
  isProfile,
  onAddPhotoClick = () => {},
  onClick = () => {},
  petName,
}) => {
  const { t } = useTranslation('myPets');

  const petInitials = getNameInitials(petName);

  return (
    <div className={containerClassName || 'Pet-profile-image-container'}>
      {image ? (
        <button
          className="Like-span My-pets-item-card-image-action"
          onClick={onClick}
          type="button"
        >

          <img
            alt="My-pets item"
            className={`My-pets-item-card-image ${imageClassName}`}
            src={image}
          />
        </button>
      ) : (
        <div className={`My-pets-item-card-image ${imageClassName}`}>
          <h1 className={`${initialsClassName} No-clicked `}>{petInitials}</h1>

          <button
            className={`My-pets-item-card-image-button ${
              hideAddPhoto ? 'Gone' : ''
            }`}
            disabled={hideAddPhoto}
            onClick={onAddPhotoClick}
            type="button"
          >
            {t('PetCard.AddPhoto')}
          </button>
        </div>
      )}

      {isProfile && (
        <UploadPhotoButton
          className="My-pets-item-card-profile-image-icon"
          iconOnly
          isProfilePicture
          onClick={onAddPhotoClick}
        />
      )}
    </div>
  );
};

export { PetProfileImage };
