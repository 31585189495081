import React from 'react';
import { useTranslation } from 'react-i18next';

import { AttachmentItem } from './AttachmentItem';
import { DragZone } from './DragZone';

import arrowIcon from '../../../../assets/claimbot/upload-circle.svg';

import {
  ACCEPTED_FILES,
} from '../../../../constants';

import './AttachmentSelector.css';

const AttachmentSelector = ({
  attachments = [],
  onAddAttachment = () => { },
  onRemoveAttachment = () => { },
}) => {
  const { t } = useTranslation('fileClaimbot');

  function addFiles(files) {
    const fileList = Array.from(files);
    onAddAttachment(fileList);
  }

  function handleFileChange(event) {
    addFiles(event.target.files);
  }

  return (
    <div className="File-claimbot-attachment-selector-container">
      <div className="File-claimbot-attachment-drag-zone-card">
        <DragZone attachments={attachments} onAddFiles={addFiles} />

        <img alt="" src={arrowIcon} />

        <span>
          {t('attachments.dragAndDropOr')}

          <label htmlFor="choseFileAttachments">
            <input
              accept={ACCEPTED_FILES}
              id="choseFileAttachments"
              multiple
              onChange={handleFileChange}
              style={{ display: 'none' }}
              type="file"
              value=""
            />

            {t('attachments.chooseFile')}
          </label>

          {t('attachments.toUpload')}
        </span>

        <span>{t('attachments.fileLimit')}</span>
      </div>

      {attachments.length > 0
        ? (
          <h3
            className="File-claimbot-attachment-selector-uploaded-files-title"
          >
            {t('attachments.uploadedFiles')}
          </h3>
        )
        : null}

      {attachments.map((att) => (
        <AttachmentItem
          key={String(att.key)}
          attachment={att}
          onRemoveAttachment={onRemoveAttachment}
          t={t}
        />
      ))}
    </div>
  );
};

export { AttachmentSelector };
