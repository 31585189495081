import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TouchableDiv from './TouchableDiv';
import trashImage from '../../assets/settings/trash.png';
import errorModalHeaderImage from '../../assets/error-modal-header.png';
import { dismissAlert } from '../../actions';
import './CustomModal.css';

const CustomModal = () => {
  const { t } = useTranslation('common');
  const [inputValue, changeInputValue] = useState('');
  const dispatch = useDispatch();
  const alert = useSelector(({
    common,
  }) => (common.alert), shallowEqual);
  const CONTACT_US_URL =
    'https://figopetinsurance.com/contact-us-figo-pet-insurance';

  function handleInputChanged(event) {
    switch (event.target.id) {
      case 'modalInput':
        changeInputValue(event.target.value);
        break;

      default:
        break;
    }
  }

  function handleInputModalSubmit(event) {
    event.preventDefault();
    dispatch(dismissAlert());

    if (alert.primaryAction) {
      alert.primaryAction({ inputValue });
    }

    changeInputValue('');
  }

  function onPrimaryAction() {
    dispatch(dismissAlert());

    if (alert.primaryAction) {
      alert.primaryAction();
    }
  }

  function onSecondaryAction() {
    dispatch(dismissAlert());

    if (alert.secondaryAction) {
      alert.secondaryAction();
    }

    changeInputValue('');
  }

  function handleCloseModal() {
    dispatch(dismissAlert());
  }

  function handleModalOverlayClick() {
    const { type } = alert;
    const overlayClose =
      type === 'info'
      || type === 'error'
      || type === 'input'
      || alert.overlayClose;

    if (overlayClose) {
      handleCloseModal();
    }
  }

  const renderModal = () => {
    switch (alert.type) {
      case 'info':
        return (
          <div
            className="Modal-overlay"
            id="InfoModalOverlay"
            onClick={handleModalOverlayClick}
            onKeyDown={handleModalOverlayClick}
            role="button"
            tabIndex={0}
          >
            <Card className="Info-modal-card">
              <Image
                className="Info-modal-image-title"
                fluid
                src={alert.image}
              />

              <div className="Info-modal-text-body">
                <p className="Info-modal-body-text">{alert.title}</p>
              </div>

              <div className="Info-modal-subtitle-container">
                <p className="Info-modal-subtitle-text">{alert.subtitle}</p>
              </div>

              <Button
                className="Info-modal-action Custom-info"
                onClick={onPrimaryAction}
                variant="info"
              >
                {alert.primaryText}
              </Button>
              <div className="Info-modal-actions" />
            </Card>
          </div>
        );

      case 'error':
        return (
          <TouchableDiv
            className="Modal-overlay"
            id="ErrorModalOverlay"
            onClick={handleModalOverlayClick}
          >
            <Card className="Error-modal-card">
              <button
                className="Error-modal-close-button"
                onClick={handleCloseModal}
                type="submit"
              >
                <span className="Error-modal-close-text">x</span>
              </button>
              <Image
                className="Error-modal-image-title"
                fluid
                src={errorModalHeaderImage}
              />

              <span className="Error-modal-body-text">
                {t('errorModal.title')}
              </span>
              <div>
                <span className="Error-modal-subtext">
                  {t('errorModal.subtitle')}
                </span>
                <a
                  className="Error-modal-subtext Contact-subtext"
                  href={CONTACT_US_URL}
                >
                  {t('errorModal.subtitleContact')}
                </a>
              </div>
            </Card>
          </TouchableDiv>
        );

      case 'input':
        return (
          <TouchableDiv
            className="Modal-overlay"
            id="InputModalOverlay"
            onClick={handleModalOverlayClick}
          >
            <Card className="Input-modal-card">
              <div className="Static-modal-content">
                <Image
                  className="Input-modal-image"
                  fluid
                  height={alert.imageSize}
                  src={alert.image}
                  width={alert.imageSize}
                />

                <span className="Input-modal-title">{alert.title}</span>

                <span className="Input-modal-subtitle">{alert.subtitle}</span>
              </div>

              <Form onSubmit={handleInputModalSubmit}>
                <Form.Group
                  className="Input-modal-form-group"
                  controlId="modalInput"
                >
                  <Form.Label>{alert.inputLabel}</Form.Label>

                  <Form.Control
                    className={`Input-modal-input
                      ${alert.error && ' Input-error'}`}
                    maxLength={alert.maxLength}
                    onChange={handleInputChanged}
                    placeholder={alert.placeholder}
                    type={alert.inputType}
                    value={inputValue}
                  />

                  <p className="Error-label">
                    {alert.error || ''}
                  </p>
                </Form.Group>

                <div className="Input-modal-buttons-container">
                  <Button
                    className="Input-modal-button Custom-light"
                    onClick={onSecondaryAction}
                    size="lg"
                    variant="light"
                  >
                    <span className="Input-modal-secondary-text">
                      {alert.secondaryText}
                    </span>
                  </Button>

                  <Button
                    className="Input-modal-button Custom-info"
                    size="lg"
                    type="submit"
                    variant="info"
                  >
                    <span className="Input-modal-primary-text">
                      {alert.primaryText}
                    </span>
                  </Button>
                </div>
              </Form>
            </Card>
          </TouchableDiv>
        );

      default:
        return (
          <TouchableDiv
            className="Modal-overlay"
            onClick={handleModalOverlayClick}
          >
            <Card className="Confirm-modal-card">
              <Image
                className="Confirm-modal-image-title"
                fluid
                src={alert.image || trashImage}
              />

              <div className="Confirm-modal-text-body">
                <p className="Confirm-modal-body-text">{alert.body}</p>
              </div>

              <div className="Confirm-modal-actions">
                <Button
                  className="Confirm-modal-action Button-mint Custom-light"
                  onClick={onSecondaryAction}
                  variant="light"
                >
                  {alert.secondaryText}
                </Button>

                <Button
                  className="Confirm-modal-action Custom-info-blue"
                  onClick={onPrimaryAction}
                  variant="info"
                >
                  {alert.primaryText}
                </Button>
              </div>
            </Card>
          </TouchableDiv>
        );
    }
  };

  return alert.visible && renderModal();
};

export default CustomModal;
