import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getContactBrandData } from '../../../actions';
import { useSubmitRequestAccountChanges } from '../../../hooks/useFeatureFlags';

const InsuranceAccountRequestChange = ({
  onContactUsClick = () => { },
  toggleUpdateForm = () => { },
}) => {
  const { t } = useTranslation('myAccount');
  const dispatch = useDispatch();
  const submitAccountChanges = useSubmitRequestAccountChanges();

  useEffect(() => {
    dispatch(getContactBrandData());
  }, []);

  return (
    <>
      <h2 className="Insurance-account-subtitle">
        {t('insuranceAccountSection.requestChange')}
      </h2>

      <p className="Insurance-account-subtitle-description">
        {t('insuranceAccountSection.updateDescription')}
      </p>

      <div className="Insurance-account-buttons-container">
        <button
          className="Insurance-account-button-contact-us"
          onClick={onContactUsClick}
          type="button"
        >
          {t('insuranceAccountSection.contactUs')}
        </button>

        {submitAccountChanges ? (
          <button
            className="Insurance-account-button-contact-submit"
            onClick={toggleUpdateForm}
            type="button"
          >
            {t('insuranceAccountSection.submitRequestForm')}
          </button>
        ) : null}
      </div>
    </>
  );
};

export { InsuranceAccountRequestChange };
