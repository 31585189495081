import React, { useEffect } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { convertImpersonateCustomerId } from '../../actions';

const RedirectCustomerId = ({ isSessionActive, location }) => {
  const dispatch = useDispatch();
  const store = useSelector(({ session }) => ({ session }), shallowEqual);
  const {
    impersonateCustomerId: customerId,
    isImpersonate,
  } = store.session;
  const customerIdParam = new URLSearchParams(location.search)
    .get('customerId');

  useEffect(() => {
    if (customerIdParam) {
      dispatch(convertImpersonateCustomerId(customerIdParam));
    }
  }, [customerIdParam]);

  const handleImpersonateRedirect = () => {
    if (customerId && isImpersonate && !isSessionActive) {
      return (<Redirect to={`/impersonate/${customerId}`} />);
    }
    return <Redirect to="/" />;
  };

  return (handleImpersonateRedirect());
};

export default withRouter(RedirectCustomerId);
