import React from 'react';
import { useTranslation } from 'react-i18next';

import { PetCard } from '../../../insurance/multiplePets/PetCard';

import { PET_GENDER } from '../../../../constants';

import './PetSelector.css';

const PetSelector = ({
  onPetItemPress = () => { },
  pets = [],
  selectedPet = null,
}) => {
  const { t } = useTranslation('fileClaimbot');

  return (
    <div className="Pet-selector-container">
      {pets.map((pet) => (
        <PetCard
          key={`${pet.Id}-${pet.Name}`}
          age={pet.Age}
          breed={pet.Breed}
          classContainer={pet === selectedPet
            ? 'Pet-selector-pet-selected'
            : 'Pet-selector-pet-non-selected'}
          gender={pet.Gender === PET_GENDER.MALE
            ? t('petGenders.male') : t('petGenders.female')}
          name={pet.Name}
          onClick={onPetItemPress(pet)}
          picture={pet.PictureUrl}
          weight={pet.Weight}
        />
      ))}
    </div>
  );
};

export { PetSelector };
