import { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { MINIMUM_DAYS_REQUIRED_FOR_QUOTE, POLICY_STATUS } from '../constants';
import { useAlphabeticalSortedPets } from './useAlphabeticalSortedPets';

const usePetPolicies = () => {
  const store = useSelector(({
    billingPayments,
    claims,
    policies,
  }) => ({
    billingPayments,
    claims,
    policies,
  }), shallowEqual);

  const {
    billingPayments,
    policies: {
      allPets,
      loading,
      pets,
    },
    claims: { petID },
  } = store;

  const selectedPolicyIndex = billingPayments.selectedPolicy;
  const hasPets = loading
    ? false : !!allPets.length;
  const getAlphabeticalSortedPets = useAlphabeticalSortedPets();

  const noPolicyPets = useMemo(() => {
    const policyPetsIds = pets.map((pet) => pet.PetId);
    return allPets
      .filter((pet) => !policyPetsIds.includes(pet.PetId)
        && pet.AgeInDays >= MINIMUM_DAYS_REQUIRED_FOR_QUOTE);
  }, [pets, allPets]);

  const selectedPet = useMemo(() => {
    let petIndex = 0;
    if (petID && getAlphabeticalSortedPets) {
      petIndex = getAlphabeticalSortedPets
        .findIndex((pet) => pet.PetId === petID);
    }
    return petIndex < 0 ? 0 : petIndex;
  }, [petID, getAlphabeticalSortedPets]);

  function unPolicyPet() {
    if (!hasPets) {
      return true;
    }

    if (!allPets.length) {
      return false;
    }

    const pet = allPets[selectedPet];
    const policy = pet.policies[selectedPolicyIndex];

    return !policy.Number;
  }

  function policyCancelled() {
    if (!allPets.length) {
      return true;
    }

    const pet = allPets[selectedPet];
    const policy = pet.policies[selectedPolicyIndex];

    return policy.Status === POLICY_STATUS.cancelled;
  }

  return {
    allPets,
    hasPets,
    loading,
    noPolicyPets,
    policyCancelled,
    selectedPet,
    selectedPolicyIndex,
    unPolicyPet,
  };
};

export { usePetPolicies };
