import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import TouchableDiv from '../common/TouchableDiv';

import inboxIcon from '../../assets/inboxDocsMultibrand/inbox.svg';
import arrowIcon from '../../assets/inboxDocs/arrow.svg';

import { getNullSafeDate } from '../../services/utils';
import { INBOX_DOCS } from '../../constants';

import { filePermissionsLoaded } from '../../actions';

import './InboxSection.css';

const InboxSection = ({
  inbox = { Metadata: { LastAddedDate: '', UnReadDocs: 0 } },
  email,
  onClick = () => { },
}) => {
  const { t } = useTranslation('inboxAndDocs');
  const dispatch = useDispatch();
  const amountFiles = inbox.Metadata.UnReadDocs;

  function handleInboxClick() {
    const filePermissions = inbox.FilePermissions;
    dispatch(filePermissionsLoaded(filePermissions));

    onClick({ id: INBOX_DOCS.inbox, name: t('inboxTitle') });
  }

  return (
    <div>
      <div className="Inbox-title">{t('inboxTitle')}</div>

      <TouchableDiv
        className="Inbox-card-container Flex"
        id="Inbox"
        onClick={handleInboxClick}
      >
        <div className="Flex No-clicked ">
          <div className="Blue-background">
            <img
              alt=""
              src={inboxIcon}
            />
          </div>

          <div className="Inbox-card-email-container Flex Flex-column">
            <span className="Inbox-card-email">{email?.toLowerCase()}</span>

            <span className="Inbox-card-text">{t('inboxText')}</span>
          </div>
        </div>

        <div className="flex No-clicked">
          {amountFiles > 0 && (
            <div className="Inbox-card-unread-files flex flex-column">
              <span className="Inbox-unread-files-amount">
                {t('unreadFile', { count: amountFiles })}
              </span>

              <span className="Inbox-unread-files-date">
                {t('dateAdded', {
                  date: getNullSafeDate(inbox.Metadata.LastAddedDate),
                  interpolation: { escapeValue: false },
                })}
              </span>
            </div>
          )}

          <img
            alt=""
            className="Inbox-card-arrow-icon No-clicked"
            src={arrowIcon}
          />
        </div>
      </TouchableDiv>
    </div>
  );
};

export { InboxSection };
