import React from 'react';
import './Shimmer.css';

const Shimmer = (props = { visible: true }) => {
  function isVisible() {
    if (props.visible) {
      return `${props.className} Shimmer`;
    }

    return props.className || '';
  }

  function styles() {
    if (!props.visible) {
      return {};
    }

    const style = { borderRadius: props.borderRadius, margin: props.margin };

    if (props.height) {
      style.height = props.height;
    }

    if (props.width) {
      style.width = props.width;
    }

    return style;
  }

  switch (props.element) {
    case 'span': {
      return (
        <span className={isVisible()} style={styles()}>
          {props.children}
        </span>
      );
    }

    case 'touchableDiv': {
      return (
        <button
          ref={props.compRef}
          className={isVisible()}
          id={props.id}
          onClick={props.onClick}
          onKeyDown={props.onKeyDown}
          onKeyPress={props.onKeyPress}
          style={styles()}
          type="button"
          {...props.ariaProps}
        >
          {props.children}
        </button>
      );
    }

    case 'fragment':
      return (
        <>
          {props.visible ? (
            <div className={isVisible()} style={styles()}>
              {props.children}
            </div>
          ) : (
            <>
              {props.children}
            </>
          )}
        </>
      );

    default: return (
      <div className={isVisible()} style={styles()}>
        {props.children}
      </div>
    );
  }
};

Shimmer.defaultProps = {
  borderRadius: 8,
  height: 0,
  visible: true,
  width: 0,
};

export default Shimmer;
