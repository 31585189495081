import { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { currencyFormat } from '../../../../services/utils';
import {
  getContactBrandData,
  reviewChangesVisibleChanged,
  submitChangeCoverage,
} from '../../../../actions';
import {
  POWERUP_TYPE,
  POWER_UPS_RELATION,
} from '../../../changeCoverage/powerups/powerups.constans';

const useCoveragePreviewSubmit = ({
  petInfo = '',
  policyNumber = '',
}) => {
  const dispatch = useDispatch();
  const [contactUsVisible, setContactUsVisible] = useState(false);
  const store = useSelector(({
    personalInformation,
    policies,
  }) => ({ ...policies.changeCoverage, personalInformation }));
  const {
    loadingSubmit,
    initialCoverageValues,
    quoteSelectedValues: {
      deductibleId,
      reimbursementId,
      selectedPlan,
      total,
      totalMonthly,
      totalAnnual,
    },
    rate: {
      effectiveDate,
      insuranceProduct,
      isAnnual,
      petQuoteResponseList,
    },
    success,
  } = store;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getContactBrandData());
  }, []);

  const powerUps = useMemo(() => {
    const quoteModifiers = petQuoteResponseList
      ?.find((item) => item.petQuoteId === petInfo.petQuoteId)?.modifiers || [];
    const { InsuranceModifiers } = petInfo;
    if (InsuranceModifiers) {
      const powerUpsFilter = InsuranceModifiers
        .filter((modifier) => modifier.InsuranceModifierTypeId === POWERUP_TYPE
          && modifier.IsVisible)
        .map((powerUp) => {
          const isIncluded = quoteModifiers
            .find((item) => item.id === powerUp.Id)?.isSelected || false;

          return {
            id: powerUp.Id,
            isIncluded,
            isUpdated: initialCoverageValues.InsuranceModifiers
              .find((item) => item.id === powerUp.Id)?.isIncluded
              !== isIncluded,
            name: powerUp.AppText,
          };
        });

      return powerUpsFilter;
    }
    return [];
  }, [
    initialCoverageValues.InsuranceModifiers,
    petInfo,
    petQuoteResponseList,
  ]);

  const priceWithFormat = useMemo(() => {
    const price = isAnnual
      ? total : totalMonthly;
    const annualTotal = isAnnual ? totalAnnual : 0;

    if (!price) {
      return { decimal: '00', integer: 0, monthlyTotal: 0, total: 0 };
    }

    return {
      annualTotal: parseFloat(annualTotal),
      monthlyDecimal: `.${price.toString().split('.')[1] || '00'}`,
      monthlyInteger: price.toString().split('.')[0],
      monthlyTotal: parseFloat(totalMonthly),
    };
  }, [isAnnual, total, totalAnnual, totalMonthly]);

  const fees = useMemo(() => {
    if (!insuranceProduct?.InsuranceProductFee) {
      return [];
    }

    const { InsuranceProductFee } = insuranceProduct;

    return InsuranceProductFee.map(({ InsuranceFee }) => ({
      amount: InsuranceFee.Amount,
      description: InsuranceFee.DisplayName,
    }));
  }, [insuranceProduct]);

  const totalFees = fees.reduce((prev, next) => (prev + next.amount), 0);

  const annualPrice = `$${priceWithFormat.annualTotal}`;
  const monthlyPrice =
    `$${priceWithFormat.monthlyInteger}${priceWithFormat.monthlyDecimal}`;

  const totalPriceWithFees = useMemo(() => {
    const totalWithOutFees = isAnnual
      ? priceWithFormat.annualTotal : priceWithFormat.monthlyTotal;
    const totalWithFees = totalWithOutFees + totalFees;

    return currencyFormat({ value: totalWithFees });
  }, [isAnnual, totalFees, priceWithFormat]);

  const initialTotalPriceWithFees = useMemo(() => {
    const totalWithOutFees = isAnnual
      ? initialCoverageValues.annualAmountPlan
      : initialCoverageValues.monthlyAmountPlan;
    const totalWithFees = totalWithOutFees + totalFees;

    return currencyFormat({ value: totalWithFees });
  }, [isAnnual, totalFees, initialCoverageValues]);

  const handleCancel = useCallback(() => {
    if (loadingSubmit) {
      return;
    }

    dispatch(reviewChangesVisibleChanged(false));
  }, []);

  const handleSubmit = useCallback(() => {
    const { InsuranceModifiers, modifiers } = petInfo;
    const vetFees = InsuranceModifiers.find((item) => item.PMSModifierId
      === POWER_UPS_RELATION.VETERINARY_EXAM_FEES_PSM_ID);
    const hasVetFees = modifiers.length && modifiers
      .find((modifier) => modifier.id === vetFees.Id && modifier.isSelected);
    const selectedModifiers = modifiers || [];

    // powerups hidden and seleted id's
    const hideModifiers = petInfo.InsuranceModifiers
      .filter((insuranceModifer) => insuranceModifer
        .InsuranceModifierTypeId === POWERUP_TYPE
        && !insuranceModifer.IsVisible
        && insuranceModifer.IsSelected)
      .map((insuranceModifer) => ({
        id: insuranceModifer.Id,
        isSelected: true,
      }));

    // powerup id's selected, hidden and powerups children
    let allModifiers = [...selectedModifiers, ...hideModifiers];
    const extraCarePack = petInfo.InsuranceModifiers.find((item) => item
      .PMSModifierId === POWER_UPS_RELATION.EXTRA_CARE_PACK_PSM_ID);

    if (extraCarePack) {
      const extraCarePackSelected = allModifiers
        .find((modifier) => modifier.id === extraCarePack.Id);

      const extraCarePackChildren =
        extraCarePack.BundleInsuranceModifiers.map((child) => ({
          id: child.Id,
          isSelected: extraCarePackSelected
            && extraCarePackSelected.isSelected,
        }));

      allModifiers = [
        ...allModifiers,
        ...extraCarePackChildren,
      ];
    }

    dispatch(submitChangeCoverage({
      body: {
        Deductible: deductibleId,
        EffectiveDate: effectiveDate,
        HasVetFees: !!hasVetFees,
        modifiers: allModifiers,
        QuotePlan: selectedPlan,
        Reimbursement: reimbursementId,
      },
      policyNumber,
    }));
  }, [
    deductibleId,
    dispatch,
    effectiveDate,
    petInfo,
    policyNumber,
    reimbursementId,
    selectedPlan,
  ]);

  const onContactUsClick = () => {
    setContactUsVisible(true);
  };

  const onContactUsClose = () => {
    setContactUsVisible(false);
  };

  return {
    annualPrice,
    contactUsVisible,
    effectiveDate,
    fees,
    handleCancel,
    handleSubmit,
    initialCoverageValues,
    initialTotalPriceWithFees,
    isAnnual,
    loadingSubmit,
    monthlyPrice,
    onContactUsClick,
    onContactUsClose,
    powerUps,
    quoteSelectedValues: store.quoteSelectedValues,
    success,
    totalPriceWithFees,
  };
};

export { useCoveragePreviewSubmit };
