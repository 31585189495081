import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import navArrowIcon from '../../../assets/myAccount/nav-arrow.svg';

import './PetCloudBreadcrumbs.css';

const PetCloudBreadcrumbs = ({
  activeRoute = '',
  rootRoute = '',
  rootRouteLink = '',
}) => {
  const history = useHistory();

  const handleRootClick = useCallback(() => {
    if (rootRouteLink) {
      history.push(rootRouteLink);
    } else {
      history.goBack();
    }
  }, [rootRouteLink]);

  return (
    <nav aria-label="Breadcrumb" className="Pet-cloud-breadcrumbs-container">
      <ol className="Pet-cloud-breadcrumbs-inner-container">
        <li className="Pet-cloud-breadcrumbs-item">
          <button
            onClick={handleRootClick}
            type="button"
          >
            {rootRoute}
          </button>
        </li>

        <li
          aria-hidden="true"
          className="Pet-cloud-breadcrumbs-divider"
          role="presentation"
        >
          <img alt="" src={navArrowIcon} />
        </li>

        <li aria-current="page" className="Pet-cloud-breadcrumbs-item-active">
          {activeRoute}
        </li>
      </ol>
    </nav>
  );
};

export { PetCloudBreadcrumbs };
