import React from 'react';

import Shimmer from '../common/Shimmer';

import { PET_TYPE } from '../../constants';
import dogDefaultImage from '../../assets/billingPayments/dog-placeholder.png';
import catDefaultImage from '../../assets/billingPayments/cat-placeholder.png';
import './css/CurrentPetPolicy.css';

const CurrentPetPolicy = ({ currentPet = {}, loading = false }) => {
  const placeholderImage = currentPet.Type === PET_TYPE.CAT
    ? catDefaultImage
    : dogDefaultImage;

  return (
    <Shimmer
      className="Current-pet-policy-shimmer"
      element="fragment"
      visible={loading}
    >
      <div className="Current-pet-policy-container">
        <img alt="" src={currentPet.ProfileUrl || placeholderImage} />

        <div className="Current-pet-policy-info-container">
          <span className="Current-pet-policy-name">{currentPet.PetName}</span>

          <span className="Current-pet-policy-breed">
            {currentPet.Breed}
          </span>
        </div>
      </div>
    </Shimmer>
  );
};

export { CurrentPetPolicy };
