import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import alertImage from '../../assets/alert.png';
import './MessageModal.css';

const MessageModal = ({
  body,
  buttonText,
  hideClosebutton = false,
  image = alertImage,
  onButtonClick,
  title,
  visible,
}) => {
  function handleModalOverlayClick(event) {
    if (event.target.id === 'messageModalOverlay') {
      if (onButtonClick) {
        onButtonClick();
      }
    }
  }

  function handleOnButtonClick() {
    if (onButtonClick) {
      onButtonClick();
    }
  }

  function renderModal() {
    if (visible) {
      return (
        <div
          className="Modal-overlay"
          id="messageModalOverlay"
          onClick={handleModalOverlayClick}
          onKeyDown={handleModalOverlayClick}
          role="button"
          tabIndex={0}
        >
          <Card className="Message-modal-card">
            <div className="Message-modal-card-content">
              <Image
                className="Message-modal-image"
                fluid
                src={image}
              />

              <span className="Message-modal-body-title">{title}</span>

              <div className="Message-modal-body-container">
                <p className="Message-modal-body-text">{body}</p>
              </div>

              {hideClosebutton
                ? null
                : (
                  <Button
                    className="Message-modal-button Custom-info"
                    id="messageModalButton"
                    onClick={handleOnButtonClick}
                    variant="info"
                  >
                    {buttonText}
                  </Button>
                )}
            </div>
          </Card>
        </div>
      );
    }

    return null;
  }

  return renderModal();
};

export default MessageModal;
