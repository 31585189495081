import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { youngPetModalClosed } from '../../actions';
import ModalContainer from '../common/ModalContainer';
import TouchableDiv from '../common/TouchableDiv';
import './YoungPetModal.css';

const YoungPetModal = ({ isVisible }) => {
  const { t } = useTranslation('insuranceScreen');
  const dispatch = useDispatch();

  function handleCloseModal() {
    dispatch(youngPetModalClosed());
  }

  return (
    <ModalContainer show={isVisible}>
      <div className="Young-pet-modal-container">
        <span className="Young-pet-modal-title">
          {t('youngPetModal.title')}
        </span>

        <span className="Young-pet-modal-message">
          {t('youngPetModal.message')}
        </span>

        <TouchableDiv
          className="Young-pet-button-container-blue"
          id="youngPetButton"
          onClick={handleCloseModal}
        >
          <span className="Young-pet-button-text">
            {t('youngPetModal.button')}
          </span>
        </TouchableDiv>

      </div>
    </ModalContainer>
  );
};

export { YoungPetModal };
