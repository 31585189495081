import './ReimbursementDetailsFrame.css';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { WEB_VIEW_TYPES } from '../../../../constants';
import { useWebviewMultibrand } from '../../../../hooks';

export const ReimbursementDetailsFrame = ({
  petId = '',
  policy = {},
}) => {
  const { t } = useTranslation('myAccount');

  const {
    getWebView,
    isLoading,
    title,
    url,
  } = useWebviewMultibrand();
  const { marketingChannelId, policyId, policyNumber } = policy;

  useEffect(() => {
    const params = {
      companyId: marketingChannelId,
      petId,
      policyId,
      policyNumber,
      webViewType: WEB_VIEW_TYPES.reimbursementMethod,
    };

    if (getWebView) {
      getWebView(params);
    }
  }, [
    getWebView,
    marketingChannelId,
    petId,
    policyId,
    policyNumber,
  ]);

  return (
    <div
      className={`Reimbursement-details__container 
      Colors-${marketingChannelId}`}
    >
      <div className="Reimbursement-details__page-title">
        <h1>{t('reimbursementSection.policyDetails')}</h1>
      </div>

      <div className="Reimbursement-details__iframe">
        {!isLoading && (
          <iframe
            allowFullScreen
            src={url}
            title={title}
          />
        )}
      </div>
    </div>
  );
};
