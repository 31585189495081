import { call, put, select, delay, takeLatest, all } from 'redux-saga/effects';
import {
  acceptAddressUpdate,
  addressUpdated,
  billingInfoLoaded,
  billingInfoLoadFailed,
  changeProfilePicture as CHANGE_PROFILE_PICTURE,
  changeProfilePictureFailed,
  changeProfilePictureSuccess,
  closeMultiPoliciesModal,
  confirmAddressChangeModalChanged,
  coverPictureRemoved,
  displayEndorsementSuccessModal,
  emailUpdated,
  endorsementLoaded,
  loadPaymentHistory,
  loadPoliciesPaymentNumber,
  loadReimbursementMethod,
  loadZipCodeDetailsFailed,
  namesUpdated,
  passwordUpdated,
  paymentHistoryLoaded,
  paymentHistoryLoadFailed,
  paymentMethodPastDueModalVisibleChanged,
  paymentMethodUpdateAttempted,
  clearUserCache,
  paymentMethodUpdated,
  petCloudEmailUpdated,
  petsLoadAttempt,
  petsLoaded,
  petsLoadedFinished,
  phonesUpdated,
  policyDetailsLoaded,
  policyDetailsLoadFailed,
  reimbursementMethodRoutingValidationFailed,
  reimbursementMethodUpdated,
  reimbursementMethodUpdateFailed,
  removeAvatarPicture as REMOVE_AVATAR_PICTURE,
  removeCoverPicture as REMOVE_COVER_PICTURE,
  removeCoverPictureFailed,
  resetEndorsementData,
  showAlert,
  socialProfileLoadAttempt,
  socialProfileLoaded,
  socialProfileLoadedFinished,
  storeStates,
  updateEmailError,
  updatePasswordError,
  updatePetCloudEmailError,
  updatePhonesError,
  zipCodeDetailsLoaded,
  avatarPictureRemoved,
  removeAvatarPictureFailed,
  paymentHistoryCleared,
  updateInsuranceInformation as UPDATE_INSURANCE_INFORMATION,
  updateInsuranceInformationFailed,
  updateInsuranceInformationSuccess,
  displayConfirmChangesModal,
  getFeatureFlags as GET_FEATURE_FLAGS,
  getFeatureFlagsFailed,
  getFeatureFlagsLoaded,
  loadPolicyDetails,
} from '../actions';
import {
  apiCallWithApiKey,
  del,
  get,
  IDOR_HEADER_V2,
  patch,
  post,
  postApiV2,
  postV2,
} from '../services/api';
import { isEmailValid } from '../services/utils';
import { IMAGE_UPLOAD_TYPE, REIMBURSEMENT_METHODS } from '../constants';
import {
  useClaimsApiV1ForSagas,
  useCustomerApi150617V1ForSagas,
  useClaimsAPIV2T155969ForSagas,
} from '../services/featureFlagsForSagas';
import { getPolicySummary } from './policies';

export function* getStates(dispatch) {
  const response = yield call(get, dispatch, 'api/State');
  if (response.success) {
    yield put(storeStates(response.Data));
  }
}

function* validateHomeDataChanged(type) {
  const {
    address1,
    address2,
    city,
    firstName,
    FirstName,
    isFirstNameValid,
    isLastNameValid,
    isPrimaryPhoneValid,
    isSecondaryPhoneValid,
    lastName,
    LastName,
    OtherPhoneNumber,
    PhoneNumber,
    Phone1,
    Phone2,
    primaryPhone,
    secondaryPhone,
    state,
    zipCode,
  } = yield select(({ personalInformation }) => ({
    ...personalInformation,
    City: personalInformation.userInfo.Address.City,
    FirstName: personalInformation.userInfo.FirstName,
    IsNameEditable: personalInformation.userInfo.IsNameEditable,
    LastName: personalInformation.userInfo.LastName,
    Line1: personalInformation.userInfo.Address.Line1,
    Line2: personalInformation.userInfo.Address.Line2 || '',
    OtherPhoneNumber: personalInformation.userInfo.OtherPhoneNumber || '',
    Phone1: personalInformation.userInfo.Address.Phone1,
    Phone2: personalInformation.userInfo.Address.Phone2,
    PhoneNumber: personalInformation.userInfo.PhoneNumber,
    StateId: personalInformation.userInfo.Address.StateId,
    ZipCode: personalInformation.userInfo.Address.ZipCode,
  }));

  let body = {};

  switch (type) {
    case 'address':
      body = {
        City: city,
        Line1: address1.trimStart().trimEnd(),
        Line2: address2.trimStart().trimEnd(),
        Phone1: Phone1 || primaryPhone,
        Phone2: Phone2 || secondaryPhone,
        StateId: state,
        ZipCode: zipCode,
      };

      if (body.Phone1 === primaryPhone && !isPrimaryPhoneValid) {
        body.Phone1 = '';
      }

      if (body.Phone2 === secondaryPhone && !isSecondaryPhoneValid) {
        body.Phone2 = '';
      }
      break;

    case 'names':
      body = {
        FirstName: firstName.trimStart().trimEnd(),
        LastName: lastName.trimStart().trimEnd(),
        Phone1: isPrimaryPhoneValid ? primaryPhone : PhoneNumber,
        Phone2: isSecondaryPhoneValid ? secondaryPhone : OtherPhoneNumber,
      };
      break;

    case 'phones':
      body = {
        FirstName: isFirstNameValid ? firstName : FirstName,
        LastName: isLastNameValid ? lastName : LastName,
        Phone1: primaryPhone,
        Phone2: secondaryPhone,
      };
      break;

    default:
      break;
  }
  return body;
}

export function* recoverPassword(dispatch, { payload }) {
  const { email } = payload;
  const url = `${window.location.origin}/`;

  const requestBody = { Email: email, Url: url };

  if (isEmailValid(email)) {
    const data = yield call(
      apiCallWithApiKey,
      'api/Account/PasswordRecoveryMessage',
      {
        body: requestBody,
        headers: IDOR_HEADER_V2,
        method: 'POST',
      },
      dispatch,
    );

    if (data.IsValid) {
      yield call(payload.successCallback);
    } else if (data.Message) {
      yield call(payload.backendErrorCallback, data.Message, true);
    } else {
      yield put(showAlert({ type: 'error' }));
    }
  } else {
    yield call(payload.errorCallback, 'invalidEmail');
  }
}

export function* updateEmail(dispatch, action) {
  const store = yield select(({ accountLogin, common, session }) => ({
    accountLogin,
    common,
    session,
  }));

  const url = 'customer/Auth/UpdateLocalAccountUserInfo';

  const requestBody = {
    id: store.session.userInfo.CustomerId || '',
    newEmail: action.payload.email || store.accountLogin.email.trim(),
  };

  const response = yield call(
    patch,
    dispatch,
    url,
    requestBody,
  );

  if (response.success) {
    yield put(emailUpdated(requestBody));
    yield call(action.payload.successCallback);
  } else {
    yield call(action.payload.errorCallback);
    yield put(updateEmailError('emailTaken'));
  }

  yield put(displayConfirmChangesModal({ showConfirmChangesModal: true }));
}

export function* updatePassword(dispatch, action) {
  const store = yield select(({ accountLogin }) => (accountLogin));
  const requestBody = {
    Email: action.payload.email || store.userInfo.LoginEmail,
    NewPassword: action.payload.newPassword || store.newPassword,
    Password: action.payload.password || store.oldPassword,
  };
  const data = yield call(
    post,
    dispatch,
    'api/User/UpdateEmailAndPassword',
    requestBody,
  );

  if (data.IsValid) {
    yield put(passwordUpdated(requestBody));
    yield call(action.payload.successCallback);
  } else {
    yield put(updatePasswordError({ message: data.Message, type: data.Type }));
    yield call(action.payload.errorCallback, data.Message);
  }
}

export function* updatePetCloudEmail(dispatch, action) {
  const store = yield select(({ accountLogin }) => (accountLogin));
  const requestBody = {
    User: store.petCloudEmail,
  };
  const data = yield call(
    post,
    dispatch,
    'api/User/UpdatePetCloudEmail',
    requestBody,
  );

  if (data.IsValid) {
    yield put(petCloudEmailUpdated(requestBody));
    yield call(action.payload.successCallback);
  } else {
    if (data.Message === 'This user is already taken!') {
      yield put(updatePetCloudEmailError('userTaken'));
    }

    yield call(action.payload.errorCallback, data.Message);
  }
}

export function* loadZipCodeDetails(dispatch, action) {
  const { zipCode } = action.payload;

  const response = yield call(
    get,
    dispatch,
    `api/State/GetStateByZipCode/${zipCode}`,
  );

  if (response.IsValid) {
    yield put(zipCodeDetailsLoaded(response.Data));
  } else {
    yield put(loadZipCodeDetailsFailed(response.Message));
  }
}

export function* applyAddressUpdate(dispatch, action, noEndorsement) {
  const requestBody = yield call(validateHomeDataChanged, 'address');
  const data = yield call(
    post,
    dispatch,
    'api/User/ApplyUpdateAddress',
    requestBody,
  );

  const {
    userInfo,
    zipCode,
  } = yield select(({ personalInformation }) => (personalInformation));

  if (data.IsValid && data.success) {
    yield put(addressUpdated(requestBody));
    yield put(resetEndorsementData());

    if (userInfo.Address.ZipCode !== zipCode) {
      const payload = { zipCode: requestBody.ZipCode };
      yield loadZipCodeDetails(dispatch, { payload });
    }

    if (!noEndorsement) {
      yield put(displayEndorsementSuccessModal());
    }
  } else {
    yield call(action.payload.errorCallback, data.Message);
  }
  yield put(acceptAddressUpdate(false));
}

export function* updateAddress(dispatch, action) {
  const requestBody = yield call(validateHomeDataChanged, 'address');
  const noEndorsement = true;
  const data = yield call(
    post,
    dispatch,
    'api/User/UpdateAddress',
    requestBody,
  );

  if (data.IsValid && data.success) {
    const { Data } = data;
    if (!Data.PriceChange) {
      yield applyAddressUpdate(dispatch, action, noEndorsement);
      yield put(confirmAddressChangeModalChanged(true));
    } else if (Array.isArray(Data.UserAddressEndorsements)
      && Data.UserAddressEndorsements.length >= 1) {
      const multiplePets = Data.UserAddressEndorsements.length > 1;

      yield put(endorsementLoaded({ data: data.Data, multiplePets }));
    } else {
      yield applyAddressUpdate(dispatch, action, noEndorsement);
    }

    yield call(action.payload.successCallback);
  } else {
    yield call(action.payload.errorCallback, data.Message);
  }
}

export function* updateNames(dispatch, action) {
  const requestBody = yield call(validateHomeDataChanged, 'names');
  const data = yield call(
    post,
    dispatch,
    'api/User/UpdatePersonalInfo',
    requestBody,
  );

  if (data.IsValid) {
    yield put(namesUpdated(requestBody));
    yield call(action.payload.successCallback);
  } else {
    yield put(showAlert({ type: 'error' }));
  }
}

export function* updatePhones(dispatch, action) {
  const requestBody = yield call(validateHomeDataChanged, 'phones');
  const data = yield call(
    post,
    dispatch,
    'api/User/UpdatePersonalInfo',
    requestBody,
  );

  if (data.IsValid) {
    yield put(phonesUpdated());
    yield call(action.payload.successCallback);
  } else {
    yield put(updatePhonesError());
    yield call(action.payload.errorCallback, data.Message);
  }
}

export function* getPaymentHistory(dispatch, action) {
  yield put(paymentHistoryCleared());

  const {
    errorCallback,
    itemsOfFirstPage,
    itemsPerPage,
    pageIndex,
    policyId,
    successCallback,
  } = action.payload;

  const requestBody = {
    itemsOfFirstPage: itemsOfFirstPage || 20,
    itemsPerPage: itemsPerPage || 20,
    pageIndex,
    policyId,
  };

  const response = yield call(
    get,
    dispatch,
    'api/Billing/PaymentHistoryDetails',
    requestBody,
  );

  if (response.IsValid) {
    const paymentHistory = response.Data;

    yield put(paymentHistoryLoaded({
      paymentHistoryCurrentPage: pageIndex,
      paymentHistoryData: paymentHistory,
      paymentHistoryTotalItems: response.TotalItems,
      paymentHistoryTotalPages: response.TotalPages,
    }));

    if (successCallback) {
      yield call(successCallback);
    }
  } else {
    yield put(paymentHistoryLoadFailed(response.Message));

    if (errorCallback) {
      yield call(errorCallback);
    }
  }
}

export function* getPolicyInfo(dispatch, action) {
  const policyNumber = action.payload;
  const response = yield call(
    get,
    dispatch,
    `api/Policy/GetPolicyByPolicyNumber/${policyNumber}`,
  );

  if (response.IsValid) {
    yield put(policyDetailsLoaded(response.Data));
    const paymentPayload = {
      itemsOfFirstPage: 10,
      itemsPerPage: 10,
      pageIndex: 0,
      policyId: response.Data.PolicyId,
    };
    yield put(loadPaymentHistory(paymentPayload));
  } else {
    yield put(policyDetailsLoadFailed(response.Message));
  }
}

export function* getBillingInfo(dispatch, action) {
  const policyNumber = action.payload;
  const response = yield call(
    get,
    dispatch,
    `api/Billing/Info/${policyNumber}`,
  );

  if (response.IsValid) {
    yield put(billingInfoLoaded(response.Data));
  } else {
    yield put(billingInfoLoadFailed(response.Message));
  }
}

export function* cancelAddressUpdate(dispatch) {
  yield call(post, dispatch, '/api/User/CancelUpdateAddress');

  yield put(resetEndorsementData());
}

function* saveReimbursementMethod({ dispatch, reimbursementData, url }) {
  const useClaimsAPIV2T155969 = yield useClaimsAPIV2T155969ForSagas();
  const method = useClaimsAPIV2T155969 ? postApiV2 : post;

  const response = yield call(method, dispatch, url, reimbursementData);
  const store = yield select(({ billingPayments, policies }) => ({
    billingPayments, policies,
  }));

  const { billingPayments, policies } = store;

  const policy = policies
    .pets[billingPayments.selectedPet]
    .policies[billingPayments.selectedPolicy];

  const isValidResponse = useClaimsAPIV2T155969
    ? response.success : response.IsValid;

  if (isValidResponse) {
    yield put(reimbursementMethodUpdated());
    yield put(loadReimbursementMethod(policy.Number));
  } else {
    const errorMessage = response?.Message || 'Unexpected Error';
    yield put(reimbursementMethodUpdateFailed(errorMessage));
  }
}

export function* updateReimbursementMethod(dispatch, action) {
  const reimbursementData = action.payload;

  const useClaimsApiV1 = yield useClaimsApiV1ForSagas();
  const useClaimsAPIV2T155969 = yield useClaimsAPIV2T155969ForSagas();
  const url = useClaimsAPIV2T155969
    ? 'claims/reimbursement-methods'
    : 'claims/claims/UpdateReimbursementMethod';

  if (reimbursementData.Type === REIMBURSEMENT_METHODS.deposit) {
    const routingNumberUrl = useClaimsApiV1
      ? `claims/claims/ValidateACHRoute/${reimbursementData.RoutingNumber}`
      : `/api/Claim/ValidateACHRoute/${reimbursementData.RoutingNumber}`;

    const routingNumberResponse = yield call(
      get,
      dispatch,
      routingNumberUrl,
    );

    if (routingNumberResponse.IsValid) {
      yield call(saveReimbursementMethod, { dispatch, reimbursementData, url });
    } else {
      yield put(
        reimbursementMethodRoutingValidationFailed(
          routingNumberResponse.Message,
        ),
      );
    }
  } else {
    yield call(saveReimbursementMethod, { dispatch, reimbursementData, url });
  }
}

export function* updatePaymentMethod(dispatch, action) {
  const {
    paymentMethodData,
    policies,
  } = action.payload;
  yield put(paymentMethodUpdateAttempted());
  const isCreditCard = paymentMethodData.paymentCategory === 'CreditCard';

  const dataCreditCard = {
    cardExpirationMonth: paymentMethodData.cardExpirationMonth,
    cardExpirationYear: paymentMethodData.cardExpirationYear,
    cardholderName: paymentMethodData.customerName,
    cardType: paymentMethodData.cardType,
    lastFourDigits: paymentMethodData.lastFourDigits,
    tokenId: paymentMethodData.tokenId,
  };

  const dataAcc = {
    LastFourDigits: paymentMethodData.lastFourDigits,
    TokenId: paymentMethodData.tokenId,
  };

  const data = {
    oneIncCreditCardDTO: isCreditCard ? dataCreditCard : {},
    OneIncEftDTO: isCreditCard ? {} : dataAcc,
    paymentMethodEnum: isCreditCard ? 1 : 0,
  };

  const { policyNumber } = paymentMethodData;
  const url = `api/Payments/UpdatePaymentMethod/${policyNumber}`;

  const policyResponse = yield call(postV2, dispatch, url, data);
  const error = !policyResponse.IsValid;
  const messageResponse = policyResponse.Message;

  yield put(closeMultiPoliciesModal());
  yield put(paymentMethodPastDueModalVisibleChanged({ visible: false }));
  yield put(loadPoliciesPaymentNumber());
  yield put(loadPolicyDetails(policyNumber));
  yield call(getPolicySummary, dispatch);
  yield put(paymentMethodUpdated({
    error,
    messageResponse,
    policiesUpdated: policies.length,
  }));
}

export function* doPetLoad(dispatch) {
  yield put(petsLoadAttempt());
  const store = yield select(({ accountLogin }) => (accountLogin));

  if (!store.userInfo) {
    return;
  }

  const useCustomerPets = yield useCustomerApi150617V1ForSagas();
  const urlPref = useCustomerPets ? 'customer' : 'api';

  const { userInfo: { SocialInfo } } = store;

  const url = `${urlPref}/Pet?UnaId=${SocialInfo.Id}`;

  const petsResponse =
    yield call(get, dispatch, url);

  if (petsResponse.IsValid) {
    yield put(petsLoaded(petsResponse.Data));
  }

  yield put(petsLoadedFinished());
}

export function* doSocialProfileLoad(dispatch) {
  yield put(socialProfileLoadAttempt());
  const store = yield select(({ accountLogin }) => (accountLogin));

  if (!store.userInfo) {
    return;
  }

  const { userInfo: { SocialInfo } } = store;

  const url = `api/Social/Profile/${SocialInfo.Id}`;

  const profileResponse =
    yield call(get, dispatch, url);

  if (profileResponse.IsValid) {
    yield put(socialProfileLoaded(profileResponse.Data));
  }

  yield put(socialProfileLoadedFinished());
}

// isCover: true -> Cover, false -> ProfilePicture
function* changeProfilePicture(dispatch, { payload }) {
  const { imageBase64, isCover } = payload;
  const error = 'Unexpected error saving photo.';
  const postBody = {
    ContentType: 'image/jpeg',
    Extension: 'jpg',
    FileContent: imageBase64,
    ImageType: isCover
      ? IMAGE_UPLOAD_TYPE.background
      : IMAGE_UPLOAD_TYPE.customerProfile,
  };

  // uploads the image
  const uploadImageResponse = yield call(
    post,
    dispatch,
    'api/Social/Upload',
    postBody,
  );

  if (uploadImageResponse.success && uploadImageResponse.IsValid) {
    const { Data } = uploadImageResponse;
    // links the image to the user
    const linkImageToUserResponse = yield call(
      post,
      dispatch,
      'api/Social/Pictures/Submit',
      {
        CoverPictureUid: isCover ? Data : null,
        ProfilePictureUid: isCover ? null : Data,
      },
    );

    if (linkImageToUserResponse.success && linkImageToUserResponse.IsValid) {
      // waits for the cache refresh time.
      yield put(clearUserCache());
      yield delay(5000);
      yield put(changeProfilePictureSuccess());
    } else {
      yield put(changeProfilePictureFailed(error));
    }
  } else {
    yield put(changeProfilePictureFailed(error));
  }
}

function* removeCoverPicture(dispatch) {
  const response = yield call(
    del,
    dispatch,
    'api/Social/User/Cover/Picture',
  );

  if (response.IsValid) {
    yield put(coverPictureRemoved());
  } else {
    yield put(removeCoverPictureFailed());
  }
}

function* removeAvatarPicture(dispatch) {
  const response = yield call(
    del,
    dispatch,
    'api/Social/User/Profile/Picture',
  );

  if (response.IsValid) {
    yield put(clearUserCache());
    yield put(avatarPictureRemoved());
  } else {
    yield put(removeAvatarPictureFailed());
  }
}

export function* updateInsuranceInformation(dispatch, action) {
  const data = action.payload;
  const response = yield call(
    post,
    dispatch,
    'api/Account/ChangeAccountInformation',
    data,
  );
  if (response.IsValid) {
    yield put(updateInsuranceInformationSuccess());
  } else {
    yield put(updateInsuranceInformationFailed());
  }
}

function* getFeatureFlags() {
  try {
    const response = yield call(apiCallWithApiKey, 'customer/FeatureFlag');
    const petCloudFlags = Object.entries(response)
      .filter(([key]) => key.startsWith('PetCloud'))
      .reduce((p, [k, v]) => ({ ...p, [k]: v }), {});
    yield put(getFeatureFlagsLoaded(petCloudFlags));
  } catch (error) {
    yield put(getFeatureFlagsFailed(error));
  }
}

export function* settingsSaga(store) {
  yield all([
    takeLatest(CHANGE_PROFILE_PICTURE, changeProfilePicture, store.dispatch),
    takeLatest(REMOVE_COVER_PICTURE, removeCoverPicture, store.dispatch),
    takeLatest(REMOVE_AVATAR_PICTURE, removeAvatarPicture, store.dispatch),
    takeLatest(
      UPDATE_INSURANCE_INFORMATION,
      updateInsuranceInformation,
      store.dispatch,
    ),
    takeLatest(GET_FEATURE_FLAGS, getFeatureFlags, store.dispatch),
  ]);
}
