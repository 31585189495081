import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { tagPetFile, tagPetFileRestarted } from '../../../actions';
import { addOrRemoveItemFromList } from '../../../services/utils';
import { ActionModal } from '../../common/ActionModal';
import { useCustomerInboxDocsApiV2 } from '../../../hooks';
import { PetSelectorMultisSelection }
from '../foldersContent/fileListTable/PetSelectorMultisSelection';
import './TagPetModal.css';

const TagPetModal = ({
  currentFolderId = 0,
  fileToTagPet = 0,
  onCloseTagPetModal = () => { },
  onSuccessTagPetModal = () => { },
  petList = [],
  previousPetTagged = [],
  show,
}) => {
  const store = useSelector(({ inboxAndDocs }) => ({ inboxAndDocs }));
  const { inboxAndDocs: { fileTaggedSuccesfully, tagPetLoading } } = store;
  const dispatch = useDispatch();
  const { t } = useTranslation('inboxAndDocs');
  const [petListChecked, setPetListChecked] = useState([]);
  const petSelectedQuantity = petListChecked.length;
  const isCustomerApiV2 = useCustomerInboxDocsApiV2();

  useEffect(() => {
    setPetListChecked(previousPetTagged);
  }, [previousPetTagged]);

  const selectorTitle = petSelectedQuantity
    ? t('tagPetModal.selectorTitlePetSelected',
      { count: petSelectedQuantity })
    : t('tagPetModal.selectorTitleEmptySelection');

  function handlePetSelected(petSelected) {
    const newList = addOrRemoveItemFromList({
      itemList: petListChecked,
      newItem: petSelected,
    });

    setPetListChecked(newList);
  }

  function handleAssignTagPet() {
    if (petSelectedQuantity) {
      let petsIds = [];

      if (isCustomerApiV2) {
        const prevIds = previousPetTagged.map((prevPet) => prevPet.PetId);
        const checkedPetsIds = petListChecked.map((pet) => pet.PetId);

        petsIds = prevIds.filter((petId) => !checkedPetsIds.includes(petId))
          .concat(checkedPetsIds.filter((petId) => !prevIds.includes(petId)));
      } else {
        petsIds = petListChecked.map((pet) => pet.PetId);
      }

      dispatch(tagPetFile({
        currentFolderId,
        fileId: fileToTagPet,
        petIdList: petsIds,
      }));
    }
  }

  function handleAssignTagPetSuccess() {
    dispatch(tagPetFileRestarted());
    setPetListChecked([]);
    onCloseTagPetModal();
    onSuccessTagPetModal();
  }

  function handleAssignTagPetClose() {
    dispatch(tagPetFileRestarted());
    onCloseTagPetModal();
    setPetListChecked([]);
  }

  return (
    <ActionModal
      cancelButtonText={t('tagPetModal.cancelButton')}
      confirmButtonText={t('tagPetModal.confirmButton')}
      loading={tagPetLoading}
      onCancel={handleAssignTagPetClose}
      onClose={handleAssignTagPetClose}
      onConfirm={handleAssignTagPet}
      onSuccess={handleAssignTagPetSuccess}
      saveButtonDisabled={!petSelectedQuantity}
      show={show}
      success={fileTaggedSuccesfully}
      title={t('tagPetModal.title')}
    >
      <PetSelectorMultisSelection
        classContainer="Tag-pet-modal-selector-container"
        isModal
        itemList={petList}
        itemListChecked={petListChecked}
        onItemClick={handlePetSelected}
        title={selectorTitle}
      />

      <span className="Tag-pet-modal-error">
        {store.inboxAndDocs.error}
      </span>
    </ActionModal>
  );
};

export { TagPetModal };
