import React, { useMemo, useState } from 'react';
import infoIcon from '../../assets/billingPayments/info.png';
import infoIconDark from '../../assets/info-icon.svg';
import TouchableDiv from './TouchableDiv';
import './IconTooltip.css';

export const TOOL_TIP_POSITION = {
  center: {
    containerArrowPosition: 'Icon-tooltip-arrow-container-center',
    left: -115,
  },
  end: {
    containerArrowPosition: 'Icon-tooltip-arrow-container-end',
    left: -204,
  },
  start: {
    containerArrowPosition: 'Icon-tooltip-arrow-container-start',
    left: -24,
  },
};

const LEFT = 'left';
const RIGHT = 'right';
const DOWN = 'down';
const TOP = 'top';
const DOWN_LEFT = 'down-left';
const TOP_LEFT = 'top-left';

const IconTooltip = ({
  activeOnTouch = false,
  arrowClass = '',
  buttonClass = '',
  containerClass = '',
  description = '',
  direction = TOP,
  height = 0,
  icon = null,
  iconClassName = '',
  mainClass = '',
  teal = false,
  text = '',
  textClassName = '',
  width = 234,
  xPosition = 0,
  yPosition = 0,
}) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const getDescriptionPosition = () => {
    let top = yPosition;
    let left = xPosition;
    let containerWidth = width || 234;

    if (!xPosition && !yPosition) {
      switch (direction) {
        case DOWN:
          top = 44;
          left = -(containerWidth / 2);
          break;
        case RIGHT:
          left = 28;
          top = -(containerWidth * 0.805);
          break;
        case LEFT:
          left = -(containerWidth + 7);
          top = -(height / 2);
          break;
        case DOWN_LEFT:
          top = 44;
          left = -(containerWidth - (containerWidth * 0.09));
          containerWidth = width || 274;
          break;
        case TOP_LEFT:
          top = -(height + 5);
          left = -((containerWidth / 2) - 90);
          break;
        default:
          top = -(height + 5);
          left = -((containerWidth / 2) - 10);
      }
    }
    return {
      height: height || 'auto',
      left: `${left}px`,
      top: `${top}px`,
      width: containerWidth,
    };
  };

  const getArrowContainerPosition = () => {
    switch (direction) {
      case DOWN:
      case DOWN_LEFT:
        return 'Tooltip-arrow-container-down';
      case RIGHT:
        return 'Tooltip-arrow-container-right';
      case LEFT:
        return 'Tooltip-arrow-container-left';
      case TOP_LEFT:
        return 'Tooltip-arrow-container-top-left';
      default:
        return 'Tooltip-arrow-container-top';
    }
  };

  const getArrowStyle = () => {
    switch (direction) {
      case TOP:
      case TOP_LEFT:
        return 'Tooltip-arrow-down';
      case DOWN:
      case DOWN_LEFT:
        return 'Tooltip-arrow-top';
      case RIGHT: return 'Tooltip-arrow-left';
      case LEFT: return 'Tooltip-arrow-right';
      default: return '';
    }
  };

  function handleHoverIn() {
    if (!activeOnTouch) {
      setTooltipVisible(true);
    }
  }

  function handleClose() {
    setTooltipVisible(false);
  }

  function handleHoverOut() {
    if (!activeOnTouch) {
      handleClose();
    }
  }

  function handleClick() {
    if (activeOnTouch) {
      setTooltipVisible(true);
    }
  }

  function renderTooltip() {
    if (!tooltipVisible) {
      return null;
    }

    return (
      <>
        {activeOnTouch && (
          <TouchableDiv
            className="Tooltip-backdrop"
            id="modalOuterTooltip"
            onClick={handleClose}
            onKeyPress={handleClose}
          />
        )}

        <div className={`Tooltip-main-container ${mainClass}`} data-teal={teal}>
          <div
            className={`Tooltip-description-container ${containerClass}`}
            style={getDescriptionPosition()}
          >
            <span className={`Tooltip-description ${textClassName}`}>
              {description}
            </span>
          </div>

          <div
            className={`Tooltip-arrow-container ${getArrowContainerPosition()}`}
          >
            <div className={`${arrowClass} ${getArrowStyle()}`} />
          </div>
        </div>
      </>
    );
  }

  const iconSrc = useMemo(() => (
    icon || (!teal && infoIcon) || infoIconDark),
  [icon]);

  return (
    <div className="Tooltip-icon-container">
      <button
        className={`Like-span ${buttonClass}`}
        onClick={handleClick}
        type="button"
      >
        {text || (
          <img
            alt=""
            className={`Tooltip-icon-default ${iconClassName}`}
            onMouseEnter={handleHoverIn}
            onMouseLeave={handleHoverOut}
            src={iconSrc}
          />
        )}
      </button>

      {renderTooltip()}
    </div>
  );
};

export default IconTooltip;
