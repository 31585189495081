import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual } from 'react-redux';
import moment from 'moment';
import { useTrackOnMount } from '../../../hooks';
import {
  currencyFormat,
  getBrandNameByChannelId,
} from '../../../services/utils';
import { EV6 } from '../../../services/mixpanel';
import { ClaimInfoItem } from './ClaimInfoItem';
import { MARKETING_CHANNEL_ID } from '../../../theme/theme.constants';
import { getThemeLogoById } from '../../../theme/theme.utils';
import { CLAIM_STATUS_BY_TEXT, PET_TYPE_MULTIBRAND } from '../../../constants';
import dogDefaultImage from
'../../../assets/billingPayments/dog-placeholder.png';
import catDefaultImage from
'../../../assets/billingPayments/cat-placeholder.png';

const CLAIM_COMPLETED = [
  CLAIM_STATUS_BY_TEXT.done,
  CLAIM_STATUS_BY_TEXT.completed,
];

const OrcaDetail = ({
  logo,
  completed = false,
  claim,
  marketingChannelId = MARKETING_CHANNEL_ID.akc,
  getImage = () => { },
  pets = [],
}) => {
  const { t } = useTranslation('claims');

  return (
    <section className="Claim-details-container">
      <div className="Claim-details-header">
        <div className="Claim-details-header-content">
          <h3 className="Claim-details-header-title">
            {t('claimSummary')}
          </h3>

          <div className="Claim-details-info-container">
            <ClaimInfoItem
              bold={completed}
              title={t('titleStatus')}
              value={claim.ClaimStatus}
            />

            <ClaimInfoItem
              title={t('claimNumber')}
              value={claim.ClaimNumber}
            />

            <ClaimInfoItem
              title={t('dateSubmitted')}
              value={claim.DateSubmitted
                ? `${moment(claim.DateSubmitted,
                  'YYYY-MM-DDTHH:mm:ss.SSSZ')
                  .format('MMMM D, YYYY')}`
                : ''}
            />

            <ClaimInfoItem
              title={t('titleClamedAmount')}
              value={`${currencyFormat({ value: claim.ClaimedAmount })}`}
            />

            {completed ? (
              <ClaimInfoItem
                title={t('titlePaid')}
                value={`${currencyFormat({ value: claim.AmountPaid })}`}
              />
            ) : null}
          </div>
        </div>

        <div className="Claim-details-header-company-logo">
          <img
            alt=""
            className={`Claim-details-header-company-${marketingChannelId}`}
            src={logo}
          />
        </div>
      </div>

      <div className="Claim-diagnosis-container">
        <header className="Claim-details-header">
          <h3 className="Claim-details-header-title">
            {t('petsOnClaim')}
          </h3>
        </header>

        {pets.map((pet) => (
          <div key={pet.PetId} className="Claim-diagnosis-content">
            <div className="Claim-details-pet-info">
              <img alt="pet-pic" src={getImage(pet)} />

              <div className="Claim-details-pet">
                <span className="Claim-details-pet-name">{pet.PetName}</span>

                <span className="Claim-details-pet-breedname">
                  {pet.BreedName}
                </span>
              </div>
            </div>

            <div className="Claim-diagnosis-dates">
              <ClaimInfoItem
                direction="column"
                title={t('injuryDate')}
                value={claim.InjuryDate
                  ? `${moment(claim.InjuryDate,
                    'YYYY-MM-DDTHH:mm:ss.SSSZ')
                    .format('MMMM D, YYYY')}`
                  : ''}
              />

              <ClaimInfoItem
                direction="column"
                title={t('treatmentDate')}
                value={pet?.TreatmentStartDate
                  ? `${moment(pet.TreatmentStartDate,
                    'YYYY-MM-DDTHH:mm:ss.SSSZ')
                    .format('MMMM D, YYYY')}`
                  : ''}
              />
            </div>

            <div className="Claim-description-container">
              <h6 className="Claim-description-title">
                {t('titleDescription')}
              </h6>

              <div className="Claim-description-body">
                {pet.Diagnosis}
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

const FigoDetail = ({
  claim,
  getImage = () => { },
  logo,
  pet,
  completed = false,
}) => {
  const { t } = useTranslation('claims');

  return (
    <section className="Claim-details-container">
      <div className="Claim-details-header">
        <div className="Claim-details-header-content">
          <h3 className="Claim-details-header-title">
            {t('claimSummary')}
          </h3>

          <div className="Claim-details-pet-info">
            <img alt={pet?.PetName} src={getImage(pet)} />

            <div className="Claim-details-pet">
              <span className="Claim-details-pet-name">
                {pet?.PetName}
              </span>

              <span className="Claim-details-pet-breedname">
                {pet?.BreedName}
              </span>
            </div>
          </div>
        </div>

        <div className="Claim-details-header-company-logo">
          <img
            alt=""
            className="Claim-details-header-company-1"
            src={logo}
          />
        </div>
      </div>

      <div className="Claim-info-wrapper">
        <div className="Claim-info-col">
          <ClaimInfoItem
            bold={completed}
            title={t('titleStatus')}
            value={claim.ClaimStatus}
          />

          <ClaimInfoItem
            title={t('dateSubmitted')}
            value={claim.DateSubmitted
              ? `${moment(claim.DateSubmitted,
                'YYYY-MM-DDTHH:mm:ss.SSSZ')
                .format('MMMM D, YYYY')}`
              : ''}
          />

          <ClaimInfoItem
            title={t('claimNumber')}
            value={claim.ClaimNumber}
          />
        </div>

        <div className="Claim-info-col">
          <ClaimInfoItem
            title={t('titleTypeClaim')}
            value={claim.ClaimTypeDescription}
          />

          <ClaimInfoItem
            title={t('titleClamedAmount')}
            value={`${currencyFormat({ value: claim.ClaimedAmount })}`}
          />

          {completed ? (
            <ClaimInfoItem
              title={t('titlePaid')}
              value={`${currencyFormat({ value: claim.AmountPaid })}`}
            />
          ) : null}

          <ClaimInfoItem
            title={t('reimbursementText')}
            value={claim.Reimbursement}
          />
        </div>
      </div>

      <div className="Claim-description-container">
        <h4 className="Claim-description-title">
          {t('titleDescription')}
        </h4>

        <div className="Claim-description-body">
          {pet?.Diagnosis}
        </div>
      </div>
    </section>
  );
};

const DetailsList = () => {
  const store = useSelector(({ claims }) => ({ claims }), shallowEqual);

  const {
    claimById: {
      Diagnoses,
      MarketingChannelId,
      PolicyManagementSystem,
      ...claim
    },
    isLoadingDetails,
  } = store.claims;

  const viewClaimsDetailsEvent = useMemo(() => {
    if (!isLoadingDetails && claim.ClaimNumber) {
      return EV6({
        claimNumber: claim.ClaimNumber,
        claimStatusLabel: claim,
        paidAmount: claim.AmountPaid,
        petName: Diagnoses?.[0]?.PetName,
        pms: PolicyManagementSystem,
        policyBrand: getBrandNameByChannelId(MarketingChannelId),
        policyNumber: claim.PolicyNumber,
      });
    }
    return null;
  }, [
    claim.ClaimNumber,
    claim.AmountPaid,
    claim.PolicyNumber,
    isLoadingDetails,
  ]);

  useTrackOnMount(viewClaimsDetailsEvent, [viewClaimsDetailsEvent]);

  const themeLogos = useMemo(
    () => getThemeLogoById(MarketingChannelId),
    [MarketingChannelId],
  );

  const getImage = (pet) => {
    if (pet?.ProfileImage) {
      return pet.ProfileImage;
    }
    return pet?.PetType === PET_TYPE_MULTIBRAND.CAT
      ? catDefaultImage : dogDefaultImage;
  };

  if (!MarketingChannelId || !themeLogos?.dark) {
    return null;
  }

  return MarketingChannelId === MARKETING_CHANNEL_ID.figo ? (
    <FigoDetail
      claim={claim}
      completed={CLAIM_COMPLETED.includes(claim?.ClaimStatus)}
      getImage={getImage}
      logo={themeLogos.dark}
      pet={Diagnoses[0]}
    />
  ) : (
    <OrcaDetail
      claim={claim}
      completed={CLAIM_COMPLETED.includes(claim?.ClaimStatus)}
      getImage={getImage}
      logo={themeLogos.dark}
      marketingChannelId={MarketingChannelId}
      pets={Diagnoses}
    />
  );
};

export { DetailsList };
