import React from 'react';
import Lottie from 'react-lottie';
import Button from 'react-bootstrap/Button';
import loadingAnimation from '../../assets/loading-spinner.json';
import loadingBlue from '../../assets/lottieFiles/loading-blue.json';
import successAnimation from '../../assets/loading-check.json';
import './LoadingButton.css';

const loadingAnimationOptions = (blueSpinner) => ({
  animationData: blueSpinner ? loadingBlue : loadingAnimation,
  autoplay: true,
  loop: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
});

const successAnimationOptions = {
  animationData: successAnimation,
  autoplay: true,
  loop: false,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const LoadingButton = ({
  className = '',
  disabled = false,
  height = 34,
  id,
  loading = false,
  blueSpinner = false,
  onClick = () => { },
  onSuccessAnimationEnd = () => { },
  size = '',
  submit = true,
  success = false,
  text = '',
  textStyle = '',
  variant = 'info',
  width = 34,
  ...rest
}) => {
  function handleAnimationEnd() {
    if (onSuccessAnimationEnd) {
      onSuccessAnimationEnd();
    }
  }

  function renderButtonContent() {
    if (loading) {
      return (
        <Lottie
          height={height}
          options={loadingAnimationOptions(blueSpinner)}
          width={width}
        />
      );
    }

    if (!loading && success) {
      return (
        <Lottie
          eventListeners={[{
            callback: handleAnimationEnd,
            eventName: 'complete',
          }]}
          height={height}
          options={successAnimationOptions}
          width={width}
        />
      );
    }

    return (
      <span className={textStyle || 'Loading-button-text'}>
        {text}
      </span>
    );
  }

  function handleOnClick(event) {
    if (onClick && !loading && !success) {
      onClick(event);
    }
  }

  return (
    <Button
      className={`${className || 'Loading-button-container'}`}
      disabled={disabled}
      id={id}
      onClick={handleOnClick}
      size={size}
      type={submit ? 'submit' : 'button'}
      variant={variant}
      {...rest}
    >
      {renderButtonContent()}
    </Button>
  );
};

export default LoadingButton;
