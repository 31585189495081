import React from 'react';
import { useTranslation } from 'react-i18next';
import { PET_TYPE_ID } from '../../constants';
import dogPlaceHolder from '../../assets/myPets/dog-placeholder-oval.png';
import catPlaceHolder from '../../assets/myPets/cat-placeholder-oval.png';
import { UploadPhotoButton } from '../common/UploadPhotoButton';
import { getNameInitials } from '../../services/utils';
import './PetItemCard.css';

const PetItemCardImage = ({
  containerClassName,
  hideAddPhoto,
  image,
  imageClassName = 'Pet-item-card-image-pet-list',
  initialsClassName = '',
  isProfile,
  onAddPhotoClick = () => { },
  onClick = () => { },
  petName,
  petType,
  touchable = false,
}) => {
  const { t } = useTranslation('myPets');
  const petTypePlaceholder = petType === PET_TYPE_ID.CAT
    ? catPlaceHolder
    : dogPlaceHolder;

  const petInitials = getNameInitials(petName);

  return (
    <>
      <div className={containerClassName
        || 'Pet-item-card-image-container'}
      >
        {touchable ? (
          <button
            className="Like-span Pet-item-card-image-action"
            onClick={onClick}
            type="button"
          >
            <img
              alt="Pet item"
              className={`Pet-item-card-image ${imageClassName}`}
              src={image || petTypePlaceholder}
            />
          </button>
        ) : (
          <img
            alt="Pet item"
            className={`Pet-item-card-image ${imageClassName}`}
            src={image || petTypePlaceholder}
          />
        )}

        {!image && (
          <>
            <h1 className={`${initialsClassName} No-clicked`}>
              {petInitials}
            </h1>

            <button
              className={`Pet-item-card-image-button${hideAddPhoto
                ? ' Gone' : ''}`}
              onClick={onAddPhotoClick}
              type="button"
            >
              {t('PetCard.AddPhoto')}
            </button>
          </>
        )}

        {isProfile && (
          <UploadPhotoButton
            iconOnly
            isProfilePicture
            onClick={onAddPhotoClick}
          />
        )}
      </div>
    </>
  );
};

export { PetItemCardImage };
