/* eslint-disable max-len */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

import { EditReimbursementModalButtons } from './EditReimbursementModalButtons';

import { useDirectDepositContent } from './hooks/useDirectDepositContent';

import '../css/CheckContent.css';

const CheckContent = ({ onCancel = () => { } }) => {
  const { t } = useTranslation('myAccount');
  const store = useSelector(({ personalInformation }) => ({
    personalInformation,
  }), shallowEqual);
  const { personalInformation: { userInfo } } = store;
  const {
    handleEditReimbursementAccept,
    handleEditReimbursementCancel,
    handleEditReimbursementSuccess,
    reimbursementMethodUpdateSuccess,
    updatingReimbursementMethod,
  } = useDirectDepositContent({ onCancel });

  const addressValue = useMemo(() => `${userInfo.Address.Line1} ${userInfo.Address.Line2 ? `,${userInfo.Address.Line2}` : ''}
    ${userInfo.Address.City}, ${userInfo.Address.StateId} ${userInfo.Address.ZipCode}`,
  [userInfo.Address]);

  return (
    <div>
      <h6 className="Check-content-title">
        {t('reimbursementSection.editModal.addressOnFile')}
      </h6>

      <p className="Check-content-address">{addressValue}</p>

      <p className="Check-content-footer-text">
        {t('reimbursementSection.editModal.appliedToAllPolicies')}
      </p>

      <EditReimbursementModalButtons
        handleEditReimbursementAccept={handleEditReimbursementAccept}
        handleEditReimbursementCancel={handleEditReimbursementCancel}
        handleEditReimbursementSuccess={handleEditReimbursementSuccess}
        reimbursementMethodUpdateSuccess={reimbursementMethodUpdateSuccess}
        updatingReimbursementMethod={updatingReimbursementMethod}
      />
    </div>
  );
};

export { CheckContent };
