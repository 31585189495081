import { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { loadCustomerInformation } from '../../../../actions';

const useInsuranceAccountScreen = (companyId) => {
  const store = useSelector(({
    session,
  }) => ({ session }), shallowEqual);
  const { session: { customerInformation, loading } } = store;
  const dispatch = useDispatch();

  useEffect(() => {
    if (companyId) {
      dispatch(loadCustomerInformation({ marketingChannelId: companyId }));
    }
  }, [companyId]);

  const userInformation = useMemo(() => {
    if (!customerInformation) {
      return {
        address: '',
        email: '',
        nameOnAccount: '',
        phoneNumber: '',
      };
    }

    const {
      addressPart1,
      addressPart2,
      fullName,
      email,
      phoneNumber1,
      phoneNumber2,
    } = customerInformation;

    const fullAddress = addressPart2
      ? `${addressPart1}, ${addressPart2}` : addressPart1;

    return {
      address: `${fullAddress}`,
      email,
      nameOnAccount: fullName,
      phoneNumber: phoneNumber1 || phoneNumber2,
    };
  }, [customerInformation]);

  return {
    ...userInformation,
    loading,
  };
};

export { useInsuranceAccountScreen };
