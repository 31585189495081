import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import ScrollableDiv from '../../../common/ScrollableDiv';
import Checkbox from '../../../common/Checkbox';
import './RelatedPetsSelector.css';

const RelatedPetsSelector = ({
  petList = [],
  petsIdsSelected = [],
  onPetSelectedChange = () => { },
}) => {
  const { t } = useTranslation('inboxAndDocs');

  const handlePetSelectedChanged = useCallback((petId) => () => {
    onPetSelectedChange(petId);
  }, [onPetSelectedChange]);

  return (
    <div
      className="Related-pets-selector-container"
    >
      <h3 className="Upload-files-title">
        {t('uploadModal.assignToPets')}
      </h3>

      <ScrollableDiv className="Related-pets-selector-scrollable">
        <ul className="Related-pets-selector-list">
          {petList.map((pet) => (
            <li
              key={String(pet.Id)}
              className={petList.length < 2
                ? 'Related-pets-selector-list-full' : ''}
            >
              <Checkbox
                checked={petsIdsSelected.includes(pet.Id)}
                className="Related-pets-selector-list-item"
                isRounded={false}
                label={pet.Name}
                onChange={handlePetSelectedChanged(pet.Id)}
              />
            </li>
          ))}
        </ul>
      </ScrollableDiv>
    </div>
  );
};

export { RelatedPetsSelector };
