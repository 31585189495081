import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  PetCloudInput,
  RadioInputWithLabel,
} from '../../../common/petCloud';
import { EDIT_ROUTING_NUMBER_LENGTH } from '../../../../constants';
import { useDirectDepositContent } from './hooks/useDirectDepositContent';

import { EditReimbursementModalButtons } from './EditReimbursementModalButtons';

import '../css/DirectDepositContent.css';

const ACCOUNT_TYPES = { checking: 1, savings: 2 };

const DirectDepositContent = ({
  onCancel = () => { },
}) => {
  const { t } = useTranslation('myAccount');
  const {
    accountNumberError,
    bankAccountRef,
    confirmAccountNumberError,
    confirmAccountRef,
    handleAccountNumberChange,
    handleAccountTypeChange,
    handleArrowKeyPressed,
    handleConfirmAccountNumberChange,
    handleEditReimbursementAccept,
    handleEditReimbursementCancel,
    handleEditReimbursementSuccess,
    handleRoutingNumberChange,
    isBankFormInvalid,
    reimbursementData,
    reimbursementMethodUpdateSuccess,
    routingNumberError,
    routingNumberRef,
    updatingReimbursementMethod,
  } = useDirectDepositContent({ onCancel });

  return (
    <form
      className="Direct-deposit-content-container"
      onSubmit={handleEditReimbursementAccept}
    >
      <h6>{t('reimbursementSection.editModal.accountType')}</h6>

      <div className="Direct-deposit-radio-buttons-container">
        <RadioInputWithLabel
          id={`radio-${t('reimbursementSection.editModal.checking')}`}
          isChecked={reimbursementData.AccountType === ACCOUNT_TYPES.checking}
          onClick={handleAccountTypeChange(ACCOUNT_TYPES.checking)}
          title={t('reimbursementSection.editModal.checking')}
        />

        <RadioInputWithLabel
          id={`radio-${t('reimbursementSection.editModal.savings')}`}
          isChecked={reimbursementData.AccountType === ACCOUNT_TYPES.savings}
          onClick={handleAccountTypeChange(ACCOUNT_TYPES.savings)}
          title={t('reimbursementSection.editModal.savings')}
        />
      </div>

      <div className="Direct-deposit-input-margin">
        <PetCloudInput
          ariaProps={{
            'aria-label': t('reimbursementSection.editModal.routingNumber'),
          }}
          error={routingNumberError}
          id="bankRoutingNumber"
          inputRef={routingNumberRef}
          maxLength={EDIT_ROUTING_NUMBER_LENGTH}
          onBlur={handleRoutingNumberChange}
          onChange={handleRoutingNumberChange}
          onKeyDown={handleArrowKeyPressed()}
          pattern="[0-9]*"
          placeholder={t('reimbursementSection.editModal.routingNumber')}
          title={t('reimbursementSection.editModal.routingNumber')}
          type="number"
          value={reimbursementData.RoutingNumber}
        />
      </div>

      <div className="Direct-deposit-input-margin">
        <PetCloudInput
          ariaProps={{
            'aria-label': t('reimbursementSection.editModal.accountNumber'),
          }}
          error={accountNumberError}
          id="bankAccountNumber"
          inputRef={bankAccountRef}
          onBlur={handleAccountNumberChange}
          onChange={handleAccountNumberChange}
          onKeyDown={handleArrowKeyPressed()}
          pattern="[0-9]*"
          placeholder={t('reimbursementSection.editModal.accountNumber')}
          title={t('reimbursementSection.editModal.accountNumber')}
          type="number"
          value={reimbursementData.AccountNumber}
        />
      </div>

      <div className="Direct-deposit-input-margin-last">
        <PetCloudInput
          ariaProps={{
            'aria-label':
              t('reimbursementSection.editModal.confirmAccountNumber'),
          }}
          error={confirmAccountNumberError}
          id="confirmBankAccountNumber"
          inputRef={confirmAccountRef}
          onBlur={handleConfirmAccountNumberChange}
          onChange={handleConfirmAccountNumberChange}
          onKeyDown={handleArrowKeyPressed()}
          pattern="[0-9]*"
          placeholder={t('reimbursementSection.editModal.confirmAccountNumber')}
          title={t('reimbursementSection.editModal.confirmAccountNumber')}
          type="number"
          value={reimbursementData.ConfirmAccountNumber}
        />
      </div>

      <EditReimbursementModalButtons
        handleEditReimbursementAccept={handleEditReimbursementAccept}
        handleEditReimbursementCancel={handleEditReimbursementCancel}
        handleEditReimbursementSuccess={handleEditReimbursementSuccess}
        isBankFormInvalid={isBankFormInvalid}
        reimbursementMethodUpdateSuccess={reimbursementMethodUpdateSuccess}
        updatingReimbursementMethod={updatingReimbursementMethod}
      />
    </form>
  );
};

export { DirectDepositContent };
