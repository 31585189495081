import React, { useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import checkIcon
from '../../../assets/myAccount/reimbursement/check-payment-logo.svg';
import savingBankLogo
from '../../../assets/myAccount/reimbursement/saving-bank-logo.svg';
import addIcon from '../../../assets/myPets/add-icon-blue.svg';
import editIcon from '../../../assets/myAccount/reimbursement/edit-pencil.svg';

import {
  closeEditReimbursementMethodModal,
  openEditReimbursementMethodModal,
} from '../../../actions';

import { ReimbursementEditMenu } from './editOptions/ReimbursementEditMenu';
import { ReimbursementEditModal } from './editModal/ReimbursementEditModal';

import { REIMBURSEMENT_METHODS } from '../../../constants';

import './css/ReimbursementMethodSection.css';

const ReimbursementMethodSection = ({ reimbursementOption }) => {
  const { t } = useTranslation('myAccount');
  const dispatch = useDispatch();
  const store = useSelector(({ billingPayments, policies }) => ({
    billingPayments,
    policies,
  }), shallowEqual);
  const {
    billingPayments: {
      editReimbursementMethodModalVisible,
      loadingReimbursementMethod,
    },
  } = store;

  const isCheck = reimbursementOption.PreferredReimbursementMethod
    === REIMBURSEMENT_METHODS.check;

  function handleEditReimbursementCancel() {
    dispatch(closeEditReimbursementMethodModal());
  }

  function handleEditReimbursementClick() {
    dispatch(openEditReimbursementMethodModal(2));
  }

  const menuOptions = useMemo(() => {
    const permissions = [];

    permissions.push({
      icon: editIcon,
      id: 0,
      isReadOnlyVisible: false,
      onClick: handleEditReimbursementClick,
      text: t('reimbursementSection.editOptions.editAccountDetails'),
    });

    return permissions;
  }, []);

  function renderReimbursementCard() {
    if (reimbursementOption.PreferredReimbursementMethod
      === REIMBURSEMENT_METHODS.none || loadingReimbursementMethod) {
      return null;
    }

    return (
      <div className="Reimbursement-method-section-card-container">
        <div className="Reimbursement-method-card-left-content">
          <img alt="" src={isCheck ? checkIcon : savingBankLogo} />

          <p>
            {isCheck ? t('reimbursementSection.checkMethod', {
              addressLine1: reimbursementOption.Details.AddressLine1,
              addressLine2: reimbursementOption.Details.AddressLine2,
              stateId: reimbursementOption.Details.State,
              zipCode: reimbursementOption.Details.Zipcode,
            }) : t('reimbursementSection.bankAccount', {
              accountNumberLastFour:
                reimbursementOption.AccountNumberLastFour,
            })}
          </p>
        </div>

        <ReimbursementEditMenu options={menuOptions} />
      </div>
    );
  }

  return (
    <div className="Reimbursement-method-section-container">
      <h1>{t('reimbursementSection.reimbursementMethod')}</h1>

      <p>{t('reimbursementSection.reimbursmentDescription')}</p>

      {renderReimbursementCard()}

      {reimbursementOption.PreferredReimbursementMethod
        === REIMBURSEMENT_METHODS.none && (
        <button
          className="Reimbursement-method-add-button"
          onClick={handleEditReimbursementClick}
          type="button"
        >
          <img alt="" src={addIcon} />

          <span>{t('reimbursementSection.addReimbursementMethod')}</span>
        </button>
      )}

      <ReimbursementEditModal
        isVisible={editReimbursementMethodModalVisible}
        onCancel={handleEditReimbursementCancel}
      />
    </div>
  );
};

export { ReimbursementMethodSection };
