import React from 'react';

import { PetCard } from './PetCard';

import './MultiplePetsSelector.css';

const MultiplePetsSelector = ({
  pets = [],
  subTitle = '',
  handlePetSelect,
  title = '',
}) => {
  function renderContent() {
    return pets.map((pet) => (
      <PetCard
        key={`${pet.petId}`}
        age={pet.petAge}
        breed={pet.petBreed}
        gender={pet.petGender}
        name={pet.petName}
        onClick={handlePetSelect(pet.petId)}
        picture={pet.petProfileImage}
        type={pet.petType}
        weight={pet.petWeight}
      />
    ));
  }

  return (
    <div className="Multiple-pet-selector-main-container">
      <div className="Multiple-pet-selector-container">
        <h1>{title}</h1>

        <span>{subTitle}</span>

        <div className="Multiple-pet-selector-grid-container">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export { MultiplePetsSelector };
