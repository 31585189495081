import React, { useEffect, useRef } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { getSharePostData } from '../../actions';
import Shimmer from '../common/Shimmer';
import figoLogo from '../../assets/sharePost/figo-logo.png';
import appleAppStoreLogo from '../../assets/sharePost/app-store-apple.png';
import googlePlayLogo from '../../assets/sharePost/google-play.png';
import defaultProfilePic from '../../assets/sharePost/default-pic.png';
import { APP_STORE_LINK, GOOGLE_PLAY_LINK } from '../../constants';
import './SharePost.css';

const OPEN_ANCHOR = /<a[\s]+([^>]+)>/g;
const CLOSE_ANCHOR = /<\/a>/g;

const SharePost = () => {
  const match = useRouteMatch();
  const { postId = '' } = match.params;
  const store = useSelector(({ common }) => ({ common }), shallowEqual);
  const {
    isLoading,
    postAuthorName,
    postAuthorPicPath,
    postContents,
    postDate,
    postPhotoPath,
  } = store.common.sharePost;

  const dispatch = useDispatch();

  const postContentRef = useRef(null);

  useEffect(() => {
    if (postContentRef.current) {
      postContentRef.current.innerHTML = postContents
        ?.replace(OPEN_ANCHOR, '<strong>')
        .replace(CLOSE_ANCHOR, '</strong>');
    }
  }, [postContentRef.current, postContents]);

  useEffect(() => {
    dispatch(getSharePostData({ postId }));
  }, [dispatch, postId]);

  return (
    <div>
      <nav className="Share-post-navbar">
        <div className="Share-post-nav-container">
          <a href="https://www.mypetcloud.com/">
            <img alt="figo" className="Share-post-nav-header" src={figoLogo} />
          </a>

          <div className="Share-post-store-icons">
            <a href={APP_STORE_LINK}>
              <img
                alt="app store"
                className="Share-post-nav-appstore"
                src={appleAppStoreLogo}
              />
            </a>

            <a href={GOOGLE_PLAY_LINK}>
              <img
                alt="google play"
                className="Share-post-navbar-googleplay"
                src={googlePlayLogo}
              />
            </a>
          </div>
        </div>
      </nav>

      {isLoading ? (
        <Shimmer className="Share-post-shimmer-container" />
      ) : (
        <div className="Share-post-container">
          <article className="Share-post-article">
            <div className="Share-post-article-header">
              <img
                alt=""
                className="Share-post-profile-pic"
                src={postAuthorPicPath || defaultProfilePic}
              />

              <div className="Share-post-article-profile">
                <h3 className="Share-post-profile-name">{postAuthorName}</h3>

                <h4 className="Share-post-profile-date">{postDate}</h4>
              </div>
            </div>

            <div className="Share-post-article-content">
              <img
                alt=""
                className="Share-post-article-pic"
                src={postPhotoPath}
              />

              <p ref={postContentRef} className="Share-post-article-text" />
            </div>
          </article>
        </div>
      )}
    </div>
  );
};

export { SharePost };
