import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import alertIcon from '../../../../assets/changeCoverage/alert-icon.svg';

import '../css/ChangeCoveragePreviewSubmit.css';

const PreviewSubmitNote = ({ onContactUsClick = () => { } }) => {
  const { t } = useTranslation('changeCoverage');

  const ContactUsButton = () => (
    <button
      className="Preview-plan-info-note-text-contact-us"
      onClick={onContactUsClick}
      type="button"
    >
      <span>{t('reviewChangesModal.contactUs')}</span>
    </button>
  );

  return (
    <div className="Preview-plan-info-note" role="alert">
      <div className="Preview-plan-info-note-top-row">
        <img alt="" src={alertIcon} />

        <span>{t('reviewChangesModal.annualBenefit')}</span>
      </div>

      <div className="Preview-plan-info-note-text">
        <Trans
          components={{ button: <ContactUsButton /> }}
          i18nKey="reviewChangesModal.disclaimerNote"
          t={t}
        />
      </div>

    </div>
  );
};

export { PreviewSubmitNote };
