import React from 'react';

import { doNothing } from '../../../../services/utils';

import '../css/PaymentHistoryList.css';

const PaymentHistoryPageButton = ({
  handlePageButtonClick = () => { },
  index = '',
  isActive = false,
  pageNumber = '',
}) => {
  function handleOnClick() {
    if (isActive) {
      doNothing();
    }

    handlePageButtonClick({ pageIndex: index });
  }

  return (
    <button
      className={`Payment-history-list-page-button${isActive
        ? '-active' : ''}`}
      onClick={handleOnClick}
      type="button"
    >
      <span>{pageNumber}</span>
    </button>
  );
};

export { PaymentHistoryPageButton };
