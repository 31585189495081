import React from 'react';
import { useTranslation } from 'react-i18next';
import placeHolder from '../../../assets/myVets/placeholder-image.svg';
import removeVet from '../../../assets/myVets/my-pets-bin.svg';
import { EXPLORE_PROVIDERS } from '../../../constants';

import './MyPetsVetCard.css';

const MyPetsVetCard = ({
  handleRemoveVet = () => {},
  providerId = '',
  vetAddress1 = '',
  vetAddress2 = '',
  vetCity = '',
  vetImage = '',
  vetName = '',
  vetState = '',
  vetTelephone = '',
}) => {
  const { t } = useTranslation('myVets');

  return (

    <div className="My-pets-vet-card-container">
      <div className="My-pets-vet-image-container">
        <img
          alt="Placeholder"
          aria-label="Placeholder"
          className={vetImage ? 'My-pets-vet-image' : 'My-pets-vet-no-image'}
          src={vetImage || placeHolder}
        />
      </div>

      <div className="My-pets-vet-card-box">

        <div className="My-pets-vet-info-container">
          <p className="My-pets-vet-info-name">{vetName}</p>

          <p className="My-pets-vet-info-telephone">{vetTelephone}</p>

          {providerId === EXPLORE_PROVIDERS.manuallyAdded ? (
            <p className="My-pets-vet-info-address">
              {`${vetState}, ${vetCity}`}
            </p>
          ) : (
            <p className="My-pets-vet-info-address">
              {vetAddress1}

              {vetAddress2 && `, ${vetAddress2}`}
            </p>
          )}

          <button
            className="Remove-my-pets-vet btn"
            onClick={handleRemoveVet}
            type="button"
          >
            <img
              alt={t('myVets.removeVet')}
              aria-label={t('myVets.removeVet')}
              className="Remove-vet-icon"
              src={removeVet}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export { MyPetsVetCard };
