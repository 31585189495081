import React from 'react';
import './PetCloudInputAnimated.css';

const PetCloudInputAnimated = ({
  children,
  error,
  extraItemClass = '',
  id,
  inputClass = '',
  label,
  labelClass = '',
  maxLength,
  onBlur = () => { },
  onChange = () => { },
  onFocus = () => { },
  placeholder,
  type = 'text',
  value,
  ...inputProps
}) => (
  <div className="Pet-cloud-input-container">
    <label className={`Pet-cloud-input-label ${labelClass}`} htmlFor={id}>
      {label}
    </label>

    <input
      className={'Pet-cloud-input-control '
        + `${inputClass} `
        + `${error ? 'Pet-cloud-input-error' : ''}`
        + `${children && !!children.type
          ? 'Pet-cloud-input-control-extra-space' : ''}`}
      id={id}
      maxLength={maxLength}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      placeholder={placeholder}
      type={type}
      value={value}
      {...inputProps}
    />

    <span className={`Pet-cloud-input-extra-item ${extraItemClass}`}>
      {children}
    </span>

    <p aria-live="polite" className="Error-label">
      {error}
    </p>
  </div>
);

export { PetCloudInputAnimated };
