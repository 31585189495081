import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import FigoLottie from './FigoLottie';
import loadingWheel from '../../assets/lottieFiles/loading-blue.json';
import FigoLoadingOverlay from './FigoLoadingOverlay';
import { OneIncDefaultPaymentMethod } from './OneIncDefaultPaymentMethod';
import {
  createOneIncSession,
  oneIncLoadDefaultPayments,
  oneIncSessionRemoved,
} from '../../actions/oneInc';
import {
  BANK_ACCOUNT,
  CREDIT_CARD,
  PAYMENT_METHOD_NAMES,
  PAYMENT_METHOD_IDS,
  ONE_INC_PAYMENT_CATEGORY,
} from '../../constants';

import './OneIncModal.css';
import {
  openMultiPoliciesModal,
  paymentMethodPastDueModalVisibleChanged,
} from '../../actions';

let closeDefaultCount = 0;
const OneIncModal = ({
  buttonClass,
  children,
  hasPastPaymentDue,
  onUpdatePaymentMethod,
  selectedPolicy,
}) => {
  const dispatch = useDispatch();
  const store = useSelector(({
    billingPayments,
    editPayment,
    oneInc,
    personalInformation,
    policies,
  }) => ({
    billingPayments,
    editPayment,
    oneInc,
    personalInformation,
    policies,
  }), shallowEqual);
  const div = useRef(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [closeDefaultModal, setCloseDefaultModal] = useState(0);
  const { billingPayments: { billingDetails } } = store;
  const {
    firstName,
    lastName,
    userInfo: { Address, DiamondClientId },
  } = store.personalInformation;
  let oneIncPaymentData = null;

  useEffect(() => {
    if (!store.editPayment.showLoading && showModal) {
      setShowModal(false);
    }
  }, [store.editPayment.showLoading]);

  const userInformation = {
    address1: store.personalInformation.address1,
    address2: store.personalInformation.address2,
    city: store.personalInformation.city,
    state: store.personalInformation.state,
    zipCode: store.personalInformation.zipCode,
  };

  function getRequestUpdatePaymentMethod() {
    return {
      paymentMethodData: oneIncPaymentData,
      policies: [],
      policyNumber: selectedPolicy,
      sameBillingAddress: true,
      userInformation,
    };
  }

  function getForceToUpdatePolicies() {
    if (!billingDetails) {
      return [];
    }

    return store.editPayment.policiesList
      .filter((policy) => (
        policy.Policy.PaymentMethod
        === PAYMENT_METHOD_NAMES[billingDetails.PayMethod]
      ));
  }

  function handleUpdatePaymentMethod() {
    const requestUpdatePaymentMethod = getRequestUpdatePaymentMethod();
    onUpdatePaymentMethod(requestUpdatePaymentMethod);
  }

  function showMultiplePolicyModal() {
    const policies = getForceToUpdatePolicies()
      .map((policy) => (policy.Policy.Number));

    dispatch(openMultiPoliciesModal({
      paymentMethodData: oneIncPaymentData,
      policies,
      sameBillingAddress: true,
      userInformation,
    }));
  }

  const handleSaveComplete = useCallback(({ data, saveByDefault = false }) => {
    const isCreditCard =
      data.paymentCategory === ONE_INC_PAYMENT_CATEGORY.creditCard;
    const paymentName = isCreditCard ? CREDIT_CARD : BANK_ACCOUNT;

    oneIncPaymentData = {
      ...data,
      paymentName,
      policyNumber: selectedPolicy,
    };
    const hasOnePolicy = saveByDefault
      || store.editPayment.policiesList.length === 1;

    if (hasOnePolicy) {
      if (hasPastPaymentDue) {
        const requestUpdatePaymentMethod = getRequestUpdatePaymentMethod();

        dispatch(paymentMethodPastDueModalVisibleChanged({
          requestUpdatePaymentMethod,
          visible: true,
        }));
      } else {
        setShowModal(true);
        handleUpdatePaymentMethod();
      }
    } else {
      showMultiplePolicyModal();
    }
    setLoading(false);
  }, [hasPastPaymentDue, selectedPolicy, store.editPayment]);

  const handleSaveByDefault = useCallback(({
    customerName,
    lastFourDigits,
    paymentCategory,
    tokenId,
  }) => {
    const data = {
      customerName,
      lastFourDigits,
      paymentCategory,
      sessionId: store.oneInc.portalOneSessionKey,
      tokenId,
    };

    handleSaveComplete({ data, saveByDefault: true });
  }, [handleSaveComplete]);

  const callback = useCallback(() => {
    closeDefaultCount += 1;
    setCloseDefaultModal(closeDefaultCount + 1);
  }, []);

  useEffect(() => {
    if (!div || !window.$) {
      return;
    }

    window.$('#portalOneContainer').on('portalOne.load', callback);

    window.$('#portalOneContainer').on('portalOne.unload', () => {
      setLoading(false);
      dispatch(oneIncSessionRemoved());
    });

    window.$('#portalOneContainer').on('portalOne.saveComplete', (_, data) => {
      handleSaveComplete({ data });
    });

    window.$('#portalOneContainer').on('portalOne.error', () => {
      setLoading(false);
      dispatch(oneIncSessionRemoved());
    });
  }, [dispatch, div]);

  useEffect(() => {
    if (!store.oneInc.portalOneSessionKey || !div) {
      return;
    }

    const billingAddressStreet = Address.City && Address.State
      ? `${Address.AddressLine1} ${Address.AddressLine2}`
      + ` ${Address.City}, ${Address.State}`
      : '';

    const params = {
      billingAddressStreet,
      billingZip: Address.ZipCode,
      clientReferenceData1: DiamondClientId,
      confirmationDisplay: 'false',
      paymentCategory: 'UserSelect',
      policyHolderName: `${firstName} ${lastName}`,
      sessionId: store.oneInc.portalOneSessionKey,
    };

    window.$('#portalOneContainer').portalOne();
    window.$('#portalOneContainer').data('portalOne').savePaymentMethod(params);
  }, [
    Address,
    DiamondClientId,
    firstName,
    store.oneInc.portalOneSessionKey,
    lastName,
  ]);

  function onAddPaymentMethod() {
    setLoading(true);
    dispatch(oneIncLoadDefaultPayments());
  }

  function onCreateSession() {
    dispatch(createOneIncSession());
  }

  function onCancelDefaultMondal() {
    setLoading(false);
  }

  function renderUpdateButton() {
    if (loading) {
      return (
        <div className={buttonClass}>
          <FigoLottie
            animationData={loadingWheel}
            height={30}
            width={30}
          />
        </div>
      );
    }

    return (
      <button
        className={buttonClass}
        onClick={onAddPaymentMethod}
        type="button"
      >
        <div className="No-click">
          {children}
        </div>
      </button>
    );
  }

  return (
    <>
      <FigoLoadingOverlay visible={showModal} />

      <OneIncDefaultPaymentMethod
        forceClose={closeDefaultModal}
        isCreditCardDefault={billingDetails && billingDetails.PayMethod
          === PAYMENT_METHOD_IDS.creditCard}
        onCancel={onCancelDefaultMondal}
        oneIncReducer={store.oneInc}
        onNewAddPaymentMethod={onCreateSession}
        onSaveComplete={handleSaveByDefault}
      />

      {renderUpdateButton()}

      {/* OneInc modal create here */}
      <div id="portalOneContainer" />
    </>
  );
};

export { OneIncModal };
