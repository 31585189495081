import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { usePetPolicies } from '../../../hooks/usePetPolicies';

import IconTooltip from '../../common/IconTooltip';

import Shimmer from '../../common/Shimmer';

import infoIcon from '../../../assets/policy/info-circle.svg';

import { getDateBasicFormat } from '../../../services/utils';

import { CoverageInfoFeeCancelled } from './CoverageInfoFeeCancelled';

import './css/CoverageFigoInfoFee.css';

const CoverageFigoInfoFee = ({
  effectiveDate = '',
  isCancelled = false,
  selectedPolicy = {},
}) => {
  const { t } = useTranslation('insuranceScreen');
  const petPolicies = usePetPolicies();
  const { loading } = petPolicies;

  function formatDate(date) {
    if (!date) {
      return t('na');
    }

    return getDateBasicFormat(date.split('T')[0], 'MM/DD/YYYY');
  }

  const annualCoverage = useMemo(() => {
    if (selectedPolicy.PolicyDisplayNumber) {
      return selectedPolicy.Benefit.includes('K')
        ? `$${selectedPolicy.Benefit.replace('K', ',000')}`
        : selectedPolicy.Benefit;
    }

    return t('na');
  }, [selectedPolicy]);
  const deductible = selectedPolicy.PolicyDisplayNumber
    ? `$${selectedPolicy.Deductible}` : t('na');
  const reimbursement = selectedPolicy.PolicyDisplayNumber
    ? `${selectedPolicy.Reimbursement}%` : t('na');

  function renderShimmer() {
    return (
      <div className="Flex Flex-column">
        <Shimmer className="Coverage-overview-info-shimmer" visible />

        <Shimmer className="Coverage-overview-info-shimmer" visible />

        <Shimmer className="Coverage-overview-info-shimmer" visible />

        <Shimmer className="Coverage-overview-info-shimmer" visible />
      </div>
    );
  }

  function renderContent() {
    if (isCancelled) {
      const payAmount = selectedPolicy.TotalPremium
        || selectedPolicy.TotalPremium === 0
        ? `$${selectedPolicy.TotalPremium.toFixed(2)}` : t('na');

      return (
        <CoverageInfoFeeCancelled
          cancellationDate={formatDate(selectedPolicy.CancellationDate)}
          effectiveDate={formatDate(effectiveDate)}
          payAmount={payAmount}
          policyNumber={selectedPolicy.PolicyDisplayNumber}
        />
      );
    }

    return (
      <>
        <div className="Flex Flex-column">
          <span className="Coverage-overview-info-title">
            {t('policyNumberTitle')}
          </span>

          <span className="Coverage-overview-info-value">
            {selectedPolicy.PolicyDisplayNumber}
          </span>
        </div>

        <div className="Flex Flex-column">
          <span className="Coverage-overview-info-title">
            {t('policyTerm')}
          </span>

          <span className="Coverage-overview-info-value">
            {`${formatDate(selectedPolicy.PolicyStartDate)} - 
            ${formatDate(selectedPolicy.PolicyEndDate)}`}
          </span>
        </div>
      </>
    );
  }

  return loading ? renderShimmer() : (
    <>
      <div className={'Coverage-overview-policy-info-container '
        + 'Change-coverate-policy-row'}
      >
        {renderContent()}
      </div>

      {isCancelled ? null : (
        <div className="Coverage-overview-figo-info">
          <div className="Coverage-overview-figo-info-row">
            <div className="Coverage-overview-figo-fee-title">
              <span>{t('annualCoverage')}</span>

              <IconTooltip
                activeOnTouch
                arrowClass="Coverage-overview-tooltip-arrow"
                containerClass="Coverage-overview-tooltip-description"
                description={t('tooltip.annualCoverageTooltip')}
                direction="top-left"
                height={58}
                icon={infoIcon}
                iconClassName="Coverage-overview-tooltip-icon"
                mainClass="Coverage-overview-tooltip-main-class"
                textClassName="Coverage-overview-tooltip-text"
                width={200}
              />
            </div>

            <span className="Coverage-overview-figo-fee-value">
              {annualCoverage}
            </span>
          </div>

          <div className="Coverage-overview-figo-info-row">
            <div className="Coverage-overview-figo-fee-title">
              <span>{t('reimbursement')}</span>

              <IconTooltip
                activeOnTouch
                arrowClass="Coverage-overview-tooltip-arrow"
                containerClass="Coverage-overview-tooltip-description"
                description={t('tooltip.reimbursementTooltip')}
                direction="top-left"
                height={64}
                icon={infoIcon}
                iconClassName="Coverage-overview-tooltip-icon"
                mainClass="Coverage-overview-tooltip-main-class"
                textClassName="Coverage-overview-tooltip-text"
                width={200}
              />
            </div>

            <span className="Coverage-overview-figo-fee-value">
              {reimbursement}
            </span>
          </div>

          <div className="Coverage-overview-figo-info-row">
            <div className="Coverage-overview-figo-fee-title">
              <span>{t('deductible')}</span>

              <IconTooltip
                activeOnTouch
                arrowClass="Coverage-overview-tooltip-arrow"
                containerClass="Coverage-overview-tooltip-description"
                description={t('tooltip.deductibleTooltip')}
                direction="top-left"
                height={64}
                icon={infoIcon}
                iconClassName="Coverage-overview-tooltip-icon"
                mainClass="Coverage-overview-tooltip-main-class"
                textClassName="Coverage-overview-tooltip-text"
                width={200}
              />
            </div>

            <span className="Coverage-overview-figo-fee-value">
              {deductible}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export { CoverageFigoInfoFee };
