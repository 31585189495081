import React from 'react';
import { useTranslation } from 'react-i18next';

import ModalContainer, {
  MODAL_CONTAINER_ANIMATION_TYPE,
} from '../ModalContainer';

import costcoIcon
from '../../../assets/brandProviderLogos/costco-insurance-agency.svg';

import './CostcoMemberQuoteModal.css';

const CostcoMemberQuoteModal = ({
  onContinueWithCostco = () => { },
  onContinueWithStandardQuote = () => { },
  onClosePress = () => { },
  visible = false,
}) => {
  const { t } = useTranslation('multibrand');

  return (
    <ModalContainer
      animationType={MODAL_CONTAINER_ANIMATION_TYPE.OPACITY}
      cancelable
      handleOutClick={onClosePress}
      show={visible}
    >
      <div className="Costco-quote-modal-main-container">
        <img alt="" src={costcoIcon} />

        <h1>{t('costcoMemberQuoteModal.title')}</h1>

        <span>
          {t('costcoMemberQuoteModal.descriptionBegining')}

          <span>
            {t('costcoMemberQuoteModal.descriptionDiscount')}
          </span>

          {t('costcoMemberQuoteModal.descriptionEnd')}
        </span>

        <button
          className={'Costco-quote-modal-button Costco-quote-modal-button-blue'
           + ' Margin-top-24'}
          onClick={onContinueWithCostco}
          type="button"
        >
          <span>
            {t('costcoMemberQuoteModal.withCostcoDiscount')}
          </span>
        </button>

        <button
          className={'Costco-quote-modal-button Costco-quote-modal-button-white'
           + ' Margin-top-8'}
          onClick={onContinueWithStandardQuote}
          type="button"
        >
          <span>
            {t('costcoMemberQuoteModal.withStandardQuote')}
          </span>
        </button>
      </div>
    </ModalContainer>
  );
};

export { CostcoMemberQuoteModal };
