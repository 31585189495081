import React from 'react';
import CameraIcon from '../../assets/myPets/camera.svg';
import './UploadPhotoButton.css';

const UploadPhotoButton = ({
  className = '',
  iconOnly = true,
  isProfilePicture = false,
  onClick,
  text = '',
  placeholder = false,
}) => {
  let buttonClass = '';

  if (iconOnly) {
    buttonClass = 'Upload-icon-button';
  } else {
    buttonClass = 'Upload-text-button';
  }

  if (isProfilePicture) {
    buttonClass += ' Upload-button-profile-picture';
  } else if (placeholder) {
    buttonClass += ' Upload-button-family-picture-placeholder';
  } else {
    buttonClass += ' Upload-button-family-picture-';
  }

  buttonClass += ` ${className}`;

  return (
    <button
      className={buttonClass}
      onClick={onClick}
      type="button"
    >
      {!iconOnly && (
        <>
          <img
            alt="Upload"
            className="Pet-profile-family-picture-icon-button"
            src={CameraIcon}
          />

          <span>{text}</span>
        </>
      )}
    </button>
  );
};
export { UploadPhotoButton };
