import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

import { MyAccountCardItem } from './MyAccountCardItem';
import { InsuranceAccountCards }
from './insuranceAccount/InsuranceAccountCards';

import petCloudIcon from '../../assets/myAccount/pet-cloud-card-logo.svg';
import creditCardIcon from '../../assets/myAccount/credit-card.svg';
import creditCardSyncIcon from '../../assets/myAccount/credit-card-sync.svg';
import reimbursementIcon
from '../../assets/myAccount/reimbursement/reimbursement-logo.svg';

import { MY_ACCOUNT } from '../../constants';
import { MARKETING_CHANNEL_ID } from '../../theme/theme.constants';

import './css/MyAccountScreen.css';

const MyAccountScreen = ({ breadCrumbs }) => {
  const { t } = useTranslation('myAccount');
  const store = useSelector(({
    personalInformation,
  }) => ({ personalInformation }), shallowEqual);

  const {
    personalInformation: { userInfo },
  } = store;

  const insuranceCompanies = userInfo.Companies;

  const isFigo = useMemo(() => insuranceCompanies
    .some((company) => company.Id === MARKETING_CHANNEL_ID.figo),
  [insuranceCompanies]);

  const isPPIG = useMemo(() => insuranceCompanies
    .every((company) => company.Id === MARKETING_CHANNEL_ID.ppiOnePack),
  [insuranceCompanies]);

  const onCardClick = useCallback(({
    extraData = '',
    id = '',
    name = '',
  }) => () => {
    if (breadCrumbs) {
      breadCrumbs.onAddBreadcrumbItem({ extraData, id, name });
    }
  }, [breadCrumbs]);

  const renderPaymentHistoryCard = () => {
    if (isFigo) {
      return (
        <MyAccountCardItem
          description={t('paymentHistoryCard.description')}
          icon={creditCardSyncIcon}
          onClick={onCardClick({
            id: MY_ACCOUNT.paymentHistory,
            name: t(`breadCrumbs.${MY_ACCOUNT.paymentHistory}`),
          })}
          title={t('paymentHistoryCard.title')}
        />
      );
    }

    return null;
  };

  const renderReimbursementCard = () => {
    if (isPPIG) {
      return null;
    }

    return (
      <MyAccountCardItem
        description={t('reimbursementCard.description')}
        icon={reimbursementIcon}
        onClick={onCardClick({
          id: MY_ACCOUNT.reimbursement,
          name: t(`breadCrumbs.${MY_ACCOUNT.reimbursement}`),
        })}
        title={t('reimbursementCard.title')}
      />
    );
  };

  return (
    <div className="My-account-container">
      <h1 className="My-account-title">{t('title')}</h1>

      <div className="My-account-grid-container">
        <MyAccountCardItem
          description={t('petCloudSection.petCloudCard.description')}
          icon={petCloudIcon}
          onClick={onCardClick({
            id: MY_ACCOUNT.petCloudAccount,
            name: t(`breadCrumbs.${MY_ACCOUNT.petCloudAccount}`),
          })}
          title={t('petCloudSection.petCloudCard.title')}
        />

        <InsuranceAccountCards
          insuranceCompanies={insuranceCompanies}
          onClick={onCardClick}
        />

        <MyAccountCardItem
          description={t('billingDetailsCard.description')}
          icon={creditCardIcon}
          onClick={onCardClick({
            id: MY_ACCOUNT.billingAndPayments,
            name: t(`breadCrumbs.${MY_ACCOUNT.billingAndPayments}`),
          })}
          title={t('billingDetailsCard.title')}
        />

        {renderPaymentHistoryCard()}

        {renderReimbursementCard()}
      </div>
    </div>
  );
};

export { MyAccountScreen };
