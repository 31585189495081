import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ModalContainer from '../../common/ModalContainer';
import TouchableDiv from '../../common/TouchableDiv';
import LoadingButton from '../../common/LoadingButton';
import { PET_TYPE_ID } from '../../../constants';
import {
  POWER_UPS_RELATION,
  POWERUP_TYPE,
} from '../powerups/powerups.constans';
import dogIcon from '../../../assets/policy/dog-selected.svg';
import catIcon from '../../../assets/policy/cat-selected.svg';
import infoIcon from '../../../assets/policy/policy-alert.svg';
import { submitChangeCoverage } from '../../../actions';
import './ReviewChangesModal.css';

const ReviewChangesModal = ({
  disableSubmit = true,
  onClose = () => { },
  petInfo,
  policyNumber = '',
  showModal = false,
}) => {
  const { t } = useTranslation('changeCoverage');
  const dispatch = useDispatch();
  const store = useSelector(({ policies }) => ({ ...policies.changeCoverage }));
  const {
    loadingSubmit,
    quoteSelectedValues: {
      deductibleDescription,
      reimbursementDescription,
      selectedAnnualBenefit,
      deductibleId,
      reimbursementId,
      selectedPlan,
      total,
      totalMonthly,
      totalAnnual,
    },
    rate: {
      effectiveDate,
      isAnnual,
      petQuoteResponseList,
    },
    success,
  } = store;

  const { DOG } = PET_TYPE_ID;

  const powerUps = useMemo(() => {
    const quoteModifiers = petQuoteResponseList
      ?.find((item) => item.petQuoteId === petInfo.petQuoteId)?.modifiers || [];
    const { InsuranceModifiers } = petInfo;
    if (InsuranceModifiers) {
      const powerUpsFilter = InsuranceModifiers
        .filter((modifier) => modifier.InsuranceModifierTypeId === POWERUP_TYPE
          && modifier.IsVisible)
        .map((powerUp) => ({
          id: powerUp.Id,
          isIncluded: quoteModifiers
            .find((item) => item.id === powerUp.Id)?.isSelected || false,
          name: powerUp.AppText,
        }));

      return powerUpsFilter;
    }
    return [];
  }, [petInfo, petQuoteResponseList]);

  const priceWithFormat = useMemo(() => {
    const price = isAnnual
      ? total : totalMonthly;
    const annualTotal = isAnnual ? totalAnnual : 0;

    if (!price) {
      return { decimal: '00', integer: 0, total: 0 };
    }

    return {
      annualTotal,
      monthlyDecimal: `.${price.toString().split('.')[1] || '00'}`,
      monthlyInteger: price.toString().split('.')[0],
    };
  }, [isAnnual, total, totalAnnual, totalMonthly]);

  const annualPrice = `$${priceWithFormat.annualTotal}`;
  const monthlyPrice =
    `$${priceWithFormat.monthlyInteger}${priceWithFormat.monthlyDecimal}`;

  const handleCancel = useCallback(() => {
    if (loadingSubmit) {
      return;
    }

    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    const { InsuranceModifiers, modifiers } = petInfo;
    const vetFees = InsuranceModifiers.find((item) => item.PMSModifierId
      === POWER_UPS_RELATION.VETERINARY_EXAM_FEES_PSM_ID);
    const hasVetFees = modifiers.length && modifiers
      .find((modifier) => modifier.id === vetFees.Id && modifier.isSelected);
    const selectedModifiers = modifiers || [];

    // powerups hidden and seleted id's
    const hideModifiers = petInfo.InsuranceModifiers
      .filter((insuranceModifer) => insuranceModifer
        .InsuranceModifierTypeId === POWERUP_TYPE
        && !insuranceModifer.IsVisible
        && insuranceModifer.IsSelected)
      .map((insuranceModifer) => ({
        id: insuranceModifer.Id,
        isSelected: true,
      }));

    // powerup id's selected, hidden and powerups children
    let allModifiers = [...selectedModifiers, ...hideModifiers];
    const extraCarePack = petInfo.InsuranceModifiers.find((item) => item
      .PMSModifierId === POWER_UPS_RELATION.EXTRA_CARE_PACK_PSM_ID);

    if (extraCarePack) {
      const extraCarePackSelected = allModifiers
        .find((modifier) => modifier.id === extraCarePack.Id);

      const extraCarePackChildren =
        extraCarePack.BundleInsuranceModifiers.map((child) => ({
          id: child.Id,
          isSelected: extraCarePackSelected
            && extraCarePackSelected.isSelected,
        }));

      allModifiers = [
        ...allModifiers,
        ...extraCarePackChildren,
      ];
    }

    dispatch(submitChangeCoverage({
      body: {
        Deductible: deductibleId,
        EffectiveDate: effectiveDate,
        HasVetFees: !!hasVetFees,
        modifiers: allModifiers,
        QuotePlan: selectedPlan,
        Reimbursement: reimbursementId,
      },
      policyNumber,
    }));
  }, [
    deductibleId,
    dispatch,
    effectiveDate,
    petInfo,
    policyNumber,
    reimbursementId,
    selectedPlan,
  ]);

  return (
    <ModalContainer show={showModal}>
      <div className="Review-changes-modal-main-container">
        <div className="Review-changes-modal-main-top">
          <img
            alt=""
            className="Review-changes-modal-main-pet-icon"
            src={petInfo.petType === DOG ? dogIcon : catIcon}
          />

          <p className="Review-changes-modal-main-pet-name">
            {`${petInfo.petName}'s Plan`}
          </p>
        </div>

        <div className="Review-changes-modal-main-money">
          <div className="Review-changes-modal-main-payment-summary">
            <span className="Review-changes-modal-main-payment-text-top">
              {selectedAnnualBenefit}
            </span>

            <span className="Review-changes-modal-main-payment-text-bottom">
              {t('reviewChangesModal.annualBenefit')}
            </span>
          </div>

          <div className="Review-changes-modal-main-payment-summary">
            <span className="Review-changes-modal-main-payment-text-top">
              {reimbursementDescription || ''}
            </span>

            <span className="Review-changes-modal-main-payment-text-bottom">
              {t('reviewChangesModal.reimbursement')}
            </span>
          </div>

          <div className="Review-changes-modal-main-payment-summary">
            <span className="Review-changes-modal-main-payment-text-top">
              {deductibleDescription || ''}
            </span>

            <span className="Review-changes-modal-main-payment-text-bottom">
              {t('reviewChangesModal.deductible')}
            </span>
          </div>
        </div>

        <div className="Review-changes-modal-main-line-divisor" />

        <div className="Review-changes-modal-main-powerups-container">
          <div className="Review-changes-modal-main-powerups-section">
            {powerUps.map((powerup) => (
              <div
                key={powerup.id}
                className="Review-changes-modal-main-powerup"
              >
                <span className="Review-changes-modal-main-powerup-title">
                  {powerup.name}
                </span>

                <span className="Review-changes-modal-main-powerup-choice">
                  {powerup.isIncluded
                    ? t('reviewChangesModal.included')
                    : t('reviewChangesModal.notIncluded')}
                </span>
              </div>
            ))}

            <div className="Review-changes-modal-main-powerup-total">
              <span
                className={`Review-changes-modal-main-total-pet 
                Review-changes-modal-main-total-gap`}
              >
                {isAnnual
                  ? t('reviewChangesModal.annualTotal')
                  : t('reviewChangesModal.monthlyTotal')}
              </span>

              <span className="Review-changes-modal-main-total-pet">
                {isAnnual ? annualPrice : monthlyPrice}
              </span>
            </div>
          </div>
        </div>

        <div className="Review-changes-modal-main-info-section">
          <img
            alt=""
            className="Review-changes-modal-main-info-icon"
            src={infoIcon}
          />

          <span className="Review-changes-modal-main-disclaimer">
            {t('reviewChangesModal.disclaimer')}
          </span>
        </div>

        <div className="Review-changes-modal-main-buttons">
          <TouchableDiv
            className="Review-changes-modal-main-cancel-button"
            id="rcmCancel"
            onClick={handleCancel}
          >
            <span className="Review-changes-modal-main-cancel-button-text">
              {t('reviewChangesModal.cancel')}
            </span>
          </TouchableDiv>

          <LoadingButton
            className="Review-changes-modal-main-submit-button"
            disabled={disableSubmit}
            loading={loadingSubmit}
            onClick={handleSubmit}
            success={success}
            text={t('reviewChangesModal.submit')}
            textStyle="Review-changes-modal-main-submit-button-text"
          />
        </div>
      </div>
    </ModalContainer>
  );
};

export { ReviewChangesModal };
