import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useMixpanel } from '../../hooks';
import { EV3 } from '../../services/mixpanel';

import {
  checkIfHasPrimaryVet,
  loadClaimSteps,
  loadHasFigoClaims,
  loadNewClaimSteps,
} from '../../actions';
import { FILE_CLAIMBOT } from '../navigation/routes.constants';
import { useNewClaimbot } from '../../services/utils';
import { MARKETING_CHANNEL_ID } from '../../theme/theme.constants';

import addIcon from '../../assets/policy/add-white.svg';

import { AddPrimaryVet } from './primaryVet/AddPrimaryVet';
import LoadingActionButtonMyPets from
'../common/petCloud/LoadingActionButtonMyPets';
import { FirstFigoClaimModal } from './FirstFigoClaimModal';

import './FileClaimButton.css';

const FileClaimButton = ({
  className = '',
}) => {
  const [showModal, setShowModal] = useState(false);
  const [
    firstFigoClaimModalVisible,
    setFirstFigoClaimModalVisible,
  ] = useState(false);

  const { t } = useTranslation('claims');

  const { sendEvent } = useMixpanel();

  const store = useSelector(({
    billingPayments,
    claims,
    claimsGeneric,
    myVets,
    personalInformation,
    policies,
  }) => ({
    billingPayments,
    claims,
    claimsGeneric,
    myVets,
    personalInformation,
    policies,
  }), shallowEqual);

  const {
    claims: { loadingClaimSteps, newClaimStarted },
    claimsGeneric: {
      claimSteps,
      claimStepsViewed,
      figoClaimValidations,
      loadingSteps,
    },
    myVets: {
      hasPrimaryVet,
      isSavingVet,
      addVetError,
      vetSaved,
      checkedPrimaryVet,
      checkingPrimaryVet,
    },
  } = store;

  const dispatch = useDispatch();
  const history = useHistory();

  const hasAnyFigoPolicy = useMemo(() => {
    if (!store.personalInformation.userInfo) {
      return false;
    }

    const companies = store.personalInformation.userInfo.Companies;

    return companies && !!companies
      .find((comp) => comp.Id === MARKETING_CHANNEL_ID.figo);
  }, [store.personalInformation.userInfo]);

  useEffect(() => {
    if (!checkedPrimaryVet && !checkingPrimaryVet) {
      dispatch(checkIfHasPrimaryVet());
    }

    if (hasAnyFigoPolicy) {
      dispatch(loadHasFigoClaims());
    }
  }, []);

  useEffect(() => {
    if (newClaimStarted) {
      history.push(FILE_CLAIMBOT);
    }
  }, [newClaimStarted]);

  useEffect(() => {
    if (useNewClaimbot && !claimStepsViewed && claimSteps) {
      history.push(FILE_CLAIMBOT);
    }
  }, [claimSteps, claimStepsViewed, useNewClaimbot]);

  const doLoadSteps = () => {
    if (useNewClaimbot) {
      dispatch(loadClaimSteps());
    } else {
      dispatch(loadNewClaimSteps());
    }
  };

  function handleNewClaim() {
    sendEvent(EV3());

    if (hasAnyFigoPolicy && !figoClaimValidations.hasClaimFigo) {
      setFirstFigoClaimModalVisible(true);
      return;
    }

    if (!hasPrimaryVet) {
      setShowModal(true);
    } else {
      doLoadSteps();
    }
  }

  function closePrimaryVetModal() {
    setShowModal(false);
  }

  const onCloseFirstFigoClaim = () => {
    setFirstFigoClaimModalVisible(false);
  };

  const onContinueFirstFigoClaimPress = () => {
    setFirstFigoClaimModalVisible(false);
    doLoadSteps();
  };

  useEffect(() => {
    if (showModal && !isSavingVet && !addVetError
      && vetSaved && hasPrimaryVet) {
      doLoadSteps();
      setShowModal(false);
    }
  }, [dispatch, showModal, isSavingVet, addVetError, hasPrimaryVet]);

  return (
    <>

      <LoadingActionButtonMyPets
        className={`File-claim-button-blue ${className}`}
        icon={addIcon}
        loading={loadingClaimSteps || loadingSteps}
        onClick={handleNewClaim}
        text={t('fileAClaim')}
        type="button"
      />

      {showModal && <AddPrimaryVet onClose={closePrimaryVetModal} />}

      {firstFigoClaimModalVisible ? (
        <FirstFigoClaimModal
          onCancelPress={onCloseFirstFigoClaim}
          onContinuePress={onContinueFirstFigoClaimPress}
        />
      ) : null}
    </>
  );
};

export { FileClaimButton };
