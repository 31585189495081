import { createActions } from 'redux-actions';

const actions = createActions({
  DISPLAY_CONFIRM_CHANGES_MODAL: (data) => data,
  DISPLAY_EMAIL_CHANGE_MODAL: (data) => data,
  DISPLAY_FORGOT_PASSWORD_MODAL: (data) => data,
  DISPLAY_UNSAVED_MODAL: (data) => data,
  MY_ACCOUNT_EMAIL_CHANGED: (data) => data,
  MY_ACCOUNT_HAS_CHANGES: (data) => data,
});

export const {
  displayConfirmChangesModal,
  displayEmailChangeModal,
  displayForgotPasswordModal,
  displayUnsavedModal,
  myAccountEmailChanged,
  myAccountHasChanges,
} = actions;
