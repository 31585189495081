import { createActions } from 'redux-actions';

const actions = createActions({
  CONFIRM_PASSWORD_CHANGED: (data) => data,
  EMAIL_CHANGED: (data) => data,
  EMAIL_UPDATED: (data) => data,
  NEW_PASSWORD_CHANGED: (data) => data,
  OLD_PASSWORD_CHANGED: (data) => data,
  PASSWORD_UPDATED: (data) => data,
  PET_CLOUD_EMAIL_CHANGED: (data) => data,
  PET_CLOUD_EMAIL_RESET: () => { },
  PET_CLOUD_EMAIL_UPDATED: (data) => data,
  RECOVER_PASSWORD: (data) => data,
  RESET_EMAIL: () => { },
  RESET_INFO: () => { },
  UPDATE_EMAIL: (data) => data,
  UPDATE_EMAIL_ERROR: (data) => data,
  UPDATE_PASSWORD: (data) => data,
  UPDATE_PASSWORD_ERROR: (data) => data,
  UPDATE_PET_CLOUD_EMAIL: (data) => data,
  UPDATE_PET_CLOUD_EMAIL_ERROR: (data) => data,
});

export const confirmPasswordChanged = actions.confirmPasswordChanged;
export const emailChanged = actions.emailChanged;
export const emailUpdated = actions.emailUpdated;
export const newPasswordChanged = actions.newPasswordChanged;
export const oldPasswordChanged = actions.oldPasswordChanged;
export const passwordUpdated = actions.passwordUpdated;
export const petCloudEmailChanged = actions.petCloudEmailChanged;
export const petCloudEmailUpdated = actions.petCloudEmailUpdated;
export const recoverPassword = actions.recoverPassword;
export const resetEmail = actions.resetEmail;
export const resetInfo = actions.resetInfo;
export const resetPetCloudEmail = actions.petCloudEmailReset;
export const updateEmail = actions.updateEmail;
export const updateEmailError = actions.updateEmailError;
export const updatePassword = actions.updatePassword;
export const updatePasswordError = actions.updatePasswordError;
export const updatePetCloudEmail = actions.updatePetCloudEmail;
export const updatePetCloudEmailError = actions.updatePetCloudEmailError;
