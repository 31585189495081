import React from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

import { PET_TYPE_ID } from '../../constants';

import datPlaceholder from '../../assets/billingPayments/cat-placeholder.png';
import dogPlaceholder from '../../assets/billingPayments/dog-placeholder.png';

import './FoundLostPetPetInformation.css';

const FoundLostPetPetInformation = ({ columnDesign = false }) => {
  const store = useSelector(({ foundPetLost }) => (foundPetLost), shallowEqual);
  const { lostPetData } = store;
  const { t } = useTranslation('foundLostPet');

  const petAge = lostPetData.PetAge;
  const petAddress = t('petBreedAndAddress',
    { breedName: lostPetData.BreedName, petCity: lostPetData.City });
  const petType = lostPetData.Type;
  const petProfilePicture = lostPetData.ImageUrl;
  const placeHolderPet =
        petType === PET_TYPE_ID.CAT ? datPlaceholder : dogPlaceholder;
  const petBiography = lostPetData.Note;
  const petName = lostPetData.PetName;

  if (columnDesign) {
    return (
      <>
        <img
          alt="petProfilePicture"
          className="Figo-lost-pet-found-pet-picture"
          src={petProfilePicture || placeHolderPet}
        />

        <span className="Figo-lost-pet-found-pet-name">
          {petName}
        </span>

        <span className="Figo-lost-pet-found-pet-age">
          {t('petAgeYearsOld', { petAge })}
        </span>

        <span className="Figo-lost-pet-found-pet-address">
          {petAddress}
        </span>

        <span className={'Figo-lost-pet-found-pet-message-information '
              + `${petBiography ? '' : 'Gone'}`}
        >
          {petBiography}
        </span>
      </>
    );
  }

  return (
    <div className="Figo-lost-pet-found-pet-row">
      <img
        alt="petProfilePicture"
        className={'Figo-lost-pet-found-pet-picture '
          + 'Figo-lost-pet-found-pet-no-margin'}
        src={petProfilePicture || placeHolderPet}
      />

      <div className="Figo-lost-pet-found-pet-col">
        <span
          className={'Figo-lost-pet-found-pet-name '
          + 'Figo-lost-pet-found-pet-no-margin'}
        >
          {petName}
        </span>

        <span className="Figo-lost-pet-found-pet-age">
          {t('petAgeYearsOld', { petAge })}
        </span>

        <span className="Figo-lost-pet-found-pet-address">
          {petAddress}
        </span>
      </div>
    </div>
  );
};

export { FoundLostPetPetInformation };
