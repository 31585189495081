import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import MessageModal from './MessageModal';
import { getStorageOrDefault } from '../../services/api';

const ImpersonateSession = () => {
  const { t } = useTranslation('sessionRouter');
  const store = useSelector(({ accountLogin }) => (accountLogin), shallowEqual);
  const [exireSession, setExpireSession] = useState(false);
  const { userInfo } = store;

  const onFocus = useCallback(async () => {
    const user = await getStorageOrDefault('user_info');

    if (!userInfo || !user) {
      return;
    }

    const { CustomerId } = user;

    if (CustomerId !== userInfo.CustomerId) {
      setExpireSession(true);
    }
  }, [store.email]);

  useEffect(() => {
    window.onfocus = onFocus;

    return () => { window.onfocus = null; };
  }, [onFocus]);

  return (
    <MessageModal
      body={t('impersonate.sessionEndBody')}
      buttonText={t('impersonate.sessionEndButton')}
      hideClosebutton
      title={t('impersonate.sessionEndTitle')}
      visible={exireSession}
    />
  );
};

export { ImpersonateSession };
