import React from 'react';

import petCloudLogo from '../../assets/pet-cloud-logo.svg';

import './HeaderPetcloud.css';

const HeaderPetcloud = ({
  logoutLabel = '',
  onLogoutClick = () => { },
  showLogout = false,
}) => (
  <div className="Reset-password-pet-cloud-logo-div">
    <img
      alt="pet-cloud-logo"
      className="Reset-password-pet-cloud-logo"
      src={petCloudLogo}
    />

    {showLogout && (
      <button onClick={onLogoutClick} type="button">
        {logoutLabel}
      </button>
    )}
  </div>
);

export { HeaderPetcloud };
