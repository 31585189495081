import React from 'react';
import avi from '../../../assets/inboxDocsMultibrand/iconsFolder/avi.svg';
import empty from '../../../assets/inboxDocsMultibrand/iconsFolder/empty.svg';
import html from '../../../assets/inboxDocsMultibrand/iconsFolder/html.svg';
import jpeg from '../../../assets/inboxDocsMultibrand/iconsFolder/jpeg.svg';
import mov from '../../../assets/inboxDocsMultibrand/iconsFolder/mov.svg';
import mp3 from '../../../assets/inboxDocsMultibrand/iconsFolder/mp3.svg';
import pdf from '../../../assets/inboxDocsMultibrand/iconsFolder/pdf.svg';
import png from '../../../assets/inboxDocsMultibrand/iconsFolder/png.svg';
import txt from '../../../assets/inboxDocsMultibrand/iconsFolder/txt.svg';
import word from '../../../assets/inboxDocsMultibrand/iconsFolder/doc.svg';
import excel from '../../../assets/inboxDocsMultibrand/iconsFolder/xls.svg';
import zip from '../../../assets/inboxDocsMultibrand/iconsFolder/zip.svg';

const RecentFileIcon = ({
  alt = '',
  className = '',
  fileExtension = '',
}) => {
  const fileExtensionList = {
    '': empty,
    '.avi': avi,
    '.doc': word,
    '.docx': word,
    '.htm': html,
    '.html': html,
    '.jpeg': jpeg,
    '.jpg': jpeg,
    '.mov': mov,
    '.mp3': mp3,
    '.pdf': pdf,
    '.png': png,
    '.txt': txt,
    '.xls': excel,
    '.xlsx': excel,
    '.zip': zip,
  };
  return (
    <img
      alt={alt}
      className={className}
      src={fileExtensionList[fileExtension] || empty}
    />
  );
};

export { RecentFileIcon };
