import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounceSearch } from '../../../hooks';
import searchIcon from '../../../assets/myVets/search-icon.svg';
import loadingBlue from '../../../assets/lottieFiles/loading-blue.json';
import ScrollableDiv from '../../common/ScrollableDiv';
import FigoLottie from '../../common/FigoLottie';
import { removeEmojis } from '../../../services/utils';

import './MyPetsSearchInput.css';

const MyPetsSearchInput = ({
  emptyMessage = '',
  hideInput = false,
  id = '',
  isLoadingVets = true,
  itemListRender = () => { },
  itemSelected = null,
  itemsFound = [],
  onSearch = () => { },
  onSelected = () => { },
  renderSelectedItem = () => { },
  placeholder = '',
  ...otherProps
}) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [itemsFiltered, setItemsFiltered] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const { t } = useTranslation('myPets');

  function handleInputChange(event) {
    const { value } = event.target;
    setInputValue(removeEmojis(value));
  }

  function handleInputFocus() {
    setOpen(true);
    setIsFocused(true);
  }

  function handleInputBlur() {
    setIsFocused(false);
  }

  function closeDropdown() {
    setOpen(false);
  }

  const handleSelection = (item) => () => {
    onSelected(item);
  };

  const { searchResults } = useDebounceSearch(onSearch, 500);

  useEffect(() => {
    searchResults(inputValue);
  }, [inputValue]);

  useEffect(() => {
    setItemsFiltered(itemsFound);
  }, [itemsFound]);

  useEffect(() => {
    closeDropdown();
  }, [itemSelected]);

  useEffect(() => {
    if (hideInput) {
      setOpen(false);
    }
  }, [hideInput]);

  return (
    <div
      className="My-pets-search-input-my-pets-add-vet-modal-input"
    >

      {itemSelected ? (
        <div>
          {renderSelectedItem()}
        </div>
      ) : (
        <div
          className={`My-pets-search-input-container
          ${isFocused ? 'focused' : ''}`}
        >
          <img
            alt="search"
            src={searchIcon}
          />

          <input
            className="My-pets-search-input-container-input"
            onBlur={handleInputBlur}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            placeholder={placeholder}
            value={inputValue}
            {...otherProps}
          />
        </div>
      )}

      {open && (
        <div className="My-pets-search-input-dropdown-container">
          <ScrollableDiv className="My-pets-search-input-dropdown">
            <button
              aria-label={t('myVets.searchInput')}
              className="My-pets-pets-shade"
              id={`searchInput${id}`}
              onClick={closeDropdown}
              type="button"
            />

            {isLoadingVets && <FigoLottie animationData={loadingBlue} />}

            {itemsFiltered.map((item) => (
              <button
                key={`${item.PlaceId}` || `${item.Title}` || `${item.Id}`}
                className="My-pets-search-input-list-item"
                id={item.Id}
                onClick={handleSelection(item)}
                type="button"
              >
                {itemListRender(item)}
              </button>
            ))}

          </ScrollableDiv>

          {!isLoadingVets && (
            <div className="My-pets-search-input-empty-message">
              {emptyMessage}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export { MyPetsSearchInput };
