export function goToSelectedFolder({
  folderId = 0,
  folderContent = [],
  breadcrumbs = null,
}) {
  let selectedFolder = folderContent
    .find((folder) => String(folder.Id) === String(folderId));

  if (!selectedFolder) {
    // TODO: Remove 'Childrens' validation when v2 is complete
    const foldersChildrenMtx = folderContent
      .map((folders) => folders?.Childrens || folders?.Folders)
      .filter((child) => child?.length);
    const flatFoldersChildren = foldersChildrenMtx
      .reduce((inputArray, flatArray = []) => inputArray.concat(flatArray));

    selectedFolder = flatFoldersChildren
      .find((item) => String(item.Id) === String(folderId));
  }

  breadcrumbs.onDirectNavigation({
    id: selectedFolder.Id,
    name: selectedFolder.Name,
  });
}
