import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import infoImg from '../../../assets/changeCoverage/info-deep-blue.svg';

import PowerUpSwitch from '../../common/PowerUpSwitch';
import { PetProtectCopayModal } from './PetProtectCopayModal';

import './css/PetCopayCoverageSection.css';

const PetCopayCoverageSection = ({
  active = false,
  howItWorksClick = () => { },
  modifierData = {},
  onAddPowerup = () => { },
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { t } = useTranslation('changeCoverage');

  const childPowerUp = modifierData.BundleInsuranceModifiers[0];

  function handleToggle() {
    if (active) {
      onAddPowerup({
        isSelected: false,
        powerUp: childPowerUp || modifierData,
      });
    } else {
      setModalVisible(true);
    }
  }

  function closeModal() {
    setModalVisible(false);
  }

  function handleConfirm() {
    onAddPowerup({
      isSelected: true,
      powerUp: childPowerUp || modifierData,
    });
    setModalVisible(false);
  }

  return (
    <div className={'Pet-copay-coverage-section-card'
      + `${active ? '-selected' : ''}`}
    >
      <span className="Pet-copay-coverage-section-title">
        {modifierData.AppText}
      </span>

      <p className="Pet-copay-coverage-section-description">
        {modifierData.InformationText}
      </p>

      <div className="Pet-copay-coverage-section-bottom-row">
        <button
          className="Like-span"
          onClick={howItWorksClick}
          type="button"
        >
          <img
            alt=""
            className="Pet-copay-coverage-section-info-img"
            src={infoImg}
          />

          <span className="Pet-copay-coverage-section-info-text">
            {t('options.howDoesItWork')}
          </span>
        </button>

        <PowerUpSwitch
          active={active}
          customClassname={'Powerup-switch'
            + `${active ? ' Powerup-added' : ''}`}
          onClick={handleToggle}
          switchBallColor={active ? 'var(--deep-blue-main)'
            : 'var(--base-pc-color-white)'}
        />
      </div>

      <PetProtectCopayModal
        onCancel={closeModal}
        onConfirm={handleConfirm}
        show={modalVisible}
      />
    </div>
  );
};

export { PetCopayCoverageSection };
