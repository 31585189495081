import React, { useMemo } from 'react';
import './StepperMultibrand.css';

const StepperMultibrand = ({
  currentStepNumber = 1,
  numberOfSteps = 10,
}) => {
  const progress = useMemo(() => {
    const incrementPerStep = 100 / numberOfSteps;
    return incrementPerStep * currentStepNumber;
  }, [currentStepNumber, numberOfSteps]);

  return (
    <div className="Stepper-multibrand-progress-bar">
      <div
        className="Stepper-multibrand-progress-bar-completed"
        style={{ width: `${progress}%` }}
      />
    </div>
  );
};

export { StepperMultibrand };
