import { call, put } from 'redux-saga/effects';

import { apiCallWithApiKey, IDOR_HEADER_V2 } from '../services/api';
import {
  getNewPasswordTokenFailed,
  newPasswordTokenGenerated,
  passwordSetupChecked,
  resetPasswordFailed,
  resetPasswordSuccess,
  resetPasswordTokenLoaded,
  setUpNewPasswordFailed,
  setUpNewPasswordSuccess,
} from '../actions';

export function* doResetPassword(dispatch, { payload }) {
  const response = yield call(
    apiCallWithApiKey,
    'api/Account/PasswordRecoveryConfirm',
    {
      body: {
        ConfirmNewPassword: payload.confirmPassword,
        Email: payload.email,
        NewPassword: payload.newPassword,
        Token: payload.token,
      },
      method: 'POST',
    },
    dispatch,
  );

  const action = response.IsValid ? resetPasswordSuccess : resetPasswordFailed;
  yield put(action(response));
}

export function* validResetPasswordToken(dispatch, { payload }) {
  const params = { body: payload, method: 'POST' };
  const response = yield call(
    apiCallWithApiKey,
    'api/Account/RecoveryPasswordValidation',
    params,
    dispatch,
  );

  yield put(resetPasswordTokenLoaded(response.success && response.IsValid));
}

export function* checkPasswordSetup({ payload }) {
  const { customerEmail } = payload;
  const response = yield call(
    apiCallWithApiKey,
    `api/Customer/HasSavedPassword/${customerEmail}/`,
  );

  yield put(passwordSetupChecked({
    customerHasPassword: response.IsValid && response.Data.HasSavedPassword,
  }));
}

export function* getNewPasswordToken({ payload }) {
  const { customerEmail } = payload;
  const response = yield call(
    apiCallWithApiKey,
    `api/account/getonetimepasswordtoken/${customerEmail}/`,
    { headers: IDOR_HEADER_V2 },
  );

  if (response.IsValid) {
    yield put(newPasswordTokenGenerated(response.Data.Token));
  } else {
    yield put(getNewPasswordTokenFailed());
  }
}

export function* setUpNewPassword(dispatch, { payload }) {
  const response = yield call(
    apiCallWithApiKey,
    'api/Account/NewPassword',
    {
      body: {
        ConfirmNewPassword: payload.confirmPassword,
        Email: payload.email,
        NewPassword: payload.newPassword,
        Token: payload.token,
      },
      headers: IDOR_HEADER_V2,
      method: 'POST',
    },
    dispatch,
  );

  if (response.IsValid) {
    yield put(setUpNewPasswordSuccess());
  } else {
    yield put(setUpNewPasswordFailed(response.Message));
  }
}
