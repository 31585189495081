import React from 'react';
import { CONTACT_FIGO_URL } from '../../../constants';
import ModalContainer from '../../common/ModalContainer';
import './css/RateErrorModal.css';

const RateErrorModal = ({
  visible = false,
  tErrorText = () => { },
  onClose = () => { },
}) => (
  <ModalContainer contentStyle="Rate-error-modal-container" show={visible}>
    <div className="Rate-error-modal">
      <h4>{tErrorText('rateError.title')}</h4>

      <p>
        {tErrorText('rateError.text')}
        <a href={CONTACT_FIGO_URL}>
          {tErrorText('rateError.linkLabel')}
        </a>
      </p>

      <button onClick={onClose} type="button">
        {tErrorText('rateError.close')}
      </button>
    </div>
  </ModalContainer>
);

export { RateErrorModal };
