import React from 'react';
import { useTranslation } from 'react-i18next';
import check from '../../../assets/billingPayments/cash-payment-sign.svg';
import savingBank from '../../../assets/billingPayments/saving-bank.svg';
import LoadingActionButton from '../../common/petCloud/LoadingActionButton';
import addIcon from '../../../assets/policy/add-white.svg';

const Check = ({
  addressCheck = '',
  t,
}) => (
  <div className="Reimbursement-method__card">
    <img alt="check" src={check} />

    <span>{t('checkSent', { addressCheck })}</span>
  </div>
);

const DirectDeposit = ({
  bankNumber = '',
  t,
}) => (
  <div className="Reimbursement-method__card">
    <img alt="saving-bank" src={savingBank} />

    <span>{t('directDepositEding', { bankNumber })}</span>
  </div>
);

const AddReimbursementMethod = ({
  isLoading = false,
  isRedirectAvailable = false,
  onReimbursementMethod = () => { },
  t,
}) => {
  const handleAddReimbursementMethod = () => {
    onReimbursementMethod();
  };

  return (
    <div className="Reimbursement-method__card">
      <span className="Reimbursement-method__text">
        {`${t('noReimbursementMethod')} ${isRedirectAvailable
          ? t('addMethod')
          : ''}`}
      </span>

      {isRedirectAvailable && (
        <LoadingActionButton
          className="Reimbursement-method__add-button"
          loading={isLoading}
          onClick={handleAddReimbursementMethod}
          type="button"
        >
          <img alt="" src={addIcon} />

          <span>{t('addReimbursementMethod')}</span>
        </LoadingActionButton>
      )}
    </div>
  );
};

export const ReimbursementMethodCard = ({
  addressCheck = '',
  isLoading = false,
  isRedirectAvailable = false,
  lastNumbers = '',
  methodType = '',
  onReimbursementMethod = () => { },
}) => {
  const { t } = useTranslation('insuranceScreen');

  const reimbursementMethods = {
    check: (
      <Check
        addressCheck={addressCheck}
        t={t}
      />
    ),
    directDeposit: (
      <DirectDeposit
        bankNumber={lastNumbers}
        t={t}
      />
    ),
  };

  return reimbursementMethods[methodType] || (
    <AddReimbursementMethod
      isLoading={isLoading}
      isRedirectAvailable={isRedirectAvailable}
      onReimbursementMethod={onReimbursementMethod}
      t={t}
    />
  );
};
