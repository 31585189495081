import React from 'react';
import { useTranslation } from 'react-i18next';
import './FinishButton.css';

const FinishButton = () => {
  const { t } = useTranslation('claims');

  return (
    <a
      className="Finish-button-a"
      href="/claims"
    >
      {t('newClaim.noEligiblePetsButton')}
    </a>
  );
};

export { FinishButton };
