import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import policyInfoIcon from '../../assets/policy/information-icon.svg';
import policyOrangeAlertIcon
from '../../assets/policy/information-alert-icon.svg';
import { loadPolicyNotifications } from '../../actions';
import { CHANGE_COVERAGE_WEB } from '../navigation/routes.constants';
import './PolicyNotifications.css';
import { MY_ACCOUNT, POLICY_STATUS } from '../../constants';
import { objectToBase64 } from '../../services/utils';
import { highlightPrice } from './policyNotificationsUtils ';
import { MARKETING_CHANNEL_ID } from '../../theme/theme.constants';

const PAYMENT_INFO_ACTION = 2;
const CLICK_HERE_RENEWAL = 3;
const CLICK_HERE_ROLLOVER = 4;

const PolicyNotifications = (props) => {
  const { t } = useTranslation('insuranceScreen');
  const dispatch = useDispatch();
  const store = useSelector(({ policies }) => policies);

  useEffect(() => {
    if (props.policy && props.policy.Number) {
      dispatch(loadPolicyNotifications(props.policy.Number));
    }
  }, [props.policy]);

  const getFuturePolicy = () => {
    const { petId } = props;
    const selectedPet = store.allPets.find((pet) => pet.PetId === petId);

    if (!selectedPet) {
      return '';
    }

    const futurePolicy = selectedPet.policies
      .find((policy) => policy.Status === POLICY_STATUS.future);

    return futurePolicy?.Number;
  };

  const breadCrumbData = () => {
    const { policy } = props;

    const data = [
      {
        id: MY_ACCOUNT.landing,
        name: t('breadCrumbNotification.myAccount'),
      },
      {
        extraData: '',
        id: MY_ACCOUNT.billingAndPayments,
        name: t('breadCrumbNotification.billingAndPayments'),
      },
      {
        extraData: {
          marketingChannelId: MARKETING_CHANNEL_ID.figo,
          policyNumber: policy?.Number || '',
        },
        id: MY_ACCOUNT.billingAndPaymentDetails,
        name: t('breadCrumbNotification.policyNumber', {
          policyNumber: policy.Number || '',
        }),
      },
    ];

    return `data=${objectToBase64(data)}`;
  };

  const getNotificationAction = (action) => {
    const { policy } = props;

    if (action === PAYMENT_INFO_ACTION) {
      return (
        <Link
          className="Policy-alert-info-red-link"
          to={`/myAccount/Policy%20${policy.Number}/8?${breadCrumbData()}`}
        >
          {t('updateBillingInfo')}
        </Link>
      );
    }

    if (action === CLICK_HERE_RENEWAL) {
      return (
        <Link
          className="Policy-alert-info-link"
          to={`${CHANGE_COVERAGE_WEB}/${policy.Number}`}
        >
          {t('clickHere')}
        </Link>
      );
    }

    if (action === CLICK_HERE_ROLLOVER) {
      return (
        <Link
          className="Policy-alert-info-link"
          to={`${CHANGE_COVERAGE_WEB}/${getFuturePolicy()}`}
        >
          {t('clickHere')}
        </Link>
      );
    }

    return (
      <>
        <span className="Policy-alert-description">
          {t('questions')}&nbsp;
        </span>

        <a
          className="Policy-alert-info-link"
          href="https://figopetinsurance.com/contact-us-figo-pet-insurance"
          rel="noopener noreferrer"
          target="_blank"
        >
          {t('contactUs')}
        </a>
      </>
    );
  };

  const renderMessage = ({ action, message }) => {
    const clickHereText = ' {{click_here}}.';
    const notificationMessage = action === CLICK_HERE_RENEWAL
      || action === CLICK_HERE_ROLLOVER
      ? message.replace(clickHereText, '')
      : message;

    const highlightedMessage = highlightPrice(notificationMessage);

    return (
      <>
        {highlightedMessage}&nbsp;
        {getNotificationAction(action)}
      </>
    );
  };

  const renderPolicyNotifications = () => {
    if (store.policyNotifications
      && Array.isArray(store.policyNotifications)
      && store.policyNotifications.length) {
      const notifications = store.policyNotifications
        .map((notification, index) => {
          const { Action, Message, Title } = notification;

          return (
            <div
              key={`${Action}${Title.replace(/\s/g, '')}`}
              className={'Policy-renewal-info-container '
                + `${Action === PAYMENT_INFO_ACTION
                  ? 'Policy-renewal-container-red' : ''}
                ${index >= 1 ? ' Policy-alerts-separation' : ''}`}
            >
              <img
                alt="policy alert"
                className="Policy-alert-icon"
                src={Action === PAYMENT_INFO_ACTION
                  ? policyOrangeAlertIcon : policyInfoIcon}
              />

              <div className="Policy-alert-content">
                <span className="Policy-alert-info-title">
                  {Title}
                </span>

                <div className="Policy-alert-description-container">
                  <span className="Policy-alert-info-description">
                    {renderMessage({ action: Action, message: Message })}
                  </span>
                </div>
              </div>
            </div>
          );
        });

      return (
        <div className="Policy-alerts-container">
          {notifications}
        </div>
      );
    }

    return null;
  };

  return renderPolicyNotifications();
};

export default PolicyNotifications;
