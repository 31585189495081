import React from 'react';
import checkedIcon from '../../../../assets/radio-checked.png';
import uncheckedIcon from '../../../../assets/radio-unchecked.png';

import './ClaimSelector.css';

const ClaimSelectorItem = ({
  checked = false,
  label = '',
  id = 0,
  onItemPress = () => { },
}) => (
  <div className="File-claimbot-claim-selector-item-container">
    <label
      className="File-claimbot-claim-selector-item-label"
      htmlFor={`rb-claim-type-item-${id}`}
    >
      <input
        checked={checked}
        id={`rb-claim-type-item-${id}`}
        onChange={onItemPress}
        type="radio"
      />

      <img
        alt=""
        src={checked ? checkedIcon : uncheckedIcon}
      />

      {label}
    </label>
  </div>
);

const ClaimSelector = ({
  claimTypes = [],
  claimTypeSelected = null,
  onSelectClaimType = () => { },
}) => {
  const title = 'Select a Claim Type';

  const onItemPress = (claim) => () => {
    onSelectClaimType(claim);
  };

  return (
    <div className="File-claimbot-claim-selector-container">
      <h3>{title}</h3>

      <div className="File-claimbot-claim-selector-options-container">
        {claimTypes.map((claim) => (
          <ClaimSelectorItem
            key={String(`${claim.id} ${claim.label}`)}
            checked={claim.id === claimTypeSelected?.id}
            id={claim.id}
            label={claim.label}
            onItemPress={onItemPress(claim)}
          />
        ))}
      </div>
    </div>
  );
};

export { ClaimSelector };
