import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  lostPetTagChanged,
  searchPetTag,
  searchPetTagReset,
} from '../../actions';

import {
  CONTACT_FIGO_URL,
  LEARN_MORE_URL,
} from '../../constants';

import LoadingButton from '../common/LoadingButton';
import HeaderCommon from '../common/HeaderCommon';
import { FooterFoundLostPet } from './FooterFoundLostPet';
import { InputCapsule } from '../common/inputFiles/InputCapsule';
import { FoundLostPetPetInformation } from './FoundLostPetPetInformation';
import { FoundLostPetFamilyRow } from './FoundLostPetFamilyRow';
import { FoundLostPetContactForm } from './FoundLostPetContactForm';

import { useLogoutAndRedirection } from '../../hooks/useLogoutAndRedirection';

import './FoundLostPet.css';

const FoundLostPet = () => {
  const store = useSelector(({ foundPetLost }) => (foundPetLost), shallowEqual);
  const {
    loadingLostPetData,
    lostPetData,
    lostPetDataError,
    lostPetTag,
    sendMessageAttempted,
    sendMessageSuccess,
  } = store;
  const dispatch = useDispatch();
  const [isContactPetFamilyOpen, setContactPetFamilyOpen] = useState(false);
  const [petTagError, setPetTagError] = useState('');
  const { t } = useTranslation('foundLostPet');

  useLogoutAndRedirection();

  useEffect(() => () => {
    dispatch(searchPetTagReset());
  }, []);

  const notFoundTagError = React.useMemo(() => (
    lostPetDataError?.length ? t('petTagError') : ''), [lostPetDataError]);

  function handlePetTagChanged(event) {
    const { target: { value } } = event;
    dispatch(lostPetTagChanged(value));
    if (value) {
      setPetTagError('');
    }
  }

  function handleSearchPetSubmit(event) {
    event.preventDefault();

    if (!lostPetTag) {
      setPetTagError(t('requiredField'));
      return;
    }

    setPetTagError('');
    dispatch(searchPetTag({ petTag: lostPetTag }));
  }

  function handleTextMyFamilyClick() {
    setContactPetFamilyOpen(true);
  }

  const renderContent = () => {
    const petHasBeenFound = !!lostPetData;
    const messageSentSuccessfully = sendMessageAttempted && sendMessageSuccess;

    if (petHasBeenFound) {
      const petName = lostPetData.PetName;

      if (messageSentSuccessfully) {
        return (
          <div className="Figo-lost-pet-content-container">
            <FoundLostPetFamilyRow />

            <span className="Figo-lost-pet-found-title">{t('thankYou')}</span>

            <p className="Figo-lost-pet-gratitude">
              {t('gratitude', {
                interpolation: { escapeValue: false },
                petName,
              })}

              <a
                className="Figo-lost-pet-contact"
                href={CONTACT_FIGO_URL}
                target="blank"
              >
                {t('contactFigo')}.
              </a>
            </p>

            <a
              className={'btn btn-info Figo-lost-pet-found-rounded-button-base '
                + ' Figo-lost-pet-learn-more'}
              href={LEARN_MORE_URL}
            >
              {t('learnMore')}
            </a>
          </div>
        );
      }

      if (isContactPetFamilyOpen) {
        return (
          <div className="Figo-lost-pet-content-container">
            <FoundLostPetFamilyRow />

            <FoundLostPetContactForm />
          </div>
        );
      }

      return (
        <div className="Figo-lost-pet-content-container">
          <FoundLostPetFamilyRow />

          <h2 className="Figo-lost-pet-found-title">
            {t('youFoundMe')}
          </h2>

          <FoundLostPetPetInformation columnDesign />

          <LoadingButton
            className={'Figo-lost-pet-found-rounded-button-base '
              + 'Figo-lost-pet-found-pet-text-my-family-button'}
            onClick={handleTextMyFamilyClick}
            text={t('textMyFamily')}
            textStyle="Figo-lost-pet-found-pet-text-my-family-text"
          />
        </div>
      );
    }

    return (
      <div className="Figo-lost-pet-content-container">
        <FoundLostPetFamilyRow />

        <div className="Figo-lost-pet-form-container">
          <span className="Found-lost-pet-content-title">
            {t('foundLostPet')}
          </span>

          <span className="Found-lost-pet-content-subtitle">
            {t('foundLostPetContent')}
          </span>

          <form
            className="Found-lost-pet-inputs-container"
            onSubmit={handleSearchPetSubmit}
          >
            <InputCapsule
              customStyleClass="Found-lost-pet-input-tag"
              error={notFoundTagError || petTagError}
              maxLength={14}
              onChange={handlePetTagChanged}
              placeholder={t('enterTagNumberPlaceholder')}
              value={lostPetTag}
            />

            <LoadingButton
              className={'Figo-lost-pet-found-rounded-button-base '
                + 'Found-lost-pet-button-search'}
              loading={loadingLostPetData}
              onClick={handleSearchPetSubmit}
              text={t('searchTag')}
            />
          </form>
        </div>
      </div>
    );
  };

  return (
    <div className="Found-lost-pet-main-container">
      <HeaderCommon />

      {renderContent()}

      <FooterFoundLostPet />
    </div>
  );
};

export { FoundLostPet };
