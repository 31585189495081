import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { RecentFileIcon } from './RecentFileIcon';
import { InboxAndDocsMenu } from '../InboxAndDocsMenu';

import { getNullSafeDate } from '../../../services/utils';
import { useResponsive } from '../../../hooks/useResponsive';

import { filePermissionsLoaded } from '../../../actions';

import { PERMISSIONS_INBOX_DOCS } from '../../../constants';

import './RecentFile.css';

const RecentFile = ({
  dateAdded = '',
  dateFormat = 'MMM DD, YYYY',
  fileExtension = '',
  fileName = '',
  fileInfo = { IsReadOnly: true, Metadata: { Url: '' }, Name: '' },
  goToFolder = () => { },
  id,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('inboxAndDocs');
  const dateFormated = getNullSafeDate(dateAdded, dateFormat);
  const { isTablet } = useResponsive();

  // used on going to file (view file list, as opening from a folder)
  const filePermissionsSiblingFiles = fileInfo.FilePermissions;

  // used on this component
  const filePermissionsFixed = [
    PERMISSIONS_INBOX_DOCS.download,
    PERMISSIONS_INBOX_DOCS.viewinfolder,
  ];

  function handleViewInFolderClick() {
    dispatch(filePermissionsLoaded(filePermissionsSiblingFiles));
    goToFolder();
  }

  return (
    <div className="Recent-file-container">
      <RecentFileIcon
        alt="file extension icon"
        className="Recent-file-extension-icon"
        fileExtension={fileExtension}
      />

      <div className="Recent-file-name-container">
        <span className="Recent-file-name">{fileName}</span>

        {isTablet ? (
          <div className="Recent-file-date-container">
            <span className="Recent-file-added-on">
              {t('recentActivity.addedOn').replace('{dateFormated}', '')}
            </span>

            <span className="Recent-file-date-formated">
              {dateFormated.toUpperCase()}
            </span>
          </div>
        ) : (
          <span className="Recent-file-date">
            {t('recentActivity.addedOn').replace(
              '{dateFormated}',
              dateFormated.toUpperCase(),
            )}
          </span>
        )}
      </div>

      <div className="Recent-file-dots-container">
        <InboxAndDocsMenu
          filePermissions={filePermissionsFixed}
          goToFolder={handleViewInFolderClick}
          isFolder={false}
          itemId={id}
          itemInfo={fileInfo}
        />
      </div>
    </div>
  );
};

export { RecentFile };
