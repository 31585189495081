import React from 'react';
import { useTranslation } from 'react-i18next';

import { CoveragePolicyStatus } from './CoveragePolicyStatus';
import { CoverageFigoInfoFee } from './CoverageFigoInfoFee';
import { CoverageFigoProgressBar } from './CoverageFigoProgressBar';
import Shimmer from '../../common/Shimmer';
import { CoverageInfoFee } from './CoverageInfoFee';

import { MULTIBRAND_POLICY_STATUS, POLICY_STATUS } from '../../../constants';
import { MARKETING_CHANNEL_ID } from '../../../theme/theme.constants';

import { CoverageGroupInfoFee } from './CoverageGroupInfoFee';
import { ViewMoreButton } from '../ViewMoreButton';
import { isBasicOrcaPolicy } from './changeCoverage.utils';

import './css/CoverageOverview.css';

const CoverageOverview = ({
  marketingChanneld = MARKETING_CHANNEL_ID.figo,
  effectiveDate = '',
  isCancelled = false,
  loading = false,
  loadingOverview = false,
  policies = [],
  selectedPolicy,
  onOpenIframe = () => { },
}) => {
  const { t } = useTranslation('insuranceScreen');
  const {
    StatusDisplay = '',
  } = selectedPolicy;
  const status = POLICY_STATUS[StatusDisplay?.toLowerCase()];

  function renderShimmer() {
    return <Shimmer className="Coverage-overview-title-shimmer" visible />;
  }

  const handleChangeCoverage = () => {
    onOpenIframe();
  };

  function renderLeftSide() {
    switch (marketingChanneld) {
      case MARKETING_CHANNEL_ID.ppiOnePack:
        return (
          <CoverageGroupInfoFee
            company={marketingChanneld}
            effectiveDate={effectiveDate}
            isCancelled={isCancelled}
            selectedPolicy={selectedPolicy}
          />
        );
      case MARKETING_CHANNEL_ID.akc:
      case MARKETING_CHANNEL_ID.ppi:
      case MARKETING_CHANNEL_ID.spot:
        return (
          <CoverageInfoFee
            company={marketingChanneld}
            effectiveDate={effectiveDate}
            isCancelled={isCancelled}
            selectedPolicy={selectedPolicy}
          />
        );
      case MARKETING_CHANNEL_ID.figo:
      default:
        return (
          <CoverageFigoInfoFee
            effectiveDate={effectiveDate}
            isCancelled={isCancelled}
            selectedPolicy={selectedPolicy}
          />
        );
    }
  }

  function renderRightSide() {
    if (marketingChanneld !== MARKETING_CHANNEL_ID.figo) {
      return null;
    }

    return (
      <div className="Coverage-overview-right-info-side">
        <CoverageFigoProgressBar
          loadingOverview={loadingOverview}
          policies={policies}
          selectedPolicy={selectedPolicy}
        />
      </div>
    );
  }

  function render30DayPolicy() {
    if (marketingChanneld !== MARKETING_CHANNEL_ID.akc
      || StatusDisplay === POLICY_STATUS.cancelled) {
      return null;
    }

    return (
      <div className="Coverage-overview-30-day-policy-container">
        <span>{t('thirtyDayPolicy')}</span>
      </div>
    );
  }

  function renderChangeCoverage() {
    if (marketingChanneld === MARKETING_CHANNEL_ID.figo
      || marketingChanneld === MARKETING_CHANNEL_ID.ppiOnePack
      || StatusDisplay === MULTIBRAND_POLICY_STATUS.cancelled
      || StatusDisplay === MULTIBRAND_POLICY_STATUS.past) {
      return null;
    }

    if (isBasicOrcaPolicy(selectedPolicy?.IncidentLimit)) {
      return null;
    }

    return (
      <ViewMoreButton
        className="Coverage-overview-change-coverage"
        onClick={handleChangeCoverage}
        text={t('changeCoverage')}
      />
    );
  }

  if (loading) {
    return renderShimmer();
  }

  return (
    <>
      <div className="Coverage-overview-title-container">
        <h2>{t('coverageOverview')}</h2>

        <CoveragePolicyStatus
          label={t(`policyStatus-${status}`)}
          status={status}
        />

        {render30DayPolicy()}

        {renderChangeCoverage()}
      </div>

      <div className="Coverage-overview-section-container">
        <div className="Coverage-overview-left-info-side">
          {renderLeftSide()}
        </div>

        {renderRightSide()}
      </div>
    </>
  );
};

export { CoverageOverview };
