export const EV1 = ({ source, policyBrand = '', pms = '' }) => ({
  eventName: 'view insurance',
  params: { pms, 'policy brand': policyBrand, source },
});

export const EV2 = ({ source }) => ({
  eventName: 'view claims',
  params: { source },
});

export const EV3 = () => ({
  eventName: 'start new claim',
});

export const EV4 = ({
  claimData,
  claimDescription,
  dateOfLoss,
  lastBotText,
  lastInputFromUser,
  policyNumber,
  policyBrand,
}) => {
  const params = {
    'claim data': claimData,
    'claim description': claimDescription,
    'date of loss': dateOfLoss,
    'last bot text': lastBotText,
    'last input from user': lastInputFromUser,
    'policy brand': policyBrand,
    'policy number': policyNumber,
  };

  return {
    eventName: 'abandon claim',
    params,
  };
};

export const EV5 = ({
  claimAmount,
  claimData,
  claimDescription,
  claimResult,
  dateOfLoss,
  policyBrand,
  policyNumber,
  timeSpentBeforeSubmit,
  pms,
}) => {
  const params = {
    'claim amount': claimAmount,
    'claim data': claimData,
    'claim description': claimDescription,
    'claim result': claimResult,
    'date of loss': dateOfLoss,
    pms,
    'policy brand': policyBrand,
    'policy number': policyNumber,
    'time spent before submit': timeSpentBeforeSubmit,
  };

  return {
    eventName: 'submit claim',
    params,
  };
};

export const EV6 = ({
  claimNumber,
  claimStatusLabel,
  petName,
  paidAmount,
  pms,
  policyBrand,
  policyNumber,
}) => {
  const params = {
    'claim number': claimNumber,
    'claim status': claimStatusLabel,
    'paid amount': paidAmount,
    'pet name': petName,
    pms,
    'policy brand': policyBrand,
    'policy number': policyNumber,
  };

  return {
    eventName: 'view claim detail',
    params,
  };
};

export const EV7 = ({ claimNumber, numberOfAttachments, policyBrand }) => ({
  eventName: 'view claim attachments',
  params: {
    'claim number': claimNumber,
    'number of attachments': numberOfAttachments,
    'policy brand': policyBrand,
  },
});

export const EV8 = ({ claimNumber, fileName, filePath, policyBrand }) => ({
  eventName: 'open claim attachments',
  params: {
    'claim number': claimNumber,
    'file name': fileName,
    'file path': filePath,
    'policy brand': policyBrand,
  },
});

export const EV9 = () => ({
  eventName: 'view dashboard',
});

export const EV10 = ({
  petDateOfBirth,
  breedId,
  breedName,
  petDescription,
  petId,
  petImageSelected,
  petName,
  petSpecies,
  petWeight,
  petGender,
}) => {
  const params = {
    'pet breed id': breedId,
    'pet breed name': breedName,
    'pet description': petDescription,
    'pet dob': petDateOfBirth,
    'pet gender': petGender,
    'pet id': petId,
    'pet image selected': petImageSelected,
    'pet name': petName,
    'pet species': petSpecies,
    'pet weight': petWeight,
  };

  return {
    eventName: 'add pet',
    params,
  };
};

export const EV11 = ({ petId }) => ({
  eventName: 'delete pet',
  params: { petId },
});

export const EV15 = ({
  customerEmail,
  customerGuid,
  customerName,
  followStatus,
  ownPet,
  petId,
  petOwnerId,
}) => {
  const params = {
    'customer guid': customerGuid,
    'own pet': ownPet,
    'target customer email': customerEmail || '',
    'target customer follow status': followStatus,
    'target customer name': customerName || '',
    'target pet id': petId,
    'target pet owner id': petOwnerId || '',
  };

  return {
    eventName: 'view pet profile',
    params,
  };
};

export const EV39 = ({ source, uploadSource }) => ({
  eventName: 'add file to inbox and docs',
  params: {
    source,
    'source of upload': uploadSource,
  },
});

export const EV40 = ({ fileName }) => ({
  eventName: 'open file',
  params: { 'file name': fileName },
});

export const EV53 = ({
  customerId,
  petId,
  policyBrand,
  source,
}) => ({
  eventName: 'start quote',
  params: {
    'customer id': customerId,
    'pet id': petId,
    'policy brand': policyBrand,
    source,
  },
});

export const MIXPANEL_LOGIN_EVENTS = {
  b2cStarted: 'login b2c flow started',
  b2cSuccess: 'login b2c success',
  error: 'login error',
  getApiTokens: 'login getting api\'s tokens',
  getPersonalInfo: 'login getting personalInfo',
  getPersonalInfoError: 'login getting personalInfo error',
  getPersonalInfoSuccess: 'login getting personalInfo success',
  getTerms: 'login getting terms',
  getTermsError: 'login getting terms error',
  getTermsSuccess: 'login getting terms success',
  invalidCustomerId: 'invalid petCloudCustomerId',
  registerB2C: 'login registerB2C',
  registerB2CError: 'login registerB2C error',
  registerB2CSuccess: 'login registerB2C success',
  sendLog: 'login log sent',
};

/**
 * Register an login error
 * @param {Object} param0 - event data
 * @param {String} params0.mixpanelEvent - Event name,
 *  see {@link MIXPANEL_LOGIN_EVENTS}
 * @param {String} email - Event email register
 * @param {String} error - Event error register
 */
export const EV_LOGIN = ({
  mixpanelEvent = '',
  email = '',
  error = '',
}) => ({
  eventName: mixpanelEvent,
  params: { email, error },
});
