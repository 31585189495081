import React from 'react';
import Form from 'react-bootstrap/Form';

const FigoSelect = ({
  className,
  controlId,
  custom,
  inputName,
  onChange,
  placeholder,
  renderOptionList,
  title,
  value,
}) => (
  <Form.Group controlId={controlId}>
    <Form.Label>{title}</Form.Label>

    <Form.Control
      as="select"
      className={className}
      custom={custom}
      name={inputName}
      onBlur={onChange}
      onChange={onChange}
      placeholder={placeholder}
      type="text"
      value={value}
    >
      {renderOptionList()}
    </Form.Control>
  </Form.Group>
);

export default FigoSelect;
