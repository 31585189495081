import React from 'react';
import { NavLink } from 'react-router-dom';

const SideMenuItem = ({
  Icon = null,
  label = '',
  onClick = () => { },
  ...props
}) => (
  <div className="Side-menu-button">
    <NavLink
      activeClassName="Selected-link"
      exact={props.exact}
      onClick={onClick}
      to={props.to}
    >
      <div className="Side-menu-button-container">
        <div className="Menu-icon-container">
          {Icon && <Icon className="Side-menu-icon" />}
        </div>

        <div className="Menu-button-text-container">
          <span className="Menu-button-text">{label}</span>
        </div>
      </div>
    </NavLink>
  </div>
);

export default SideMenuItem;
