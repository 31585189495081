import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  getAllBrandsData,
  updatePaymentMethod,
  loadPolicyDetails,
} from '../../../actions';

import { useResponsive } from '../../../hooks/useResponsive';

import BillingDetailsCard from './BillingDetailsCard';
import FigoLoadingOverlay from '../../common/FigoLoadingOverlay';
import PaymentMethodCard from './PaymentMethodCard';
import {
  EditPaymentMultiPolicyModal,
} from '../../settings/EditPaymentMultiPolicyModal';

import './css/BillingInfoScreen.css';
import { useWebViewReskin } from '../../../hooks';

const BillingInfoScreen = ({ policyNumber }) => {
  const { t } = useTranslation('myAccount');
  const dispatch = useDispatch();
  const useMobileReskin = useWebViewReskin();
  const { isMobile } = useResponsive();
  const store = useSelector(({ billingPayments, policies }) => ({
    billingPayments,
    policies,
  }), shallowEqual);

  const {
    billingPayments: {
      loadingBillingInfo,
      updatingPaymentMethod,
    },
  } = store;
  const { allBrandsData, loadingAllBrandsData } = store.billingPayments;
  const isLoading = loadingBillingInfo
    || updatingPaymentMethod || loadingAllBrandsData;

  const getPolicyInformation = React.useMemo(() => {
    if (loadingAllBrandsData) {
      return {};
    }

    return allBrandsData.find((policy) => policy.PolicyNumber === policyNumber)
      || allBrandsData[0];
  }, [allBrandsData, loadingAllBrandsData]);

  useEffect(() => {
    if (policyNumber) {
      dispatch(getAllBrandsData());
      dispatch(loadPolicyDetails(policyNumber));
    }
  }, [policyNumber]);

  function onUpdatePaymentMethod({
    paymentMethodData,
    policies,
    sameBillingAddress,
    userInformation,
  }) {
    dispatch(updatePaymentMethod({
      paymentMethodData: {
        ...paymentMethodData,
        policyNumber,
      },
      policies,
      sameBillingAddress,
      userInformation,
    }));
  }

  return (
    <div className="Billing-info-screen-container">
      {!useMobileReskin && isMobile ? (
        <h1 className="Billing-info-screen-title">
          {t('billingDetailsCard.title')}
        </h1>
      ) : null}

      <EditPaymentMultiPolicyModal
        hasPastPaymentDue={false}
        onUpdatePaymentMethod={onUpdatePaymentMethod}
        policyNumber={policyNumber}
      />

      <FigoLoadingOverlay visible={isLoading} />

      <BillingDetailsCard policyInformation={getPolicyInformation} />

      <h1
        className={useMobileReskin
          ? 'Billing-info-screen-title-responsive'
          : 'Billing-info-screen-title'}
      >
        {t('billingDetailsCard.paymentMethod')}
      </h1>

      <PaymentMethodCard
        policyInformation={getPolicyInformation}
        policyNumber={policyNumber}
      />
    </div>
  );
};

export default BillingInfoScreen;
