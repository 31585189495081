import React, { useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  deleteFiles,
  deleteFolder,
  deleteRestarted,
  moveFolderRestarted,
} from '../../../actions';
import { GenericModal } from '../../common/petCloud/GenericModal';

const DeleteModal = ({
  isFolder = false,
  isTrashScreen = false,
  itemExtension = '',
  itemName = '',
  onClose = () => { },
  onSuccess = () => { },
  selectedFolderIds = [],
  selectedItemsIds = [],
  show = false,
}) => {
  const { t } = useTranslation('inboxAndDocs');
  const dispatch = useDispatch();
  const store = useSelector(({ inboxAndDocs }) => inboxAndDocs, shallowEqual);
  const {
    folderDeleteLoading,
    itemDeletedSuccesfully,
    itemDeleteLoading,
    loading,
    moveFolder: movingFolder,
  } = store;

  const isLoading = itemDeleteLoading
    || folderDeleteLoading
    || loading
    || movingFolder.loading;

  const isSuccess = itemDeletedSuccesfully || movingFolder.success;

  function handleOnSuccess() {
    dispatch(deleteRestarted());
    dispatch(moveFolderRestarted());
    onSuccess();
    onClose();
  }

  async function handleOnConfirm() {
    if (selectedFolderIds.length > 0) {
      dispatch(deleteFolder({ folderId: selectedFolderIds[0] }));
    }

    if (selectedItemsIds.length > 0) {
      dispatch(deleteFiles({ fileIds: selectedItemsIds }));
    }
  }

  const deleteTitle = useMemo(() => {
    const count = selectedItemsIds.length;

    if (isFolder) {
      const titleFolder = isTrashScreen
        ? 'titleDeleteFolderPermanently' : 'titleDeleteFolder';

      return t(`deleteInboxModal.${titleFolder}`);
    }

    const title = isTrashScreen ? 'titleDeletePermanently' : 'titleDeleteFile';

    return t(`deleteInboxModal.${title}`, { count });
  }, [isFolder, selectedItemsIds, isTrashScreen]);

  const deleteMessage = useMemo(() => {
    const count = selectedItemsIds.length;

    if (isTrashScreen) {
      return t('deleteInboxModal.messageFilePermanently', { count });
    }

    if (isFolder) {
      return t('deleteInboxModal.messageFolder', { name: itemName });
    }

    return t('deleteInboxModal.messageFile', {
      count,
      name: itemName,
    });
  }, [isFolder, isTrashScreen, itemExtension, itemName, selectedItemsIds]);

  return (
    <GenericModal
      description={deleteMessage}
      handleOutClick={onClose}
      isDeleteAction
      loadingPrimary={isLoading}
      onPrimaryClick={handleOnConfirm}
      onPrimarySuccess={handleOnSuccess}
      onSecondaryClick={onClose}
      primaryButtonText={t('deleteInboxModal.delete')}
      secondaryButtonText={t('deleteInboxModal.cancel')}
      show={show}
      successPrimary={isSuccess}
      title={deleteTitle}
    />
  );
};

export { DeleteModal };
