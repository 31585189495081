import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MARKETING_CHANNEL_ID } from '../../../theme/theme.constants';
import { getThemeLogoById } from '../../../theme/theme.utils';
import { POLICY_STATUS } from '../../../constants';

import { CoveragePolicyStatus }
from '../../insurance/coverageOverview/CoveragePolicyStatus';

import './css/ReimbursementPolicyDetails.css';

const ReimbursementPolicyDetails = ({
  policyNumber = '',
  policyPets = '',
  policyStatus = '',
  policyTerm = '',
}) => {
  const { t } = useTranslation('myAccount');

  const figoLogo = useMemo(() => {
    const theme = getThemeLogoById(MARKETING_CHANNEL_ID.figo);
    return theme.dark;
  }, []);

  const policyStatusId = useMemo(() => POLICY_STATUS[policyStatus
    .toLowerCase()], [POLICY_STATUS, policyStatus]);

  return (
    <div className="Reimbursement-policy-details-container">
      <h1>{t('reimbursementSection.policyDetails')}</h1>

      <div className="Reimbursement-policy-details-card">
        <img alt="" src={figoLogo} />

        <div className="Reimbursement-policy-info-wrapper">
          <div className="Reimbursement-policy-info-container">
            <span className="Reimbursement-policy-info-title">
              {t('reimbursementSection.policyNumber')}
            </span>

            <span className="Reimbursement-policy-info-value">
              {policyNumber}
            </span>
          </div>

          <div className="Reimbursement-policy-info-container">
            <span className="Reimbursement-policy-info-title">
              {t('reimbursementSection.policyTerm')}
            </span>

            <span className="Reimbursement-policy-info-value">
              {policyTerm}
            </span>
          </div>

          <div className="Reimbursement-policy-info-container">
            <span className="Reimbursement-policy-info-title">
              {t('reimbursementSection.petsOnThisPolicy')}
            </span>

            <span className="Reimbursement-policy-info-value">
              {policyPets}
            </span>
          </div>
        </div>

        <CoveragePolicyStatus
          label={policyStatus}
          status={policyStatusId}
        />
      </div>
    </div>
  );
};

export { ReimbursementPolicyDetails };
