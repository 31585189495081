import React, { useEffect, useRef, useState } from 'react';

const HtmlContent = ({
  classNameContainer = '',
  content = '',
  elementType = 'div',
}) => {
  const [contentLoaded, setContentLoaded] = useState();
  const ref = useRef();

  useEffect(() => {
    if (ref.current && !contentLoaded && content) {
      const htmlContent = content;
      const htmlContainer = document.createElement(elementType);

      if (classNameContainer.trim()) {
        htmlContainer.classList.add(classNameContainer);
      }

      htmlContainer.innerHTML = htmlContent;
      ref.current.appendChild(htmlContainer);
      setContentLoaded(true);
    }
  }, [classNameContainer, content, contentLoaded, elementType, ref]);

  return (
    <div ref={ref} />
  );
};

export { HtmlContent };
