import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';

import { PET_TYPE } from '../../constants';
import { ReactComponent as CatIcon } from '../../assets/sideMenu/cat.svg';
import { ReactComponent as DogIcon } from '../../assets/sideMenu/dog.svg';
import { ReactComponent as AddIcon } from '../../assets/sideMenu/add.svg';
import { MY_PETS } from '../navigation/routes.constants';

import SideMenuItem from './SideMenuItem';
import { SideMenuActionButton } from './SideMenuActionButton';
import { AddAPetModal } from '../myPets/AddAPetModal';

import './YourPetsSection.css';

export const YourPetsSection = ({ onMenuItemPress = () => { } }) => {
  const [showAddPetModal, setShowAddPetModal] = useState(false);
  const { t } = useTranslation('sideMenu');
  const pets = useSelector(({ policies }) => policies.allPets, shallowEqual);

  const toggle = useCallback(() => {
    setShowAddPetModal((prevShow) => !prevShow);
  }, []);

  return (
    <nav aria-labelledby="your-pets-nav">
      <div className="Side-menu-section-title" id="your-pets-nav">
        {t('yourPets')}
      </div>

      <ul className="Your-pets-section-list">
        {pets.map((pet) => (
          <li key={pet.PetId}>
            <SideMenuItem
              Icon={pet.PetType === PET_TYPE.DOG ? DogIcon : CatIcon}
              label={pet.Name}
              onClick={onMenuItemPress}
              to={`${MY_PETS}/${pet.PetId}`}
            />
          </li>
        ))}
      </ul>

      <SideMenuActionButton
        Icon={AddIcon}
        label={t('addPet')}
        onClick={toggle}
      />

      <AddAPetModal
        modalAddPetVisible={showAddPetModal}
        onCloseModalAddPet={toggle}
      />
    </nav>
  );
};
