import React from 'react';

import './ScheduleRow.css';

const ScheduleRow = ({
  classContainer = '',
  leftText = '',
  rightText = '',
}) => (
  <div className={`Contact-us-schedule-row ${classContainer}`}>
    <span className="Contact-us-schedule-text Margin-right-33">
      {leftText}
    </span>

    <span className="Contact-us-schedule-text">
      {rightText}
    </span>
  </div>
);

export { ScheduleRow };
