import React from 'react';
import { useReimbursementScreen } from './hooks/useReimbursementScreen';

import { ReimbursementPolicyDetails } from './ReimbursementPolicyDetails';
import { ReimbursementMethodSection } from './ReimbursementMethodSection';
import { ReimbursementMultibrandList }
from './multibrandList/ReimbursementMultibrandList';

import FigoLottie from '../../common/FigoLottie';
import loadingSpinner from '../../../assets/lottieFiles/loading-blue.json';

import { MY_ACCOUNT } from '../../../constants';

import './css/ReimbursementScreen.css';
import { MARKETING_CHANNEL_ID } from '../../../theme/theme.constants';
import { ReimbursementDetailsFrame } from './reimbursementDetails';

const ReimbursementScreen = ({ breadCrumbs, currentFolderId }) => {
  const {
    allBrandsData,
    loadingAllBrandsData,
    onClickReimbursementCard,
    reimbursementOption,
  } = useReimbursementScreen({ breadCrumbs });
  const isReimDetails = currentFolderId === MY_ACCOUNT.reimbursementDetails;
  const currentPolicy = breadCrumbs.navData.find((data) => data.id
    === currentFolderId).extraData.policyInfo;

  if (loadingAllBrandsData) {
    return (
      <div className="Reimbursement-screen-shimmer-container">
        <FigoLottie
          animationData={loadingSpinner}
          height={50}
          width={50}
        />
      </div>
    );
  }

  function renderContent() {
    if (isReimDetails) {
      if (currentPolicy?.MarketingChannelId
        && currentPolicy.MarketingChannelId
        !== MARKETING_CHANNEL_ID.figo) {
        return (
          <ReimbursementDetailsFrame
            petId={currentPolicy?.policyInfo?.Pets?.[0]?.PetId}
            policy={{
              marketingChannelId: currentPolicy?.PolicyData?.MarketChannelId,
              policyId: currentPolicy?.PolicyData?.Id,
              policyNumber: currentPolicy?.PolicyData?.PolicyDisplayNumber,
            }}
          />
        );
      }

      return (
        <div className="Reimbursement-screen-container">
          <ReimbursementPolicyDetails
            policyNumber={currentPolicy.PolicyNumber}
            policyPets={currentPolicy.Pets.map((pet) => pet.PetName).join(', ')}
            policyStatus={currentPolicy.PolicyStatus}
            policyTerm={currentPolicy.PolicyTerm}
          />

          <ReimbursementMethodSection
            reimbursementOption={reimbursementOption}
          />
        </div>
      );
    }

    return (
      <ReimbursementMultibrandList
        allBrandsData={allBrandsData}
        onClickCard={onClickReimbursementCard}
      />
    );
  }

  return renderContent();
};

export { ReimbursementScreen };
