import React from 'react';
import { StepperMultibrand } from './StepperMultibrand';
import LoadingButton from '../../../common/LoadingButton';

import './ClaimbotStepper.css';

const ClaimbotStepper = ({
  backButtonLabel = '',
  currentStepNumber = 1,
  goBackDisabled = false,
  goForwardisabled = false,
  submitted = false,
  loading = false,
  nextButtonLabel = '',
  onNextStepClick = () => { },
  onPreviousStepClick = () => { },
  currentStepName = '',
  numberOfSteps = 0,
}) => (
  <div className="Claimbot-stepper">
    <div className="Claimbot-stepper-row">
      <h2>{currentStepName}</h2>

      <div className="Claimbot-stepper-buttons">
        <button
          className={goBackDisabled
            ? 'Claimbot-stepper-back-button-disabled'
            : 'Claimbot-stepper-back-button'}
          disabled={goBackDisabled}
          onClick={onPreviousStepClick}
          type="button"
        >
          {backButtonLabel}
        </button>

        <LoadingButton
          aria-disabled={goForwardisabled || loading}
          className={`${goForwardisabled
            ? 'Claimbot-stepper-next-button-container-disabled'
            : 'Claimbot-stepper-next-button-container'} ${
              loading ? 'Claimbot-stepper-next-button-loading' : ''}`}
          disabled={goForwardisabled || loading}
          loading={loading}
          onClick={onNextStepClick}
          success={submitted}
          text={nextButtonLabel}
          textStyle="Claimbot-stepper-next-button"
        />
      </div>
    </div>

    <StepperMultibrand
      currentStepNumber={currentStepNumber}
      numberOfSteps={numberOfSteps}
    />
  </div>
);

export { ClaimbotStepper };
