export const POWERUP_TYPE = 5;

export const POWER_UPS_RELATION = {
  DISMINISHING_DEDUCTIBLE: 100004,
  EXTRA_CARE_PACK_PSM_ID: 70427,
  PETPROTECT_COPAY: 100005,
  VETERINARY_EXAM_FEES_PSM_ID: 70422,
  WELLNESS_DENTAL_PSM_ID: 70430,
  WELLNESS_PSM_ID: 70428,
};

export const PRODUCT_VALUES = {
  FPI_VALUES: [11, 12, 13, 14, 15, 16],
  IHC_VALUES: [1, 2, 3],
};

export const WELLNESS_MODAL_ID = 2;

export const BENEFIT_FEE_TYPE = {
  dynamic: 3,
  fixedFee: 2,
  ftpFactor: 1,
  none: 0,
};

export const WELLNESS_COVERAGE_LIMIT_FPI = {
  BASIC: [41, 42],
  PLUS: [43, 44],
};

export const WELLNESS_MULTIMODAL_ID = {
  BASIC: 15,
  PLUS: 16,
};
