import React from 'react';
import { useTranslation } from 'react-i18next';

import { PetCloudNameEdit } from './petCloudAccountEdit/PetCloudNameEdit';
import { PetCloudLoginEmailEdit }
from './petCloudAccountEdit/PetCloudLoginEmailEdit';
import { PetCloudEmailEdit } from './petCloudAccountEdit/PetCloudEmailEdit';
import { PetCloudPasswordEdit }
from './petCloudAccountEdit/PetCloudPasswordEdit';

import './css/PetCloudAccountRow.css';

const PetCloudAccountRow = ({
  actionLabel = '',
  initialValue = '',
  isEditing = false,
  isEditingAny = false,
  isProfilePicture = false,
  label = '',
  onClickActionButton = () => { },
  onClickCancel = () => { },
  profilePicture = '',
}) => {
  const { t } = useTranslation('myAccount');

  function renderPicture() {
    if (profilePicture) {
      return (
        <img
          alt=""
          className="Pet-cloud-account-picture"
          src={profilePicture}
        />
      );
    }

    return <span className="Pet-cloud-account-initials">{initialValue}</span>;
  }

  function renderValueContainer() {
    if (isProfilePicture) {
      return (
        <button
          className="Pet-cloud-account-picture-button"
          type="button"
        >
          {renderPicture()}
        </button>
      );
    }

    if (isEditing) {
      switch (label) {
        case t('petCloudSection.name'):
          return (
            <PetCloudNameEdit
              initialValue={initialValue}
              onClickCancel={onClickCancel}
            />
          );
        case t('petCloudSection.email'):
          return (
            <PetCloudLoginEmailEdit
              initialValue={initialValue}
              onClickCancel={onClickCancel}
            />
          );
        case t('petCloudSection.petCloudEmail'):
          return (
            <PetCloudEmailEdit
              initialValue={initialValue}
              onClickCancel={onClickCancel}
            />
          );
        case t('petCloudSection.password'):
          return (
            <PetCloudPasswordEdit
              onClickCancel={onClickCancel}
            />
          );

        default:
          return null;
      }
    }

    return <span className="Pet-cloud-account-value">{initialValue}</span>;
  }

  return (
    <div className="Pet-cloud-account-row-container">
      <div className="Pet-cloud-account-top-row-container">
        <h4>{label}</h4>

        <button
          className={`${!isEditing && isEditingAny ? 'Pet-cloud-edit-gray'
            : 'Pet-cloud-edit'}`}
          onClick={isEditing ? onClickCancel : onClickActionButton}
          type="button"
        >
          {isEditing ? t('petCloudSection.cancel') : actionLabel}
        </button>
      </div>

      {renderValueContainer()}
    </div>
  );
};

export { PetCloudAccountRow };
