import React from 'react';
import { doNothing } from '../../services/utils';
import './TripleDotMenu.css';

const TripleDotMenuOption = ({
  handleOnClickOption = () => { },
  itemId,
  option,
}) => {
  function handleClick(event) {
    event.preventDefault();
    event.stopPropagation();

    handleOnClickOption(option);
  }

  const { isDisabled } = option;

  return (
    <li
      aria-selected="false"
      className={isDisabled
        ? 'Dot-menu-item-container-disabled Flex'
        : 'Dot-menu-item-container Flex'}
      id={`dotMenuItem-${itemId}-${option.id}`}
      onClick={isDisabled ? doNothing : handleClick}
      onKeyPress={isDisabled ? doNothing : handleClick}
      role="option"
      tabIndex={-1}
    >
      <img alt="" src={option.icon} />

      <span>{option.text}</span>
    </li>
  );
};

export { TripleDotMenuOption };
