import { handleActions } from 'redux-actions';

const INITIAL_STATE = {
  checkingPassword: false,
  customerHasPassword: false,
  loadingResetPassword: false,
  loadingToken: false,
  passwordChecked: false,
  resetPasswordComplete: false,
  resetPasswordError: '',
  tokenValid: null,
};

export default handleActions({
  CHECK_PASSWORD_SETUP: (state) => ({
    ...state,
    checkingPassword: true,
    passwordChecked: false,
  }),
  DO_RESET_PASSWORD: (state) => ({ ...state, loadingResetPassword: true }),
  GET_NEW_PASSWORD_TOKEN: (state) => ({
    ...state,
    loadingToken: true,
  }),
  GET_NEW_PASSWORD_TOKEN_FAILED: (state) => ({
    ...state,
    loadingToken: false,
    tokenValid: null,
  }),
  NEW_PASSWORD_TOKEN_GENERATED: (state, { payload }) => ({
    ...state,
    loadingToken: false,
    tokenValid: payload,
  }),
  PASSWORD_SETUP_CHECKED: (state, { payload }) => ({
    ...state,
    checkingPassword: false,
    customerHasPassword: payload.customerHasPassword,
    passwordChecked: true,
  }),
  PASSWORD_SETUP_RESTARTED: (state) => ({
    ...state,
    loadingResetPassword: false,
    resetPasswordComplete: false,
    resetPasswordError: '',
    tokenValid: null,
  }),
  RESET_PASSWORD_FAILED: (state, { payload }) => ({
    ...state,
    loadingResetPassword: false,
    resetPasswordError: payload.Message,
  }),
  RESET_PASSWORD_SUCCESS: (state) => ({
    ...state,
    loadingResetPassword: false,
    resetPasswordComplete: true,
  }),
  RESET_PASSWORD_TOKEN_LOADED: (state, { payload }) => ({
    ...state,
    loadingToken: false,
    tokenValid: payload,
  }),
  SET_UP_NEW_PASSWORD: (state) => ({
    ...state,
    loadingResetPassword: true,
  }),
  SET_UP_NEW_PASSWORD_FAILED: (state, { payload }) => ({
    ...state,
    loadingResetPassword: false,
    resetPasswordError: payload,
  }),
  SET_UP_NEW_PASSWORD_SUCCESS: (state) => ({
    ...state,
    loadingResetPassword: false,
    resetPasswordComplete: true,
  }),
  VALID_RESET_PASSWORD_TOKEN: (state) => ({
    ...state,
    loadingToken: true,
  }),
}, INITIAL_STATE);
