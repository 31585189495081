import React from 'react';

import checkIcon from '../../../assets/check-onyx.svg';

import './ListItem.css';

const ListItem = ({
  item,
  liRef,
  onClick = () => { },
  onKeyDown = () => { },
  selected,
}) => (
  <li
    ref={liRef}
    aria-selected="false"
    className={'List-item-container'
      + `${selected ? ' List-item-selected' : ''}`}
    onClick={onClick}
    onKeyDown={onKeyDown}
    role="option"
    tabIndex={-1}
  >
    <span className={`List-item List-item-${item.level}`} tabIndex={-1}>
      {item.label}
    </span>

    {selected && (
      <img alt="" src={checkIcon} />
    )}
  </li>
);

export { ListItem };
