import React from 'react';

export const SideMenuActionButton = ({
  className = '',
  Icon = React.Fragment(),
  iconClassName = '',
  label = '',
  onClick = () => { },
}) => (
  <button
    className={`Side-menu-action-button ${className}`}
    onClick={onClick}
    type="button"
  >
    <Icon className={`Side-menu-action-button-icon ${iconClassName}`} />

    {label}
  </button>
);
