import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ModalContainer from '../../common/ModalContainer';
import LoadingActionButtonMyPets
from '../../common/petCloud/LoadingActionButtonMyPets';
import { ActionButton } from '../../common/petCloud/ActionButton';
import closeIcon from '../../../assets/close-button.svg';
import {
  addCustomToFavorites,
  vetCustomSavedCleared,
} from '../../../actions/myVets';
import { EXPLORE_PROVIDERS } from '../../../constants';
import { useCustomVetForm } from './hooks/useCustomVetForm';
import './MyPetsAddVetManuallyModal.css';
import { StatesList, AddVetInputContainer } from './AddVetManualComponents';

const MyPetsVetManualAdd = ({
  closeModal = () => { },
  handleSuccesCustomVetModal = () => { },
  onSavePrimaryVet = () => { },
  primaryVet = false,
  show = false,
}) => {
  const dispatch = useDispatch();
  const store = useSelector(({ myVets, personalInformation }) => ({
    myVets,
    personalInformation,
  }), shallowEqual);
  const { states } = store.personalInformation;
  const { isSavingCustomVet, vetCustomSaved } = store.myVets;
  const { t } = useTranslation('myPets');
  const [isFilled, setIsFilled] = useState(false);

  function clearSuccessLoading() {
    dispatch(vetCustomSavedCleared());
  }

  const {
    handleCityError,
    handleCityNameChange,
    handleCloseModal,
    handleStateError,
    handleStateNameChange,
    handleSuccess,
    handleVetCompanyError,
    handleVetCompanyNameChange,
    state,
  } = useCustomVetForm({
    clearSuccessLoading,
    closeModal,
    handleSuccesCustomVetModal,
  });

  const invalidForm = useMemo(() => !state.vetCompanyName
    || !state.cityName || !state.stateId,
  [state.vetCompanyName, state.cityName, state.stateId]);

  function handleNextClick() {
    if (invalidForm) {
      handleVetCompanyError();
      handleCityError();
      handleStateError();
      return;
    }
    setIsFilled(!isFilled);
  }

  function handleCancel() {
    setIsFilled(false);
    handleCloseModal();
  }

  useEffect(() => {
    if (vetCustomSaved) {
      handleCloseModal();
    }
  }, [vetCustomSaved]);

  function handleSaveClick() {
    if (invalidForm) {
      handleVetCompanyError();
      handleCityError();
      handleStateError();
      return;
    }

    if (primaryVet) {
      onSavePrimaryVet({
        City: state.cityName,
        isCustom: true,
        Name: state.vetCompanyName,
        StateId: state.stateId,
        StateName: state.stateName,
      });
    } else {
      dispatch(addCustomToFavorites({
        categoryId: 1,
        city: state.cityName,
        name: state.vetCompanyName,
        providerId: EXPLORE_PROVIDERS.manuallyAdded,
        stateId: state.stateId,
      }));
    }
  }

  return (
    <ModalContainer show={show}>
      <div className="My-pets-manual-add-container">
        <div className="My-pets-manual-add-title-container">
          <div className="My-pets-manual-add-title-box">
            <h2 className="My-pets-manual-add-title">
              {t('MyVets.addAVet')}
            </h2>

            <button
              className="My-pets-manual-add-close-btn"
              id="closeIconBtn"
              onClick={handleCancel}
              type="button"
            >
              <img alt="" className="Add-figo-tag-close-icon" src={closeIcon} />
            </button>
          </div>

          <h3 className="My-pets-manual-add-vet-modal-subtitle">
            {isFilled
              ? t('MyVets.checkInfo')
              : t('AddCustomVetModal.subtitle')}
          </h3>
        </div>

        {isFilled ? (
          <div className="My-pets-manual-add-check">
            <span className="My-pets-manual-add-vet-check-name">
              {state.vetCompanyName}
            </span>

            <div className="My-pets-manual-add-vet-check-state">
              <span className="My-pets-manual-add-location">
                {state.cityName},
              </span>

              <span className="My-pets-manual-add-location">
                {state.stateId}
              </span>
            </div>
          </div>
        ) : (
          <>
            <AddVetInputContainer
              errorMessage={state.vetCompanyError}
              inputTitle={t('AddCustomVetModal.vetPractice')}
            >
              <input
                className="My-pets-manual-add-input-name"
                onChange={handleVetCompanyNameChange}
                value={state.vetCompanyName}
              />
            </AddVetInputContainer>

            <div className="My-pets-manual-add-inputs-row">
              <AddVetInputContainer
                errorMessage={state.cityError}
                inputTitle={t('AddCustomVetModal.city')}
              >
                <input
                  className="My-pets-manual-add-input-city"
                  onChange={handleCityNameChange}
                  value={state.cityName}
                />
              </AddVetInputContainer>

              <AddVetInputContainer
                errorMessage={state.stateError}
                inputTitle={t('AddCustomVetModal.state')}
              >
                <select
                  className="My-pets-manual-add-select"
                  onChange={handleStateNameChange}
                  value={state.stateId || ''}
                >
                  <option disabled hidden value="">
                    {t('AddCustomVetModal.statePlaceholder')}
                  </option>

                  <StatesList states={states} />
                </select>
              </AddVetInputContainer>
            </div>
          </>
        )}

        <div className="My-pets-manual-add-buttons">
          <LoadingActionButtonMyPets
            loading={isSavingCustomVet}
            onClick={isFilled ? handleSaveClick : handleNextClick}
            onSuccessAnimationEnd={handleSuccess}
            success={vetCustomSaved}
            text={isFilled
              ? t('MyVets.addVetModal.add')
              : t('MyVets.addVetModal.next')}
            type="button"
          />

          <ActionButton
            className="Action-button My-pets-manual-add-button"
            isSecondaryButton
            onClick={handleCancel}
            title={t('AddCustomVetModal.cancel')}
            type="button"
          />
        </div>
      </div>
    </ModalContainer>
  );
};

export { MyPetsVetManualAdd };
