import React, { useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  restartClaimsDetails, getClaimByIdRequested,
} from '../../../actions';
import Shimmer from '../../common/Shimmer';
import { CLAIMS } from '../../navigation/routes.constants';
import { DetailsList } from './DetailsList';
import { ClaimsAttachments } from './ClaimsAttachments';
import './ClaimDetails.css';
import { PetCloudBreadcrumbs } from '../../myPets/common/PetCloudBreadcrumbs';
import { CLAIM_STATUS_BY_TEXT } from '../../../constants';
import { AttentionNeeded } from './AttentionNeeded';

const ClaimsDetails = () => {
  const { t } = useTranslation('claims');
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector(({
    claims,
    fileClaims,
  }) => ({
    attachments: claims.attachmentsByClaim,
    claims,
    fileClaims,
  }), shallowEqual);
  const { claimById: { ClaimNumber, ClaimStatus } } = store.claims;

  const getDataClaim = () => {
    if (location.state) {
      dispatch(getClaimByIdRequested({
        claimId: location.state.claimId,
      }));
    }
  };

  const loadDataCallback = useCallback(getDataClaim, []);

  useEffect(() => {
    loadDataCallback();

    return () => {
      dispatch(restartClaimsDetails());
    };
  }, [dispatch, loadDataCallback]);

  if (!location.state) {
    history.replace(CLAIMS);
  }

  return (
    <div className="Claim-details-general-container-multibrand">
      <PetCloudBreadcrumbs
        activeRoute={`${t('breadcrumb.subtitle')} ${ClaimNumber}`}
        rootRoute={t('breadcrumb.title')}
      />

      {ClaimStatus === CLAIM_STATUS_BY_TEXT.attentionNeeded
        && <AttentionNeeded />}

      <div className="Claim-details-general-body">
        <Shimmer
          className="Shimmer-wrapper"
          height={474}
          visible={store.claims.isLoadingDetails}
          width={736}
        >
          <DetailsList />
        </Shimmer>

        <Shimmer
          className="Shimmer-wrapper"
          height={118}
          visible={store.claims.isLoadingDetails}
          width={352}
        >
          <ClaimsAttachments />
        </Shimmer>
      </div>
    </div>
  );
};

export default ClaimsDetails;
