import { handleActions } from 'redux-actions';
import { sortListByName } from '../services/utils';

const INITIAL_STATE = {
  articles: [],
  articlesRequested: false,
  contactInfo: [],
  currentSkip: 0,
  getPetWisdomFailed: false,
  isPetWisdomLoading: true,
  limit: 0,
  loadingArticles: false,
  loadingMore: false,
  petWisdomInfo: {},
  tags: [],
  totalItems: 0,
};

export default handleActions({
  ARTICLES_CLEANED: () => ({
    ...INITIAL_STATE,
  }),
  ARTICLES_LOAD_FAILED: (store) => ({
    ...store,
    loadingArticles: false,
  }),
  ARTICLES_LOADED: (store, { payload }) => ({
    ...store,
    articles: [
      ...store.articles,
      ...payload.Items,
    ],
    articlesRequested: true,
    currentSkip: payload.Skip,
    limit: payload.Limit,
    loadingArticles: false,
    loadingMore: false,
    tags: [
      ...new Set(sortListByName({
        ascendent: true,
        fieldName: 'TagName',
        list: [
          ...store.tags,
          ...payload.TagsAndColors.map((tag) => ({
            ...tag,
            TagName: tag.TagName.toUpperCase(),
          })),
        ],
      })),
    ],
    totalItems: payload.Total,
  }),
  GET_ARTICLES: (store, { payload }) => ({
    ...store,
    loadingArticles: payload.firstLoad,
    loadingMore: !payload.firstLoad,
  }),
  GET_CONTACT_BRAND_DATA_LOADED: (store, { payload }) => ({
    ...store,
    contactInfo: payload,
  }),
  GET_PET_WISDOM_ATTEMPT: (state) => ({
    ...state,
    getPetWisdomFailed: false,
    isPetWisdomLoading: true,
    petWisdomInfo: {},
  }),
  PET_WISDOM_FAILED: (state) => ({
    ...state,
    getPetWisdomFailed: true,
    isPetWisdomLoading: false,
    petWisdomInfo: {},
  }),
  PET_WISDOM_LOADED: (state, { payload }) => ({
    ...state,
    getPetWisdomFailed: false,
    isPetWisdomLoading: false,
    petWisdomInfo: payload,
  }),
}, INITIAL_STATE);
