import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  displayForgotPasswordModal,
  recoverPassword,
} from '../../../../actions';

import { GenericModal } from '../../../common/petCloud/GenericModal';
import { PetCloudInputAnimated }
from '../../../common/petCloud/PetCloudInputAnimated';

import tooltipIcon from '../../../../assets/myAccount/tooltip.svg';
import errorIcon from '../../../../assets/myAccount/input-error-icon.svg';

import IconTooltip from '../../../common/IconTooltip';
import { isEmailValid, removeEmojis } from '../../../../services/utils';

import '../css/PetCloudEdit.css';

const ForgotPasswordModal = ({
  onClickCancel = () => { },
  showForgotPasswordModal,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('myAccount');
  const [emailValue, setEmailValue] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isForgotLoading, setIsForgotLoading] = useState(false);
  const [successReset, setSuccessReset] = useState(false);

  const handleEmailChange = useCallback(({ target }) => {
    const { value } = target;
    const cleanValue = removeEmojis(value);

    setEmailValue(cleanValue);
    const invalidEmail = !isEmailValid(cleanValue);
    setEmailError(invalidEmail ? t('petCloudSection.invalidEmail') : '');
  }, []);

  const handleCancelAction = useCallback(() => {
    dispatch(displayForgotPasswordModal({ showForgotPasswordModal: false }));
    setEmailValue('');
    setEmailError('');
  }, []);

  function handlePasswordResetError(resetError) {
    setEmailError(resetError);
    setIsForgotLoading(false);
  }

  function handleSuccessReset() {
    setSuccessReset(true);
    setIsForgotLoading(false);
  }

  const handleConfirmAction = useCallback(() => {
    if (emailError) {
      return;
    }

    if (isForgotLoading) {
      return;
    }

    setIsForgotLoading(true);
    setEmailError(null);
    dispatch(recoverPassword({
      backendErrorCallback: handlePasswordResetError,
      email: emailValue,
      errorCallback: handlePasswordResetError,
      successCallback: handleSuccessReset,
    }));
  }, [
    emailError,
    emailValue,
    isForgotLoading,
  ]);

  const handleCloseSuccessModal = useCallback(() => {
    dispatch(displayForgotPasswordModal({ showForgotPasswordModal: false }));
    onClickCancel();
  }, []);

  function renderError() {
    if (emailError) {
      return (
        <div className="Pet-cloud-forgot-modal-error">
          <img alt="" src={errorIcon} />

          <span>{emailError}</span>
        </div>
      );
    }

    return null;
  }

  function renderModal() {
    if (successReset) {
      const modalDescription =
        t('petCloudSection.forgotPasswordModal.successDescription');

      return (
        <GenericModal
          containerClassName="Pet-cloud-confirm-email-modal-container"
          description={modalDescription}
          handleOutClick={handleCloseSuccessModal}
          onPrimaryClick={handleCloseSuccessModal}
          primaryButtonText={t('petCloudSection.close')}
          show={showForgotPasswordModal}
          title={t('petCloudSection.forgotPasswordModal.successTitle')}
        />
      );
    }

    return (
      <GenericModal
        containerClassName="Pet-cloud-confirm-email-modal-container"
        description={t('petCloudSection.forgotPasswordModal.description')}
        handleOutClick={handleCancelAction}
        loadingPrimary={isForgotLoading}
        onPrimaryClick={handleConfirmAction}
        onSecondaryClick={handleCancelAction}
        primaryButtonDisabled={!emailValue.length}
        primaryButtonText={t('petCloudSection.forgotPasswordModal.send')}
        secondaryButtonText={t('petCloudSection.cancel')}
        show={showForgotPasswordModal}
        title={t('petCloudSection.forgotPasswordModal.title')}
      >
        <div className="Pet-cloud-confirm-email-container">
          <PetCloudInputAnimated
            error={renderError()}
            extraItemClass={emailError
              ? 'Pet-cloud-forgot-tooltip-extra-space' : ''}
            label={t('petCloudSection.forgotPasswordModal.currentEmail')}
            onChange={handleEmailChange}
            value={emailValue}
          >
            <IconTooltip
              activeOnTouch
              arrowClass="Pet-cloud-forgot-tooltip-arrow"
              containerClass="Pet-cloud-forgot-tooltip-container"
              description={t('petCloudSection.forgotPasswordModal.tooltip')}
              direction="down-left"
              height={50}
              icon={tooltipIcon}
              textClassName="Pet-cloud-forgot-tooltip"
              width={200}
            />
          </PetCloudInputAnimated>
        </div>
      </GenericModal>
    );
  }

  return renderModal();
};

export { ForgotPasswordModal };
