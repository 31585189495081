import React, { useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CONTACT_US_STEPS_BY_NUMBER }
from '../../sideMenu/contactUs/contactUs.constants';

import { ContactUsModal } from '../../sideMenu/contactUs/ContactUsModal';

import Warning from '../../../assets/claims/warning-icon.svg';

const AttentionNeeded = () => {
  const { t } = useTranslation('claims');
  const [contactUsVisible, setContactUsVisible] = useState(false);

  const store = useSelector(({ claims }) => ({ claims }), shallowEqual);

  const { claimById } = store.claims;

  const onContactUsClick = () => {
    setContactUsVisible(true);
  };

  const onContactUsClose = () => {
    setContactUsVisible(false);
  };
  return (
    <>
      <ContactUsModal
        companyStep={CONTACT_US_STEPS_BY_NUMBER[claimById.MarketingChannelId]}
        hasHeader
        onClose={onContactUsClose}
        show={contactUsVisible}
      />

      <div className="Claim-attention-needed-container">
        <div className="Claim-attention-needed-title-container">
          <img alt="" src={Warning} />

          <h2>{t('attentionNeeded')}</h2>
        </div>

        <div className="Claim-attention-needed-description-container">
          <p>
            {t('attentionNeededTxt')}

            <button onClick={onContactUsClick} type="button">
              {t('contactUs')}
            </button>
          </p>
        </div>
      </div>
    </>
  );
};

export { AttentionNeeded };
