import React from 'react';
import { useTranslation } from 'react-i18next';

import figoIcon from '../../../assets/myAccount/figo-card-logo.svg';
import akcIcon from '../../../assets/myAccount/akc-card-logo.svg';
import petPartnersIcon
from '../../../assets/myAccount/pet-partners-card-logo.svg';

import { MY_ACCOUNT } from '../../../constants';
import { MARKETING_CHANNEL_ID } from '../../../theme/theme.constants';

import { MyAccountCardItem } from '../MyAccountCardItem';

import '../css/MyAccountScreen.css';

const InsuranceAccountCards = ({
  insuranceCompanies = [],
  onClick = () => { },
}) => {
  const { t } = useTranslation('myAccount');
  const { akc, ppi, ppiOnePack } = MARKETING_CHANNEL_ID;

  if (!insuranceCompanies?.length) {
    return null;
  }

  return insuranceCompanies.map((company) => {
    let cardTitle = t('providers.figo');
    let cardKey = t('providers.figo');
    let cardIcon = figoIcon;

    switch (company.Id) {
      case akc:
        cardTitle = t('providers.akc');
        cardKey = t('providers.akc');
        cardIcon = akcIcon;
        break;

      case ppi:
      case ppiOnePack:
        cardTitle = t('providers.petPartners');
        cardKey = company?.CustomerSourceId || t('providers.petPartners');
        cardIcon = petPartnersIcon;
        break;

      default:
        cardTitle = t('providers.figo');
        cardKey = t('providers.figo');
        cardIcon = figoIcon;
        break;
    }

    return (
      <MyAccountCardItem
        key={String(cardKey)}
        description={t('providers.description')}
        icon={cardIcon}
        onClick={onClick({
          extraData: company.Id,
          id: MY_ACCOUNT.insuranceAccount,
          name: t(`breadCrumbs.${MY_ACCOUNT.insuranceAccount}`),
        })}
        title={cardTitle}
      />
    );
  });
};

export { InsuranceAccountCards };
