import React, { useCallback, useEffect, useLayoutEffect, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import FigoLottie from '../common/FigoLottie';

import { useWebviewMultibrand } from '../../hooks';
import { POST_MESSAGE_TYPES, WEB_VIEW_TYPES } from '../../constants';

import './AddPetScreenIFrame.css';

const HTTPS_KEY = 'https://';

const AddPetScreenIFrame = () => {
  const { t } = useTranslation('insuranceScreen');
  const location = useLocation();
  const history = useHistory();

  const { getWebView, isLoading, url } = useWebviewMultibrand();

  const selectedPolicy = useMemo(() => {
    if (!location.state) {
      return {};
    }

    return location.state.selectedPolicy;
  }, [location.state]);

  const selectedPet = useMemo(() => {
    if (!location.state) {
      return {};
    }

    return location.state.selectedPet;
  }, [location.state]);

  useEffect(() => {
    if (!selectedPolicy) {
      return;
    }

    const request = {
      companyId: selectedPolicy.MarketChannelId,
      policyId: selectedPolicy.Id,
      policyNumber: selectedPolicy.PolicyDisplayNumber,
      webViewType: WEB_VIEW_TYPES.addingNewPetToPolicy,
    };

    if (selectedPet) {
      request.petId = selectedPet.PetId;
    }

    getWebView(request);
  }, [selectedPolicy, selectedPet]);

  const onMessage = useCallback(({ data, origin }) => {
    if (!url) {
      return;
    }

    const [webViewOrigin] = url.replace(HTTPS_KEY, '').split('/');
    const domain = `${HTTPS_KEY}${webViewOrigin}`;
    const validPostMessage = data === POST_MESSAGE_TYPES.CLOSE_PAGE;

    if (origin === domain && validPostMessage) {
      history.go(-2);
    }
  }, [url]);

  useLayoutEffect(() => {
    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [onMessage]);

  if (isLoading || !url) {
    return <FigoLottie />;
  }

  return (
    <div className="Add-pet-iframe-container">
      <div className="Add-pet-iframe-title-page-container">
        <h1>{t('addOrcaPetIFrame.title')}</h1>

        <p>{t('addOrcaPetIFrame.description')}</p>
      </div>

      <iframe
        allowFullScreen
        src={url}
        title={t('addOrcaPetIFrame.title')}
      />
    </div>
  );
};

export { AddPetScreenIFrame };
