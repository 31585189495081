import { createActions } from 'redux-actions';

const actions = createActions({
  CLOSE_MULTI_POLICIES_MODAL: () => {},
  LOAD_MULTI_POLICIES: (data) => data,
  LOAD_POLICIES_CARD_INFORMATION: (data) => data,
  LOAD_POLICIES_PAYMENT_NUMBER: () => {},
  OPEN_MULTI_POLICIES_MODAL: (data) => data,
  SORT_POLICIES: (data) => data,
  SWITCH_CHECKBOX_STATE: (data) => data,
});

export const {
  closeMultiPoliciesModal,
  loadMultiPolicies,
  loadPoliciesCardInformation,
  loadPoliciesPaymentNumber,
  openMultiPoliciesModal,
  sortPolicies,
  switchCheckboxState,
} = actions;
