import React from 'react';
import { useTranslation } from 'react-i18next';

import ModalContainer from '../../common/ModalContainer';

import './NoVetModal.css';

const NoVetModal = ({
  show = false,
  onAddVetClick = () => { },
  onClose = () => { },
}) => {
  const { t } = useTranslation('claims');

  return (
    <ModalContainer
      cancelable
      contentStyle="No-vet-modal-container"
      handleOutClick={onClose}
      show={show}
    >
      <h3>{t('noPrimaryVet.title')}</h3>

      <p>{t('noPrimaryVet.text')}</p>

      <button
        className="No-vet-modal-action-button Action-modal-button-blue"
        onClick={onAddVetClick}
        type="button"
      >
        {t('noPrimaryVet.action')}
      </button>
    </ModalContainer>
  );
};

export { NoVetModal };
