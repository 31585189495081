import { handleActions } from 'redux-actions';

const INITIAL_STATE = {
  isVisible: false,
  paymentMethodData: {},
  policiesList: [],
  policiesToUpdate: [],
  sameBillingAddress: false,
  showLoading: false,
  userInformation: {},
};

export default handleActions({
  CLOSE_MULTI_POLICIES_MODAL: (state) => ({
    ...state,
    isVisible: false,
  }),
  LOAD_MULTI_POLICIES: (state, { payload }) => ({
    ...state,
    policiesList: payload.map((item) => ({
      ...item,
      isToUpdate: false,
    })),
  }),
  LOAD_POLICIES_CARD_INFORMATION: (state, { payload }) => ({
    ...state,
    policiesList: state.policiesList.map((policy) => ({
      ...policy,
      Policy: {
        ...policy.Policy,
        last4: payload.find((item) => (
          item.PolicyNumber === policy.Policy.Number
        )).AccountNumber,
        PaymentMethod: payload.find((item) => (
          item.PolicyNumber === policy.Policy.Number
        )).PaymentMethod,
      },
    })),
  }),
  OPEN_MULTI_POLICIES_MODAL: (state, { payload }) => ({
    ...state,
    isVisible: true,
    paymentMethodData: payload.paymentMethodData,
    policiesList: state.policiesList.map((item) => ({
      ...item,
      isToUpdate: false,
    })),
    policiesToUpdate: payload.policies,
    sameBillingAddress: payload.sameBillingAddress,
    userInformation: payload.userInformation,
  }),
  PAYMENT_METHOD_UPDATE_ATTEMPTED: (state) => ({
    ...state,
    showLoading: true,
  }),
  PAYMENT_METHOD_UPDATED: (state) => ({
    ...state,
    showLoading: false,
  }),
  SORT_POLICIES: (state, { payload }) => ({
    ...state,
    policiesList: payload,
  }),
  SWITCH_CHECKBOX_STATE: (state, { payload }) => ({
    ...state,
    policiesList: state.policiesList.map((item) => {
      if (item.Policy.Number === payload) {
        return {
          ...item,
          isToUpdate: !item.isToUpdate,
        };
      }
      return item;
    }),
  }),
}, INITIAL_STATE);
