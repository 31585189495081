import { sessionExpired, showGenericError } from '../actions';
import app from '../app.json';
import {
  CLAIMS_API_TOKEN,
  CUSTOMER_API_TOKEN,
  POLICY_API_TOKEN,
} from './constants';
import { serializeForUri, sleep } from './utils';

const BASE_IP = `${process.env.REACT_APP_API_URL}`;
const CLIENT_NAME = 'Petcloud';

export function saveData(key, data) {
  return sessionStorage.setItem(key, data);
}

export function getData(key) {
  return sessionStorage.getItem(key);
}

export async function getStorageOrDefault(key) {
  try {
    const data = await getData(key);

    return JSON.parse(data);
  } catch (_) {
    return {};
  }
}

export function removeData(key) {
  return sessionStorage.removeItem(key);
}

export function removeAllData() {
  return sessionStorage.clear();
}

export const IDOR_HEADER_V2 = { 'Api-Version': 2 };
export const API_HEADER_V2 = { 'Api-Version': 'v2' };

async function getApiToken(url = '') {
  const fullUrl = `${BASE_IP}/${url}`;
  let tokenKey = 'token';

  if (fullUrl.includes(`${BASE_IP}/customer`)) {
    tokenKey = CUSTOMER_API_TOKEN;
  } else if (fullUrl.includes(`${BASE_IP}/claims`)) {
    tokenKey = CLAIMS_API_TOKEN;
  } else if (fullUrl.includes(`${BASE_IP}/policy`)) {
    tokenKey = POLICY_API_TOKEN;
  }

  const token = await getData(tokenKey);

  return token;
}

async function call({
  attempt = 0,
  anonymous = false,
  data,
  dispatch,
  extraHeaders = {},
  method,
  responseJson = true,
  returnObject = 'json',
  url = '',
}) {
  const token = anonymous
    ? 'api-key' : await getApiToken(url);
  const headers = {
    'Api-Version': 'v1',
    authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
    'X-Client-Name': CLIENT_NAME,
    'X-Client-Version': app.version,
    ...extraHeaders,
  };

  if (!token) {
    return { error: '401', statusCode: 401, success: false };
  }

  let statusCode;

  try {
    const requestData = {
      body: data && JSON.stringify(data),
      headers,
      method,
    };

    const response = await fetch(`${BASE_IP}/${url}`, requestData);
    statusCode = response.status;

    // wait for refresh token
    if (attempt === 0) {
      await sleep(600);
    }

    if (response.status === 401) {
      if (attempt === 0) {
        dispatch(sessionExpired());
      }

      if (attempt <= 1) {
        return call({
          attempt: attempt + 1,
          data,
          dispatch,
          extraHeaders,
          method,
          responseJson,
          returnObject,
          url,
        });
      }
    }

    if (response.status >= 500) {
      const error = await response.json();

      if (dispatch && returnObject === 'json' && !error.detail) {
        dispatch(showGenericError(true));
      }

      return { error, statusCode, success: false };
    }

    if (response.status === 204) {
      return { statusCode, success: true };
    }

    let returnableData = {};
    if (returnObject === 'blob') {
      const res = await response.blob();
      returnableData = { blob: res };
    } else {
      const res = responseJson ? await response.json()
        : { data: await response.text() };
      returnableData = res;

      if (returnableData instanceof Array) {
        return { data: returnableData, statusCode, success: true };
      }
    }

    return { success: response.ok, ...returnableData };
  } catch (error) {
    if (dispatch) {
      dispatch(showGenericError(true));
    }
    return { error: error?.message, statusCode, success: false };
  }
}

export function get(dispatch, URL, params = {}) {
  const queryParams = serializeForUri(params);
  const url = `${URL}?${queryParams}`;

  return call({ dispatch, method: 'GET', url });
}

/* api version 2 */
export function getApiV2(dispatch, URL, params = {}) {
  const queryParams = serializeForUri(params);
  const url = `${URL}?${queryParams}`;

  return call({
    dispatch,
    extraHeaders: API_HEADER_V2,
    method: 'GET',
    url,
  });
}

export function postApiV2(dispatch, url, data, returnObject = 'json') {
  return call({
    data,
    dispatch,
    extraHeaders: API_HEADER_V2,
    method: 'POST',
    returnObject,
    url,
  });
}

export function putApiV2(dispatch, url, data) {
  return call({
    data,
    dispatch,
    extraHeaders: API_HEADER_V2,
    method: 'PUT',
    url,
  });
}

export function delApiV2(dispatch, url, data) {
  return call({
    data,
    dispatch,
    extraHeaders: API_HEADER_V2,
    method: 'DELETE',
    url,
  });
}

export function patchApiV2(
  dispatch,
  url,
  data,
  responseJson = false,
) {
  return call({
    data,
    dispatch,
    extraHeaders: API_HEADER_V2,
    method: 'PATCH',
    responseJson,
    url,
  });
}

export function postApiBlobV2(dispatch, url, data) {
  return call({
    data,
    dispatch,
    extraHeaders: API_HEADER_V2,
    method: 'POST',
    returnObject: 'blob',
    url,
  });
}

/* api IDOR version 2 */
export function getV2(dispatch, path, params = {}) {
  const queryParams = serializeForUri(params);
  const url = `${path}?${queryParams}`;

  return call({
    dispatch,
    extraHeaders: IDOR_HEADER_V2,
    method: 'GET',
    url,
  });
}

export function post(dispatch, url, data, returnObject = 'json') {
  return call({ data, dispatch, method: 'POST', returnObject, url });
}

export function postBlob(dispatch, url, data) {
  return call({ data, dispatch, method: 'POST', returnObject: 'blob', url });
}

export function postV2(dispatch, url, data, returnObject = 'json') {
  return call({
    data,
    dispatch,
    extraHeaders: IDOR_HEADER_V2,
    method: 'POST',
    returnObject,
    url,
  });
}

/* api IDOR version 2 */
export function postBlobV2(dispatch, url, data) {
  return call({
    data,
    dispatch,
    extraHeaders: IDOR_HEADER_V2,
    method: 'POST',
    returnObject: 'blob',
    url,
  });
}

export function put(dispatch, url, data, formData = false) {
  return call({
    data,
    dispatch,
    method: 'PUT',
    responseJson: formData,
    url,
  });
}

export function del(dispatch, url, data) {
  return call({ data, dispatch, method: 'DELETE', url });
}

export function patch(
  dispatch,
  url,
  data,
  responseJson = false,
) {
  return call({
    data,
    dispatch,
    method: 'PATCH',
    responseJson,
    url,
  });
}

export async function apiCallWithApiKey(path, params = {}, dispatch) {
  try {
    let url = `${BASE_IP}/${path}`;

    const options = {
      headers: {
        Authorization: 'APIKEY 8f7ecbb6-a62c-44d9-ab88-7b295ba7956c',
        'Content-Type': 'application/json',
        'X-Client-Name': CLIENT_NAME,
        'X-Client-Version': app.version,
        ...params.headers,
      },
    };

    if (params.params) {
      const queryParams = serializeForUri(params);
      url += `?${queryParams}`;
    }

    if (params.method === 'POST' || params.method === 'DELETE') {
      options.method = params.method;
      options.body = JSON.stringify(params.body);
    }

    const response = await fetch(url, options);
    const res = await response.json();

    if (response.status >= 500) {
      if (dispatch) {
        dispatch(showGenericError(true));
      }
      return {
        ...res,
        success: false,
      };
    }

    return {
      ...res,
      success: true,
    };
  } catch (_) {
    if (dispatch) {
      dispatch(showGenericError(true));
    }
    return {
      success: false,
    };
  }
}

export function getAnonymous(
  dispatch,
  URL,
  params = {},
) {
  const queryParams = serializeForUri(params);
  const url = `${URL}?${queryParams}`;

  return call({
    anonymous: true,
    dispatch,
    extraHeaders: API_HEADER_V2,
    method: 'GET',
    url,
  });
}

export function postAnonymous(
  dispatch,
  URL,
  body,
  params = {},
) {
  const queryParams = serializeForUri(params);
  const url = `${URL}?${queryParams}`;

  return call({
    anonymous: true,
    data: body,
    dispatch,
    extraHeaders: API_HEADER_V2,
    method: 'POST',
    url,
  });
}
