import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import addIcon
from '../../../../assets/myAccount/reimbursement/check-white.svg';

import { changeReimbursementMethodType } from '../../../../actions';
import {
  CHECK_REIMBURSEMENT,
  DEPOSIT_REIMBURSEMENT,
} from '../../../../constants';

import '../css/ReimbursementEditModal.css';

const ReimbursementMethodContainer = ({
  PreferredReimbursementMethod = DEPOSIT_REIMBURSEMENT,
}) => {
  const { t } = useTranslation('myAccount');
  const dispatch = useDispatch();

  const isBankAccountActive = PreferredReimbursementMethod
    === DEPOSIT_REIMBURSEMENT;

  const isCheckActive = PreferredReimbursementMethod
    === CHECK_REIMBURSEMENT;

  const bankAccountTabClassname = 'Reimbursement-edit-modal-method-left'
    + `${isBankAccountActive ? '-selected' : ''}`;

  const checkTabClassname = 'Reimbursement-edit-modal-method-right'
    + `${isCheckActive ? '-selected' : ''}`;

  function displayBankTabContent() {
    dispatch(changeReimbursementMethodType(DEPOSIT_REIMBURSEMENT));
  }

  function displayCheckTabContent() {
    dispatch(changeReimbursementMethodType(CHECK_REIMBURSEMENT));
  }

  return (
    <div className="Reimbursement-edit-modal-method-container">
      <button
        className={bankAccountTabClassname}
        onClick={displayBankTabContent}
        type="button"
      >
        {isBankAccountActive ? <img alt="" src={addIcon} /> : null}

        <span>{t('reimbursementSection.editModal.directDeposit')}</span>
      </button>

      <button
        className={checkTabClassname}
        onClick={displayCheckTabContent}
        type="button"
      >
        {isCheckActive ? <img alt="" src={addIcon} /> : null}

        <span>{t('reimbursementSection.editModal.check')}</span>
      </button>
    </div>
  );
};

export { ReimbursementMethodContainer };
