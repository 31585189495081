import React from 'react';

import './InputCapsule.css';

const InputCapsule = ({
  customStyleClass = '',
  error = '',
  maxLength = 10,
  onChange = () => { },
  placeholder = '',
  value = '',
}) => {
  function onInputValueChange(event) {
    onChange(event);
  }

  return (
    <div className="Input-capsule-input-container-with-error">
      <input
        className={`Input-capsule-input ${customStyleClass}`}
        maxLength={maxLength}
        onChange={onInputValueChange}
        placeholder={placeholder}
        type="text"
        value={value}
      />

      {error ? <span>{error}</span> : null}
    </div>
  );
};

export { InputCapsule };
