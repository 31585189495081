import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cloudAdd from '../../../assets/fileClaim/cloud-add.svg';
import './Dropzone.css';

const Dropzone = ({
  coverClassName = '',
  dropzoneClassName = '',
  onAddFiles = () => { },
  text = '',
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const { t } = useTranslation('common');
  const dropzoneCoverRef = useRef();
  const dropzoneRef = useRef();
  const [documentHeight, setDocumentHeight] = useState(false);

  const updateDocumentHeight = () => {
    if (window.scrollY <= 0) {
      setDocumentHeight(false);
    } else {
      setDocumentHeight(true);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateDocumentHeight);
    window.addEventListener('scroll', updateDocumentHeight);
  }, [updateDocumentHeight]);

  const handleDragover = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleDragenter = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    const draggingFiles = event.dataTransfer.types.indexOf('Files') >= 0;
    if (draggingFiles) {
      setIsDragging(true);
    }
  }, []);

  const handleDragleave = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    const { fromElement, toElement } = event;
    const { current: dropzoneElement } = dropzoneRef;
    const { current: dropzoneCoverElement } = dropzoneCoverRef;
    if ((fromElement !== dropzoneElement
      || toElement !== dropzoneCoverElement)
      && (fromElement !== dropzoneCoverElement
        || toElement !== dropzoneElement)) {
      setIsDragging(false);
    }
  }, []);

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
    if (event.dataTransfer.files) {
      onAddFiles(event.dataTransfer.files);
      event.dataTransfer.clearData();
    }
  }, []);

  useEffect(() => {
    if (isDragging) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isDragging]);

  useEffect(() => {
    const currentCover = dropzoneCoverRef.current;
    if (currentCover) {
      currentCover.addEventListener('dragleave', handleDragleave);
    }

    const currentDropzone = dropzoneRef.current;
    if (currentDropzone) {
      currentDropzone.addEventListener('drop', handleDrop);
    }

    return () => {
      if (currentCover) {
        currentCover.removeEventListener('dragleave', handleDragleave);
      }

      if (currentDropzone) {
        currentDropzone.removeEventListener('drop', handleDrop);
      }
    };
  }, [handleDrop, handleDragleave, dropzoneCoverRef, dropzoneRef, isDragging]);

  useEffect(() => {
    window.addEventListener('dragover', handleDragover);
    window.addEventListener('dragenter', handleDragenter);

    return () => {
      window.removeEventListener('dragover', handleDragover);
      window.removeEventListener('dragenter', handleDragenter);
    };
  }, [handleDragenter, handleDragover]);

  return (isDragging ? (
    <div
      ref={dropzoneCoverRef}
      className={`Dropzone-cover ${coverClassName}
      ${documentHeight && 'Dropzone-cover-resize'}`}
    >
      <div
        ref={dropzoneRef}
        className={`Dropzone-container
          ${dropzoneClassName}
          ${documentHeight && 'Dropzone-container-resize'}
          `}
      >
        <div className="Dropzone-file-container No-clicked">
          <div className="Dropzone-file-icon">
            <img
              alt="doc"
              src={cloudAdd}
            />
          </div>

          <span className="Dropzone-paragraph">
            {text || t('dropzoneText')}
          </span>

          <span className="Dropzone-formats">
            {t('dropzoneFormats')}
          </span>
        </div>
      </div>
    </div>
  )
    : null
  );
};

export { Dropzone };
