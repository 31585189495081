import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './ClaimsScreenComponent.css';
import {
  setIsCheckedOptionClosed,
  setIsCheckedOptionOpen,
} from '../../actions';

const NoMatchingFilters = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('claims');

  function clearFilters() {
    dispatch(setIsCheckedOptionOpen(true));
    dispatch(setIsCheckedOptionClosed(true));
  }

  return (
    <div className="Claims-screen-no-match-container">
      <h3 className="Claims-screen-no-match-title">
        {t('noMatchFilters')}
      </h3>

      <button
        className="Filter-claims-clear-button"
        onClick={clearFilters}
        type="button"
      >
        {t('clearFilters')}
      </button>
    </div>
  );
};
export default NoMatchingFilters;
