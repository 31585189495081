import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getThemeLogoById } from '../../../theme/theme.utils';

import { MY_ACCOUNT } from '../../../constants';
import { CONTACT_US_STEPS_BY_NUMBER }
from '../../sideMenu/contactUs/contactUs.constants';

import { useInsuranceAccountScreen } from './hooks/useInsuranceAccount';

import { InsuranceAccountRow } from './InsuranceAccountRow';
import { InsuranceAccountShimmer } from './InsuranceAccountShimmer';
import { ContactUsModal } from '../../sideMenu/contactUs/ContactUsModal';
import { InsuranceAccountUpdateForm } from './InsuranceAccountUpdateForm';
import { InsuranceAccountRequestChange } from './InsuranceAccountRequestChange';

import './css/InsuranceAccountScreen.css';

const InsuranceAccountScreen = ({ breadCrumbs = {}, currentFolderId = 0 }) => {
  const { t } = useTranslation('myAccount');

  const [contactUsVisible, setContactUsVisible] = useState(false);
  const [isUpdateFormOpen, setUpdateFormOpen] = useState(false);
  const toggleUpdateForm = () => setUpdateFormOpen(!isUpdateFormOpen);

  const companyId = useMemo(() => {
    if (breadCrumbs) {
      return breadCrumbs.navData
        .find((data) => data.id === MY_ACCOUNT.insuranceAccount).extraData;
    }

    return 1;
  }, [breadCrumbs]);

  const {
    address,
    email,
    loading,
    nameOnAccount,
    phoneNumber,
  } = useInsuranceAccountScreen(companyId);

  const isUpdateAccount = currentFolderId === MY_ACCOUNT.update;

  const themeLogo = useMemo(() => {
    const theme = getThemeLogoById(companyId);
    return theme.dark;
  }, [companyId]);

  const onContactUsClick = () => {
    setContactUsVisible(true);
  };

  const onContactUsClose = () => {
    setContactUsVisible(false);
  };

  const originId = CONTACT_US_STEPS_BY_NUMBER?.[companyId];

  if (loading) {
    return <InsuranceAccountShimmer />;
  }

  function renderAccountScreen() {
    return (
      <div className="Insurance-account-container">
        <h1 className="Insurance-account-title">
          {t('insuranceAccountSection.title')}
        </h1>

        <p className="Insurance-account-description">
          {t('insuranceAccountSection.description')}
        </p>

        <div className="Insurance-account-info-container">
          <InsuranceAccountRow
            breadCrumbs={breadCrumbs}
            companyLogo={themeLogo}
            isFirstRow
          />

          <InsuranceAccountRow
            initialValue={nameOnAccount}
            label={t('insuranceAccountSection.nameAccount')}
          />

          <InsuranceAccountRow
            initialValue={phoneNumber}
            label={t('insuranceAccountSection.phoneNumber')}
          />

          <InsuranceAccountRow
            initialValue={email}
            label={t('insuranceAccountSection.email')}
          />

          <InsuranceAccountRow
            initialValue={address}
            label={t('insuranceAccountSection.customerAddress')}
          />
        </div>
      </div>
    );
  }

  function renderUpdateAccount() {
    return (
      <div className="Insurance-account-container">
        <ContactUsModal
          companyStep={originId}
          onClose={onContactUsClose}
          show={contactUsVisible}
        />

        <h1 className="Insurance-account-title">
          {t('insuranceAccountSection.updateTitle')}
        </h1>

        <div className="Insurance-account-info-container">
          {isUpdateFormOpen && (
            <InsuranceAccountUpdateForm
              breadCrumbs={breadCrumbs}
              onContactUsClick={onContactUsClick}
              setUpdateFormOpen={setUpdateFormOpen}
              toggleUpdateForm={toggleUpdateForm}
            />
          )}

          {!isUpdateFormOpen && (
            <InsuranceAccountRequestChange
              onContactUsClick={onContactUsClick}
              toggleUpdateForm={toggleUpdateForm}
            />
          )}
        </div>
      </div>

    );
  }

  return isUpdateAccount ? renderUpdateAccount() : renderAccountScreen();
};

export { InsuranceAccountScreen };
