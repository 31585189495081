import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  displayForgotPasswordModal,
  myAccountHasChanges,
  refreshPersonalInformation,
  updatePassword,
} from '../../../../actions';

import { PetCloudInputAnimated }
from '../../../common/petCloud/PetCloudInputAnimated';

import { ActionButton } from '../../../common/petCloud/ActionButton';

import { useMyAccountInfo } from '../../hooks/useMyAccountInfo';

import { ForgotPasswordModal } from './ForgotPasswordModal';

import { removeEmojis } from '../../../../services/utils';

import hiddenIcon from '../../../../assets/myAccount/password-hidden.svg';
import visibleIcon from '../../../../assets/myAccount/password-visible.svg';

import '../css/PetCloudEdit.css';

const PetCloudPasswordEdit = ({
  onClickCancel = () => { },
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('myAccount');
  const store = useSelector(({ myAccount }) => ({ myAccount }), shallowEqual);
  const { myAccount: { showForgotPasswordModal } } = store;
  const [isLoading, setIsLoading] = useState(false);
  const [updateError, setUpdateError] = useState('');
  const [currentPassword, setCurrentPassword] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);

  const {
    handleVisibleConfirmPassword,
    handleVisibleCurrentPassword,
    handleVisibleNewPassword,
    isConfirmHidden,
    isCurrentHidden,
    isNewHidden,
    loginEmail,
  } = useMyAccountInfo();

  useEffect(() => {
    dispatch(myAccountHasChanges({
      pageHasChanges: currentPassword || newPassword || confirmPassword,
    }));

    if (newPassword && confirmPassword) {
      const error = newPassword !== confirmPassword
        ? t('petCloudSection.passwordEdit.passwordErrorMatch') : '';

      setUpdateError(error);
    }
  }, [confirmPassword, currentPassword, newPassword]);

  const disableButton = useMemo(() => !currentPassword || !newPassword
    || !confirmPassword || updateError, [
    confirmPassword,
    currentPassword,
    newPassword,
    updateError,
  ]);

  const handleOnClickForgot = useCallback(() => {
    dispatch(displayForgotPasswordModal({ showForgotPasswordModal: true }));
  }, []);

  function handleUpdateError(error) {
    setUpdateError(error);
    setIsLoading(false);
  }

  function handleUpdateSuccess() {
    dispatch(refreshPersonalInformation());
    dispatch(myAccountHasChanges({ pageHasChanges: false }));
    setIsLoading(false);
    onClickCancel();
  }

  const onClickSave = useCallback(() => {
    setIsLoading(true);

    dispatch(updatePassword({
      email: loginEmail,
      errorCallback: handleUpdateError,
      newPassword,
      password: currentPassword,
      successCallback: handleUpdateSuccess,
    }));
  }, [loginEmail, newPassword]);

  function handleInputChanged(event) {
    const cleanValue = removeEmojis(event.target.value);
    setUpdateError(false);

    switch (event.target.id) {
      case 'accountCurrentPasswordInput':
        setCurrentPassword(cleanValue);
        break;

      case 'accountNewPasswordInput':
        setNewPassword(cleanValue);
        break;

      case 'accountConfirmPasswordInput':
        setConfirmPassword(cleanValue);
        break;

      default:
        break;
    }
  }

  return (
    <div className="Pet-cloud-edit-container">
      <div className="Pet-cloud-current-password-container">
        <PetCloudInputAnimated
          id="accountCurrentPasswordInput"
          inputClass="Pet-cloud-password-input"
          label={t('petCloudSection.passwordEdit.currentPassword')}
          labelClass="Pet-cloud-password-input-label"
          onChange={handleInputChanged}
          placeholder={t('petCloudSection.passwordEdit.password')}
          type={isCurrentHidden ? 'password' : 'text'}
        >
          <button
            className="Like-span"
            onClick={handleVisibleCurrentPassword}
            type="button"
          >
            <img
              alt=""
              id="currentPasswordHideButton"
              src={isCurrentHidden ? hiddenIcon : visibleIcon}
            />
          </button>
        </PetCloudInputAnimated>

        <button
          className="Like-span"
          onClick={handleOnClickForgot}
          type="button"
        >
          <span className="Pet-cloud-current-password-forgot">
            {t('petCloudSection.passwordEdit.forgotPassword')}
          </span>
        </button>
      </div>

      <PetCloudInputAnimated
        id="accountNewPasswordInput"
        inputClass="Pet-cloud-password-input"
        label={t('petCloudSection.passwordEdit.newPassword')}
        labelClass="Pet-cloud-password-input-label"
        onChange={handleInputChanged}
        placeholder={t('petCloudSection.passwordEdit.newPassword')}
        type={isNewHidden ? 'password' : 'text'}
      >
        <button
          className="Like-span"
          onClick={handleVisibleNewPassword}
          type="button"
        >
          <img
            alt=""
            id="newPasswordHideButton"
            src={isNewHidden ? hiddenIcon : visibleIcon}
          />
        </button>
      </PetCloudInputAnimated>

      <div className="Pet-cloud-confirm-password-container">
        <PetCloudInputAnimated
          id="accountConfirmPasswordInput"
          inputClass="Pet-cloud-password-input"
          label={t('petCloudSection.passwordEdit.confirmPassword')}
          labelClass="Pet-cloud-password-input-label"
          onChange={handleInputChanged}
          placeholder={t('petCloudSection.passwordEdit.newPassword')}
          type={isConfirmHidden ? 'password' : 'text'}
        >
          <button
            className="Like-span"
            onClick={handleVisibleConfirmPassword}
            type="button"
          >
            <img
              alt=""
              id="confirmPasswordHideButton"
              src={isConfirmHidden ? hiddenIcon : visibleIcon}
            />
          </button>
        </PetCloudInputAnimated>

        <span className="Error-label">{updateError}</span>
      </div>

      <div className="Pet-cloud-edit-save-button">
        <ActionButton
          disabled={disableButton}
          loading={isLoading}
          onClick={onClickSave}
          title={t('petCloudSection.save')}
        />
      </div>

      <ForgotPasswordModal
        onClickCancel={onClickCancel}
        showForgotPasswordModal={showForgotPasswordModal}
      />
    </div>
  );
};

export { PetCloudPasswordEdit };
