import React from 'react';
import { useTranslation } from 'react-i18next';

import './css/PowerUpTitle.css';

const PowerUpTitle = ({
  children,
  localSelected,
  title,
}) => {
  const { t } = useTranslation('changeCoverage');

  return (
    <div className="Powerup-title-container-optional">
      <div className="Powerup-title" data-selected={localSelected}>
        <h3>
          <span className="Powerup-optional">
            {t('options.optional')}
          </span>

          {title}
        </h3>

        {children}
      </div>
    </div>
  );
};

export { PowerUpTitle };
