const STEP_KEY_NAME = {
  claim_filing_error: 'claim_filing_error',
  claim_filing_success: 'claim_filing_success',
  confirm_reimb_preference: 'confirm_reimb_preference',
  confirm_select_check: 'confirm_select_check',
  error_nopolicy: 'error_nopolicy',
  get_ach_account: 'get_ach_account',
  get_ach_account_type: 'get_ach_account_type',
  get_ach_routing: 'get_ach_routing',
  get_attachments: 'get_attachments',
  get_claim_amount: 'get_claim_amount',
  get_confirm_terms: 'get_confirm_terms',
  get_illness_date: 'get_illness_date',
  get_illness_desc: 'get_illness_desc',
  get_onset_date: 'get_onset_date',
  get_other_insurance: 'get_other_insurance',
  get_other_insurance_name: 'get_other_insurance_name',
  get_reimb_preference: 'get_reimb_preference',
  get_select_pet: 'get_select_pet',
  get_signature: 'get_signature',
  get_treatment_date: 'get_treatment_date',
  get_vetfee_attachments: 'get_vetfee_attachments',
  get_vetfee_attachments_ar_s2: 'get_vetfee_attachments_ar_s2',
  get_vetfee_attachments_bf: 'get_vetfee_attachments_bf',
  get_vetfee_attachments_rw: 'get_vetfee_attachments_rw',
  get_vetfee_attachments_s1: 'get_vetfee_attachments_s1',
  get_vetfee_attachments_s2: 'get_vetfee_attachments_s2',
  get_vetfee_attachments_s3: 'get_vetfee_attachments_s3',
  get_vetfee_attachments_st_s2: 'get_vetfee_attachments_st_s2',
  get_vetfee_attachments_vc: 'get_vetfee_attachments_vc',
  get_wellness_treatment_date: 'get_wellness_treatment_date',
  ok_other_insurance: 'ok_other_insurance',
  ok_select_injury: 'ok_select_injury',
  ok_select_pet: 'ok_select_pet',
  ok_select_vetfee: 'ok_select_vetfee',
  ok_select_wellness: 'ok_select_wellness',
  select_claim_type: 'select_claim_type',
  show_summary: 'show_summary',
  submit_claim: 'submit_claim',
  synthetic_policy_error: 'synthetic_policy_error',
  terms_declined: 'terms_declined',
  user_has_more_than_one_pet: 'user_has_more_than_one_pet',
  user_has_pets_with_policy: 'user_has_pets_with_policy',

  // syntetic step are no comming from backend
  // eslint-disable-next-line sort-keys
  syntetic_get_select_policy: 'syntetic_get_select_policy',
};

const ALL_ATTACHMENT_STEPS = [
  STEP_KEY_NAME.get_attachments,
  STEP_KEY_NAME.get_vetfee_attachments_s1,
  STEP_KEY_NAME.get_vetfee_attachments_s2,
  STEP_KEY_NAME.get_vetfee_attachments_s3,
  STEP_KEY_NAME.get_vetfee_attachments,
];

const INPUT_TYPES = {
  VALIDATION: 15,
};
const STEP_TYPES_TO_SKIP_FROM_HISTORY = [
  INPUT_TYPES.VALIDATION,
];

const STEPS_TO_SKIP_FROM_HISTORY = [
  STEP_KEY_NAME.ok_select_injury,
];

/**
 * Property name to send policy id on submiting claim.
 */
const ORCA_POLICY_ID_PROP_NAME = 'OrcaPolicyId';

const POLICY_NUMBER_PROP_NAME = 'PolicyNumber';

const ROUTING_NUMBER_PROP_NAME = 'ACHRoutingNumber';

const ACCOUNT_NUMBER_PROP_NAME = 'ACHAccountNumber';

const ACCOUNT_TYPE_PROP_NAME = 'ACHAccountType';

const REIMBURSEMENT_METHOD_TYPE_KEY_NAME = 'PaymentType';

/**
 * Defines the type of reimbursement method chosen by the user.
 * This is the general type: direct deposit or check.
 */
const REIMBURSEMENT_METHOD_TYPE = {
  ACH: 2,
  CHECK: 1,
  NONE: 0,
};

/**
 * Defines the sub-type of direct deposit (ACH)
 * account type: checking or savings.
 */
const REIMBURSEMENT_METHOD_ACCOUNT_TYPE = {
  Checking: 1,
  Savings: 2,
};

const SIGNATURE_TYPE = {
  DRAWING: 0,
  TYPING: 1,
};

const SIGNATURE_MAX_LENGTH = 40;

export {
  ACCOUNT_NUMBER_PROP_NAME,
  ALL_ATTACHMENT_STEPS,
  ACCOUNT_TYPE_PROP_NAME,
  ORCA_POLICY_ID_PROP_NAME,
  POLICY_NUMBER_PROP_NAME,
  REIMBURSEMENT_METHOD_ACCOUNT_TYPE,
  REIMBURSEMENT_METHOD_TYPE_KEY_NAME,
  REIMBURSEMENT_METHOD_TYPE,
  ROUTING_NUMBER_PROP_NAME,
  SIGNATURE_MAX_LENGTH,
  SIGNATURE_TYPE,
  STEP_KEY_NAME,
  STEP_TYPES_TO_SKIP_FROM_HISTORY,
  STEPS_TO_SKIP_FROM_HISTORY,
};
