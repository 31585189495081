import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../common/LoadingButton';
import {
  closeMultiPoliciesModal,
  paymentMethodPastDueModalVisibleChanged,
} from '../../actions';
import ModalContainer from '../common/ModalContainer';
import FigoLoadingOverlay from '../common/FigoLoadingOverlay';
import closeIcon from '../../assets/close-button.svg';
import './EditPaymentMultiPolicyModal.css';
import './EndorsementModal.css';

const EditPaymentMultiPolicyModal = ({
  hasPastPaymentDue,
  onUpdatePaymentMethod,
}) => {
  const { t } = useTranslation('settingsScreen');
  const dispatch = useDispatch();
  const store = useSelector(({ editPayment, billingPayments }) => ({
    billingPayments,
    editPayment,
  }));
  const {
    billingPayments: { updatingPaymentMethod, policyDetails },
    editPayment: { policiesList },
  } = store;

  const listSort = useMemo(() => {
    if (!policiesList.length) {
      return [];
    }

    return policiesList
      .sort((prev, next) => {
        if (prev.PetInformation.Name > next.PetInformation.Name) {
          return 1;
        }

        if (prev.PetInformation.Name < next.PetInformation.Name) {
          return -1;
        }

        return 0;
      });
  }, [policiesList]);

  const selectedPet = useMemo(() => {
    if (!policyDetails) {
      return 0;
    }

    return listSort.findIndex((item) => item.Policy && item.Policy.Number
      === policyDetails.PolicyNumber);
  }, [policiesList]);

  const petPoliciesSorted = useMemo(() => {
    if (!policiesList.length) {
      return [];
    }

    if (selectedPet === 0) {
      return listSort;
    }

    const pet = listSort[selectedPet];

    return [
      pet,
      ...listSort.filter((_, index) => index !== selectedPet),
    ];
  }, [selectedPet, policiesList]);

  const noAgencyBillPoliciesOneInc = useMemo(
    () => {
      const selectedPayment = store.editPayment.paymentMethodData
        ? store.editPayment.paymentMethodData.paymentName : '';

      return petPoliciesSorted.filter((policy) => policy
        && policy.Policy.PaymentMethod === selectedPayment);
    },
    [petPoliciesSorted, store.editPayment.paymentMethodData],
  );

  function onCancel() {
    if (!updatingPaymentMethod) {
      dispatch(closeMultiPoliciesModal());
    }
  }

  function onSave() {
    const {
      paymentMethodData,
      policiesToUpdate,
      sameBillingAddress,
      userInformation,
    } = store.editPayment;

    if (updatingPaymentMethod) {
      return;
    }

    const extraPolicies = policiesList.filter((policy) => (
      policy.isToUpdate
    )).map((item) => (item.Policy.Number));

    const requestUpdatePaymentMethod = {
      paymentMethodData,
      policies: [...policiesToUpdate, ...extraPolicies],
      sameBillingAddress,
      userInformation,
    };

    if (hasPastPaymentDue) {
      dispatch(closeMultiPoliciesModal());
      dispatch(paymentMethodPastDueModalVisibleChanged({
        requestUpdatePaymentMethod,
        visible: true,
      }));
      return;
    }

    onUpdatePaymentMethod(requestUpdatePaymentMethod);
  }

  if (!store.editPayment.isVisible) {
    return null;
  }

  if (noAgencyBillPoliciesOneInc.length === 0) {
    onSave();
  }

  if (updatingPaymentMethod) {
    return <FigoLoadingOverlay visible />;
  }

  function renderViewWithoutPetList() {
    return (
      <>
        <button
          className="Edit-payment-policy-withoulist-close-button"
          onClick={onCancel}
          type="button"
        >
          <img alt="close" src={closeIcon} />
        </button>

        <div className="Edit-payment-policy-withoutlist-container">
          <span className="Edit-payment-withoutlist-title-text">
            {t('editPoliciesPaymentMethod.title')}
          </span>
        </div>

        <div className="Edit-payment-withoutlist-description-container">
          <p className="Edit-payment-withoutlist-description-text">
            {t('editPoliciesPaymentMethod.withoutListDescription')}
          </p>
        </div>

        <div className="Edit-payment-withoutlist-buttons-container">
          <LoadingButton
            className="Edit-payment-button-update"
            disabled={updatingPaymentMethod}
            id="saveMultiplePoliciesPayMethod"
            loading={updatingPaymentMethod}
            onClick={onSave}
            text={t('editPoliciesPaymentMethod.update')}
            textStyle="Edit-payment-button-update-text"
          />

          <button
            className="Edit-payment-button-cancel"
            disabled={updatingPaymentMethod}
            id="cancelMultiplePoliciesPayMethod"
            onClick={onCancel}
            type="button"
          >
            <span className="Edit-payment-button-cancel-text">
              {t('editPoliciesPaymentMethod.cancel')}
            </span>
          </button>
        </div>
      </>
    );
  }

  return (
    <ModalContainer
      contentStyle="Edit-payment-modal"
      handleOutClick={onCancel}
      show
    >
      {renderViewWithoutPetList()}
    </ModalContainer>
  );
};

export { EditPaymentMultiPolicyModal };
