import React from 'react';

import ModalContainer from '../ModalContainer';
import { ActionButton } from './ActionButton';
import closeIcon from '../../../assets/close-button.svg';

import './GenericModal.css';

const GenericModal = ({
  children = null,
  containerClassName = '',
  description = '',
  handleOutClick = () => { },
  isDeleteAction = false,
  loadingPrimary = false,
  onPrimaryClick = () => { },
  onPrimarySuccess = () => { },
  onSecondaryClick = () => { },
  primaryButtonDisabled = false,
  primaryButtonText = '',
  secondaryButtonDisabled = false,
  secondaryButtonText = '',
  show = false,
  successPrimary = false,
  title = '',
}) => (
  <ModalContainer
    cancelable
    closeButtonStyle="Generic-modal-close-button"
    customCloseIcon={closeIcon}
    handleOutClick={handleOutClick}
    show={show}
  >
    <div className={`Generic-modal-container ${containerClassName}`}>
      <h3>{title}</h3>

      <p className="Generic-modal-description-text">{description}</p>

      {children}

      <div className="Generic-modal-buttons-container">
        <ActionButton
          disabled={primaryButtonDisabled}
          isDeleteButton={isDeleteAction}
          loading={loadingPrimary}
          onClick={onPrimaryClick}
          onSuccess={onPrimarySuccess}
          success={successPrimary}
          title={primaryButtonText}
        />

        {secondaryButtonText && (
          <ActionButton
            disabled={secondaryButtonDisabled}
            isSecondaryButton
            onClick={onSecondaryClick}
            title={secondaryButtonText}
          />
        )}
      </div>
    </div>
  </ModalContainer>
);

export { GenericModal };
