import React from 'react';
import { useTranslation } from 'react-i18next';

import { BannerApp } from './BannerApp';
import { DownloadMobileApps } from './DownloadMobileApps';
import { PublicFooter } from '../common/PublicFooter';

import FigoBackground from '../../assets/login/login-background.svg';
import PetCloudBanner from '../../assets/pet-cloud-logo.svg';

import './LoginMobile.css';

const LoginMobile = () => {
  const { t } = useTranslation('loginScreen');

  return (
    <div className="Login-mobile">
      <BannerApp
        appName="Figo Pet Cloud"
        buttonText={t('loginMobile.view')}
        title={t('loginMobile.openApp')}
      />

      <div className="Login-mobile-container">
        <div className="Login-pet-cloud-banner">
          <img alt="" src={PetCloudBanner} />
        </div>

        <img
          alt=""
          className="Login-mobile-figo-background"
          src={FigoBackground}
        />

        <div className="Login-mobile-background-content">
          <h1>{t('loginMobile.welcome')}</h1>

          <p>{t('loginMobile.textApp')}</p>

          <div className="Login-mobile-apps-container">
            <DownloadMobileApps />
          </div>
        </div>

      </div>

      <PublicFooter />

    </div>
  );
};

export { LoginMobile };
