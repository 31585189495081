import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { possesiveAdaptationI18n } from '../../../services/utils';

import './css/ChangeCoverageScreenPreview.css';

const ChangeCoverageDetails = ({
  annualBenefit = '',
  deductible = 0,
  effectiveDate = '',
  lastDate = '',
  petName = '',
  price = 0,
  reimbursement = 0,
}) => {
  const { t } = useTranslation('changeCoverage');

  const benefits = useMemo(() => ([
    {
      text: t('annualBenefit'),
      value: `$${annualBenefit.replace('K', ',000')}`,
    },
    { text: t('reimbursement'), value: reimbursement },
    { text: t('deductible'), value: deductible },
  ]), [annualBenefit, deductible, reimbursement]);

  const priceValue = useMemo(() => {
    if (price) {
      return {
        decimals: price?.split('.')[1],
        integer: price?.split('.')[0],
      };
    }

    return { decimals: '', integer: '' };
  }, [price]);

  return (
    <>
      <div className="Change-coverage-screen-price-container">
        <span className="Change-coverage-screen-price">
          {priceValue.integer}
        </span>

        <span className="Change-coverage-screen-price-decimals">
          .{priceValue.decimals}
        </span>

        <span className="Change-coverage-screen-price-month">
          {t('preview.pricePerMonth')}
        </span>
      </div>

      <p className="Change-coverage-screen-preview-dates">
        {t('preview.resume1', {
          count: possesiveAdaptationI18n(petName),
          name: petName,
        })}
        <b>{effectiveDate}</b>
        {t('preview.resume2')}
        <b>{lastDate}</b>
        {t('preview.resume3')}
      </p>

      <h3>{t('preview.coverageDetails')}</h3>

      <div className="Change-coverage-screen-benefits-container">
        {benefits.map((benefit) => (
          <div key={benefit.text} className="Flex Flex-column">
            <span className="Change-coverage-screen-benefit-title">
              {benefit.text}
            </span>

            <span className="Change-coverage-screen-benefit-value">
              {benefit.value}
            </span>
          </div>
        ))}
      </div>
    </>
  );
};

export { ChangeCoverageDetails };
