import { all, call, put, select } from 'redux-saga/effects';
import platform from 'platform';
import { REIMBURSEMENT_METHODS, ACCOUNT_TYPES } from '../constants';
import { get, post } from '../services/api';
import { doNothing, fileSizeToMB, getMimefile } from '../services/utils';
import app from '../app.json';
import {
  addAttachmentsLoad,
  claimRequested,
  failedToLoadClaimSteps,
  fileClaimCompleted,
  getClaimAttachmentsFailed,
  getClaimAttachmentsLoaded,
  getClaimByIdFailed,
  getClaimByIdLoaded,
  getClaimsFailed,
  getClaimsLoaded,
  newClaimStepsLoaded,
  fileClaimsAttachmentUpdated,
  claimsResultsLoadedError,
  claimsResultsLoadedSuccess,
  claimsResultsLoadedMore,
} from '../actions';

export function* getClaimsUser(dispatch, { payload = {} }) {
  const {
    loadMore = false,
    offset = 15,
    page = 0,
    petId = 0,
    policyNumber = null,
    status = null,
  } = payload;

  const query = {
    offset,
    page,
    petId,
  };

  if (policyNumber) {
    query.policyNumber = policyNumber;
  }

  if (status) {
    query.status = status;
  }

  const totalCalimsResponse =
    yield call(get, dispatch, 'api/PMS/Claim/GetTotalClaims', query);
  const response = yield call(get, dispatch, 'api/PMS/Claim/GetClaims', {
    ...query,
    page,
  });

  if (response.success && response.IsValid) {
    yield put(getClaimsLoaded({
      claims: response.Data,
      loadMore,
      totalClaims: totalCalimsResponse.Data,
    }));
  } else {
    yield put(getClaimsFailed());
  }
}

export function* loadNewClaimSteps(dispatch) {
  const response = yield call(get, dispatch, 'api/Bot/GetClaimTypeSteps/0/1');

  if (response.success && response.IsValid) {
    yield put(newClaimStepsLoaded(response.Data));
  } else {
    yield put(failedToLoadClaimSteps());
  }
}

export function* getAttachmentsByClaim(dispatch, action) {
  yield put(claimRequested());
  const response = yield call(
    get,
    dispatch,
    'api/PMS/Claim/GetClaimAttachments',
    { claimId: action.payload.cliamId },
  );

  if (response.success && response.IsValid) {
    yield put(getClaimAttachmentsLoaded(response.Data));
  } else {
    yield put(getClaimAttachmentsFailed());
  }
}

export function* getClaimByNumber(dispatch, action) {
  const { claimNumber } = action.payload;
  yield put(claimRequested());
  const response = yield call(
    get,
    dispatch,
    'api/PMS/Claim/Get',
    { claimNumber },
  );

  if (response.success && response.IsValid && response.Data) {
    yield put(getClaimByIdLoaded(response.Data));
  } else {
    yield put(getClaimByIdFailed());
  }
}

function* submitClaimError({
  claimData,
  claimError = '',
  dispatch,
  error = '',
  userId,
}) {
  try {
    const { os } = platform;

    const content = {
      ClaimFailError: claimError,
      DeviceInfo: `${os.family},${os.version},${app.version}`,
      FrontEndError: error.toString(),
      Json: {
        ...claimData,
        AttachmentsCount: claimData.Attachments.length,
      },
      User: userId,
    };

    yield call(
      post,
      dispatch,
      'api/Bot/SubmitClaimErrorLogging',
      { content: JSON.stringify(content) },
    );
  } catch (_) {
    doNothing();
  }
}

export function* createClaim(dispatch) {
  const NONE = 'NONE';
  const ACH = 'ACH';
  const CHECK = 'CHECK';

  const store = yield select(({
    fileClaims,
    billingPayments,
    personalInformation,
  }) => ({
    billingPayments,
    fileClaims,
    personalInformation,
  }));

  const {
    fileClaims,
    billingPayments: { reimbursementMethod },
    personalInformation: { userInfo: { CustomerId } },
  } = store;

  let paymentType = NONE;
  switch (reimbursementMethod.PreferredReimbursementMethod) {
    case REIMBURSEMENT_METHODS.deposit:
      paymentType = ACH;
      break;
    case REIMBURSEMENT_METHODS.check:
      paymentType = CHECK;
      break;
    default:
      break;
  }

  // TODO: Claim lost date new Date(Number(fileClaims.date)).toISOString()
  const lostDate = new Date(+fileClaims.date);
  const lostDateDay = lostDate.getDate();
  const lostDateMount = lostDate.getMonth() + 1;
  const lostDateYear = lostDate.getFullYear();
  const lostDateFormated =
    `${lostDateYear}-${lostDateMount}-${lostDateDay} 12:00:00`;

  const postBody = {
    AmtClaimed: Number(fileClaims.amount.replace(/,/g, '').replace('$', '')),
    Attachments: fileClaims.attachments.map((file) => ({
      DownloadBinary: file.binary,
      Extension: file.extension,
      Filename: file.filename,
      Filesize: `${fileSizeToMB({ fileSize: file.size })} MB`,
    })),
    ClaimType: fileClaims.claimTypeText,
    CoverageCodeId: fileClaims.claimType,
    Description: fileClaims.claimsDetails,
    Euthanized: false,
    EuthanizedAdvised: false,
    HospitalAdmittedDate: '',
    HospitalDischargeDate: '',
    HospitalDuration: 0,
    IllnessStartDate: lostDateFormated,
    LossDate: lostDateFormated,
    NotifiedOthers: false,
    NotifiedPolice: false,
    OtherInsurance: !!fileClaims.anotherPetInsurance,
    PaymentType: paymentType,
    PolicyNumber: fileClaims.policyNumber,
    RelatedTreamentDscr: '',
    SaveAsDefault: false,
    VacationCancelled: false,
    VacationPlanned: false,
  };

  const AttachmentsError = fileClaims.attachments.map((file) => ({
    DownloadBinary: '',
    Extension: file.extension,
    Filename: file.filename,
    Filesize: `${fileSizeToMB({ fileSize: file.size })} MB`,
  }));

  if (fileClaims.anotherPetInsurance) {
    postBody.OtherInsuranceDscr = fileClaims.anotherPetInsurance;
  }

  if (reimbursementMethod.PreferredReimbursementMethod
    === REIMBURSEMENT_METHODS.deposit) {
    postBody.ACHAccountNumber = reimbursementMethod.AccountNumber;
    postBody.ACHAccountType = Object.keys(ACCOUNT_TYPES)
      .find((key) => ACCOUNT_TYPES[key] === reimbursementMethod.AccountType);
    postBody.ACHRoutingNumber = reimbursementMethod.RoutingNumber;
  }

  try {
    const response = yield call(
      post,
      dispatch,
      'api/Bot/SubmitClaim',
      postBody,
    );

    yield put(fileClaimCompleted({
      isSuccess: response.success && response.IsValid,
      message: response.Message,
    }));

    if (!response.success || !response.IsValid) {
      yield call(submitClaimError, {
        claimData: { ...postBody, Attachments: AttachmentsError },
        claimError: response.Message,
        dispatch,
        error: '',
        userId: CustomerId,
      });
    }
  } catch (error) {
    yield call(submitClaimError, {
      claimData: { ...postBody, Attachments: AttachmentsError },
      claimError: '',
      dispatch,
      error,
      userId: CustomerId,
    });
  }
}

function* saveAttachment(dispatch, file, ClaimId, t) {
  try {
    const url = 'api/PMS/Claim/AddClaimAttachment';

    const postBody = {
      ClaimId,
      ContentType: getMimefile(file.extension?.toLowerCase()),
      Extension: file.extension,
      FileContent: file.binary,
      FileName: file.filename,
    };

    const response = yield call(post, dispatch, url, postBody);

    if (response.success && response.IsValid) {
      dispatch(fileClaimsAttachmentUpdated({
        key: file.key,
        percentage: 100,
        uploadValid: true,
        valid: true,
      }));
    } else {
      dispatch(fileClaimsAttachmentUpdated({
        errorMsg: t('conectionError'),
        key: file.key,
        percentage: 100,
        uploadValid: false,
        valid: false,
      }));
    }
  } catch (_) {
    dispatch(fileClaimsAttachmentUpdated({
      errorMsg: t('conectionError'),
      key: file.key,
      percentage: 100,
      uploadValid: false,
      valid: false,
    }));
  }
}

export function* addAttachmentToClaim(dispatch, action) {
  const { files, ClaimId, t } = action.payload;
  yield all(files.map((file) => {
    let progress = 0;
    while (progress <= 74) {
      progress += 1;
      dispatch(fileClaimsAttachmentUpdated({
        key: file.key,
        percentage: progress,
      }));
    }

    return saveAttachment(dispatch, file, ClaimId, t);
  }));

  dispatch(addAttachmentsLoad({ loading: false, success: true }));
}

export function* fetchClaimsResults({ payload }) {
  try {
    const action = payload.pageNumber > 0
      ? claimsResultsLoadedMore
      : claimsResultsLoadedSuccess;
    const claimsData =
      yield call(post, null, 'api/MultiBrand/Claims', payload);
    if (claimsData.IsValid && claimsData.success) {
      yield put(action({
        data: claimsData.Data,
        status: payload.status,
      }));
    } else {
      yield put(claimsResultsLoadedError(claimsData.Message));
    }
  } catch (error) {
    yield put(claimsResultsLoadedError(error.message));
  }
}

export function* fetchClaimById(dispatch, { payload }) {
  try {
    const { claimId } = payload;

    const response = yield call(
      get,
      dispatch,
      `api/MultiBrand/Claim/${claimId}`,
    );

    if (response?.success && response?.IsValid && response?.Data) {
      yield put(getClaimByIdLoaded(response.Data));
    } else {
      yield put(getClaimByIdFailed());
    }
  } catch (error) {
    yield put(getClaimByIdFailed());
  }
}
