import { createActions } from 'redux-actions';

const actions = createActions({
  GET_FEATURE_FLAGS: () => { },
  GET_FEATURE_FLAGS_FAILED: (data) => data,
  GET_FEATURE_FLAGS_LOADED: (data) => data,
  LOAD_ZIP_CODE_DETAILS: (data) => (data),
  LOAD_ZIP_CODE_DETAILS_FAILED: () => { },
  LOAD_ZIP_CODE_DETAILS_RESTARTED: () => { },
  RESET_INFORMATION: () => { },
  ZIP_CODE_DETAILS_LOADED: (data) => (data),
});

export const {
  getFeatureFlags,
  getFeatureFlagsFailed,
  getFeatureFlagsLoaded,
  loadZipCodeDetails,
  loadZipCodeDetailsFailed,
  loadZipCodeDetailsRestarted,
  resetInformation,
  zipCodeDetailsLoaded,
} = actions;
