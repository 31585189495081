import { createActions } from 'redux-actions';

const actions = createActions({
  ARTICLES_CLEANED: () => {},
  ARTICLES_LOAD_FAILED: () => {},
  ARTICLES_LOADED: (data) => data,
  GET_ARTICLES: (data) => data,
  GET_CONTACT_BRAND_DATA: () => { },
  GET_CONTACT_BRAND_DATA_LOADED: (data) => data,
  GET_PET_WISDOM: (data) => data,
  GET_PET_WISDOM_ATTEMPT: () => { },
  PET_WISDOM_FAILED: () => { },
  PET_WISDOM_LOADED: (data) => data,
});

export const {
  articlesCleaned,
  articlesLoaded,
  articlesLoadFailed,
  getArticles,
  getContactBrandData,
  getContactBrandDataLoaded,
  getPetWisdom,
  getPetWisdomAttempt,
  petWisdomFailed,
  petWisdomLoaded,
} = actions;
