import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalContainer from '../common/ModalContainer';
import './CancelPolicyModal.css';

const CancelPolicyModal = (props) => {
  const { t } = useTranslation('insuranceScreen');
  const firstPart = t('cancelPolicyModal.messages.firstPart');
  const phoneNumber = t('cancelPolicyModal.messages.phoneNumber');
  const secondPart = t('cancelPolicyModal.messages.secondPart');
  const email = t('cancelPolicyModal.messages.email');
  const buttonText = t('cancelPolicyModal.buttonText');
  const title = t('cancelPolicyModal.title');

  function handleOnClickButton() {
    if (props.onClose) {
      props.onClose();
    }
  }

  function greenText(content) {
    return (
      <span className="Cancel-policy-modal-content-green">
        {content}
      </span>
    );
  }

  return (
    <ModalContainer
      id="cancelPolicyModal"
      show={props.visible}
    >
      <div className="Cancel-policy-modal-container">
        <span className="Cancel-policy-modal-title">{title}</span>

        <span className="Cancel-policy-modal-content">
          {firstPart} {greenText(phoneNumber)}&nbsp;
          {secondPart} {greenText(email)}
        </span>

        <button
          className="Cancel-policy-modal-close-button"
          onClick={handleOnClickButton}
          type="button"
        >
          {buttonText}
        </button>
      </div>
    </ModalContainer>
  );
};

export default CancelPolicyModal;
