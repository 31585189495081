import { createActions } from 'redux-actions';

const actions = createActions({
  GET_MULTIBRAND_WEB_VIEW: (data) => data,
  WEB_VIEW_MULTIBRAND_ATTEMPT: () => { },
  WEB_VIEW_MULTIBRAND_FAILED: () => { },
  WEB_VIEW_MULTIBRAND_RESET: () => { },
  WEB_VIEW_MULTIBRAND_RESULT_LOADED: (data) => data,
  WEB_VIEW_MULTIBRAND_SUCCESS: (data) => data,
});

export const {
  getMultibrandWebView,
  webViewMultibrandAttempt,
  webViewMultibrandFailed,
  webViewMultibrandReset,
  webViewMultibrandResultLoaded,
  webViewMultibrandSuccess,
} = actions;
