import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalContainer from '../ModalContainer';
import { InputImageMyPets } from './InputImageMyPets';
import { ImageEditableMyPets } from './ImageEditableMyPets';
import closeIcon from '../../../assets/close-button.svg';
import './ImageEditorMyPets.css';
import roundArrow from '../../../assets/myPets/round-arrow.svg';
import { ActionButton } from '../petCloud/ActionButton';
import LoadingActionButtonMyPets from '../petCloud/LoadingActionButtonMyPets';
import { readFile, getCroppedImg } from '../../../services/utils';
import { SliderMyPets } from './SliderImageMyPets';

const ACCEPTED_FILES = '.jpg,.jpeg,.png,.svg';
const ALLOWED_EXT = /(\.jpg|\.jpeg|\.png|\.svg)$/i;

const steps = { EDIT: 2, PREVIEW: 3, REMOVE: 0, SELECT: 1 };

const ImageEditorMyPets = ({
  classContainer = '',
  classCropperContainer = '',
  classInputContainer = '',
  classRotateButtonsContainer = '',
  classSliderContainer = '',
  imageHeight,
  imageWidth,
  initialPicture = null,
  isSaving = false,
  onClose = () => { },
  onRemove = () => { },
  onSave = () => { },
  onSuccess = () => { },
  photoType = '',
  round = true,
  saveSuccess = false,
  show = false,
  subtitle = '',
  title = '',
}) => {
  const { t } = useTranslation('common');
  const [imageSource, setImageSource] = useState(null);
  const [invalidSource, setInvalidSource] = useState(false);
  const [imageSourceData, setImageSourceData] = useState();
  const [imageDestination, setImageDestination] = useState(null);
  const [step, setStep] = useState(steps.SELECT);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(150);

  useEffect(() => {
    if (!show) {
      setImageSource(null);
      setInvalidSource(false);
      setImageSourceData(null);
      setImageDestination(null);
      setStep(steps.SELECT);
      setCrop({ x: 0, y: 0 });
      setCroppedAreaPixels(null);
      setRotation(0);
      setZoom(150);
    }

    if (show && initialPicture) {
      setImageSourceData(initialPicture);
      setZoom(150);
      setStep(steps.EDIT);
    }
  }, [show]);

  async function generatePreview() {
    try {
      const croppedImage = await getCroppedImg(
        imageSourceData,
        croppedAreaPixels,
        rotation,
      );

      return croppedImage;
    } catch (_) {
      return null;
    }
  }

  const handleModalImageEditorClose = useCallback(() => {
    if (!isSaving && !saveSuccess) {
      onClose();
    }
  }, [isSaving, saveSuccess, onClose]);

  const handleImageChanged = useCallback(async (file) => {
    let imageDataUrl = null;
    if (file) {
      const isImage = file.name.match(ALLOWED_EXT);
      if (isImage) {
        setInvalidSource(false);
        imageDataUrl = await readFile(file);
        setZoom(150);
      } else {
        setInvalidSource(true);
      }
    }
    setImageSource(file);
    setImageSourceData(imageDataUrl);

    if (imageDataUrl && !invalidSource) {
      setStep((prevStep) => prevStep + 1);
    }
  }, []);

  function handleCropChange(newCrop) {
    setCrop(newCrop);
  }

  function handleCropComplete(_, newCroppedAreaPixels) {
    setCroppedAreaPixels(newCroppedAreaPixels);
  }

  function handleRotation(degreesStep) {
    setRotation((prevRotation) => prevRotation + degreesStep);
  }

  async function handleSaveSuccess() {
    onSuccess();
  }

  async function handleSave() {
    const imageName = imageSource?.name.split('.')[0] || '';
    let imageToSave = null;
    if (imageDestination) {
      imageToSave = imageDestination;
    } else {
      imageToSave = await generatePreview();
    }

    if (imageToSave) {
      onSave(imageToSave, imageName);
    }
  }

  function handleRemove() {
    setStep(steps.REMOVE);
  }

  function handleZoom(newZoom) {
    setZoom(newZoom);
  }

  const ImageActions = () => (
    <div className="My-pets-image-editor-actions-container">
      <div className="My-pets-image-editor-actions-buttons">
        <div className="My-pets-image-editor-actions-top-bottom">
          <LoadingActionButtonMyPets
            disabled={step === steps.SELECT}
            height={20}
            loading={isSaving}
            onClick={handleSave}
            onSuccessAnimationEnd={handleSaveSuccess}
            success={saveSuccess}
            text={t('uploadEditablePhoto.save')}
            type="button"
            width={20}
          />

          <ActionButton
            className="My-pets-image-editor-actions-cancel"
            isSecondaryButton
            onClick={handleModalImageEditorClose}
            title={t('uploadEditablePhoto.cancel')}
          />
        </div>

        {step === steps.EDIT && initialPicture && (
          <LoadingActionButtonMyPets
            className="My-pets-image-editor-remove"
            disabled={step === steps.SELECT}
            height={20}
            loading={isSaving}
            onClick={handleRemove}
            onSuccessAnimationEnd={handleSaveSuccess}
            success={saveSuccess}
            text={t('uploadEditablePhoto.removeProfilePhoto')}
            type="button"
            width={20}
          />
        )}
      </div>

      {step === steps.SELECT && (
        <div className="My-pets-image-editor-actions-box">
          <div
            className="My-pets-image-editable-slider-container"
          >
            <SliderMyPets
              disabled
              max={350}
              min={150}
              step={0.1}
              value={zoom}
            />
          </div>

          <div
            className="My-pets-image-editable-rotate-buttons"
          >
            <button
              className="My-pets-image-editable-rotate"
              onClick={handleRotation}
              type="button"
            >
              <img alt="Rotate to right" src={roundArrow} />
            </button>

            <button
              className="My-pets-image-editable-rotate"
              onClick={handleRotation}
              type="button"
            >
              <img
                alt="Rotate to left"
                className="My-pets-image-editable-rotate-arrow-left"
                src={roundArrow}
              />
            </button>
          </div>
        </div>
      )}
    </div>
  );

  function handleConfirmRemove() {
    onRemove();
  }

  function handleKeepImage() {
    setStep(steps.EDIT);
  }

  const getStepContent = () => {
    switch (step) {
      case steps.SELECT:
        return (
          <>
            <InputImageMyPets
              acceptFiles={ACCEPTED_FILES}
              classContainer={classInputContainer}
              error={invalidSource}
              file={imageSource}
              imageAreaHeight={imageHeight}
              imageAreaWidth={imageWidth}
              onFileChange={handleImageChanged}
              round={round}
            />

            <ImageActions />
          </>
        );

      case steps.EDIT:
        return (
          <>
            <ImageEditableMyPets
              classNameButtonConainer={classRotateButtonsContainer}
              classNameContainer={classCropperContainer}
              classNameSliderContainer={classSliderContainer}
              crop={crop}
              cropHeight={imageHeight}
              cropWidth={imageWidth}
              image={imageSourceData}
              onCropChange={handleCropChange}
              onCropComplete={handleCropComplete}
              onRotation={handleRotation}
              onZoom={handleZoom}
              rotation={rotation}
              round={round}
              zoom={zoom}
            />

            <ImageActions />
          </>
        );

      case steps.REMOVE:
        return (
          <ModalContainer
            contentStyle="My-pets-image-editor-remove-container"
            show
          >
            <h2 className="My-pets-image-editor-remove-title-modal">
              {t('uploadEditablePhoto.removePhoto')}
            </h2>

            <h3 className="My-pets-image-editor-remove-subtitle-modal">
              {t('uploadEditablePhoto.removeConfirmation',
                { photoType })}
            </h3>

            <div className="My-pets-image-editor-remove-buttons">
              <LoadingActionButtonMyPets
                className="My-pets-image-editor-remove-button"
                disabled={step === steps.SELECT}
                height={20}
                loading={isSaving}
                onClick={handleConfirmRemove}
                onSuccessAnimationEnd={handleSaveSuccess}
                success={saveSuccess}
                text={t('uploadEditablePhoto.remove')}
                width={20}
              />

              <ActionButton
                isSecondaryButton
                onClick={handleKeepImage}
                title={t('uploadEditablePhoto.keep')}
              />
            </div>
          </ModalContainer>
        );

      default:
        return null;
    }
  };

  return (
    <ModalContainer contentStyle="My-pets-image-editor-modal" show={show}>
      <div className={`My-pets-image-editor-container ${classContainer}`}>
        <button
          className="My-pets-image-editor-close-icon-container"
          id="closeIconAddPetImageModal"
          onClick={handleModalImageEditorClose}
          type="button"
        >
          <img
            alt="Close"
            className="My-pets-modal-close-icon"
            src={closeIcon}
          />
        </button>

        {step !== steps.REMOVE && (
          <>
            <h3 className="My-pets-image-editor-title">{title}</h3>

            <h3 className="My-pets-image-editor-subtitle">
              {subtitle}
            </h3>
          </>
        )}

        {getStepContent()}
      </div>
    </ModalContainer>
  );
};

export { ImageEditorMyPets };
