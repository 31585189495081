import React from 'react';
import { useTranslation } from 'react-i18next';
import uploaderIcon
from '../../assets/inboxDocsMultibrand/iconsFolder/upload.svg';
import './UploadFilesButton.css';

const UploadFilesButton = ({ onClick = () => { } }) => {
  const { t } = useTranslation('inboxAndDocs');

  return (
    <button
      className={'Inbox-and-docs-upload Upload-files-button '
        + 'Round-button'}
      onClick={onClick}
      type="button"
    >
      <img
        alt=""
        className="No-clicked"
        src={uploaderIcon}
      />

      <span className="No-clicked">{t('upload')}</span>
    </button>
  );
};

export { UploadFilesButton };
