import { handleActions } from 'redux-actions';

const INITIAL_STATE = {
  featureFlags: {
    error: '',
    flags: {},
    loading: false,
  },
};

export default handleActions({
  GET_FEATURE_FLAGS: (state) => ({
    ...state,
    featureFlags: {
      ...state.featureFlags,
      error: '',
      flags: {},
      loading: true,
    },
  }),
  GET_FEATURE_FLAGS_FAILED: (state, { payload }) => ({
    ...state,
    featureFlags: {
      ...state.featureFlags,
      error: payload,
      flags: {},
      loading: false,
    },
  }),
  GET_FEATURE_FLAGS_LOADED: (state, { payload }) => ({
    ...state,
    featureFlags: {
      ...state.featureFlags,
      error: '',
      flags: payload,
      loading: false,
    },
  }),
}, INITIAL_STATE);
