import React from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import TouchableDiv from './TouchableDiv';
import './FigoInput.css';

const FigoInput = ({
  ariaProps,
  asElement = 'input',
  className,
  controlId,
  counterClassName = '',
  description,
  disabled = false,
  error = '',
  hit,
  inputName,
  inputRef,
  maxLength,
  onBlur,
  onChange,
  onFocus,
  onKeyPress,
  placeholder,
  renderError,
  title,
  type = 'text',
  unit,
  unitClassName,
  value,
  withCounter = false,
}) => (
  <Form.Group controlId={controlId}>
    <TouchableDiv>
      {title && <Form.Label>{title}</Form.Label>}
    </TouchableDiv>

    <InputGroup>
      <Form.Control
        ref={inputRef}
        as={asElement}
        className={`FigoInput-input ${className || ''} `
          + `${error && 'Input-error'} ${disabled && 'Input-disabled'}`}
        disabled={disabled}
        maxLength={maxLength}
        name={inputName}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        type={type}
        value={value}
        {...ariaProps}
      />

      {unit && (
        <InputGroup.Append>
          <InputGroup.Text
            className={`${unitClassName || ''} ${error && 'Input-error'}`}
            tabIndex={-1}
          >
            {unit}
          </InputGroup.Text>
        </InputGroup.Append>
      )}
    </InputGroup>

    {description && !error
      && <span className="FigoInput-description">{description}</span>}

    {withCounter
      && (
        <span className={`FigoInput-counter ${counterClassName}`}>
          {value.length}/{maxLength}
        </span>
      )}

    {error && renderError && (
      <p className="Error-label">
        {renderError(error)}
      </p>
    )}

    {!error && hit && (<span className="FigoInput-hit">{hit}</span>)}
  </Form.Group>
);

export default FigoInput;
